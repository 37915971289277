import Vue from 'vue'
const address = require('./address.json')
const IERC20 = require('./IERC20.json');
const Web3 = require('web3')
const liquidityABI = require("./liquidity.json")
const nftABI = require("./nft.json")
const nodeABI = require("./node.json")
const teamABI = require("./team.json")
const gameABI = require("./game.json")

import i18n from '../lang/index';

import { NumSplic } from "../unit/tool";
import big from "big.js";
big.NE = -40
big.PE = 40

let web3 = undefined
let connectedChainId = null
let connectedNetwork = ''
let currWalletAddress = undefined
let connectedMainNetwork
let chain = "BSC"

const initWeb3 = async () => {
    let web3Provider
    let ethereum = window.ethereum
    if (ethereum) {
        await connectMetaMask()
        await connectWallet()
    } else if (window.web3) {
        web3Provider = window.web3.currentProvider
        if (typeof web3Provider !== 'undefined') {
            web3 = new Web3(web3Provider)
            getNetwork()
            await connectWallet()
            console.log('Connected wallet success!')
        } else {
            console.log('Connected wallet failed!')
        }
    }

    onEthereumEvent();
    
    Vue.prototype.$eventHub.$emit('walletChanged', null);
}

const connectMetaMask = () => {
    return new Promise((resolve, reject) => {
        let web3Provider
        let ethereum = window.ethereum
        if (ethereum) {
            web3Provider = ethereum
            try {
                // user auth
                if (ethereum.request != undefined) {
                    ethereum.request({ method: 'eth_requestAccounts' })
                } else {
                    ethereum.enable()
                }
                resolve(true)
            } catch (error) {
                // user not auth
                reject(error && error.message ? error.message : error)
            }
        } else {
            reject('No Ethereum')
        }

        if (typeof web3Provider !== 'undefined') {
            web3 = new Web3(web3Provider)
            getNetwork()
            if (ethereum.isMetaMask) {
                currWalletType = 'MetaMask'
            } else {
                currWalletType = 'Ethereum'
            }
        }
    })
}

const getNetwork = () => {
    if (!web3 || !web3.eth) {
        connectedMainNetwork = false
        connectedNetwork = ''
        connectedChainId = null
        return
    }
    web3.eth.net.getId().then(res => {
        connectedChainId = res
        if (res === 1) {
            connectedMainNetwork = true
            connectedNetwork = 'Main'
        } else if (res === 3) {
            connectedNetwork = 'Ropsten'
        } else if (res === 42) {
            connectedNetwork = 'Kovan'
        } else if (res === 4) {
            connectedNetwork = 'Rinkeby'
        } else if (res === 5) {
            connectedNetwork = 'Goerli'
        } else {
            connectedMainNetwork = false
            connectedNetwork = ''
        }
    })
}

// let chainInfo = [{  // BSC TEST
//     chainId: '0x61',
//     chainName: 'BSC Test',
//     nativeCurrency:
//     {
//         name: 'TBNB',
//         symbol: 'TBNB',
//         decimals: 18
//     },
//     rpcUrls: ['https://endpoints.omniatech.io/v1/bsc/testnet/public'],
//     blockExplorerUrls: ['https://rinkeby.etherscan.io'],
// }]


// window.ethereum.request({ method: 'wallet_addEthereumChain', params: chainInfo })


const connectWallet = () => {
    return new Promise((resolve, reject) => {
        if (web3 == undefined) {
            currWalletAddress = undefined;
            reject("no wallet")
            return;
        }
        web3.eth.getAccounts().then(accounts => {
            if (accounts.length > 0) {
                currWalletAddress = accounts[0];
            } else {
                currWalletAddress = undefined;
            }
            resolve(currWalletAddress)
        }).catch((err) => {
            currWalletAddress = undefined;
            console.error('web3.eth.getAccounts error :' + err)
            reject(err)
        })
    })
}

const onEthereumEvent = () => {
    let ethereum = window.ethereum;
    if (ethereum && ethereum.isMetaMask) {
        ethereum.on('accountsChanged', (accounts) => {
            // Handle the new accounts, or lack thereof.
            // "accounts" will always be an array, but it can be empty.
            if(accounts != undefined) {
                Vue.prototype.$eventHub.$emit('walletChanged', null);
            }
        });

        ethereum.on('chainChanged', (chainId) => {
            // Handle the new chain.
            // Correctly handling chain changes can be complicated.
            // We recommend reloading the page unless you have a very good reason not to.
            // window.location.reload();
            Vue.prototype.$eventHub.$emit('walletChanged', null);
            getNetwork();
        });
    }
}

let tronWeb3 = undefined
let tronCurrWalletAddress = undefined

// tron 
const initTron = async ()=>{

    // await window.tronLink.request({method: 'tron_requestAccounts'})//返回accounts [0]是钱包地址

    tronWeb3 = window.tronWeb;
    tronCurrWalletAddress = window.tronWeb.defaultAddress.base58?window.tronWeb.defaultAddress.base58 : ''


    chain = "TRON"
    let a = await tronWeb3.trx

    Vue.prototype.$eventHub.$emit('tronWalletChanged', null);

        
}


const commonCallAndHandler = (method, ...params) => {
    return new Promise((resolve, reject) => {
        method(...params).call({ from: currWalletAddress }).then(result => {
            resolve(result);
        }).catch(error => {
            reject(error);
        })
    })
}


const commonSend = (method, ...params) => {
    return new Promise((resolve, reject) => {
        return method(...params).send({ from: currWalletAddress }).then(result => {
            resolve(result);
        }).catch(error => {
            reject(error);
        })
    })
}


// bsc & eth 
function beforeCheck () {
    if(web3 == undefined) {
        throw Vue.prototype.$message.error(i18n.t("message.unit.h1"));
        // return true
    }
    return false
}


function contracts (e) {
    if(e == 'usdt') {
        return new web3.eth.Contract(IERC20, address.usdt)
    }else if(e == 'token'){
        return new web3.eth.Contract(IERC20, address.token)
    }else if(e == 'liquidity'){
        return new web3.eth.Contract(liquidityABI, address.liquidity)
    }else if(e == 'nft'){
        return new web3.eth.Contract(nftABI, address.nft)
    }else if(e == 'node'){
        return new web3.eth.Contract(nodeABI, address.node)
    }else if(e == 'team'){
        return new web3.eth.Contract(teamABI, address.team)
    }else if(e == 'game'){
        return new web3.eth.Contract(gameABI, address.game)
    }else {
        return ''
    }
}

//                     Token
// 查精度
const tokenDecimals = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')
    return commonCallAndHandler(_contract.methods.decimals);
}

const tokenName = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')
    return commonCallAndHandler(_contract.methods.name);
}

const tokenSymbol = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')
    return commonCallAndHandler(_contract.methods.symbol);
}

// 查授权
const tokenAllowance = (_address) => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')
    return commonCallAndHandler(_contract.methods.allowance, currWalletAddress, _address);
}

// 授权
const tokenApprove = (_address) => {
    if(beforeCheck()) {
        return
    }

    let bigNumber = web3.utils.toBN;
    var amount = new bigNumber(big('99999999999').times(10 ** 18));
    let _contract = contracts('token')
    return commonSend(_contract.methods.approve, _address, amount);
}

const tokenTotalSupply = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')
    return commonCallAndHandler(_contract.methods.totalSupply);
}
// 余额
const tokenBalanceOf = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')
    return commonCallAndHandler(_contract.methods.balanceOf, currWalletAddress);
}

const tokenBalanceOfDecimal = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')

    let _decimals = await tokenDecimals()
    let _balance = await commonCallAndHandler(_contract.methods.balanceOf, currWalletAddress)

    let amount = big(_balance).div(10 ** _decimals);

    return amount.toString();
}

const tokenBalanceOfAddress = (address) => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('token')
    return commonCallAndHandler(_contract.methods.balanceOf, address);
}

const tokenDivDecimal = async (amount_) => {
    let _decimals = await tokenDecimals()

    let amount = big(amount_).div(10 ** _decimals);

    return amount.toString();
}


//                     usdt
// 查精度
const usdtDecimals = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')
    return commonCallAndHandler(_contract.methods.decimals);
}

const usdtName = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')
    return commonCallAndHandler(_contract.methods.name);
}

const usdtSymbol = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')
    return commonCallAndHandler(_contract.methods.symbol);
}

// 查授权
const usdtAllowance = (_address) => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')
    return commonCallAndHandler(_contract.methods.allowance, currWalletAddress, _address);
}

// 授权
const usdtApprove = (_address) => {
    if(beforeCheck()) {
        return
    }

    let bigNumber = web3.utils.toBN;
    var amount = new bigNumber(big('99999999999').times(10 ** 18));
    let _contract = contracts('usdt')
    return commonSend(_contract.methods.approve, _address, amount);
}

const usdtTotalSupply = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')
    return commonCallAndHandler(_contract.methods.totalSupply);
}
// 余额
const usdtBalanceOf = () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')
    return commonCallAndHandler(_contract.methods.balanceOf, currWalletAddress);
}

const usdtBalanceOfDecimal = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')

    let _decimals = await usdtDecimals()
    let _balance = await commonCallAndHandler(_contract.methods.balanceOf, currWalletAddress)

    let amount = big(_balance).div(10 ** _decimals);

    return amount.toString();
}

const usdtBalanceOfAddress = (address) => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('usdt')
    return commonCallAndHandler(_contract.methods.balanceOf, address);
}

const usdtDivDecimal = async (amount_) => {
    let _decimals = await usdtDecimals()

    let amount = big(amount_).div(10 ** _decimals);

    return amount.toString();
}
// game 
const playGame = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonSend(_contract.methods.palySmallGame,);
}

const gameHistory = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonCallAndHandler(_contract.methods.getHistory,10);
}

const gameUserHistory = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonCallAndHandler(_contract.methods.userHistory,currWalletAddress);
}

const gameGetSmallGame = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonCallAndHandler(_contract.methods.getSmallGame);
}

const gameGetReward= async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonSend(_contract.methods.getReward);
}

const gameCalcGetReward = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonCallAndHandler(_contract.methods.calcGetReward);
}

// 用户奖励
const gameUserReward = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonCallAndHandler(_contract.methods.userReward,currWalletAddress);
}

// 用户已领取
const gameUserRewarded = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('game')
    return commonCallAndHandler(_contract.methods.userRewarded,currWalletAddress);
}


// node
const buyBigNode = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('node')
    return commonSend(_contract.methods.buyBigNode,);
}

const buySmallNode = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('node')
    console.log(_contract)
    return commonSend(_contract.methods.buySmallNode,);
}

const nodeHistory = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('node')
    return commonCallAndHandler(_contract.methods.getHistory);
}

const nodeCalcReceiveDividends = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('node')
    return commonCallAndHandler(_contract.methods.calcReceiveDividends,currWalletAddress);
}

const nodeReceiveDividends = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('node')
    return commonSend(_contract.methods.receiveDividends);
}


const viewUserNode = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('node')
    return commonCallAndHandler(_contract.methods.viewUserNode,currWalletAddress);
}

// team
const bindRef = async (address_) => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('team')
    console.log(_contract)
    return commonSend(_contract.methods.bindingRelation,address_);
}

const teamHistory = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('team')
    return commonCallAndHandler(_contract.methods.getHistory);
}

const teamUser = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('team')
    return commonCallAndHandler(_contract.methods.user,currWalletAddress);
}
// nft 
const nftCalcTransferableNFT = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('nft')
    return commonCallAndHandler(_contract.methods.calcTransferableNFT,currWalletAddress);
}

const nftCalcCharge = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('nft')
    return commonCallAndHandler(_contract.methods.calcCharge,currWalletAddress);
}

const getBalanceOfBatch = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('nft')
    let user = [currWalletAddress,currWalletAddress,currWalletAddress,currWalletAddress,currWalletAddress,currWalletAddress,currWalletAddress,currWalletAddress,currWalletAddress,currWalletAddress]
    let ids = [0,1,2,3,4,5,6,7,8,9]
    return commonCallAndHandler(_contract.methods.balanceOfBatch,user,ids);
}

const nftSafeBatchTransferFrom = async (to,ids,amounts) => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('nft')
    return commonSend(_contract.methods.safeBatchTransferFrom,currWalletAddress,to,ids,amounts,[]);
}

const getCalcCharge = async () => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('nft')
    return commonCallAndHandler(_contract.methods.calcCharge,currWalletAddress);
}

const nftBuyNFT = async (amount) => {
    if(beforeCheck()) {
        return
    }
    let _contract = contracts('nft')
    return commonSend(_contract.methods.buy,0,amount);
}

//                     Tool
// 当前文件变量
const getConnectedChainId = () => {
    return connectedChainId
}

const getConnectedNetwork = () => {
    return connectedNetwork
}

const getWeb3 = () => {
    return web3
}

const toBigNumber = () => {
    return web3.utils.toBN
}

const getContractsAddress = () => {
    return address
}

const getContracts = () => {
    return contracts;
}

const getCurrWalletAddress = () => {
    return currWalletAddress;
}

const setChain = (_chain) => {
    chain = _chain
}

const getChain = () => {
    return chain;
}

const getTronWeb3 = () => {
    return tronWeb3;
}

const tronGetContractsAddress = () => {
    return tronCurrWalletAddress;
}

export default {
    initWeb3,
    connectMetaMask,
    getConnectedChainId,
    getConnectedNetwork,
    getWeb3,
    toBigNumber,
    getContractsAddress,
    getContracts,
    getCurrWalletAddress,
    connectWallet,
    setChain,
    getChain,
    //tron
    getTronWeb3,
    tronGetContractsAddress,
    initTron,
    // token
    tokenName,
    tokenDecimals,
    tokenSymbol,
    tokenAllowance,
    tokenApprove,
    tokenBalanceOf,
    tokenBalanceOfAddress,
    tokenBalanceOfDecimal,
    tokenTotalSupply,
    tokenDivDecimal,
    // usdt
    usdtName,
    usdtDecimals,
    usdtSymbol,
    usdtAllowance,
    usdtApprove,
    usdtBalanceOf,
    usdtBalanceOfAddress,
    usdtBalanceOfDecimal,
    usdtTotalSupply,
    usdtDivDecimal,
    // game
    playGame,
    gameHistory,
    gameUserHistory,
    gameGetSmallGame,
    gameCalcGetReward,
    gameGetReward,
    gameUserReward,
    gameUserRewarded,
    // node
    buySmallNode,
    buyBigNode,
    nodeHistory,
    nodeCalcReceiveDividends,
    nodeReceiveDividends,
    viewUserNode,
    // team
    bindRef,
    teamHistory,
    teamUser,
    // nft
    nftCalcTransferableNFT,
    getBalanceOfBatch,
    nftSafeBatchTransferFrom,
    getCalcCharge,
    nftBuyNFT,
    nftCalcCharge
}
