module.exports = {
    topBar: {
        linkWallet: "在 Reactor 免费启动你的第一个机器人。",
        linkWallet2: "了解更多",
        unit: "美元",
        h1: "首页",
        h2: "产品",
        h2_1: "k线的回测能力",
        h2_1_1: "策略回测",
        h2_1_2: "进行模拟交易回测",
        h2_1_3: "手动交易",
        h2_1_4: "在链上挂限价单",
        h2_1_5: "K线与链上数据",
        h2_1_6: "实时查阅交易K线与链上数据",
        h2_2: "机器人多种类",
        h2_2_1: "复制机器人",
        h2_2_2: "跟单专业的交易员",
        h2_2_3: "交易机器人",
        h2_2_4: "24小时工作,排除情绪化交易",
        h2_2_5: "做市商机器人",
        h2_2_6: "24小时工作,排除情绪化交易",
        h2_2_7: "套利机器人",
        h2_2_8: "跨交易所套利",
        h2_2_9: "DEMO机器人",
        h2_2_10: "没有风险的获得交易经验",
        h2_3: "模版商城即开即用",
        h2_3_1: "信号商城",
        h2_3_2: "进行更准确的交易决策",
        h2_3_3: "策略商城",
        h2_3_4: "像专业人士一样交易",
        h2_3_5: "机器人商城",
        h2_3_6: "各类自动化机器人",
        h3: "市场",
        h3_1: "支持的Token",
        h3_2: "支持流动性平台",
        h3_2_1: "币安",
        h3_2_2: "Binance 是全球交易量最大的加密货币交易所，截至 2022 年 8 月，Binance 交易…",
        h3_2_3: "火币",
        h3_2_4: "Huobi Global 是一家国际数字资产交易所，业务遍及 100 多个国家。该平台于2013…",
        h3_2_5: "OKX",
        h3_2_6: "OKX 是一个位于塞舌尔的加密货币集中交易(CEX) 和钱包平台，为全球数百万客户…",
        h3_2_7: "PancakeSwap",
        h3_2_8: "PancakeSwap 是一种业内领先的非托管化去中心化交易所的开发者，其专注于先进的加…",
        h3_2_9: "UniSwap",
        h3_2_10: "Uniswap 是一个去中心化交易所(DEX)，它首先引入了自动做市商(AMM) 模型。与…",
        h3_2_11: "DODO",
        h3_2_12: "Dodo 是一个基于以太坊的去中心化交易所，具有“主动做市商”，即自动做市商 (AMM…",
        h3_3: "查看全部受支持的Token",
        h3_4: "更多交易平台",
        h4: "价格",
        h5: "企业",
        h5_1: "关于我们",
        h5_2: "卓越服务，持续创新。与客户紧密合作，提供高质量解决方案。建立长期合作关系，共同成长。",
        h5_3: "OEM",
        h5_4: "专业定制解决方案，满足您的独特需求，为您提供个性化服务。",
        h5_5: "商务运营",
        h5_6: "高效管理和拓展业务，助力您实现持续增长和市场领先。",
        h5_7: "社交媒体",
        h5_8: "发掘我们的社交媒体渠道，追踪最新动态，共同探索行业前沿和创新趋势。",
        h5_9: "合作伙伴",
        h5_10: "携手共创，共享资源和机遇，实现互利共赢，共同迈向成功之路。",
        h5_11: "联系我们",
        h5_12: "与我们取得联系，让我们一起探讨商机并合作开展有益的合作关系。",
        h6: "帮助",
        h7_1: "简体中文",
        h7_2: "英文",
        h7_3: "日文",
        h7_4: "韩文",
        h7_5: "繁体中文",
        h7_6: "泰语",
        h7_7: "马来语",
        h7_8: "越南语",
        h8: "链接钱包",
        h9: "更多",
        h9_1: "返佣",
        h9_2: "通过链接邀请您的朋友加入我们的平台，您将有机会获得额外的佣金回报。",
        h9_3: "钱包",
        h9_4: "安全可靠，方便存储，轻松管理您的数字资产，保障资金安全与隐私保护。",
        h9_5: "定制策略",
        h9_6: "深入了解您的投资目标，为您量身定制符合您需求的投资策略。",
        h9_7: "DCA",
        h9_8: "智能定投，稳健增值，规避市场波动风险，长期持有收益稳定。",
    },
    btm: {
        h1: "产品",
        h2: "钱包",
        h3: '市场 ',
        h4: "信号",
        h5: "链上数据",
        h6: "定制策略",
        h7: "返佣",
        h8: "机器人",
        h9: "交易机器人",
        h10: "DEMO机器人",
        h11: "复制机器人",
        h12: "套利机器人",
        h13: "做市商机器人",
        h14: "关于我们",
        h15: "OEM",
        h16: "广告合作",
        h17: "商务运营",
        h18: "社交媒体",
        h19: "合作伙伴",
        h20: "社区",
        h21: "服务",
        h22: "下载",
        h23: "服务协议",
        h24: '隐私协议',
        h25: "风险提示",
        h26: "帮助中心",
        h27: "关注我们",
        h28: "本地下载",
        h29: "企业信用评价AAA级信用企业",
        h30: "招标投标领域国家级荣誉证书",
        h31: "中华人民共和国商务部诚信档案企业",
        h32: "诚信经营示范企业",
        h33: "粤公网安备44030502009469号 粤ICP备2022043961号-1 增值电信业务经营许可证 B1-20214279 粤网文:（2018）5045-1753号 © 2018 - 2023 TIANYI. ALL RIGHTS RESERVED",
        h34: "桌面客户端",

        A1: '策略回测',
        A2: '手动交易',
        A3: 'K线与链上数据',
        A4: '信号商城',
        A5: '策略商城',
        A6: '交易机器人',
        A7: 'DEMO机器人',
        A8: '复制机器人',
        A9: '套利机器人',
        A10: '做市商机器人',
        A11: '关于我们',
        A12: 'OEM',
        A13: '社交媒体',
        A14: '联系我们',
        A15: '隐私政策',
        A16: '服务协议',
        A17: '免责声明',
        A18: '帮助中心',
        A19: '定制策略',
        A20: '返佣',
        A21: '钱包',
        A22: 'DCA',
        A23: '下载',
        A24: "更多",
    },
    home: {
        h1: "自动交易，低买高卖",
        h2: "聚集诸多功能的智能交易终端",
        h3: '可定制的数字货币交易机器人 ',
        h4: "免费体验",
        h5: "支持中心化交易所&去中心化交易所 ",
        h6: "多元化投资",
        h7: "专业量化",
        h8: "指标参数",
        h9: "交易数据",
        h10: "邀请返佣",
        h11: "连接您的钱包和交易所账户 并使用我们的交易终端管理它们",
        h12: "我们深知多元化投资对于投资组合的重要性。通过Reactor您可以轻松地同时管理多个交易所和钱包，更加灵活地分散资金，将投资分布在不同的资产和市场上，有效降低单一资产或市场的风险。",
        h11_1: "精准分析，卓越交易效果",
        h12_1: "Reactor可以进行精准的市场分析和交易策略优化，实现卓越的交易效果。利用先进的算法和数据指标提高交易效率，从而更好地掌控市场，获得更稳定的收益。",
        h11_2: "灵活定制，优化交易指标",
        h12_2: "为您提供灵活的指标参数设置，根据个人需求定制化交易策略。通过调整指标参数来优化交易表现，更好地适应市场变化，实现个性化的交易体验，获得更好的投资回报。",
        h11_3: "实时监控，精准把握市场动态",
        h12_3: "通过实时数据，您可以更准确地做出交易决策，及时捕捉投资机会。不再错过任何重要时刻，通过数据监控，让您精准把握市场动态。",
        h11_4: "分享赚钱，邀请奖励",
        h12_4: "通过邀请好友参与平台，获得至高15%的邀请奖励。通过邀请反佣功能，您与好友一起分享交易乐趣的同时，还可以获得可观的反佣收益。",
        h13: "了解更多",
        h14: "支持网络",
        h15: "支持的",
        h16: "流动性平台",
        h17: "加密货币",
        h18: "处理的",
        h19: "交易订单",
        h20: "让您像专业人士一样交易的理想之选",
        h21: "在 Reactor 上使用聚合流动性平台进行交易加密货币，在链上进行市价单和限价单交易，执行自动化交易策略，查看交易数据。",
        h22: "专业的团队和技术",
        h23: "我们致力于提供透明、可靠的服务，始终把客户的利益放在首位。我们的投资策略建立在充分的研究和尽职调查的基础上，以确保为您提供最佳的投资机会。",
        h24: '您的资金和算法是安全的',
        h25: "钱包",
        h26: "我们不会记录您的私钥信息，机器人将严格遵守交易所和钱包的安全标准",
        h27: "身份验证",
        h28: "采取多重身份验证和加密措施，确保您的资产和交易信息的安全",
        h29: "安全交易",
        h30: "您可以放心地使用我们的机器人进行交易，享受便捷和安全的投资体验。",
        h31: "加密货币交易终端便捷管理所有加密资产组合",
        h32: "我们的交易终端汇聚了多个交易所和钱包的功能，让您能够在一个统一的界面上进行资产管理和交易操作。",
        h33: "一起探索区块链的无限可能",
        h34: "我们提供全面的区块链解决方案和专业的咨询服务。我们的团队拥有丰富的区块链经验和专业知识，帮助您理解区块链的核心概念，掌握关键技术和工具，并探索如何将区块链应用于实际场景。",
    },
    about: {
        h1: "TIANYI 是一家集服务器/网络等底层到应用层数字化工程解决方案开发提供商",
        h2: "我们是一个来自世界各地的数字创意团体。",
        h3: '我们的团队由一群富有经验、充满激情的专业人士组成。我们多项领域的专业解决方案和创意服务，包括应用程序开发、Web3、视觉设计与创意、应用设计与策划、服务器/网络与机房、营销与办公系统。 ',
        h4: "一站式服务",
        h5: "我们提供全方位的一站式服务，涵盖了从规划、设计、开发到运营的所有领域。",
        h6: "全球标准",
        h7: "全球标准设计，采用无代码工具，并积极引入NFT和元界等Web3tran技术。",
        h8: "服务器和IDC网络",
        h9: "我们提供高性能的服务器解决方案和可扩展的IDC网络架构，确保您的业务运行平稳、安全可靠。",
        h10: "使命、愿景和价值观",
        h11: "使命",
        h12: "用数字创意的力量，解决问题，为社会做出持久贡献。我们的使命是通过创新和创意，为社会提供有意义的解决方案，推动社会进步。",
        h13: "联系我们，或者预约参观我们的办公室",
        h14: "深圳市宝安区新安街道宝民一路宝通大厦608",
        h15: "立即咨询",
    },
    // chainData:{
    //     h1:"展现透明可信的链上数据",
    //     h2:"发掘区块链的无限潜能",
    //     h3:'查看详情',
    //     h4:"在区块链的未来中掌握先机！",
    //     h5:"我们的产品为您提供了一个独特的机会，让您深入了解和展示区块链的无限潜能和透明性。",
    //     h6:"深入了解交易历史",
    //     h7:"通过链上数据的展示和分析，您可以追踪和分析各种交易，了解市场趋势和交易者行为",
    //     h8:"资产流动追踪",
    //     h9:"通过链上数据的可视化，您可以跟踪资产的转移路径、持有者变动等重要信息。",
    //     h10:"智能合约分析",
    //     h11:"我们的工具允许您深入研究和分析智能合约的执行情况，帮助您评估合约的安全性和可靠性。",
    // },
    // K线与链上数据
    chainData: {
        h1: "K线与链上数据",
        h2: "洞察市场趋势的完美组合",
        h3: '融合历史行情与实时区块链信息',
        h4: "优化您的交易信息",
        h5: "查看详情",
        h6: "融合K线与链上数据的卓越优势",
        h7: "在Reactor实时洞察市场，精准决策交易",
        h8: "显示历史价格走势，并追踪区块链网络中的交易数据",
        h9: "查看最新的区块链交易记录区块高度和交易确认时间",
        h10: "基于K线和链上数据的分析生成准确的交易信号",
        h11: "直观的图表和图形方式展示K线和链上数据",
        h12: "查看持仓情况、资产分配比例、每个资产的市值变化",
        h13: "还有Reactor特有的",
        h14: "提升交易决策的洞察力和效率",
        h15: "多链",
        h16: "可定制化",
        h17: "信号",
        h18: "Reactor支持多条区块链网络上的交易。如以太坊、波场、币安智能链等",
        h19: "您可以自定义交易策略、风控规则和考数设遭，从而实现个性化的交易体验",
        h20: "在K线图上显示点位、交易建议和操作提示，应观的了解信号与市场的关系",
        h21: "深入洞察区块链的实时数据",
        h22: "通过Reactor，深入了解链上数据的关键内容和意义",
        h23: '实时市场数据',
        h24: "区块链交易追踪",
        h25: "智能合约分析",
        h26: "地址和余额查询",
        h27: "历史数据回溯",
        h28: "自定义指标分析",
        h29: "包括加密货币价格、交易量和市值等关键指标，帮助您全面了解市场的动态。",
        h30: "追踪和显示交易历史，包括交易时间、交易对、交易量和交易方向。",
        h31: "帮助您深入了解和评估不同项目的智能合约的安全性和可靠性。",
        h32: "您可以输入地址查询任何加密货币的余额和交易记录。",
        h33: "展示历史加密货币的价格走势、交易量变化等数据",
        h34: "您可以根据自己的需求和策略，创建个性化的指标，并应用于K线或链上数据。",
        h35: "为交易而生",
        h36: "实时K线图、精准指标和智能分析",
        h37: "币种信息",
        h38: "图表交易",
        h39: "智能分析",
        h40: "币种名称、发行总量等基本信息和价格、市值、24小时交易量等重要指标",
        h41: "可以百接在K线图右侧进行交易，从中洞察市场的机会井做出相应的交易决策",
        h42: "信号可以识别价格的趋势模式、形态结构，以及市场的阻力和支撑位。",
    },
    community: {
        h1: "加入Reactor社区",
        h2: "获取最新的Reactor活动资讯和在线支持",
        h3: 'Telegram',
        h4: "量化社区",
        h5: "Twitter",
        h6: "华语社区",
        h7: "Instagram",
        h8: "Facebook",
        h9: "Telegram",
        h10: "获得专业工作人员的支持和解答",
        h11: "我们的社区拥有经验丰富的专业工作人员，他们对加密市场和量化交易有着深入的了解。作为社区会员，您可以与我们的专家团队直接互动，提出问题、寻求建议和解答。无论是关于市场分析、交易策略还是技术支持，我们的工作人员将竭诚为您提供帮助，确保您在量化交易的道路上始终走在正确的方向。",
        h12: "捕捉加密市场趋势，探索新的量化策略",
        h13: "加入我们的社区，您将有机会捕捉加密市场的最新趋势和动态。我们提供先进的量化交易工具和技术，帮助您分析市场数据、制定策略并实时执行交易。通过与其他会员的交流和分享，您可以探索新的量化策略，拓展投资视野，并在不断变化的市场中获取竞争优势。",
        h14: "自由分享，结识新的加密朋友",
        h15: "我们的社区是一个自由分享和互助的平台。作为会员，您可以分享您的交易经验、洞察和策略，与其他会员进行互动和讨论。通过与其他志同道合的加密爱好者和专业交易者交流，您可以扩大您的人脉圈并结识新的加密朋友。在这个积极和合作的社区中，您将有机会从他人的经验中学习，共同成长，并创造更多的交易机会。"
    },
    cooperation: {
        h1: "全球公司合作伙伴",
        h2: "Tianyi 在中国、亚洲、中东、东南亚、等世界各地拥有众多合作伙伴公司。我们与世界各地的合作伙伴 公司一起提供新技术解决方案。",
        h3: '深圳',
        h4: "总公司",
        h5: "新加坡",
        h6: "东南亚",
        h7: "成都",
        h8: "分公司",
        h9: "武汉",
        h10: "日本",
        h11: "亚洲",
        h12: "越南",
        h13: "湖南",
        h14: "河南",
        h15: "韩国",
        h16: "柬埔寨",
        h17: "海南",
        h18: "迪拜",
        h19: "中东",
        h20: "泰国"
    },
    // copyRobot:{
    //     h1:"Reactor 的复制机器人",
    //     h2:"您无需自己开发策略即可参与到有利可图的交易中",
    //     h3:'查看更多',
    //     h4:"您可以选择信号提供者并自动复制其交易策略。这意味着您可以跟随优秀交易者的步伐，享受他们的交易经验和成功。",
    //     h5:"自动将选定交易者的交易行为复制到投资者的交易账户上",
    //     h6:"灵活的配置选项，如设定复制比例、设置风险控制参数等",
    //     h7:"风险管理功能和自动停损机制",
    //     h8:"多样化的复制选项",
    //     h9:"提供多样化的复制选项，可以根据投资者的需求和偏好选择不同的复制方式",
    //     h10:"实时同步交易",
    //     h11:"实时同步交易者的交易行为，确保投资者能够及时跟随并复制交易者的操作。",
    //     h12:"多平台支持",
    //     h13:"投资者可以在自己熟悉和信任的交易所上进行交易，并享受复制交易的便利和优势。",
    // },
    // 复制机器人
    copyRobot: {
        h1: "跟随顶级交易者的超凡表现",
        h2: "通过Reactor获取顶级交易者的独特交易策略和卓越表现",
        h3: '查看更多',
        h4: "独特的交易策略",
        h5: "与顶级交易者直接合作的机会",
        h6: "提高自己的交易成功率和绩效",
        h7: "适当的资金分配，实现更好的风险管理",
        h8: "更广泛和多样化的交易策略选择",
        h9: "了解详情",
        h10: "为此，我们提供了",
        h11: "完全自动地盟随交易者的买入和卖出行为",
        h12: "个性化的调整参数，如止盈、止报等",
        h13: "查看买入和卖出的资产、交易记录",
        h14: "根据自己的凤险承受能力进行资金配置和风险管理",
        h15: "查看更多功能",
        h16: "为您提供详细数据和信息",
        h17: "轻松寻找并跟随优秀交易者，获得稳定的投资回报",
        h18: "机器人卖方信息透明",
        h19: "每周的交易结果汇总",
        h20: "平均的利润",
        h21: "用户可以根据卖方的在步状杰和最后上埃时间来判断其活跃程度，区时通过挥供的郎箱联采方式与卖方进行进一步的合作",
        h22: "复制机器人恐为现探供每周的交易结果汇总，包括总利润、买入次数、卖出次数等，帮助您及时了解和评估交易的表现。",
        h23: "复制机器人将计算并展示您的平均利润，以图表的形式展现，让您了解每笔交易的平均收益以及预期收益。",
        h24: "查看上个月的表现报告",
        h25: "胜率、负率、盈亏比",
        h26: "交易币种和交易次数",
        h27: "包括总资产走势图表、每笔交易的最佳利润、最大回撤等数据，以帮助您了解过去一个月的投资情况。",
        h28: "复制机器人将为您展示交易者的胜率、负率和盈亏比等重要指标，帮助您评估交易策略的稳定性和盈利能力",
        h29: "复制机器人将提供您复制的交易币种列表以及总交易次数的统计，让您了解您参与的交易品种和交易频率。",
    },
    dca: {
        h1: "量化之旅从定投策略(DCA)出发",
        h2: "定期投资资金来购买加密货币无论市场价格如何波动",
        h3: '查看更多',
        h4: "什么是DCA？",
        h5: "DCA是一种长期投资策略，通过定期投资相同金额的资金来购买加密货币，无论市场价格如何波动。这种策略可以帮助投资者分散风险、减少市场波动的影响，并长期受益于资产增值。您可以轻松设置定投计划，选择您感兴趣的加密货币和投资周期。系统将自动按照您设定的计划，在指定的时间间隔内执行交易，并根据市场价格智能购买加密货币。灵活调整定投计划，根据市场变化和个人需求进行优化。",
        h6: "在 Reactor 使用DCA策略有什么优势？",
        h7: "稳定投资",
        h8: "DCA通过定期投资固定金额，无论市场价格如何波动，都能保持稳定的投资节奏。这种稳定性可以帮助投资者摊平市场的波动，减少投资的风险。",
        h9: "平均成本",
        h10: "DCA策略通过定期投资，可以实现“买入成本平均化”。当市场价格下跌时，您会购买更多的资产单位，而当市场价格上涨时，您购买的资产单位会减少。这种方式可以帮助您在市场波动中平均购买资产，降低买入价格的风险。",
    },
    // DEMO机器人
    demoRobot: {
        h1: "实时模拟交易，零风险体验",
        h2: "DEMO机器人帮您探索交易世界",
        h3: '查看更多',
        h4: "为什么要用DEMO机器人",
        h5: "DEMO机器人提供了一个无风险的环境，供用户学习、实践和测试量化交易策略。它们可以帮助用户提高交易技能，评估机器人的性能，优化交易策略，并增强对市场的理解。通过使用DEMO机器人，用户可以在实际交易之前建立信心和经验，从而更好地应对真实市场中的挑战。",
        h6: "零风险模拟交易",
        h7: "DEMO机器人允许您以虚拟资金进行模拟交易，无需使用真实资金。您可以在真实市场条件下进行交易操作，体验实时价格波动，了解交易的实际运作方式，而无需承担任何实际风险。",
        h8: "模拟交易参数和历史记录",
        h9: "DEMO机器人会记录您的模拟交易历史，包括买入、卖出、止损、止盈等操作。您可以查看历史记录，分析交易的结果和绩效，评估您的交易策略和技巧，并进行必要的调整和改进。",
        h10: "策略优化和调整",
        h11: "通过DEMO机器人，用户可以进行策略的优化和调整。DEMO机器人会生成实时报告和分析，包括交易历史、收益曲线、交易统计等数据。您可以通过报告和分析了解您的交易表现、收益情况以及其他关键指标。",
    },
    download: {
        h1: "开始免费使用 Reactor 进行交易！",
        h2: "完美匹配Windows与Mac，支持全业务功能",
        h3: 'Reactor 电脑版',
        h4: "下载Windows与Mac OS客户端，享受专业的交易服务。",
        h5: "本地下载",
        h6: "为什么选择Reactor？",
        h7: "保护您的资产安全",
        h8: "Reactor采用最高级别的安全协议和加密技术，确保您的数字资产始终得到最强大的保护。",
        h9: "洞察市场趋势",
        h10: "Reactor提供全面的数据统计和分析工具，帮助您深入了解市场趋势和价格波动。并根据市场情况调整您的交易策略。",
        h11: "迅捷高效的交易体验",
        h12: "Reactor提供高速的交易执行和订单处理，确保您能够快速响应市场变化，抓住交易机会。",
    },
    exchange: {
        h1: "币安",
        h2: "聊天",
        h3: '什么是币安？',
        h4: "Binance 是全球交易量最大的加密货币交易所，截至 2022 年 8 月，Binance 交易所的每日交易量为 760 亿美元，在全球拥有 9000 万客户。该平台已成为加密领域值得信赖的成员，用户可以在其中购买、出售和存储他们的数字资产，以及访问列出的 350 多种加密货币和数千个交易对。Binance 生态系统现在包括 Binance Exchange、Labs、Launchpad、Info、Academy、Research、Trust Wallet、Charity、NFT 等。",
        h5: "Reactor如何在币安管理您的账户？",
        h6: "通过 Reactor 平台的 API，无缝连接交易所，实现自动化交易。借助我们强大的 API 功能，您可以方便地与多个交易所进行关联，自动执行交易策略，实现高效的交易操作。充分利用 API 的便利性，轻松管理您的交易账户，并实现更好的投资结果。",
        h7: "谁是币安创始人？",
        h8: "这家全球公司由赵长鹏和何一在中国共同创立。赵长鹏（CZ）是加拿大华裔开发商和业务主管，是该公司的首席执行官。他曾就读于麦吉尔大学蒙特利尔分校，并拥有成功的企业家记录。他过去的经历包括 Bloomberg Tradebook 期货研发团队负责人、Fusion Systems 创始人和 Blockchain.com 技术负责人。 Yi He 是 Binance 的首席营销官，也是该公司风险投资部门 Binance Labs 的负责人。曾任领先移动视频科技公司一侠科技的副总裁，以及数字资产交易所 OKCoin 的联合创始人。",
        h9: "币安什么时候推出的？",
        h10: "Binance 于 2017 年 6 月推出，并在 180 天内成长为全球最大的加密货币交易所。",
        h11: "币安受限国家？",
        h12: "根据币安使用条款，受限地区包括美国、新加坡和安大略省（加拿大）。但是，某些国家/地区限制使用或功能可能由于监管原因而受到限制，包括但不限于中国、马来西亚、日本、英国和泰国。德国、意大利和荷兰也不提供期货和衍生产品。2019 年 9 月，为美国客户推出了一个单独的专用平台 Binance.US。",
        h13: "币安费用是多少？",
        h14: "该平台用户友好，是最便宜的平台之一，提供大量交易类型选择，并为经验丰富的投资者提供一套先进的交易工具。它基于分级系统收费，从普通用户到VIP 9。对于普通用户，现货交易收取0.10%的maker taker费用。2022 年 7 月，币安宣布 BTC 现货交易对零手续费交易，并于 8 月宣布 ETH/BUSD 交易对零手续费交易。",
        h15: "是否可以在 Binance 上使用杠杆或保证金交易？",
        h16: "交易者可以借入资金并参与币安保证金的保证金交易，这允许以高达 10 倍的杠杆率交易加密货币。用户还可以使用以 USDT、BUSD 或其他加密货币结算的币安合约等衍生产品和币安期权来进行交易。",

        A0: 'OKX',
        A1: '什么是 OKX？',
        A2: 'OKX 是设立于塞舌尔的加密货币中心化交易所 (CEX) 和钱包平台，服务于全球数百万客户。该交易所提供包括现货和简单期权在内的基本交易，以及包括保证金、期货、永续合同和期权在内的衍生品。其他产品包括：交易机器人、大宗交易、OKX Earn（储蓄、稳定币、DeFi、质押、ETH 2.0等）、加密货币贷款和交易所的启动平台Jumpstart。',
        A3: 'OKX钱包声称是“Web3门户”，提供加密热门钱包、去中心化交易所(DEX)、NFT市场和去中心化应用程序 (DApp)。该钱包支持超过 30 种网络，包括主要区块链，如比特币、以太坊、BNB 链、Solana、Polygon、Avalanche、Fantom等。',
        A4: 'OKX也有自己的原生区块链 —— OKX 链与原生代币 OKB，它允许创建者和项目部署DApp，并允许开发人员使用OKX Oracle等基础架构。',
        A5: '2022年1月，OKEx更名为OKX，有了新的品牌和路线图。',
        A6: 'OKX 的创始人是谁？',
        A6: 'OKX（原OKEx）是 OK 集团旗下 OKCoin 的子公司。',
        A7: '该公司由 Mingxing “Star” Xu 于2013年在中国创立。Xu是一名中国企业家。他拥有北京科技大学应用物理学学士学位。Star Xu是OK集团的首席执行官。',
        A8: 'OKX 的现任首席执行官是 Jay Hao。',
        A9: 'OKX 于何时推出？',
        A10: '该交易所（前身为 OKEX）于2017年推出。',
        A11: 'OKX 位于何处？',
        A12: '公司总部设在塞舌尔。',
        A13: '限制使用 OKX 的国家',
        A14: '该项目可在 200 个以上国家使用。然而，美国居民无法使用该平台的服务。',
        A15: 'OKX 可支持哪些币种？',
        A16: '有 350 种以上的加密货币在该交易所上架，其支持的交易对在 500 种以上。该平台上架的代币包括BTC、ETH、OKB、AAVE、SOL、MATIC、XRP、DOGE、SHIB和DOT。',
        A17: 'OKX 的手续费如何？',
        A18: '该交易所采用挂单吃单费用模式。平台上的交易费用起始于0.10%，并随着交易量的增加而降低。对于普通用户而言，其手续费取决于OKX生态系统中OKB（原声代币）的数量，而对于高级用户而言，手续费是基于他们30天的交易量计算的。',
        A19: 'OKX 上可使用杠杆或保证金交易吗？',
        A20: 'OKX的保证金交易最高杠杆为10倍。在衍生品方面，OKX 提供最高 125 倍的期货交易、永续合约掉期杠杆。此外，交易者还可以通过加密期权，包括BTC、ETH等进行杠杆操作。',


        B0: '火币',
        B1: '什么是 Huobi Global？',
        B2: 'Huobi Global是一个国际数字资产交易所，在全球 100 多国均可使用。该平台于2013年在中国成立，为全球用户提供交易和投资服务。其每天的交易量达40亿美元。',
        B3: '该中心化交易所 (CEX) 支持首次代币发行 (ICO)，也上架了多种加密货币。该平台上有超过 500 种资产与 300 种以上交易对。用户可以使用保险政策来保护他们的资产。该交易所拥有多个全年无休的客服支持渠道，让客户享受一流的专业服务。',
        B4: '该交易所的生态系统包括现货交易、保证金交易、期货交易、衍生品交易、质押、加密货币贷款等。活跃交易者与投资人可在场外交易 (OTC) 市场平台寻觅机会，并使用各种自定义交易工具。',
        B5: 'Huobi Global 的创始人是谁？',
        B6: '该交易所由李林 (Leon Li) 于 2013 年在北京创办。其首席执行官李林于2013年毕业于清华大学的自动化专业。在创办 Huobi Global 前，李林在美国最大的级软件公司之一——甲骨文担任计算机工程师。',
        B7: 'Huobi Global 位于何处？',
        B8: '该公司总部位于塞舌尔群岛，并在香港、韩国、日本和美国设有办事处。',
        B9: '限制使用 Huobi Global 的国家',
        B10: '来自下列国家的用户不得使用 Huobi Global 提供的所有服务：中国、美国、加拿大、日本、古巴、伊朗、朝鲜、苏丹、叙利亚、委内瑞拉、新加坡和克里米亚。',
        B11: '下列国家/地区的用户无法使用衍生品交易服务：中国大陆、台湾、香港、以色列、伊拉克、孟加拉国国、玻利维亚、厄瓜多尔、吉尔吉斯斯坦、塞瓦斯托波尔、西班牙、英国（仅限零售用户）和新西兰。',
        B12: '日本用户可以使用 Huobi Japan 交易所的服务。此外，该公司也宣布重回美国市场的计划。',
        B13: 'Huobi Global 可支持哪些币种？',
        B14: '该交易所支持 400 种以上数字资产与加密货币，包括最热门的：BTC、DOGE、ETH、LTC、XMR、HT、HUSD、USDT。',
        B15: '截至 2022 年 8 月，该交易所支持的法币包括 ALL、BGN、CHF、CZK、DKK、EUR、GBP、HRK、HUF、MDL、MKD、NOK、PLN、RON、SEK、TRY、UAH、HKD、AUD、USD。',
        B16: 'Huobi Global 的手续费如何？',
        B17: 'Huobi 手续费具有等级架构，交易费用视个别用户的月交易量而定。该平台收取基本挂单吃单费用 0.2%，交易量达 500 万美元可享折扣；而 Huobi 代币 (HT) 的持有者亦可享受折扣。专业交易者可享受以下优惠：挂单费 0.0362% 起，吃单费 0.0462% 起。',
        B18: 'Huobi Global 上可使用杠杆或保证金交易吗？',
        B19: '该平台可针对多种加密货币使用保证金交易，让用户借用资金进行最多 3 倍杠杆的全仓模式保证金交易。其利率按小时计算。',
        B20: '用户可以使用最高 200 倍杠杆，进行比特币和以太币加密期货合约交易。',


        C0: 'PancakeSwap',
        C1: 'PancakeSwap简介',
        C2: 'PancakeSwap是一种热门的去中心化交易所 (DEX)，它可允许用户在BSC 链上进行 BEP-20 代币兑换。其为 BSC 链生态系统上最大的交易所，且从市场占有率而言，也是全球热门的去中心化交易所 (DEX) 之一。该交易所采用自动做市商 (AMM) 模式，让用户能够与流动性池进行交易。您可以成为流动性提供者，并获得流动性提供 (LP) 代币，便可分得交易所的交易手续费。',
        C3: '此外，LP 代币持有者也可以参与流动性挖矿，以赚取该交易所的实用代币——CAKE。该代币可以在所谓的“糖浆池”中进行定期或活期质押。活期质押用户可随时取消质押，而定期质押会将报酬率拉到最大值（质押期最长为 52 周）。',
        C4: '此外，PancakeSwap也会提供彩票抽奖游戏，让其用户可以赢取 CAKE 奖金。在 NFT 市场中，用户可以交易收藏品，并且也可通过 NFT 个人资料系统中设定个性化的个人简介。另外，也有一个与 ApolloX 共同开发的永续交易所，它包含多种热门代币交易对，并可提供无限期的期货交易服务。用户可在此交易所中利用比账户余额还多的资金杠杆，来进行热门代币对的交易。',
        C5: 'PancakeSwap的创始人是谁？',
        C6: 'PancakeSwap为匿名人士创办，其带领二十多名匿名“厨师”，在交易所的“厨房”内工作。该交易所是开源的，并已通过 Certik 和 Slowmist 等知名安全公司的审计。',
        C7: 'PancakeSwap于何时推出？',
        C8: 'PancakeSwap于 2020 年 9 月推出。',
        C9: 'PancakeSwap位于何处？',
        C10: '由于其为去中心化交易所，因此其运营团队都采取了远程工作的模式。不过据 Crunchbase 报道，PancakeSwap 总部位于日本福冈。',
        C11: '限制使用 PancakeSwap 的国家',
        C12: '由于其为去中心化交易所，因此不存在国家限制。不过，下列国家已受到地理封锁：古巴、伊朗、津巴布韦、伊拉克、克里米亚、叙利亚、苏丹、白俄罗斯和刚果民主共和国。',
        C13: 'PancakeSwap支持的代币列表',
        C14: '所有的 BEP-20 代币皆可交易，此外也支持多种热门期货交易对，例如 BTC/USDT、ETH/USDT、BNB/USDT 与其他所有大型第一层区块链。',
        C15: 'PancakeSwap的手续费如何？',
        C16: '在撰写此文时，代币兑换会收取 0.25% 的交易费用。其中，0.17% 会当做奖励回到流动性池，0.03% 作为储备金，剩下的 0.05% 用于回购与销毁。',
        C17: 'PancakeSwap上可使用杠杆或保证金交易吗？',
        C18: '对于最大型的交易对，其永续期货交易所可允许使用 150 倍杠杆，而对于较小型的交易对，上限则为 50 倍。',


        D0: 'UniSwap',
        D1: '什么是 Uniswap？',
        D2: 'Uniswap 于 2018 年推出，是最受欢迎的加密货币去中心化交易所 (DEX) 之一。该交易所率先引入了自动做市商 (AMM) 模型，以取代仍在其他交易所中使用的传统订单簿模式。Uniswap 在以太坊区块链上运作，通过多个智能合约来安全地在用户之间兑换 ERC-20 代币。',
        D3: '该协议的去中心化属性，使得没有单一集中式机构来运营管理该交易所；相对的，兑换也是以点对点 (P2P) 的方式进行的。此外，Uniswap 也致力于解决其他交易所带来的流动性问题。Uniswap V3 是该协议的第三版。该更新后协议的不但提高了补偿力度，且让个人流动性提供者拥有更大的控制权与灵活度。Uniswap V3 占所有去中心化交易所交易量的 46.5%，此外，该协议也在 2022 年 5 月创下了累计交易量达 1万亿美元的纪录。',
        D4: 'Uniswap V3 的创始人是谁？',
        D5: 'Hayden Adams 创办了 Uniswap，他之前就读于 Stony Brook（纽约州立大学石溪分校），之后在西门子担任机械工程师。Adams 受到了Vitalik Buterin 一篇博客文章与一篇 Reddit 贴子的启发，并在得到以太坊基金会的资助后，于 2018 年 11 月推出该协议。Uniswap Labs 先从 Paradigm 募得种子轮资金后，接着又在 2020 年 8 月通过Andreessen Horowitz 领投的 A 轮中募得 1,100 万美元。',
        D6: 'Uniswap V3已于2021年5月发布，距离第一版发布已经过去了将近2.5年，距离Uniswap V2发布也已经过去了一年。',
        D7: 'Uniswap 位于何处？',
        D8: '负责开发 Uniswap 协议与 Uniswap 界面的 Uniswap Labs 位于美国纽约市。作为一种去中心化金融(DeFi)协议，全世界范围内任何能够上网的个人均可使用它。不过，有些国家的用户受到了一些限制。截至 2022 年 7 月，Uniswap 禁止 10个国家的用户使用其协议。这些国家包括：白俄罗斯、古巴、伊朗、朝鲜、叙利亚、科特迪瓦、利比里亚、苏丹、津巴布韦和伊拉克。',
        D9: '哪些加密货币可在 Uniswap 交易？',
        D10: '由于是去中心化的点对点协议，任何人都可在 Uniswap 上架任何代币。截止本文撰写时，最热门的交易对包括USDC、Wrapped BTC、WETH和DAI。',
        D11: 'Uniswap 的手续费如何？',
        D12: 'Uniswap 的流动性提供者能够以 0.05%、0.30%、和 1% 的三种费用等级开始建立流动性池。通过 UNI 治理提案，可能会新增更多的费用等级。',
        D13: 'Uniswap 上可使用杠杆或保证金交易吗？',
        D14: 'Uniswap 平台不提供杠杆或保证金交易。',


        E0: 'DODO',
        E1: '什么是 Dodo？',
        E2: 'Dodo 是一种具有“主动做市商”（即为自动做市商 (AMM) 的定制版本）功能且基于以太坊的去中心化交易所。Dodo提供了一种在区块链上发行和交易数字资产的方法。该交易所提供的流动性，有自有以及聚合于其他交易所两种。据其白皮书指出，这可让该交易所提供全球最佳的 Web3 资产价格。',
        E3: '用户可以通过市价单或限价单，以免许可的方式兑换不同代币。同时，Dodo 也提供免 gas 费用的兑换路由，并通过专业做市商提供更高的流动性（零滑点且免 gas 费）。除此以外，还有一个可交易 ERC-721 与 ERC-1155 代币的 NFT 市场。其流动性提供者计划是在该交易所上赚钱的方法之一，用户可以添加流动性，以取得 LP 代币并',
        E4: '赚取交易费用分成。另一种方式是挖矿。用户挖掘单一代币，或是将资金加进指定流动性池中以进行 LP 做市挖矿。当然，用户也可以通过更有效率的方式进行闪电贷款或配置资本。',
        E5: '最后，该交易所也提供工具，让开发者创建代币。该交易所支持的区块链包括以太坊、BNB Chain、Polygon 与 OKChain。',
        E6: 'Dodo 的创始人是谁？',
        E7: '根据 CrunchBase 上的信息，Dodo 是由两名中国人 Qi Wang 与 Shichi Dai 创办的。该项目从17家不同的风险投资公司共筹集了500万美元，其中包括Coinbase ventures、Defiance Capital和Galaxy Digital。',
        E8: 'Dodo 于何时推出？',
        E9: 'Dodo 于2020年8月推出。',
        E10: 'Dodo 位于何处？',
        E11: '根据 CrunchBase 的信息，该交易所的总部位于香港。',
        E12: '限制使用 Dodo 的国家',
        E13: '本文撰稿之时，并没有任何关于限制使用该交易所国家的信息。',
        E14: 'Dodo 支持的代币列表',
        E15: 'Dodo已经部署在几乎所有与EVM兼容的区块链上，如 arbitrrum、optimistic、Avalanche和 Aurora。因此，该交易所支持所有区块链上的多种不同的代币，包括所有主要的代币，以及包装以太币 (Wrapped Ether) 和 包装比特币 (Wrapped Bitcoin)。',
        E16: 'Dodo 的手续费如何？',
        E17: 'Dodo 对创建新代币收取少量的使用费 (0.02 ETH)，但未提供任何关于交易费用的信息。',
        E18: 'Dodo 上可使用杠杆或保证金交易吗？',
        E19: '该平台目前没有保证金交易功能，但该项目的发展蓝图中指出，其将于 2022 年推出杠杆交易服务。',


        F2: '关于Curve (Ethereum)',
        F3: 'Curve Finance 简介',
        F4: 'Curve Finance 是一种去中心化的稳定币交易流动性池。它不使用订单簿，而是利用自动做市商 (AMM) 模型来匹配流动性。由于本协议是去中心化且免信任的协议，所以任何人都可以向一或多个流动性池提供流动性。该 AMM 的恒定乘积公式，不但能确保最高效率，也为交易者带来最小的滑点。',
        F5: 'Curve 池是一种执行智能合约的 StableSwap 变体，可用以交易多种代币。可通过稳定币交易对 (普通池) 或包装代币来进行代币兑换，且底层的抵押品则在另一个协议 (贷出池) 中贷出。最后，Metapools将稳定币与来自另一个池的 LP 代币进行配对。',
        F6: '多条区块链均支持该协议，如以太坊、Arbitrum、Aurora、Avalanche、Fantom、Harmony、Optimism、Polygon、xDai、Moonbeam。若需要使用 Curve 协议，用户一般必须从以太坊桥接资金到这些区块链。由于本协议对去中心化金融 (DeFi) 的系统化重要性，因此本协议也在所谓的 Curve 战争中引来多组其他协议来争夺其治理权。',
        F7: 'Curve Finance 的创始人是谁？',
        F8: 'Curve Finance 由加密货币底层架构协议 NuCypher 的前任共同创始人兼首席技术官 Michael Egorov 创立。此外他也创建了一种去中心化的银行与借贷网络——LoanCoin。他在跨入加密货币领域前，于莫斯科物理技术学院与 Swinburne 科技大学求学。',
        F9: 'Curve Finance 于何时推出？',
        F10: 'Curve Finance于 2020 年 6 月推出，即在所谓的“去中心化金融（DeFi）之夏”。因此，它可说是新生的DeFi产业的基石之一。',
        F11: 'Curve Finance 位于何处？',
        F12: '根据 CBInsight 的消息，Curve Finance 的总部设于瑞士。',
        F13: '限制使用 Curve Finance 的国家',
        F14: '截止本文撰稿之时，并没有任何关于限制使用 Curve Finance 的国家的信息。不过，受美国金融制裁的国家，其用户可能受到地理位置封锁的限制。',
        F15: 'Curve Finance 支持的代币列表',
        F16: '该交易所支持多种主流稳定币的流动性池，例如DAI、USDC、USDT、FRAX和TUSD。此外，它同时也支持包装代币的兑换，例如wBTC、wETH和stETH（其为一种原生的 Lido 的质押以太币变体）。',
        F17: 'Curve Finance 的手续费如何？',
        F18: '该协议规定的标准手续费率为 0.04%。其中一半分配给流动性提供者，另一半则分配给 veCRV 的持有者。veCRV 的持有者如定期质押其代币，即可获得更大的治理权。',
        F19: 'Curve Finance 可使用杠杆或保证金交易吗？',
        F20: '该交易所仅提供代币兑换服务，且不提供杠杆或保证金交易。',


        G0: '什么是Balancer？',
        G1: 'Balancer 是一种基于以太坊的去中心化自动做市商 (AMM) 协议，它代表了可编程流动性的灵活构建块。',
        G2: '通过将 AMM 曲线逻辑和数学与核心交换功能分离，Balancer 成为一个可扩展的 AMM，可以包含任意数量的交换曲线和池类型。这包括：',
        G3: '传统 50/50x*y=k加权池',
        G4: '自定义权重，例如 80/20，用于控制曝光',
        G5: '稳定的互换曲线',
        G6: '嵌套池（例如：Boosted Pools）',
        G7: '权重不断变化的矿池（例如：流动性自举矿池）',
        G8: '集中的流动性池',
        G9: '允许自定义参数的托管池',
        G10: '整个协议都构建在顶部（例如：陀螺仪）',
        G11: '这样，交换者、聚合者和套利者就可以轻松获得所有总流动性。Balancer Vault优化了批处理和路径逻辑，使 Gas 成本和资本需求保持极低。每个单独的池和项目都建立在 Balancer 的全球流动性之上，这为基础资产带来了深度的流动性并开辟了互换路径。',
        G12: '谁使用Balance？',
        G13: 'Balancer 对于 Defi 领域的不同参与者来说是一个非常有用的工具。',
        G14: '交换者可以在任意两个 ERC20 代币之间进行交换。这可以通过Balancer Dapp来完成或像1inch这样的聚合器,抹茶，或Paraswap。',
        G15: '流动性提供者 (LP) 可以向资金池添加流动性，以赚取掉期费用、流动性激励和其他形式的收益',
        G16: '被动 LP 可以利用增强池在已经复利的 Aave 代币之上赚取收益',
        G17: '套利者可以使用批量互换和闪贷等方式与矿池进行互换',
        G18: 'BAL 代币持有者可以将其代币锁定在 veBAL 中并参与 Balancer 协议发展的治理',
        J0: '什么是 SushiSwap？',
        J1: 'SushiSwap 是一家使用AMM的去中心化交易所 (DEX)。该自动做市商利用智能合约为不同的代币对创建交易市场。流动性提供者为这些智能合约提供资金，实现无摩擦交易。该交易所是最早采用这种模式的交易所之一，且最初是另一家热门的 DEX （去中心化交易所）—— UniSwap 的分支。后来，它成长为所有DeFi中最大的交易所之一。现在，它几乎可以在所有与EVM兼容的第一层区块链和以太坊的某些第二层解决方案上运作。',
        J3: '该交易所的目标主要是希望进行免许可和非托管交易的 DeFi 交易者和机构。该交易所消除了流动性问题，并采取了比其他交易所更平等的治理方式。其原生代币可用于对治理议题投票。',
        J4: '其核心产品包括 Bento Box，这是一种代币库，可以为存入其中的资金产生收益。Kashi 借贷是一个贷款与保证金交易平台，任何人均可在此创造自定义且高 gas 效率的代币市场。在撰写本文时，MasterChef V2是一个包含超过10亿美元的流动性提供计划。最后，无论是通过固定价格还是荷兰式拍卖期权，Miso 为项目提供了在交易所上进行交易的机会。',
        J5: 'SushiSwap 的创始人是谁？',
        J6: 'SushiSwap 最初由 Chef Nomi（化名）创立。后来这批人离开了该项目，并把它的管理权交给了另外两个匿名为 sushiswap 和 0xMaki 的开发人员。这两人进行了产品开发与业务运营，后来该交易所的拥有权转给了另一家中心化交易所 FTX 的首席执行官 Sam Bankman-Fried。',
        J7: 'SushiSwap 于何时推出？',
        J8: 'SushiSwap 于2020年8月推出。',
        J9: 'SushiSwap 位于何处？',
        J10: '该交易所没有一个中心总部，而是以去中心化的方式运作。',
        J11: '限制使用 SushiSwap 的国家',
        J12: '目前没有禁用或限制该交易所的国家的信息。',
        J13: 'SushiSwap 支持的代币列表',
        J14: '由于其存在于第一层和二层区块链上，SushiSwap 支持市场上几乎所有的ERC-20代币。',
        J15: 'SushiSwap 的手续费如何？',
        J16: '该交易所依据业界标准，对代币兑换收取 0.3% 的费用。',
        J17: 'SushiSwap 上可使用杠杆或保证金交易吗？',
        J18: 'Sushiswap 提供了贷款和保证金交易平台 Kashi，该平台使用了一种独特的隔离市场框架。',



    },
    // 交易机器人
    exchangeRobot: {
        h1: "实现个性化的自动化交易策略",
        h2: "交易机器人",
        h3: '灵活参数设置和个性化的风险管理，助您精确执行交易策略',
        h4: "查看详情",
        h5: "更精确、有效的交易执行",
        h6: "帮助您实现自动化交易并根据自身需求进行个性化的交易策略设置",
        h7: "灵活的策略参数",
        h8: "Reactor的交易机器人提供广泛的策略参数设置，以满足不同投资者的需求",
        h9: "买入和卖出的触发条件",
        h10: "预计投入资金设置",
        h11: "设置止盈、止损等参数",
        h12: "多样化的买入策略",
        h13: "您可以根据市场状况和投资目标选择适合的买入策略，并通过参数设置进行进一步定制",
        h14: "百分比出价格",
        h15: "最大开放时间购买",
        h16: "最大未平仓头寸和每个代币百分比",
        h17: "交易冷却机制",
        h18: "智能卖出策略，精准掌握时机",
        h19: "Reactor提供智能卖出策略，帮助您在交易中掌握最佳卖出时机，同时有效管理风险",
        h20: '止损卖出',
        h21: "追踪止损卖出",
        h22: "自动关闭",
        h23: "美元平均成本法",
        h24: "高级配置参数",
        h25: "Reactor交易机器人支持高级订单类型，例如追踪止损买入、自动关闭、美元平均成本法等，使用户能够更精细地控制交易执行和风险管理。",
        h26: "个性化的止损设置",
        h27: "您可以灵活设置止损参数，以限制潜在损失。设置固定止损金额或百分比，或者使用动态的跟踪止损功能，在价格下跌时自动调整止损水平，以保护您的资金安全。",
        h28: "美元平均成本法（DCA）",
        h29: "通过定期、等额的投资方式来分散市场波动风险，实现长期稳健增长。",
    },
    faq: {
        h1: "客户支持/FAQ",
        h2: "寻找答案？答案可能就在这里。",
        h3: '新手指导',
        h4: "什么是Reactor？",
        h5: "如何设置Reactor？",
        h6: "返佣",
        h7: "流动性平台",
        h8: "DEX",
        h9: "安全问题",
        h10: "会员权益",
        h11: "产品",
        h12: "交易",
        h13: "查看更多",
        h14: "Reactor是一款创新的量化交易平台",
        h15: "Reactor整合了先进的算法和技术，旨在帮助用户更加智能、高效地进行交易。通过自动化执行交易策略、提供实时市场数据和分析，以及提供丰富的交易工具，使用户能够在金融市场中取得更好的交易结果。无论是新手还是专业交易者，Reactor都为其提供了一个强大的交易平台，帮助他们实现投资目标并获得更高的交易收益。",
        h16: "常见问题",
        h17: "我可以赚取多少利润？",
        h18: "套利是如何运作的？",
        h19: "你们保证盈利吗？",
        h20: "我该如何连接到交易所？",
        h21: "我可以在一个交易账户上开启几个机器人？",
        h22: "我应该使用哪种基础货币？",
        h23: "Reactor安全吗？",
        h24: "退出主HD钱包后我的账户还在吗？",
    },
    helpCenter: {
        h1: "帮助中心",
        h2: "寻找答案？答案可能就在这里。",
        h3: '浏览主题',
        h4: "探索我们支持的文章和常见问题解答类别",
        h5: "什么是Reactor？",
        h6: "Reactor 为用户提供了准确的市场分析、量化策略和交易工具。通过 Reactor，用户可以实现更高的投资回报率，并在活跃的社区中与其他量化交易者共同成长。",
        h7: "如何设置Reactor？",
        h8: "关于如何设置 Reactor 的各种基本功能的简短指南。这是您作为新用户开始的地方！",
        h9: "返佣",
        h10: "流动性平台",
        h11: "订阅和付款",
        h12: "安全问题",
        h13: "会员权益",
        h14: "做市商机器人",
        h15: "手动交易",
        h16: "DEMO机器人",
        h17: "策略回测",
        h18: "信号商城",
        h19: "策略商城",
        h20: "复制机器人",
        h21: "K线与链上数据",
        h22: "交易机器人",
        h23: "套利机器人",
        h24: "常见问题",
        h25: "我可以赚取多少利润？",
        h26: "套利是如何运作的？",
        h27: "你们保证盈利吗？",
        h28: "我该如何连接到交易所？",
        h29: "我可以在一个交易账户上开启几个机器人？",
        h30: "我应该使用哪种基础货币？",
        h31: "Reactor安全吗？",
        h32: "退出主HD钱包后我的账户还在吗？",
    },
    // interestRobot:{
    //     h1:"Reactor 的套利机器人",
    //     h2:"利用价格差异来获取利润",
    //     h3:'查看更多',
    //     h4:"Reactor 的套利机器人通过同时在不同交易所或链上之间进行快速交易，利用价格差异来获取利润。",
    //     h5:"快速识别市场中的价格差异和机会",
    //     h6:"监测多个交易所和市场的价格和订单簿",
    //     h7:"提供监控和统计数据，让用户可以实时跟踪和分析套利交易的表现",
    //     h8:"多种套利策略",
    //     h9:"支持多种套利策略，如市场套利、交易所套利、跨链套利、期现套利等",
    //     h10:"实时监控和报警",
    //     h11:"套利机器人会实时监控交易所和市场的价格和订单簿，并根据预设的条件进行触发报警",
    //     h12:"可定制化和灵活性",
    //     h13:"用户可以根据自己的的需求和偏好进行配置和调整，以满足个人的交易策略和风险偏好。",
    // },
    // 套利机器人
    interestRobot: {
        h1: "捕捉价差，实现稳定收益",
        h2: "利用市场间价格差异的智能套利机器人",
        h3: '查看更多',
        h4: "套利机器人",
        h5: "利用价格差异，进行跨交易所套利",
        h6: "支持同时连接多个交易平台和区块链",
        h7: "包括但不限于币安、OKX、火币、以太坊、币安智能链、波场等，实时监测的行情和价格变动。",
        h8: "市场扫描和比较",
        h9: "一旦发现价格差异，它会立即执行相应的交易操作。在一个平台上进行买入，同时在另一个平台上进行卖出，以获得价格差的利润",
        h10: "实时监测和报告",
        h11: "用户可以查看详细的交易记录、利润统计和图表展示，以便全面评估套利交易的效果和潜在机会。",
        h12: "快速执行交易",
        h13: "我们致力于提供快速、高效的交易执行，确保用户能够及时抓住市场机会。",
        h14: "我们的交易系统经过精心设计和优化，能够处理大量的订单并保持快速响应执行交易，确保您能够以最佳价格进行买卖操作。交易系统建立在稳定可靠的基础设施上，具备高度的容错性和冗余机制。",
        h15: "作为Reactor的会员，您将享有专属服务器支持，这将进一步提升交易的执行速度和效率。",
        h16: '分布式节点',
        h17: "机房",
        h18: "服务器集群",
        h19: "免费提供专属服务器",
        h20: "Reactor为专业级会员免费分配了专用的服务器资源，为您的机器人提供独立且高效的运行环境",
        h21: "查看详情",
        h22: "每日稳定赚取收益",
        h23: "设置硬币和金额、选定的市场以及交易所套利设置和市场套利设置，让机器人能够准确捕捉不同交易所之间的价格差异，从中获得套利机会。",
        h24: "同时，机器人还具备恢复和积压功能，能够灵活应对市场波动，确保您的投资持续增长。",
        h25: "了解更多",
    },
    join: {
        h1: "拓展您的 Reactor 探索之旅，选择适合您的方案！",
        h2: "新手",
        h3: "免费",
        h4: "可使用1个模板策略",
        h5: "可创建1个策略组合",
        h6: "可执行40次策略组合",
        h7: "支持2个交易所",
        h8: "投资管理组合",
        h9: "开始吧",
        h10: "进阶",
        h11: "月",
        h12: "可使用5个模板策略",
        h13: "可创建5个策略组合",
        h14: "可执行200次策略组合",
        h15: "支持5个交易所",
        h16: "投资管理组合",
        h17: "可复制跟踪5个机器人交易",
        h18: "免费使用模拟交易",
        h19: "开放MarketPlace策略",
        h20: "高级",
        h21: "可使用10个模板策略",
        h22: "可创建15个策略组合",
        h23: "可执行500次策略组合",
        h24: "支持20个交易所",
        h25: "投资管理组合",
        h26: "可复制跟踪20个机器人交易",
        h27: "获取每月交易培训课程2节",
        h28: "一对一定制化策略设计师服务",
        h29: "支持Reactor AI策略和AI设计师",
        h30: "支持申请成为合作策略师",
        h31: "免费进入VIP专属社群",
        h32: "专业",
        h33: "无限使用模板策略",
        h34: "无限创建策略组合",
        h35: "无限次执行策略组合",
        h36: "不限交易所",
        h37: "投资管理组合",
        h38: "可复制跟踪50个机器人交易",
        h39: "每月交易培训全部课程",
        h40: "一对一定制化策略设计师服务",
        h41: "支持Reactor AI策略和AI设计师",
        h42: "额外的技术指标指导",
        h43: "支持申请成为特邀策略师并共同分润",
        h44: "专用服务器",
        h45: "专属策略间隔检查",
        h46: "会员等级比较",
        h47: "手动交易",
        h48: "交易机器人",
        h49: "复制机器人",
        h50: "做市商机器人",
        h51: "套利机器人",
        h52: "交易培训课程",
        h53: "模拟交易",
        h54: "一对一定制化策略",
        h55: "申请成为合作策略师",
        h56: "VIP专属社群",
        h57: "技术指标指导",
        h58: "申请成为特邀策略师并共同分润",
        h59: "专用服务器",
        h60: "专属策略间隔检查",
    },
    manualTrading: {
        h1: "自主决策的权力",
        h2: "量化交易与手动交易的完美结合",
        h3: "查看更多",
        h4: "Reactor在自动化交易方面具有卓越的能力，但为什么仍然需要手动交易的参与？",
        h5: "更全面和综合的交易体验",
        h6: "保留您的交易决策权",
        h7: "手动交易使用户能够保留最终的交易决策权，您仍然可以根据自己的判断和情况，对交易进行最终决策。",
        h8: "事件驱动交易",
        h9: "某些事件和新闻公告可能会对市场产生重大影响，这些事件可能无法完全预测和适应于自动化交易系统。",
        h10: "专业判断和经验",
        h11: "经验丰富的交易者具有丰富的市场知识和专业判断力，能够根据自己的经验做出更准确的决策。",
        h12: "快速应对市场风险，保护您的资产",
        h13: "紧急停止清仓",
        h14: "当市场行情不利时，用户可以在Reactor触发紧急停止清仓功能，通过一键操作将所有持仓资产卖出。",
    },
    market: {
        h1: "今天的加密货币市场",
        h2: "在 Reactor 平台上，您可以从10多个流动性平台中选择数千种加密货币进行量化投资交易。我们与多家知名交易所建立了合作关系，为您提供广泛的加密货币选择。",
        h3: '加密货币市场',
        h4: "美元",
        h5: "24小时",
        h6: "币安",
        h7: "OKX",
        h8: "火币",
        h9: "币种",
        h10: "价格",
        h11: "24小时涨跌",
        h12: "24小时成交量",
        h13: "成交额",
        h14: "亿",
        h15: "合计:608条,每页显示:20条",
    },
    merchantRobot: {
        h1: "Reactor 的做市商机器人",
        h2: "更好地参与市场，提高交易效果，并在投资过程中获得更好的体验",
        h3: '查看更多',
        h4: "Reactor 的做市商机器人是如何工作的？",
        h5: "Reactor的做市商机器人会监控多个交易所的市场行情、订单状况和交易活动。它会实时获取最新的市场数据，包括买卖订单的数量、价格和深度等信息并自动进行买入和卖出操作，根据市场条件和订单簿的变化，动态调整报价和交易量。",
        h6: "做市商机器人的优势",
        h7: "稳定的收益模式",
        h8: "持续的做市活动获取交易差价收益，收益模式相对稳定",
        h9: "提供流动性",
        h10: "通过报价和提供买卖订单，为市场提供流动性。",
        h11: "实时市场监控",
        h12: "根据市场波动和订单状况自动调整报价和流动性策略",
        h13: "自动化交易",
        h14: "根据市场条件实时调整报价和订单",
        h15: "为什么要在Reactor使用做市商机器人？",
        h16: "Reactor开启的做市商机器人相较于传统的普通做市商具有自动化执行、技术优势、快速适应市场、数据分析和优化以及配置灵活性等优势。",
    },
    policyBacktest: {
        h1: "策略回测",
        h2: "用户根据自己的交易策略，在历史市场数据上进行模拟交易，以评估策略的效果和潜在盈利能力。",
        h3: '查看更多',
        h4: "灵活选择，精确回测",
        h5: "选择交易对和时间段",
        h6: "您可以根据个人偏好和市场状况，选择合适的交易对和时间段能够更准确地评估策略的表现和潜在风险。",
        h7: "找到最佳组合，提升策略表现",
        h8: "参数设置和优化",
        h9: "您可以灵活调整交易频率、止盈止损条件等参数，以找到最佳的策略配置。不断尝试和优化参数组合，有助于提高策略的盈利能力和稳定性。",
        h10: "详细报告，全面了解策略表现",
        h11: "回测结果分析和报告",
        h12: "Reactor会生成详细的回测结果报告。报告中包括收益率、胜率、最大回撤等关键指标，让您能够全面了解策略的表现。帮助您发现策略的优势和改进的空间，并做出相应的调整。",
        h13: "直观展示，深入了解策略变化",
        h14: "图表和指标展示",
        h15: "Reactor提供直观的图表和指标，让您更好地理解策略回测的结果。您可以查看价格走势图、交易信号以及持仓情况等图表，同时也可以利用各种技术指标来辅助评估策略的有效性。",
        h16: "量身定制的回测方案",
        h17: "Reactor为您提供私人定制的回测体验：量身定制的回测方案、个性化的回测结果分析、实时策略调整和优化、定制化的交易建议",
        h18: "查看更多",
        h19: "Reactor策略回测的基本原理",
        h20: "基于历史市场数据和设定的交易规则进行模拟交易，以评估和验证策略的表现",
        h21: "历史市场数据",
        h22: "Reactor使用过去的市场数据，包括价格、成交量等指标，构建一个模拟的历史市场环境。",
        h23: "模拟交易执行",
        h24: "基于设定的交易规则，Reactor对历史市场数据进行模拟交易执行。",
        h25: "回测结果评估",
        h26: "Reactor会根据回测期间的交易记录计算关键指标，例如收益率、胜率、最大回撤等",
    },
    rebate: {
        h1: "分享链接，赚取额外收益与朋友一起赚币！",
        h2: "通过分享提供的链接来邀请您的朋友。您的朋友在 Reactor 购买的每次订阅您均可赚取高达 15%的佣金。",
        h3: '查看详情',
        h4: "佣金可提现",
        h5: "进行无限制的推广",
        h6: "推广越多获得奖励越多",
        h7: "最高15%的佣金",
        h8: "只需三个简单步骤即可获得佣金",
        h9: "分享您的链接",
        h10: "让您的朋友使用您唯一的推广链接绑定 Reactor 账户。",
        h11: "付费订阅",
        h12: "您的朋友订阅了我们的会员计划或者在市场上购买了策略",
        h13: "获得奖励",
        h14: "您将获得最高15%的返佣奖励，这个奖励可以随时提现到您的钱包！",
        h15: "我们将会保护您的隐私和好友信息",
        h16: "用户的安全和隐私对我们来说至关重要。Reactor 配备顶级安全基础设施，确保始终对托管资产进行全面保护。",
        h17: "查看隐私协议",
        h18: "如何使用推荐链接？",
        h19: "通过电子邮件或私信发送给好友。",
        h20: "分享在社交媒体上。",
        h21: "发布在个人网站上。",
        h22: "在 Youtube 发布视频并在评论中发布链接。",
        h23: "查看邀请规则",
    },
    // signal:{
    //     h1:"跟随顶级交易者的智慧 实现稳定盈利",
    //     h2:"我们引以为豪地推出了全新的信号交易功能，让您能够轻松跟随顶级交易者的智慧，实现稳定的交易盈利。",
    //     h3:'查看详情',
    //     h4:"选择适合您的信号器是成功交易的关键",
    //     h5:"信号频率",
    //     h6:"了解信号器发送信号的频率，以确定其适应您的交易频率和偏好。",
    //     h7:"订阅者数量",
    //     h8:"了解信号器的订阅者数量，这可以作为信号器受欢迎程度和社区认可度的指标。",
    //     h9:"评级和性能",
    //     h10:"查看信号器的评级方式、最近3个月的信号数量、每笔交易的平均利润等指标，以评估过去的表现和可靠性。",
    //     h11:"统计和评论",
    //     h12:"查看信号器的实时统计数据和其他用户的评论，以获取更多关于信号器的信息和用户体验。",
    //     h13:"什么是交易信号？",
    //     h14:"信号交易是一种创新的交易方式，通过复制专业交易者的交易策略和操作，您可以无需具备专业知识和经验，即可参与到优秀交易者的交易活动中。让您的交易行动与顶级交易者同步。",
    //     h15:"查看更多",
    // },
    // 信号商城
    signal: {
        h1: "自动化交易的智慧引擎",
        h2: "获取高质量交易信号模版，实现稳定投资回报",
        h3: '查看详情',
        h4: "高质量交易信号模版",
        h5: "经过严格的筛选和验证的",
        h6: "获取专业交易者的实际操作，包括买入点、卖出点、止损位等关键指标，订阅后还可以自动复制",
        h7: "在Reactor的严格筛选下，信号的平均胜率达到58.6%",
        h8: "商城中有100+个信号提供者，他们是专业的交易者或机构。",
        h9: "了解更多",
        h10: "提交入驻申请",
        h11: "在商城中展示您的交易策略和专业知识，与全球的交易者分享您的成功经验，从而拓展您的影响力和收益潜力。",
        h12: "联系我们",
        h13: "您可以通过这些数据更准确地选择适合自己的信号",
        h14: "信号频率",
        h15: "发送信号的频率，以确定其适应您的交易偏好。",
        h16: "订阅者数量",
        h17: "了解信号器的订阅者数量，作为社区认可度的指标",
        h18: '评级和性能',
        h19: "最近3个月的信号数量、每笔交易的平均利润等指标",
        h20: "统计和评论",
        h21: "随时查看信号器的实时统计数据和其他用户的评论",
    },
    strategy: {
        h1: "定制您的独特交易策略",
        h2: "为您提供个性化的交易解决方案",
        h3: '联系我们',
        h4: "专属的量化策略师",
        h5: "个性化的交易支持 和专业建议",
        h6: "成为特邀策略师 并共同分润",
        h7: "专用服务器",
        h8: "个性化的交易支持和专业建议",
        h9: "我们深知每位客户的投资目标和需求都独一无二，因此我们将通过专属策略师与您合作，确保您的交易策略与目标紧密契合。",
        h10: "策略",
        h11: "我们的策略师将与您一对一合作，深入了解您的投资目标、风险承受能力和时间要求。",
        h12: "为您量身定制的解决方案",
        h13: "我们的团队将超预期的帮助您实现目标",
        h14: "私人客户",
        h15: "希望实现您最雄心勃勃的投资目标？我们已协助无数客户实现收益最大化、减少支出和增加投资组合资产。",
        h141: "企业",
        h151: "寻求更高的财务效率和投资回报率？我们与众多企业合作，为您提供度身定制的解决方案，助您实现资金增长和风险管理的双重目标。",
        h16: "企业",
        h17: "我们满足您的每一个需求",
        h18: "Reactor 在我们领先的产品上拥有无与伦比的优势",
        h19: "专属的量化策略师",
        h20: "能够根据您的需求和目标定制量化交易策略。他们将与您密切合作，深入了解您的投资偏好和风险承受能力，并根据市场趋势和数据分析为您提供个性化的交易建议。",
        h21: "个性化的交易支持和专业建议",
        h22: "我们将深入了解您的交易目标和需求，为您提供针对性的交易策略和建议，帮助您优化交易决策。无论是技术分析、市场研究还是风险管理，我们将为您提供专业的指导和支持。",
        h23: "成为特邀策略师并共同分润",
        h24: "作为特邀策略师，您将有机会分享您的成功交易策略和经验，与其他用户共同学习和成长。同时，您将享受到分润的机会，通过与Reactor合作共同分享交易盈利。",
        h25: "专用服务器",
        h26: "我们的服务器具备高性能和低延迟的特点，能够快速处理交易指令并确保交易的及时执行。通过使用专用服务器，您可以更好地把握交易机会，并提升交易的成功率和效果。",
        h27: "定制化服务",
        h28: "帮助您实现交易目标，并在量化交易领域取得更大的成功。无论您的交易风格、偏好或目标如何，我们都将竭诚为您提供最优质的定制化服务",
        h29: "联系我们",
    },
    // 策略商城
    strategyMall: {
        h1: "发现优质策略，实现交易突破",
        h2: "与顶级交易达人共同探索市场机会",
        h3: '查看详情',
        h4: "策略商城",
        h5: "优化交易体验",
        h6: "经过验证的精选交易策略",
        h7: "一次购买终身使用，您可以获取并持有所购买的交易策略使用权并且这个策略是可以下载的。",
        h8: "详细的绩效数据，包括历史回报率、风险指标和交易频率等信息。",
        h9: "我们提供详细的交易历史记录，让您全面了解每个策略的实际表现。",
        h10: "详细了解策略商城",
        h11: "提交入驻申请",
        h12: "在商城中展示您的交易策略和专业知识，与全球的交易者分享您的成功经验，从而拓展您的影响力和收益潜力。",
        h13: "联系我们",
        h14: "Reactor策略商城的优势？",
        h15: "多元化的策略选择",
        h16: "灵活的自主交易",
        h17: "专业策略师",
        h18: "无论您是偏好趋势跟踪、波段交易还是套利策略，都能找到适合您的策略",
        h19: "能够在保持策略的基础上灵活调整交易参数，以适应不断变化的市场条件",
        h20: "策略师拥有市场洞察力和成功的交易记录，他们将为您的交易提供有力支持",
        h21: "了解更多",
        h22: "严格的策略监管",
        h23: "为确保交易策略的透明度和可靠性，每个策略在上线之前都需要经过我们的审核和测试流程。",
        h24: "经过时间验证的策略",
        h25: "策略在我们的后台进行了长时间的实时模拟和回测，我们提供的历史数据完全真实。",
    },
    business: {
        h1: "开创商机，共享成功",
        h2: "我们热忱欢迎各行各业的合作伙伴与我们共同合作。通过参与我们的合作伙伴计划，您将有机会成为我们的战略伙伴，共同开创商业机会，实现共赢。",
        h3: '联系我们',
        h4: "合作伙伴支持和资源共享",
        h5: "作为合作伙伴，您将享受我们提供的全方位支持和资源。我们将提供专业的培训和技术支持，以帮助您更好地了解我们的产品和服务，并能够为客户提供优质的解决方案。此外，您还将获得我们的市场推广支持，包括合作伙伴专属的市场活动、营销资料和品牌宣传。我们将与您合作，共同推动业务增长，共享成功。",
        h6: "合作共赢，持续发展",
        h7: "我们重视与合作伙伴的紧密合作，相信通过共同努力和互相支持，我们能够取得更大的成功。我们提供灵活的合作模式，包括合作伙伴销售、分销和推广计划，为合作伙伴创造丰厚的经济回报。同时，我们鼓励合作伙伴提供宝贵的反馈和建议，以帮助我们不断改进和创新，共同发展。我们相信通过合作共赢的理念，我们将在竞争激烈的市场中持续发展。",
        h8: "加入我们",
        h9: "如果您对与我们合作伙伴计划感兴趣，我们非常欢迎您加入我们的行列。无论您是一家创新型初创企业、一家成熟的技术提供商，还是一家寻求新商机的公司，我们都愿意与您合作，共同开创成功之路。加入我们的合作伙伴计划，您将获得与我们紧密合作的机会，共享我们的资源和经验，共同拓展市场，实现共赢。",
    },
    dowView: {
        h1: "下载客户端",
        h2: "探索量化交易的无限潜力，从容应对市场波动。",
        h3: '免费体验',
    },
    contactView: {
        h1: "联系我们，或者预约参观我们的办公室",
        h2: "深圳市宝安区新安街道宝民一路宝通大厦608",
        h3: '立即咨询',
    },
    helpView: {
        h1: "需要更多帮助？",
        h2: "给我们的团队留言，我们会在工作时间与您取得联系。",
        h3: '联系支持',
    },
    token: {
        h1: "合约",
        h2: "市场统计",
        h3: '市值排名',
        h4: "市值",
        h5: "亿",
        h6: "24小时成交量",
        h7: "24小时最高",
        h8: "历史最高价",
        h9: "最大流通量",
        h10: "以太坊（ETH）实时行情",
        h11: "当前以太坊价格为 1,786.68 美元，在过去 24 小时内其价值下跌了 0.31%。以太坊较历史高位 4,878.26 美元下跌 64.33%。以太坊在市值方面排名第二，占整个加密市场的 19.08%。以太坊是一种去中心化货币，您可以在Reactor通过中心化&去中心化流动性平台购买、出售和对ETH进行量化交易。",
        h12: "什么是以太坊",
        h13: "以太坊是一个去中心化的区块链平台，使开发人员能够在其区块链之上构建去中心化应用程序 (dApp)。以太坊于 2015 年由 Vitalik Buterin 推出，凭借其强大的生态系统、活跃的社区和创新功能，已成为世界上最受欢迎的区块链平台之一。以太 (ETH) 是以太坊的原生加密货币，在平台上有多种用途。它可用于在以太坊网络上支付Gas,并作为参与平台治理系统的一种方式。以太坊的两个最重要的特征是它的互操作性和可扩展性。前者指的是以太坊能够与其他区块链网络和平台连接和通信，从而实现更强大的功能和兼容性。另一方面，可扩展性是指以太坊在不影响其性能或安全性的情况下处理大量交易和数据的能力。2022 年 9 月，以太坊经历了一次名为 The Merge 的重大升级，因其复杂性而被称为该行业最重要的升级事件之一。它涉及从工作量证明共识算法到更高效的权益证明算法的过渡，目的是降低能耗，进一步提高可扩展性，并提高安全性。因此，这要求通过质押而不是挖矿来验证以太坊上的交易。尽管期待已久，合并只是以太坊为完成 Vitalik 的愿景而必须进行的一系列重大更新的开始。",
        h14: "为什么 Reactor 是量化交易的正确",
        h15: "多元化投资",
        h16: "将资金分散于交易所和去中心化钱包，有效降低风险并提高潜在收益。",
        h17: "支持模拟账户",
        h18: "提供真实市场模拟环境，让您以零风险的方式进行投资实践和策略测试。",
        h19: "交易数据统计",
        h20: "您可以放心地使用我们的机器人进行交易，享受便捷和安全的投资体验。",
        h21: "浏览其他资产",

        BTC0: '比特币是一种去中心化的加密货币。在2008年发布的白皮书中，由某个人或某个群体化名为中本聪（Satoshi Nakamoto） ，首度探讨了比特币的机制。2009年1月，比特币正式问世。',
        BTC1: '比特币是一种基于点对点网络的货币，所有交易都是在平等独立的网络参与者之间直接进行，而无需任何中间方的许可或促成。用中本聪自己的话来说，创造比特币就是为了让“一方无需通过金融机构就能直接对另一方在线付款”。',
        BTC2: '在比特币出现之前，出现过一些类似的去中心化电子货币概念，但比特币的独特之处在于，它是有史以来首个被应用于现实生活中的加密货币。',

        ETH0: '以太坊是一个去中心化开源区块链系统，它拥有自己的原生加密货币——以太币。以太坊还是很多其他加密货币的交易平台，同时它也可以执行去中心化智能合约。',
        ETH1: 'Vitalik Buterin在发表于2013年的白皮书中首次介绍了以太坊。Buterin先生和其他联合创始人一起，于2014年夏天通过项目在线众筹的方式，在2015年7月30日正式推出了以太坊区块链。',
        ETH2: '以太坊声称，其目的是成为一个全球性去中心化应用平台，让全世界的使用者都能编写和运行软件，而无需被审查、不会出现故障停机以及欺诈行为。',

        USDT0: '泰达币(USDT)是一种数字货币，其价值意在反映美元的价值。 泰达币于2014年推出，其目的是创建一种稳定的加密货币，可像数字美元或“稳定币”一样使用。泰达币的价格与美元锚定。',
        USDT1: '泰达币最开始使用比特币网络的Omni层作为其传输协议，现在它可以在以太坊上作为ERC-20代币使用。泰达币已经在比特币(Omni和Liquid协议)、以太坊、EOS、波场区块链上发行。',
        USDT2: '泰达币由Tether Limited发行，这家公司与加密货币交易所Bitfinex拥有同一个CEO。Tether曾声称为泰达币储备了100%的美元准备金。2019年，该公司的律师表示只有74%的泰达币有准备金做支撑。对此Tether的解释是，其子公司的贷款也属于储备金的一部分。',


        BNB0: '币安币于2017年通过首次代币发行推出，11天之后，币安加密货币交易所上线。币安币最初以ERC-20代币形式在以太坊网络中发行，最大总供给量为2亿枚代币，在首次代币发售中发行了1亿枚代币。然而，随着2019年4月币安链主网上线，ERC-20币安币与BEP2币安币按1：1的比率兑换，现在不再托管在以太坊上。',
        BNB1: '币安币作为一种支付方式，用户可以使用这种实用型代币，在币安交易所抵扣手续费，并参与币安资产发行平台（LaunchPad）上的代币销售。币安币还是币安去中心化交易（DEX）的主要货币。',

        XRP0: '首先，要了解瑞波币、瑞波和瑞波网（RippleNet）之间的区别。瑞波币是在数字支付平台——瑞波网上运行的货币。瑞波网是基于一种分布式账本数据库（也叫做瑞波账本（XRP Ledger））创建的。虽然瑞波网由瑞波（Ripple）公司运营，但瑞波账本却是开源的，并未采用区块链技术，而是根据之前提到的分布式账本数据库创建的。',
        XRP1: '瑞波网支付平台是一个实时全额支付（RTGS）系统，旨在实现全球范围内的即时货币交易。虽然瑞波币是瑞波账本的原生加密货币，用户仍然可以在该平台上使用任何一种货币进行交易。',
        XRP2: '瑞波支付平台背后的理念由Ryan Fugger于2004年首次提出，但当Jed McCaleb 和 Chris Larson在2012年接管了项目后，创建瑞波公司的计划才提上日程（当时，瑞波也被称为OpenCoin）。',

        USDC0: 'USDC 是一种稳定币，与美元以 1:1 的比例挂钩。这种加密货币的每一个流通单位都有 1 美元的储备作为支持，其中包括现金和短期美国国债。该资产背后的中心财团表示，USDC 是由受监管的金融机构发行的。',
        USDC1: '该稳定币最初于2018年9月限量发行。简单地说，美元币的口号是 "数字时代的数字货币"--这种稳定币是为无现金交易越来越普遍的世界而设计的。',
        USDC2: '美元币已经发布了几种使用案例。除了在波动时期为加密货币交易者提供安全避风港之外，稳定币的幕后推手还表示，它还可以让企业接受数字资产支付，并撼动包括去中心化金融和游戏在内的一系列行业。',

        DOGE0: '狗狗币（DOGE）的名称源于网络流行语“DOGE”，它的logo上有一只柴犬。这一开源型数字货币由美国俄勒冈州波特兰市的比利·马库斯(Billy Markus)和澳大利亚悉尼市的杰克逊·帕尔默(Jackson Palmer)共同创造，并在2013年12月由莱特币中派生出来。狗狗币的创始人们将其设定为一种轻松有趣的虚拟货币，希望它能吸引比特币核心受众之外的更多用户，毕竟它的形象来源于在网上超火的表情包"doge"。Telsa公司的CEO埃隆·马斯克曾数次发推说狗狗币是他的最爱。',


        ADA0: 'Cardano是采用权益证明机制的区块链平台，其宣称的目标是让“改变者、创新者和远见者”带来积极的全球性改变。',
        ADA1: '这个开源项目还有一个目标，就是“将权力从不负责任的机构处重新分配给个人”——以致力于创造一个更加安全、透明和公平的社会。',
        ADA2: 'Cardano成立于2017年，ADA（艾达币）代币的设计是为了保证其拥有者能参与网络运营。因此，持有该加密货币的人有权对任何改进提议进行投票表决。',
        ADA3: '该团队认为，其技术旨在使用模块化概念开发去中心化app和智能合约，目前已有一些非常好的使用案例，',
        ADA4: '农业公司可以使用Cardano追踪从土地出产的新鲜农产品达到餐桌的流程，而该平台的其他产品则可以完好保存教育资质证明不受篡改，或者为零售商们取缔假冒商品提供支持。',


        SOL0: 'Solana是一个功能强大的开源项目，它利用区块链技术的无权限特性提供去中心化金融（DeFi）解决方案。虽然该项目的想法和初期工作始于2017年，但Solana于2020年3月由总部位于瑞士日内瓦的Solana基金会正式推出。',
        SOL1: '要了解有关该项目的更多信息，请查看我们对 Solana 的深入研究。',
        SOL2: 'Solana 协议旨在促进去中心化应用程序（DApp）的创建。它旨在通过引入历史证明（PoH）共识与区块链的底层权益证明（PoS）共识相结合来提高可扩展性。',

        TRX0: '波场币(TRX)由孙宇晨(Justin Sun)于2017年通过波场基金会创立，该基金会总部位于新加坡。 2017年，波场基金会的首次代币发行（ICO）创造了1000亿枚TRX，共筹集了7000万美元。',
        TRX1: '波场协议对整个操作系统的架构进行了描述，该系统基于区块链技术，能够让开发人员创建智能合约和去中心化应用(DApps)，自由发布、拥有和存储数据以及其他内容。根据波场基金会的说法，围绕这个网络的生态系统专门提供大规模的可扩展性和一致的可靠性，能够通过高吞吐量计算来高速处理事务。',
        TRX2: '波场币原本基于以太坊网络创建，在2018年迁移到了自己的网络上。ERC-20波场币持有者将代币换成了波场网络上的TRX数字货币，以太坊上的波场代币随即被销毁。',

        Matic0: 'Polygon （前身为 Matic Network）是首个结构合理、易于使用的以太坊扩展和基础设施开发平台。其核心组件是 Polygon SDK，这是一个模块化的灵活框架，支持构建多种类型的应用程序。',
        Matic1: '要了解有关此项目的更多信息，请查看我们对 Polygon Matic 的深入研究。',
        Matic2: '使用 Polygon，可以创建乐观卷积链、ZK 卷积链、独立链或开发人员需要的任何其他类型的基础设施。',

        LTC0: '莱特币（LTC）是一种加密货币，旨在通过使用区块链技术的特性，提供快速、安全且低成本的支付功能。',
        LTC1: '该加密货币是基于比特币（BTC）协议创建的，但在哈希算法、硬顶（Hard Cap）、区块交易时间和一些其他因素上有所不同。莱特币的出块时间仅需2.5分钟，且交易费用极低，因此非常适合小额交易和销售点（point-of-sale）支付方式。',
        LTC2: '莱特币于2011年10月7日由一位开源用户在GitHub上推出，五天后，即2011年10月13日，莱特币网络正式上线。从那时起，其在商家中的使用量和接受度开始暴增，自问世以来，莱特币长期维持在市值排名前10的加密货币。',
        LTC3: '该加密货币由前谷歌员工李启威（Charlie Lee）创建，他希望把莱特币做成比特币的精简版，因此开发了很多与比特币相同的特性（除了重要性和通用性更小一些）。',

        AVAX0: '雪崩是一种第一层区块链，可作为去中心化应用程序和定制区块链网络的平台。它是以太坊的竞争对手之一，旨在取代以太坊成为最受欢迎的智能合约区块链。它的目标是在不影响可扩展性的前提下，实现高达每秒 6,500 次的交易输出。',
        AVAX1: '这得益于 Avalanche 独特的架构。雪崩网络由三个独立的区块链组成：X 链、C 链和 P 链。每条链都有不同的目的，这与比特币和以太坊使用的方法（即让所有节点验证所有交易）截然不同。雪崩区块链甚至根据其使用情况使用不同的共识机制。',
        AVAX2: '在 2020 年推出主网后，雪崩一直致力于开发自己的 DApps 和 DeFi 生态系统。不同的基于以太坊的项目，如 SushiSwap 和 TrueUSD，都已与 Avalanche 集成。此外，该平台还在不断努力提高自身生态系统与以太坊之间的互操作性，例如通过开发桥接器。',

        ETC0: '以太坊经典（ETC）是以太坊（ETH）的硬分叉，于 2016 年 7 月推出。其主要功能是作为一个智能合约网络，能够托管和支持去中心化应用程序（DApps）。其原生代币为 ETC。',
        ETC1: '自推出以来，以太坊经典一直在寻求与以太坊的差异化，随着时间的推移，这两个网络的技术路线图也越来越背离。',
        ETC2: '在一次重大黑客事件导致 360 万以太坊被盗后，以太坊经典首先着手维护现有以太坊区块链的完整性。',

        ARB0: '',

        SUI0: 'Sui 是一种低延迟、高吞吐量的第一层区块链。其即时交易结算能力，使 Sui 成为链上用例（如 DeFi和 GameFi）的适当区块链。该区块链是用Rust编写的（Rust是一种专注于快速和安全交易执行的编程语言）。Rust 也是另一种高速区块链 Solana 采用的编程语言。',
        SUI1: 'Sui 宣称的目标是：要通过比以太坊等区块链竞争对手更卓越的智能合约架构，来布署去中心化应用 (DApp），以“带领下一批十亿用户来到 Web 3”。区块链利用一种称为“交易并行化”的过程来实现这一点，它允许在“并行协议”中处理交易。正如Sui所称的那样，这种“并行扩展”可以在拜占庭容错权益证明(PoS)共识机制中实现更好的数据组织方式。',

        DAI0: '',

        LINK0: '',

        UNI0: 'Uniswap 是一种流行的去中心化交易协议，因其在促进去中心化金融（DeFi）代币自动交易方面的作用而闻名。',
        UNI1: '作为自动做市商（AMM）的一个范例，Uniswap 于 2018 年 11 月推出，但由于 DeFi 现象和相关代币交易的激增，它在今年获得了相当高的人气。',
        UNI2: 'Uniswap 旨在保持代币交易的自动化，并对持有代币的任何人完全开放，同时提高与传统交易所相比的交易效率。',
        UNI3: 'Uniswap 通过自动化解决方案解决流动性问题，避免了困扰第一批去中心化交易所的问题，从而提高了效率。',
        UNI4: '2020 年 9 月，Uniswap 更进一步，创建了自己的治理代币 UNI，并授予协议的过去用户。这既增加了盈利潜力，又让用户有能力塑造其未来--这是去中心化实体的一个吸引人的方面。',

        EOS0: '',

        OP0: '',








    },
    wallet: {
        h1: "Web3的量化交易钱包",
        h2: "解锁无限交易潜能，实现卓越的量化交易体验。",
        h3: '免费体验',
        h4: "多元化投资",
        h5: "资产安全保障",
        h6: "快速、高效的交易体验",
        h7: "Reactor 钱包量化的优势",
        h8: "钱包量化提供更加安全、灵活和个性化的交易体验",
        h9: "个人资产安全护航",
        h10: "Reactor 使用个人钱包账户进行交易，相比交易所您拥有更大的控制权和安全性。",
        h11: "融合量化，无缝跨越多个区块链",
        h12: "Reactor 通过集成多个区块链网络，实现无缝的资产转移和交易，不受单一链的限制，获得更多的投资机会。",
        h13: "智能低Gas费路线",
        h14: "Reactor 可以自动分析当前网络情况，并选择最低的gas费路线来执行交易，以减少交易成本。",
        h15: "个人资产安全护航",
        h16: "Reactor 使用个人钱包账户进行交易，相比交易所您拥有更大的控制权和安全性。",
        h17: "创建您的主HD钱包，开启Web3世界的探索",
        h18: "主HD钱包是您独一无二的身份，可连接到多个非托管钱包",
        h19: "通过主HD钱包，您可以方便地管理和控制多个非托管钱包，无需为每个钱包创建和记忆不同的私钥和密码。",
        h20: "主HD钱包提供详尽的数据统计功能，让您了解您的交易历史、资产分布和收益情况，帮助您做出更明智的投资决策。",
        h21: "主HD钱包具有高度的灵活性，可根据您的需求和偏好进行个性化设置，让您更好地定制和管理您的数字资产。",
        h22: "定制化策略",
        h23: "Reactor 可以根据自己的需求和策略进行定制化的交易。您可以根据自己的交易策略和风险偏好进行量化交易，而不受交易所API的限制",
        h24: "多链环境强力支持",
        h25: "Reactor 能够在不同的链上进行交易。这为您提供了更多的选择和灵活性，可以根据市场情况和资产配置进行交易",
    },
    message: {
        home: {
            h1: "需要NFT获得参加资格",
            h2: "请勿重复参加",
            h3: "先绑定上级",
            h4: "授权成功，请完成后续操作",
            h5: "授权失败",
            h6: "参加成功",
            h7: "参加失败"
        },
        nft: {
            h1: "授权成功，请完成后续操作",
            h2: "授权失败",
            h3: "转移成功",
            h4: "转移失败",
            h5: "购买成功",
            h6: "购买失败"
        },
        node: {
            h1: "先绑定上级",
            h2: "授权成功，请完成后续操作",
            h3: "授权失败",
            h4: "购买成功",
            h5: "购买失败",
            h6: "先绑定上级",
            h7: "授权成功，请完成后续操作",
            h8: "授权失败",
            h9: "购买成功",
            h10: "购买失败",
            h11: "领取成功",
            h12: "领取失败"
        },
        team: {
            h1: "复制成功",
            h2: "复制失败",
            h3: "绑定成功",
            h4: "绑定失败"
        },
        game: {
            h1: "领取成功",
            h2: "领取失败"
        },
        unit: {
            h1: "没有链接钱包"
        }
    },
}
// {{$t("team.")}}
// this.$t("message.team.")