<template>
  <div class="dowImg">
    <div class="dowImg-top">
        {{$t('dowView.h1')}}
    </div>
    <div class="dowImg-det">
        {{$t('dowView.h2')}}
    </div>
    <div class="dowImg-btm">
        <div class="dowImg-btm1" @click="goDownload">
            <span>{{$t('dowView.h3')}}</span>
            <span class="solidSpan">
                <img src="../../assets/policyBacktest/home_button_right_normal (2).svg" alt="">
            </span>
        </div>
        <div class="dowImg-btm2">
            <!-- <img src="../../assets/home_icon_apple2_normal.png" alt=""> -->
        </div>
        <div class="dowImg-btm3">
        </div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        goDownload(){
            if (this.$router.currentRoute.path === '/download') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/download",
            });
        },
    }
}
</script>

<style scoped lang="scss">
  $defaultSize: 0.0833333333vw;
  @keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}
.dowImg{
    background-image: url("../../assets/sim/dowImg.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 208 * $defaultSize;
    margin-top: 50 * $defaultSize;
    text-align: center;
    padding: 30 * $defaultSize;
    .dowImg-top{
        font-size: 20 * $defaultSize;
        font-family: Inter-Semi Bold, Semi Bold;
        font-weight: 500;
        color: #333333;
        margin-bottom: 10 * $defaultSize;
    }
    .dowImg-det{
        font-size: 15 * $defaultSize;
        font-family: Inter-Regular, Regular;
        font-weight: 400;
        color: #4F5559;
    }
    .dowImg-btm{
        width: 100%;
        padding: 25 * $defaultSize;
        display: flex;
        align-items: center;
        justify-content: center;
        .dowImg-btm1 {
            cursor: pointer;
            display: inline-block;
            width: 100 * $defaultSize;
            height: 35 * $defaultSize;
            background-color: #224FD9;
            line-height: 35 * $defaultSize;
            border-radius: 5 * $defaultSize;
            font-size: 15 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            text-align: center;
            font-weight: 500;
            color: #FFFFFF;
            img{
                width: 12 * $defaultSize;
                color: #FFFFFF;
            }
        }
        .dowImg-btm1:hover{
            background: #1F41A7;
            .solidSpan{
                img{
                    animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                }
            }
        }
        .dowImg-btm2{
            cursor: pointer;
            margin-left: 20 *$defaultSize;
            width: 25  *$defaultSize;
            height: 25  *$defaultSize;
            background-image: url('../../assets/sim/home_icon_apple_dark(1).svg'); /* 默认图片路径 */
            background-size: 100%;
        }
        .dowImg-btm2:hover{
            background-image: url('../../assets/sim/home_icon_apple_dark.svg'); /* 默认图片路径 */
        }
        .dowImg-btm3{
            cursor: pointer;
            margin-left: 10 *$defaultSize;
            width: 25  *$defaultSize;
            height: 25  *$defaultSize;
            background-image: url('../../assets/sim/home_icon_win_dark.svg'); /* 默认图片路径 */
            background-size: 100%;
        }
        .dowImg-btm3:hover{
            background-image: url('../../assets/sim/home_icon_win_dark(1).svg'); /* 默认图片路径 */
        }
    }
}
</style>