<template>
  <div>
    <!-- <div :style="`${num === 0 ? 'display: block' : 'display: none'}`">
      <Topbar :isPageBack="$route.meta.isPageBacK === true" />
    </div> -->
    <!-- {{ GET_Dialog }} -->
    <Topbar :isPageBack="$route.meta.isPageBacK === true" />
    <router-view :class="GET_Dialog ? 'routerClss' : ''" />
    <!-- <BTM :isPageBack="$route.meta.isPageBacK === true" /> -->
    <div :style="`${num === 0 ? 'display: block' : 'display: none'}`">
      <BTM :isPageBack="$route.meta.isPageBacK === true" />
    </div>
  </div>
</template>

<script>
import Topbar from '../src/components/topbar.vue';
import BTM from '../src/components/btm.vue';
import { mapGetters } from "vuex";
export default {
  components: { Topbar, BTM },
  computed: {
    ...mapGetters(['GET_Dialog']),
  },
  data() {
    return {
      num: 0,
    }
  },
  mounted() {
    console.log(this.$route);
    if (this.$route.name == 'strategy') {
      this.num = 1;
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'strategy') {
        this.num = 1;
      } else {
        this.num = 0;
      }
      console.log(this.num);
      document.documentElement.scrollTop = 0;
    },
  }
}

</script>

<style>
body {
  margin: 0;
}

.routerClss {
  margin-top: 3.8em;
}

#app {}

* {
  box-sizing: border-box;
}
</style>
