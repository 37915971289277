var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"box1"},[_c('div',_vm._l((_vm.h1Arr(_vm.$t('about.h1'))),function(item,index){return _c('span',{key:index,class:[`${_vm.GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`],style:({ animationDelay: `${(index * 0.02) + (_vm.h1Arr(_vm.$t('home.h1')).length * 0.02)}s` })},[_vm._v(_vm._s(item)+" "),(_vm.getI18nByEn())?_c('span',[_vm._v(" ")]):_vm._e()])}),0)]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"box2"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.$t('about.h2')))]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(_vm.$t('about.h3')))])]),_c('div',{staticClass:"box3"},[_c('div',{staticClass:"small"},[_vm._m(0),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('about.h4')))]),_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.$t('about.h5')))])]),_c('div',{staticClass:"small"},[_vm._m(1),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('about.h6')))]),_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.$t('about.h7')))])]),_c('div',{staticClass:"small"},[_vm._m(2),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('about.h8')))]),_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.$t('about.h9')))])])]),_vm._m(3),_c('div',{staticClass:"box5"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('about.h10')))]),_c('div',{staticClass:"big"},[_c('div',{staticClass:"small"},[_c('img',{attrs:{"src":require("../assets/LIVHNG9YPOMF0.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"Num"},[_vm._v("01")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('about.h11')))]),_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.$t('about.h12')))])])]),_vm._m(4),_vm._m(5)])]),_c('div',[_c('contact-view')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgDiv"},[_c('img',{attrs:{"src":require("../assets/about1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgDiv"},[_c('img',{attrs:{"src":require("../assets/about2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgDiv"},[_c('img',{attrs:{"src":require("../assets/about3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box4"},[_c('img',{attrs:{"src":require("../assets/about4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"Num"},[_vm._v("02")]),_c('div',{staticClass:"name"},[_vm._v("愿景")]),_c('div',{staticClass:"hint"},[_vm._v("我们的愿景是通过丰富世界的创新解决方案，成为引领行业发展的领导者。我们追求卓越的用户体验，致力于为用户创造无与伦比的价值，并通过创意和创新为社会带来积极的影响。")])]),_c('img',{attrs:{"src":require("../assets/LIVHNHY3VBMI1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small"},[_c('img',{attrs:{"src":require("../assets/LIVHNJTZT4T0H.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"Num"},[_vm._v("03")]),_c('div',{staticClass:"name"},[_vm._v("价值观")]),_c('div',{staticClass:"hint"},[_vm._v("以领导力为基础，在与社区的紧密合作中，为您提供高效、高质的解决方案。为客户提供卓越的服务和成果。我们的目标是以卓越的执行力和创新精神，超越客户期望，实现共同成功。")])])])
}]

export { render, staticRenderFns }