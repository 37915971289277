<template>
    <div class="rebate">
        <div class="main">
            <div class="box1 fadeInDown">
                <div class="left">
                    <div class="top">
                        <div class="leftH1">{{ $t('rebate.h1') }}</div>
                        <div class="leftH2">{{ $t('rebate.h2') }}</div>
                    </div>
                    <div class="btm" @click="asd3">
                        <span>{{ $t('rebate.h3') }}</span>
                        <img src="@/assets/home_button_right_normal.png" alt="右箭头">
                    </div>
                </div>
                <div class="right">
                    <img src="../assets/money.png" alt="">
                </div>
            </div>
            <div class="box2">
                <div class="small">
                    <img src="../assets/LJ2B88Y1JTLZG 1.svg" alt=""><span>{{ $t('rebate.h4') }}</span>
                </div>
                <div class="small">
                    <img src="../assets/LJ2B8ADPWM881 1.svg" alt=""><span>{{ $t('rebate.h5') }}</span>
                </div>
                <div class="small">
                    <img src="../assets/LJ2B8CA56KKT9 1.svg" alt=""><span>{{ $t('rebate.h6') }}</span>
                </div>
                <div class="small">
                    <img src="../assets/LJ2B8IVWL54JT 1.svg" alt=""><span>{{ $t('rebate.h7') }}</span>
                </div>
            </div>
            <div class="box3">
                <div class="title">{{ $t('rebate.h8') }}</div>
                <div class="big">
                    <div class="small">
                        <img src="../assets/Group 57.svg" alt="">
                        <div class="smallH1">{{ $t('rebate.h9') }}</div>
                        <div class="smallH2">{{ $t('rebate.h10') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/Group 58.svg" alt="">
                        <div class="smallH1">{{ $t('rebate.h11') }}</div>
                        <div class="smallH2">{{ $t('rebate.h12') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/Group 59.svg" alt="">
                        <div class="smallH1">{{ $t('rebate.h13') }}</div>
                        <div class="smallH2">{{ $t('rebate.h14') }}</div>
                    </div>
                </div>
            </div>
            <div class="box4" @click="asd2">
                <img src="../assets/LJ2FU6U63Z36E 1.svg" alt="">
                <div class="right">
                    <div class="h1">{{ $t('rebate.h15') }}</div>
                    <div class="h2">{{ $t('rebate.h16') }}</div>
                    <div class="h3">
                        <span>{{ $t('rebate.h17') }}</span>
                        <img src="@/assets/home_icon_more_normal.png" alt="">
                    </div>
                </div>
            </div>
            <div class="box5">
                <div class="title">{{ $t('rebate.h18') }}</div>
                <div class="body">
                    <div class="small">
                        <img src="../assets/dui.svg" alt=""><span>{{ $t('rebate.h19') }}</span>
                    </div>
                    <div class="small">
                        <img src="../assets/dui.svg" alt=""><span>{{ $t('rebate.h20') }}</span>
                    </div>
                    <div class="small">
                        <img src="../assets/dui.svg" alt=""><span>{{ $t('rebate.h21') }}</span>
                    </div>
                    <div class="small">
                        <img src="../assets/dui.svg" alt=""><span>{{ $t('rebate.h22') }}</span>
                    </div>
                </div>
                <div class="btn" @click="asd">
                    <span>{{ $t('rebate.h23') }}</span>
                    <img src="@/assets/rebate/home_button_right_normal.svg" alt="右箭头">
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
big.NE = -40;
big.PE = 40;
export default {
    name: "rebate",
    data() {
        return {
        };
    },
    methods: {
        asd() {
            window.open('https://support.rac.art/fan-yong/ru-he-yao-qing-ta-ren-bing-huo-de-fan-yong', '_blank')
        },
        asd2() {
            window.open('https://support.rac.art/qi-ta/yin-si-zheng-ce', '_blank')
        },
        asd3() {
            window.open('https://support.rac.art/fan-yong/ru-he-yao-qing-ta-ren-bing-huo-de-fan-yong', '_blank')
        },
    },
    computed: {},
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.fadeInDown {
    animation: tracking-in-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes tracking-in-top {
    0% {
        transform: translateY(-50 * $defaultSize);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.rebate {

    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;
    .main {
        border: 1px #FFF solid !important;
        width: 750 * $defaultSize;
        margin: auto;

        .box1 {
            margin-top: 80 * $defaultSize;
            display: flex;

            .left {
                width: 40%;
                padding-top: 30 * $defaultSize;

                .top {
                    .leftH1 {
                        font-size: 30 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: bold;
                        color: #000000;
                        margin-bottom: 15 * $defaultSize;
                    }

                    .leftH2 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }

                .btm {
                    cursor: pointer;
                    width: 100 * $defaultSize;
                    display: inline-block;
                    padding: 8 * $defaultSize 2 * $defaultSize;
                    background: #03A76A;
                    border-radius: 5 * $defaultSize;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: normal;
                    color: #FFFFFF;
                    margin-top: 25 * $defaultSize;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>span {
                        display: block;
                        margin-right: 5 * $defaultSize;
                    }

                    &>img {
                        width: 15 * $defaultSize;
                        height: 15 * $defaultSize;
                    }
                }

                .btm:hover {
                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }

            .right {
                width: 60%;

                img {
                    width: 100%;
                }
            }
        }

        .box2 {
            margin-top: 88 * $defaultSize;
            display: flex;
            justify-content: space-between;

            .small {
                width: 18%;
                display: flex;
                align-items: center;

                img {
                    width: 35 * $defaultSize;
                    margin-right: 15 * $defaultSize;
                }

                span {
                    font-size: 12 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        .box3 {
            margin: 88 * $defaultSize 0;

            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                color: #000000;
                margin-bottom: 25 * $defaultSize;
                text-align: center;
            }

            .big {
                display: flex;
                justify-content: space-between;

                .small {
                    width: 30%;
                    text-align: center;
                    border-radius: 20 * $defaultSize;
                    opacity: 1;
                    border: 1px solid #CCCCCC;
                    padding: 25 * $defaultSize;

                    img {
                        width: 30 * $defaultSize;
                    }

                    .smallH1 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 19 * $defaultSize;
                    }

                    .smallH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }

                .small:hover {
                    border: 1px solid #224FD9;
                }
            }
        }

        .box4 {
            width: 100%;
            height: 353 * $defaultSize;
            background-image: url("../assets/Rectangle 159.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 113 * $defaultSize;
            padding-right: 53 * $defaultSize;

            img {
                width: 197 * $defaultSize;
            }

            .right {
                width: 315 * $defaultSize;

                .h1 {
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 600;
                    color: #333333;
                }

                .h2 {
                    margin-top: 10 * $defaultSize;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                }

                .h3 {
                    cursor: pointer;
                    margin-top: 20 * $defaultSize;
                    font-size: 14 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 600;
                    color: #333333;

                    display: flex;
                    align-items: center;

                    &>span {
                        display: block;
                        margin-right: 5 * $defaultSize;
                    }

                    &>img {
                        width: 12 * $defaultSize;
                        height: 12 * $defaultSize;
                    }
                }

                .h3:hover {
                    color: #1E4DD8;

                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }

        .box5 {
            width: 100%;
            height: 318 * $defaultSize;
            margin-top: 88 * $defaultSize;
            background-image: url("../assets/Rectangle 160.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            padding: 50 * $defaultSize 88 * $defaultSize;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 30px;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 500;
                color: #FFFFFF;
                margin-bottom: 40px !important;
            }

            .body {
                display: flex;
                flex-wrap: wrap;

                .small {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    margin-bottom: 25 * $defaultSize;

                    img {
                        width: 14 * $defaultSize;
                        margin-right: 10 * $defaultSize;
                    }

                    span {
                        font-size: 20px;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: normal;
                        color: #FFFFFF;
                    }
                }
            }

            .btn {
                cursor: pointer;
                border-radius: 4 * $defaultSize;
                padding: 9 * $defaultSize 13 * $defaultSize;
                font-size: 22px;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 600;
                color: #2652D9;
                background: #FFFFFF;

                display: flex;
                align-items: center;
            }

            .btn:hover {
                color: #1E4DD8;

                img {
                    animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                }
            }
        }
    }
}

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}
</style>
  
  