<template>
    <div class="manualTrading">
        <div class="box1">
            <div class="main box1Main">
                <div class="left">
                    <div class="top">
                        <div class="leftH1" style="display:flex;">
                            <span v-for="(item,index) in h1Arr($t('manualTrading.h1'))" :key="index" :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]" :style="{ animationDelay:`${index * 0.02}s`}">{{ item }}</span>
                            <span v-if="getI18nByEn()">&nbsp;</span>
                        </div>
                        <div class="leftH2" style="display:flex;">
                            <span v-for="(item,index) in h1Arr($t('manualTrading.h2'))" :key="index" :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]" 
                            :style="{ animationDelay:`${(index * 0.02) + (h1Arr($t('manualTrading.h1')).length *0.02)}s`}">
                            {{ item }}
                            <span v-if="getI18nByEn()">&nbsp;</span>
                            </span>
                        </div>
                    </div>
                    <div class="btm" @click="goFaq">
                        <span>{{$t('manualTrading.h3')}}</span>
                        <span class="solidSpan">
                            <img src="../assets/home/home_button_right_normal.svg" alt="">
                        </span>
                    </div>
                </div>
                <!-- <img class="right" src="../assets/merchantIMG.svg" alt=""> -->
            </div>
        </div>
        <div class="main">
            <div class="box2">
                <div class="h1">{{$t('manualTrading.h4')}}</div>
                <div class="h2">
                    <div class="h2left" v-animate="'animate__animated animate__fadeInLeft'">
                        <div class="t1">{{$t('manualTrading.h5')}}</div>
                        <div class="t2">{{$t('manualTrading.h6')}}</div>
                        <div class="t3">{{$t('manualTrading.h7')}}</div>
                    </div>
                    <div class="h2right " v-animate="'animate__animated animate__fadeInRight'">
                        <img src="../assets/Group 129.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="box3">  
                <div class="big">
                    <div class="small">
                        <img src="../assets/exchange-learn-more-4 1.svg" alt="">
                        <div class="h1">{{$t('manualTrading.h8')}}</div>
                        <div class="h2">{{$t('manualTrading.h9')}}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/exchange-learn-more-4 1 (1).svg" alt="">
                        <div class="h1">{{$t('manualTrading.h10')}}</div>
                        <div class="h2">{{$t('manualTrading.h11')}}</div>
                    </div>
                </div>
            </div>
            <div class="box4">
                <img src="../assets/manualBig.svg" alt="">
            </div>
            <div class="box2">
                <div class="h2">
                    <div class="h2left" v-animate="'animate__animated animate__fadeInLeft'">
                        <div class="t1">{{$t('manualTrading.h12')}}</div>
                        <div class="t2">{{$t('manualTrading.h13')}}</div>
                        <div class="t3">{{$t('manualTrading.h14')}}</div>
                    </div>
                    <div class="h2right" v-animate="'animate__animated animate__fadeInRight'">
                        <img src="../assets/Group 130.svg" alt="">
                    </div>
                </div>
            </div>
            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { NumSplic } from "../unit/tool";
  import {mapGetters} from "vuex"
  import big from "big.js";
import dowView from './simViews/dowView.vue';
  big.NE = -40;
  big.PE = 40;
  export default {
  components: { dowView },
    name: "manualTrading",
    data() {
      return {
        animationStatus:0
      };
    },
    methods: {
        getI18nByEn(){
      return this.$i18n.locale == 'en';
    },
        goFaq(){
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/shou-dong-jiao-yi/shen-me-shi-shou-dong-jiao-yi-zai-reactor-zhong-ru-he-jin-hang-shou-dong-jiao-yi', '_blank')
        },
        handleScroll(e) {
            this.animationStatus = 0
            if (this.getScrollTop() >= 2100) {
                this.animationStatus = 1
            }
      },
      //获取当前滚动条的位置
      getScrollTop(){
          var scrollTop=0
          if(document.documentElement&&document.documentElement.scrollTop){
              scrollTop = document.documentElement.scrollTop
          }else if(document.body){
              scrollTop = document.body.scrollTop
          }
          return scrollTop
      },
    },
    computed: {
        ...mapGetters(['GET_IsAnimation']),
        h1Arr(text){
      if(this.$i18n.locale == 'en'){
        return text => text.split(" ");
      }else{
        return text => text.split("")
      }
    }
    },
    mounted() {
      // 获取滚动容器的引用
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      // 移除滚动事件监听
      window.removeEventListener('scroll', this.handleScroll);
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $defaultSize: 0.0833333333vw;

  .textAnimation{
  display: block;
  animation: tracking-in-expand-fwd-bottom 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none{
  animation: none;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

@keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}

 @keyframes tracking-in-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
            transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    opacity: 0;
  }
  40% {
    opacity: 0.1;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

  .manualTrading {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1{
        .box1Main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
        // padding-top: 88 * $defaultSize;
        height: 400 * $defaultSize;
        // background-image: url("../assets/signalBGI.png");
        background: url("../assets/manualTradingBGI.svg") no-repeat;
        background-size: 100% 100%;
        justify-content: center;
        .left{
            width: 60%;
            .top{
                .leftH1{
                    font-size: 40 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 700;
                    color: #000000;
                    margin-bottom: 10 * $defaultSize;
                }
                .leftH2{
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                }
            }
            .btm {
                cursor: pointer;
                display: inline-block;
                padding: 10 * $defaultSize 16 * $defaultSize;
                background: #224FD9;
                border-radius: 5 * $defaultSize;
                font-size: 17 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: normal;
                color: #FFFFFF;
                margin-top: 25 * $defaultSize;
            }
            .btm:hover{
                background: #1F41A7;
                .solidSpan{
                    img{
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }
        .right{
            width: 35%;
            img {
                width: 100%;
            }
        }
    }
    .main {
        width: 750 * $defaultSize;
        margin: auto;
        .box2 {
            .h1 {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 600;
                color: #333333;
                margin-top: 50 * $defaultSize;
            }
            .h2 {
                margin-top: 4.16667vw;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .h2left {
                    width: 50%;
                    .t1 {
                        font-size: 14 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: 600;
                        color: #224FD9;
                    }
                    .t2 {
                        margin: 10 * $defaultSize 0;
                        font-size: 25 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: 700;
                        color: #333333;
                    }
                    .t3 {
                        font-size: 14 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #4F5559;
                    }
                }
                .h2right {
                    width: 35%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .box3 {
            padding-top: 88 * $defaultSize;
            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 700;
                color: #333333;
                margin-bottom: 20 * $defaultSize;
            }
            .big {
                display: flex;
                justify-content: space-between;
                .small {
                    width: 48%;
                    text-align: center;
                    img {
                        width: 181 * $defaultSize;
                    }
                    .h1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                    }
                    .h2 {
                        margin-top: 19 * $defaultSize;
                        font-size: 13 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }
        .box4 {
            padding-top: 88 * $defaultSize;
            img {
                width: 100%;
            }
        }
    }
  }
  </style>
  
  