<template>
    <div class="copyRobot">
        <div class="box1">
            <div class="main">
                <div class="left">
                    <div class="top">
                        <div class="leftH1" style="display:flex;">
                            <span v-for="(item, index) in h1Arr($t('interestRobot.h1'))" :key="index"
                                :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
                                :style="{ animationDelay: `${index * 0.02}s` }">{{ item }}
                                <span v-if="getI18nByEn()">&nbsp;</span>
                            </span>
                        </div>
                        <div class="leftH2" style="display:flex;">
                            <span v-for="(item, index) in h1Arr($t('interestRobot.h2'))" :key="index"
                                :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
                                :style="{ animationDelay: `${(index * 0.02) + (h1Arr($t('interestRobot.h1')).length * 0.02)}s` }">
                                {{ item }}
                                <span v-if="getI18nByEn()">&nbsp;</span>
                            </span>
                        </div>
                        <!-- <div class="leftH1">{{$t('interestRobot.h1')}}</div>
                        <div class="leftH2">{{$t('interestRobot.h2')}}</div> -->
                    </div>
                    <div class="btm" @click="asd">
                        <span>{{ $t('interestRobot.h3') }}</span>
                        <span class="solidSpan">
                            <img src="../assets/solid.svg" alt="">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box3">
                <div class="left">
                    <div class="h1">
                        {{ $t('interestRobot.h4') }}
                    </div>
                    <div class="h2">
                        {{ $t('interestRobot.h5') }}
                    </div>
                    <div class="h2">
                        <img src="../assets/interestRobot/left1.svg" alt="">
                    </div>
                </div>
                <div class="right">
                    <div class="small">
                        <div class="smallH1" @click="goFaq">
                            <img src="../assets/interestRobot/Group 156.svg" alt="">
                            {{ $t('interestRobot.h6') }}
                        </div>
                        <div class="smallH2">{{ $t('interestRobot.h7') }}</div>
                    </div>
                    <div class="small">
                        <div class="smallH1" @click="goFaq">
                            <img src="../assets/interestRobot/Group 156.svg" alt="">
                            {{ $t('interestRobot.h8') }}
                        </div>
                        <div class="smallH2">{{ $t('interestRobot.h9') }}</div>
                    </div>
                    <div class="small">
                        <div class="smallH1" @click="goFaq">
                            <img src="../assets/interestRobot/Group 156.svg" alt="">
                            {{ $t('interestRobot.h10') }}
                        </div>
                        <div class="smallH2">{{ $t('interestRobot.h11') }}</div>
                    </div>
                </div>
            </div>
            <div class="box4">
                <div class="left" v-animate="'animate__animated animate__fadeInLeft'">
                    <div class="h1">
                        {{ $t('interestRobot.h12') }}
                    </div>
                    <div class="h2">
                        {{ $t('interestRobot.h13') }}
                    </div>
                    <div class="h2">
                        {{ $t('interestRobot.h14') }}
                    </div>
                    <div class="h2">
                        {{ $t('interestRobot.h15') }}
                    </div>
                    <div class="h3 " v-animate="'animate__animated animate__fadeInDown'">
                        <div>
                            <animate-number ref="reNum1" from="0" :to="100" :key="100" duration="2000"></animate-number>+
                        </div>
                        <div>
                            <animate-number ref="reNum2" from="0" :to="20" :key="20" duration="2000"></animate-number>个
                        </div>
                        <div>
                            <animate-number ref="reNum3" from="0" :to="300" :key="300" duration="2000"></animate-number>+
                        </div>
                    </div>
                    <div class="h4">
                        <div>
                            {{ $t('interestRobot.h16') }}
                        </div>
                        <div>
                            {{ $t('interestRobot.h17') }}
                        </div>
                        <div>
                            {{ $t('interestRobot.h18') }}
                        </div>
                    </div>
                </div>
                <img class="right" v-animate="'animate__animated animate__fadeInRight'"
                    src="../assets/interestRobot/investment-thesis-visual 1.svg" alt="">
            </div>
            <div class="box5">
                <div class="top">
                    <div class="leftH1">{{ $t('interestRobot.h19') }}</div>
                    <div class="leftH2">{{ $t('interestRobot.h20') }}</div>
                </div>
                <div class="btm" @click="asdasd">
                    <span>{{ $t('interestRobot.h21') }}</span>
                    <span class="solidSpan">
                        <img src="../assets/solid.svg" alt="">
                    </span>
                </div>
            </div>

            <div class="box6">
                <div class="left">
                    <div class="h1">{{ $t('interestRobot.h22') }}</div>
                    <div class="h2">
                        {{ $t('interestRobot.h23') }}
                    </div>
                    <div class="h2">
                        {{ $t('interestRobot.h24') }}
                    </div>
                    <div class="h4" @click="asd2">
                        <span class="textSpan">
                            {{ $t('interestRobot.h25') }}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/copyRobot/home_icon_more_normal.svg" />
                        </span>
                    </div>
                </div>
                <img class="right" src="../assets/interestRobot/Group 164.svg" alt="">
            </div>

            <div class="box7">
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import { mapGetters } from "vuex"
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "copyRobot",
    data() {
        return {
        };
    },
    methods: {
        asd(){
            window.open('https://support.rac.art/chan-pin/tao-li-ji-qi-ren/shen-me-shi-tao-li-ji-qi-ren', '_blank')
        },
        asdasd(){
            window.open('https://support.rac.art/hui-yuan-quan-yi/shen-me-shi-zhuan-shu-fu-wu-qi-zhuan-shu-fu-wu-qi-you-shen-me-hao-chu', '_blank')
        },
        asd2(){
            window.open('https://support.rac.art/chan-pin/tao-li-ji-qi-ren/tao-li-ji-qi-ren-shi-fou-mei-ri-du-neng-wen-ding-zhuan-qu-shou-yi', '_blank')
        },
        handleScroll() {
            this.scrollValue = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
            console.log(this.scrollValue);
            if (this.scrollValue >= 1850 && this.scrollValue <= 1900) {
                this.$nextTick(() => {
                    this.$refs.reNum1.start()
                    this.$refs.reNum2.start()
                    this.$refs.reNum3.start()
                    this.$refs.reNum4.start()
                })
            }
        },
        getI18nByEn() {
            return this.$i18n.locale == 'en';
        },
        goFaq() {
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/tao-li-ji-qi-ren/tao-li-shi-ru-he-yun-zuo-de', '_blank')
        },
    },
    computed: {
        ...mapGetters(['GET_IsAnimation']),
        h1Arr(text) {
            if (this.$i18n.locale == 'en') {
                return text => text.split(" ");
            } else {
                return text => text.split("")
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.textAnimation {
    display: block;
    animation: tracking-in-expand-fwd-bottom 0.1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none {
    animation: none;
    animation-play-state: paused;
    animation-fill-mode: forwards;
}

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}

@keyframes tracking-in-expand-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        opacity: 0;
    }

    40% {
        opacity: 0.1;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

.copyRobot {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        padding-top: 80 * $defaultSize;
        display: flex;
        height: 375 * $defaultSize;
        background: url("../assets/interestRobot/top1.svg") no-repeat;
        background-size: 100% 100%;
        justify-content: center;
        width: 100%;

        .left {
            width: 100%;

            .top {
                .leftH1 {
                    font-size: 40 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 10 * $defaultSize;
                }

                .leftH2 {
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 400;
                    color: #4F5559;
                }
            }

            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;

                span {
                    margin-left: 10 *$defaultSize;
                }

                font-weight: 600;
                color: #FFFFFF;

                img {
                    width: 7 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .btm:hover {
                background: #1F41A7;

                .solidSpan {
                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }

        .right {
            width: 60%;

            img {
                width: 100%;
            }
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;
        margin-top: 30 * $defaultSize;
        margin-bottom: 80 * $defaultSize;

        .box3 {
            margin: 88 * $defaultSize 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .left {
                width: 46%;

                .h1 {
                    display: flex;
                    align-items: center;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 400;
                    color: #224FD9;
                    margin-bottom: 10 * $defaultSize;
                }

                .h2 {
                    font-size: 30 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 15 * $defaultSize;

                    img {
                        width: 90%;
                    }
                }
            }

            .right {
                width: 46%;

                .small {
                    height: 33%;
                    width: 100%;
                    opacity: 1;

                    // border: 1px solid #CCCCCC;
                    img {
                        width: 15 * $defaultSize;
                        margin-right: 10 * $defaultSize;
                    }

                    .smallH1 {
                        cursor: pointer;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 19 * $defaultSize;
                    }

                    .smallH1:hover {
                        color: #224FD9;

                        img {
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }

                    .smallH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }

                .small:hover {
                    .smallH1 {
                        color: #224FD9;

                        img {
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }

                    .smallH2 {
                        color: #333333;
                    }
                }
            }
        }

        .box4 {
            margin: 88 * $defaultSize 0;
            display: flex;

            .left {
                width: 50%;
                margin-right: 30 * $defaultSize;
                margin-top: 30 * $defaultSize;

                .h1 {
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 20 * $defaultSize;
                }

                .h2 {
                    font-size: 13 * $defaultSize;
                    font-family: Inter-Regular, Regular;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 20 * $defaultSize;
                }

                .h3 {
                    display: flex;
                    margin-top: 60 * $defaultSize;

                    div {
                        text-align: left;
                        width: 33%;
                        font-size: 30 * $defaultSize;
                        font-family: Inter-Black, Black;
                        font-weight: 900;
                        color: #2751D0;
                        margin-bottom: 10 * $defaultSize;
                    }
                }

                .h4 {
                    display: flex;

                    div {
                        text-align: left;
                        width: 33%;
                        font-size: 13 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 10 * $defaultSize;
                    }
                }
            }

            .right {
                margin-left: 60 * $defaultSize;
                width: 40%;
            }
        }

        .box5 {
            height: 375 * $defaultSize;
            background: url("../assets/interestRobot/Group 159.svg") no-repeat;
            background-size: 100%;
            padding: 50 * $defaultSize;
            color: #FFFFFF;

            .top {
                width: 40%;

                .leftH1 {
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    margin-bottom: 10 * $defaultSize;
                }

                .leftH2 {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Regular, Regular;
                    font-weight: 400;
                    margin-bottom: 10 * $defaultSize;
                }
            }

            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #03A76A;
                line-height: 35 * $defaultSize;
                border-radius: 8 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;

                span {
                    margin-left: 10 *$defaultSize;
                }

                font-weight: 600;
                color: #FFFFFF;

                img {
                    width: 7 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .btm:hover {
                background: #3A8459;

                .solidSpan {
                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }

        .box6 {
            display: flex;

            .left {
                width: 50%;

                .h3 {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #224FD9;
                    margin-bottom: 15 * $defaultSize;
                }

                .h1 {
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 25 * $defaultSize;
                }

                .h2 {
                    display: flex;
                    align-items: center;
                    font-size: 13 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 15 * $defaultSize;

                    img {
                        width: 15 * $defaultSize;
                        margin-right: 10 * $defaultSize;
                    }
                }

                .h4 {
                    cursor: pointer;
                    margin-top: 50* $defaultSize;
                    color: #333333;
                    font-size: 15 * $defaultSize;
                    font-weight: 600;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .solidSpan {
                        margin-left: 5* $defaultSize;
                        display: flex;
                        align-items: center;
                        /* 将子元素垂直居中对齐 */
                    }
                }

                .h4:hover {
                    color: #224FD9;

                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }

            .right {
                margin-left: 80 * $defaultSize;
                width: 45%;
            }
        }
    }
}
</style>
  
  