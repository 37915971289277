<template>
    <div class="market">
        <div class="main">
            <div class="box1">
                <div class="title">{{ $t('market.h1') }}</div>
                <div class="hint">{{ $t('market.h2') }}</div>
            </div>
            <div class="box2">
                <div class="title">{{ $t('market.h3') }} <span style="color: #00A76A;">+0.95%</span></div>
                <div class="box2Main">
                    <div class="small" @click="goToken('BTC')">
                        <div class="top">
                            <div class="left">
                                <img src="../assets/btc.png" alt="">
                                <div class="leftRight">
                                    <div class="leftTop">BTC</div>
                                    <div class="leftBtm">Bitcoin</div>
                                </div>
                            </div>
                            <div class="right">
                                <div id="myChartBtc"></div>
                            </div>
                        </div>
                        <div class="btm">
                            <div class="left">
                                {{ bitcoin.price_usd_changerate }} {{ $t('market.h4') }}
                            </div>
                            <div class="right">
                                {{ $t('market.h5') }} +2.93%
                            </div>
                        </div>
                    </div>
                    <div class="small" @click="goToken('ETH')">
                        <div class="top">
                            <div class="left">
                                <img src="../assets/eth.png" alt="">
                                <div class="leftRight">
                                    <div class="leftTop">ETH</div>
                                    <div class="leftBtm">Ethereum</div>
                                </div>
                            </div>
                            <div class="right">
                                <div id="myChartEth"></div>
                            </div>
                        </div>
                        <div class="btm">
                            <div class="left">
                                {{ ethereum.price_usd_changerate }} {{ $t('market.h4') }}
                            </div>
                            <div class="right">
                                {{ $t('market.h5') }} +2.93%
                            </div>
                        </div>
                    </div>
                    <div class="small" @click="goToken('BNB')">
                        <div class="top">
                            <div class="left">
                                <img src="../assets/bnb.png" alt="">
                                <div class="leftRight">
                                    <div class="leftTop">BNB</div>
                                    <div class="leftBtm">binance</div>
                                </div>
                            </div>
                            <div class="right">
                                <div id="myChartBnb"></div>
                            </div>
                        </div>
                        <div class="btm">
                            <div class="left">
                                {{ binance.price_usd_changerate }} {{ $t('market.h4') }}
                            </div>
                            <div class="right">
                                {{ $t('market.h5') }} +2.93%
                            </div>
                        </div>
                    </div>
                </div>
                <img src="../assets/tuoyuan.png" class="tuoyuan1" alt="">
                <img src="../assets/tuoyuan.png" class="tuoyuan2" alt="">
            </div>
            <div class="box3">
                <div class="header">
                    <div class="left">
                        <div class="small" @click="marketTypeFind('binance')"
                            :style="marketType == 'binance' ? 'color:#2751D0' : 'color:#333333'">{{ $t('market.h6') }}</div>
                        <div class="small" @click="marketTypeFind('okex')"
                            :style="marketType == 'okex' ? 'color:#2751D0' : 'color:#333333'">{{ $t('market.h7') }}</div>
                        <div class="small" @click="marketTypeFind('huobipro')"
                            :style="marketType == 'huobipro' ? 'color:#2751D0' : 'color:#333333'">{{ $t('market.h8') }}
                        </div>
                        <div class="small" @click="marketTypeFind('UniSwap')"
                            :style="marketType == 'UniSwap' ? 'color:#2751D0' : 'color:#333333'">UniSwap</div>
                        <!-- <div class="small" @click="marketTypeFind('Curve')" :style="marketType=='Curve'?'color:#2751D0':'color:#333333'">Curve</div>
                        <div class="small" @click="marketTypeFind('Balancer')" :style="marketType=='Balancer'?'color:#2751D0':'color:#333333'">Balancer</div>
                        <div class="small" @click="marketTypeFind('PancakeSwap')" :style="marketType=='PancakeSwap'?'color:#2751D0':'color:#333333'">PancakeSwap</div>
                        <div class="small" @click="marketTypeFind('DODO')" :style="marketType=='DODO'?'color:#2751D0':'color:#333333'">DODO</div> -->
                        <div class="small" @click="marketTypeFind('SushiSwap')"
                            :style="marketType == 'SushiSwap' ? 'color:#2751D0' : 'color:#333333'">SushiSwap</div>
                    </div>
                    <div class="right">
                        <el-input class="custom-input" placeholder="请输入内容" v-model="input4">
                            <i slot="prefix" class="el-input__icon el-icon-search "></i>
                        </el-input>
                    </div>
                </div>
                <div class="body">
                    <div class="tbHeader">
                        <div class="small bname">{{ $t('market.h9') }}</div>
                        <div class="small" :style="sortStatus == 1 ? 'color:#2751D0' : 'color:#333333'" @click="priceSort">
                            {{ $t('market.h10') }}
                            <img v-if="sortStatus == 1 && sortType == 1" src="../assets/market/sortNext.svg" />
                            <img v-if="sortStatus == 1 && sortType == 2" src="../assets/market/sortTop.svg" />
                        </div>
                        <div class="small" :style="sortStatus == 2 ? 'color:#2751D0' : 'color:#333333'"
                            @click="changePercentSort">{{ $t('market.h11') }}
                            <img v-if="sortStatus == 2 && sortType == 1" src="../assets/market/sortNext.svg" />
                            <img v-if="sortStatus == 2 && sortType == 2" src="../assets/market/sortTop.svg" />
                        </div>
                        <div class="small" :style="sortStatus == 3 ? 'color:#2751D0' : 'color:#333333'" @click="volUsdSort">
                            {{
                                $t('market.h12') }}
                            <img v-if="sortStatus == 3 && sortType == 1" src="../assets/market/sortNext.svg" />
                            <img v-if="sortStatus == 3 && sortType == 2" src="../assets/market/sortTop.svg" />
                        </div>
                        <div class="small" :style="sortStatus == 4 ? 'color:#2751D0' : 'color:#333333'"
                            @click="valueUsdSort">{{
                                $t('market.h13') }}
                            <img v-if="sortStatus == 4 && sortType == 1" src="../assets/market/sortNext.svg" />
                            <img v-if="sortStatus == 4 && sortType == 2" src="../assets/market/sortTop.svg" />
                        </div>
                    </div>
                    <div class="tbBody" v-for="(data, index) in currencyList" :key="index" @click="goToken33(data, index)">
                        <div class="td">
                            <div class="small name bname">
                                <img :src="data.logo" alt="">
                                <div class="info">
                                    <div class="infoName">{{ data.symbol }}/{{ data.pair2 }}</div>
                                    <div class="infoSymbol">{{ data.name_zh }}</div>
                                </div>
                            </div>
                            <div class="small">${{ data.price }}</div>
                            <div class="small green">{{ data.accounting }}%</div>
                            <div class="small">${{ formattedNumber(data.vol) }}</div>
                            <div class="small">${{ formattedNumber(data.volume) }}</div>
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <div class="left">共{{ total }}条</div>
                    <div class="right">
                        <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
                            layout="prev, pager, next" :page-size="pageSize" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';
import { NumSplic } from "../unit/tool";
import big from "big.js";
import axios from 'axios';
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "market",
    data() {
        return {
            marketType: 'binance',
            total: 0,
            currentPage: 1,
            pageSize: 20,
            input4: undefined,
            btcCharts: {
                xAxis: {
                    data: ['02:36 PM', '07:24PM', '12:12 AM', '04:59 AM', '09:47 AM',],
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                },
                yAxis: {
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                },
                series: [
                    {
                        data: [1, 2, 1, 3, 1],
                        type: 'line',
                    },
                ]
            },
            ethereum: {
                price_usd_changerate: 0,
                value: [],
                minValue: 0,
                maxValue: 0,
                startValue: 0,
                stopValue: 0,
            },
            bitcoin: {
                price_usd_changerate: 0,
                value: [],
                minValue: 0,
                maxValue: 0,
                startValue: 0,
                stopValue: 0,
            },
            binance: {
                price_usd_changerate: 0,
                value: [],
                minValue: 0,
                maxValue: 0,
                startValue: 0,
                stopValue: 0,
            },
            currencyList: [],
            sortType: 0, // 从大到小
            sortStatus: 0,
        };
    },
    created() {
        this.getData();
        this.fetchData();
    },
    methods: {
        formattedNumber(val) {
            const roundedNumber = Math.round(val * 100) / 100; // 小数点四舍五入保留两位小数
            const parts = roundedNumber.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },
        goToken33(code, n) {
            console.log(code);
            console.log(n);
            if (this.$router.currentRoute.path === '/token' && this.zhi == n) {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.zhi = n;
            this.$router.push({
                path: "/token",
                query: { asd: code.symbol, zhi: this.zhi },
            });
        },
        marketTypeFind(type) {
            this.marketType = type;
            this.fetchData();
        },
        priceSort() {
            console.log(this.sortType);
            if (this.currencyList) {
                if (this.sortType == 2) {
                    this.sortType = 0;
                    return
                }
                this.sortStatus = 1;
                if (this.sortType == 0) {
                    this.currencyList.sort((a, b) => b.low - a.low);
                    this.sortType += 1;
                } else if (this.sortType == 1) {
                    this.currencyList.sort((a, b) => a.low - b.low);
                    this.sortType += 1;
                }
            }
        },
        changePercentSort() {
            if (this.currencyList) {
                if (this.sortType == 2) {
                    this.sortType = 0;
                    return
                }
                this.sortStatus = 2
                if (this.sortType) {
                    this.currencyList.sort((a, b) => b.changerate_utc - a.changerate_utc);
                    this.sortType += 1;
                } else {
                    this.currencyList.sort((a, b) => a.changerate_utc - b.changerate_utc);
                    this.sortType += 1;
                }
            }
        },
        volUsdSort() {
            if (this.currencyList) {
                if (this.sortType == 2) {
                    this.sortType = 0;
                    return
                }
                this.sortStatus = 3
                if (this.sortType) {
                    this.currencyList.sort((a, b) => b.price_cny - a.price_cny);
                    this.sortType += 1;
                } else {
                    this.currencyList.sort((a, b) => a.price_cny - b.price_cny);
                    this.sortType += 1;
                }
            }
        },
        valueUsdSort() {
            if (this.currencyList) {
                if (this.sortType == 2) {
                    this.sortType = 0;
                    return
                }
                this.sortStatus = 4
                if (this.sortType) {
                    this.currencyList.sort((a, b) => b.volume - a.volume);
                    this.sortType += 1;
                } else {
                    this.currencyList.sort((a, b) => a.volume - b.volume);
                    this.sortType += 1;
                }
            }
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.fetchData();
        },
        // 查询货币列表
        fetchData() {
            // axios.get('https://ow.rac.art/api/v3/coin/futures?page=' + this.currentPage + '&per_page=' + this.pageSize
            //     + '&market=USDT&exchangecode=' + this.marketType + '&webp=1')
            //     .then(response => {
            //         this.currencyList = response.data.data.list;
            //         this.total = response.data.data.total_count;
            //     })
            //     .catch(error => {
            //     });
            axios.post('https://ow.rac.art/api/exchange/coinpair_list', {
                "page": this.currentPage,
                "code": this.marketType,
                "pagesize": this.pageSize,
                "token": "",
                "pair2": "",
                "tickertype": 0,
                "webp": 1
            }).then((res) => {
                this.currencyList = res.data.data.markets;
                this.total = res.data.data.total_count;
                console.log(this.currencyList);
            }).catch(error => {
            });

            // 发起GET请求
            // axios.get('https://ow.rac.art/api/coin/web-coinrank?page=' + this.currentPage + '&type=-1&pagesize=' + this.pageSize + '&webp=1')
            //     .then(response => {
            //         this.currencyList = response.data.data;
            //         this.total = response.data.maxpage;
            //     })
            //     .catch(error => {
            //     });
        },
        goToken() {
        },
        getData() {
            // 发起GET请求ethereum
            axios.get('https://ow.rac.art/api/coin/web-charts?code=ethereum&type=all&webp=1')
                .then(response => {
                    const par = [];
                    const dataList = response.data.value.split("],");
                    dataList.forEach((item, index) => {
                        // 去掉字符串首尾的方括号，得到只包含数字的字符串
                        const substring = item.substring(1);
                        // 使用逗号分割得到数字数组
                        const numberArray = substring.split(",");
                        par.push(numberArray[1]);
                    })
                    this.ethereum.maxValue = Math.max(...par);
                    this.ethereum.minValue = Math.min(...par);
                    this.ethereum.value = par;
                    this.drawLine(par, 1);
                    this.ethereum.price_usd_changerate = response.data.price_usd_changerate;
                })
                .catch(error => {
                });
            // 发起GET请求bitcoin
            axios.get('https://ow.rac.art/api/coin/web-charts?code=bitcoin&type=all&webp=1')
                .then(response => {
                    const par = [];
                    const dataList = response.data.value.split("],");
                    dataList.forEach((item, index) => {
                        // 去掉字符串首尾的方括号，得到只包含数字的字符串
                        const substring = item.substring(1);
                        // 使用逗号分割得到数字数组
                        const numberArray = substring.split(",");
                        par.push(numberArray[1]);
                    })
                    this.bitcoin.maxValue = Math.max(...par);
                    this.bitcoin.minValue = Math.min(...par);
                    this.bitcoin.value = par;
                    this.drawLine(par, 2);
                    this.bitcoin.price_usd_changerate = response.data.price_usd_changerate;
                    // this.bitcoin = response.data;
                })
                .catch(error => {
                });

            // 发起GET请求binance-coin
            axios.get('https://ow.rac.art/api/coin/web-charts?code=binance-coin&type=all&webp=1')
                .then(response => {
                    const par = [];
                    const dataList = response.data.value.split("],");
                    dataList.forEach((item, index) => {
                        // 去掉字符串首尾的方括号，得到只包含数字的字符串
                        const substring = item.substring(1);
                        // 使用逗号分割得到数字数组
                        const numberArray = substring.split(",");
                        par.push(numberArray[1]);
                    })
                    this.binance.maxValue = Math.max(...par);
                    this.binance.minValue = Math.min(...par);
                    this.binance.value = par;
                    this.drawLine(par, 3);
                    this.binance.price_usd_changerate = response.data.price_usd_changerate;
                    // this.binance = response.data;
                })
                .catch(error => {
                });
        },
        drawLine(value, type) {
            // 绘制图表
            const btcCharts = {
                xAxis: {
                    data: Array.from(Array(value.length).keys()),
                    // data: ['02:36 PM', '07:24PM', '12:12 AM', '04:59 AM', '09:47 AM',],
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                },
                yAxis: {
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                },
                series: [
                    {
                        data: value,
                        type: 'line',
                        color: '#00A76A'
                    },
                ]
            }
            if (type == 1) {
                let myChartBtc = echarts.init(document.getElementById("myChartBtc"));
                myChartBtc.setOption(btcCharts);
            }
            if (type == 2) {
                let myChartEth = echarts.init(document.getElementById("myChartEth"));
                // 绘制图表
                myChartEth.setOption(btcCharts);
            }
            if (type == 3) {
                let myChartBnb = echarts.init(document.getElementById("myChartBnb"));
                // 绘制图表
                myChartBnb.setOption(btcCharts);
            }
        },
    },
    computed: {},
    mounted() {
        // this.drawLine();
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #224FD9; //修改后的背景图颜色
    color: #fff;
}

.market {
    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .box1 {
            .title {
                margin-top: 88 * $defaultSize;
                text-align: center;
                font-size: 40 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
            }

            .hint {
                margin-top: 10 * $defaultSize;
                font-size: 20 * $defaultSize;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4F5559;
            }
        }

        .box2 {
            margin-top: 35 * $defaultSize;
            width: 100%;
            height: 198 * $defaultSize;
            background: #FFFFFF;
            border-radius: 20 * $defaultSize;
            padding: 25 * $defaultSize;
            position: relative;

            .title {
                font-size: 18 * $defaultSize;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }

            .box2Main {
                display: flex;
                justify-content: space-between;
                margin-top: 10 * $defaultSize;

                .small {
                    cursor: pointer;
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    border-radius: 15 * $defaultSize;
                    border: 1px solid #D8D8D8;

                    padding: 15 * $defaultSize 15 * $defaultSize;

                    .top {
                        display: flex;

                        .left {
                            width: 50%;
                            display: flex;
                            align-items: center;

                            img {
                                width: 27 * $defaultSize;
                                margin-right: 5 * $defaultSize;
                            }

                            .leftRight {
                                display: flex;
                                flex-direction: column;

                                .leftTop {
                                    font-size: 14 * $defaultSize;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: #333333;
                                }

                                .leftBtm {
                                    font-size: 11 * $defaultSize;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #4F5559;
                                }
                            }
                        }

                        .right {
                            width: 50%;

                            // height: 83 * $defaultSize;
                            #myChartBtc {
                                width: 100%;
                                height: 100%;
                            }

                            #myChartEth {
                                width: 100%;
                                height: 100%;
                            }

                            #myChartBnb {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .btm {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 5 * $defaultSize;

                        .left {
                            font-size: 11 * $defaultSize;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }

                        .right {
                            font-size: 10 * $defaultSize;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #999999;
                        }
                    }
                }

                .small:hover {
                    border-color: #1F4ED8;
                    /* 鼠标悬停时修改边框颜色为红色 */
                }
            }

            .tuoyuan1 {
                width: 440 * $defaultSize;
                position: absolute;
                left: -30%;
                top: -80%;
                z-index: -10;
            }

            .tuoyuan2 {
                width: 440 * $defaultSize;
                position: absolute;
                right: -25%;
                bottom: -80%;
                z-index: -10;
            }
        }

        .box3 {
            background: #FFFFFF;
            border-radius: 20 * $defaultSize;
            margin-top: 35 * $defaultSize;

            .header {
                display: flex;
                justify-content: space-between;
                height: 65 * $defaultSize;
                align-items: center;
                padding: 0 25 * $defaultSize;

                .left {
                    display: flex;

                    .small {
                        cursor: pointer;
                        font-size: 13 * $defaultSize;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        margin-right: 25 * $defaultSize;
                    }

                    .small:hover {
                        color: #1F4ED8;
                    }

                    .check {
                        color: #224FD9;
                    }
                }

                .right {
                    .custom-input:hover {
                        border-radius: 4px;
                        box-shadow: 0 0 0 1px #1F4ED8;
                        /* 鼠标悬停时修改边框颜色为红色 */
                    }
                }
            }

            .body {
                border-radius: 20px;
                border: 1px solid #D8D8D8;
                padding: 0 20 * $defaultSize;

                .tbHeader {
                    display: flex;
                    border-bottom: 1px solid #D8D8D8;

                    .small {

                        cursor: pointer;
                        width: 20%;
                        font-size: 11 * $defaultSize;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        padding: 20 * $defaultSize 0;
                    }

                    .bname {
                        width: 30%;
                        padding-left: 15 * $defaultSize;
                    }

                    .small:hover {
                        color: #2751D0 !important;
                    }
                }

                .tbBody {

                    .td {

                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;

                        .small {
                            width: 20%;
                            font-size: 14 * $defaultSize;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            padding: 15 * $defaultSize 0 * $defaultSize 15 * $defaultSize 15 * $defaultSize;
                            border-bottom: 1px solid #D8D8D8;
                            display: flex;
                            align-items: center;
                        }

                        .name {
                            img {
                                width: 27 * $defaultSize;
                                margin-right: 9 * $defaultSize;
                            }

                            .info {
                                .infoName {
                                    font-size: 14 * $defaultSize;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #333333;

                                }

                                .infoSymbol {
                                    font-size: 11 * $defaultSize;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #4F5559;
                                }
                            }
                        }

                        .bname {
                            width: 30%;
                        }

                        .green {
                            color: #00A76A;
                        }
                    }

                    .td:hover {
                        background-color: #EAEEFB;
                    }
                }
            }

            .foot {
                display: flex;
                justify-content: space-between;
                margin-top: 20 * $defaultSize;

                .left {
                    font-size: 13 * $defaultSize;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .right {}
            }
        }
    }
}
</style>
  