import Vue from 'vue'
import VueRouter from 'vue-router' 
import home from '../views/home.vue'
import token from '../views/token.vue'
import exchange from '../views/exchange.vue'
import exchange1 from '../views/exchange1.vue'
import exchange2 from '../views/exchange2.vue'
import exchange3 from '../views/exchange3.vue'
import exchange4 from '../views/exchange4.vue'
import exchange5 from '../views/exchange5.vue'
import exchange6 from '../views/exchange6.vue'
import exchange7 from '../views/exchange7.vue'
import exchange8 from '../views/exchange8.vue'
import market from '../views/market.vue'
import market2 from '../views/market2.vue'
import join from '../views/join.vue'
import about from '../views/about.vue'
import community from '../views/community.vue'
import cooperation from '../views/cooperation.vue'
import download from '../views/download.vue'
import helpCenter from '../views/helpCenter.vue'
import faq from '../views/faq.vue'
import wallet from '../views/wallet.vue'
import rebate from '../views/rebate.vue'
import signal from '../views/signal.vue'
import strategy from '../views/strategy.vue'
import chainData from '../views/chainData.vue'
import exchangeRobot from '../views/exchangeRobot.vue'
import copyRobot from '../views/copyRobot.vue'
import interestRobot from '../views/interestRobot.vue'
import demoRobot from '../views/demoRobot.vue'
import merchantRobot from '../views/merchantRobot.vue'
import policyBacktest from '../views/policyBacktest.vue'
import DCA from '../views/DCA.vue'
import manualTrading from '../views/manualTrading.vue'
import strategyMall from '../views/strategyMall.vue'
import business from '../views/business.vue'

import reactor from '../views/reactor/reactor.vue'
import ziyuan from '../views/ziyuan/ziyuan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      metaInfo: {
        title: "Reactor-赚取稳定收益的加密货币交易利器",
        keywords: "Reactor,智能交易终端,量化交易机器人,加密货币交易,稳定收益,持续盈利,智能算法,高效执行,赚钱秘诀",
        description: "专为加密货币交易而设计，通过独特的交易策略和快速执行能力，帮助您捕捉市场机会，最大化利润，并实现加密货币交易的成功。释放您的赚钱潜能。"
      }
    }
  },
  {
    path: '/ziyuan',
    name: 'ziyuan',
    component: ziyuan,
    meta: {
      metaInfo: {
        title: "Reactor-赚取稳定收益的加密货币交易利器",
        keywords: "Reactor,智能交易终端,量化交易机器人,加密货币交易,稳定收益,持续盈利,智能算法,高效执行,赚钱秘诀",
        description: "专为加密货币交易而设计，通过独特的交易策略和快速执行能力，帮助您捕捉市场机会，最大化利润，并实现加密货币交易的成功。释放您的赚钱潜能。"
      }
    }
  },
  {
    path: '/reactor',
    name: 'reactor',
    component: reactor,
    meta: {
      metaInfo: {
        title: "Reactor-赚取稳定收益的加密货币交易利器",
        keywords: "Reactor,智能交易终端,量化交易机器人,加密货币交易,稳定收益,持续盈利,智能算法,高效执行,赚钱秘诀",
        description: "专为加密货币交易而设计，通过独特的交易策略和快速执行能力，帮助您捕捉市场机会，最大化利润，并实现加密货币交易的成功。释放您的赚钱潜能。"
      }
    }
  },
  {
    path: '/token',
    name: 'token',
    component: token,
    meta: {
      metaInfo: {
        title: "实时行情、关键指标和投资参考",
        keywords: "Reactor、加密货币、实时行情、关键指标、加密货币投资、币种趋势、投资决策、币种数据、",
        description: "了解币种的成交量、名称、合约、池子等重要信息，把握币种的动态和趋势，助您做出明智的投资决策。"
      }
    }
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: exchange,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange1',
    name: 'exchange1',
    component: exchange1,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange2',
    name: 'exchange2',
    component: exchange2,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange3',
    name: 'exchange3',
    component: exchange3,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange4',
    name: 'exchange4',
    component: exchange4,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange5',
    name: 'exchange5',
    component: exchange5,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange6',
    name: 'exchange6',
    component: exchange6,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange7',
    name: 'exchange7',
    component: exchange7,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/exchange8',
    name: 'exchange8',
    component: exchange8,
    meta: {
      metaInfo: {
        title: "汇集主流加密货币交易所的信息中心",
        keywords: "Reactor、加密货币交易所、去中心化交易所、币安、火币、OKX、dYdX、DODO、UniSwap、Swap量化、加密货币交易、加密市场机会",
        description: "我们提供了主流加密货币交易所的信息展示。您可以查看各大交易所的信息，同时享受我们提供的强大交易功能和智能工具。"
      }
    }
  },
  {
    path: '/market',
    name: 'market',
    component: market,
    meta: {
      metaInfo: {
        title: "探索丰富多样的加密货币投资机会",
        keywords: "Reactor、币种列表、加密货币投资、量化交易、区块链行情、投资机会、收益潜力",
        description: "数千种加密货币可供筛选，量化投资的首选！与知名交易所合作，提供多元化投资选择。高效研究和分析，制定策略，获得更好回报。"
      }
    }
  },
  {
    path: '/market2',
    name: 'market2',
    component: market2,
    meta: {
      metaInfo: {
        title: "探索丰富多样的加密货币投资机会",
        keywords: "Reactor、币种列表、加密货币投资、量化交易、区块链行情、投资机会、收益潜力",
        description: "数千种加密货币可供筛选，量化投资的首选！与知名交易所合作，提供多元化投资选择。高效研究和分析，制定策略，获得更好回报。"
      }
    }
  },
  {
    path: '/join',
    name: 'join',
    component: join,
    meta: {
      metaInfo: {
        title: "加入Reactor会员计划，开启投资超级之旅！",
        keywords: "Reactor会员计划、独一无二的策略、高级市场分析、投资管理组合、独家特权、投资服务、社群互动、学习资源、复制跟踪机器人交易",
        description: "您将获得独家特权，享受优质的投资服务和无与伦比的体验。社群互动和学习资源结合，您的投资之路将更加精彩！"
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {
      metaInfo: {
        title: "关于TIANYI-一家专注于应用程序开发和网络制作的公司",
        keywords: "数字化工程解决方案、应用程序开发、Web3技术、视觉设计与创意、应用设计与策划、服务器与网络、营销与办公系统、一站式服务、服务器和IDC网络、区块链",
        description: "我们提供多领域的专业解决方案和创意服务，致力于为您提供一站式的服务。联系我们，预约参观我们的办公室，一同开启数字化时代的创新旅程！"
      }
    }
  },
  {
    path: '/community',
    name: 'community',
    component: community,
    meta: {
      metaInfo: {
        title: "加入Reactor社区，探索加密世界的精彩！",
        keywords: "Reactor社区、活动资讯、在线支持、加密市场趋势、量化策略、社交媒体、加密爱好者、自由分享",
        description: "获取最新的活动资讯、在线支持和专业工作人员的解答。与志同道合的加密爱好者结识和自由分享捕捉加密市场趋势，探索新的量化策略，"
      }
    }
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: cooperation,
    meta: {
      metaInfo: {
        title: "全球公司合作伙伴，引领创新技术解决方案",
        keywords: "Tianyi、全球合作伙伴、创新技术解决方案、Web3区块链项目、区块链行业创新、加密货币",
        description: "Tianyi与全球范围内的合作伙伴紧密合作，我们致力于引领区块链行业的创新发展，为客户提供卓越的解决方案和协作机会。"
      }
    }
  },
  {
    path: '/download',
    name: 'download',
    component: download,
    meta: {
      metaInfo: {
        title: "免费下载Reactor智能交易终端",
        keywords: "免费下载、Reactor交易平台、Windows下载、Mac OS下载、客户端下载、桌面端下载",
        description: "无论您使用的是Windows还是Mac OS，都能获得专业的交易服务。Reactor提供全业务功能支持，保护您的资产安全，帮助您洞察市场趋势，并提供迅捷高效的交易体验。选择Reactor，开启您的量化交易之旅！"
      }
    }
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: helpCenter,
    meta: {
      metaInfo: {
        title: "帮助中心-寻找答案与了解Reactor",
        keywords: "帮助中心、市场分析、量化策略、交易工具、设置指南、机器人、链上数据、常见问题",
        description: "我们为您提供详细信息和支持。包括Reactor的功能设置、常见问题解答以及各种量化交易策略的文章。"
      }
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: faq,
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: wallet,
    meta: {
      metaInfo: {
        title: "Web3的量化交易钱包",
        keywords: "Reactor钱包、Web3量化交易、个人资产安全、多链环境、定制化策略、HD钱包、多元化投资、",
        description: "保护个人资产安全、跨越多个区块链的无缝融合、智能低Gas费路线以及定制化策略等功能，助您管理投资组合并在多链环境中获得强力支持。"
      }
    }
  },
  {
    path: '/rebate',
    name: 'rebate',
    component: rebate,
    meta: {
      metaInfo: {
        title: "邀请朋友，赚取返佣！分享链接，轻松获得额外收益。",
        keywords: "Reactor返佣、推广链接、额外收益、邀请朋友、佣金奖励、分享链接、无限制推广",
        description: "通过分享您的推荐链接，邀请您的朋友加入Reactor，每当您的朋友通过您的链接购买订阅时，您都能获得高达15%的丰厚佣金奖励。无限制的推广机会，推广越多，奖励越多！快来分享您的链接，与朋友一起赚取额外收益吧！"
      }
    }
  },
  {
    path: '/signal',
    name: 'signal',
    component: signal,
    meta: {
      metaInfo: {
        title: "获取高质量交易信号，稳定投资回报",
        keywords: "信号商城，交易信号，自动化交易，投资回报，跟随专业交易者，信号跟单",
        description: "信号商城是自动化交易的智慧引擎，提供高质量的交易信号模版。这些信号经过严格的筛选和验证，包括专业交易者的实际操作，如买入点、卖出点、止损位等关键指标。您可以订阅并自动复制这些信号，以帮助您更准确地选择适合自己的交易信号，实现稳定的投资回报。"
      }
    }
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: strategy,
    meta: {
      metaInfo: {
        title: "定制您的独特交易策略，获得个性化的交易解决方案",
        keywords: "私人订制交易策略，个性化交易解决方案，量化策略师，专业建议，特邀策略师，专用服务器，定制化服务，独特交易体验",
        description: "私人订制页面为您提供独特的交易体验。与专属的量化策略师合作，享受个性化的交易支持和专业建议。我们致力于为您量身定制解决方案，满足您的交易目标和要求。"
      }
    }
  },
  {
    path: '/chainData',
    name: 'chainData',
    component: chainData,
    meta: {
      metaInfo: {
        title: "K线与链上数据分析，实现精准交易决策",
        keywords: "Reactor，K线与链上数据，市场洞察，精准交易决策，实时市场数据，区块链交易追踪，智能合约分析，自定义指标分析。",
        description: "查看历史价格走势，并追踪区块链网络中的交易数据。利用基于K线和链上数据的分析，生成准确的交易信号，帮助您做出更明智的交易决策。"
      }
    }
  },
  {
    path: '/exchangeRobot',
    name: 'exchangeRobot',
    component: exchangeRobot,
    meta: {
      metaInfo: {
        title: "交易机器人-精确执行您的交易策略",
        keywords: "交易机器人，参数设置，风险管理，自动化交易，个性化策略，买入策略，卖出策略，高级配置参数，止损设置",
        description: "通过灵活的参数设置和个性化的风险管理，您可以精确执行交易策略，实现自动化交易，并根据自身需求进行策略参数的灵活调整。"
      }
    }
  },
  {
    path: '/copyRobot',
    name: 'copyRobot',
    component: copyRobot,
    meta: {
      metaInfo: {
        title: "复制顶级交易者的超凡表现，获取独特交易策略",
        keywords: "资讯,新闻,财经,复制机器人，代单老师，独特交易策略，自动跟单，个性化参数，交易记录，交易师表现，胜率，盈亏比",
        description: "复制机器人通过与顶级交易者合作，让您完全自动地跟随其买入和卖出行为。您可以根据自身需求进行优化，通过查看资产、交易记录和详细数据，深入了解交易师的表现和交易结果汇总。利用胜率、负率和盈亏比等指标，您可以更好地评估交易师的实力和潜在盈利机会。"
      }
    }
  },
  {
    path: '/interestRobot',
    name: 'interestRobot',
    component: interestRobot,
    meta: {
      metaInfo: {
        title: "捕捉价差，稳定收益的套利机器人",
        keywords: "套利机器人，价差捕捉，稳定收益，跨交易所套利，市场监测，快速执行交易，每日收益",
        description: "Reactor的套利机器人支持同时连接多个交易平台和区块链，能够进行跨交易所的套利操作。套利机器人通过市场扫描和比较，实时监测和报告价格差异，并快速执行交易。"
      }
    }
  },
  {
    path: '/demoRobot',
    name: 'demoRobot',
    component: demoRobot,
    meta: {
      metaInfo: {
        title: "实时模拟交易，零风险体验的DEMO机器人",
        keywords: "实时模拟交易，零风险体验，DEMO机器人，交易技能，交易策略优化，模拟交易历史，交易分析",
        description: "DEMO机器人为您提供一个无风险的环境，让您能够探索交易世界。使用虚拟资金进行交易操作，并实时体验市场的价格波动。您可以通过模拟交易参数和历史记录来优化和调整交易策略，评估交易结果和绩效，并进行必要的改进。DEMO机器人是提升交易技能、建立信心和经验的理想选择。"
      }
    }
  },
  {
    path: '/merchantRobot',
    name: 'merchantRobot',
    component: merchantRobot,
    meta: {
      metaInfo: {
        title: "提升交易效果，享受Reactor做市商机器人的优势",
        keywords: "做市商机器人，优化交易，提升体验，稳定收益模式，流动性提供，实时市场监控，自动化交易，量化交易算法，灵活配置选项",
        description: "Reactor的做市商机器人通过稳定的收益模式、流动性提供、实时市场监控和自动化交易等优势，为您带来稳定的收益并提供优质的交易执行。"
      }
    }
  },
  {
    path: '/policyBacktest',
    name: 'policyBacktest',
    component: policyBacktest,
    meta: {
      metaInfo: {
        title: "策略回测-精确评估交易策略并提升回报率",
        keywords: "Reactor策略回测，交易策略评估，历史市场数据，模拟交易，优化参数，回测报告，图表展示，精确评估，提升回报率。",
        description: "根据自己的交易策略，在历史市场数据上进行模拟交易，以评估策略的效果和潜在盈利能力。详细的回测报告和图表展示帮助用户全面了解策略的表现和变化，为更好的交易决策提供指导。"
      }
    }
  },
  {
    path: '/DCA',
    name: 'DCA',
    component: DCA,
    meta: {
      metaInfo: {
        title: "DCA定投策略-实现稳定投资回报",
        keywords: "DCA定投策略，稳定投资回报，平均成本，市场波动，加密货币投资。",
        description: "DCA策略带来稳定投资和平均成本的优势，帮助您降低市场风险，实现稳定的投资回报。"
      }
    }
  },
  {
    path: '/manualTrading',
    name: 'manualTrading',
    component: manualTrading,
    meta: {
      metaInfo: {
        title: "掌握交易自主权，实现个性化交易",
        keywords: "Reactor手动交易，自主决策，事件驱动交易，交易终端，资产管理，紧急停止，风险控制。",
        description: "为了保留您的交易决策权，手动交易的参与仍然非常重要。手动交易允许您根据个人判断和经验进行事件驱动的交易，您还可以随时进行紧急停止和清仓操作，确保交易安全。"
      }
    }
  },
  {
    path: '/strategyMall',
    name: 'strategyMall',
    component: strategyMall,
    meta: {
      metaInfo: {
        title: "掌握交易自主权，实现个性化交易",
        keywords: "Reactor手动交易，自主决策，事件驱动交易，交易终端，资产管理，紧急停止，风险控制。",
        description: "为了保留您的交易决策权，手动交易的参与仍然非常重要。手动交易允许您根据个人判断和经验进行事件驱动的交易，您还可以随时进行紧急停止和清仓操作，确保交易安全。"
      }
    }
  },
  {
    path: '/business',
    name: 'business',
    component: business,
    meta: {
      metaInfo: {
        title: "商务运营-携手共创商机，共享成功",
        keywords: "商务运营、合作伙伴计划、商业机会、共享成功、全方位支持、市场推广、灵活的合作模式、共赢、战略伙伴、商业合作、开拓市场、资源共享",
        description: "您将享受我们提供的全方位支持和资源，包括专业的培训和技术支持，市场推广支持以及灵活的合作模式。加入我们的商务运营合作计划，与我们携手共创商机，共享成功！"
      }
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
