<template>
    <div class="chainData">

        <div class="box1">
            <div class="topMain">
                <img class="topImg" src="../assets/chainData/top1.svg">
                <div class="left fadeInDown">
                    <div class="top">
                        <div class="leftH1">{{$t('strategyMall.h1')}}</div>
                        <div class="leftH2">{{$t('strategyMall.h2')}}</div>
                    </div>
                    <div class="btm" @click="goFaq">
                        <span>
                            {{$t('strategyMall.h3')}}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                </div>
                <div class="bomDiv">
                    <div class="bomDiv-left">
                        <div class="smallH1">
                            {{$t('strategyMall.h4')}}
                        </div>
                        <div class="smallH2">
                            {{$t('strategyMall.h5')}}
                        </div>
                        <div class="smallH3">
                            {{$t('strategyMall.h6')}}
                        </div>
                        <div class="smallH4">
                            <span class="small animate__animated animate__fadeInLeft" 
                            v-for="(data,index) in [1,2,3] " :key="index" :style="{ animationDelay:`${(index * 0.3)}s`}">
                                <img src="../assets/chainData/tab1.svg">
                                <span> {{ $t('strategyMall.h'+(6+data)) }} </span>
                            </span>
                        </div>

                        <div class="smallH5" @click="goFaq2">
                            <span class="textSpan">
                                {{$t('strategyMall.h10')}}
                            </span>
                            <span class="solidSpan">
                                <img src="../assets/copyRobot/home_icon_more_normal.svg" />
                            </span>
                        </div>
                    </div>
                    <img class="bomDiv-right animate__animated animate__fadeInRight" src="../assets/strategyMall/Group 129.svg"/>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box5">
                <div class="top">
                    <div class="leftH1">{{$t('strategyMall.h11')}}</div>
                    <div class="leftH2">{{$t('strategyMall.h12')}}</div>
                </div>
                <div class="btm" @click="goFaq3">
                    <span>{{$t('strategyMall.h13')}}</span>
                    <span class="solidSpan">
                        <img src="../assets/solid.svg" alt="">
                    </span>
                </div>
            </div>
            <div class="box3">
                <div class="box3-top">
                    <div class="title">{{$t('strategyMall.h14')}}</div>
                    <div class="box3-top-img">
                        <img src="../assets/strategyMall/Group 171.svg"/>
                    </div>
                </div>
                <div class="box3-bom">
                    <div v-for="(data,index) in [1,2,3]" :key="index">
                        <div class="box3-bom1">{{ $t('strategyMall.h'+(14+data)) }}</div>
                        <div class="box3-bom2">{{ $t('strategyMall.h'+(17+data)) }}</div>
                        <div class="box3-bom3" @click="goFaq4">
                            <span class="textSpan">
                                {{$t('strategyMall.h21')}}
                            </span>
                            <span class="solidSpan">
                                <img src="../assets/copyRobot/home_icon_more_normal.svg" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box4">
                <div class="box4-left">
                    <div class="box4-left-sm1"> 
                        <img class="box4-left-img" src="../assets/strategyMall/Group 172.svg"/>
                    </div>
                    <div class="leftH1">{{$t('strategyMall.h22')}}</div>
                    <div class="leftH2">{{$t('strategyMall.h23')}}</div>
                </div>
                <div class="box4-right">
                    <div class="box4-right-sm1"> 
                        <img class="box4-right-img" src="../assets/strategyMall/exchange-learn-more-2 1.svg"/>
                    </div>
                    <div class="leftH1">{{$t('strategyMall.h24')}}</div>
                    <div class="leftH2">{{$t('strategyMall.h25')}}</div>
                </div>
            </div>
            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { NumSplic } from "../unit/tool";
  import big from "big.js";
import dowView from './simViews/dowView.vue';
  big.NE = -40;
  big.PE = 40;
  export default {
  components: { dowView },
    name: "chainData",
    data() {
      return {
      };
    },
    methods: {
        goFaq(){
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/ce-lve-shang-cheng/shen-me-shi-ce-lve-shang-cheng', '_blank')
        },
        goFaq2(){
            window.open('https://support.rac.art/chan-pin/ce-lve-shang-cheng/ru-he-xuan-ze-kuo-he-wo-de-ce-lve-wo-ru-he-zhi-dao-ce-lve-de-li-shi-biao-xian-ru-he', '_blank')
        },
        goFaq3(){
            window.open('https://tianyi.art/contact/inquiry', '_blank')
        },
        goFaq4(){
            window.open('https://support.rac.art/chan-pin/ce-lve-shang-cheng/ce-lve-shang-cheng-you-na-xie-you-shi', '_blank')
        }
    },
    computed: {},
    mounted() {
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $defaultSize: 0.0833333333vw;

  .fadeInDown{
    animation: tracking-in-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }

  @keyframes tracking-in-top {
  0% {
    transform: translateY(-50 * $defaultSize);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}
  .chainData {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1{
        width: 750 * $defaultSize;
        margin: auto;
        // display: flex;
        height: 707 * $defaultSize;
        .topMain{
            height: 707 * $defaultSize;
            .topImg{
                z-index: -1;
                width: 100%; /* 图片宽度占满容器 */
                height: auto; /* 图片高度自适应 */
                position: absolute; /* 相对于容器绝对定位 */
                top: 0; /* 图片相对于容器顶部对齐 */
                left: 0; /* 图片相对于容器左侧对齐 */
            }
            text-align: center;
            .left{
                padding-top: 88 * $defaultSize;
                width: 100%;
                .top{
                    .leftH1{
                        font-size: 40 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                    }
                    .leftH2{
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Medium, Medium;
                        font-weight: 300;
                        color: #4F5559;
                    }
                }
                .btm {
                    cursor: pointer;
                    display: inline-block;
                    width: 100 * $defaultSize;
                    height: 35 * $defaultSize;
                    background-color: #03A76A;
                    line-height: 35 * $defaultSize;
                    border-radius: 5 * $defaultSize;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    margin-top: 25 * $defaultSize;
                    font-weight: 600;
                    color: #FFFFFF;
                    img{
                        width: 13 * $defaultSize;
                        color: #FFFFFF;
                    }
                }
                .btm:hover{
                    background: #3A8459;
                    .solidSpan{
                        img{
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }
                }
            }
            .bomDiv{
                display: flex;
                margin-top: 68 * $defaultSize;
                width: 100%;
                height: 310 * $defaultSize;
                // background: url('../assets/chainData/Rectangle 216.svg');
                // background-size: cover;
                .bomDiv-left{
                    width: 50%;
                    text-align: left;
                    .smallH1{
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Medium, Medium;
                        font-weight: 400;
                        color: #224FD9;
                    }
                    .smallH2{
                        margin-top: 5 * $defaultSize;
                        font-size: 25 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                    }

                    .smallH3{
                        width: 100%;
                        text-align: left;
                        margin-top: 10 * $defaultSize;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Medium;
                        font-weight: 200;
                        color: #4F5559;
                    }
                    .smallH4{
                        width: 100%;
                        text-align: left;
                        font-size: 12 * $defaultSize;
                        font-family: Inter-Medium, Medium;
                        font-weight: 200;
                        color: #4F5559;
                        .small{
                            display: flex;
                            align-items: center; /* 将子元素垂直居中对齐 */
                            justify-content: left;
                            width: 80%;
                            border-bottom: 1px solid #D9D9D9;
                            padding: 15 * $defaultSize 0 15 * $defaultSize 0 * $defaultSize;
                            span{
                                margin-left: 10 * $defaultSize;
                                display: inline-block;
                                width: 100%;
                            }
                            img{
                                width: 23 * $defaultSize;
                            }
                        }
                    }
                    .smallH5{
                        cursor: pointer;
                        margin-top: 50* $defaultSize;
                        color: #333333;
                        font-size: 15 * $defaultSize;
                        font-weight: 600;
                        display: flex;
                        .solidSpan{
                            margin-left: 5* $defaultSize;
                            display: flex;
                            align-items: center; /* 将子元素垂直居中对齐 */
                        }
                    }
                    
                    .smallH5:hover{
                        color: #224FD9;
                        img{
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }
                }
                .bomDiv-right{
                    width: 50%;
                }
            }
        }
    }
    .main {
        width: 750 * $defaultSize;
        margin: auto;
        margin-top: 70 * $defaultSize;
        .box3{
            .box3-top{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title{
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 500;
                    color: #333333;
                    margin-bottom: 5 * $defaultSize;
                }
                .box3-top-img{
                    width: 50%;
                    height: 200 * $defaultSize;
                    img{
                        transform:scale(1);
                        transition:transform 0.5s;
                        width: 95%;
                    }
                }

                .box3-top-img:hover{
                    img{
                        transform:scale(1.1)
                    }
                }
            }
            .box3-bom{
                margin-top: 30 * $defaultSize;
                display: flex;
                div{
                    width: 33%;
                    .box3-bom1{
                        width: 80%;
                        font-size: 18 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 5 * $defaultSize;
                    }
                    .box3-bom2{
                        width: 80%;
                        font-size: 10 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 400;
                        color: #4F5559;
                        margin-bottom: 5 * $defaultSize;
                    }

                    .box3-bom3{
                        width: 80%;
                        font-size: 12 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 5 * $defaultSize;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        .solidSpan{
                            margin-left: 5 * $defaultSize;
                        }
                    }
                    .box3-bom3:hover{
                        color: #224FD9;
                        img{
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }
                }
            }
        }
        .box4 {
            margin-top: 80 * $defaultSize;
            display: flex;
            width: 100%;
            .box4-left{
                width: 40%;
                margin-right: 10%;
                text-align: center;
                .box4-left-sm1{
                    width: 100%;
                    height: 150* $defaultSize;
                    text-align: center;
                    .box4-left-img{
                        width: 50%;
                        transform:scale(1);
                        transition:transform 0.5s;
                    }
                }
                .box4-left-sm1:hover{
                    .box4-left-img{
                        transform:scale(1.1);
                    }
                }
            }

            .box4-right{
                width: 40%;
                text-align: center;
                margin-left: 10%;
                .box4-right-sm1{
                    text-align: center;
                    width: 100%;
                    height: 150* $defaultSize;
                    .box4-right-img{
                        width: 50%;
                        transform:scale(1);
                        transition:transform 0.5s;
                    }
                }
                .box4-right-sm1:hover{
                    .box4-right-img{
                        transform:scale(1.1);
                    }
                }
            }

            .leftH1{
                text-align: center;
                font-size: 20 * $defaultSize;
                font-family: Inter-Semi Bold, Semi Bold;
                font-weight: 600;
                margin: 10 * $defaultSize 0;
            }
            .leftH2{
                text-align: center;
                font-size: 12 * $defaultSize;
                font-family: Inter-Regular, Regular;
                color: #4F5559;
                font-weight: 400;
                margin-bottom: 10 * $defaultSize;
                width: 100%;
            }
        }
        .box5{
            margin-top: 80 * $defaultSize;
            height: 375 * $defaultSize;
            background: url("../assets/signal/Group 159.svg") no-repeat;
            background-size: 100%;
            padding: 50 * $defaultSize;
            padding-bottom: 0;
            color: #FFFFFF;
            .top{
                width: 40%;
                .leftH1{
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    margin-bottom: 10 * $defaultSize;
                }
                .leftH2{
                    font-size: 12 * $defaultSize;
                    font-family: Inter-Regular, Regular;
                    font-weight: 400;
                    margin-bottom: 10 * $defaultSize;
                }
            }
            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #03A76A;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                span{
                    margin-left: 10 *$defaultSize;
                }
                font-weight: 600;
                color: #FFFFFF;
                img{
                    width: 7 * $defaultSize;
                    color: #FFFFFF;
                }
            }
            .btm:hover{
                background: #3A8459;
                .solidSpan{
                    img{
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }
    }
  }
  </style>
  
  