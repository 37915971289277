<!-- :class="{'textAnimation':GET_IsAnimation}" -->
<template>
  <div class="home">
    <div class="bgImg"></div>
    <div class="main">
        <div class="left">
          <div class="h1" style="display:flex;">
            <span v-for="(item, index) in h1Arr($t('home.h1'))" :key="index"
              :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
              :style="{ animationDelay: `${index * 0.02}s` }">
              {{ item }}
              <span v-if="getI18nByEn()">&thinsp;</span>
            </span>
          </div>
          <div class="h2" style="display:flex;">
            <span v-for="(item, index) in h1Arr($t('home.h2'))" :key="index"
              :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
              :style="{ animationDelay: `${(index * 0.02) + (h1Arr($t('home.h1')).length * 0.02)}s` }">
              {{ item }}
              <span v-if="getI18nByEn()">&thinsp;</span>
            </span>
          </div>
          <div class="h3">{{ $t('home.h3') }}</div>
          <div class="h4">
            <div class="btn1" @click="goDownload">
              <span>{{ $t('home.h4') }}</span>
              <span class="solidSpan">
                <img src="../assets/home/home_button_right_normal.svg" alt="">
              </span>
            </div>
            <div class="aaa">
              <img src="../assets/home_icon_apple_normal.png" alt="" class="btn2">
            </div>
            <div class="aaa">
              <img src="../assets/home_icon_win_normal.png" alt="" class="btn2">
            </div>

          </div>
          <div class="h5">{{ $t('home.h5') }}</div>
          <div class="h6">
            <img src="../assets/home/home_logo_binance.svg" alt="">
            <img src="../assets/home/home_logo_okx.png" alt="">
            <img src="../assets/home/home_logo_huobi.svg" alt="">
            <img src="../assets/home/home_logo_dydx.svg" alt="">
            <img src="../assets/home/home_logo_dodo.svg" alt="">
            <img src="../assets/home/home_logo_kine.svg" alt="">
            <img src="../assets/home/home_logo_pancakeswap.svg" alt="">
            <img src="../assets/home/home_logo_uniswap.svg" alt="">
          </div>
        </div>
        <div class="right">
          <img src="../assets/home_banner@2x.png" alt="">
        </div>
      <div class="box2"
        :style="{ backgroundImage: `url('${require(`../assets/home/home_bg${backgroundImageIndex}.svg`)}')` }">
        <div class="header">
          <div class="check" @click="(event) => upInterval(event, 1)">
            <span class="span-i"><i class="i1"></i></span>
            <span>{{ $t('home.h6') }}</span>
          </div>
          <div class="selcet" @click="(event) => upInterval(event, 2)"><span class="span-i"><i
                class="i2"></i></span><span>{{ $t('home.h7') }}</span>
          </div>
          <div class="selcet" @click="(event) => upInterval(event, 3)"><span class="span-i"><i
                class="i3"></i></span><span>{{ $t('home.h8') }}</span>
          </div>
          <div class="selcet" @click="(event) => upInterval(event, 4)"><span class="span-i"><i
                class="i4"></i></span><span>{{ $t('home.h9') }}</span>
          </div>
          <div class="selcet" @click="(event) => upInterval(event, 5)"><span class="span-i"><i
                class="i5"></i></span><span>{{ $t('home.h10') }}</span>
          </div>
        </div>
        <div class="solid"></div>
        <div class="box2Main">
          <div class="carouselDiv">
            <div class="box2Mainleft" :class="[`${carouselIndex === 1 ? 'textAnimation' : 'textAnimation_none'}`]">
              <p class="h1">{{ $t('home.h11') }}</p>
              <p class="h2">{{ $t('home.h12') }}</p>
              <div class="btn" @click="asd(1)">
                <span>{{ $t('home.h13') }}</span>
                <span class="solidSpan">
                  <img src="../assets/home/home_button_right_normal.svg" alt="">
                </span>
              </div>
            </div>
            <div class="box2Mainright">
              <img src="../assets/home/home_banner2.svg"
                :class="[`${carouselIndex === 1 ? 'image-container' : 'textAnimation_none'}`]" alt="">
            </div>
          </div>
          <div class="carouselDiv">
            <div class="box2Mainleft" :class="[`${carouselIndex === 2 ? 'textAnimation' : 'textAnimation_none'}`]">
              <p class="h1">{{ $t('home.h11_1') }}</p>
              <p class="h2">{{ $t('home.h12_1') }}</p>
              <div class="btn" @click="asd(2)">
                <span>{{ $t('home.h13') }}</span>
                <span class="solidSpan">
                  <img src="../assets/home/home_button_right_normal.svg" alt="">
                </span>
              </div>
            </div>
            <div class="box2Mainright ">
              <img src="../assets/home/Group 261.svg"
                :class="[`${carouselIndex === 2 ? 'image-container' : 'textAnimation_none'}`]" alt="">
            </div>
          </div>
          <div class="carouselDiv">
            <div class="box2Mainleft" :class="[`${carouselIndex === 3 ? 'textAnimation' : 'textAnimation_none'}`]">
              <p class="h1">{{ $t('home.h11_2') }}</p>
              <p class="h2">{{ $t('home.h12_2') }}</p>
              <div class="btn" @click="asd(3)">
                <span>{{ $t('home.h13') }}</span>
                <span class="solidSpan">
                  <img src="../assets/home/home_button_right_normal.svg" alt="">
                </span>
              </div>
            </div>
            <div class="box2Mainright image-container">
              <img src="../assets/home/Group 262.svg"
                :class="[`${carouselIndex === 3 ? 'image-container' : 'textAnimation_none'}`]" alt="">
            </div>
          </div>
          <div class="carouselDiv">
            <div class="box2Mainleft" :class="[`${carouselIndex === 4 ? 'textAnimation' : 'textAnimation_none'}`]">
              <p class="h1">{{ $t('home.h11_3') }}</p>
              <p class="h2">{{ $t('home.h12_3') }}</p>
              <div class="btn" @click="asd(4)">
                <span>{{ $t('home.h13') }}</span>
                <span class="solidSpan">
                  <img src="../assets/home/home_button_right_normal.svg" alt="">
                </span>
              </div>
            </div>
            <div class="box2Mainright image-container">
              <img src="../assets/home/Group 265.svg"
                :class="[`${carouselIndex === 4 ? 'image-container' : 'textAnimation_none'}`]" alt="">
            </div>
          </div>
          <div class="carouselDiv">
            <div class="box2Mainleft" :class="[`${carouselIndex === 5 ? 'textAnimation' : 'textAnimation_none'}`]">
              <p class="h1">{{ $t('home.h11_4') }}</p>
              <p class="h2">{{ $t('home.h12_4') }}</p>
              <div class="btn" @click="asd(5)">
                <span>{{ $t('home.h13') }}</span>
                <span class="solidSpan">
                  <img src="../assets/home/home_button_right_normal.svg" alt="">
                </span>
              </div>
            </div>
            <div class="box2Mainright image-container">
              <img src="../assets/home/Group 264.svg"
                :class="[`${carouselIndex === 5 ? 'image-container' : 'textAnimation_none'}`]" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="row">
          <div class="top"> <animate-number ref="reNum1" from="0" :to="20" :key="20" duration="2000"></animate-number> +
          </div>
          <div class="btm">{{ $t('home.h14') }}</div>
        </div>
        <div class="row">
          <div class="top"><animate-number ref="reNum2" from="0" :to="10" :key="10" duration="2000"></animate-number>+
          </div>
          <div class="btm">{{ $t('home.h15') }} <br> {{ $t('home.h16') }}</div>
        </div>
        <div class="row">
          <div class="top"><animate-number ref="reNum3" from="0" :to="1000" :key="1000" duration="2000"></animate-number>+
          </div>
          <div class="btm">{{ $t('home.h15') }} <br> {{ $t('home.h17') }}</div>
        </div>
        <div class="row">
          <div class="top">
            <animate-number ref="reNum4" from="0" :to="10" :key="10" duration="2000"></animate-number>W+
          </div>
          <div class="btm">{{ $t('home.h18') }} <br> {{ $t('home.h19') }}</div>
        </div>
      </div>
      <div class="box4">
        <div class="box4left">
          <div class="h1">
            {{ $t('home.h20') }}
          </div>
          <div class="h2">
            {{ $t('home.h21') }}
          </div>
          <div class="btn" @click="goManualTrading">
            <span>{{ $t('home.h13') }}</span>
            <span class="solidSpan">
              <img src="../assets/home/home_icon_more_normal.svg" alt="">
            </span>
          </div>
        </div>
        <div class="box4right">
          <img src="../assets/home_banner3@2x.png" alt="">
          <img src="../assets/home_banner4@2x.png" alt="">
        </div>
      </div>
      <div class="box5">
        <div class="box5small">
          <div class="h1">{{ $t('home.h22') }}</div>
          <div class="h2">{{ $t('home.h23') }}</div>
          <div class="h3">
            <div class="more" @click="goAbout">
              <span>{{ $t('home.h13') }}</span>
              <span class="solidSpan">
                <img src="../assets/home/home_icon_more_normal (1).svg" alt="">
              </span>
            </div>
            <img src="../assets/home_banner5@2x.png" alt="">
          </div>
        </div>
        <div class="box5small">
          <div class="h1">{{ $t('home.h33') }}</div>
          <div class="h2">{{ $t('home.h34') }}</div>
          <div class="h3">
            <div class="more" @click="goAbout">
              <span>{{ $t('home.h13') }}</span>
              <span class="solidSpan">
                <img src="../assets/home/home_icon_more_normal (1).svg" alt="">
              </span>
            </div>
            <img src="../assets/honme_banner6@2x.png" alt="">
          </div>
        </div>
      </div>
      <div class="box6">
        <div class="title">{{ $t('home.h24') }}</div>
        <div class="box6body">
          <div class="small">
            <img src="../assets/home_banner7@2x.png" alt="">
            <div class="name">{{ $t('home.h25') }}</div>
            <div class="hint">{{ $t('home.h26') }}</div>
          </div>
          <div class="small">
            <img src="../assets/home_banner8@2x.png" alt="">
            <div class="name">{{ $t('home.h27') }}</div>
            <div class="hint">{{ $t('home.h28') }}</div>
          </div>
          <div class="small">
            <img src="../assets/home_banner9@2x.png" alt="">
            <div class="name">{{ $t('home.h29') }}</div>
            <div class="hint">{{ $t('home.h30') }}</div>
          </div>
        </div>
      </div>
      <div class="box7">
        <div class="box7Box">
          <div class="h1">{{ $t('home.h31') }}</div>
          <div class="h2">{{ $t('home.h32') }}</div>
          <div class="h3">
            <div class="btn1" @click="goDownload">
              <span>{{ $t('home.h4') }}</span>
              <span class="solidSpan">
                <img src="../assets/home/home_button_right_normal.svg" alt="">
              </span>
            </div>
            <img src="../assets/home_icon_apple_normal.png" alt="" class="btn2">
            <img src="../assets/home_icon_win_normal.png" alt="" class="btn2">
          </div>
        </div>
      </div>
    </div>
    <el-dialog :z-index="100000000" style="" :visible.sync="isChinaIP" @close="close" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="true" center>
      <div class="dialogDiv">
        <div class="title">
          <img src="../assets/home_logo_reactor@2x.png" />
          <div>Reactor</div>
        </div>
        <div class="body">
          <div class="small">
            亲爱的用户:
          </div>
          <div class="small2">
            为积极响应中国政府监管政策要求，自2021年9月27日起，Reactor 停止为中国境内用户提供相关服务。
          </div>
          <div class="small2">
            Reactor 致力于遵循各国法律法规合法运营，感谢您的支持与理解！
          </div>
          <div class="small3">
            Reactor团队
          </div>
          <div class="small3">
            2021年9月27日
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import big from "big.js";
big.NE = -40;
big.PE = 40;
// 使用ipify获取客户端的公网IP地址
async function getPublicIPAddress() {
  try {
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error occurred while fetching IP address:', error);
    return null;
  }
}

// 使用ipapi.co查询IP地址的国家信息
async function getIPCountry(ip) {
  try {
    const response = await fetch(`https://ipapi.co/${ip}/json/`);
    const data = await response.json();
    return data.country;
  } catch (error) {
    console.error('Error occurred while fetching IP country:', error);
    return null;
  }
}

export default {
  name: "home",
  data() {
    return {
      carouselIndex: 1,
      intervalTime: 10,
      intervalId: undefined,
      carouseObjs: [],
      isChinaIP: false,
      backgroundImageIndex: 1,
      scrollValue: 0,
    };
  },
  watch: {
    carouselIndex(newValue, oldValue) {
      // 处理数据变化的逻辑
      const headerElement = document.querySelector('.header');
      const divElements = headerElement.querySelectorAll('div');
      divElements.forEach((data, index) => {
        if ((index + 1) === newValue) {
          data.classList.replace('selcet', 'check');
        } else {
          data.classList.replace('check', 'selcet');
        }
      })
      this.init(this.carouseObjs);
    },
  },
  computed: {
    ...mapGetters(['GET_IsAnimation', 'GET_Dialog']),
    h1Arr(text) {
      if (this.$i18n.locale == 'en') {
        return text => text.split(" ");
      } else {
        return text => text.split("")
      }
    }
  },
  async created() {
    const ip = await getPublicIPAddress();
    if (ip) {
      const country = await getIPCountry(ip);
      if (country === 'CN') {
        this.isChinaIP = true;
      }
    }
  },
  methods: {
    asd(n) {
      if (n == 1) {
        window.open('https://support.rac.art/jiao-yi/shen-me-shi-duo-yuan-hua-tou-zi', '_blank')
      }
      if (n == 2) {
        window.open('https://support.rac.art/jiao-yi/shen-me-shi-liang-hua-jiao-yi', '_blank')
      }
      if (n == 3) {
        window.open('https://support.rac.art/jiao-yi/shen-me-shi-zhi-biao-can-shu', '_blank')
      }
      if (n == 4) {
        window.open('https://support.rac.art/chan-pin/k-xian-yu-lian-shang-shu-ju/k-xian-shi-shen-me-ru-he-cha-kan-li-shi-jia-ge-zou-shi', '_blank')
      }
      if (n == 5) {
        this.$router.push({
          path: "/rebate",
        });
      }
    },
    handleScroll() {
      this.scrollValue = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      console.log(this.scrollValue);
      if (this.scrollValue >= 1130 && this.scrollValue <= 1150) {
        this.$nextTick(() => {
          this.$refs.reNum1.start()
          this.$refs.reNum2.start()
          this.$refs.reNum3.start()
          this.$refs.reNum4.start()
        })
      }
    },
    getI18nByEn() {
      return this.$i18n.locale == 'en';
    },
    close() {
      // this.$store.commit('SET_Dialog',false)
      // localStorage.setItem('SET_Dialog', 'false');
    },
    goExchange() {
      this.$router.push({
        path: "/exchange",
      });
    },
    goToken() {
      this.$router.push({
        path: "/token",
      });
    },
    goWallet() {
      this.$router.push({
        path: "/wallet",
      });
    },
    goManualTrading() {
      this.$router.push({
        path: "/manualTrading",
      });
    },
    goAbout() {
      this.$router.push({
        path: "/about",
      });
    },
    goDownload() {
      this.$router.push({
        path: "/download",
      });
    },
    init(carouseObjs) {
      for (let i = 0; i < carouseObjs.length; i++) {
        if (i + 1 == this.carouselIndex) {
          carouseObjs[i].style.height = '100%'
          carouseObjs[i].style.opacity = 1
        } else {
          carouseObjs[i].style.height = '0'
          carouseObjs[i].style.opacity = 0
        }
      }
    },
    upInterval(event, index) {
      // 修改背景图片
      this.backgroundImageIndex = index;

      const targetElement = event.currentTarget;
      if (targetElement.classList.contains('check')) {
        return;
      }
      const headerElement = document.querySelector('.header');
      const divElements = headerElement.querySelectorAll('div');
      divElements.forEach((data) => {
        if (data.classList.contains('check')) {
          data.classList.replace('check', 'selcet');
        }
      })
      targetElement.classList.replace('selcet', 'check');
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.carouselIndex = index;
        this.intervalId = setInterval(() => {
          if (this.carouselIndex === 5) {
            this.carouselIndex = 1;
            return
          } else {
            this.carouselIndex += 1;
          }
        }, this.intervalTime * 1000)
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.carouseObjs = document.getElementsByClassName('carouselDiv');
    this.$nextTick(() => {
      this.init(this.carouseObjs);
      this.intervalId = setInterval(() => {
        if (this.carouselIndex === 5) {
          this.carouselIndex = 1;
          this.backgroundImageIndex = 1;
          return
        } else {
          this.carouselIndex += 1;
          this.backgroundImageIndex = this.carouselIndex;
        }
      }, this.intervalTime * 1000)
    });

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

::v-deep .el-dialog {
  margin-top: 10%;
  width: 40%;
  border-radius: 30px !important;
  height: 50%;
}

.el-dialog__wrapper {
  background-color: #132D82;
  /* 修改此处为你想要的颜色 */
}

.dialogDiv {
  width: 100%;
  height: 100%;

  .title {
    padding: 0 100 * $defaultSize;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 10%;
    }

    div {
      font-size: 32 * $defaultSize;
      font-family: Inter-Semi Bold, Semi Bold;
      font-weight: 600;
      color: #333333;
    }
  }

  .body {
    margin-top: 20 * $defaultSize;
    font-size: 12 * $defaultSize;
    font-family: Inter-Semi Bold, Semi Bold;
    font-weight: 600;
    color: #333333;

    .small {
      margin-bottom: 10 * $defaultSize;
    }

    .small2 {
      font-weight: 400;
      margin-bottom: 15 * $defaultSize;
    }

    .small3 {
      text-align: right;
      font-weight: 400;
      margin-bottom: 5 * $defaultSize;
    }
  }

  .btm {
    display: flex;
    justify-content: space-around;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 20 * $defaultSize;
      margin-top: 10 * $defaultSize;
      font-size: 10 * $defaultSize;
      font-family: Inter-Semi Bold, Semi Bold;
      font-weight: 600;
      color: #fff;
      background: #224FD9;
      width: 30%;
      border-radius: 2 * $defaultSize;
    }
  }
}

.home {
  background-image: url("../assets/home/home_bg.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 100 * $defaultSize;

  .bgImg {
    width: 100%;
    position: absolute;
    height: 430 * $defaultSize;
    background-image: url("../assets/home/arrows.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .main {
    width: 750 * $defaultSize;
    margin: auto;
    position: relative;

    .left {
      width: 65%;
      z-index: 9999;

      .h1 {
        font-size: 40 * $defaultSize;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;

        .fade-in {}

        content: "";
        // animation: text-animation 3s steps(10) forwards;
        // width: 0;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: bottom;
        display: flex;
        flex-wrap: wrap;
      }

      .h2 {
        font-size: 25 * $defaultSize;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 7 * $defaultSize;
        display: flex;
        flex-wrap: wrap;
      }

      .h3 {
        font-size: 16 * $defaultSize;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C4CAE0;
        margin-bottom: 17 * $defaultSize;
      }

      .h4 {
        display: flex;
        align-items: center;
        margin-bottom: 37 * $defaultSize;

        .btn1 {
          width: 94 * $defaultSize;
          height: 35 * $defaultSize;
          border-radius: 5 * $defaultSize;
          cursor: pointer;
          font-size: 14 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          text-align: center;
          line-height: 35 * $defaultSize;
          background-color: #03A76A;
          margin-right: 14 * $defaultSize;

          img {
            width: 12 * $defaultSize;
            color: #FFFFFF;
          }
        }

        .btn1:hover {
          background-color: #3B855A;

          .solidSpan {
            img {
              animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            }
          }
        }

        .aaa {
          width: 50px;
          height: 60px;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn2 {
          width: 16 * $defaultSize;
          height: 19 * $defaultSize;
          margin-right: 19 * $defaultSize;
          z-index: 999999;
          transition: 0.5s;
        }

        .btn2:hover {
          width: 20 * $defaultSize;
          height: 23 * $defaultSize;
          margin-right: 19 * $defaultSize;
          z-index: 999999;
          transition: 0.5s;
        }
      }

      .h5 {
        font-size: 10 * $defaultSize;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #9EB3EE;
        margin-bottom: 17 * $defaultSize;
      }

      .h6 {
        width: 70%;
        margin-bottom: 42 * $defaultSize;

        img {
          height: 20 * $defaultSize;
          margin-right: 10 * $defaultSize;
        }
      }
    }

    .right {
      position: absolute;
      // width: 1038px;
      // right: -100px;
      // top: -30px;
      width: 95%;
      right: -10%;
      top: -2%;
      animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

      img {
        width: 100%;
      }
    }

    .box2 {
      position: relative;
      width: 100%;
      height: 421 * $defaultSize;
      // background-color: #fff;
      z-index: 10000;
      // background-image: url("../assets/home/home_bg1.svg");
      background-size: 100%;

      .header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 25 * $defaultSize;
        padding-bottom: 19 * $defaultSize;
        padding-left: 100 * $defaultSize;
        padding-right: 100 * $defaultSize;

        div {
          width: 18%;
        }

        .selcet {
          cursor: pointer;
          padding: 6 * $defaultSize 21 * $defaultSize;
          border-radius: 14 * $defaultSize;
          border: 1px solid #DADDE3;

          font-size: 11 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4F5559;
          white-space: nowrap;
          /* 防止文字换行 */
          overflow: hidden;
          /* 隐藏溢出的文字 */
          text-overflow: ellipsis;

          /* 使用省略号代替溢出的文字 */
          .span-i {
            display: inline-block;
            width: 6 * $defaultSize;
            height: 6 * $defaultSize;
            margin-right: 5* $defaultSize;
          }
        }

        .check {

          white-space: nowrap;
          /* 防止文字换行 */
          overflow: hidden;
          /* 隐藏溢出的文字 */
          text-overflow: ellipsis;
          /* 使用省略号代替溢出的文字 */
          cursor: pointer;
          font-size: 11 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4F5559;
          text-align: center;

          .span-i {
            display: inline-block;
            width: 6 * $defaultSize;
            height: 6 * $defaultSize;
            margin-right: 5* $defaultSize;
          }

          i {
            display: inline-block;
            width: 6 * $defaultSize;
            height: 6 * $defaultSize;
            border-radius: 100%;
          }

          .i1 {
            background-color: #03A76A;
          }

          .i2 {
            background-color: #E39169;
          }

          .i3 {
            background-color: #122E82;
          }

          .i4 {
            background-color: #7743DB;
          }

          .i5 {
            background-color: #3A8EF6;
          }
        }

        .selcet:hover {
          border: 1px solid #979797;

          i {
            display: inline-block;
            width: 6 * $defaultSize;
            height: 6 * $defaultSize;
            border-radius: 100%;
          }

          .i1 {
            background-color: #03A76A;
          }

          .i2 {
            background-color: #E39169;
          }

          .i3 {
            background-color: #122E82;
          }

          .i4 {
            background-color: #7743DB;
          }

          .i5 {
            background-color: #3A8EF6;
          }
        }
      }

      .solid {}

      .box2Main {
        align-items: center;
        padding: 50 * $defaultSize;
        height: 80%;

        .carouselDiv {
          // cursor: pointer;
          display: flex;
          width: 100%;
          height: 100%;
          // transform: translateY(-30px);
          opacity: 0;
          // transition: all 1s;
        }

        .carouselDiv:nth-of-type(1) {
          z-index: 5;

          .btn {
            background-color: #03A76A;
          }

          .btn:hover {
            background-color: #3B855A;

            .solidSpan {
              img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }
          }
        }

        .carouselDiv:nth-of-type(2) {
          z-index: 4;

          .btn {
            background-color: #E39169;
          }

          .btn:hover {
            background-color: #B2623C;

            .solidSpan {
              img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }
          }
        }

        .carouselDiv:nth-of-type(3) {
          z-index: 3;

          .btn {
            background-color: #2751D0;
          }

          .btn:hover {
            background-color: #2243A8;

            .solidSpan {
              img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }
          }
        }

        .carouselDiv:nth-of-type(4) {
          z-index: 2;

          .btn {
            background-color: #7743DB;
          }

          .btn:hover {
            background-color: #441FAA;

            .solidSpan {
              img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }
          }
        }

        .carouselDiv:nth-of-type(5) {
          z-index: 1;

          .btn {
            background-color: #3A8EF6;
          }

          .btn:hover {
            background-color: #4174C0;

            .solidSpan {
              img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }
          }
        }

        .box2Mainleft {
          width: 60%;

          .h1 {
            font-size: 20 * $defaultSize;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }

          .h2 {
            font-size: 13 * $defaultSize;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F5559;
          }

          .btn {
            cursor: pointer;
            display: inline-block;

            width: 94 * $defaultSize;
            height: 35 * $defaultSize;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 35 * $defaultSize;
            border-radius: 5 * $defaultSize;
            text-align: center;
            font-size: 14 * $defaultSize;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;

            img {
              width: 12 * $defaultSize;
              color: #FFFFFF;
            }
          }
        }

        .box2Mainright {
          width: 40%;

          img {
            width: 100%;
          }


          .image-container {
            animation: resizeImage 0.5s ease;
          }
        }
      }
    }

    .box3 {
      display: flex;
      justify-content: space-around;
      padding: 88 * $defaultSize 0;
      position: relative;
      z-index: 9999999;

      .row {
        .top {
          font-size: 30 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1E4DD8;
        }

        .btm {
          font-size: 11 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4A5055;
        }
      }
    }

    .box4 {
      width: 100%;
      height: 269 * $defaultSize;
      background-image: url("../assets/home_bg3@2x.png");
      background-size: 100%;
      background-repeat: no-repeat;

      display: flex;
      // justify-content: space-around;
      align-items: center;

      padding: 0 40 * $defaultSize;

      .box4left {
        width: 40%;

        .h1 {
          font-size: 20 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          margin-bottom: 11 * $defaultSize;
        }

        .h2 {
          font-size: 13 * $defaultSize;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A0A4A7;
          margin-bottom: 25 * $defaultSize;
        }

        .btn {
          cursor: pointer;
          display: inline-block;
          width: 94 * $defaultSize;
          height: 35 * $defaultSize;
          color: #FFFFFF;
          line-height: 35 * $defaultSize;
          border-radius: 5 * $defaultSize;
          // padding: 8 * $defaultSize 13 * $defaultSize;
          display: flex;
          font-size: 14 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;

          .solidSpan {
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
              width: 15 * $defaultSize;
              margin-left: 5 * $defaultSize;
              color: #FFFFFF;
            }
          }
        }

        .btn:hover {
          color: #9FB2ED;

          .solidSpan {
            img {
              animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            }
          }
        }
      }

      .box4right {
        width: 60%;

        img {
          width: 50%;
        }
      }
    }

    .box5 {
      display: flex;
      justify-content: space-between;
      margin-top: 88 * $defaultSize;
      margin-bottom: 69 * $defaultSize;
      padding: 0 50 * $defaultSize;

      .box5small {
        width: 40%;

        .h1 {
          font-size: 20 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-bottom: 11 * $defaultSize;
        }

        .h2 {
          font-size: 13 * $defaultSize;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4F5559;
          margin-bottom: 25 * $defaultSize;
        }

        .h3 {
          display: flex;

          //  .more{
          //     font-size: 14 * $defaultSize;
          //     font-family: PingFangSC-Semibold, PingFang SC;
          //     font-weight: 600;
          //     color: #333333;
          //   }

          .more {
            cursor: pointer;
            font-size: 14 * $defaultSize;
            display: inline-block;
            width: 94 * $defaultSize;
            height: 35 * $defaultSize;
            font-weight: 600;
            color: #333333;
            line-height: 35 * $defaultSize;
            display: flex;
            // span{
            //   margin-left: 10 *$defaultSize;
            // }
            font-size: 14 * $defaultSize;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;

            .solidSpan {
              display: flex;
              justify-content: space-around;
              align-items: center;

              img {
                width: 15 * $defaultSize;
                margin-left: 5 * $defaultSize;
                color: #FFFFFF;
              }
            }
          }

          .more:hover {
            color: #1E4DD8;

            .solidSpan {
              img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }
          }

          img {
            width: 70%;
          }
        }
      }
    }

    .box6 {
      .title {
        font-size: 20 * $defaultSize;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        text-align: center;
        margin-bottom: 25 * $defaultSize;
      }

      .box6body {

        display: flex;

        .small {
          text-align: center;
          padding: 0 50 * $defaultSize;

          img {
            width: 88 * $defaultSize;
          }

          .name {
            font-size: 20 * $defaultSize;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
          }

          .hint {
            margin-top: 15 * $defaultSize;
            font-size: 13 * $defaultSize;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F5559;
          }
        }
      }
    }

    .box7 {
      margin-top: 88 * $defaultSize;
      height: 281 * $defaultSize;
      background-image: url("../assets/home_bg5@2x.png");
      background-size: 100%;
      background-repeat: no-repeat;

      display: flex;
      align-items: center;
      padding: 40 * $defaultSize;

      .box7Box {
        width: 40%;

        .h1 {
          font-size: 20 * $defaultSize;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          margin-bottom: 11 * $defaultSize;
        }

        .h2 {
          font-size: 13 * $defaultSize;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #F3F6FC;
          margin-bottom: 11 * $defaultSize;
        }

        .h3 {
          display: flex;
          align-items: center;

          .btn1 {
            width: 94 * $defaultSize;
            height: 35 * $defaultSize;
            border-radius: 5 * $defaultSize;
            cursor: pointer;
            font-size: 14 * $defaultSize;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
            line-height: 35 * $defaultSize;
            background-color: #03A76A;
            margin-right: 24 * $defaultSize;

            img {
              width: 12 * $defaultSize;
              color: #FFFFFF;
            }
          }

          .btn1:hover {
            background-color: #3B855A;

            .solidSpan {
              img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              }
            }
          }

          .btn2 {
            width: 16 * $defaultSize;
            height: 19 * $defaultSize;
            margin-right: 19 * $defaultSize;
          }

          .btn3 {
            width: 19 * $defaultSize;
            height: 19 * $defaultSize;
          }
        }
      }
    }
  }
}

@keyframes resizeImage {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.textAnimation {
  display: block;
  animation: tracking-in-expand-fwd-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none {
  animation: none;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

@keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(3 * $defaultSize);
  }
}

@keyframes tracking-in-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    opacity: 0;
  }

  40% {
    opacity: 0.1;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes text-animation {
  0% {
    width: 0;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}
</style>
