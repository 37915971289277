<template>
    <div class="btm">
        <div class="about">
            <div class="left">
                <div class="top">
                    <div class="top_title"><img src="../assets/home_logo_reactor@2x.png" alt="">Reactor</div>
                    <div class="right1">
                        <div class="topTitle1">{{ $t('btm.h27') }}</div>
                        <div class="imgs1">
                            <div class="A11">
                                <img src="BTM2/home_icon_twitter_normal.svg" alt="">
                            </div>
                            <div class="A11">
                                <img src="BTM2/home_icon_ins_normal.svg" alt="">
                            </div>
                            <div class="A11">
                                <img src="BTM2/home_icon_telegram_normal.svg" alt="" style="margin-right: 10px;">
                            </div>
                            <div class="A11">
                                <img src="BTM2/home_icon_facebook_normal.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btmDiv">
                    <div class="row">
                        <div class="title">
                            {{ $t('btm.h1') }}
                        </div>
                        <div class="line" @click="goPolicyBacktest">
                            {{ $t('btm.A1') }}
                        </div>
                        <div class="line" @click="goManualTrading">
                            {{ $t('btm.A2') }}
                        </div>
                        <div class="line" @click="goChainData">
                            {{ $t('btm.A3') }}
                        </div>
                        <div class="line" @click="goSignal">
                            {{ $t('btm.A4') }}
                        </div>
                        <div class="line" @click="goStrategyMall">
                            {{ $t('btm.A5') }}
                        </div>
                        <!-- <div class="line">
                            {{$t('btm.h7')}}
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="title">
                            {{ $t('btm.h8') }}
                        </div>
                        <div class="line" @click="goExchangeRobot">
                            {{ $t('btm.h9') }}
                        </div>
                        <div class="line" @click="goDemoRobot">
                            {{ $t('btm.h10') }}
                        </div>
                        <div class="line" @click="goCopyRobot">
                            {{ $t('btm.h11') }}
                        </div>
                        <div class="line" @click="goInterestRobot">
                            {{ $t('btm.h12') }}
                        </div>
                        <div class="line" @click="goMerchantRobot">
                            {{ $t('btm.h13') }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="title">
                            {{ $t('btm.h14') }}
                        </div>
                        <div class="line" @click="goAbout">
                            {{ $t('btm.h14') }}
                        </div>
                        <div class="line" @click="goOem">
                            {{ $t('btm.h15') }}
                        </div>
                        <div class="line" @click="goCommunity">
                            {{ $t('btm.A13') }}
                        </div>
                        <div class="line" @click="goContact">
                            {{ $t('btm.A14') }}
                        </div>
                        <div class="line" @click="goFaqaa">
                            {{ $t('btm.A15') }}
                        </div>
                        <div class="line" @click="goFaqbb">
                            {{ $t('btm.A16') }}
                        </div>
                        <div class="line" @click="goFaqcc">
                            {{ $t('btm.A17') }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="title">
                            {{ $t('btm.A24') }}
                        </div>
                        <div class="line" @click="goHelpCenter">
                            {{ $t('btm.A18') }}
                        </div>
                        <div class="line" @click="goStrategy">
                            {{ $t('btm.A19') }}
                        </div>
                        <div class="line" @click="goRebate">
                            {{ $t('btm.A20') }}
                        </div>
                        <div class="line" @click="linkWaller">
                            {{ $t('btm.A21') }}
                        </div>
                        <div class="line" @click="goDca">
                            {{ $t('btm.A22') }}
                        </div>
                        <div class="line" @click="gpxiazai">
                            {{ $t('btm.A23') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <!-- <div class="topTitle">{{ $t('btm.h27') }}</div>

                <div class="imgs">
                    <div class="A1">
                        <img src="../assets/home_icon_twitter_normal@2x.png" alt="">
                    </div>
                    <div class="A1">
                        <img src="../assets/home_icon_ins_normal@2x.png" alt="">
                    </div>
                    <div class="A1">
                        <img src="../assets/home_icon_telegram_normal@2x.png" alt="" style="margin-right: 10px;">
                    </div>
                    <div class="A1">
                        <img src="../assets/home_icon_facebook_normal@2x.png" alt="">
                    </div>
                </div> -->

                <div class="trustpilot" style="cursor: pointer;" @click="goTrustpilot">
                    <img src="BTM2/LIMS1MAYW8X76.svg" alt="" class="trustpilot2">
                </div>

                <div class="span_span">
                    <span>扫码使用DAPP</span>
                </div>

                <div class="QRCode">
                    <img src="../assets/topbar/Group.svg" />
                </div>

                <div class="macos">
                    <!-- <img src="macos.png" /> -->
                </div>

                <div class="windows">
                    <!-- <img src="windows.png" /> -->
                </div>

                <!-- <div class="btmTitle">{{ $t('btm.h34') }}</div> -->

                <!-- <div class="box">
                    <div class="boxLeft">
                        <img src="../assets/home_icon_apple2_normal@2x.png" alt="">
                    </div>
                    <div class="boxRight">
                        <div class="boxTop">{{ $t('btm.h28') }}</div>
                        <div class="boxBtm">Mac OS</div>
                    </div>
                </div>
                <div class="box">
                    <div class="boxLeft">
                        <img src="../assets/home_icon_windows2_normal@2x.png" alt="">
                    </div>
                    <div class="boxRight">
                        <div class="boxTop">{{ $t('btm.h28') }}</div>
                        <div class="boxBtm">Windows</div>
                    </div>
                </div> -->

            </div>
        </div>
        <div class="renzheng">
            <div class="small" @mouseover="over" @mouseout="out">
                <img :src="num == 0 ? 'home_icon_aaa@2x.png' : 'home_icon_aaa@2x2.png'" alt="">
                {{ $t('btm.h29') }}
            </div>
            <div class="small" @mouseover="over2" @mouseout="out2">
                <img :src="num2 == 0 ? 'home_icon_jzhy@2x.png' : 'home_icon_jzhy@2x2.png'" alt="">
                {{ $t('btm.h30') }}
            </div>
            <div class="small" @mouseover="over3" @mouseout="out3">
                <img :src="num3 == 0 ? 'home_icon_cxda@2x.png' : 'home_icon_cxda@2x2.png'" alt="">
                {{ $t('btm.h31') }}
            </div>
            <div class="small" @mouseover="over4" @mouseout="out4">
                <img :src="num4 == 0 ? 'home_icon_cxjy@2x.png' : 'home_icon_cxjy@2x2.png'" alt="">
                {{ $t('btm.h32') }}
            </div>
        </div>
        <div class="soiold"></div>
        <div class="gongsi">
            <!-- <img src="../assets/home_logo_tianyi@2x.png" alt=""> -->
            <div>
                <p>
                    {{ $t('btm.h33') }}
                </p>
                <div class="dowImg-det">
                    <img src="../assets/sim/contactImg2.svg" alt=""> {{ $t('contactView.h2') }}
                </div>
            </div>
        </div>
        <div style="height: 40px;"></div>
    </div>
</template>

<script>
export default {
    name: 'topbar',

    data() {
        return {
            num: 0,
            num2: 0,
            num3: 0,
            num4: 0,
        }
    },

    mounted() {
        // this.loadingData();
        this.$eventHub.$on("walletChanged", () => {
            this.loadingData();
        });
    },

    methods: {
        goFaqaa() {
            window.open('https://support.rac.art/qi-ta/yin-si-zheng-ce', '_blank')
        },
        goFaqbb() {
            window.open('https://support.rac.art/qi-ta/mian-ze-sheng-ming', '_blank')
        },
        goFaqcc() {
            window.open('https://support.rac.art/qi-ta/fu-wu-xie-yi', '_blank')
        },
        gpxiazai() {
            if (this.$router.currentRoute.path === '/download') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/download",
            });
        },
        goTrustpilot() {
            window.open('https://www.trustpilot.com/', '_blank');
        },
        over() {
            this.num = 1;
        },
        out() {
            this.num = 0;
        },
        over2() {
            this.num2 = 1;
        },
        out2() {
            this.num2 = 0;
        },
        over3() {
            this.num3 = 1;
        },
        out3() {
            this.num3 = 0;
        },
        over4() {
            this.num4 = 1;
        },
        out4() {
            this.num4 = 0;
        },
        goRebate() {
            if (this.$router.currentRoute.path === '/rebate') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/rebate",
            });
        },
        goStrategy() {
            if (this.$router.currentRoute.path === '/strategy') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/strategy",
            });
        },
        goFaq() {
            if (this.$router.currentRoute.path === '/faq') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/faq",
            });
        },
        goHelpCenter() {
            if (this.$router.currentRoute.path === '/helpCenter') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/helpCenter",
            });
        },
        goContact() {
            window.open('https://tianyi.art/contact/inquiry', '_blank');
        },
        goCommunity() {
            if (this.$router.currentRoute.path === '/community') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/community",
            });
        },
        goOem() {
            window.open('https://tianyi.art', '_blank');
        },
        goAbout() {
            if (this.$router.currentRoute.path === '/about') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/about",
            });
        },
        goDemoRobot() {
            if (this.$router.currentRoute.path === '/demoRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/demoRobot",
            });
        },
        goExchangeRobot() {
            if (this.$router.currentRoute.path === '/exchangeRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchangeRobot",
            });
        },
        goMerchantRobot() {
            if (this.$router.currentRoute.path === '/merchantRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/merchantRobot",
            });
        },
        goInterestRobot() {
            if (this.$router.currentRoute.path === '/interestRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/interestRobot",
            });
        },
        goCopyRobot() {
            if (this.$router.currentRoute.path === '/copyRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/copyRobot",
            });
        },
        goStrategyMall() {
            if (this.$router.currentRoute.path === '/strategyMall') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/strategyMall",
            });
        },
        goSignal() {
            if (this.$router.currentRoute.path === '/signal') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/signal",
            });
        },
        goChainData() {
            if (this.$router.currentRoute.path === '/chainData') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/chainData",
            });
        },
        goManualTrading() {
            if (this.$router.currentRoute.path === '/manualTrading') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/manualTrading",
            });
        },
        goPolicyBacktest() {
            if (this.$router.currentRoute.path === '/policyBacktest') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/policyBacktest",
            });
        },
        goMarket() {
            if (this.$router.currentRoute.path === '/market') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/market",
            });
        },
        linkWaller() {
            if (this.$router.currentRoute.path === '/wallet') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/wallet",
            });
        },
        goDca() {
            if (this.$router.currentRoute.path === '/DCA') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/DCA",
            });
        },
        changelang(e) {
            this.selectLang = false;
            this.$i18n.locale = e;
        },
        goto(e) {
            if (e == this.$route.path) {
                return
            }
            this.$router.push(e)
            this.showLeft = false
        },

    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        test() {

        }
    }
};
</script>

<style lang="scss" scoped>
$defaultSize: 0.0833333333vw;


.btm {
    margin: auto;
    margin-top: 100 * $defaultSize;

    width: 750 * $defaultSize;

    .about {
        display: flex;

        .left {
            width: 80%;

            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 20 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;

                .top_title {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #3DABFF;
                }

                .right1 {
                    width: 30%;
                    font-size: 0px !important;
                    height: 130px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin-right: 20px !important;


                    .topTitle1 {
                        font-size: 16 * $defaultSize;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFF !important;
                    }

                    .imgs1 {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        .A11 {
                            border: 1px #000 solid;
                            width: 30px;
                            height: 30px;
                            padding: 10px;
                            transition: 1s;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .A11:hover {
                            border: 1px #252a2f solid;
                            background-color: #252a2f;
                            border-radius: 4px;
                            transition: 1s;
                            cursor: pointer;
                        }

                        img {
                            margin-right: 2 * $defaultSize;
                            height: 20 * $defaultSize;
                            width: 20 * $defaultSize;
                        }
                    }

                    .topTitle {
                        font-size: 16 * $defaultSize;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 20 * $defaultSize;
                    }

                    .imgs {
                        width: 360px;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 30 * $defaultSize;

                        .A1 {
                            border: 1px #FFF solid;
                            width: 50px;
                            height: 46px;
                            padding: 10px;
                            transition: 1s;
                        }

                        .A1:hover {
                            border: 1px #e8ebef solid;
                            background-color: #e8ebef;
                            border-radius: 4px;
                            transition: 1s;
                        }

                        img {
                            margin-right: 30 * $defaultSize;
                            height: 12 * $defaultSize;
                        }
                    }
                }

            }

            .btmDiv {
                margin-top: 30 * $defaultSize;
                width: 100%;
                display: flex;

                // justify-content: space-between;
                .row {
                    margin-right: 60 * $defaultSize;

                    .line {
                        cursor: pointer;
                        font-size: 11 * $defaultSize;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #C5C5C5;
                        margin-top: 20px;
                    }

                    .line:hover {
                        color: #FFF;
                    }

                    .title {
                        margin-top: 10 * $defaultSize;
                        font-size: 14 * $defaultSize;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        margin-bottom: 10 * $defaultSize;
                        color: #FFF;
                    }
                }
            }
        }

        .right {
            width: 35%;
            padding-left: 50 * $defaultSize;

            .topTitle {
                font-size: 16 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                margin-bottom: 20 * $defaultSize;
            }

            .imgs {
                width: 360px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 30 * $defaultSize;

                .A1 {
                    border: 1px #000 solid;
                    width: 50px;
                    height: 46px;
                    padding: 10px;
                    transition: 1s;
                }

                .A1:hover {
                    border: 1px #e8ebef solid;
                    background-color: #e8ebef;
                    border-radius: 4px;
                    transition: 1s;
                }

                img {
                    margin-right: 30 * $defaultSize;
                    height: 12 * $defaultSize;
                }
            }

            .trustpilot2:hover {
                filter: brightness(110%);
                cursor: pointer;
            }

            .span_span {
                margin-top: 52px;
                margin-bottom: 17px;
                color: #FFF;
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
            }

            .QRCode {
                padding-right: 4px;
                margin-bottom: 22px;
            }

            .macos {
                margin-bottom: 14px;
                cursor: pointer;
                width: 168px;
                height: 51px;
                background-image: url('../../public/BTM2/macos.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                transition: 0.5s;
            }

            .macos:hover {
                margin-bottom: 14px;
                cursor: pointer;
                width: 168px;
                height: 51px;
                background-image: url('../../public/BTM2/macos_hover.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                transition: 0.5s;
            }

            .windows {
                cursor: pointer;
                width: 168px;
                height: 51px;
                background-image: url('../../public/BTM2/windows.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                transition: 0.5s;
            }

            .windows:hover {
                cursor: pointer;
                width: 168px;
                height: 51px;
                background-image: url('../../public/BTM2/windows_hover.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                transition: 0.5s;
            }

            .btmTitle {
                font-size: 16 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                margin-bottom: 20 * $defaultSize;
            }

            .box {
                display: flex;
                align-items: center;
                background: #F2F7FF;
                border-radius: 5 * $defaultSize;
                border: 1px solid #999999;
                margin-bottom: 15 * $defaultSize;
                width: 281px;
                height: 74px;

                .boxLeft {
                    width: 30%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 21 * $defaultSize;
                    }
                }

                .boxRight {
                    width: auto;

                    .boxtop {
                        font-size: 10 * $defaultSize;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4F5559;
                    }

                    .boxBtm {
                        font-size: 13 * $defaultSize;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }
                }
            }
        }
    }

    .renzheng {
        margin-top: 80 * $defaultSize;
        margin-bottom: 46px !important;
        display: flex;
        justify-content: space-between;

        .small {
            display: flex;
            align-items: center;
            font-size: 10 * $defaultSize;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #E7EBEF ;

            width: 20%;

            img {
                width: 36 * $defaultSize;
                margin-right: 10 * $defaultSize;
            }
        }
    }

    .soiold {
        width: 100%;
        height: 1px;
        background-color: #ccc;
    }

    .gongsi {
        margin-top: 40px;
        display: flex;
        align-items: center;

        img {
            width: 130 * $defaultSize;
            margin-right: 69 * $defaultSize;
        }

        p {

            font-size: 11 * $defaultSize;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E7EBEF;
        }

        .dowImg-det {
            font-size: 11 * $defaultSize;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E7EBEF;
            display: flex;
            align-items: center;

            img {
                margin-right: 5 * $defaultSize;
                width: 12 * $defaultSize;
                color: #FFFFFF;
            }
        }
    }
}
</style>