<template>
    <div class="market2">
        <div class="main">
            <div class="title">流动性平台，为<span>交易</span>而生</div>
            <div class="title2"> Reactor与多家知名中心化交易所和去中心化流动性平台合作， </div>
            <div class="title3">连接丰富的交易机会，让您自由把握投资。</div>

            <div class="box3">
                <div class="header1">
                    <div class="header2">
                        <div class="left">
                            <div class="small" :style="`${num === 0 ? 'color:#2751D0' : 'color:#333333'}`" @click="num = 0">
                                全部
                            </div>
                            <div class="small" :style="`${num === 1 ? 'color:#2751D0' : 'color:#333333'}`" @click="num = 1">
                                中心化交易所</div>
                            <div class="small" :style="`${num === 2 ? 'color:#2751D0' : 'color:#333333'}`" @click="num = 2">
                                去中心化流动性平台</div>
                        </div>
                        <div class="right">
                            <el-input class="custom-input" placeholder="搜索" v-model="input4">
                                <i slot="prefix" class="el-input__icon el-icon-search "></i>
                            </el-input>
                        </div>
                    </div>
                    <div class="bottom1">
                        <!-- 1 -->
                        <div v-if="input4 == '' || checkIfElementExists('Binance')">
                            <div class="box" v-if="num == 2 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/binance.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="bian">Binance</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame.png" alt=""></div>
                                            <div class="A22font">交易所分数 9.9</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1"> www.binance.com</div>
                                            <div class="A23font2">美国</div>
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <botton class="btn" @click="chuangjian1">创建账户</botton>
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1">
                                            <!-- <img src="../assets/bach1_1.png" alt=""> -->
                                        </div>
                                        <div class="A1img2">
                                            <!-- <img src="../assets/bach1_2.png" alt=""> -->
                                        </div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="bian">中心化交易所 · 现货 · 衍生品 · 现货手续费0.10%</div>
                                    </div>
                                    <div class="A3" @click="bian1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 2 -->
                        <div v-if="input4 == '' || checkIfElementExists('OKX')">
                            <div class="box" v-if="num == 2 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/okx.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="okx">OKX</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame2.png" alt=""></div>
                                            <div class="A22font">交易所分数 7.2</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1"> www.okx.com</div>
                                            <div class="A23font2">塞舌尔</div>
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <botton class="btn" @click="chuangjian2">创建账户</botton>
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="okx">中心化交易所 · 现货 · 衍生品 · 现货手续费0.10%</div>
                                    </div>
                                    <div class="A3" @click="okx1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 3 -->
                        <div v-if="input4 == '' || checkIfElementExists('Huobi')">
                            <div class="box" v-if="num == 2 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/huobi.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="huobi">Huobi</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame3.png" alt=""></div>
                                            <div class="A22font">交易所分数 6.7</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1"> www.huobi.com</div>
                                            <div class="A23font2">塞舌尔</div>
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <botton class="btn" @click="chuangjian3">创建账户</botton>
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="huobi">中心化交易所 · 现货 · 衍生品 · 现货手续费0.2%</div>
                                    </div>
                                    <div class="A3" @click="huobi1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 4 -->
                        <div v-if="input4 == '' || checkIfElementExists('UniSwap')">
                            <div class="box" v-if="num == 1 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/uniswap.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="uniSwap">UniSwap</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame2.png" alt=""></div>
                                            <div class="A22font">交易所分数 8.3</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1"> www.uniswap.org</div>
                                            <div class="A23font2">美国</div>
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <!-- <botton class="btn">创建账户</botton> -->
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="uniSwap">去中心化交易所 · 现货 · AMM模式 · 手续费0.3%</div>
                                    </div>
                                    <div class="A3" @click="uniSwap1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 5 -->
                        <div v-if="input4 == '' || checkIfElementExists('Curve')">
                            <div class="box" v-if="num == 1 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/curve.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="curve">Curve</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame2.png" alt=""></div>
                                            <div class="A22font">交易所分数 7.3</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1"> www.curve.fi</div>
                                            <div class="A23font2">瑞士</div>
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <!-- <botton class="btn">创建账户</botton> -->
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="curve">去中心化交易所 · 现货 · 矿池标准费用0.04%</div>
                                    </div>
                                    <div class="A3" @click="curve1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 6 -->
                        <div v-if="input4 == '' || checkIfElementExists('Balancer')">
                            <div class="box" v-if="num == 1 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/balancer.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="balancer">Balancer</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame3.png" alt=""></div>
                                            <div class="A22font">交易所分数 6.3</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1" style="border:0px !important"> app.balancer.fi</div>
                                            <!-- <div class="A23font2">美国</div> -->
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <!-- <botton class="btn">创建账户</botton> -->
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="balancer">去中心化交易所 · 现货 · AMM模式</div>
                                    </div>
                                    <div class="A3" @click="balancer1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 7 -->
                        <div v-if="input4 == '' || checkIfElementExists('PancakeSwap')">
                            <div class="box" v-if="num == 1 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/pancakeswap.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="pancakeswap">PancakeSwap</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame3.png" alt=""></div>
                                            <div class="A22font">交易所分数 6.1</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1" style="border:0px !important"> www.pancakeswap.finance
                                            </div>
                                            <!-- <div class="A23font2">美国</div> -->
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <!-- <botton class="btn">创建账户</botton> -->
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="pancakeswap">去中心化交易所 · 现货 · AMM模式 · 手续费0.25%</div>
                                    </div>
                                    <div class="A3" @click="pancakeswap1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 8 -->
                        <div v-if="input4 == '' || checkIfElementExists('DODO')">
                            <div class="box" v-if="num == 1 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/dodo.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="dodo">DODO</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame3.png" alt=""></div>
                                            <div class="A22font">交易所分数 5.4</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1" style="border:0px !important"> www.dodoex.io</div>
                                            <!-- <div class="A23font2">美国</div> -->
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <!-- <botton class="btn">创建账户</botton> -->
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="dodo">去中心化交易所 · 创建新Token收取0.02ETH</div>
                                    </div>
                                    <div class="A3" @click="dodo1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>
                        <!-- 9 -->
                        <div v-if="input4 == '' || checkIfElementExists('Sushi')">
                            <div class="box" v-if="num == 1 ? false : true">
                                <div class="box1">
                                    <div class="A1">
                                        <div class="A1img"><img src="market2/sushi.svg" alt=""></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A21" @click="sushi">Sushi</div>
                                        <div class="A22">
                                            <div> <img src="../assets/Frame3.png" alt=""></div>
                                            <div class="A22font">交易所分数 6.4</div>
                                        </div>
                                        <div class="A23">
                                            <div class="A23font1" style="border:0px !important"> www.sushi.com</div>
                                            <!-- <div class="A23font2">美国</div> -->
                                        </div>
                                    </div>
                                    <div class="A3">
                                        <!-- <botton class="btn">创建账户</botton> -->
                                    </div>
                                </div>
                                <div class="box2">
                                    <div class="A1">
                                        <div class="A1img1"></div>
                                        <div class="A1img2"></div>
                                    </div>
                                    <div class="A2">
                                        <div class="A2font" @click="sushi">去中心化交易所 · 现货 · 手续费0.3%</div>
                                    </div>
                                    <div class="A3" @click="sushi1"> 了解更多 </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="dibu">
                <div class="dowImg-top">
                    {{ $t('dowView.h1') }}
                </div>
                <div class="dowImg-det">
                    {{ $t('dowView.h2') }}
                </div>
                <div class="dowImg-btm">
                    <div class="dowImg-btm1">
                        <span>{{ $t('dowView.h3') }}</span>
                        <span class="solidSpan">
                            <img src="../assets/policyBacktest/home_button_right_normal (2).svg" alt="">
                        </span>
                    </div>
                    <div class="dowImg-btm2">
                        <!-- <img src="../../assets/home_icon_apple2_normal.png" alt=""> -->
                    </div>
                    <div class="dowImg-btm3">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';
import { NumSplic } from "../unit/tool";
import big from "big.js";
import axios from 'axios';
big.NE = -40;
big.PE = 40;
export default {
    name: "market2",
    data() {
        return {
            input4: '',
            num: 0,
            index: [],
            value: [
                'Binance',
                'OKX',
                'Huobi',
                'UniSwap',
                'Curve',
                'Balancer',
                'PancakeSwap',
                'DODO',
                'Sushi'
            ],
        };
    },
    watch: {
        input4: function (newVal, oldVal) {
            this.index = this.value.filter(item => item.toLowerCase().includes(newVal.toLowerCase()));
        }
    },
    created() {

    },
    methods: {
        chuangjian1() {
            window.open('https://accounts.binance.com/register?ref=28573299', '_blank')
        },
        chuangjian2() {
            window.open('https://okx.com/join/14940567', '_blank')
        },
        chuangjian3() {
            window.open('https://www.blue-bebe.com/invite/zh-cn/1f?invite_code=4zpz3', '_blank')
        },
        checkIfElementExists(element) {
            return this.index.includes(element);
        },
        bian() {
            this.$router.push({
                path: "/exchange",
            });
        },
        bian1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/bi-an-binance', '_blank')
        },
        huobi() {
            this.$router.push({
                path: "/exchange1",
            });
        },
        huobi1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/huo-bi-huobi', '_blank')

        },
        okx() {
            this.$router.push({
                path: "/exchange2",
            });
        },
        okx1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/ou-yi-okx', '_blank')
        },
        pancakeswap() {
            this.$router.push({
                path: "/exchange3",
            });
        },
        pancakeswap1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/pancakeswap', '_blank')
        },
        uniSwap() {
            this.$router.push({
                path: "/exchange4",
            });
        },
        uniSwap1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/uniswap', '_blank')
        },
        dodo() {
            this.$router.push({
                path: "/exchange5",
            });
        },
        dodo1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/dodo', '_blank')
        },
        curve() {
            this.$router.push({
                path: "/exchange6",
            });
        },
        curve1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/curve', '_blank')
        },
        balancer() {
            this.$router.push({
                path: "/exchange7",
            });
        },
        balancer1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/balancer', '_blank')
        },
        sushi() {
            this.$router.push({
                path: "/exchange8",
            });
        },
        sushi1() {
            window.open('https://support.rac.art/liu-dong-xing-ping-tai/reactor-zhi-chi-na-xie-jiao-yi-suo-he-liu-dong-xing-ping-tai/sushiswap', '_blank')
        },
    },
    computed: {},
    mounted() {
    },
};
</script>
  
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #224FD9; //修改后的背景图颜色
    color: #fff;
}

.add {
    color: rgb(39, 81, 208);
}

.market2 {
    border: 1px #FFF solid;
    margin-top: 7.1%;
    background-image: url('../assets/market2.png');
    background-repeat: no-repeat;
    background-size: 100%;


    .main {
        width: 750 * $defaultSize;
        margin: auto;
    }

    .title {
        text-align: center;
        margin-top: 5%;
        color: #333333;
        font-size: 64px;
        font-weight: bold;
        font-family: 'Inter-Semi Bold, Inter';

        span {
            color: #1E4DD8;
        }
    }

    .title2 {
        text-align: center;
        margin-top: 23px;
        font-size: 32px;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        color: #4F5559;
        line-height: 38px;
    }

    .title3 {
        text-align: center;
        font-size: 32px;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        color: #4F5559;
        line-height: 38px;
    }

    .box3 {
        background: #FFFFFF;
        border-radius: 32px;
        margin-top: 35 * $defaultSize;
        margin: auto;

        .header1 {

            border: 1px #D8D8D8 solid;
            border-radius: 32px;
            margin-top: 86px;

            .header2 {
                display: flex;
                justify-content: space-between;
                height: 65 * $defaultSize;
                align-items: center;
                padding: 0 25 * $defaultSize;

                .left {
                    display: flex;

                    .small {
                        cursor: pointer;
                        font-size: 13 * $defaultSize;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        margin-right: 25 * $defaultSize;
                    }

                    .small:hover {
                        color: #1F4ED8;
                    }

                    .check {
                        color: #224FD9;
                    }
                }

                .right {
                    .custom-input:hover {
                        border-radius: 4px;
                        box-shadow: 0 0 0 1px #1F4ED8;
                        /* 鼠标悬停时修改边框颜色为红色 */
                    }
                }
            }
        }

        .bottom1 {
            // border: 1px #fff solid;
            padding: 0 25 * $defaultSize;

            .box {
                border: 1px #D8D8D8 solid;
                border-radius: 24px;

                height: 240px;
                margin: auto;
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .box1 {
                    border-bottom: 1px #D8D8D8 solid;
                    width: 100%;
                    height: 76%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 30px 60px;

                    .A1 {
                        width: 100px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .A2 {
                        width: 540px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .A21 {
                            width: 200px;
                            height: 100px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            font-size: 26px;
                            color: #333333;
                            font-weight: bold;
                        }

                        .A21:hover {
                            color: rgb(39, 81, 208);
                            cursor: pointer;
                        }

                        .A22 {
                            width: 500px;
                            height: 100px;
                            display: flex;
                            align-items: center;

                            img {
                                height: 25px;
                                object-fit: contain;
                            }

                            .A22font {
                                margin-left: 16px;
                                font-size: 16px;
                                color: #333333;
                            }
                        }

                        .A23 {
                            width: 400px;
                            height: 100px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .A23font1 {
                                border-right: 3px #DCDCE5 dotted;
                                // width: 266px;
                                padding-right: 20px;
                                font-size: 20px;
                                color: #696A6A;
                            }

                            .A23font2 {
                                font-size: 16px;
                                color: #696A6A;
                                margin-left: 20px;

                            }
                        }
                    }

                    .A3 {
                        width: 160px;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        .btn {
                            cursor: pointer;
                            width: 85%;
                            height: 50px;
                            background: #03A76A;
                            color: #FFF;
                            text-align: center;
                            line-height: 50px;
                            font-size: 18px;
                            border-radius: 8px 8px 8px 8px;
                        }
                    }
                }

                .box2 {
                    width: 100%;
                    height: 24%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px 60px;

                    .A1 {
                        width: 100px;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .A1img1 {
                            width: 30px;
                            height: 30px;
                            background-image: url('../assets/bach1_1.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            transition: 0.5s;
                            cursor: pointer;
                        }

                        .A1img1:hover {
                            width: 30px;
                            height: 30px;
                            background-image: url('../../public/market2/icon_twitter_hover.svg');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            transition: 0.5s;
                            cursor: pointer;
                        }

                        .A1img2 {
                            width: 30px;
                            height: 30px;
                            background-image: url('../assets/bach1_2.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            margin-right: 10 * $defaultSize;;
                        }

                        .A1img2:hover {
                            width: 30px;
                            height: 30px;
                            background-image: url('../../public/market2/icon_telegram_hover.svg');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            transition: 0.5s;
                            cursor: pointer;
                           
                        }

                    }

                    .A2 {
                        width: 576px;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .A2font {
                            border-left: 1px #DCDCE5 solid;
                            padding: 0px 20px;
                            // margin-left: 20px;
                            height: 40px;
                            line-height: 40px;
                            color: #333333;
                            font-size: 16px;
                        }

                        .A2font:hover {
                            cursor: pointer;
                            color: rgb(39, 81, 208);
                        }
                    }

                    .A3 {
                        width: 160px;
                        height: 100%;
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        text-decoration: underline;
                        color: #1E4DD8;
                        font-size: 16px;
                        cursor: pointer;
                        padding-right: 20px;
                    }

                }
            }
        }

    }

    .dibu {
        margin: auto;
        margin-top: 74px;
        height: 290px;
        border-radius: 24px;
        background-image: url('../assets/bg222.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dowImg-top {
            font-size: 32px;
            color: #333333;
            text-align: center;
            margin-bottom: 22px;
        }

        .dowImg-det {
            font-size: 20px;
            color: #4F5559;
            text-align: center;
            margin-bottom: 32px;
        }

        .dowImg-btm {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .dowImg-btm1 {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                text-align: center;
                font-weight: 500;
                color: #FFFFFF;

                img {
                    width: 12 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .dowImg-btm1:hover {
                background: #1F41A7;

                .solidSpan {
                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }

            .dowImg-btm2 {
                cursor: pointer;
                margin-left: 20 *$defaultSize;
                width: 25 *$defaultSize;
                height: 25 *$defaultSize;
                background-image: url('../assets/sim/home_icon_apple_dark(1).svg');
                /* 默认图片路径 */
                background-size: 100%;
            }

            .dowImg-btm2:hover {
                background-image: url('../assets/sim/home_icon_apple_dark.svg');
                /* 默认图片路径 */
            }

            .dowImg-btm3 {
                cursor: pointer;
                margin-left: 10 *$defaultSize;
                width: 25 *$defaultSize;
                height: 25 *$defaultSize;
                background-image: url('../assets/sim/home_icon_win_dark.svg');
                /* 默认图片路径 */
                background-size: 100%;
            }

            .dowImg-btm3:hover {
                background-image: url('../assets/sim/home_icon_win_dark(1).svg');
                /* 默认图片路径 */
            }
        }
    }
}
</style>
  