<template>
    <div class="helpCenter">
        <div class="box1">
            <!-- <div class="h1">{{ $t('helpCenter.h1') }}</div>
            <div class="h2">{{ $t('helpCenter.h2') }}</div>
            <div class="elInput">
                <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="input2">
                </el-input>
            </div> -->
        </div>
        <div class="main">
            <div class="box2">
                <div class="h1">{{ $t('helpCenter.h3') }}</div>
                <div class="h2">{{ $t('helpCenter.h4') }}</div>
                <div class="big">
                    <div class="small" @click="goFaq">
                        <img src="../assets/home_logo_reactor.png" alt="">
                        <div class="smallH1">{{ $t('helpCenter.h5') }}</div>
                        <div class="smallH2">{{ $t('helpCenter.h6') }}</div>
                    </div>
                    <div class="small" @click="goFaq">
                        <img src="../assets/icon-nexonian.png" alt="">
                        <div class="smallH1">{{ $t('helpCenter.h7') }}</div>
                        <div class="smallH2">{{ $t('helpCenter.h8') }}</div>
                    </div>
                </div>
                <div class="flex">
                    <div class="small" @click="goRouter(0)">
                        <img src="../assets/icon_demo.png" alt="">
                        <div class="name">{{ $t('helpCenter.h9') }}</div>
                    </div>
                    <div class="small" @click="goRouter(1)">
                        <img src="../assets/Cross-chain assets crypto projects - Gate 1.png" alt="">
                        <div class="name">{{ $t('helpCenter.h10') }}</div>
                    </div>
                    <div class="small" @click="goRouter(2)">
                        <img src="../assets/DEX crypto projects - Gate 1.png" alt="">
                        <div class="name">{{ $t('helpCenter.h11') }}</div>
                    </div>
                    <div class="small" @click="goRouter(3)">
                        <img src="../assets/year.png" alt="">
                        <div class="name">{{ $t('helpCenter.h12') }}</div>
                    </div>
                    <div class="small" @click="goRouter(4)">
                        <img src="../assets/icon_demo.png" alt="">
                        <div class="name">{{ $t('helpCenter.h13') }}</div>
                    </div>

                    <div class="small" @click="goRouter(5)">
                        <img src="../assets/icon_demo1.png" alt="">
                        <div class="name">{{ $t('helpCenter.h14') }}</div>
                    </div>
                    <div class="small" @click="goRouter(6)">
                        <img src="../assets/icon_wall.png" alt="">
                        <div class="name">{{ $t('helpCenter.h15') }}</div>
                    </div>
                    <div class="small" @click="goRouter(7)">
                        <img src="../assets/icon_dca.png" alt="">
                        <div class="name">{{ $t('helpCenter.h16') }}</div>
                    </div>
                    <div class="small" @click="goRouter(8)">
                        <img src="../assets/icon_track.png" alt="">
                        <div class="name">{{ $t('helpCenter.h17') }}</div>
                    </div>
                    <div class="small" @click="goRouter(9)">
                        <img src="../assets/icon_signal.png" alt="">
                        <div class="name">{{ $t('helpCenter.h18') }}</div>
                    </div>


                    <div class="small" @click="goRouter(10)">
                        <img src="../assets/icon_wall (1).png" alt="">
                        <div class="name">{{ $t('helpCenter.h19') }}</div>
                    </div>
                    <div class="small" @click="goRouter(11)">
                        <img src="../assets/icon_wall (2).png" alt="">
                        <div class="name">{{ $t('helpCenter.h20') }}</div>
                    </div>
                    <div class="small" @click="goRouter(12)">
                        <img src="../assets/icon_data1.png" alt="">
                        <div class="name">{{ $t('helpCenter.h21') }}</div>
                    </div>
                    <div class="small" @click="goRouter(13)">
                        <img src="../assets/icon_tradebot.png" alt="">
                        <div class="name">{{ $t('helpCenter.h22') }}</div>
                    </div>
                    <div class="small" @click="goRouter(14)">
                        <img src="../assets/icon_ia.png" alt="">
                        <div class="name">{{ $t('helpCenter.h23') }}</div>
                    </div>
                </div>
            </div>
            <div class="box3">
                <div class="title">{{ $t('helpCenter.h24') }}</div>
                <div class="body">
                    <div class="small"  @click="goRouter(15)">· {{ $t('helpCenter.h25') }}</div>
                    <div class="small"  @click="goRouter(16)">· {{ $t('helpCenter.h26') }}</div>
                    <div class="small"  @click="goRouter(17)">· {{ $t('helpCenter.h27') }}</div>
                    <div class="small"  @click="goRouter(18)">· {{ $t('helpCenter.h28') }}</div>
                    <div class="small"  @click="goRouter(19)">· {{ $t('helpCenter.h29') }}</div>
                    <div class="small"  @click="goRouter(20)">· {{ $t('helpCenter.h30') }}</div>
                    <div class="small"  @click="goRouter(21)">· {{ $t('helpCenter.h31') }}</div>
                    <div class="small"  @click="goRouter(22)">· {{ $t('helpCenter.h32') }}</div>
                </div>
            </div>
            <div>
                <help-view></help-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import helpView from './simViews/helpView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { helpView },
    name: "helpCenter",
    data() {
        return {
            input2: undefined
        };
    },
    methods: {
        goFaq() {
            if (this.$router.currentRoute.path === '/faq') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin-gong-gao/gong-gao-zhuan-qu', '_blank')
        },
        goRouter(n) {
            if (this.$router.currentRoute.path === '/faq') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            if (n == 0) {
                window.open('https://support.rac.art/fan-yong/ru-he-yao-qing-ta-ren-bing-huo-de-fan-yong', '_blank')
            }
            if (n == 1) {
                window.open('https://support.rac.art/liu-dong-xing-ping-tai/shen-me-shi-liu-dong-xing-ping-tai-wo-gai-ru-he-lian-jie-dao-liu-dong-xing-ping-tai', '_blank')
            }
            if (n == 2) {
                window.open('https://support.rac.art/ding-yue-he-fu-kuan/ru-he-ding-yue-reactor-de-hui-yuan-fu-wu', '_blank')
            }
            if (n == 3) {
                window.open('https://support.rac.art/an-quan-wen-ti/wo-men-ru-he-bao-zhang-yong-hu-zi-chan-de-an-quan', '_blank')
            }
            if (n == 4) {
                window.open('https://support.rac.art/hui-yuan-quan-yi/ru-he-cheng-wei-reactor-de-hui-yuan-hui-yuan-xiang-you-na-xie-te-quan-he-you-shi', '_blank')
            }
            if (n == 5) {
                window.open('https://support.rac.art/chan-pin/zuo-shi-shang-ji-qi-ren', '_blank')
            }
            if (n == 6) {
                window.open('https://support.rac.art/chan-pin/shou-dong-jiao-yi', '_blank')
            }
            if (n == 7) {
                window.open('https://support.rac.art/chan-pin/demo-ji-qi-ren', '_blank')
            }
            if (n == 8) {
                window.open('https://support.rac.art/chan-pin/ce-lve-hui-ce', '_blank')
            }
            if (n == 9) {
                window.open('https://support.rac.art/chan-pin/xin-hao-shang-cheng', '_blank')
            }
            if (n == 10) {
                window.open('https://support.rac.art/chan-pin/ce-lve-shang-cheng', '_blank')
            }
            if (n == 11) {
                window.open('https://support.rac.art/chan-pin/fu-zhi-ji-qi-ren', '_blank')
            }
            if (n == 12) {
                window.open('https://support.rac.art/chan-pin/k-xian-yu-lian-shang-shu-ju', '_blank')
            }
            if (n == 13) {
                window.open('https://support.rac.art/chan-pin/jiao-yi-ji-qi-ren', '_blank')
            }
            if (n == 14) {
                window.open('https://support.rac.art/chan-pin/tao-li-ji-qi-ren', '_blank')
            }
            if (n == 15) {
                window.open('https://support.rac.art/xin-shou-zhi-dao/wo-ke-yi-zhuan-qu-duo-shao-li-run', '_blank')
            }
            if (n == 16) {
                window.open('https://support.rac.art/chan-pin/tao-li-ji-qi-ren/tao-li-shi-ru-he-yun-zuo-de', '_blank')
            }
            if (n == 17) {
                window.open('https://support.rac.art/xin-shou-zhi-dao/ni-men-bao-zheng-ying-li-ma', '_blank')
            }
            if (n == 18) {
                window.open('https://support.rac.art/liu-dong-xing-ping-tai/shen-me-shi-liu-dong-xing-ping-tai-wo-gai-ru-he-lian-jie-dao-liu-dong-xing-ping-tai', '_blank')
            }
            if (n == 19) {
                window.open('https://support.rac.art/xin-shou-zhi-dao/wo-ke-yi-zai-yi-ge-jiao-yi-zhang-hu-shang-kai-qi-ji-ge-ji-qi-ren', '_blank')
            }
            if (n == 20) {
                window.open('https://support.rac.art/xin-shou-zhi-dao/wo-ying-gai-shi-yong-na-zhong-ji-chu-huo-bi', '_blank')
            }
            if (n == 21) {
                window.open('https://support.rac.art/an-quan-wen-ti/wo-men-ru-he-bao-zhang-yong-hu-zi-chan-de-an-quan', '_blank')
            }
            if (n == 22) {
                window.open('https://support.rac.art/xin-shou-zhi-dao/tui-chu-zhu-hd-qian-bao-hou-wo-de-zhang-hu-hai-zai-ma', '_blank')
            }
        }
    },
    computed: {},
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.el-input.is-focus {
    border-color: #ff0000;
    /* 设置你想要的边框颜色 */
}

.helpCenter {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        background-image: url("../assets/helpCenterBgi.png");
        background-size: 100%;
        background-repeat: no-repeat;
        height: 399 * $defaultSize;
        text-align: center;
        padding-top: 88 * $defaultSize;

        .h1 {
            text-align: center;
            font-size: 40 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: normal;
            color: #333333;
            margin-bottom: 15 * $defaultSize;
        }

        .h2 {
            text-align: center;
            font-size: 20 * $defaultSize;
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            color: #4F5559;
            margin-bottom: 50 * $defaultSize;
        }

        .elInput {
            width: 570 * $defaultSize;
            margin: auto;
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;
        margin-top: -300 * $defaultSize;

        .box2 {
            .h1 {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: normal;
                color: #000000;
                text-align: center;
            }

            .h2 {
                font-size: 20 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #4F5559;
                margin-top: 15 * $defaultSize;
                text-align: center;
                margin-bottom: 17 * $defaultSize;
            }

            .big {
                display: flex;
                justify-content: space-between;

                .small {
                    cursor: pointer;
                    width: 48%;
                    padding: 25 * $defaultSize 35 * $defaultSize;
                    border-radius: 10 * $defaultSize;
                    border: 1px solid #E6E9EC;
                    text-align: center;

                    img {
                        width: 25 * $defaultSize;
                    }

                    .smallH1 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }

                    .smallH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }

                .small:hover {
                    border: 1px solid #1E4DD8;
                }
            }

            .flex {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .small {
                    cursor: pointer;
                    margin: 25 * $defaultSize 0 0 0;
                    border-radius: 5 * $defaultSize;
                    border: 1px solid #E6E9EC;
                    padding: 10 * $defaultSize;
                    width: 18%;

                    img {
                        width: 34 * $defaultSize;
                        margin-bottom: 10 * $defaultSize;
                    }

                    .name {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #333333;
                    }
                }

                .small:hover {
                    border: 1px solid #1E4DD8;

                    .name {
                        color: #1E4DD8;
                    }
                }
            }
        }

        .box3 {
            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: normal;
                color: #000000;
                margin-top: 88 * $defaultSize;
                margin-bottom: 25 * $defaultSize;
            }

            .body {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .small {
                    cursor: pointer;
                    width: 50%;
                    font-size: 14 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 25 * $defaultSize;
                }

                .small:hover {
                    color: #1E4DD8;
                }
            }
        }
    }
}</style>

<style>.elInput .el-input.is-active .el-input__inner,
.el-input__inner:focus {
    border-color: #1E4DD8 !important;
}</style>
  