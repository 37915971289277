<template>
    <div class="cooperation" @scroll="handleScroll">
        <div class="box1">
            <img src="../assets/SecurityImg11.png" alt="" class="animate__animated animate__fadeInDown">
        </div>
        <div class="main">
          <div class="h1 animate__animated animate__fadeInDown">{{$t('cooperation.h1')}}</div>
          <div class="h2 animate__animated animate__fadeInDown">{{$t('cooperation.h2')}}</div>

          <div class="box2">
            <div class="row">
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'">
                <div class="up">{{$t('cooperation.h3')}}</div>
                <div class="down">{{$t('cooperation.h4')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.02s">
                <div class="up">{{$t('cooperation.h5')}}</div>
                <div class="down">{{$t('cooperation.h6')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.04s">
                <div class="up">{{$t('cooperation.h7')}}</div>
                <div class="down">{{$t('cooperation.h8')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.06s">
                <div class="up">{{$t('cooperation.h9')}}</div>
                <div class="down">{{$t('cooperation.h8')}}</div>
              </div>
            </div>
            <div class="row">
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.08s">
                <div class="up">{{$t('cooperation.h10')}}</div>
                <div class="down">{{$t('cooperation.h11')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.1s">
                <div class="up">{{$t('cooperation.h12')}}</div>
                <div class="down">{{$t('cooperation.h6')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.12s">
                <div class="up">{{$t('cooperation.h13')}}</div>
                <div class="down">{{$t('cooperation.h8')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.14s">
                <div class="up">{{$t('cooperation.h14')}}</div>
                <div class="down">{{$t('cooperation.h8')}}</div>
              </div>
            </div>
            <div class="row">
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.16s">
                <div class="up">{{$t('cooperation.h15')}}</div>
                <div class="down">{{$t('cooperation.h11')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.18s">
                <div class="up">{{$t('cooperation.h16')}}</div>
                <div class="down">{{$t('cooperation.h6')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.2s">
                <div class="up">{{$t('cooperation.h17')}}</div>
                <div class="down">{{$t('cooperation.h8')}}</div>
              </div>
              <!-- <div class="small">
                <div class="up"></div>
                <div class="down"></div>
              </div> -->
            </div>
            <div class="row">
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.22s">
                <div class="up">{{$t('cooperation.h18')}}</div>
                <div class="down">{{$t('cooperation.h19')}}</div>
              </div>
              <div class="small" :class="animationStatus?'animationDiv':'textAnimation_none'" style="animationDelay:0.24s">
                <div class="up">{{$t('cooperation.h20')}}</div>
                <div class="down">{{$t('cooperation.h6')}}</div>
              </div>
            </div>
          </div>
          <div> 
            <contact-view></contact-view>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import { NumSplic } from "../unit/tool";
  import big from "big.js";
import contactView from './simViews/contactView.vue';
  big.NE = -40;
  big.PE = 40;
  export default {
  components: { contactView },
    name: "cooperation",
    data() {
      return {
        animationStatus:false
      };
    },
    methods: {
      handleScroll(e) {
        if (this.getScrollTop() >= 100) {
          this.animationStatus = true
        }
      },
      //获取当前滚动条的位置
      getScrollTop(){
          var scrollTop=0
          if(document.documentElement&&document.documentElement.scrollTop){
              scrollTop = document.documentElement.scrollTop
          }else if(document.body){
              scrollTop = document.body.scrollTop
          }
          return scrollTop
      },
    },
    mounted() {
      // 获取滚动容器的引用
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      // 移除滚动事件监听
      window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {},
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $defaultSize: 0.0833333333vw;
.animationDiv{
  animation: tracking-in-expand-fwd-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none{
  animation: none;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}
@keyframes tracking-in-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
            transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    opacity: 0;
  }
  40% {
    opacity: 0.1;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
  .cooperation {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        background-image: url("../assets/nexo-corporate-accounts-background.png");
        background-size: 100%;
        background-repeat: no-repeat;
        height: 308 * $defaultSize;
        text-align: center;
        display: flex;
        align-items: end;
        justify-content: center;
        img {
            width: 201 * $defaultSize;
            margin-bottom: 19 * $defaultSize;
        }
    }
    .main {
        width: 750 * $defaultSize;
        margin: auto;
        .h1 { 
          font-size: 40 * $defaultSize;
          font-family: Inter-Semi Bold, Inter;
          font-weight: normal;
          color: #333333;
          text-align: center;
        }
        .h2 {
          padding: 0 5%;
          margin-top: 15 * $defaultSize;
          font-size: 16 * $defaultSize;
          font-family: Inter-Regular, Inter;
          font-weight: 400;
          color: #4F5559;
          text-align: center;
        }
        .box2 {
          margin-top: 100 * $defaultSize;
          .row {
            display: flex;
            justify-content: space-between;
            padding: 25 * $defaultSize 0;
            border-bottom: 1px solid #ccc;

            &:nth-of-type(3){
              margin-left: 32 * $defaultSize;
              width: 500* $defaultSize;
            }

            &:nth-of-type(4){
              margin-left: 65 * $defaultSize;
              width: 250* $defaultSize;
            }

            .small {
              width: 25%;
              text-align: center;
              .up {
                font-size: 20 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 700;
                color: #333333;
                margin-bottom: 2 * $defaultSize;
              }
              .down {
                font-size: 13 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #4F5559; 
              }
            }
          }
        }
    }
  }
  </style>
  