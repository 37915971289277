import Vue from 'vue';
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale:'cn',
    messages:{
      'cn':require('./cn'),
      'en':require('./en'),
      'ko':require('./ko'),
      'jp':require('./jp'),
      'tra':require('./tra'),
      'thai':require('./thai'),
      'malay':require('./malay'),
      'vietnam':require('./vietnam'),
    }
  })

  export default i18n