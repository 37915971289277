<template>
    <div class="copyRobot">

        <div class="box1">
            <div class="main">
                <div class="left">
                    <div class="top">
                        <div class="leftH1" style="display:flex;">
                            <span v-for="(item,index) in h1Arr($t('demoRobot.h1'))" :key="index" :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]" 
                            :style="{ animationDelay:`${index * 0.02}s`}">{{ item }}
                            <span v-if="getI18nByEn()">&nbsp;</span>
                        </span>
                        </div>
                        <div class="leftH2" style="display:flex;">
                            <span v-for="(item,index) in h1Arr($t('demoRobot.h2'))" :key="index" :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]" 
                            :style="{ animationDelay:`${(index * 0.02) + (h1Arr($t('demoRobot.h1')).length *0.02)}s`}">
                            {{ item }}
                            <span v-if="getI18nByEn()">&nbsp;</span>
                            </span>
                        </div>
                    </div>
                    <div class="btm" @click="goFaq">
                        <span class="textSpan">
                            {{$t('copyRobot.h3')}}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box2">
                <div class="h1">{{$t('demoRobot.h4')}}</div>
                <div class="h2">{{$t('demoRobot.h5')}}</div>
            </div>
            <div class="box3 " >
                <div class="title">
                    <img class="right" v-animate="'animate__animated animate__fadeInLeft'" src="../assets/Group 93.svg" alt="">
                    <div class="left" v-animate="'animate__animated animate__fadeInRight'">
                        <div class="h1">{{$t('demoRobot.h6')}}</div>
                        <div class="h2">
                            {{$t('demoRobot.h7')}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="box3">
                <div class="title">
                    <div class="left" v-animate="'animate__animated animate__fadeInLeft'">
                        <div class="h1">{{$t('demoRobot.h8')}}</div>
                        <div class="h2">
                            {{$t('demoRobot.h9')}}
                        </div>
                    </div>
                    <img class="right" v-animate="'animate__animated animate__fadeInRight'" src="../assets/Group 92.svg" alt="">
                </div>
            </div>

            <div class="box3">
                <div class="title">
                    <img class="right" v-animate="'animate__animated animate__fadeInLeft'" src="../assets/Group 94.svg" alt="">
                    <div class="left" v-animate="'animate__animated animate__fadeInRight'">
                        <div class="h1">{{$t('demoRobot.h10')}}</div>
                        <div class="h2">
                            {{$t('demoRobot.h11')}}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { NumSplic } from "../unit/tool";
  import big from "big.js";
  import {mapGetters} from "vuex"
import dowView from './simViews/dowView.vue';
  big.NE = -40;
  big.PE = 40;
  export default {
  components: { dowView },
    name: "copyRobot",
    data() {
      return {
      };
    },
    methods: {
        getI18nByEn(){
      return this.$i18n.locale == 'en';
    },
        goFaq(){
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/demo-ji-qi-ren/shen-me-shi-demo-ji-qi-ren', '_blank')
        },
    },
    computed:{
        ...mapGetters(['GET_IsAnimation']),
        h1Arr(text){
      if(this.$i18n.locale == 'en'){
        return text => text.split(" ");
      }else{
        return text => text.split("")
      }
    }
    },
    mounted() {
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $defaultSize: 0.0833333333vw;
  .textAnimation{
  display: block;
  animation: tracking-in-expand-fwd-bottom 0.1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none{
  animation: none;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

@keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}

 @keyframes tracking-in-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
            transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    opacity: 0;
  }
  40% {
    opacity: 0.1;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
  .copyRobot {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1{
        padding-top: 38 * $defaultSize;
        display: flex;
        height: 308 * $defaultSize;
        // background-image: url("../assets/signalBGI.png");
        background: url("../assets/demoRobotBGI.svg") no-repeat;
        background-size: 100%;
        justify-content: center;
        .left{
            width: 60%;
            .top{
                .leftH1{
                    font-size: 35 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 700;
                    color: #000000;
                    margin-bottom: 10 * $defaultSize;
                }
                .leftH2{
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                }
            }
            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                font-weight: 600;
                color: #FFFFFF;
                .textSpan{
                    margin-left: 10 * $defaultSize;
                }
                img{
                    width: 13 * $defaultSize;
                    color: #FFFFFF;
                }
            }
            .btm:hover{
                background: #1F41A7;
                .solidSpan{
                    img{
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }
        .right{
            width: 60%;
            img {
                width: 100%;
            }
        }
    }
    .main {
        width: 750 * $defaultSize;
        margin: auto;
        .box2 {
            .h1 {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 600;
                color: #333333;
                margin-top: 50 * $defaultSize;
            }
            .h2 {
                font-size: 14 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #333333;
                margin-top: 25 * $defaultSize;
            }
        }
        .box3{
            margin: 88 * $defaultSize 0;
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left{
                    width: 48%;
                    .h1 {
                        font-size: 24 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 25 * $defaultSize;
                    }
                    .h2 {
                        display: flex;
                        align-items: center;
                        font-size: 13 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                        margin-bottom: 15 * $defaultSize;
                        img {
                            width: 15 * $defaultSize;
                        }
                    }
                }
                .right {
                    width: 48%;
                }
            }
            .big{
                display: flex;
                justify-content: space-between;
                .small{
                    width: 23%;
                    text-align: center;
                    border-radius: 20 * $defaultSize;
                    opacity: 1;
                    // border: 1px solid #CCCCCC;
                    padding: 25 * $defaultSize;
                    img {
                        width: 75 * $defaultSize;
                    }
                    .smallH1{
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 19 * $defaultSize;
                    }
                    .smallH2{
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }
        
    }
  }
  </style>
  
  