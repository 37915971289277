<template>
    <div class="copyRobot">
        <div class="box1">
            <div class="left">
                <div class="top">
                    <div class="leftH1" style="display:flex;">
                        <span v-for="(item, index) in h1Arr($t('copyRobot.h1'))" :key="index"
                            :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
                            :style="{ animationDelay: `${index * 0.02}s` }">{{ item }}
                            <span v-if="getI18nByEn()">&nbsp;</span>
                        </span>
                    </div>
                    <div class="leftH2" style="display:flex;">
                        <span v-for="(item, index) in h1Arr($t('copyRobot.h2'))" :key="index"
                            :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
                            :style="{ animationDelay: `${(index * 0.02) + (h1Arr($t('copyRobot.h1')).length * 0.02)}s` }">
                            {{ item }}
                            <span v-if="getI18nByEn()">&nbsp;</span>
                        </span>
                    </div>
                </div>
                <div class="btm" @click="goFaq">
                    <span class="textSpan">
                        {{ $t('copyRobot.h3') }}
                    </span>
                    <span class="solidSpan">
                        <img src="../assets/chainData/home_button_right_normal.svg" />
                    </span>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box3">
                <div class="title">
                    <div class="left">
                        <div class="h3">{{ $t('copyRobot.h4') }}</div>
                        <div class="h1">{{ $t('copyRobot.h5') }}</div>
                        <div class="h2" v-animate="'animate__animated animate__fadeInLeft'">
                            <img src="../assets/copyRobot/Vector.svg" alt="">
                            <span>
                                {{ $t('copyRobot.h6') }}
                            </span>
                        </div>
                        <div class="h2" v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.3s">
                            <img src="../assets/copyRobot/Vector.svg" alt="">
                            <span>
                                {{ $t('copyRobot.h7') }}
                            </span>
                        </div>
                        <div class="h2 " v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.6s">
                            <img src="../assets/copyRobot/Vector.svg" alt="">
                            <span>
                                {{ $t('copyRobot.h8') }}
                            </span>
                        </div>
                        <div class="h4" @click="goFaq2">
                            <span class="textSpan">
                                {{ $t('copyRobot.h9') }}
                            </span>
                            <span class="solidSpan">
                                <img src="../assets/copyRobot/home_icon_more_normal.svg" />
                            </span>
                        </div>
                    </div>
                    <img class="right " v-animate="'animate__animated animate__fadeIn'"
                        src="../assets/copyRobot/Group 137.svg" alt="">
                </div>

                <div class="bomDiv">
                    <div class="smallH1">
                        {{ $t('copyRobot.h10') }}
                    </div>
                    <div class="smallH3" v-animate="'animate__animated animate__fadeInDown'">
                        <span class="small" v-for="(data, index) in [1, 2, 3, 4] " :key="index">
                            <img src="../assets/chainData/tab1.svg">
                            <span> {{ $t('copyRobot.h' + (10 + data)) }} </span>
                        </span>
                    </div>
                    <div class="btm" @click="goFaq3">
                        <span class="textSpan">
                            {{ $t('copyRobot.h15') }}
                            <img src="@/assets/rebate/home_button_right_normal.svg" alt="右箭头">
                        </span>
                    </div>
                </div>

                <div class="big">
                    <div class="big-title">{{ $t('copyRobot.h16') }}</div>
                    <div class="hint">{{ $t('copyRobot.h17') }}</div>
                    <div class="small1">
                        <div class="smallH1" v-for="(data, index) in [1, 2, 3]" :key="index">
                            <div class="div1"> {{ $t('copyRobot.h' + (17 + data)) }} </div>
                            <div class="div2"> {{ $t('copyRobot.h' + (20 + data)) }} </div>
                        </div>
                    </div>
                    <div class="small2">
                        <div class="smallH1" v-for="(data, index) in [1, 2, 3]" :key="index">
                            <div class="div1"> {{ $t('copyRobot.h' + (23 + data)) }} </div>
                            <div class="div2"> {{ $t('copyRobot.h' + (26 + data)) }} </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="box8">
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import { mapGetters } from "vuex"
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "copyRobot",
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters(['GET_IsAnimation']),
        h1Arr(text) {
            if (this.$i18n.locale == 'en') {
                return text => text.split(" ");
            } else {
                return text => text.split("")
            }
        }
    },
    methods: {
        getI18nByEn() {
            return this.$i18n.locale == 'en';
        },
        goFaq() {
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/fu-zhi-ji-qi-ren/shen-me-shi-fu-zhi-ji-qi-ren', '_blank')
        },
        goFaq2(){
            window.open('https://support.rac.art/chan-pin/fu-zhi-ji-qi-ren/fu-zhi-ji-qi-ren-de-jiao-yi-ru-he-zhi-hang', '_blank')
        },
        goFaq3(){
            window.open('https://support.rac.art/chan-pin/fu-zhi-ji-qi-ren/wo-gai-fu-zhi-shui-de-ji-qi-ren-reactor-ti-gong-le-shen-me-jin-hang-can-kao', '_blank')
        },
    },
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.textAnimation {
    display: block;
    animation: tracking-in-expand-fwd-bottom 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none {
    animation: none;
    animation-play-state: paused;
    animation-fill-mode: forwards;
}

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}

@keyframes tracking-in-expand-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        opacity: 0;
    }

    40% {
        opacity: 0.1;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

.copyRobot {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        padding-top: 88 * $defaultSize;
        display: flex;
        height: 408 * $defaultSize;
        // background-image: url("../assets/signalBGI.png");
        background: url("../assets/copyRobot/Group 134.svg") no-repeat;
        background-size: 100%;
        justify-content: center;

        .left {
            width: 750 * $defaultSize;

            .top {
                .leftH1 {
                    font-size: 40 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 10 * $defaultSize;

                    span {
                        display: inline-block;
                    }

                    width: 700 * $defaultSize;
                    display: flex;
                    flex-wrap: wrap;
                }

                .leftH2 {
                    width: 500 * $defaultSize;
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                font-weight: 600;
                color: #FFFFFF;
                text-align: center;

                img {
                    width: 13 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .btm:hover {
                background: #1F41A7;

                img {
                    animation: tracking-in-left 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                }
            }
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .box3 {
            margin: 48 * $defaultSize 0;

            .title {
                display: flex;

                .left {
                    width: 50%;
                    margin-top: 25 * $defaultSize;

                    .h3 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #224FD9;
                        margin-bottom: 15 * $defaultSize;
                    }

                    .h1 {
                        font-size: 25 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 25 * $defaultSize;
                    }

                    .h2 {
                        display: flex;
                        align-items: center;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: 15 * $defaultSize;

                        img {
                            width: 15 * $defaultSize;
                            margin-right: 10 * $defaultSize;
                        }
                    }

                    .h4 {
                        cursor: pointer;
                        margin-top: 50* $defaultSize;
                        color: #333333;
                        font-size: 15 * $defaultSize;
                        font-weight: 600;
                        display: flex;

                        .solidSpan {
                            margin-left: 5* $defaultSize;
                            display: flex;
                            align-items: center;
                            /* 将子元素垂直居中对齐 */
                        }
                    }

                    .h4:hover {
                        .solidSpan {
                            img {
                                animation: tracking-in-left 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                            }
                        }
                    }
                }

                .right {
                    margin-left: 80 * $defaultSize;
                    width: 45%;
                }
            }

            .bomDiv {
                display: inline-block;
                margin-top: 68 * $defaultSize;
                width: 100%;
                border-radius: 20 * $defaultSize;
                height: 310 * $defaultSize;
                background: url('../assets/copyRobot/Rectangle 160.svg');
                background-size: cover;
                text-align: center;

                .smallH1 {
                    text-align: center;
                    margin-top: 50 * $defaultSize;
                    margin-bottom: 50 * $defaultSize;
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .smallH3 {
                    width: 100%;
                    text-align: left;
                    margin-top: 20 * $defaultSize;
                    font-size: 12 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #FFFFFF;
                    padding: 0 60 * $defaultSize ;

                    .small {
                        display: inline-block;
                        margin-bottom: 20 * $defaultSize;
                        width: 50%;

                        // padding: 15 * $defaultSize 0 15 * $defaultSize 50 * $defaultSize;
                        span {
                            margin-left: 10 * $defaultSize;
                            display: inline-block;
                        }

                        img {
                            width: 12 * $defaultSize;
                        }
                    }
                }

                .btm {
                    cursor: pointer;
                    display: inline-block;
                    min-width: 110 * $defaultSize;
                    padding: 5 * $defaultSize;
                    height: 30 * $defaultSize;
                    background-color: #FFFFFF;
                    border-radius: 5 * $defaultSize;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    margin-top: 25 * $defaultSize;
                    font-weight: 600;
                    color: #2652D9;
                }

                .btm:hover {
                    background-color: #CFDCFF;
                }
            }

            .big {
                margin-top: 80 * $defaultSize;
                text-align: center;

                .big-title {
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Medium, Inter;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 5 * $defaultSize;
                    text-align: center;
                }

                .hint {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    text-align: center;
                }

                .small1 {
                    width: 100%;
                    display: flex;
                    // align-items: center; /* 将子元素垂直居中对齐 */
                    justify-content: flex-start;

                    .smallH1 {
                        text-align: left;
                        width: 33.3%;
                        height: 130 * $defaultSize;
                        border: 2px solid #E8EBEF;
                        border-radius: 15 * $defaultSize;
                        padding: 20 * $defaultSize;
                        margin-right: 15 * $defaultSize;
                        // margin-left: 15 * $defaultSize;
                        margin-top: 30 * $defaultSize;

                        .div1 {
                            font-size: 15 * $defaultSize;
                            font-family: Inter-Semi Bold, Semi Bold;
                            font-weight: 600;
                            color: #2751D0;
                        }

                        .div2 {
                            margin-top: 15 * $defaultSize;
                            font-size: 10 * $defaultSize;
                            font-family: Inter-Medium, Medium;
                            font-weight: 400;
                            color: #4F5559;
                        }
                    }

                    .smallH1:hover {
                        border: 2px solid #224FD9;
                    }
                }

                .small2 {
                    width: 100%;
                    display: flex;
                    // align-items: center; /* 将子元素垂直居中对齐 */
                    justify-content: flex-start;

                    .smallH1 {
                        text-align: left;
                        width: 33.3%;
                        height: 130 * $defaultSize;
                        border: 2px solid #E8EBEF;
                        border-radius: 15 * $defaultSize;
                        padding: 20 * $defaultSize;
                        margin-right: 15 * $defaultSize;
                        // margin-left: 15 * $defaultSize;
                        margin-top: 30 * $defaultSize;

                        .div1 {
                            font-size: 15 * $defaultSize;
                            font-family: Inter-Semi Bold, Semi Bold;
                            font-weight: 600;
                            color: #2751D0;
                        }

                        .div2 {
                            margin-top: 15 * $defaultSize;
                            font-size: 10 * $defaultSize;
                            font-family: Inter-Medium, Medium;
                            font-weight: 400;
                            color: #4F5559;
                        }
                    }

                    .smallH1:hover {
                        border: 2px solid #224FD9;
                    }
                }
            }
        }

    }
}</style>
  
  