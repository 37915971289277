import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAnimation: true,
    dialog: true,
  },
  getters: {
    GET_IsAnimation(state) {
      return state.isAnimation
    },
    GET_Dialog(state){
      return state.dialog;
    }
  },
  mutations: {
    SET_IsAnimation(state, data) {
      state.isAnimation = data
    },
    SET_Dialog(state, data){
      state.dialog = data
    },
  },
  actions: {
  },
  modules: {
  }
})
