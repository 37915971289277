<template>
    <div class="exchange">
        <div class="main">
            <h1>
                <img src="market2/binance.svg" alt=""><span style="margin-left: 10px;">{{ $t('exchange.h1') }}</span>
            </h1>
            <div class="link">
                <div class="web text-underline"><img src="../assets/icon_web_normal.png" alt="">https://www.binance.com/
                </div>
                <div class="tw text-underline"><img src="../assets/icon_twitter_normal.png" alt="">@Binance</div>
                <div class="tel text-underline"><img src="../assets/icon_telegram_normal.png" alt="">{{ $t('exchange.h2') }}
                </div>
            </div>

            <div class="title">{{ $t('exchange.h3') }}</div>
            <div class="hint">{{ $t('exchange.h4') }}</div>

            <div class="title">{{ $t('exchange.h5') }}</div>
            <div class="hint">{{ $t('exchange.h6') }}</div>

            <div class="title">{{ $t('exchange.h7') }}</div>
            <div class="hint">{{ $t('exchange.h8') }}</div>

            <div class="title">{{ $t('exchange.h9') }}</div>
            <div class="hint">{{ $t('exchange.h10') }}</div>

            <div class="title">{{ $t('exchange.h11') }}</div>
            <div class="hint">{{ $t('exchange.h12') }}</div>

            <div class="title">{{ $t('exchange.h13') }}</div>
            <div class="hint">{{ $t('exchange.h14') }}</div>

            <div class="title">{{ $t('exchange.h15') }}</div>
            <div class="hint">{{ $t('exchange.h16') }}</div>


            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "exchange",
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {},
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.exchange {

    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;
    .main {
        width: 750 * $defaultSize;
        margin: auto;
        margin-top: 200px;

        h1 {
            display: flex;
            align-items: center;
            font-size: 25 * $defaultSize;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;

            img {
                width: 31 * $defaultSize;
            }
        }

        .link {
            display: flex;
            margin-top: 10 * $defaultSize;

            div {
                display: flex;
                align-items: center;
                margin-right: 25 * $defaultSize;
                font-size: 11 * $defaultSize;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1F4ED8;

                img {
                    margin-right: 5 * $defaultSize;
                    width: 15 * $defaultSize;
                }
            }

            .text-underline {
                cursor: pointer;
                text-decoration: none;
            }

            .text-underline:hover {
                text-decoration: underline;
                /* 鼠标悬停时添加下划线 */
            }
        }

        .title {
            font-size: 20 * $defaultSize;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-top: 25 * $defaultSize;
        }

        .hint {
            font-size: 13 * $defaultSize;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F5559;
            margin-top: 15 * $defaultSize;
        }
    }
}
</style>
  