module.exports = { // 马来语
    topBar: {
        linkWallet: "Mulakan bot pertama anda secara percuma di Reactor. Ketahui lebih lanjut",
        unit: "USD",
        h1: "Rumah",
        h2: "Produk",
        h2_1: "Keupayaan ujian belakang k-line",
        h2_1_1: "Ujian belakang strategi",
        h2_1_2: "Lakukan ujian belakang dagangan simulasi",
        h2_1_3: "Perdagangan manual",
        h2_1_4: "Letakkan pesanan had pada rantai",
        h2_1_5: "K line dan data pada rantai",
        h2_1_6: "Transaksi pertanyaan masa nyata K-line dan data rantaian",
        h2_2: "Berbilang jenis robot",
        h2_2_1: "Salin Robot",
        h2_2_2: "Pedagang profesional dokumentari",
        h2_2_3: "Robot Perdagangan",
        h2_2_4: "Kerja 24 jam, kecualikan transaksi emosi",
        h2_2_5: "Robot Pembuat Pasaran",
        h2_2_6: "Kerja 24 jam, kecualikan transaksi emosi",
        h2_2_7: "Robot Arbitraj",
        h2_2_8: "Arbitraj pertukaran silang",
        h2_2_9: "Robot DEMO",
        h2_2_10: "Dapatkan pengalaman perdagangan tanpa risiko",
        h2_3: "Pusat beli-belah templat sedia untuk digunakan",
        h2_3_1: "Signal Mall",
        h2_3_2: "Buat keputusan perdagangan yang lebih tepat",
        h2_3_3: "Strategy Mall",
        h2_3_4: "Berdagang seperti profesional",
        h2_3_5: "Robot Mall",
        h2_3_6: "Pelbagai jenis robot automatik",
        h3: "pasaran",
        h3_1: "Token",
        h3_2: "Sokongan platform kecairan",
        h3_2_1: "Binance",
        h3_2_2: "Binance ialah pertukaran mata wang kripto terbesar di dunia mengikut volum dagangan, setakat Ogos 2022, Binance berdagang…",
        h3_2_3: "Huobi",
        h3_2_4: "Huobi Global ialah pertukaran aset digital antarabangsa dengan operasi di lebih 100 negara. Platform ini telah dilancarkan pada 2013…",
        h3_2_5: "Ou Yi",
        h3_2_6: "OKX ialah pertukaran berpusat mata wang kripto (CEX) dan platform dompet yang berpusat di Seychelles yang melayani berjuta-juta pelanggan di seluruh dunia…",
        h3_2_7: "PancakeSwap",
        h3_2_8: "PancakeSwap ialah pembangun pertukaran terdesentralisasi bukan kustodian (DEX) peneraju industri yang memfokuskan pada kripto termaju…",
        h3_2_9: "UniSwap",
        h3_2_10: "Uniswap ialah pertukaran terdesentralisasi (DEX), yang pertama kali memperkenalkan model Automated Market Maker (AMM). Dengan…",
        h3_2_11: "DODO",
        h3_2_12: "Dodo ialah pertukaran terdesentralisasi berasaskan Ethereum dengan Pembuat Pasaran Aktif, atau Pembuat Pasaran Automatik (AMM…",
        h3_3: "Lihat semua aset yang disokong",
        h3_3: "Lebih banyak platform perdagangan",
        h4: "Harga",
        h5: "Perusahaan",
        h5_1: "Tentang kami",
        h5_2: "Perkhidmatan cemerlang, inovasi berterusan. Bekerjasama rapat dengan pelanggan untuk menyediakan penyelesaian berkualiti tinggi. Wujudkan hubungan kerjasama jangka panjang dan berkembang bersama.",
        h5_3: "OEM",
        h5_4: "Penyelesaian tersuai profesional untuk memenuhi keperluan unik anda dan memberi anda perkhidmatan yang diperibadikan.",
        h5_5: "Operasi Perniagaan",
        h5_6: "Urus dan kembangkan perniagaan anda dengan cekap untuk membantu anda mencapai pertumbuhan mampan dan kepimpinan pasaran.",
        h5_7: "Media Sosial",
        h5_8: "Terokai saluran media sosial kami, jejaki perkembangan terkini, dan bersama-sama meneroka sempadan industri dan arah aliran inovatif.",
        h5_9: "Rakan Kongsi",
        h5_10: "Buat bersama-sama, kongsi sumber dan peluang, capai manfaat bersama dan hasil menang-menang, dan bekerjasama di jalan menuju kejayaan.",
        h5_11: "Hubungi kami",
        h5_12: "Hubungi kami, mari kita bincangkan peluang perniagaan dan bekerjasama dalam perkongsian yang bermanfaat.",
        h6: "Bantuan",
        h7_1: "Cina Ringkas",
        h7_2: "Bahasa Inggeris",
        h7_3: "Bahasa Jepun",
        h7_4: "Bahasa Korea",
        h7_5: "Cina Tradisional",
        h7_6: "Thailand",
        h7_7: "Melayu",
        h7_8: "Vietnam",
        h8: "Dompet Pautan",
        h9: "Lagi",
        h9_1: "Rebat",
        h9_2: "Jemput rakan anda untuk menyertai platform kami melalui pautan, dan anda akan berpeluang mendapat ganjaran komisen tambahan.",
        h9_3: "Dompet",
        h9_4: "Selamat dan boleh dipercayai, mudah untuk penyimpanan, mudah untuk mengurus aset digital anda, memastikan keselamatan kewangan dan perlindungan privasi.",
        h9_5: "strategi tersuai",
        h9_6: "Pemahaman mendalam tentang matlamat pelaburan anda, dan strategi pelaburan yang disesuaikan yang memenuhi keperluan anda.",
        h9_7: "DCA",
        h9_8: "Pelaburan tetap pintar, nilai tambah yang stabil, mengelakkan risiko turun naik pasaran, dan pendapatan pegangan jangka panjang yang stabil.",
    },
    btm: {
        h1: "Produk",
        h2: "Dompet",
        h3: 'Pasaran',
        h4: "Isyarat",
        h5: "Data pada rantai",
        h6: "strategi tersuai",
        h7: "Rebat",
        h8: "Robot",
        h9: "Robot Perdagangan",
        h10: "Robot DEMO",
        h11: "Salin Robot",
        h12: "Robot Arbitraj",
        h13: "Robot Pembuat Pasaran",
        h14: "Tentang kami",
        h15: "OEM",
        h16: "Kerjasama Pengiklanan",
        h17: "Operasi Perniagaan",
        h18: "Media Sosial",
        h19: "Rakan Kongsi",
        h20: "Komuniti",
        h21: "Perkhidmatan",
        h22: "Muat turun",
        h23: "Perjanjian Perkhidmatan",
        h24: 'Perjanjian Privasi',
        h25: "Amaran Risiko",
        h26: "Pusat Bantuan",
        h27: "Ikuti kami",
        h28: "Muat turun setempat",
        h29: "Penilaian kredit perusahaan kredit gred AAA perusahaan",
        h30: "Sijil kehormat negara dalam bidang bidaan dan bidaan",
        h31: "Perusahaan Arkib Integriti Kementerian Perdagangan Republik Rakyat China",
        h32: "Perusahaan demonstrasi pengurusan integriti",
        h33: "No. Keselamatan Rangkaian Awam Guangdong 44030502009469 No. ICP Guangdong 2022043961-1 Lesen Perniagaan Telekomunikasi Tambah Nilai B1-20214279 Teks Bersih Guangdong: (2018) No. 5045-1758 - ANY 2045-1758, No.",
        h34: "klien desktop",


        A1: 'Ujian Semula Strategi',
        A2: 'Perdagangan Manual',
        A3: 'Data K-Line dan Berantai',
        A4: 'Pusat Isyarat',
        A5: 'Pusat Strategi',
        A6: 'Robot Perdagangan',
        A7: 'Robot DEMO',
        A8: 'Robot Salin',
        A9: 'Robot Arbitraj',
        A10: 'Robot Pembuat Pasar',
        A11: 'Tentang Kami',
        A12: 'OEM',
        A13: 'Media Sosial',
        A14: 'Hubungi Kami',
        A15: 'Dasar Privasi',
        A16: 'Perjanjian Layanan',
        A17: 'Penafian',
        A18: 'Pusat Bantuan',
        A19: 'Strategi Khusus',
        A20: 'Komisen Kembali',
        A21: 'Dompet',
        A22: 'DCA',
        A23: 'Muat Turun',
        A24: "lebih banyak",


    },
    home: {
        h1: "Perdagangan automatik, beli rendah dan jual tinggi",
        h2: "Terminal dagangan pintar yang mengumpulkan banyak fungsi",
        h3: 'Robot dagangan mata wang digital boleh disesuaikan',
        h4: "Percubaan Percuma",
        h5: "Sokong pertukaran berpusat & pertukaran terpencar",
        h6: "Pelaburan terpelbagai",
        h7: "Kuantiti profesional",
        h8: "Parameter penunjuk",
        h9: "Data Transaksi",
        h10: "Rebat jemputan",
        h11: "Sambungkan dompet anda dan tukar akaun dan uruskannya dengan terminal dagangan kami",
        h12: "Kami tahu kepentingan pelaburan terpelbagai untuk portfolio pelaburan. Dengan Reaktor, anda boleh menguruskan berbilang bursa dan dompet pada masa yang sama dengan mudah, mempelbagaikan dana dengan lebih fleksibel, mengagihkan pelaburan dalam aset dan pasaran yang berbeza, dan mengurangkan risiko satu aset atau pasaran dengan berkesan.",
        h11_1: "Analisis tepat, kesan dagangan yang sangat baik",
        h12_1: "Reaktor boleh menjalankan analisis pasaran yang tepat dan pengoptimuman strategi dagangan untuk mencapai hasil dagangan yang cemerlang. Gunakan algoritma dan penunjuk data termaju untuk meningkatkan kecekapan dagangan, supaya dapat mengawal pasaran dengan lebih baik dan memperoleh pulangan yang lebih stabil.",
        h11_2: "Penyesuaian fleksibel, optimumkan penunjuk transaksi",
        h12_2: "Memberi anda tetapan parameter penunjuk yang fleksibel, dan menyesuaikan strategi dagangan mengikut keperluan individu. Dengan melaraskan parameter penunjuk untuk mengoptimumkan prestasi dagangan, menyesuaikan diri dengan perubahan pasaran dengan lebih baik, merealisasikan pengalaman dagangan yang diperibadikan dan memperoleh pulangan pelaburan yang lebih baik.",
        h11_3: "Pemantauan masa nyata, pemahaman tepat tentang dinamik pasaran",
        h12_3: "Melalui data masa nyata, anda boleh membuat keputusan perdagangan yang lebih tepat dan menangkap peluang pelaburan tepat pada masanya. Tidak lagi terlepas sebarang detik penting, dan melalui pemantauan data, anda boleh memahami dinamik pasaran dengan tepat.",
        h11_4: "Kongsi untuk menjana wang, jemput ganjaran",
        h12_4: "Dengan menjemput rakan untuk menyertai platform, anda boleh mendapat sehingga 15% daripada ganjaran jemputan. Melalui fungsi anti-komisen jemputan, anda boleh berkongsi keseronokan perdagangan dengan rakan anda dan pada masa yang sama, anda juga boleh mendapat pendapatan anti-komisen yang besar.",
        h13: "Ketahui lebih lanjut",
        h14: "Rangkaian sokongan",
        h15: "Disokong",
        h16: "Platform Kecairan",
        h17: "Cryptocurrency",
        h18: "Diproses",
        h19: "Pesanan dagangan",
        h20: "Sesuai untuk berdagang seperti profesional",
        h21: "Gunakan platform kecairan agregat pada Reaktor untuk berdagang mata wang kripto, menjalankan pesanan pasaran dan mengehadkan transaksi pesanan pada rantaian, melaksanakan strategi dagangan automatik dan melihat data transaksi.",
        h22: "Pasukan dan teknologi profesional",
        h23: "Kami komited untuk menyediakan perkhidmatan yang telus dan boleh dipercayai, sentiasa mengutamakan kepentingan pelanggan kami. Strategi pelaburan kami adalah berdasarkan penyelidikan yang mencukupi dan usaha wajar untuk memastikan kami menyediakan anda peluang pelaburan yang terbaik.",
        h24: 'Dana dan algoritma anda selamat',
        h25: "Dompet",
        h26: "Kami tidak akan merekodkan maklumat kunci peribadi anda, dan robot akan mematuhi piawaian keselamatan pertukaran dan dompet",
        h27: "Pengesahan",
        h28: "Menggunakan pelbagai langkah pengesahan dan penyulitan untuk memastikan keselamatan aset dan maklumat transaksi anda",
        h29: "Transaksi selamat",
        h30: "Anda boleh menggunakan robot kami untuk berdagang dengan yakin dan menikmati pengalaman pelaburan yang mudah dan selamat.",
        h31: "Terminal dagangan mata wang kripto untuk mengurus semua portfolio aset yang disulitkan dengan mudah",
        h32: "Terminal dagangan kami menghimpunkan fungsi berbilang bursa dan dompet, membolehkan anda melaksanakan pengurusan aset dan operasi perdagangan pada antara muka yang bersatu.",
        h33: "Menjelaskan Kemungkinan Tak Terakhir Blockchain bersama-sama",
        h34: "Kami menyediakan penyelesaian blockchain yang meliputi dan perkhidmatan konsultasi profesional. Pasukan kami mempunyai pengalaman yang kaya dan pengetahuan profesional dalam blockchain, membantu anda memahami konsep utama blockchain, teknologi utama dan alat-alat, dan mengeksplorasi bagaimana untuk melaksanakan blockchain kepada skenario praktik",
    },
    about: {
        h1: "TIANYI ialah penyedia pembangunan penyelesaian kejuruteraan digital daripada pelayan/rangkaian ke lapisan aplikasi",
        h2: "Kami adalah komuniti kreatif digital dari seluruh dunia.",
        h3: 'Pasukan kami terdiri daripada sekumpulan profesional yang berpengalaman dan bersemangat. Penyelesaian profesional dan perkhidmatan kreatif kami dalam banyak bidang, termasuk pembangunan aplikasi, Web3, reka bentuk dan kreativiti visual, reka bentuk dan perancangan aplikasi, pelayan/rangkaian dan bilik komputer, pemasaran dan sistem pejabat. ',
        h4: "Perkhidmatan sehenti",
        h5: "Kami menyediakan rangkaian penuh perkhidmatan sehenti, meliputi semua bidang daripada perancangan, reka bentuk, pembangunan hinggalah operasi.",
        h6: "Standard Global",
        h7: "Reka bentuk standard global, menggunakan alat tanpa kod, dan secara aktif memperkenalkan teknologi Web3tran seperti NFT dan Metaverse.",
        h8: "Rangkaian pelayan dan IDC",
        h9: "Kami menyediakan penyelesaian pelayan berprestasi tinggi dan seni bina rangkaian IDC berskala untuk memastikan perniagaan anda berjalan lancar, selamat dan boleh dipercayai.",
        h10: "Misi, Visi dan Nilai",
        h11: "Misi",
        h12: "Gunakan kuasa kreativiti digital untuk menyelesaikan masalah dan membuat sumbangan berpanjangan kepada masyarakat. Misi kami adalah untuk menyediakan masyarakat dengan penyelesaian yang bermakna dan memacu kemajuan sosial melalui inovasi dan kreativiti.",
        h13: "Hubungi kami, atau buat temu janji untuk melawat pejabat kami",
        h14: "Bilik 608, Bangunan Baotong, Jalan Baomin 1, Jalan Xin'an, Daerah Baoan, Shenzhen",
        h15: "Rujuk sekarang",
    },
    chainData: {
        h1: "garis K dan data pada rantai",
        h2: "Gabungan sempurna cerapan ke arah aliran pasaran",
        h3: 'Penyatuan pasaran sejarah dan maklumat rantaian masa nyata',
        h4: "Optimumkan maklumat transaksi anda",
        h5: "Lihat butiran",
        h6: "Kelebihan cemerlang menyepadukan K-line dan data on-chain",
        h7: "Wawasan masa nyata ke dalam pasaran dalam Reaktor, transaksi membuat keputusan yang tepat",
        h8: "Paparkan trend harga sejarah dan jejaki data transaksi dalam rangkaian blockchain",
        h9: "Semak ketinggian blok rekod transaksi rantaian blok terkini dan masa pengesahan transaksi",
        h10: "Jana isyarat dagangan yang tepat berdasarkan analisis data K-line dan on-chain",
        h11: "Carta dan grafik intuitif untuk memaparkan data K-line dan rantaian",
        h12: "Semak status kedudukan, nisbah peruntukan aset dan perubahan nilai pasaran setiap aset",
        h13: "Terdapat juga khusus Reaktor",
        h14: "Tingkatkan wawasan dan kecekapan membuat keputusan transaksi",
        h15: "berbilang rantai",
        h16: "Boleh disesuaikan",
        h17: "Isyarat",
        h18: "Reaktor menyokong urus niaga pada pelbagai rangkaian blockchain. Seperti Ethereum, Wave Field, Binance Smart Chain, dll.",
        h19: "Anda boleh menyesuaikan strategi dagangan, peraturan kawalan risiko dan tetapan ujian untuk mencapai pengalaman dagangan yang diperibadikan",
        h20: "Mata paparan, cadangan perdagangan dan petua operasi pada carta garis K, dan anda harus memahami hubungan antara isyarat dan pasaran",
        h21: "Pemahaman mendalam tentang data masa nyata blockchain",
        h22: "Melalui Reaktor, dapatkan pemahaman yang mendalam tentang kandungan utama dan kepentingan data pada rantai",
        h23: 'Data pasaran masa nyata',
        h24: "Penjejakan transaksi rantaian sekat",
        h25: "Analisis kontrak pintar",
        h26: "Siasatan alamat dan baki",
        h27: "Penjejakan ke belakang data sejarah",
        h28: "Analisis penunjuk tersuai",
        h29: "Termasuk penunjuk utama seperti harga mata wang kripto, volum dagangan dan permodalan pasaran untuk membantu anda memahami sepenuhnya dinamik pasaran.",
        h30: "Jejak dan paparkan sejarah transaksi, termasuk masa transaksi, pasangan transaksi, volum transaksi dan arah transaksi.",
        h31: "Membantu anda memahami dengan mendalam dan menilai keselamatan dan kebolehpercayaan kontrak pintar projek yang berbeza.",
        h32: "Anda boleh memasukkan alamat untuk menanyakan baki dan rekod transaksi mana-mana mata wang kripto.",
        h33: "Paparkan arah aliran harga mata wang kripto sejarah, perubahan volum transaksi dan data lain",
        h34: "Anda boleh mencipta penunjuk diperibadikan mengikut keperluan dan strategi anda sendiri, dan menggunakannya pada K-line atau data dalam rantaian.",
        h35: "Dilahirkan untuk perdagangan",
        h36: "Carta garis K masa nyata, penunjuk tepat dan analisis pintar",
        h37: "Maklumat Mata Wang",
        h38: "Perdagangan carta",
        h39: "Analisis Pintar",
        h40: "Maklumat asas seperti nama mata wang, jumlah jumlah terbitan dan penunjuk penting seperti harga, nilai pasaran dan volum dagangan 24 jam",
        h41: "Anda boleh terus berdagang di sebelah kanan carta K-line, dari mana anda boleh mendapatkan pandangan tentang peluang pasaran dan membuat keputusan perdagangan yang sepadan",
        h42: "Isyarat boleh mengenal pasti corak aliran harga, struktur morfologi, serta tahap rintangan dan sokongan dalam pasaran.",
    },
    community: {
        h1: "Sertai komuniti Reaktor",
        h2: "Dapatkan maklumat acara Reaktor terkini dan sokongan dalam talian",
        h3: 'Telegram',
        h4: "Komuniti kuantitatif",
        h5: "Twitter",
        h6: "Komuniti Cina",
        h7: "Instagram",
        h8: "Facebook",
        h9: "Telegram",
        h10: "Dapatkan sokongan dan jawapan daripada kakitangan profesional",
        h11: "Komuniti kami mempunyai kakitangan profesional berpengalaman yang mempunyai pemahaman mendalam tentang pasaran penyulitan dan perdagangan kuantitatif. Sebagai ahli komuniti, anda boleh berinteraksi secara langsung dengan pasukan pakar kami untuk bertanya soalan, mendapatkan nasihat dan jawapan. Sama ada mengenai analisis pasaran, strategi dagangan atau sokongan teknikal, kakitangan kami akan membantu anda dengan sepenuh hati dan memastikan anda sentiasa berada di arah perdagangan kuantitatif.",
        h12: "Tangkap arah aliran pasaran penyulitan dan terokai strategi kuantitatif baharu",
        h13: "Menyertai komuniti kami, anda akan berpeluang untuk menangkap arah aliran dan dinamik terkini pasaran penyulitan. Kami menyediakan alatan dan teknologi dagangan kuantitatif termaju untuk membantu anda menganalisis data pasaran, merangka strategi dan melaksanakan transaksi dalam masa nyata. Dengan berkomunikasi dan berkongsi dengan ahli lain, anda boleh meneroka strategi kuantitatif baharu, mengembangkan ufuk pelaburan anda yang sentiasa berdaya saing.",
        h14: "Kongsi secara bebas dan temui rakan baru yang disulitkan",
        h15: "Komuniti kami ialah platform untuk perkongsian percuma dan bantuan bersama. Sebagai ahli, anda boleh berkongsi pengalaman dagangan, pandangan dan strategi anda, serta berinteraksi serta berbincang dengan ahli lain. Dengan berkomunikasi dengan peminat kripto dan peniaga profesional lain yang berfikiran sama, anda boleh mengembangkan rangkaian anda dan bertemu rakan kripto baharu. Dalam komuniti yang aktif dan koperasi ini, anda akan berpeluang mencipta lebih banyak peluang untuk berdagang bersama-sama, dan pengalaman yang lain.",
    },
    cooperation: {
        h1: "Rakan kongsi korporat global",
        h2: "Tianyi mempunyai banyak syarikat rakan kongsi di China, Asia, Timur Tengah, Asia Tenggara, dll. di seluruh dunia. Kami menyediakan penyelesaian teknologi baharu dengan syarikat rakan kongsi di seluruh dunia.",
        h3: 'Shenzhen',
        h4: "ibu pejabat",
        h5: "Singapura",
        h6: "Asia Tenggara",
        h7: "Chengdu",
        h8: "Cawangan",
        h9: "Wuhan",
        h10: "Jepun",
        h11: "Asia",
        h12: "Vietnam",
        h13: "Hunan",
        h14: "Henan",
        h15: "Korea Selatan",
        h16: "Kemboja",
        h17: "Hainan",
        h18: "Dubai",
        h19: "Timur Tengah",
        h20: "Thailand"
    },
    copyRobot: {
        h1: "Ikuti prestasi luar biasa pedagang terkemuka",
        h2: "Akses strategi dagangan unik dan prestasi unggul pedagang teratas dengan Reaktor",
        h3: 'Lihat lagi',
        h4: "Strategi dagangan yang unik",
        h5: "Peluang untuk bekerja secara langsung dengan pedagang terkemuka",
        h6: "Tingkatkan kadar kejayaan dan prestasi transaksi anda sendiri",
        h7: "Peruntukan modal yang sesuai untuk pengurusan risiko yang lebih baik",
        h8: "Pilihan strategi perdagangan yang lebih luas dan pelbagai",
        h9: "Ketahui lebih lanjut",
        h10: "Untuk tujuan ini, kami menyediakan",
        h11: "Mengikut gelagat jual beli peniaga sepenuhnya secara automatik",
        h12: "Parameter pelarasan diperibadikan, seperti ambil untung, laporan berhenti, dsb.",
        h13: "Lihat aset dan rekod transaksi yang dibeli dan dijual",
        h14: "Menjalankan peruntukan modal dan pengurusan risiko mengikut toleransi risiko sendiri",
        h15: "Lihat lebih banyak fungsi",
        h16: "Memberi anda data dan maklumat terperinci",
        h17: "Mudah untuk mencari dan mengikuti pedagang yang cemerlang, dan dapatkan pulangan pelaburan yang stabil",
        h18: "Maklumat penjual robot telus",
        h19: "Ringkasan mingguan keputusan dagangan",
        h20: "keuntungan purata",
        h21: "Pengguna boleh menilai tahap aktiviti penjual mengikut masa penjual di Budangjie dan masa penyenaraian terakhir, dan menjalankan kerjasama selanjutnya dengan penjual melalui kaedah perlombongan bersama membekal dan membekal.",
        h22: "Robot salinan boleh memberikan ringkasan mingguan hasil dagangan, termasuk jumlah keuntungan, masa belian, masa jualan, dsb., untuk membantu anda memahami dan menilai prestasi dagangan tepat pada masanya.",
        h23: "Robot penyalin akan mengira dan memaparkan purata keuntungan anda, dan memaparkannya dalam bentuk carta, supaya anda boleh memahami keuntungan purata dan keuntungan yang dijangkakan bagi setiap transaksi.",
        h24: "Lihat laporan prestasi bulan lepas",
        h25: "Kadar menang, kadar kalah, nisbah untung rugi",
        h26: "Mata wang transaksi dan masa transaksi",
        h27: "Termasuk carta arah aliran aset, keuntungan terbaik bagi setiap transaksi, pengeluaran terbesar dan data lain untuk membantu anda memahami situasi pelaburan pada bulan lalu.",
        h28: "Robot penyalin akan menunjukkan kepada anda penunjuk penting seperti kadar kemenangan pedagang, kadar kerugian dan nisbah untung rugi, membantu anda menilai kestabilan dan keuntungan strategi dagangan anda",
        h29: "Robot salinan akan menyediakan senarai mata wang dagangan yang anda salin dan statistik jumlah bilangan transaksi, supaya anda boleh memahami jenis dagangan dan kekerapan dagangan yang anda sertai.",
    },
    dca: {
        h1: "Perjalanan kuantitatif bermula daripada strategi pelaburan tetap (DCA)",
        h2: "Laburkan dana secara kerap untuk membeli mata wang kripto tanpa mengira turun naik harga pasaran",
        h3: 'Lihat lagi',
        h4: "Apakah DCA?",
        h5: "DCA ialah strategi pelaburan jangka panjang yang membeli mata wang kripto dengan melabur jumlah wang yang sama secara tetap, tanpa mengira turun naik harga pasaran. Strategi ini boleh membantu pelabur menyuraikan risiko, mengurangkan kesan turun naik pasaran, dan mendapat manfaat daripada peningkatan aset dalam jangka panjang. Anda boleh menyediakan pelan pelaburan tetap dengan mudah, memilih mata wang kripto yang anda minati dan pelan masa yang ditetapkan mengikut kitaran pelaburan secara automatik mengikut kitaran yang akan anda laksanakan dalam jangka masa yang ditetapkan. membeli mata wang kripto secara bijak mengikut harga pasaran. Pelarasan fleksibel pelan pelaburan tetap, dioptimumkan mengikut perubahan pasaran dan keperluan peribadi.",
        h6: "Apakah kelebihan menggunakan strategi DCA dalam Reaktor?",
        h7: "Pelaburan stabil",
        h8: "DCA boleh mengekalkan rentak pelaburan yang stabil tidak kira bagaimana harga pasaran berubah-ubah dengan melabur jumlah tetap secara tetap. Kestabilan ini boleh membantu pelabur melancarkan turun naik pasaran dan mengurangkan risiko pelaburan.",
        h9: "Kos purata",
        h10: "Strategi DCA boleh mencapai purata kos belian melalui pelaburan tetap. Apabila harga pasaran jatuh, anda akan membeli lebih banyak unit aset, dan apabila harga pasaran meningkat, anda akan membeli lebih sedikit unit aset. Kaedah ini boleh membantu anda membeli aset secara purata dalam turun naik pasaran dan mengurangkan risiko harga belian.",
    },
    demoRobot: {
        h1: "Perdagangan simulasi masa nyata, pengalaman berisiko sifar",
        h2: "Robot DEMO membantu anda meneroka dunia perdagangan",
        h3: 'Lihat lagi',
        h4: "Mengapa menggunakan robot DEMO",
        h5: "Robot DEMO menyediakan persekitaran bebas risiko untuk pengguna mempelajari, berlatih dan menguji strategi dagangan kuantitatif. Mereka boleh membantu pengguna meningkatkan kemahiran dagangan, menilai prestasi robot, mengoptimumkan strategi dagangan dan meningkatkan pemahaman tentang pasaran. Dengan menggunakan robot DEMO, pengguna boleh membina keyakinan dan pengalaman sebelum dagangan sebenar, supaya mereka dapat menangani cabaran dalam pasaran sebenar dengan lebih baik.",
        h6: "Perdagangan simulasi risiko sifar",
        h7: "Robot DEMO membolehkan anda menjalankan transaksi simulasi dengan dana maya tanpa menggunakan dana sebenar. Anda boleh melakukan operasi dagangan di bawah keadaan pasaran sebenar, mengalami turun naik harga masa nyata dan memahami cara transaksi sebenarnya berfungsi tanpa mengambil sebarang risiko sebenar.",
        h8: "Parameter dan sejarah dagangan simulasi",
        h9: "Robot DEMO akan merekodkan sejarah dagangan simulasi anda, termasuk membeli, menjual, menghentikan kerugian, mengambil untung dan operasi lain. Anda boleh melihat rekod sejarah, menganalisis keputusan dan prestasi dagangan, menilai strategi dan kemahiran dagangan anda, serta membuat pelarasan dan penambahbaikan yang diperlukan.",
        h10: "Pengoptimuman dan pelarasan strategi",
        h11: "Melalui robot DEMO, pengguna boleh mengoptimumkan dan melaraskan strategi mereka. Robot DEMO akan menjana laporan dan analisis masa nyata, termasuk sejarah transaksi, keluk hasil, statistik transaksi dan data lain. Anda boleh menggunakan laporan dan analisis untuk memahami prestasi transaksi, pendapatan dan penunjuk utama anda yang lain.",
    },
    download: {
        h1: "Mulakan dagangan dengan Reaktor secara percuma!",
        h2: "Padan dengan sempurna Windows dan Mac, menyokong fungsi perniagaan penuh",
        h3: 'Versi PC Reaktor',
        h4: "Muat turun pelanggan Windows dan Mac OS untuk menikmati perkhidmatan perdagangan profesional.",
        h5: "Muat turun setempat",
        h6: "Mengapa memilih Reaktor?",
        h7: "Lindungi aset anda",
        h8: "Reaktor mengguna pakai tahap tertinggi protokol keselamatan dan teknologi penyulitan untuk memastikan aset digital anda sentiasa dilindungi paling kukuh.",
        h9: "Wawasan ke arah aliran pasaran",
        h10: "Reaktor menyediakan statistik data dan alat analisis yang komprehensif untuk membantu anda memperoleh pemahaman yang mendalam tentang arah aliran pasaran dan turun naik harga. Dan laraskan strategi dagangan anda mengikut keadaan pasaran.",
        h11: "Pengalaman dagangan yang pantas dan cekap",
        h12: "Reaktor menyediakan pelaksanaan transaksi berkelajuan tinggi dan pemprosesan pesanan untuk memastikan anda boleh bertindak balas dengan cepat kepada perubahan pasaran dan merebut peluang dagangan.",
    },
    exchange: {
        h1: "Binance",
        h2: "Sembang",
        h3: 'Apakah itu Binance? ',
        h4: "Binance ialah pertukaran mata wang kripto terbesar di dunia mengikut volum dagangan, dengan volum dagangan harian sebanyak $76 bilion pada Ogos 2022 dan 90 juta pelanggan di seluruh dunia. Platform ini telah menjadi ahli yang dipercayai dalam ruang kripto di mana pengguna boleh membeli, menjual dan menyimpan aset digital mereka, serta mengakses lebih 350 mata wang kripto tersenarai dan beribu-ribu pasangan dagangan. Kini, Binance, Wallet Exchange, ekosistem Pertukaran Trust, Binance, Akademi Exchange. , Amal, NFT, dll.",
        h5: "Bagaimana Reactor menguruskan akaun anda dalam Binance?",
        h6: "Melalui API platform Reaktor, sambungkan bursa dengan lancar untuk merealisasikan transaksi automatik. Dengan fungsi API kami yang berkuasa, anda boleh dengan mudah mengaitkan dengan berbilang bursa, melaksanakan strategi dagangan secara automatik dan mencapai operasi dagangan yang cekap. Gunakan sepenuhnya kemudahan API untuk mengurus akaun dagangan anda dengan mudah dan mencapai hasil pelaburan yang lebih baik.",
        h7: "Siapakah pengasas Binance?",
        h8: "Syarikat global ini diasaskan bersama di China oleh Changpeng Zhao dan Yi He. Changpeng Zhao (CZ), pemaju dan eksekutif perniagaan China-Kanada, ialah Ketua Pegawai Eksekutif syarikat itu. Beliau belajar di McGill University di Montreal dan mempunyai rekod prestasi sebagai usahawan yang berjaya. Pengalaman masa lalunya termasuk menjadi ketua pasukan R&D niaga hadapan Bloomberg Tradebook di Block Y Block, Ketua Bahagian Pemasaran niaga hadapan Bloomberg Tradebook, Ketua Bahagian YB Block dan Ketua Hein Marketing Block, Hein Bincha. Pegawai dan juga berkhidmat sebagai cabang modal teroka syarikat Ketua Binance Labs. Bekas Naib Presiden Yixia Technology, sebuah syarikat teknologi video mudah alih terkemuka dan pengasas bersama OKCoin, pertukaran aset digital.",
        h9: "Bilakah Binance melancarkannya?",
        h10: "Binance telah dilancarkan pada Jun 2017 dan berkembang menjadi pertukaran mata wang kripto terbesar di dunia dalam masa 180 hari.",
        h11: "Negara terhad Binance?",
        h12: "Menurut Syarat Penggunaan Binance, wilayah terhad termasuk Amerika Syarikat, Singapura dan Ontario (Kanada). Walau bagaimanapun, negara/wilayah tertentu mengehadkan penggunaan atau kefungsian mungkin dihadkan atas sebab peraturan, termasuk tetapi tidak terhad kepada China, Malaysia, Jepun, United Kingdom dan Thailand. Jerman, Itali dan Belanda juga tidak menawarkan niaga hadapan dan platform derivatif AS20 yang berdedikasi pada bulan September.",
        h13: "Berapakah bayaran Binance?",
        h14: "The platform is user-friendly, one of the cheapest, offers a large selection of trade types, and provides an advanced set of trading tools for seasoned investors. It charges fees based on a tiered system, from regular users to VIP 9. For regular users, spot trades charge a 0.10% maker taker fee. In July 2022, Binance announced BTC spot trading for zero-fee trading, and in August for ETH/BUSD trading for zero-fee trading.",
        h15: "Adakah mungkin untuk menggunakan leveraj atau dagangan margin pada Binance?",
        h16: "Pedagang boleh meminjam dana dan mengambil bahagian dalam dagangan margin pada Margin Binance, yang membolehkan perdagangan mata wang kripto dengan leverage sehingga 10x. Pengguna juga boleh berdagang menggunakan derivatif seperti Kontrak Binance yang diselesaikan dalam USDT, BUSD atau mata wang kripto lain, dan Pilihan Binance.",

        A0: 'OKX',
        A1: 'Apakah itu OKX?',
        A2: 'OKX adalah pusat pertukaran kripto terdesentralisasi (CEX) dan platform dompet yang berbasis di Seychelles, melayani jutaan pelanggan di seluruh dunia. Pertukaran ini menyediakan perdagangan dasar termasuk spot dan opsi sederhana, serta produk turunan yang mencakup margin, berjangka, kontrak tanpa tanggal jatuh tempo, dan opsi. Produk lainnya termasuk: robot perdagangan, perdagangan besar, OKX Earn (tabungan, stablecoin, DeFi, penjualan jaminan, ETH 2.0, dll.), pinjaman kripto, serta platform peluncuran pertukaran Jumpstart.',
        A3: 'Dompet OKX disebut sebagai “Gerbang Web3” yang menyediakan dompet kripto populer, pertukaran terdesentralisasi (DEX), pasar NFT, dan aplikasi terdesentralisasi (DApp). Dompet ini mendukung lebih dari 30 jaringan termasuk blockchain utama seperti Bitcoin, Ethereum, BNB Chain, Solana, Polygon, Avalanche, Fantom, dan lainnya.',
        A4: 'OKX juga memiliki blockchain native sendiri yaitu OKX Chain dan token native-nya OKB, yang memungkinkan pembuat dan proyek untuk mendeploy DApp, serta memungkinkan pengembang menggunakan infrastruktur OKX Oracle dan lainnya.',
        A5: 'Pada bulan Januari 2022, OKEx mengubah namanya menjadi OKX dengan merefresh merek dan roadmap baru.',
        A6: 'Siapa pendiri OKX?',
        A6: 'OKX (dahulu dikenal sebagai OKEx) adalah anak perusahaan OKCoin yang merupakan bagian dari OK Group.',
        A7: 'Perusahaan ini didirikan oleh Mingxing “Star” Xu pada tahun 2013 di China. Xu adalah seorang pengusaha asal China dengan gelar sarjana terapan fisika dari Universitas Sains dan Teknologi Beijing. Star Xu menjabat sebagai CEO OK Group.',
        A8: 'CEO OKX saat ini adalah Jay Hao.',
        A9: 'Kapan OKX diluncurkan?',
        A10: 'Pertukaran ini (sebelumnya dikenal sebagai OKEX) diluncurkan pada tahun 2017.',
        A11: 'Di mana OKX berlokasi?',
        A12: 'Kantor pusat perusahaan berada di Seychelles.',
        A13: 'Negara-negara yang membatasi penggunaan OKX',
        A14: 'Platform ini dapat digunakan di lebih dari 200 negara. Namun, penduduk Amerika Serikat tidak dapat menggunakan layanan platform ini.',
        A15: 'Mata uang apa yang didukung oleh OKX?',
        A16: 'Ada lebih dari 350 jenis mata uang kripto yang terdaftar di pertukaran ini, dengan lebih dari 500 pasangan perdagangan yang didukung. Beberapa token yang terdaftar di platform ini termasuk BTC, ETH, OKB, AAVE, SOL, MATIC, XRP, DOGE, SHIB, dan DOT.',
        A17: 'Bagaimana biaya transaksi di OKX?',
        A18: 'OKX menggunakan model biaya pembuat dan pengambil. Biaya perdagangan di platform dimulai dari 0.10% dan mengurangi dengan peningkatan volume perdagangan. Bagi pengguna reguler, biaya transaksi tergantung pada jumlah OKB (token asli OKX) dalam ekosistem OKX, sedangkan bagi pengguna tingkat lanjut, biaya transaksi dihitung berdasarkan volume perdagangan mereka dalam 30 hari.',
        A19: 'Apakah bisa melakukan perdagangan menggunakan leverage atau margin di OKX?',
        A20: 'Perdagangan dengan margin di OKX memiliki leverage maksimum 10x. Untuk produk turunan, OKX menawarkan perdagangan berjangka dengan leverage maksimum 125x, kontrak perputaran tanpa tanggal jatuh tempo dengan leverage maksimum 125x. Selain itu, para trader juga dapat melakukan perdagangan dengan leverage melalui opsi kripto termasuk BTC, ETH, dan lainnya.',

        B0: 'Koin api',
        B1: 'Apa itu Huobi Global?',
        B2: 'Huobi Global adalah sebuah pertukaran aset digital internasional yang dapat digunakan di lebih dari 100 negara di seluruh dunia. Platform ini didirikan di China pada tahun 2013 dan menyediakan layanan perdagangan dan investasi kepada pengguna global. Volume perdagangan harian mencapai $4 miliar.',
        B3: 'Pertukaran terpusat ini (CEX) mendukung Initial Coin Offering (ICO) dan menawarkan berbagai jenis mata uang kripto. Platform ini memiliki lebih dari 500 aset dan lebih dari 300 pasangan perdagangan. Pengguna dapat menggunakan kebijakan asuransi untuk melindungi aset mereka. Pertukaran ini memiliki berbagai saluran dukungan pelanggan yang beroperasi sepanjang tahun untuk memberikan layanan profesional berkualitas tinggi.',
        B4: 'Ekosistem pertukaran ini meliputi perdagangan spot, perdagangan marjin, perdagangan berjangka, perdagangan derivatif, penempatan, dan pinjaman mata uang kripto. Para trader aktif dan investor dapat mencari peluang di pasar perdagangan luar bursa (OTC) dan menggunakan berbagai alat perdagangan kustom.',
        B5: 'Siapa pendiri Huobi Global?',
        B6: 'Pertukaran ini didirikan oleh Leon Li pada tahun 2013 di Beijing. Chief Executive Officer (CEO), Leon Li, lulus dari Universitas Tsinghua dengan gelar dalam bidang teknik otomasi pada tahun 2013. Sebelum mendirikan Huobi Global, Leon Li bekerja sebagai insinyur komputer di salah satu perusahaan perangkat lunak terbesar di Amerika Serikat, yaitu Oracle.',
        B7: 'Di mana lokasi Huobi Global?',
        B8: 'Kantor pusat perusahaan ini terletak di Kepulauan Seychelles, dan memiliki kantor cabang di Hong Kong, Korea Selatan, Jepang, dan Amerika Serikat.',
        B9: 'Negara-negara yang terbatas penggunaannya untuk Huobi Global',
        B10: 'Pengguna dari negara-negara berikut tidak diizinkan menggunakan semua layanan yang disediakan oleh Huobi Global: China, Amerika Serikat, Kanada, Jepang, Kuba, Iran, Korea Utara, Sudan, Suriah, Venezuela, Singapura, dan Crimea.',
        B11: 'Pengguna dari negara/daerah berikut tidak dapat menggunakan layanan perdagangan derivatif: China Daratan, Taiwan, Hong Kong, Israel, Irak, Bangladesh, Bolivia, Ekuador, Kirgistan, Sevastopol, Spanyol, Britania Raya (hanya untuk pengguna ritel), dan Selandia Baru.',
        B12: 'Pengguna Jepang dapat menggunakan layanan pertukaran Huobi Japan. Selain itu, perusahaan ini juga mengumumkan rencana untuk kembali ke pasar Amerika.',
        B13: 'Aset digital apa yang didukung oleh Huobi Global?',
        B14: 'Pertukaran ini mendukung lebih dari 400 aset digital dan mata uang kripto, termasuk yang paling populer: BTC, DOGE, ETH, LTC, XMR, HT, HUSD, USDT.',
        B15: 'Hingga Agustus 2022, pertukaran ini mendukung mata uang fiat berikut: ALL, BGN, CHF, CZK, DKK, EUR, GBP, HRK, HUF, MDL, MKD, NOK, PLN, RON, SEK, TRY, UAH, HKD, AUD, USD.',
        B16: 'Berapa biaya transaksi di Huobi Global?',
        B17: 'Huobi memiliki struktur biaya yang berjenjang, biaya transaksi tergantung pada volume perdagangan bulanan pengguna individu. Platform ini mengenakan biaya pembuat pesanan dan pengambil pesanan dasar sebesar 0,2%, dengan diskon tersedia untuk volume perdagangan di atas 5 juta dolar. Pemegang token Huobi (HT) juga dapat menikmati diskon. Trader profesional dapat menikmati diskon berikut: biaya pembuat pesanan mulai dari 0,0362%, biaya pengambil pesanan mulai dari 0,0462%.',
        B18: 'Apakah Huobi Global mendukung perdagangan dengan margin atau leverage?',
        B19: 'Platform ini mendukung perdagangan dengan margin untuk berbagai mata uang kripto, memungkinkan pengguna meminjam dana untuk perdagangan dengan leverage hingga 3x dalam mode margin full. Bunga margin dihitung per jam.',
        B20: 'Pengguna dapat menggunakan leverage hingga 200x untuk melakukan perdagangan kontrak berjangka kripto Bitcoin dan Ethereum.',


        C0: 'PancakeSwap',
        C1: 'Pengenalan PancakeSwap',
        C2: 'PancakeSwap ialah satu jenis pertukaran terdesentralisasi (DEX) yang popular yang membenarkan pengguna menukar token BEP-20 di rangkaian BSC. Ia merupakan pertukaran terbesar dalam ekosistem rangkaian BSC dan merupakan salah satu DEX yang popular di seluruh dunia berdasarkan pangsa pasaran. Pertukaran ini menggunakan model Automatic Market Maker (AMM) yang membolehkan pengguna berdagang dengan pool kecairan. Anda boleh menjadi penyedia kecairan dan menerima token penyedia kecairan (LP) sebagai bahagian daripada yuran transaksi pertukaran.',
        C3: 'Selain itu, pemegang token LP juga boleh menyertainya dalam penambangan kecairan untuk mendapatkan token utiliti CAKE pertukaran tersebut. Token ini boleh digadai secara tetap atau berpanjangan di dalam “kolam syrup” yang disediakan. Para gadai tetap boleh membatalkan gadai mereka pada bila-bila masa, sementara para gadai berpanjangan akan mendapatkan kadar pulangan maksimum (jangka masa gadai maksimum adalah 52 minggu).',
        C4: 'Di samping itu, PancakeSwap juga menyediakan permainan loteri untuk membolehkan pengguna memenangi hadiah CAKE. Di pasaran NFT, pengguna boleh berdagang koleksi dan juga mengatur profil peribadi yang disesuaikan dalam sistem profil peribadi NFT. Tambahan pula, terdapat juga pertukaran berjangka tanpa had yang dibangunkan bersama ApolloX, yang menyediakan pelbagai pasangan perdagangan token popular dan perkhidmatan perdagangan berjangka tak terhad. Pengguna boleh menggunakan leverage dana yang melebihi baki akaun mereka untuk berdagang pasangan token populer di pertukaran ini.',
        C5: 'Siapakah pengasas PancakeSwap?',
        C6: 'PancakeSwap diasaskan oleh individu yang tidak dikenali dan dikendalikan oleh lebih daripada dua puluh “jurumasak” tanpa nama di “dapur” pertukaran. Pertukaran ini bersifat sumber terbuka dan telah diaudit oleh syarikat keselamatan terkemuka seperti Certik dan Slowmist.',
        C7: 'Bilakah PancakeSwap dilancarkan?',
        C8: 'PancakeSwap dilancarkan pada September 2020.',
        C9: 'Di mana lokasi PancakeSwap?',
        C10: 'Kerana ia adalah pertukaran terdesentralisasi, pasukan operasi mereka berfungsi dalam mod kerja jarak jauh. Walau bagaimanapun, menurut laporan Crunchbase, ibu pejabat PancakeSwap terletak di Fukuoka, Jepun.',
        C11: 'Negara-negara yang Terhad untuk Menggunakan PancakeSwap',
        C12: 'Kerana ia adalah pertukaran terdesentralisasi, ia tidak menghadkan negara mana pun. Walau bagaimanapun, negara-negara berikut telah terhad di sisi geografi: Cuba, Iran, Zimbabwe, Iraq, Crimea, Syria, Sudan, Belarus, dan Republik Demokratik Congo.',
        C13: 'Senarai Token yang Disokong oleh PancakeSwap',
        C14: 'Semua token BEP-20 boleh didagangkan, dan di samping itu, PancakeSwap juga menyokong pelbagai pasangan perdagangan berjangka yang popular, seperti BTC/USDT, ETH/USDT, BNB/USDT, dan semua blockchain lapisan pertama lain.',
        C15: 'Bagaimana dengan yuran PancakeSwap?',
        C16: 'Pada penulisan artikel ini, pertukaran token akan dikenakan yuran perdagangan sebanyak 0.25%. Daripada jumlah tersebut, 0.17% akan dikembalikan sebagai ganjaran kepada pool kecairan, 0.03% sebagai dana simpanan, dan baki 0.05% digunakan untuk pembelian semula dan pembakaran.',
        C17: 'Adakah PancakeSwap menyokong perdagangan berkebelakangan atau margin?',
        C18: 'Bagi pasangan perdagangan terbesar, bursa perdagangan berjangka perduaan membenarkan penggunaan leverage sehingga 150 kali ganda, manakala bagi pasangan perdagangan yang lebih kecil, had maksimum adalah 50 kali ganda.',



        D0: 'UniSwap',
        D1: 'Apa itu Uniswap?',
        D2: 'Uniswap diluncurkan pada tahun 2018 dan merupakan salah satu bursa terdesentralisasi (DEX) cryptocurrency paling populer. Bursa ini merupakan yang pertama kali memperkenalkan model Automated Market Maker (AMM) untuk menggantikan model pemesanan tradisional yang masih digunakan oleh bursa lain. Uniswap beroperasi di blockchain Ethereum dan menggunakan beberapa kontrak pintar untuk menukar token ERC-20 secara aman antara pengguna.',
        D3: 'Sifat desentralisasi protokol ini berarti tidak ada satu entitas pusat yang mengoperasikan dan mengelola bursa ini; sebaliknya, pertukaran dilakukan secara peer-to-peer (P2P). Selain itu, Uniswap juga bertujuan untuk mengatasi masalah likuiditas yang ada di bursa lain. Uniswap V3 adalah versi ketiga dari protokol ini. Pembaruan ini tidak hanya meningkatkan insentif tetapi juga memberi kekuasaan dan fleksibilitas yang lebih besar pada penyedia likuuditas individu. Uniswap V3 mencakup 46.5% dari total volume perdagangan di semua bursa terdesentralisasi, dan catatan transaksi kumulatif sebesar 1 triliun dolar AS tercapai pada Mei 2022.',
        D4: 'Siapa pendiri Uniswap V3?',
        D5: 'Hayden Adams adalah pendiri Uniswap. Ia sebelumnya belajar di Stony Brook University dan bekerja sebagai insinyur mesin di Siemens. Adams terinspirasi oleh artikel blog Vitalik Buterin dan posting Reddit, dan setelah mendapatkan pendanaan dari Ethereum Foundation, protokol ini diluncurkan pada November 2018. Uniswap Labs telah mengumpulkan dana putaran benih dari Paradigm, diikuti oleh putaran Seri A senilai 11 juta dolar AS yang dipimpin oleh Andreessen Horowitz pada Agustus 2020.',
        D6: 'Uniswap V3 diluncurkan pada Mei 2021, sekitar 2,5 tahun setelah peluncuran versi pertama dan setahun setelah peluncuran Uniswap V2.',
        D7: 'Dimana lokasi Uniswap?',
        D8: 'Uniswap Labs, yang bertanggung jawab untuk mengembangkan protokol dan antarmuka Uniswap, berlokasi di New York City, Amerika Serikat. Sebagai protokol keuangan desentralisasi (DeFi), individu di seluruh dunia yang dapat terhubung ke internet dapat menggunakannya. Namun, beberapa negara memiliki pembatasan pengguna. Pada Juli 2022, Uniswap melarang pengguna dari 10 negara menggunakan protokolnya. Negara-negara ini termasuk Belarus, Kuba, Iran, Korea Utara, Suriah, Pantai Gading, Liberia, Sudan, Zimbabwe, dan Irak.',
        D9: 'Cryptocurrency apa saja yang dapat diperdagangkan di Uniswap?',
        D10: 'Karena ini adalah protokol peer-to-peer desentralisasi, siapa pun dapat menjual token apa pun di Uniswap. Saat ini, pasangan perdagangan yang paling populer termasuk USDC, Wrapped BTC, WETH, dan DAI.',
        D11: 'Berapa biaya Uniswap?',
        D12: 'Penyedia likuiditas di Uniswap dapat membangun kolam likuiditas dengan tiga tingkatan biaya: 0,05%, 0,30%, dan 1%. Melalui proposal UNI governance, mungkin akan ada penambahan tingkatan biaya lainnya.',
        D13: 'Apakah leverage atau margin trading tersedia di Uniswap?',
        D14: 'Platform Uniswap tidak menyediakan leverage atau margin trading.',


        E0: 'DODO',
        E1: 'Apa itu Dodo?',
        E2: 'Dodo adalah pertukaran desentralisasi yang berbasis pada Ethereum dengan fitur “Pasar Maker Aktif” (versi kustom dari Automated Market Maker - AMM). Dodo menyediakan cara untuk mengeluarkan dan bertransaksi aset digital di blockchain. Pertukaran ini menyediakan likuiditas dari dua sumber, yaitu likuiditas internal dan likuiditas yang diagregasikan dari pertukaran lain. Menurut whitepaper-nya, ini memungkinkan pertukaran ini menyediakan harga aset Web3 terbaik secara global.',
        E3: 'Pengguna dapat menukar token yang berbeda dengan cara yang izin tidak usah menggunakan order pasar atau order limit. Selain itu, Dodo juga menyediakan rute pertukaran tanpa biaya gas dan likuiditas yang lebih tinggi melalui Pembuat Pasar Profesional (zero slippage dan tanpa biaya gas). Selain itu, ada juga pasar NFT yang memungkinkan perdagangan token ERC-721 dan ERC-1155. Salah satu cara bagi penyedia likuiditas untuk menghasilkan keuntungan di pertukaran ini adalah dengan menambahkan likuiditas dan mendapatkan token LP untuk mendapatkan bagian dari biaya transaksi. Cara lainnya adalah melalui pertambangan, yaitu dengan menambang token tunggal atau menyediakan dana ke dalam kolam likuiditas tertentu untuk pertambangan pembuat pasar LP. Tentu saja, pengguna juga dapat melakukan pinjaman kilat atau mengalokasikan modal secara efisien.',
        E4: 'Terakhir, pertukaran ini juga menyediakan alat untuk pengembang membuat token. Pertukaran ini mendukung beberapa blockchain termasuk Ethereum, BNB Chain, Polygon, dan OKChain.',
        E6: 'Siapa pendiri Dodo?',
        E7: 'Menurut informasi di CrunchBase, Dodo didirikan oleh dua orang China, Qi Wang dan Shichi Dai. Proyek ini berhasil mengumpulkan $5 juta dari 17 perusahaan modal ventura yang berbeda, termasuk Coinbase ventures, Defiance Capital, dan Galaxy Digital.',
        E8: 'Kapan Dodo diluncurkan?',
        E9: 'Dodo diluncurkan pada bulan Agustus 2020.',
        E10: 'Di mana lokasi Dodo?',
        E11: 'Menurut informasi di CrunchBase, markas besar pertukaran ini terletak di Hong Kong.',
        E12: 'Negara-negara terbatas penggunaan Dodo',
        E13: 'Pada saat penulisan ini, tidak ada informasi tentang batasan penggunaan pertukaran ini di negara mana pun.',
        E14: 'Daftar token yang didukung oleh Dodo',
        E15: 'Dodo telah diterapkan di hampir semua blockchain yang kompatibel dengan EVM, seperti Arbitrum, Optimistic, Avalanche, dan Aurora. Oleh karena itu, pertukaran ini mendukung berbagai macam token yang berbeda di semua blockchain, termasuk token utama dan juga Wrapped Ether (Wrapped Ether) dan Wrapped Bitcoin.',
        E16: 'Berapa biaya transaksi di Dodo?',
        E17: 'Dodo mengenakan biaya kecil untuk menciptakan token baru (0,02 ETH), tetapi tidak ada informasi tentang biaya transaksi.',
        E18: 'Dapatkah leverage atau perdagangan margin digunakan di Dodo?',
        E19: 'Platform ini saat ini tidak memiliki fitur perdagangan margin, tetapi dalam rencana pengembangan proyek ini disebutkan bahwa mereka akan meluncurkan layanan perdagangan leverage pada tahun 2022.',


        F2: 'Tentang Curve (Ethereum)',
        F3: 'Pengenalan Curve Finance',
        F4: 'Curve Finance adalah kolam likuiditas untuk perdagangan mata uang kripto berpegangan tetap. Ini menggunakan model otomatis pembuat pasar pasar (AMM) untuk mencocokkan likuiditas tanpa menggunakan pesanan buku. Protokol ini terdesentralisasi dan tidak memerlukan kepercayaan sehingga siapa pun dapat menyediakan likuiditas pada satu atau beberapa kolam. Formula perkalian tetap AMM memberikan efisiensi tinggi dan slippage minimum kepada para pedagang.',
        F5: 'Kolam Curve adalah variasi StableSwap yang mengimplementasikan kontrak pintar. Ini dapat digunakan untuk menukar berbagai token, baik pasangan mata uang kripto berpegangan tetap (kolam reguler) maupun token terkemas. Kolam ini juga memungkinkan pengguna menggabungkan stablecoin dengan token LP (liquidity provider) dari kolam lain.',
        F6: 'Protokol ini mendukung berbagai blockchain seperti Ethereum, Arbitrum, Aurora, Avalanche, Fantom, Harmony, Optimism, Polygon, xDai, Moonbeam. Untuk menggunakan protokol Curve, biasanya diperlukan bridging dana dari Ethereum ke blockchain lainnya. Karena pentingnya DeFi dalam sistem keuangan terdesentralisasi, protokol ini menjadi subjek "perang Curve" di mana berbagai protokol bersaing untuk kontrol pengelolaan.',
        F7: 'Siapa pendiri Curve Finance?',
        F8: 'Curve Finance didirikan oleh Michael Egorov, salah satu pendiri dan CTO NuCypher, sebuah protokol infrastruktur mata uang kripto. Sebelumnya, ia juga menciptakan jaringan perbankan dan pinjaman terdesentralisasi bernama LoanCoin. Sebelum terjun ke dunia mata uang kripto, ia kuliah di Moscow Institute of Physics and Technology dan Swinburne University.',
        F9: 'Kapan Curve Finance diluncurkan?',
        F10: 'Curve Finance diluncurkan pada bulan Juni 2020, yang dikenal sebagai "musim panas DeFi". Oleh karena itu, ia dianggap sebagai salah satu dasar dari industri DeFi yang baru lahir.',
        F11: 'Di mana Curve Finance berlokasi?',
        F12: 'Menurut CBInsight, pusat Curve Finance berada di Swiss.',
        F13: 'Negara-negara yang dibatasi penggunaannya di Curve Finance',
        F14: 'Pada saat artikel ini ditulis, belum ada informasi tentang negara-negara yang dibatasi penggunaannya di Curve Finance. Namun, pengguna dari negara yang menjadi sasaran sanksi keuangan AS mungkin menghadapi batasan geografis.',
        F15: 'Daftar token yang didukung oleh Curve Finance',
        F16: 'Platform ini mendukung likuiditas untuk berbagai mata uang kripto berpegangan tetap seperti DAI, USDC, USDT, FRAX, dan TUSD. Selain itu, juga mendukung pertukaran token terkemas seperti wBTC, wETH, dan stETH (varian asli Lido dari staking ETH).',
        F17: 'Berapa biaya transaksi Curve Finance?',
        F18: 'Protokol ini menetapkan biaya transaksi standar sebesar 0,04%. Separuh dari biaya tersebut diberikan kepada penyedia likuiditas, sedangkan separuh sisanya diberikan kepada pemegang veCRV. Pemegang veCRV dapat meningkatkan hak suara mereka dengan mengunci token mereka dalam jangka waktu tertentu.',
        F19: 'Apakah Curve Finance mendukung perdagangan leverage atau margin?',
        F20: 'Platform ini hanya menyediakan layanan pertukaran token dan tidak mendukung perdagangan leverage atau margin.',


        G0: 'Apakah itu Balancer?',
        G1: 'Balancer adalah protokol market maker otomatis (AMM) yang terdesentralisasi berbasis Ethereum, yang mewakili blok bangunan likuiditas yang dapat diprogram dengan fleksibilitas.',
        G2: 'Dengan memisahkan logika dan matematika kurva AMM dari fitur pertukaran inti, Balancer menjadi AMM yang dapat diperluas dan dapat mencakup jumlah kurva pertukaran dan jenis pool yang tidak terbatas. Ini termasuk:',
        G3: 'Pool penimbangan 50/50x*y=k tradisional',
        G4: 'Bobot kustom, misalnya 80/20, untuk mengontrol eksposur',
        G5: 'Kurva tukar stabil',
        G6: 'Pool bertingkat (misalnya: Boosted Pools)',
        G7: 'Pool penambang dengan bobot yang berubah (misalnya: Pool Liquid Bootstrapping)',
        G8: 'Pool likuiditas terpusat',
        G9: 'Pool yang dihosting dengan parameter kustom',
        G10: 'Seluruh protokol dibangun di atas (misalnya: balancer)',
        G11: 'Dengan demikian, pedagang, aggregator, dan arbitrase dapat dengan mudah mendapatkan likuiditas agregat. Vault Balancer mengoptimalkan logika batch dan jalur untuk menjaga biaya Gas dan kebutuhan modal sangat rendah. Setiap pool individu dan proyek dibangun di atas likuiditas global Balancer, memberikan kedalaman likuiditas yang dalam dan membuka jalan pertukaran.',
        G12: 'Siapa yang menggunakan Balancer?',
        G13: 'Balancer adalah alat yang sangat berguna bagi berbagai peserta dalam ruang DeFi.',
        G14: 'Pedagang dapat menukar antara dua token ERC20 apa pun. Ini dapat dilakukan melalui Dapp Balancer atau aggregator seperti 1inch, ApeSwap, Paraswap, dll.',
        G15: 'Penyedia Likuiditas (LP) dapat menyumbangkan likuiditas ke pool dana untuk memperoleh biaya swap, insentif likuiditas, dan bentuk pendapatan lainnya.',
        G16: 'LP Pasif dapat memperoleh pendapatan di atas token Aave yang telah di-compound di pool yang ditingkatkan.',
        G17: 'Arbitraseurs dapat melakukan arbitrase dengan pool menggunakan metode seperti pertukaran batch dan flash loans.',
        G18: 'Pemegang token BAL dapat mengunci token mereka di veBAL dan berpartisipasi dalam pengembangan tata kelola protokol Balancer.',
        J0: 'Apa itu SushiSwap?',
        J1: 'SushiSwap adalah pertukaran terdesentralisasi (DEX) yang menggunakan AMM. Market maker otomatis ini menggunakan smart contract untuk menciptakan pasar perdagangan untuk berbagai pasangan token. Penyedia likuiditas menyediakan dana ke dalam smart contract ini, memungkinkan perdagangan tanpa gesekan. Pertukaran tersebut awalnya merupakan cabang dari UniSwap, DEX populer lainnya, dan menjadi salah satu bursa terbesar dalam DeFi. Saat ini, SushiSwap bekerja di hampir semua blockchain lapis satu yang kompatibel dengan EVM dan beberapa solusi lapis dua di atas Ethereum.',
        J3: 'Tujuan utama pertukaran ini adalah untuk trader dan institusi DeFi yang ingin melakukan perdagangan tanpa izin dan non-kustodian. Pertukaran ini mengatasi masalah likuiditas dan menerapkan tata kelola yang lebih demokratis dibandingkan dengan bursa lainnya. Token asli mereka dapat digunakan untuk memilih masalah-masalah pengelolaan.',
        J4: 'Produk inti mereka termasuk Bento Box, yang merupakan wadah token yang menghasilkan pendapatan bagi dana yang disimpan di dalamnya. Kashi Lending adalah platform pinjaman dan perdagangan marjin yang memungkinkan siapa saja membuat pasar token dengan efisiensi gas tinggi. Saat ini, MasterChef V2 adalah program penyedia likuiditas dengan total nilai lebih dari 1 miliar dolar. Akhirnya, baik melalui opsi harga tetap maupun lelang Belanda, Miso memberikan kesempatan kepada proyek untuk melakukan perdagangan di bursa.',
        J5: 'Siapakah pendiri SushiSwap?',
        J6: 'SushiSwap awalnya didirikan oleh Chef Nomi (nama samaran). Namun, kelompok tersebut kemudian meninggalkan proyek dan menyerahkan pengelolaannya kepada dua pengembang lain yang menggunakan nama samaran sushiswap dan 0xMaki. Keduanya bertanggung jawab atas pengembangan produk dan operasional bisnis, dan kemudian kepemilikan pertukaran ini diserahkan kepada CEO FTX, Sam Bankman-Fried dari bursa terpusat FTX.',
        J7: 'Kapan SushiSwap diluncurkan?',
        J8: 'SushiSwap diluncurkan pada Agustus 2020.',
        J9: 'Di mana SushiSwap berlokasi?',
        J10: 'Pertukaran ini tidak memiliki kantor pusat, tetapi beroperasi secara terdesentralisasi.',
        J11: 'Negara-negara yang dibatasi untuk menggunakan SushiSwap',
        J12: 'Saat ini, tidak ada informasi tentang negara yang melarang atau membatasi penggunaan pertukaran ini.',
        J13: 'Daftar token yang didukung oleh SushiSwap',
        J14: 'Karena beroperasi pada blockchain lapis satu dan lapis dua, SushiSwap mendukung hampir semua token ERC-20 di pasar.',
        J15: 'Biaya transaksi SushiSwap',
        J16: 'Pertukaran ini mengenakan biaya 0,3% dari jumlah yang ditukarkan, sesuai dengan standar industri.',
        J17: 'Apakah SushiSwap mendukung perdagangan leverage atau margin?',
        J18: 'SushiSwap menyediakan platform pinjaman dan perdagangan marjin bernama Kashi, yang menggunakan kerangka pasar terisolasi yang unik.',


    },
    exchangeRobot: {
        h1: "Mencapai strategi perdagangan automatik yang diperibadikan",
        h2: "robot perdagangan",
        h3: 'Tetapan parameter yang fleksibel dan pengurusan risiko yang diperibadikan untuk membantu anda melaksanakan strategi dagangan dengan tepat',
        h4: "Lihat butiran",
        h5: "Pelaksanaan transaksi yang lebih tepat dan berkesan",
        h6: "Membantu anda merealisasikan dagangan automatik dan menetapkan strategi dagangan yang diperibadikan mengikut keperluan anda sendiri",
        h7: "Parameter strategi fleksibel",
        h8: "Robot dagangan reaktor menyediakan pelbagai tetapan parameter strategi untuk memenuhi keperluan pelabur yang berbeza",
        h9: "Syarat pencetus untuk membeli dan menjual",
        h10: "Anggaran Tetapan Dana Pelaburan",
        h11: "Tetapkan parameter seperti take profit dan stop loss",
        h12: "Strategi pembelian yang pelbagai",
        h13: "Anda boleh memilih strategi pembelian yang sesuai mengikut keadaan pasaran dan matlamat pelaburan, dan seterusnya menyesuaikannya melalui tetapan parameter",
        h14: "Peratusan harga bida",
        h15: "Masa buka maksimum untuk membeli",
        h16: "Faedah terbuka maksimum dan peratusan setiap token",
        h17: "Mekanisme Penyejukan Transaksi",
        h18: "Strategi jualan pintar, masa yang tepat",
        h19: "Reaktor menyediakan strategi jualan pintar untuk membantu anda memahami peluang jualan terbaik dalam urus niaga sambil menguruskan risiko dengan berkesan",
        h20: 'Hentikan jualan rugi',
        h21: "Trailing Stop Sell",
        h22: "Auto tutup",
        h23: "Kaedah purata kos dolar A.S.",
        h24: "Parameter konfigurasi lanjutan",
        h25: "Robot perdagangan reaktor menyokong jenis pesanan lanjutan, seperti trailing stop buy, auto-close, purata kos dolar, dsb., membolehkan pengguna mempunyai kawalan yang lebih terperinci ke atas pelaksanaan perdagangan dan pengurusan risiko.",
        h26: "Tetapan henti rugi yang diperibadikan",
        h27: "Anda boleh menetapkan parameter henti rugi secara fleksibel untuk mengehadkan potensi kerugian. Tetapkan amaun atau peratusan henti rugi tetap, atau gunakan fungsi henti rugi mengekor dinamik untuk melaraskan paras henti rugi secara automatik apabila harga jatuh untuk melindungi dana anda.",
        h28: "Purata Kos Dolar (DCA)",
        h29: "Bahagikan risiko turun naik pasaran melalui kaedah pelaburan tetap dan sama rata untuk mencapai pertumbuhan stabil jangka panjang.",
    },
    faq: {
        h1: "Sokongan/Soalan Lazim Pelanggan",
        h2: "Mencari jawapan? Jawapannya mungkin ada di sini.",
        h3: 'Panduan Permulaan',
        h4: "Apakah Reaktor?",
        h5: "Bagaimana untuk menyediakan Reaktor?",
        h6: "Rebat",
        h7: "Platform Kecairan",
        h8: "DEX",
        h9: "Soalan Keselamatan",
        h10: "Faedah Ahli",
        h11: "Produk",
        h12: "Transaksi",
        h13: "Lihat lagi",
        h14: "Reaktor ialah platform dagangan kuantitatif yang inovatif",
        h15: "Reaktor menyepadukan algoritma dan teknologi termaju untuk membantu pengguna berdagang dengan lebih bijak dan cekap. Dengan mengautomasikan pelaksanaan strategi dagangan, menyediakan data dan analisis pasaran masa nyata, dan menyediakan pelbagai alatan dagangan, pengguna boleh mencapai hasil dagangan yang lebih baik dalam pasaran kewangan. Sama ada mereka pedagang baru atau profesional, Reactor menyediakan mereka dengan matlamat pelaburan dagangan yang lebih tinggi kepada mereka.",
        h16: "Soalan Lazim",
        h17: "Berapa banyak keuntungan yang boleh saya perolehi?",
        h18: "Bagaimana arbitraj berfungsi?",
        h19: "Adakah anda menjamin keuntungan?",
        h20: "Bagaimana saya boleh menyambung ke pertukaran?",
        h21: "Berapa banyak robot yang boleh saya buka pada satu akaun dagangan?",
        h22: "Mata wang asas yang manakah harus saya gunakan?",
        h23: "Adakah Reaktor selamat?",
        h24: "Adakah akaun saya masih tersedia selepas keluar dari dompet HD utama?",
    },
    helpCenter: {
        h1: "Pusat Bantuan",
        h2: "Mencari jawapan? Jawapannya mungkin ada di sini.",
        h3: 'Semak imbas topik',
        h4: "Teroka artikel kami yang disokong dan kategori Soalan Lazim",
        h5: "Apakah Reaktor?",
        h6: "Reaktor menyediakan pengguna dengan analisis pasaran yang tepat, strategi kuantitatif dan alat perdagangan. Melalui Reaktor, pengguna boleh mencapai pulangan pelaburan yang lebih tinggi dan berkembang bersama-sama dengan pedagang kuantitatif lain dalam komuniti yang aktif.",
        h7: "Bagaimana untuk menyediakan Reaktor?",
        h8: "Panduan ringkas tentang cara menyediakan pelbagai ciri asas Reaktor. Di sinilah anda bermula sebagai pengguna baharu!",
        h9: "Rebat",
        h10: "Platform Kecairan",
        h11: "Kuantiti DEX",
        h12: "Soalan Keselamatan",
        h13: "Faedah Ahli",
        h14: "Robot DEMO",
        h15: "Perdagangan manual",
        h16: "DCA",
        h17: "Jejak Susunan",
        h18: "Isyarat",
        h19: "Dompet",
        h20: "Salin Robot",
        h21: "Data pada rantai",
        h22: "robot perdagangan",
        h23: "Robot Arbitraj",
        h24: "Soalan Lazim",
        h25: "Berapa banyak keuntungan yang boleh saya perolehi?",
        h26: "Bagaimana arbitraj berfungsi?",
        h27: "Adakah anda menjamin keuntungan?",
        h28: "Bagaimana saya boleh menyambung ke pertukaran?",
        h29: "Berapa banyak robot yang boleh saya buka pada satu akaun dagangan?",
        h30: "Mata wang asas yang manakah harus saya gunakan?",
        h31: "Adakah Reaktor selamat?",
        h32: "Adakah akaun saya masih tersedia selepas keluar dari dompet HD utama?",
    },
    interestRobot: {
        h1: "Tangkap perbezaan harga dan capai pendapatan yang stabil",
        h2: "Robot arbitraj pintar yang mengeksploitasi perbezaan harga antara pasaran",
        h3: 'Lihat lagi',
        h4: "Robot Arbitraj",
        h5: "Menggunakan perbezaan harga untuk menjalankan arbitraj pertukaran silang",
        h6: "Menyokong sambungan serentak ke pelbagai platform dagangan dan rantaian blok",
        h7: "Termasuk tetapi tidak terhad kepada Binance, Ouyi, Huobi, Ethereum, Binance Smart Chain, Tron, dsb., pemantauan masa nyata terhadap perubahan pasaran dan harga.",
        h8: "Imbasan dan Perbandingan Pasaran",
        h9: "Apabila perbezaan harga ditemui, ia akan segera melaksanakan operasi dagangan yang sepadan. Beli pada satu platform dan jual pada platform lain pada masa yang sama untuk mendapatkan keuntungan perbezaan harga",
        h10: "Pemantauan dan pelaporan masa nyata",
        h11: "Pengguna boleh melihat rekod urus niaga terperinci, statistik keuntungan dan paparan carta untuk menilai secara menyeluruh kesan dan peluang potensi transaksi arbitraj.",
        h12: "Lakukan transaksi dengan cepat",
        h13: "Kami komited untuk menyediakan pelaksanaan transaksi yang pantas dan cekap untuk memastikan pengguna dapat merebut peluang pasaran tepat pada masanya.",
        h14: "Sistem perdagangan kami telah direka bentuk dan dioptimumkan dengan teliti untuk mengendalikan sejumlah besar pesanan dan mengekalkan tindak balas pantas untuk melaksanakan transaksi bagi memastikan anda boleh berdagang pada harga terbaik. Sistem perdagangan dibina di atas infrastruktur yang stabil dan boleh dipercayai dengan tahap toleransi kesalahan dan mekanisme redundansi yang tinggi.",
        h15: "Sebagai ahli Reaktor, anda akan menikmati sokongan pelayan eksklusif, yang akan meningkatkan lagi kelajuan pelaksanaan dan kecekapan transaksi.",
        h16: 'nod teragih',
        h17: "bilik komputer",
        h18: "kluster pelayan",
        h19: "Sediakan pelayan khusus secara percuma",
        h20: "Reaktor memperuntukkan sumber pelayan khusus untuk ahli profesional secara percuma, menyediakan persekitaran operasi yang bebas dan cekap untuk robot anda",
        h21: "Lihat butiran",
        h22: "Dapatkan pendapatan yang stabil setiap hari",
        h23: "Sediakan syiling dan amaun, pasaran terpilih, dan tetapan arbitraj pertukaran dan tetapan arbitraj pasaran, supaya robot boleh menangkap perbezaan harga dengan tepat antara pertukaran yang berbeza, dan mendapatkan peluang arbitraj daripadanya.",
        h24: "Pada masa yang sama, robot juga mempunyai fungsi pemulihan dan tunggakan, yang boleh bertindak balas secara fleksibel terhadap turun naik pasaran dan memastikan pelaburan anda terus berkembang.",
        h25: "Ketahui lebih lanjut",
    },
    join: {
        h1: "Perluaskan perjalanan penerokaan Reaktor anda dan pilih penyelesaian yang sesuai dengan anda!",
        h2: "orang baru",
        h3: "Percuma",
        h4: "Satu strategi templat boleh digunakan",
        h5: "Satu kombinasi strategi boleh dibuat",
        h6: "40 kombinasi strategi boleh dilaksanakan",
        h7: "Sokongan 2 pertukaran",
        h8: "Portfolio Pengurusan Pelaburan",
        h9: "Mari kita mulakan",
        h10: "Lanjutan",
        h11: "bulan",
        h12: "5 strategi templat boleh digunakan",
        h13: "5 kombinasi strategi boleh dibuat",
        h14: "200 kombinasi strategi boleh dilaksanakan",
        h15: "Sokongan 5 pertukaran",
        h16: "Portfolio Pengurusan Pelaburan",
        h17: "Boleh menyalin dan menjejaki 5 transaksi robot",
        h18: "Penggunaan percuma dagangan simulasi",
        h19: "Strategi MarketPlace Terbuka",
        h20: "Lanjutan",
        h21: "10 strategi templat boleh digunakan",
        h22: "15 kombinasi strategi boleh dibuat",
        h23: "500 kombinasi strategi boleh dilaksanakan",
        h24: "Sokongan 20 pertukaran",
        h25: "Portfolio Pengurusan Pelaburan",
        h26: "Boleh menyalin dan menjejaki 20 transaksi robot",
        h27: "Dapatkan 2 sesi kursus latihan dagangan bulanan",
        h28: "Perkhidmatan pereka strategi tersuai satu-ke-satu",
        h29: "Strategi AI Reaktor Sokongan dan pereka AI",
        h30: "Aplikasi sokongan untuk menjadi ahli strategi kerjasama",
        h31: "Akses percuma kepada komuniti eksklusif VIP",
        h32: "Profesional",
        h33: "Dasar templat penggunaan tanpa had",
        h34: "Penciptaan gabungan strategi tanpa had",
        h35: "Kombinasi strategi pelaksanaan tanpa had",
        h36: "Pertukaran tanpa had",
        h37: "Portfolio Pengurusan Pelaburan",
        h38: "Boleh menyalin dan menjejaki 50 transaksi robot",
        h39: "Semua kursus latihan dagangan bulanan",
        h40: "Perkhidmatan pereka strategi tersuai satu-ke-satu",
        h41: "Strategi AI Reaktor Sokongan dan pereka AI",
        h42: "Panduan penunjuk teknikal tambahan",
        h43: "Sokong aplikasi untuk menjadi ahli strategi yang dijemput khas dan berkongsi keuntungan bersama",
        h44: "Pelayan Khusus",
        h45: "Semakan selang dasar eksklusif",
        h46: "Perbandingan tahap keahlian",
        h47: "Perdagangan manual",
        h48: "Robot Perdagangan",
        h49: "Salin Robot",
        h50: "Robot Pembuat Pasaran",
        h51: "Robot Arbitraj",
        h52: "Kursus Latihan Perdagangan",
        h53: "perdagangan simulasi",
        h54: "Strategi tersuai satu-dengan-satu",
        h55: "Memohon untuk menjadi ahli strategi kerjasama",
        h56: "Komuniti Eksklusif VIP",
        h57: "Panduan Penunjuk Teknikal",
        h58: "Memohon untuk menjadi ahli strategi yang dijemput khas dan berkongsi keuntungan bersama",
        h59: "Pelayan Khusus",
        h60: "Semakan selang dasar eksklusif",
    },
    manualTrading: {
        h1: "Kuasa untuk membuat keputusan bebas",
        h2: "Gabungan sempurna perdagangan kuantitatif dan perdagangan manual",
        h3: "Lihat lagi",
        h4: "Reaktor mempunyai keupayaan yang sangat baik dalam perdagangan automatik, tetapi mengapa ia masih memerlukan penyertaan perdagangan manual?",
        h5: "Pengalaman perdagangan yang lebih komprehensif dan komprehensif",
        h6: "Rizab kuasa membuat keputusan perdagangan anda",
        h7: "Perdagangan manual membolehkan pengguna mengekalkan kuasa membuat keputusan perdagangan terakhir, dan anda masih boleh membuat keputusan muktamad mengenai transaksi mengikut pertimbangan dan situasi anda sendiri.",
        h8: "urus niaga dipacu peristiwa",
        h9: "Acara tertentu dan pengumuman berita mungkin mempunyai impak yang besar pada pasaran, dan peristiwa ini mungkin tidak dapat diramal sepenuhnya dan boleh disesuaikan dengan sistem perdagangan automatik.",
        h10: "Pertimbangan dan pengalaman profesional",
        h11: "Pedagang yang berpengalaman mempunyai pengetahuan pasaran yang kaya dan pertimbangan profesional, dan boleh membuat keputusan yang lebih tepat berdasarkan pengalaman mereka sendiri.",
        h12: "Bertindak balas dengan cepat kepada risiko pasaran dan melindungi aset anda",
        h13: "Kelegaan Hentian Kecemasan",
        h14: "Apabila keadaan pasaran tidak menggalakkan, pengguna boleh mencetuskan fungsi pembubaran henti kecemasan dalam Reaktor, dan menjual semua aset pegangan melalui operasi satu klik.",
    },
    market: {
        h1: "Pasaran Mata Wang Kripto Hari Ini",
        h2: "Pada platform Reactor, anda boleh memilih beribu-ribu mata wang kripto daripada lebih daripada 10 platform kecairan untuk urus niaga pelaburan kuantitatif. Kami telah mewujudkan perkongsian dengan banyak bursa terkenal untuk menyediakan anda dengan pelbagai pilihan mata wang kripto.",
        h3: 'Pasaran mata wang kripto',
        h4: "USD",
        h5: "24 jam",
        h6: "Binance",
        h7: "Ouyi",
        h8: "Huobi",
        h9: "Mata Wang",
        h10: "Harga",
        h11: "naik turun 24 jam",
        h12: "Jumlah dagangan 24 jam",
        h13: "Nilai Pasaran",
        h14: "100 juta",
        h15: "Jumlah: 608 item, dipaparkan pada setiap halaman: 20 item",
    },
    merchantRobot: {
        h1: "Robot pembuat pasaran reaktor",
        h2: "Lebih baik mengambil bahagian dalam pasaran, meningkatkan hasil dagangan, dan mendapatkan pengalaman yang lebih baik dalam proses pelaburan",
        h3: 'Lihat lagi',
        h4: "Bagaimanakah robot pembuat pasaran Reaktor berfungsi?",
        h5: "Robot pembuat pasaran reaktor akan memantau keadaan pasaran, status pesanan dan aktiviti perdagangan pelbagai bursa. Ia akan memperoleh data pasaran terkini dalam masa nyata, termasuk maklumat seperti kuantiti, harga dan kedalaman pesanan jual beli, dan secara automatik melaksanakan operasi jual beli, serta melaraskan sebut harga dan volum dagangan secara dinamik mengikut perubahan dalam keadaan pasaran dan buku pesanan.",
        h6: "Kelebihan robot pembuat pasaran",
        h7: "Model pendapatan stabil",
        h8: "Aktiviti membuat pasaran berterusan untuk mendapatkan pendapatan tebaran transaksi, dan model pendapatan secara relatifnya stabil",
        h9: "Sediakan kecairan",
        h10: "Sediakan kecairan kepada pasaran dengan memetik dan menyediakan pesanan beli dan jual.",
        h11: "Pemantauan pasaran masa nyata",
        h12: "Selaraskan strategi sebut harga dan kecairan secara automatik mengikut turun naik pasaran dan status pesanan",
        h13: "Perdagangan Automatik",
        h14: "Laraskan sebut harga dan pesanan dalam masa nyata mengikut keadaan pasaran",
        h15: "Mengapa menggunakan robot pembuat pasaran dalam Reaktor?",
        h16: "Berbanding dengan pembuat pasaran biasa tradisional, robot pembuat pasaran yang dilancarkan oleh Reactor mempunyai kelebihan pelaksanaan automatik, kelebihan teknikal, penyesuaian pasaran yang pantas, analisis dan pengoptimuman data, dan fleksibiliti konfigurasi.",
    },
    policyBacktest: {
        h1: "Ujian belakang strategi",
        h2: "Pengguna menjalankan transaksi simulasi pada data pasaran sejarah mengikut strategi dagangan mereka sendiri untuk menilai kesan strategi dan potensi keuntungan.",
        h3: 'Lihat lagi',
        h4: "Pemilihan fleksibel, ujian belakang yang tepat",
        h5: "Pilih pasangan dagangan dan tempoh masa",
        h6: "Anda boleh memilih pasangan dagangan dan tempoh masa yang sesuai berdasarkan keutamaan peribadi anda dan keadaan pasaran untuk menilai dengan lebih tepat prestasi dan potensi risiko strategi.",
        h7: "Cari gabungan terbaik untuk meningkatkan prestasi strategi",
        h8: "Tetapan dan pengoptimuman parameter",
        h9: "Anda boleh melaraskan parameter secara fleksibel seperti kekerapan dagangan, keadaan henti untung dan henti rugi untuk mencari konfigurasi strategi terbaik. Sentiasa mencuba dan mengoptimumkan gabungan parameter akan membantu meningkatkan keuntungan dan kestabilan strategi.",
        h10: "Laporan terperinci untuk pemahaman menyeluruh tentang prestasi strategi",
        h11: "Analisis dan laporan hasil ujian belakang",
        h12: "Reaktor akan menjana laporan hasil ujian belakang yang terperinci. Laporan ini termasuk penunjuk utama seperti kadar pulangan, kadar kemenangan dan anjakan maksimum, membolehkan anda memahami sepenuhnya prestasi strategi. Ia membantu anda menemui kelebihan dan ruang untuk penambahbaikan strategi, dan membuat pelarasan yang sepadan.",
        h13: "Paparan visual, pemahaman mendalam tentang perubahan strategi",
        h14: "Paparan carta dan penunjuk",
        h15: "Reaktor menyediakan carta dan penunjuk intuitif untuk membolehkan anda memahami dengan lebih baik hasil ujian balik strategi. Anda boleh melihat carta seperti carta harga, isyarat dagangan dan kedudukan, dan anda juga boleh menggunakan pelbagai penunjuk teknikal untuk membantu dalam menilai keberkesanan strategi.",
        h16: "Skim ujian belakang yang dibuat khusus",
        h17: "Reaktor membekalkan anda pengalaman ujian belakang peribadi dan tersuai: penyelesaian ujian belakang yang dibuat khusus, analisis hasil ujian belakang yang diperibadikan, pelarasan dan pengoptimuman strategi masa nyata, dan pengesyoran dagangan tersuai",
        h18: "Lihat lagi",
        h19: "Prinsip Asas Pengujian Balik Strategi Reaktor",
        h20: "Perdagangan simulasi berdasarkan data pasaran sejarah dan tetapkan peraturan dagangan untuk menilai dan mengesahkan prestasi strategi",
        h21: "Data pasaran sejarah",
        h22: "Reaktor menggunakan data pasaran lepas, termasuk penunjuk seperti harga dan volum dagangan, untuk membina persekitaran pasaran sejarah yang disimulasikan.",
        h23: "Pelaksanaan transaksi simulasi",
        h24: "Berdasarkan peraturan dagangan yang ditetapkan, Reaktor melakukan pelaksanaan dagangan simulasi pada data pasaran sejarah.",
        h25: "Penilaian keputusan ujian belakang",
        h26: "Reaktor akan mengira penunjuk utama berdasarkan rekod transaksi semasa tempoh ujian belakang, seperti hasil, kadar kemenangan, penarikan semula maksimum, dll.",
    },
    rebate: {
        h1: "Kongsi pautan, dapatkan pendapatan tambahan dan dapatkan syiling dengan rakan!",
        h2: "Jemput rakan anda dengan berkongsi pautan yang disediakan. Anda boleh memperoleh komisen sehingga 15% untuk setiap langganan yang dibeli oleh rakan anda di Reactor.",
        h3: 'Lihat butiran',
        h4: "Komisen boleh ditarik balik",
        h5: "Promosi tanpa had",
        h6: "Lebih banyak anda mempromosikan, lebih banyak ganjaran yang anda dapat",
        h7: "Komisen sehingga 15%",
        h8: "Mengapa memilih Reaktor?",
        h9: "Lindungi aset anda",
        h10: "Reaktor mengguna pakai tahap tertinggi protokol keselamatan dan teknologi penyulitan untuk memastikan aset digital anda sentiasa dilindungi paling kukuh.",
        h11: "Wawasan ke arah aliran pasaran",
        h12: "Reaktor menyediakan statistik data dan alat analisis yang komprehensif untuk membantu anda memperoleh pemahaman yang mendalam tentang arah aliran pasaran dan turun naik harga. Dan laraskan strategi dagangan anda mengikut keadaan pasaran.",
        h13: "Pengalaman dagangan yang pantas dan cekap",
        h14: "Reaktor menyediakan pelaksanaan transaksi berkelajuan tinggi dan pemprosesan pesanan untuk memastikan anda boleh bertindak balas dengan cepat kepada perubahan pasaran dan merebut peluang perdagangan.",
        h15: "Kami akan melindungi privasi dan maklumat rakan anda",
        h16: "Keselamatan dan privasi pengguna kami adalah amat penting bagi kami. Reaktor dilengkapi dengan infrastruktur keselamatan teratas untuk memastikan perlindungan penuh aset terurus pada setiap masa.",
        h17: "Lihat Perjanjian Privasi",
        h18: "Bagaimana untuk menggunakan pautan rujukan?",
        h19: "Hantar kepada rakan melalui e-mel atau mesej peribadi.",
        h20: "Kongsi di media sosial.",
        h21: "Diterbitkan di laman web peribadi.",
        h22: "Siarkan video di Youtube dan siarkan pautan dalam ulasan.",
        h23: "Lihat peraturan jemputan",
    },
    signal: {
        h1: "Enjin pintar perdagangan automatik",
        h2: "Peroleh templat isyarat dagangan berkualiti tinggi untuk mencapai pulangan pelaburan yang stabil",
        h3: 'Lihat butiran',
        h4: "Templat isyarat dagangan berkualiti tinggi",
        h5: "Disaring dan disahkan dengan ketat",
        h6: "Dapatkan operasi sebenar pedagang profesional, termasuk penunjuk utama seperti titik belian, titik jualan, kedudukan henti rugi, dsb., dan boleh disalin secara automatik selepas langganan",
        h7: "Di bawah saringan ketat Reaktor, purata kadar kemenangan isyarat mencapai 58.6%",
        h8: "Terdapat 100+ penyedia isyarat di pusat beli-belah, mereka adalah peniaga atau institusi profesional.",
        h9: "Ketahui lebih lanjut",
        h10: "Serahkan permohonan untuk kemasukan",
        h11: "Tunjukkan strategi perdagangan dan kepakaran anda di pusat membeli-belah, dan kongsi pengalaman berjaya anda dengan pedagang di seluruh dunia, dengan itu mengembangkan pengaruh dan potensi pendapatan anda.",
        h12: "Hubungi kami",
        h13: "Anda boleh menggunakan data ini untuk memilih isyarat yang sesuai dengan anda dengan lebih tepat",
        h14: "Kekerapan Isyarat",
        h15: "Hantar kekerapan isyarat untuk menentukan penyesuaiannya kepada pilihan dagangan anda.",
        h16: "Bilangan Pelanggan",
        h17: "Ketahui bilangan pelanggan SignalR, sebagai penunjuk penerimaan komuniti",
        h18: 'Penilaian dan prestasi',
        h19: "Bilangan isyarat dalam 3 bulan terakhir, purata keuntungan setiap transaksi dan penunjuk lain",
        h20: "Statistik dan Ulasan",
        h21: "Semak statistik masa nyata dan ulasan pengguna lain pada bila-bila masa",
    },
    strategy: {
        h1: "Sesuaikan strategi dagangan unik anda",
        h2: "Memberi anda penyelesaian dagangan yang diperibadikan",
        h3: 'Hubungi kami',
        h4: "Ahli strategi kuantitatif eksklusif",
        h5: "Sokongan perdagangan peribadi dan nasihat profesional",
        h6: "Jadi ahli strategi yang dijemput khas dan kongsi keuntungan bersama",
        h7: "Pelayan Khusus",
        h8: "Sokongan perdagangan peribadi dan nasihat profesional",
        h9: "Kami tahu bahawa matlamat dan keperluan pelaburan setiap pelanggan adalah unik, jadi kami akan bekerjasama dengan anda melalui ahli strategi kami yang berdedikasi untuk memastikan bahawa strategi dagangan anda sejajar dengan matlamat anda.",
        h10: "Strategi",
        h11: "Ahli strategi kami akan bekerjasama dengan anda secara bersemuka untuk mendapatkan pemahaman yang mendalam tentang objektif pelaburan anda, toleransi risiko dan keperluan masa.",
        h12: "Penyelesaian yang disesuaikan untuk anda",
        h13: "Pasukan kami akan melebihi jangkaan untuk membantu anda mencapai matlamat anda",
        h14: "Pelanggan Persendirian",
        h15: "Ingin mencapai matlamat pelaburan anda yang paling bercita-cita tinggi? Kami telah membantu banyak pelanggan memaksimumkan pulangan, mengurangkan perbelanjaan dan mengembangkan aset portfolio.",
        h141: "Syarikat",
        h151: "Mencari kecekapan kewangan yang lebih tinggi dan pulangan pelaburan? Kami bekerjasama dengan pelbagai syarikat untuk menyediakan penyelesaian yang disesuaikan untuk membantu anda mencapai matlamat pertumbuhan modal dan pengurusan risiko.",
        h16: "Perusahaan",
        h17: "Kami memenuhi setiap keperluan anda",
        h18: "Reaktor mempunyai kelebihan yang tiada tandingan berbanding produk terkemuka kami",
        h19: "Ahli strategi kuantitatif eksklusif",
        h20: "Dapat menyesuaikan strategi dagangan kuantitatif mengikut keperluan dan matlamat anda. Mereka akan bekerjasama rapat dengan anda untuk mendapatkan pemahaman yang mendalam tentang keutamaan pelaburan dan toleransi risiko anda, dan memberi anda nasihat perdagangan yang diperibadikan berdasarkan aliran pasaran dan analisis data.",
        h21: "Sokongan perdagangan peribadi dan nasihat profesional",
        h22: "Kami akan memahami dengan mendalam matlamat dan keperluan dagangan anda, menyediakan strategi dan cadangan dagangan yang disasarkan kepada anda, dan membantu anda mengoptimumkan keputusan dagangan anda. Sama ada analisis teknikal, penyelidikan pasaran atau pengurusan risiko, kami akan memberikan anda bimbingan dan sokongan profesional.",
        h23: "Jadi ahli strategi yang dijemput khas dan kongsi keuntungan bersama",
        h24: "Sebagai ahli strategi tetamu, anda akan berpeluang berkongsi strategi dan pengalaman dagangan anda yang berjaya, dan belajar serta berkembang bersama-sama dengan pengguna lain. Pada masa yang sama, anda akan menikmati peluang untuk berkongsi keuntungan dan berkongsi keuntungan dagangan dengan bekerjasama dengan Reactor.",
        h25: "Pelayan khusus",
        h26: "Pelayan kami dicirikan oleh prestasi tinggi dan kependaman rendah, yang boleh memproses pesanan transaksi dengan cepat dan memastikan pelaksanaan transaksi tepat pada masanya. Dengan menggunakan pelayan khusus, anda boleh memahami peluang transaksi dengan lebih baik dan meningkatkan kadar kejayaan dan kesan transaksi.",
        h27: "Perkhidmatan tersuai",
        h28: "Membantu anda mencapai matlamat dagangan anda dan mencapai kejayaan yang lebih besar dalam bidang perdagangan kuantitatif. Tidak kira gaya dagangan, keutamaan atau matlamat anda, kami dengan sepenuh hati akan memberikan anda perkhidmatan tersuai berkualiti tinggi",
        h29: "Hubungi kami",
    },
    strategyMall: {
        h1: "Temui strategi berkualiti tinggi dan capai kejayaan transaksi",
        h2: "Terokai peluang pasaran dengan pedagang terkemuka",
        h3: 'Lihat butiran',
        h4: "Strategy Mall",
        h5: "Optimumkan pengalaman perdagangan",
        h6: "Strategi Dagangan Terbukti dan Terpilih",
        h7: "Pembelian satu kali untuk kegunaan seumur hidup, anda boleh mendapatkan dan memegang hak untuk menggunakan strategi dagangan yang dibeli dan strategi ini boleh dimuat turun.",
        h8: "Data prestasi terperinci, termasuk maklumat seperti pulangan sejarah, penunjuk risiko dan kekerapan transaksi.",
        h9: "Kami menyediakan sejarah transaksi terperinci untuk memberi anda pemahaman yang lengkap tentang prestasi sebenar setiap strategi.",
        h10: "Ketahui lebih lanjut tentang pusat membeli-belah strategi",
        h11: "Serahkan permohonan untuk kemasukan",
        h12: "Tunjukkan strategi perdagangan dan kepakaran anda di pusat membeli-belah, kongsi pengalaman berjaya anda dengan pedagang di seluruh dunia, untuk mengembangkan pengaruh dan potensi pendapatan anda.",
        h13: "Hubungi kami",
        h14: "Kelebihan Reactor Strategy Mall?",
        h15: "Pilihan strategik yang pelbagai",
        h16: "Transaksi autonomi yang fleksibel",
        h17: "Pakar Strategi Profesional",
        h18: "Sama ada anda lebih suka mengikuti arah aliran, dagangan swing atau strategi arbitraj, anda boleh mencari strategi yang sesuai dengan anda",
        h19: "Keupayaan untuk melaraskan parameter dagangan secara fleksibel berdasarkan mengekalkan strategi untuk menyesuaikan diri dengan keadaan pasaran yang berubah-ubah",
        h20: "Ahli strategi mempunyai pandangan pasaran dan rekod dagangan yang berjaya, mereka akan memberikan sokongan yang kuat untuk transaksi anda",
        h21: "Ketahui lebih lanjut",
        h22: "Pengawasan dasar yang ketat",
        h23: "Untuk memastikan ketelusan dan kebolehpercayaan strategi dagangan, setiap strategi perlu melalui proses semakan dan ujian kami sebelum pergi ke dalam talian.",
        h24: "Strategi teruji masa",
        h25: "Strategi telah menjalani simulasi masa nyata jangka panjang dan ujian belakang di latar belakang kami, dan data sejarah yang kami sediakan adalah benar-benar nyata.",
    },
    business: {
        h1: "Cipta peluang perniagaan dan kongsi kejayaan",
        h2: "Kami amat mengalu-alukan rakan kongsi dari semua lapisan masyarakat untuk bekerjasama dengan kami. Dengan menyertai program rakan kongsi kami, anda akan berpeluang menjadi rakan kongsi strategik kami untuk bersama-sama mencipta peluang perniagaan dan mencapai keputusan menang-menang.",
        h3: 'Hubungi kami',
        h4: "Sokongan rakan kongsi dan perkongsian sumber",
        h5: "Sebagai rakan kongsi, anda akan menikmati sokongan dan sumber komprehensif yang kami sediakan. Kami akan menyediakan latihan profesional dan sokongan teknikal untuk membantu anda memahami produk dan perkhidmatan kami dengan lebih baik, dan dapat menyediakan pelanggan dengan penyelesaian berkualiti tinggi. Selain itu, anda juga akan menerima sokongan pemasaran kami, termasuk aktiviti pemasaran eksklusif rakan kongsi, bahan pemasaran dan promosi jenama. Kami akan bekerjasama dengan anda untuk bersama-sama mempromosikan pertumbuhan perniagaan dan berkongsi kejayaan.",
        h6: "Kerjasama menang-menang, pembangunan mampan",
        h7: "Kami menghargai kerjasama yang erat dengan rakan kongsi kami dan percaya bahawa melalui usaha bersama dan sokongan bersama, kami boleh mencapai kejayaan yang lebih besar. Kami menyediakan model kerjasama yang fleksibel, termasuk rancangan jualan, pengedaran dan promosi rakan kongsi, untuk mencipta pulangan ekonomi yang kaya untuk rakan kongsi. Pada masa yang sama, kami menggalakkan rakan kongsi untuk memberikan maklum balas dan cadangan yang berharga untuk membantu kami terus menambah baik dan berinovasi, dan membangun bersama. Kami percaya bahawa melalui konsep kerjasama yang sangat kompetitif, kami akan terus membangunkan pasaran yang berdaya saing.",
        h8: "Sertai kami",
        h9: "Jika anda berminat dengan program rakan kongsi kami, kami mengalu-alukan anda untuk menyertai kami. Sama ada anda seorang pemula yang inovatif, penyedia teknologi matang, atau syarikat yang mencari peluang perniagaan baharu, kami bersedia untuk bekerjasama dengan anda dan bersama-sama mencipta jalan menuju kejayaan. Menyertai program rakan kongsi kami, anda akan berpeluang untuk bekerjasama rapat dengan kami, berkongsi sumber dan pengalaman kami, dan bersama-sama mengembangkan situasi pasaran yang menang-menang.",
    },
    dowView: {
        h1: "Muat turun klien",
        h2: "Terokai potensi perdagangan kuantitatif tanpa had dan dengan tenang menangani turun naik pasaran.",
        h3: 'Percubaan percuma',
    },
    contactView: {
        h1: "Hubungi kami, atau buat temujanji untuk melawat pejabat kami",
        h2: "Bilik 608, Bangunan Baotong, Jalan Baomin 1, Jalan Xin'an, Daerah Baoan, Shenzhen",
        h3: 'Rujuk sekarang',
    },
    helpView: {
        h1: "Perlu bantuan lagi?",
        h2: "Tinggalkan mesej kepada pasukan kami dan kami akan menghubungi anda semasa waktu bekerja.",
        h3: 'Hubungi Sokongan',
    },
    token: {
        h1: "Kontrak",
        h2: "Statistik Pasaran",
        h3: 'Kedudukan nilai pasaran',
        h4: "nilai pasaran",
        h5: "100 juta",
        h6: "Jumlah dagangan 24 jam",
        h7: "24 jam tertinggi",
        h8: "Harga Tertinggi Bersejarah",
        h9: "Bekalan maksimum",
        h10: "Pasaran masa nyata Ethereum (ETH)",
        h11: "Harga Ethereum semasa ialah $1,786.68, dan nilainya telah menurun sebanyak 0.31% dalam tempoh 24 jam yang lalu. Ethereum turun 64.33% daripada paras tertinggi sepanjang masanya iaitu $4,878.26. Ethereum menduduki tempat kedua dari segi permodalan pasaran, menyumbang 19.08% daripada keseluruhan pasaran dagangan kripto yang dicantumkan oleh ETH dan Ethereum. ",
        h12: "Apakah itu Ethereum",
        h13: "Ethereum ialah platform rantaian blok terdesentralisasi yang membolehkan pembangun membina aplikasi terdesentralisasi (dApps) di atas rantaian bloknya. Dilancarkan oleh Vitalik Buterin pada 2015, Ethereum telah menjadi salah satu platform rantaian blok paling popular di dunia kerana ekosistemnya yang kukuh, komuniti aktif dan ciri inovatif. Ether (ETH) mempunyai platform dua cara yang paling penting dalam sistem Ethereum. Ethereum ialah kebolehoperasian dan kebolehskalaannya. Yang pertama merujuk kepada keupayaan Ethereum untuk menyambung dan berkomunikasi dengan rangkaian dan platform blockchain lain, menghasilkan kefungsian dan keserasian yang lebih besar. Kebolehskalaan, sebaliknya, merujuk kepada keupayaan Ethereum untuk mengendalikan sejumlah besar urus niaga dan data tanpa menjejaskan prestasi atau keselamatannya. Pada September 2022, Ethereum telah mengalami peningkatan yang paling penting dalam industri yang kompleks yang dikenali sebagai The Meth. peralihan daripada algoritma konsensus Proof-of-Work kepada algoritma Proof-of-Stake yang lebih cekap, dengan matlamat untuk mengurangkan penggunaan tenaga, meningkatkan lagi kebolehskalaan, dan meningkatkan keselamatan. Oleh itu, ini memerlukan staking dan bukannya melombong untuk mengesahkan urus niaga di Ethereum. Walaupun sudah lama ditunggu-tunggu, penggabungan itu hanyalah permulaan siri kemas kini utama yang mesti dilakukan oleh Ethereum, Vitalik.",
        h14: "Mengapa Reaktor ialah pilihan yang tepat untuk perdagangan kuantitatif",
        h15: "Pelaburan terpelbagai",
        h16: "Taburkan dana dalam bursa dan dompet terdesentralisasi, mengurangkan risiko dengan berkesan dan meningkatkan potensi pulangan.",
        h17: "Sokong akaun demo",
        h18: "Sediakan persekitaran simulasi pasaran sebenar, membolehkan anda menjalankan amalan pelaburan dan ujian strategi secara bebas risiko.",
        h19: "Statistik data transaksi",
        h20: "Anda boleh menggunakan robot kami untuk berdagang dengan yakin dan menikmati pengalaman pelaburan yang mudah dan selamat.",
        h21: "Semak imbas aset lain",

        BTC0: 'Bitcoin adalah mata wang kripto terdesentralisasi. Dalam buku putih yang diterbitkan pada tahun 2008, seseorang atau sekumpulan orang yang menggunakan nama samaran Satoshi Nakamoto pertama kali membincangkan mekanisme Bitcoin. Pada Januari 2009, Bitcoin dilancarkan secara rasmi.',

        BTC1: 'Bitcoin adalah mata wang digital yang berdasarkan rangkaian peer-to-peer di mana semua transaksi dilakukan secara langsung antara peserta rangkaian yang setara, tanpa perlu melibatkan pihak tengah untuk kebenaran atau fasilitasi. Seperti yang dinyatakan oleh Satoshi Nakamoto sendiri, penciptaan Bitcoin adalah untuk membolehkan "sebuah pihak dapat membuat pembayaran atas talian kepada pihak yang lain tanpa melalui institusi kewangan".',

        BTC2: 'Sebelum wujudnya Bitcoin, terdapat beberapa konsep mata wang digital terdesentralisasi yang serupa, tetapi keunikannya terletak pada fakta bahawa Bitcoin merupakan mata wang kripto yang pertama kali digunakan dalam kehidupan nyata.',

        ETH0: 'Ethereum adalah sistem blockchain sumber terbuka yang terdesentralisasi dan memiliki mata wang kripto asli bernama Ether. Ethereum juga merupakan platform perdagangan bagi banyak mata wang kripto lainnya dan juga mampu menjalankan kontrak pintar terdesentralisasi.',
        ETH1: 'Vitalik Buterin memperkenalkan Ethereum dalam buku putih yang diterbitkan pada tahun 2013. Bersama-sama dengan para pengasas lainnya, projek Ethereum dilancarkan secara rasmi pada 30 Julai 2015 melalui pendanaan projek secara atas talian pada musim panas tahun 2014.',
        ETH2: 'Ethereum bertujuan untuk menjadi platform aplikasi terdesentralisasi global yang membolehkan pengguna di seluruh dunia menulis dan menjalankan perisian tanpa penyensoran, kerosakan berjadual, atau penipuan.',
        USDT0: 'Tether (USDT) adalah mata wang digital yang nilainya dikaitkan dengan dolar Amerika. Tether dilancarkan pada tahun 2014 dengan matlamat untuk mencipta mata wang kripto stabil yang boleh digunakan seperti dolar digital atau "mata wang stabil". Harga Tether dikaitkan dengan dolar Amerika.',
        USDT1: 'Pada awalnya, Tether menggunakan lapisan Omni dalam rangkaian Bitcoin sebagai protokol penghantaran. Sekarang, ia juga boleh digunakan sebagai token ERC-20 di Ethereum. Tether telah dikeluarkan di rangkaian Bitcoin (protocol Omni dan Liquid), Ethereum, EOS, dan rangkaian TRON.',
        USDT2: 'Tether diterbitkan oleh Tether Limited, sebuah syarikat yang berkongsi CEO dengan pertukaran kripto Bitfinex. Tether sebelum ini mengklaim bahawa mereka mempunyai simpanan 100% dalam bentuk dolar Amerika untuk menyokong Tether. Pada tahun 2019, peguam syarikat tersebut mengesahkan hanya 74% Tether yang disokong oleh simpanan dolar. Justifikasi Tether adalah bahawa pinjaman dari anak syarikat juga merupakan sebahagian daripada simpanan.',
        BNB0: 'Binance Coin dikeluarkan melalui penjualan token pertama pada tahun 2017, dan hanya 11 hari kemudian, Binance Exchange memulakan operasinya. Pada mulanya, Binance Coin dikeluarkan dalam bentuk token ERC-20 di rangkaian Ethereum dengan bekalan maksimum sebanyak 200 juta keping token. Dalam penjualan token pertama tersebut, 100 juta keping token telah dikeluarkan. Namun, dengan pelancaran rangkaian utama Binance Chain pada bulan April 2019, Binance Coin ERC-20 ditukar dengan Binance Coin BEP2 mengikut nisbah 1:1, dan ia tidak lagi bergantung kepada rangkaian Ethereum.',
        BNB1: 'Binance Coin digunakan sebagai kaedah pembayaran di platform Binance Exchange, di mana pengguna dapat menggunakan token utiliti ini untuk membayar yuran transaksi dan juga mengambil bahagian dalam penjualan token pada Binance LaunchPad',
        XRP0: 'Pertama-tama, untuk memahami perbezaan antara Ripple Coin, Ripple, dan RippleNet. Ripple Coin adalah mata wang yang beroperasi di platform pembayaran digital - RippleNet. RippleNet dibangunkan berdasarkan pangkalan data buku akaun teragih yang juga dikenali sebagai Ledger Ripple (XRP). Walaupun RippleNet dikendalikan oleh syarikat Ripple, Ledger Ripple adalah sumber terbuka dan tidak menggunakan teknologi blockchain, tetapi dibangunkan berdasarkan pangkalan data buku akaun teragih seperti yang telah disebutkan sebelumnya.',

        XRP1: 'Platform pembayaran RippleNet adalah sistem pembayaran penuh secara langsung (RTGS) yang bertujuan untuk melaksanakan transaksi mata wang secara segera di peringkat global. Walaupun Ripple Coin adalah mata wang kripto asli bagi Ledger Ripple, pengguna masih boleh menggunakan mana-mana jenis mata wang untuk bertransaksi di platform ini.',
        
        XRP2: 'Konsep di sebalik platform pembayaran Ripple dicetuskan pertama kali oleh Ryan Fugger pada tahun 2004, tetapi hanya sehingga Jed McCaleb dan Chris Larson mengambil alih projek ini pada tahun 2012, rancangan untuk mewujudkan syarikat Ripple dimulakan (pada masa itu, ia juga dikenali sebagai OpenCoin).',
        
        USDC0: 'USDC adalah mata wang stabil yang dihubungkan kepada dolar Amerika dengan nisbah 1:1. Setiap unit mata wang kripto ini didokong oleh simpanan dolar Amerika, termasuk tunai dan bon kerajaan Amerika Syarikat yang jangka pendek. Konsorsium pusat di belakang aset ini menyatakan bahawa USDC dikeluarkan oleh institusi kewangan yang dikawal selia.',
        
        USDC1: 'Mata wang stabil ini mula dikeluarkan secara terhad pada September 2018. Secara ringkasnya, slogan USD Coin adalah "mata wang digital untuk zaman digital" - mata wang stabil ini direka bentuk untuk dunia yang semakin meluas penggunaannya dalam transaksi tanpa tunai.',
        
        USDC2: 'USD Coin telah mempunyai beberapa kes penggunaan. Selain menjadi tempat perlindungan yang selamat bagi peniaga mata wang kripto semasa ketidakstabilan, penggerak di sebalik mata wang stabil ini juga menyatakan bahawa ia boleh digunakan oleh perniagaan untuk menerima pembayaran aset digital dan mencabar pelbagai industri termasuk kewangan terdesentralisasi dan permainan.',
    },
    wallet: {
        h1: "Dompet Urus Niaga Kuantitatif Web3",
        h2: "Buka kunci potensi dagangan tanpa had dan capai pengalaman dagangan kuantitatif yang sangat baik.",
        h3: 'Percubaan percuma',
        h4: "Pelaburan terpelbagai",
        h5: "Keselamatan Aset",
        h6: "Pengalaman dagangan yang pantas dan cekap",
        h7: "Kelebihan Kuantifikasi Dompet Reaktor",
        h8: "Kuantiti dompet menyediakan pengalaman transaksi yang lebih selamat, fleksibel dan diperibadikan",
        h9: "Pengiring keselamatan aset peribadi",
        h10: "Reaktor menggunakan akaun dompet peribadi untuk transaksi, dan anda mempunyai kawalan dan keselamatan yang lebih besar daripada pertukaran.",
        h11: "Kuantiti bersepadu, dengan lancar merentasi pelbagai blok",
        h12: "Reaktor mencapai pemindahan aset dan transaksi yang lancar dengan menyepadukan berbilang rangkaian rantaian blok, tanpa dihadkan oleh satu rantaian, dan memperoleh lebih banyak peluang pelaburan.",
        h13: "Laluan bayaran gas rendah pintar",
        h14: "Reaktor secara automatik boleh menganalisis situasi rangkaian semasa dan memilih laluan dengan bayaran gas terendah untuk melaksanakan transaksi untuk mengurangkan kos transaksi.",
        h15: "Pengiring keselamatan aset peribadi",
        h16: "Reaktor menggunakan akaun dompet peribadi untuk transaksi, dan anda mempunyai kawalan dan keselamatan yang lebih besar daripada pertukaran.",
        h17: "Buat dompet HD utama anda dan mula meneroka dunia Web3",
        h18: "Dompet HD utama ialah identiti unik anda dan boleh disambungkan kepada berbilang dompet bukan jagaan",
        h19: "Melalui dompet HD utama, anda boleh mengurus dan mengawal berbilang dompet bukan jagaan dengan mudah, tanpa membuat dan mengingati kunci peribadi dan kata laluan yang berbeza untuk setiap dompet.",
        h20: "Dompet HD utama menyediakan fungsi statistik terperinci, membolehkan anda memahami sejarah transaksi, pengagihan aset dan pendapatan anda, serta membantu anda membuat keputusan pelaburan yang lebih termaklum.",
        h21: "Dompet HD utama adalah sangat fleksibel dan boleh diperibadikan mengikut keperluan dan pilihan anda, membolehkan anda menyesuaikan dan mengurus aset digital anda dengan lebih baik.",
        h22: "Strategi tersuai",
        h23: "Reaktor boleh menjalankan transaksi tersuai mengikut keperluan dan strategi anda sendiri. Anda boleh menjalankan transaksi kuantitatif mengikut strategi dagangan dan keutamaan risiko anda sendiri tanpa dihadkan oleh API pertukaran",
        h24: "Sokongan kuat untuk persekitaran berbilang rantai",
        h25: "Reaktor mampu berdagang pada rantaian yang berbeza. Ini memberi anda lebih banyak pilihan dan fleksibiliti untuk berdagang mengikut keadaan pasaran dan peruntukan aset",
    },
}