<template>
    <div class="exchangeRobot">
        <div class="box1">
            <div class="topMain">
                <div class="left">
                    <div class="top animate__animated animate__fadeInDown">
                        <div class="leftH1">{{$t('exchangeRobot.h1')}}</div>
                        <div class="leftH2">{{$t('exchangeRobot.h2')}}</div>
                        <div class="leftH3">{{$t('exchangeRobot.h3')}}</div>
                    </div>
                    <div class="btm animate__animated animate__fadeInDown" @click="goFaq">
                        <span>
                            {{$t('exchangeRobot.h4')}}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                    <div class="bom">
                        <div class="leftH2">{{$t('exchangeRobot.h5')}}</div>
                        <div class="leftH3">{{$t('exchangeRobot.h6')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box3">
                <div class="title">
                    <div class="left " v-animate="'animate__animated animate__fadeIn'">
                        <img src="../assets/exchangeRobot/Group 150.svg" alt="">
                    </div>
                    <div class="right">
                        <div class="h1">{{$t('exchangeRobot.h7')}}</div>
                        <div class="h2">{{$t('exchangeRobot.h8')}}</div>
                        <div class="h3 " v-animate="'animate__animated animate__fadeInRight'">
                            <img src="../assets/exchangeRobot/Vector.svg" alt="">{{$t('exchangeRobot.h9')}}
                        </div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInRight'" style="animationDelay:0.3s">
                            <img src="../assets/exchangeRobot/Vector.svg" alt="">{{$t('exchangeRobot.h10')}}
                        </div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInRight'" style="animationDelay:0.6s">
                            <img src="../assets/exchangeRobot/Vector.svg" alt="">{{$t('exchangeRobot.h11')}}
                        </div>
                    </div>
                </div>
                <div class="title2">
                    <div class="right">
                        <div class="h1">{{$t('exchangeRobot.h12')}}</div>
                        <div class="h2">{{$t('exchangeRobot.h13')}}</div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInLeft'">
                            <div class="circular">
                                <div>1</div>
                            </div>
                            {{$t('exchangeRobot.h14')}}
                        </div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.3s">
                            <div class="circular">
                                <div>2</div>
                            </div>{{$t('exchangeRobot.h15')}}
                        </div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.6s">
                            <div class="circular">
                                <div>3</div>
                            </div>{{$t('exchangeRobot.h16')}}
                        </div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.9s">
                            <div class="circular">
                                <div>4</div>
                            </div>{{$t('exchangeRobot.h17')}}
                        </div>
                    </div>
                    <div class="left" v-animate="'animate__animated animate__fadeIn'">
                        <img src="../assets/exchangeRobot/Group 151.svg" alt="">
                    </div>
                </div>

                <div class="title3">
                    <div class="left" v-animate="'animate__animated animate__fadeIn'">
                        <img src="../assets/exchangeRobot/Group 152.svg" alt="">
                    </div>
                    <div class="right">
                        <div class="h1">{{$t('exchangeRobot.h18')}}</div>
                        <div class="h2">{{$t('exchangeRobot.h19')}}</div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInRight'">
                            <div class="circular">
                                <div>1</div>
                            </div>
                            {{$t('exchangeRobot.h20')}}
                        </div>
                        <div class="h3 " v-animate="'animate__animated animate__fadeInRight'" style="animationDelay:0.3s">
                            <div class="circular">
                                <div>2</div>
                            </div>{{$t('exchangeRobot.h21')}}
                        </div>
                        <div class="h3" v-animate="'animate__animated animate__fadeInRight'" style="animationDelay:0.6s">
                            <div class="circular">
                                <div>3</div>
                            </div>{{$t('exchangeRobot.h22')}}
                        </div>
                        <div class="h3 " v-animate="'animate__animated animate__fadeInRight'" style="animationDelay:0.9s">
                            <div class="circular">
                                <div>4</div>
                            </div>{{$t('exchangeRobot.h23')}}
                        </div>
                    </div>
                </div>
                
                <div class="big">
                    <div class="small">
                        <img src="../assets/exchangeRobot/Group 148.svg" alt="">
                        <div class="smallH1">{{$t('exchangeRobot.h24')}}</div>
                        <div class="smallH2">{{$t('exchangeRobot.h25')}}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/exchangeRobot/usp-stay-safe 1.svg" alt="">
                        <div class="smallH1">{{$t('exchangeRobot.h26')}}</div>
                        <div class="smallH2">{{$t('exchangeRobot.h27')}}</div>
                    </div>
                </div>

                <div class="bom">
                    <div class="top">
                        <div class="leftH1">{{$t('exchangeRobot.h28')}}</div>
                        <div class="leftH2">{{$t('exchangeRobot.h29')}}</div>
                    </div>
                    <div class="btm" @click="goFaq2">
                        <span>
                            {{$t('exchangeRobot.h4')}}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { NumSplic } from "../unit/tool";
  import big from "big.js";
  big.NE = -40;
  big.PE = 40;
  export default {
    name: "exchangeRobot",
    data() {
      return {
      };
    },
    methods: {
        goFaq(){
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/jiao-yi-ji-qi-ren/shen-me-shi-jiao-yi-ji-qi-ren', '_blank')
        },
        goFaq2(){
            window.open('https://support.rac.art/jiao-yi/shen-me-shi-dca-ta-de-you-shi-shi-shen-me', '_blank')
        },
    },
    computed: {},
    mounted() {
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $defaultSize: 0.0833333333vw;
  @keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}
  .exchangeRobot {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1{
        padding-top: 88 * $defaultSize;
        display: flex;
        height: 436 * $defaultSize;
        // background-image: url("../assets/signalBGI.png");
        background: url("../assets/robotBGI.png") no-repeat;
        background-size: 100%;
        justify-content: center;
        .left{
            text-align: center;
            .top{
                .leftH1{
                    font-size: 10 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    color: #224FD9;
                }
                .leftH2{
                    margin-top: 10 * $defaultSize;
                    font-size: 40 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                }
                .leftH3{
                    margin-top: 10 * $defaultSize;
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 300;
                    color: #4F5559;
                }
            }
            .bom{
                margin-top: 70 * $defaultSize;
                .leftH2{
                    font-size: 30 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                }
                .leftH3{
                    margin-top: 10 * $defaultSize;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 300;
                    color: #4F5559;
                }
            }
            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 2 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                font-weight: 600;
                color: #FFFFFF;
                img{
                    width: 13 * $defaultSize;
                    color: #FFFFFF;
                }
            }
            .btm:hover{
                    background: #1F41A7;
                    .solidSpan{
                        img{
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }
                }
        }
        .right{
            width: 60%;
            img {
                width: 100%;
            }
        }
    }
    .main {
        width: 100%;
        display: flex;
        justify-content: center;
        .box3{
            margin: 20 * $defaultSize 208 * $defaultSize;
            .title{
                display: flex;
                .left {
                    width: 50%;
                    img{
                        width: 100%;
                        float:right;
                    }
                }
                .right{
                    width: 50%;
                    margin: 60 * $defaultSize 40 * $defaultSize;
                    .h1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 25 * $defaultSize;
                    }
                    .h2{
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: 25 * $defaultSize;
                    }
                    .h3 {
                        display: flex;
                        align-items: center;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: 15 * $defaultSize;
                        img {
                            margin-right: 15 * $defaultSize;
                            width: 15 * $defaultSize;
                        }
                    }
                }
                
            }
            .title2{
                margin-top: 30 * $defaultSize;
                display: flex;
                .left {
                    width: 50%;
                    img{
                        width: 100%;
                    }
                }
                .right{
                    width: 50%;
                    margin: 30 * $defaultSize 40 * $defaultSize;
                    .h1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 25 * $defaultSize;
                    }
                    .h2{
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #4F5559;
                        margin-bottom: 25 * $defaultSize;
                    }
                    .h3 {
                        display: flex;
                        align-items: center;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: 15 * $defaultSize;
                        .circular{
                            display: flex; /* 将容器设置为弹性布局 */
                            align-items: center; /* 将子元素垂直居中对齐 */
                            justify-content: center;
                            margin-right:10 * $defaultSize;
                            div{
                                display: flex; /* 将容器设置为弹性布局 */
                                align-items: center; /* 将子元素垂直居中对齐 */
                                justify-content: center;
                                border-radius: 50%;
                                color: #FFFFFF;
                                font-size: 10 * $defaultSize;
                                background: #224FD9;
                                width: 20 * $defaultSize;
                                height: 20 * $defaultSize;
                            }
                        }
                        img {
                            margin-right: 15 * $defaultSize;
                            width: 15 * $defaultSize;
                        }
                    }
                }
                
            }
            .title3{
                display: flex;
                margin-top: 30 * $defaultSize;
                .left {
                    width: 50%;
                    img{
                        width: 100%;
                    }
                }
                .right{
                    width: 50%;
                    margin: 30 * $defaultSize 40 * $defaultSize;
                    .h1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 25 * $defaultSize;
                    }
                    .h2{
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: 25 * $defaultSize;
                    }
                    .h3 {
                        display: flex;
                        align-items: center;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: 15 * $defaultSize;
                        .circular{
                            display: flex; /* 将容器设置为弹性布局 */
                            align-items: center; /* 将子元素垂直居中对齐 */
                            justify-content: center;
                            margin-right:10 * $defaultSize;
                            div{
                                display: flex; /* 将容器设置为弹性布局 */
                                align-items: center; /* 将子元素垂直居中对齐 */
                                justify-content: center;
                                border-radius: 50%;
                                color: #FFFFFF;
                                font-size: 10 * $defaultSize;
                                background: #224FD9;
                                width: 20 * $defaultSize;
                                height: 20 * $defaultSize;
                            }
                        }
                        img {
                            margin-right: 15 * $defaultSize;
                            width: 15 * $defaultSize;
                        }
                    }
                }
                
            }
            .big{
                margin-top: 50 * $defaultSize;
                display: flex;
                justify-content: space-between;
                .small{
                    width: 48%;
                    text-align: left;
                    border: 2px solid #E8EBEF;
                    border-radius: 20 * $defaultSize;
                    opacity: 1;
                    // border: 1px solid #CCCCCC;
                    padding: 25 * $defaultSize;
                    img {
                        width: 75 * $defaultSize;
                    }
                    .smallH1{
                        margin-top: 10 * $defaultSize;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 19 * $defaultSize;
                    }
                    .smallH2{
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
                .small:hover{
                    border: 2px solid #224FD9;
                }
            }
            .bom{
                margin-top: 70 * $defaultSize;
                background: url("../assets/exchangeRobot/bom.svg") no-repeat;
                background-size: 100%;
                height: 200 * $defaultSize;
                text-align: center;
                padding: 30 * $defaultSize;
                .top{
                    .leftH1{
                        font-size: 25 * $defaultSize;
                        font-family: Inter-Medium, Medium;
                        font-weight: 500;
                        color: #333333;
                    }
                    .leftH2{
                        margin-top: 20 * $defaultSize;
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Regular;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
                .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 15 * $defaultSize;
                font-weight: 400;
                color: #FFFFFF;
                img{
                    width: 13 * $defaultSize;
                    color: #FFFFFF;
                }
            }
            .btm:hover{
                    background: #1F41A7;
                    .solidSpan{
                        img{
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }
                }
            }
        }
        
    }
  }
  </style>
  
  