<template>
    <div class="topbar">
        <div class="top" v-if="topOpen">
            <div class="topDiv">
                <span>{{ $t('topBar.linkWallet') }}<span class="golinkWallet" >{{
                    $t('topBar.linkWallet2') }}</span></span>
                <span class="span1" @click="closeTop">X</span>
            </div>
        </div>
        <div class="btm" :class="{ 'top-zero': isTopZero }" ref="bottomDiv"
            :style="`${num === 1 ? 'background-color: #000' : 'background-color: #122D82;'}`">
            <div class="left" @click="goHome">
                <img src="../assets/home_logo_reactor@2x.png" alt="">
                <span>Reactor</span>
            </div>
            <div class="centerDiv">
                <div class="center">
                    <div class="btn" @click="goHome">
                        <div class="text">
                            {{ $t('topBar.h1') }}
                        </div>
                    </div>
                    <div class="btn" :class="isHovered ? 'chanpin' : ''" @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave" @click="handleClick">
                        <div class="text">
                            {{ $t('topBar.h2') }}
                        </div>
                        <div class="show chanpinShow">
                            <div class="arrow"></div>
                            <div class="row animate__animated animate__fadeInUp">
                                <div class="title">{{ $t('topBar.h2_1') }}</div>
                                <div class="big">
                                    <div class="small" @click="goPolicyBacktest">
                                        <img src="../assets/icon_track@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_1_1') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_1_2') }}</div>
                                        </div>
                                    </div>
                                    <div class="small" @click="goManualTrading">
                                        <img src="../assets/icon_trade@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_1_3') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_1_4') }}</div>
                                        </div>
                                    </div>
                                    <div class="small" @click="goChainData">
                                        <img src="../assets/icon_data@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_1_5') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_1_6') }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row aaa animate__animated animate__fadeInUp">
                                <div class="title">{{ $t('topBar.h2_2') }}</div>
                                <div class="big bbb">
                                    <div class="small" @click="goCopyRobot">
                                        <img src="../assets/icon_copy@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_2_1') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_2_2') }}</div>
                                        </div>
                                    </div>
                                    <div class="small" @click="goExchangeRobot">
                                        <img src="../assets/icon_tradebot@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_2_3') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_2_4') }}</div>
                                        </div>
                                    </div>
                                    <div class="small" @click="goMerchantRobot">
                                        <img src="../assets/icon_demo@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_2_5') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_2_6') }}</div>
                                        </div>
                                    </div>
                                    <div class="small" @click="goInterestRobot">
                                        <img src="../assets/icon_ia@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_2_7') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_2_8') }}</div>
                                        </div>
                                    </div>
                                    <div class="small" @click="goDemoRobot">
                                        <img src="../assets/icon_dca@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_2_9') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_2_10') }}</div>
                                        </div>
                                    </div>
                                    <!-- <div class="small"></div> -->
                                </div>
                            </div>
                            <div class="row animate__animated animate__fadeInUp">
                                <div class="title">{{ $t('topBar.h2_3') }}</div>
                                <div class="big" style="margin-bottom: 0px;">
                                    <div class="small" @click="goSignal">
                                        <img src="../assets/icon_signal@2x.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_3_1') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_3_2') }}</div>
                                        </div>
                                    </div>
                                    <div class="small" @click="goStrategyMall">
                                        <img src="../assets/icon_tradebot1.png" alt="">
                                        <div class="info">
                                            <div class="name">{{ $t('topBar.h2_3_3') }}
                                                <img class="animate__animated animate__fadeInLeft"
                                                    src="../assets/topbar/home_icon_more_normal.svg" />
                                            </div>
                                            <div class="explain">{{ $t('topBar.h2_3_4') }}</div>
                                        </div>
                                    </div>
                                    <!-- <div class="small">
                                    <img src="../assets/icon_tradebot2.png" alt="">
                                    <div class="info">
                                        <div class="name">{{$t('topBar.h2_3_5')}}</div>
                                        <div class="explain">{{$t('topBar.h2_3_6')}}</div>
                                    </div>
                                </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 市场 -->
                    <div class="btn" :class="isHovered ? 'shichang' : ''" @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave" @click="handleClick">
                        <div class="text">
                            {{ $t('topBar.h3') }}
                        </div>
                        <div class="show shichangShow">
                            <div class="arrow"></div>
                            <div class="box1">
                                <div class="shichangTop animate__animated animate__fadeInUp">
                                    <div class="title">{{ $t('topBar.h3_1') }}</div>
                                    <div v-if="currencyList.length > 0" class="shichangTopMain">
                                        <div class="small" @click="goToken(data, index)"
                                            v-for="(data, index) in currencyList" :key="index">
                                            <!-- <img :src="data.logo" alt=""> -->
                                            <img src="shichangSVG/BTC.svg" alt="" v-if="data.name == 'BTC'">
                                            <img src="shichangSVG/eth.svg" alt="" v-if="data.name == 'ETH'">
                                            <img src="shichangSVG/USDT.svg" alt="" v-if="data.name == 'USDT'">
                                            <img src="shichangSVG/BNB.svg" alt="" v-if="data.name == 'BNB'">
                                            <img src="shichangSVG/XRP.svg" alt="" v-if="data.name == 'XRP'">
                                            <img src="shichangSVG/USDC.svg" alt="" v-if="data.name == 'USDC'">
                                            <img src="shichangSVG/okx.svg" alt="" v-if="data.name == 'OKB'">
                                            <img src="shichangSVG/DOGE.svg" alt="" v-if="data.name == 'DOGE'">
                                            <img src="shichangSVG/ADA.svg" alt="" v-if="data.name == 'ADA'">
                                            <img src="shichangSVG/sol.svg" alt="" v-if="data.name == 'SOL'">
                                            <div class="info">
                                                <div class="infoTop">
                                                    <div class="infoTopLeft">{{ data.name }} <span>{{ data.name }}</span>
                                                        <!-- {{ data.code }} -->
                                                    </div>
                                                    <div class="infoTopRight">{{ data.change_percent }}%</div>
                                                </div>
                                                <!-- <div class="infoBtm">${{ (data.market_value_usd / 10000).toFixed(2) }}w -->
                                                <div class="infoBtm">${{ data.current_price_usd }}
                                                </div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="currencyList.length == 0" class="shichangTopMain">
                                        <div class="small" @click="goToken({}, 0)">
                                            <img src="../assets/btc.png" alt="">
                                            <div class="info" @click="goToken({}, 0)">
                                                <div class="infoTop">
                                                    <div class="infoTopLeft">BTC <span>Ethereum</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>
                                        <div class="small" @click="goToken({}, 1)">
                                            <img src="../assets/eth.png" alt="">
                                            <div class="info">
                                                <div class="infoTop" @click="goToken({}, 1)">
                                                    <div class="infoTopLeft">ETH <span>Ethereum</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>
                                        <div class="small" @click="goToken({}, 2)">
                                            <img src="../assets/bnb.png" alt="">
                                            <div class="info">
                                                <div class="infoTop" @click="goToken({}, 2)">
                                                    <div class="infoTopLeft">BNB <span>Ethereum</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>
                                        <div class="small" @click="goToken({}, 3)">
                                            <img src="../assets/trx.png" alt="">
                                            <div class="info">
                                                <div class="infoTop" @click="goToken({}, 3)">
                                                    <div class="infoTopLeft">TRX <span>TRON</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>

                                        <div class="small" @click="goToken({}, 4)">
                                            <img src="../assets/sui.png" alt="">
                                            <div class="info">
                                                <div class="infoTop" @click="goToken({}, 4)">
                                                    <div class="infoTopLeft">SUI <span>SUI</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>


                                        <div class="small" @click="goToken({}, 5)">
                                            <img src="../assets/arb.png" alt="">
                                            <div class="info" @click="goToken({}, 5)">
                                                <div class="infoTop">
                                                    <div class="infoTopLeft">ARB <span>Arbitrum</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>

                                        <div class="small" @click="goToken({}, 6)">
                                            <img src="../assets/op.png" alt="">
                                            <div class="info" @click="goToken({}, 6)">
                                                <div class="infoTop">
                                                    <div class="infoTopLeft">OP <span>Optimism</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>

                                        <div class="small" @click="goToken({}, 7)">
                                            <img src="../assets/sol.png" alt="">
                                            <div class="info" @click="goToken({}, 7)">
                                                <div class="infoTop">
                                                    <div class="infoTopLeft">Solana <span>SOL</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>

                                        <div class="small" @click="goToken({}, 8)">
                                            <img src="../assets/bnb.png" alt="">
                                            <div class="info" @click="goToken({}, 8)">
                                                <div class="infoTop">
                                                    <div class="infoTopLeft">USDC <span>CORE</span></div>
                                                    <div class="infoTopRight">-2.93%</div>
                                                </div>
                                                <div class="infoBtm">$25,686.00</div>
                                                <button class="infoButton">查看</button>
                                            </div>
                                        </div>


                                    </div>

                                    <div class="shichangMore" @click="goMarket">
                                        {{ $t('topBar.h3_3') }}
                                        <img class="animate__animated animate__fadeInLeft"
                                            src="../assets/topbar/home_icon_more_normal.svg" />
                                    </div>

                                </div>
                                <div class="shichangBtm animate__animated animate__fadeInUp">
                                    <div class="title">{{ $t('topBar.h3_2') }}</div>
                                    <div class="shichangBtmMain">


                                        <!-- uniswap -->
                                        <div class="small" @click="goExchange4">
                                            <div class="up">
                                                <div class="upLeft"><img src="shichangSVG/UniSwap.svg" alt=""> {{
                                                    $t('topBar.h3_2_9') }}</div>
                                                <!-- <div class="upRight">
                                                    <div class="upRight1">24h Vol</div>
                                                    <div class="upRight2">5,612,141,999 {{ $t('topBar.unit') }}</div>
                                                </div> -->
                                            </div>
                                            <div class="info">
                                                {{ $t('topBar.h3_2_10') }}
                                            </div>
                                            <button class="infoButton">查看</button>
                                        </div>




                                        <div class="small" @click="goExchange3">
                                            <div class="up">
                                                <div class="upLeft"><img src="shichangSVG/Pancaking.svg" alt=""> <span
                                                        class="ssss">{{ $t('topBar.h3_2_7') }}</span> </div>
                                                <!-- <div class="upRight">
                                                    <div class="upRight1">24h Vol</div>
                                                    <div class="upRight2">5,612,141,999 {{ $t('topBar.unit') }}</div>
                                                </div> -->
                                            </div>
                                            <div class="info">
                                                {{ $t('topBar.h3_2_8') }}
                                            </div>
                                            <button class="infoButton">查看</button>
                                        </div>


                                        <!-- 币安 -->
                                        <div class="small" @click="goExchange">
                                            <div class="up">
                                                <div class="upLeft"><img src="shichangSVG/binance.svg" alt="">
                                                    {{ $t('topBar.h3_2_1') }}</div>
                                                <!-- <div class="upRight">
                                                    <div class="upRight1">24h Vol</div>
                                                    <div class="upRight2">5,612,141,999 {{ $t('topBar.unit') }}</div>
                                                </div> -->
                                            </div>
                                            <div class="info">
                                                {{ $t('topBar.h3_2_2') }}
                                            </div>
                                            <button class="infoButton">查看</button>
                                        </div>


                                        <!-- <div class="small" @click="goExchange1">
                                            <div class="up">
                                                <div class="upLeft"><img src="../assets/huobi.png" alt="">
                                                    {{ $t('topBar.h3_2_3') }}</div>
                                                <div class="upRight">
                                                    <div class="upRight1">24h Vol</div>
                                                    <div class="upRight2">5,612,141,999 {{ $t('topBar.unit') }}</div>
                                                </div>
                                            </div>
                                            <div class="info">
                                                {{ $t('topBar.h3_2_4') }}
                                            </div>
                                            <button class="infoButton">查看</button>
                                        </div> -->

                                        <div class="small" @click="goExchange2">
                                            <div class="up">
                                                <div class="upLeft"><img src="shichangSVG/okx.svg" alt=""> {{
                                                    $t('topBar.h3_2_5')
                                                }}
                                                </div>
                                                <!-- <div class="upRight">
                                                    <div class="upRight1">24h Vol</div>
                                                    <div class="upRight2">5,612,141,999 {{ $t('topBar.unit') }}</div>
                                                </div> -->
                                            </div>
                                            <div class="info">
                                                {{ $t('topBar.h3_2_6') }}
                                            </div>
                                            <button class="infoButton">查看</button>
                                        </div>



                                        <div class="shichangMore" @click="goMarket2">
                                            {{ $t('topBar.h3_4') }}
                                            <img class="animate__animated animate__fadeInLeft"
                                                src="../assets/topbar/home_icon_more_normal.svg" />
                                        </div>


                                        <!-- <div class="small" @click="goExchange5">
                                            <div class="up">
                                                <div class="upLeft"><img src="../assets/dodo.png" alt="">
                                                    {{ $t('topBar.h3_2_11') }}</div>
                                                <div class="upRight">
                                                    <div class="upRight1">24h Vol</div>
                                                    <div class="upRight2">5,612,141,999 {{ $t('topBar.unit') }}</div>
                                                </div>
                                            </div>
                                            <div class="info">
                                                {{ $t('topBar.h3_2_12') }}
                                            </div>
                                            <button class="infoButton">查看</button>
                                        </div> -->


                                    </div>

                                </div>
                            </div>
                            <!-- <div class="shichangMore" @click="goMarket">
                                {{ $t('topBar.h3_3') }}
                                <img class="animate__animated animate__fadeInLeft"
                                    src="../assets/topbar/home_icon_more_normal.svg" />
                            </div> -->
                        </div>
                    </div>
                    <!-- 企业 -->
                    <div class="btn" :class="isHovered ? 'qiye' : ''" @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave" @click="handleClick">
                        <div class="text">
                            {{ $t('topBar.h5') }}
                        </div>
                        <div class="show qiyeShow">
                            <div class="arrow"></div>
                            <div class="small animate__animated animate__fadeInUp" @click="goAbout">
                                <div class="title">{{ $t('topBar.h5_1') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h5_2') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goOem">
                                <div class="title">{{ $t('topBar.h5_3') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h5_4') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goBusiness">
                                <div class="title">{{ $t('topBar.h5_5') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h5_6') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goCommunity">
                                <div class="title">{{ $t('topBar.h5_7') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h5_8') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goCooperation">
                                <div class="title">{{ $t('topBar.h5_9') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h5_10') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goContact">
                                <div class="title">{{ $t('topBar.h5_11') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h5_12') }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 更多 -->
                    <div class="btn" :class="isHovered ? 'other' : ''" @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave" @click="handleClick">
                        <div class="text">
                            {{ $t('topBar.h9') }}
                        </div>
                        <div class="show otherShow">
                            <div class="arrow"></div>
                            <div class="small animate__animated animate__fadeInUp" @click="goRebate">
                                <div class="title">{{ $t('topBar.h9_1') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h9_2') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="linkWaller">
                                <div class="title">{{ $t('topBar.h9_3') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h9_4') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goStrategy">
                                <div class="title">{{ $t('topBar.h9_5') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h9_6') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goDca">
                                <div class="title">{{ $t('topBar.h9_7') }}
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">{{ $t('topBar.h9_8') }}</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="golinkWallet">
                                <div class="title">IP形象
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">Quant X是我们品牌的象征，代表了创新、智能和可信赖。</div>
                            </div>
                            <div class="small animate__animated animate__fadeInUp" @click="goziyuan">
                                <div class="title">资源下载
                                    <img class="animate__animated animate__fadeInLeft"
                                        src="../assets/topbar/home_icon_more_normal.svg" />
                                </div>
                                <div class="hint">提供一系列高质量的资源，供您下载和使用，以便更好地推广我们的产品和服务。</div>
                            </div>
                        </div>
                    </div>
                    <div class="btn" @click="goJoin">
                        <div class="text">
                            {{ $t('topBar.h4') }}
                        </div>
                    </div>
                    <div class="btn" @click="goHelpCenter">
                        <div class="text">
                            {{ $t('topBar.h6') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="lang">
                    <el-select v-model="langSelect" class="el-input__inner" @change="changelang">
                        <el-option value="cn" :label="$t('topBar.h7_1')">{{ $t('topBar.h7_1') }}</el-option>
                        <el-option value="en" :label="$t('topBar.h7_2')">{{ $t('topBar.h7_2') }}</el-option>
                        <el-option value="jp" :label="$t('topBar.h7_3')">{{ $t('topBar.h7_3') }}</el-option>
                        <el-option value="ko" :label="$t('topBar.h7_4')">{{ $t('topBar.h7_4') }}</el-option>
                        <el-option value="tra" :label="$t('topBar.h7_5')">{{ $t('topBar.h7_5') }}</el-option>
                        <el-option value="thai" :label="$t('topBar.h7_6')">{{ $t('topBar.h7_6') }}</el-option>
                        <el-option value="malay" :label="$t('topBar.h7_7')">{{ $t('topBar.h7_7') }}</el-option>
                        <el-option value="vietnam" :label="$t('topBar.h7_8')">{{ $t('topBar.h7_8') }}</el-option>
                    </el-select>
                </div>
                <div class="link" @click="linkWaller" v-if="myAddress == undefined">{{ $t('topBar.h8') }}</div>
                <div class="link" v-else>{{ myAddress }}</div>
                <div class="downLoad">
                    <img class="deImg" src="../assets/home_icon_win_normal.png" alt="">
                    <img class="deImg" src="../assets/home_icon_apple_normal.png" alt="">
                    <div class="show downLoadShow">
                        <div class="arrow"></div>
                        <div class="dowImg">
                            <img src="../assets/topbar/Group.svg" />
                        </div>
                        <div class="dowText">
                            扫码使用DAPP
                        </div>
                        <div class="dowBtm" @click="asdaa">
                            客户端下载
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'topbar',

    data() {
        return {
            showLeft: false,
            myAddress: undefined,
            langChange: false,
            langSelect: "简体中文",
            currencyList: [],
            topOpen: true,
            isTopZero: false,
            zhi: null,
            isHovered: false,
            num: 0,
        };
    },
    mounted() {
        // this.loadingData();
        this.$eventHub.$on("walletChanged", () => {
            this.loadingData();
        });
        if (this.$route.name == 'strategy') {
            this.num = 1;
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === 'strategy') {
                this.num = 1;
            } else {
                this.num = 0;
            }
        },
    },
    created() {
        this.hideElements();
        this.fetchData();
    },
    methods: {
        golinkWallet() {
            // window.open('https://support.rac.art/xin-shou-zhi-dao/ru-he-kai-shi-wo-de-shou-ci-jiao-yi', '_blank')
            if (this.$router.currentRoute.path === '/reactor') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/reactor",
            });
        },
        goziyuan() {
            // window.open('https://support.rac.art/xin-shou-zhi-dao/ru-he-kai-shi-wo-de-shou-ci-jiao-yi', '_blank')
            if (this.$router.currentRoute.path === '/ziyuan') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/ziyuan",
            });
        },
        asdaa() {
            if (this.$router.currentRoute.path === '/download') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/download",
            });
        },
        handleMouseEnter() {
            this.isHovered = true;
        },
        handleMouseLeave() {
            this.isHovered = false;
        },
        handleClick() {
            this.isHovered = !this.isHovered;
        },
        handleHover() {
            if (!this.isHovered) {
                this.isHovered = true;
            }
        },
        handleUnhover() {
            if (this.isHovered) {
                this.isHovered = false;
            }
        },
        closeTop() {
            this.topOpen = false;
            this.isTopZero = true;
            this.$store.commit('SET_Dialog', false)
            // const divElement = this.$refs.bottomDiv;
            // divElement.style.top = '0px';
        },
        goBusiness() {
            if (this.$router.currentRoute.path === '/business') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/business",
            });
        },
        goDca() {
            if (this.$router.currentRoute.path === '/DCA') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/DCA",
            });
        },
        goStrategy() {
            if (this.$router.currentRoute.path === '/strategy') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/strategy",
            });
        },
        goRebate() {
            if (this.$router.currentRoute.path === '/rebate') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/rebate",
            });
        },
        // 查询货币列表
        fetchData() {
            // 发起GET请求
            axios.get('https://ow.rac.art/api/coin/web-coinrank?page=1&type=-1&pagesize=10&webp=1')
                .then(response => {
                    this.currencyList = response.data.data;
                })
                .catch(error => {
                });
        },
        goStrategyMall() {
            if (this.$router.currentRoute.path === '/strategyMall') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/strategyMall",
            });
        },
        goSignal() {

            if (this.$router.currentRoute.path === '/signal') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/signal",
            });
        },
        hideElements() {
            const eml = document.getElementsByClassName('show');
            for (let i = 0; i < eml.length; i++) {
                eml[i].style.display = 'none';
            }
        },
        goMerchantRobot() {
            if (this.$router.currentRoute.path === '/merchantRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/merchantRobot",
            });
        },
        goDemoRobot() {
            if (this.$router.currentRoute.path === '/demoRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/demoRobot",
            });
        },
        goInterestRobot() {
            if (this.$router.currentRoute.path === '/interestRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/interestRobot",
            });
        },
        goExchangeRobot() {
            if (this.$router.currentRoute.path === '/exchangeRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchangeRobot",
            });
        },
        goPolicyBacktest() {
            if (this.$router.currentRoute.path === '/policyBacktest') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/policyBacktest",
            });
        },
        goManualTrading() {
            if (this.$router.currentRoute.path === '/manualTrading') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/manualTrading",
            });
        },
        goChainData() {
            if (this.$router.currentRoute.path === '/chainData') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/chainData",
            });
        },
        goCopyRobot() {
            if (this.$router.currentRoute.path === '/copyRobot') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/copyRobot",
            });
        },
        goHelpCenter() {
            if (this.$router.currentRoute.path === '/helpCenter') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/helpCenter",
            });
        },
        goCooperation() {
            if (this.$router.currentRoute.path === '/cooperation') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/cooperation",
            });
        },
        goCommunity() {
            if (this.$router.currentRoute.path === '/community') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/community",
            });
        },
        goContact() {
            window.open('https://tianyi.art/contact/inquiry', '_blank');
        },
        goOem() {
            window.open('https://tianyi.art', '_blank');
        },
        goAbout() {
            if (this.$router.currentRoute.path === '/about') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/about",
            });
        },
        goJoin() {
            if (this.$router.currentRoute.path === '/join') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/join",
            });
        },
        goMarket() {
            if (this.$router.currentRoute.path === '/market') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/market",
            });
        },
        goMarket2() {
            if (this.$router.currentRoute.path === '/market2') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/market2",
            });
        },
        goExchange() {
            if (this.$router.currentRoute.path === '/exchange') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchange",
            });
        },
        goExchange1() {
            if (this.$router.currentRoute.path === '/exchange1') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchange1",
            });
        },
        goExchange2() {
            if (this.$router.currentRoute.path === '/exchange2') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchange2",
            });
        },
        goExchange3() {
            if (this.$router.currentRoute.path === '/exchange3') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchange3",
            });
        },
        goExchange4() {
            if (this.$router.currentRoute.path === '/exchange4') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchange4",
            });
        },
        goExchange5() {
            if (this.$router.currentRoute.path === '/exchange5') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/exchange5",
            });
        },
        goToken(code, n) {
            console.log(code);
            console.log(n);
            if (this.$router.currentRoute.path === '/token' && this.zhi == n) {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.zhi = n;
            this.$router.push({
                path: "/token",
                query: { asd: code.name, zhi: this.zhi },
            });
        },
        goHome() {
            if (this.$router.currentRoute.path === '/') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/",
            });
        },
        changelang(e) {
            this.selectLang = false;
            this.$i18n.locale = e;

            this.$store.commit('SET_IsAnimation', false)
            this.$nextTick(() => {
                this.$store.commit('SET_IsAnimation', true)
            })
        },
        goto(e) {
            if (e == this.$route.path) {
                return
            }
            this.$router.push(e)
            this.showLeft = false
        },
        linkWaller() {
            if (this.$router.currentRoute.path === '/wallet') {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.$router.push({
                path: "/wallet",
            });
        },
        loadingData() {
            this.$contract.connectWallet().finally(() => {
                let address = this.$contract.getCurrWalletAddress();
                // console.log(address);
                // this.create();
                if (address != undefined) {
                    this.myAddress = address.substr(0, 4) + "..." + address.substr(38, 4);
                } else {
                    this.myAddress = address;
                }
            });
        },

    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        test() {

        }
    }
};
</script>

<style lang="scss" scoped>
.ssss {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
}

$defaultSize: 0.0833333333vw;

.top-zero {
    top: 0px !important;
}

::v-deep .el-input__inner {
    border: none;
    box-shadow: none;
    background: none;
    width: 67%;
}

::v-deep .el-input {
    .el-input__inner {
        color: #9FB2ED;
        width: 100%;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 10 * $defaultSize;
        font-weight: 500;
        padding-left: 23%;
    }
}

@keyframes tracking-in-top {
    0% {
        height: 0 * $defaultSize;
    }

    100% {
        height: 40 * $defaultSize;
    }
}

@keyframes tracking-in-top-fix {
    0% {
        top: 0 * $defaultSize;
    }

    100% {
        top: 40 * $defaultSize;
    }
}

@keyframes tracking-in-show {
    0% {
        opacity: 0;
        // transform: translateY(-10 * $defaultSize);
    }

    100% {
        opacity: 1;
        // transform: translateY(0 * $defaultSize);
    }
}

.topbar {

    .top {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 10000000;

        .topDiv {
            width: 100%;
            height: 0;
            background-color: #03A76A;
            animation: tracking-in-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            font-size: 13 * $defaultSize;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 40 * $defaultSize;
            overflow: hidden;
            text-align: center;

            .golinkWallet {
                cursor: pointer;
            }

            .golinkWallet:hover {
                cursor: pointer;
                text-decoration: underline;
            }

            .span1 {
                cursor: pointer;
                position: absolute;
                right: 50px;
            }
        }

    }

    .btm {

        background-color: #122D82;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9 * $defaultSize;
        position: fixed;
        top: 0;
        margin-bottom: 40 * $defaultSize;
        left: 0;
        right: 0;
        z-index: 10000000;
        animation: tracking-in-top-fix 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

        .left {
            cursor: pointer;
            margin-left: 30px;

            img {
                width: 25 * $defaultSize;
                vertical-align: middle;
                margin-right: 5 * $defaultSize;
            }

            span {
                font-size: 20 * $defaultSize;
                font-weight: 600;
                color: #fff;
                vertical-align: middle;
            }
        }

        .centerDiv {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .center {
                height: 30 * $defaultSize;
                display: flex;

                .btn {
                    .text {
                        height: 100%;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    padding: 0 12.5 * $defaultSize;
                    font-size: 11 * $defaultSize;
                    font-family: PingFangSC-Semibold,
                    PingFang SC;
                    font-weight: 600;
                    color: #9FB2ED;
                    height: 30 * $defaultSize;

                    .show {
                        display: none;
                        position: absolute;
                        width: 750* $defaultSize;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #FFFFFF;
                        box-shadow: 4px 6px 34px 0px rgba(117, 141, 168, 0.51);
                        border-radius: 10 * $defaultSize;
                        z-index: 9999999;
                    }
                }

                .btn:hover {
                    color: #FFFFFF;
                }

                .chanpinShow {
                    // display: block !important;
                    width: 650* $defaultSize !important;
                    padding: 20 * $defaultSize ;

                    .arrow {
                        position: absolute;
                        top: 5px;
                        left: 35%;
                        transform: translate(-50%, -27%) rotateZ(45deg);
                        width: 30px;
                        height: 30px;
                        background-color: white;
                        border-radius: 5px;
                    }

                    .aaa {
                        margin: 20px 0px !important;
                    }

                    .row {
                        width: 100%;

                        .title {
                            font-size: 10 * $defaultSize;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #A0A4A7;
                            margin-bottom: 10px;
                        }

                        .bbb {
                            height: 110 * $defaultSize;
                        }

                        .big {
                            display: flex;
                            flex-wrap: wrap;


                            .small {
                                cursor: pointer;
                                width: 33.3%;
                                display: flex;
                                align-items: center;
                                padding: 3 * $defaultSize 3 * $defaultSize;
                                height: 50 * $defaultSize;

                                img {
                                    width: 39 * $defaultSize;
                                    margin-right: 10 * $defaultSize;
                                }

                                .info {

                                    .name {
                                        font-size: 14 * $defaultSize;
                                        font-family: PingFangSC-Semibold, PingFang SC;
                                        font-weight: 600;
                                        color: #333333;
                                        display: flex;

                                        img {
                                            animation-duration: 0.5s;
                                            display: none;
                                            width: 10 * $defaultSize;
                                            margin-left: 5 * $defaultSize;
                                        }
                                    }

                                    .explain {
                                        font-size: 11 * $defaultSize;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #A0A4A7;
                                        margin-top: 5 * $defaultSize;
                                    }
                                }
                            }

                            .small:hover {
                                .info {
                                    .name {
                                        color: #1F4DD8;

                                        img {
                                            display: block;
                                        }
                                    }

                                    .explain {
                                        color: #333333;
                                    }
                                }
                            }
                        }
                    }
                }

                .chanpin:hover {

                    // .chanpin {
                    .chanpinShow {
                        animation: tracking-in-show 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        display: block;
                    }
                }

                .shichangShow {
                    // display: block !important;


                    .arrow {
                        position: absolute;
                        top: 5px;
                        left: 43.5%;
                        transform: translate(-50%, -27%) rotateZ(45deg);
                        width: 30px;
                        height: 30px;
                        background-color: white;
                        border-radius: 5px;
                    }

                    .box1 {

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .shichangTop {
                            padding: 26 * $defaultSize 25 *$defaultSize;
                            border-right: 1px #D8D8D8 solid !important;
                            width: 65%;

                            .title {
                                font-size: 10 * $defaultSize;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #A0A4A7;
                                margin-bottom: 20px;
                            }

                            .shichangTopMain {

                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;

                                .small {
                                    height: 50 * $defaultSize;
                                    padding: 10 * $defaultSize 10 * $defaultSize;
                                    width: 46%;
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 12 * $defaultSize;

                                    img {
                                        width: 27 * $defaultSize;
                                        margin-right: 10 * $defaultSize;
                                    }

                                    .info {
                                        width: 100%;
                                        position: relative;
                                        cursor: pointer;

                                        &:hover .infoButton {
                                            display: block;
                                        }

                                        &:hover .infoTopRight {
                                            display: none;
                                        }

                                        .infoTop {
                                            display: flex;
                                            justify-content: space-between;

                                            .infoTopLeft {
                                                font-size: 14 * $defaultSize;
                                                font-family: PingFangSC-Semibold, PingFang SC;
                                                font-weight: 600;
                                                color: #333333;

                                                span {
                                                    font-size: 14 * $defaultSize;
                                                    font-family: PingFangSC-Semibold, PingFang SC;
                                                    font-weight: 500;
                                                    color: #A0A4A7;
                                                }
                                            }

                                            .infoTopRight {
                                                font-size: 11 * $defaultSize;
                                                font-family: PingFangSC-Semibold, PingFang SC;
                                                font-weight: 600;
                                                color: #FC3B38;
                                            }
                                        }

                                        .infoBtm {
                                            font-size: 11 * $defaultSize;
                                            font-family: PingFangSC-Regular, PingFang SC;
                                            font-weight: 400;
                                            color: #A0A4A7;
                                            margin-top: 2 * $defaultSize;
                                        }

                                        .infoButton {
                                            cursor: pointer;
                                            display: none;
                                            position: absolute;
                                            top: 50%;
                                            right: 0;
                                            transform: translateY(-50%);
                                            width: 35 * $defaultSize;
                                            height: 20 * $defaultSize;
                                            font-family: PingFangSC-Regular, PingFang SC;
                                            font-weight: 400;
                                            color: #ffffff;
                                            background-color: #183ead;
                                            border: 0;
                                            border-radius: 5 * $defaultSize;
                                        }
                                    }
                                }

                            }

                            .shichangMore {
                                cursor: pointer;
                                text-align: center;
                                font-size: 14 * $defaultSize;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #1e4dd8;
                                margin-top: 15 * $defaultSize;
                            }

                            .shichangMore:hover {
                                color: #183ead;
                            }
                        }

                        .shichangBtm {
                            width: 35%;
                            height: 100% !important;
                            padding: 26 * $defaultSize 25 *$defaultSize;

                            .title {
                                font-size: 10 * $defaultSize;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #A0A4A7;
                                margin-top: -12px;
                                padding: 0 * $defaultSize 10 * $defaultSize;
                            }

                            .shichangBtmMain {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;

                                .small {
                                    padding: 10 * $defaultSize 10 * $defaultSize;
                                    position: relative;
                                    width: 100%;
                                    align-items: center;
                                    cursor: pointer;

                                    &:hover .infoButton {
                                        cursor: pointer;
                                        display: block;
                                    }

                                    &:hover .upRight {
                                        display: none;
                                    }

                                    .up {
                                        display: flex;
                                        justify-content: space-between;

                                        .upLeft {
                                            display: flex;
                                            align-items: center;
                                            font-size: 14 * $defaultSize;
                                            font-family: PingFangSC-Semibold, PingFang SC;
                                            font-weight: 600;
                                            color: #333333;

                                            img {
                                                width: 27 * $defaultSize;
                                                margin-right: 5 * $defaultSize;
                                            }
                                        }

                                        .upRight {
                                            text-align: right;

                                            .upRight1 {
                                                font-size: 9 * $defaultSize;
                                                color: #999;
                                            }

                                            .upRight2 {

                                                font-size: 11 * $defaultSize;
                                                color: #333;
                                            }
                                        }
                                    }

                                    .info {
                                        margin-top: 6 * $defaultSize;
                                        font-size: 10 * $defaultSize;
                                        font-family: PingFangSC-Semibold, PingFang SC;
                                        font-weight: 500;
                                        color: #A0A4A7;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                    }

                                    .infoButton {
                                        cursor: pointer;
                                        display: none;
                                        position: absolute;
                                        top: 25%;
                                        right: 0;
                                        transform: translateY(-50%);
                                        margin-right: 10px !important;
                                        width: 35 * $defaultSize;
                                        height: 20 * $defaultSize;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #ffffff;
                                        background-color: #183ead;
                                        border: 0;
                                        border-radius: 5 * $defaultSize;
                                    }
                                }
                            }

                            .shichangMore {
                                cursor: pointer;
                                text-align: center;
                                font-size: 14 * $defaultSize;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #1e4dd8;
                                margin: auto;
                                margin-top: 20 * $defaultSize;

                            }

                            .shichangMore:hover {
                                color: #183ead;
                            }
                        }
                    }








                }

                .shichang:hover {
                    .shichangShow {
                        display: block;
                        animation: tracking-in-show 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }

                .qiyeShow {
                    padding: 26 * $defaultSize 25 *$defaultSize;
                    display: flex;
                    flex-wrap: wrap;

                    .arrow {
                        position: absolute;
                        top: 5px;
                        left: 50%;
                        transform: translate(-50%, -27%) rotateZ(45deg);
                        width: 30px;
                        height: 30px;
                        background-color: white;
                        border-radius: 5px;
                    }

                    .small {
                        cursor: pointer;
                        width: 50%;
                        padding: 10 * $defaultSize;

                        .title {
                            font-size: 14 * $defaultSize;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                            margin-bottom: 8 * $defaultSize;
                            display: flex;

                            img {
                                margin-left: 5 * $defaultSize;
                                display: none;
                            }
                        }

                        .hint {
                            font-size: 11 * $defaultSize;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #A0A4A7;
                            // line-height: 16px;
                        }
                    }

                    .small:hover {
                        .title {
                            color: #1e4dd8;

                            img {
                                display: block;
                            }
                        }

                        .hint {
                            color: #333333;
                        }
                    }
                }

                .qiye:hover {
                    .qiyeShow {
                        display: block;
                        display: flex;
                        flex-wrap: wrap;
                        animation: tracking-in-show 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }

                .otherShow {
                    padding: 26 * $defaultSize 25 *$defaultSize;
                    display: flex;
                    flex-wrap: wrap;

                    .arrow {
                        position: absolute;
                        top: 5px;
                        left: 56%;
                        transform: translate(-50%, -27%) rotateZ(45deg);
                        width: 30px;
                        height: 30px;
                        background-color: white;
                        border-radius: 5px;
                    }

                    .small {

                        width: 50%;
                        padding: 10 * $defaultSize;
                        cursor: pointer;

                        .title {
                            font-size: 14 * $defaultSize;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                            margin-bottom: 8 * $defaultSize;
                            display: flex;

                            img {
                                margin-left: 5 * $defaultSize;
                                display: none;
                            }
                        }

                        .hint {
                            font-size: 11 * $defaultSize;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #A0A4A7;
                            // line-height: 16px;
                        }
                    }

                    .small:hover {
                        .title {
                            color: #1e4dd8;

                            img {
                                display: block;
                            }
                        }

                        .hint {
                            color: #333333;
                        }
                    }
                }

                .other:hover {
                    .otherShow {
                        display: block;
                        display: flex;
                        flex-wrap: wrap;
                        animation: tracking-in-show 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }

                }

            }
        }

        .right {
            display: flex;
            align-items: center;

            .lang {
                // height: 21 * $defaultSize;
                display: flex;
                justify-content: flex-end;
            }

            .link {
                // width: 60 * $defaultSize;
                cursor: pointer;
                height: 21 * $defaultSize;
                background: #FFFFFF;
                border-radius: 3px;

                padding: 0 5 * $defaultSize;

                line-height: 21 * $defaultSize;
                text-align: center;
                font-size: 11 * $defaultSize;
                font-weight: 600;
                color: #224FD9;

                margin-right: 10 * $defaultSize;
            }

            .downLoad {
                cursor: pointer;
                width: 41 * $defaultSize;
                height: 21 * $defaultSize;
                border-radius: 3 * $defaultSize;
                border: 1px solid #FFFFFF;
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: relative;

                .deImg {
                    width: 11 * $defaultSize;
                    height: 13 * $defaultSize;
                }

                .show {
                    display: none;
                    position: absolute;
                    height: 200 * $defaultSize;
                    width: 130 * $defaultSize;
                    padding: 10 * $defaultSize;
                    top: 30 * $defaultSize;
                    right: 0;
                    background: #FFFFFF;
                    box-shadow: 4px 6px 34px 0px rgba(117, 141, 168, 0.51);
                    border-radius: 5 * $defaultSize;
                    z-index: 9999999;

                    .arrow {
                        position: absolute;
                        top: 0%;
                        left: 85%;
                        transform: translate(-50%, -20%) rotateZ(45deg);
                        width: 30px;
                        height: 30px;
                        background-color: white;
                        border-radius: 5px;
                        // position: absolute;

                        // transform: translate(-50%, -50%);
                        // width: 0;
                        // height: 0;
                        // border-left: 30px solid transparent;
                        // border-right: 30px solid transparent;
                        // border-bottom: 30px solid #FFF;
                    }

                    .dowImg {
                        width: 100 * $defaultSize;
                        height: 100 * $defaultSize;
                        margin: 0 auto;

                        &>img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .dowText {
                        line-height: 21 * $defaultSize;
                        text-align: center;
                        font-size: 11 * $defaultSize;
                        font-weight: 600;
                        color: #000000;
                        margin-top: 10 * $defaultSize;
                        margin-bottom: 15 * $defaultSize;
                    }

                    .dowBtm {
                        text-align: center;
                        width: 100%;
                        line-height: 21 * $defaultSize;
                        text-align: center;
                        font-size: 11 * $defaultSize;
                        font-weight: 600;
                        color: #FFF;
                        background: #122D82;
                    }
                }
            }

            .downLoad:hover {
                .downLoadShow {
                    display: block;
                }
            }
        }
    }
}

.small:hover {
    border-radius: 5 * $defaultSize;
    background-color: #e8eaf5;
}
</style>
<style>
.el-icon-arrow-up:before {
    content: '' !important;
}

.el-select .el-input .el-select__caret {
    transform: rotateZ(0deg) !important;
}

.el-input__suffix,
.el-input__suffix-inner {
    display: flex;
    align-items: center;
}

.el-icon-arrow-up {
    width: 20px !important;
    ;
    height: 20px !important;
    ;
    background-image: url('~@/assets/topbar/home_button_right_normal.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
</style>