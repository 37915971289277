<template>
    <div class="signal">

        <div class="box1">
            <div class="main">
                <div class="left">
                    <div class="top">
                        <div class="leftH1" style="display:flex;">
                            <span v-for="(item,index) in h1Arr($t('signal.h1'))" :key="index" :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]" 
                            :style="{ animationDelay:`${index * 0.02}s`}">{{ item }}
                            <span v-if="getI18nByEn()">&nbsp;</span>
                        </span>
                        </div>
                        <div class="leftH2" style="display:flex;">
                            <span v-for="(item,index) in h1Arr($t('signal.h2'))" :key="index" :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]" 
                            :style="{ animationDelay:`${(index * 0.02) + (h1Arr($t('signal.h1')).length *0.02)}s`}">
                            {{ item }}
                            <span v-if="getI18nByEn()">&nbsp;</span>
                            </span>
                        </div>
                    </div>
                    <div class="btm" @click="goFaq">
                        <span class="textSpan">
                            {{$t('signal.h3')}}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                </div>
                <div class="right">
                    <!-- <img src="../assets/MacBookPro16.png" alt=""> -->
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box2">
                <div class="left">
                    <div class="h1">{{$t('signal.h4')}}</div>
                    <div class="h2">
                        {{$t('signal.h5')}}
                    </div>
                    <div class="h5">
                        {{$t('signal.h6')}}
                    </div>
                    <div class="h3">
                        <div class="h3-top" v-animate="'animate__animated animate__fadeInLeft'">
                            <img class="h3-top1" src="../assets/signal/top1.svg">
                            <div class="h3-top2">58.6%</div>
                            <div class="h3-top3">
                                {{$t('signal.h7')}}
                            </div>
                        </div>
                        <div class="h3-bom" v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.5s">
                            <img class="h3-bom1" src="../assets/signal/top2.svg">
                            <div class="h3-bom2">100+</div>
                            <div class="h3-bom3">
                                {{$t('signal.h8')}}
                            </div>
                        </div>
                    </div>
                    <div class="h4" @click="asd">
                        <span class="textSpan">
                            {{$t('signal.h9')}}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/copyRobot/home_icon_more_normal.svg" />
                        </span>
                    </div>
                </div>
                <img class="right animate__animated animate__fadeIn" src="../assets/signal/Group 169.svg" alt="">
            </div>
            <div class="box5">
                <div class="top">
                    <div class="leftH1">{{$t('signal.h10')}}</div>
                    <div class="leftH2">{{$t('signal.h11')}}</div>
                </div>
                <div class="btm" @click="goads">
                    <span>{{$t('signal.h12')}}</span>
                    <span class="solidSpan">
                        <img src="../assets/solid.svg" alt="">
                    </span>
                </div>
            </div>
            <div class="box3">
                <div class="title">{{$t('signal.h13')}}</div>
                <div class="big">
                    <div class="small" v-animate="'animate__animated animate__fadeIn'">
                        <img src="../assets/strategy 1.png" alt="">
                        <div class="smallH1">{{$t('signal.h14')}}</div>
                        <div class="smallH2">{{$t('signal.h15')}}</div>
                    </div>
                    <div class="small" v-animate="'animate__animated animate__fadeIn'">
                        <img src="../assets/Group 60.png" alt="">
                        <div class="smallH1">{{$t('signal.h16')}}</div>
                        <div class="smallH2">{{$t('signal.h17')}}</div>
                    </div>
                    <div class="small" v-animate="'animate__animated animate__fadeIn'">
                        <img src="../assets/Vector.png" alt="">
                        <div class="smallH1">{{$t('signal.h18')}}</div>
                        <div class="smallH2">{{$t('signal.h19')}}</div>
                    </div>
                    <div class="small" v-animate="'animate__animated animate__fadeIn'">
                        <img src="../assets/spot-etf 1.png" alt="">
                        <div class="smallH1">{{$t('signal.h20')}}</div>
                        <div class="smallH2">{{$t('signal.h21')}}</div>
                    </div>
                </div>
            </div>

            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { NumSplic } from "../unit/tool";
  import big from "big.js";
  import {mapGetters} from "vuex"
import dowView from './simViews/dowView.vue';
  big.NE = -40;
  big.PE = 40;
  export default {
  components: { dowView },
    name: "signal",
    data() {
      return {
      };
    },
    methods: {
        asd(){
            window.open('https://support.rac.art/chan-pin/xin-hao-shang-cheng/xin-hao-shang-cheng-zhong-de-xin-hao-mo-ban-ru-he-shou-fei-xin-hao-mo-ban-de-ji-xiao-ru-he-yan-zheng', '_blank')
        },
        goFaq(){
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/xin-hao-shang-cheng/shen-me-shi-xin-hao-shang-cheng-wo-gai-ru-he-shi-yong', '_blank')
        },
        goads(){
            window.open('https://tianyi.art/contact/inquiry', '_blank')
        },
        getI18nByEn(){
      return this.$i18n.locale == 'en';
    },
    },
    computed:{
        ...mapGetters(['GET_IsAnimation']),
        h1Arr(text){
      if(this.$i18n.locale == 'en'){
        return text => text.split(" ");
      }else{
        return text => text.split("")
      }
    }
    },
    mounted() {
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $defaultSize: 0.0833333333vw;
  .textAnimation{
  display: block;
  animation: tracking-in-expand-fwd-bottom 0.1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none{
  animation: none;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

@keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}


 @keyframes tracking-in-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
            transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    opacity: 0;
  }
  40% {
    opacity: 0.1;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
  .signal {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1{
        padding-top: 58 * $defaultSize;
        display: flex;
        height: 366 * $defaultSize;
        background: url("../assets/signalBGI.png") no-repeat;
        background-size: 100%;
        .left{
            width: 50%;
            .top{
                .leftH1{
                    font-size: 37 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 15 * $defaultSize;
                }
                .leftH2{
                    font-size: 23 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 400;
                    color: #4F5559;
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #03A76A;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                font-weight: 600;
                color: #FFFFFF;
                .textSpan{
                    margin-left: 10 * $defaultSize;
                }
                img{
                    width: 13 * $defaultSize;
                    color: #FFFFFF;
                }
            }
            .btm:hover{
                background: #3A8459;
                .solidSpan{
                    img{
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }
        .right{
            width: 60%;
            img {
                width: 100%;
            }
        }
    }
    .main {
        width: 750 * $defaultSize;
        margin: auto;
        margin-top: 30 * $defaultSize;
        .box2{
            display: flex;
            .left{
                width: 50%;
                .h1 {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 600;
                    color: #224FD9;
                    margin-bottom: 15 * $defaultSize;
                }
                .h2 {
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 25 * $defaultSize;
                }
                .h5{
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 25 * $defaultSize;
                }
                .h3 {
                    font-size: 12 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 15 * $defaultSize;
                    .h3-top{
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        border-bottom: 1px solid #E8EBEF;
                        padding: 10 * $defaultSize 0;
                        .h3-top2{
                            font-size: 20 * $defaultSize;
                            font-family: Inter-Bold, Bold;
                            font-weight: 600;
                            color: #333333;
                            margin-right: 10 * $defaultSize;
                        }
                    }
                    .h3-bom{
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 10 * $defaultSize 0;
                        .h3-bom2{
                            width: 25%;
                            font-size: 20 * $defaultSize;
                            font-family: Inter-Bold, Bold;
                            font-weight: 600;
                            color: #333333;
                            margin-right: 10 * $defaultSize;
                        }
                    }
                    img {
                        width: 30 * $defaultSize;
                        margin-right: 10 * $defaultSize;
                    }
                }
                .h4{
                    cursor: pointer;
                    margin-top: 50* $defaultSize;
                    color: #333333;
                    font-size: 15 * $defaultSize;
                    font-weight: 600;
                    display: flex;
                    .solidSpan{
                        margin-left: 5* $defaultSize;
                        display: flex;
                        align-items: center; /* 将子元素垂直居中对齐 */
                    }
                }
                .h4:hover{
                    color: #224FD9;
                    img{
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
            .right {
                margin-left: 80 * $defaultSize;
                width: 45%;
            }
        }
        .box3{
            .title{
                font-size: 20 * $defaultSize;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                color: #000000;
                margin-bottom: 25 * $defaultSize;
                text-align: center;
            }
            .big{
                display: flex;
                justify-content: space-between;
                .small{
                    width: 230%;
                    text-align: center;
                    border-radius: 20 * $defaultSize;
                    opacity: 1;
                    // border: 1px solid #CCCCCC;
                    padding: 25 * $defaultSize;
                    img {
                        width: 75 * $defaultSize;
                        transform:scale(1);
                        transition:transform 0.5s;
                    }
                    img:hover{
                        transform:scale(1.1)
                    }
                    .smallH1{
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 19 * $defaultSize;
                    }
                    .smallH2{
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }
        .box4 {
            width: 100%;
            height: 353 * $defaultSize;
            background-image: url("../assets/Rectangle 159.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 113 * $defaultSize;
            padding-right: 53 * $defaultSize;
            img {
                width: 360 * $defaultSize;
            }
            .right {
                width: 315 * $defaultSize;
                .h1 {
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 600;
                    color: #333333;
                }
                .h2 {
                    margin-top: 10 * $defaultSize;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                }
                .h3 {
                    margin-top: 20 * $defaultSize;
                    font-size: 14 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 600;
                    color: #333333;
                }
            }
        }
        .box5{
            margin-top: 80 * $defaultSize;
            height: 375 * $defaultSize;
            background: url("../assets/signal/Group 159.svg") no-repeat;
            background-size: 100%;
            padding: 50 * $defaultSize;
            padding-bottom: 0;
            color: #FFFFFF;
            .top{
                width: 40%;
                .leftH1{
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    margin-bottom: 10 * $defaultSize;
                }
                .leftH2{
                    font-size: 12 * $defaultSize;
                    font-family: Inter-Regular, Regular;
                    font-weight: 400;
                    margin-bottom: 10 * $defaultSize;
                }
            }
            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #03A76A;
                line-height: 35 * $defaultSize;
                border-radius: 8 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                span{
                    margin-left: 10 *$defaultSize;
                }
                font-weight: 600;
                color: #FFFFFF;
                img{
                    width: 7 * $defaultSize;
                    color: #FFFFFF;
                }
            }
            .btm:hover{
                background: #3A8459;
                .solidSpan{
                    img{
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }
    }
  }
  </style>
  
  