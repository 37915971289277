<template>
    <div class="strategy">
        <!-- <Topbar2 /> -->
        <div class="box1">
            <div>
                <div class="h1">{{ $t('strategy.h1') }}</div>
                <div class="h2">{{ $t('strategy.h2') }}</div>
                <div class="btn">{{ $t('strategy.h3') }}
                    <img src="../assets/strategy/home_button_right_normal.svg" alt="">
                </div>
                <div class="big">
                    <div class="small animate__animated animate__fadeInDown">
                        <img src="../assets/LJ2RF2YWRMKJI 1.svg" alt="">
                        <span>{{ $t('strategy.h4') }}</span>
                    </div>
                    <div class="small animate__animated animate__fadeInDown">
                        <img src="../assets/LJ2RF4WMVATM6 1.svg" alt="">
                        <span>{{ $t('strategy.h5') }}</span>
                    </div>
                    <div class="small animate__animated animate__fadeInDown">
                        <img src="../assets/LJ2RF9ROJ8VLP 1.svg" alt="">
                        <span>{{ $t('strategy.h6') }}</span>
                    </div>
                    <div class="small animate__animated animate__fadeInDown">
                        <img src="../assets/LJ2RF722436L0 1.svg" alt="">
                        <span>{{ $t('strategy.h7') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box2">
                <div class="title">
                    {{ $t('strategy.h8') }}
                </div>
                <div class="hint">
                    {{ $t('strategy.h9') }}
                </div>
                <div class="big">
                    <div class="small">
                        <img src="../assets/Group 77.svg" alt="">
                        <div class="name">{{ $t('strategy.h10') }}</div>
                        <div class="tip">{{ $t('strategy.h11') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/Group 78.svg" alt="">
                        <div class="name">{{ $t('strategy.h10') }}</div>
                        <div class="tip">{{ $t('strategy.h11') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/Group 79.svg" alt="">
                        <div class="name">{{ $t('strategy.h10') }}</div>
                        <div class="tip">{{ $t('strategy.h11') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/Group 80.svg" alt="">
                        <div class="name">{{ $t('strategy.h10') }}</div>
                        <div class="tip">{{ $t('strategy.h11') }}</div>
                    </div>
                </div>
            </div>
            <div class="box3">
                <div class="h1">{{ $t('strategy.h12') }}</div>
                <div class="h2">{{ $t('strategy.h13') }}</div>
                <div class="body">
                    <div class="left">
                        <div :style="`${current === 0 ? 'display: block' : 'display: none'} `">
                            <div class="h3">{{ $t('strategy.h14') }}</div>
                            <div class="h4">{{ $t('strategy.h15') }}</div>
                        </div>
                        <div :style="`${current === 0 ? 'display: none' : 'display: block'} `">
                            <div class="h3">{{ $t('strategy.h141') }}</div>
                            <div class="h4">{{ $t('strategy.h151') }}</div>
                        </div>
                        <div class="h5">
                            <div :class="`${current === 0 ? 'btn1' : 'btn2'}`" @click="current = 0">{{ $t('strategy.h14')
                            }}
                            </div>
                            <div :class="`${current === 1 ? 'btn3' : 'btn2'}`" @click="current = 1">{{ $t('strategy.h16')
                            }}
                            </div>
                        </div>
                    </div>
                    <div class="right"></div>
                </div>
            </div>
            <div class="box4">
                <div class="h1">{{ $t('strategy.h17') }}</div>
                <div class="h2">{{ $t('strategy.h18') }}</div>
                <div class="body">
                    <div class="smallDiv">
                        <div class="small">
                            <div class="h3">{{ $t('strategy.h19') }}</div>
                            <div class="h4">{{ $t('strategy.h20') }}</div>
                        </div>
                    </div>

                    <div class="smallDiv">
                        <div class="small">
                            <div class="h3">{{ $t('strategy.h21') }}</div>
                            <div class="h4">{{ $t('strategy.h22') }}</div>
                        </div>
                    </div>
                    <div class="smallDiv">
                        <div class="small">
                            <div class="h3">{{ $t('strategy.h23') }}</div>
                            <div class="h4">{{ $t('strategy.h24') }}</div>
                        </div>
                    </div>
                    <div class="smallDiv">
                        <div class="small">
                            <div class="h3">{{ $t('strategy.h25') }}</div>
                            <div class="h4">{{ $t('strategy.h26') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box5">
                <div class="left">
                    <div class="h1">{{ $t('strategy.h27') }}</div>
                    <div class="h2">{{ $t('strategy.h28') }}</div>
                    <div class="h3">
                        <span>{{ $t('strategy.h29') }}</span>
                        <img src="../assets/strategy/home_button_right_normal.svg" alt="">
                    </div>
                </div>
                <img src="../assets/Mask group1.svg" alt="">
            </div>
            <div class="box6">
                <div class="box6-small">
                    <span>让我们开始吧</span>
                    <span>安排与我们的工作人员联系，了解我们如何帮助您增加财富</span>
                    <div>
                        <span>预约联系</span>
                        <img src="../assets/strategy/home_button_right_normal.svg" alt="">
                    </div>
                </div>

            </div>
        </div>
        <BTM2 />
    </div>
</template>
  
<script>

import { NumSplic } from "../unit/tool";
import big from "big.js";
big.NE = -40;
big.PE = 40;
import Topbar2 from '../components/topbar2.vue';
import BTM2 from '../components/btm2.vue';
export default {
    name: "strategy",
    components: { Topbar2, BTM2 },
    data() {
        return {
            isPageBack: false,
            current: 0
        };
    },
    methods: {
    },
    computed: {},
    mounted() {

    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}

.strategy {
    background-color: #000;
    // border: 1px solid #f0f2f5;

    .box1 {
        background: url("../assets/wallet/atf-video.gif") no-repeat 100%;
        height: 474 * $defaultSize;
        text-align: center;
        padding-top: 86 * $defaultSize;

        .h1 {
            font-size: 49 * $defaultSize;
            font-family: Inter-Extra Bold, Inter;
            font-weight: 700;
            color: #FFFFFF;
        }

        .h2 {
            font-size: 26 * $defaultSize;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            color: #FFFFFF;
            margin-top: 15 * $defaultSize;
        }

        .btn {
            cursor: pointer;
            display: inline-block;
            margin-top: 35 * $defaultSize;
            padding: 8 * $defaultSize 10 * $defaultSize;
            background: #E1A163;
            font-size: 21 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: 600;
            color: #333333;
            text-align: center;
            border-radius: 4 * $defaultSize;

            img {
                width: 18 * $defaultSize;
            }
        }

        .btn:hover {
            background: #FFF;

            img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            }
        }

        .big {
            width: 100%;
            height: 100 * $defaultSize;
            display: flex;
            align-items: ce;
            justify-content: space-between;
            margin-top: 130 * $defaultSize;
            background-image: url('@/assets/Rectangle 168.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            padding: 0 220 * $defaultSize;

            .small {
                display: flex;
                align-items: center;
                width: 18%;

                img {
                    width: 31 * $defaultSize;
                    margin-right: 10 * $defaultSize;
                }

                span {
                    font-size: 11 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: normal;
                    color: #FFFFFF;
                    text-align: left;
                }
            }
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .box2 {
            width: 100%;
            padding-top: 88 * $defaultSize;

            .title {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 700;
                color: #FFFFFF;
            }

            .hint {
                font-size: 20 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #B1B4B5;
                padding-top: 15 *$defaultSize;
                padding-bottom: 25 *$defaultSize;
            }

            .big {
                display: flex;
                justify-content: space-between;

                .small {
                    width: 20%;

                    img {
                        width: 25 * $defaultSize;
                    }

                    .name {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: normal;
                        color: #FFFFFF;
                        margin: 10 * $defaultSize 0;
                    }

                    .tip {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #B1B4B5;
                    }
                }
            }
        }

        .box3 {
            .h1 {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 700;
                color: #FFFFFF;
                margin-top: 80 * $defaultSize;
                text-align: center;
            }

            .h2 {
                font-size: 20px;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #B1B4B5;
                margin-top: 15 * $defaultSize;
                text-align: center;
            }

            .body {
                height: 247 * $defaultSize;
                margin-top: 25 * $defaultSize;
                background: url("../assets/Group 76.svg");
                background-size: 100%;
                background-repeat: no-repeatc;
                padding: 50 * $defaultSize;

                .left {
                    width: 35%;
                }

                .h3 {
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: normal;
                    color: #FFFFFF;
                }

                .h4 {
                    font-size: 11 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #B1B4B5;
                    margin-top: 10 * $defaultSize;
                    margin-bottom: 25 *$defaultSize;
                }

                .h5 {
                    display: flex;
                    align-items: center;

                    .btn1 {
                        font-size: 14 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: normal;
                        color: #030405;
                        padding: 4 *$defaultSize 15 *$defaultSize;
                        border-radius: 2 * $defaultSize;
                        background: #E1A163;
                        transition: 0.2s;
                    }

                    .btn2 {
                        margin-left: 15 * $defaultSize;
                        font-size: 14 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: normal;
                        color: #FFFFFF;
                        cursor: pointer;
                        transition: 0.2s;
                    }

                    .btn3 {
                        font-size: 14 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: normal;
                        color: #030405;
                        padding: 4 *$defaultSize 15 *$defaultSize;
                        border-radius: 2 * $defaultSize;
                        background: #E1A163;
                        margin-left: 20px !important;
                        transition: 0.2s;
                    }
                }
            }
        }

        .box4 {
            padding-top: 88 * $defaultSize;

            .h1 {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 700;
                color: #FFFFFF;
                margin-bottom: 15 * $defaultSize;
                text-align: center;
            }

            .h2 {
                font-size: 20 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #B1B4B5;
                margin-bottom: 25 * $defaultSize;
                text-align: center;
            }

            .body {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .smallDiv {
                    width: 49%;
                    border-radius: 32px;
                    box-sizing: border-box;
                    padding: 1px;
                    // border-image: linear-gradient(173deg, rgba(140, 142, 145, 1), rgba(0, 0, 0, 0)) 1 1;
                    background-image: linear-gradient(173deg, rgba(140, 142, 145, 1), rgba(0, 0, 0, 0));
                    margin-bottom: 25 * $defaultSize;

                    .small {
                        width: 100%;
                        height: 100%;
                        padding: 25 * $defaultSize;
                        background: #030405;
                        border-radius: 32px;

                        .h3 {
                            font-size: 20px;
                            font-family: Inter-Semi Bold, Inter;
                            font-weight: 600;
                            color: #E1A163;
                            margin-bottom: 10 * $defaultSize;
                        }

                        .h4 {
                            font-size: 11 * $defaultSize;
                            font-family: Inter-Regular, Inter;
                            font-weight: 400;
                            color: #B1B4B5;
                        }
                    }
                }

            }
        }

        .box5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 88 * $defaultSize;

            .left {
                width: 50%;

                .h1 {
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 700;
                    color: #FFFFFF;
                }

                .h2 {
                    font-size: 11 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #B1B4B5;
                    margin-top: 10 * $defaultSize;
                    margin-bottom: 25 * $defaultSize;
                }

                .h3 {
                    cursor: pointer;
                    background: #E1A163;
                    border-radius: 5 * $defaultSize;
                    font-size: 14 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: normal;
                    color: #030405;
                    padding: 4 * $defaultSize 15 * $defaultSize;
                    display: inline-block;

                    img {
                        width: 12* $defaultSize;
                    }
                }

                .h3:hover {
                    background: #FFFFFF;

                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }

            img {
                width: 50%;
            }
        }
    }
}

.box6 {
    width: 750 * $defaultSize;
    height: 300 * $defaultSize;
    margin-bottom: 30 * $defaultSize;
    background: #030405;

    .box6-small {
        width: 100%;
        height: 190 * $defaultSize;
        border-radius: 12 * $defaultSize;
        background-image: url('~@/assets/Group 73.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &>span:nth-of-type(1) {
            font-size: 22 * $defaultSize;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            color: #FFFFFF;
        }

        &>span:nth-of-type(2) {
            margin-top: 12* $defaultSize;
            font-size: 10 * $defaultSize ;
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            color: #FFFFFF;
        }

        &>div {
            margin-top: 15 * $defaultSize ;
            width: 80 * $defaultSize;
            height: 30 * $defaultSize;
            background: #E1A163;
            border-radius: 4 * $defaultSize;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 12* $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: bold;
            color: #030405;

            img {
                width: 12* $defaultSize;
            }
        }

        &>div:hover {
            background: #FFFFFF;

            img {
                animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            }
        }
    }

}
</style>
  
  