module.exports = {
    topBar: {
        linkWallet: "Launch your first robot for free in Reactor. Learn more",
        unit: "USD",
        h1: "home page",
        h2: "Product",
        h2_1: "The Backtesting Capability of Line K",
        h2_1_1: "Strategy Backtesting",
        h2_1_2: "Conduct simulated transaction backtesting",
        h2_1_3: "Manual transactions",
        h2_1_4: "Placing limit orders on the chain",
        h2_1_5: "K-line and on chain data",
        h2_1_6: "Real time access to transaction K-line and on chain data",
        h2_2: "There are multiple types of robots",
        h2_2_1: "Copy Robot",
        h2_2_2: "A professional trader who follows orders",
        h2_2_3: "Trading Robot",
        h2_2_4: "Working 24 hours to eliminate emotional trading",
        h2_2_5: "Market maker Robot",
        h2_2_6: "Working 24 hours to eliminate emotional trading",
        h2_2_7: "Arbitrage Robot",
        h2_2_8: "Cross exchange arbitrage",
        h2_2_9: "DEMO Robot",
        h2_2_10: "Gain trading experience without risk",
        h2_3: "Template Mall is ready to use and open",
        h2_3_1: "Signal Mall",
        h2_3_2: "Make more accurate trading decisions",
        h2_3_3: "Strategic Mall",
        h2_3_4: "Trading like a professional",
        h2_3_5: "Robot Mall",
        h2_3_6: "Various types of automated robots",
        h3: "Market",
        h3_1: "Token",
        h3_2: "Supporting Liquidity Platforms",
        h3_2_1: "Coin An",
        h3_2_2: "Binance is the world's largest Cryptocurrency exchange in terms of trading volume. As of August 2022, Binance has traded...",
        h3_2_3: "Fire Coin",
        h3_2_4: "huobi Global is an international digital asset exchange with operations in over 100 countries. The platform was launched in 2013",
        h3_2_5: "OKX",
        h3_2_6: "OKX is a Cryptocurrency centralized transaction (CEX) and wallet platform located in Seychelles, serving millions of customers worldwide...",
        h3_2_7: "PancakeSwap",
        h3_2_8: "PancakeSwap is a leading developer of an industry leading non managed decentralized exchange (DEX) that focuses on advanced",
        h3_2_9: "UniSwap",
        h3_2_10: "Uniswap is a decentralized exchange (DEX), which first introduced the automatic Market maker (AMM) model",
        h3_2_11: "DODO",
        h3_2_12: "Dodo is a decentralized exchange based on Ethereum, with active Market maker that is, automatic Market maker (AMM...",
        h3_3: "View all supported assets",
        h3_4: "More trading platforms",
        h4: "Price",
        h5: "Enterprise",
        h5_1: "About us",
        h5_2: "Excellent service, continuous innovation. Collaborate closely with customers to provide high-quality solutions. Establish long-term cooperative relationships and grow together",
        h5_3: "OEM",
        h5_4: "Professional customized solutions to meet your unique needs and provide personalized services",
        h5_5: "Business Operations",
        h5_6: "Efficiently manage and expand your business, helping you achieve sustained growth and market leadership",
        h5_7: "Social media",
        h5_8: "Explore our social media channels, track the latest trends, and jointly explore the cutting-edge and innovative trends in the industry",
        h5_9: "Partner",
        h5_10: "Join hands to create, share resources and opportunities, achieve mutual benefit and win-win results, and jointly move towards the path of success",
        h5_11: "Contact Us",
        h5_12: "Get in touch with us and let's explore business opportunities and collaborate on beneficial partnerships",
        h6: "help",
        h7_1: "Simplified Chinese",
        h7_2: "English",
        h7_3: "Japanese",
        h7_4: "Korean",
        h7_5: "Traditional Chinese",
        h7_6: "Thai",
        h7_7: "Malay",
        h7_8: "Vietnamese",
        h8: "Link Wallet",
        h9: "More",
        h9_1: "Rebate",
        h9_2: "Invite your friends to join our platform through the link, and you will have the opportunity to get additional commission rewards.",
        h9_3: "Wallet",
        h9_4: "Safe and reliable, convenient for storage, easy to manage your digital assets, ensuring financial security and privacy protection.",
        h9_5: "custom strategy",
        h9_6: "In-depth understanding of your investment goals, and tailor-made investment strategies that meet your needs.",
        h9_7: "DCA",
        h9_8: "Intelligent fixed investment, stable value-added, avoiding the risk of market fluctuations, and stable long-term holding income.",
    },
    btm: {
        h1: "Products",
        h2: "Wallet",
        h3: 'Market',
        h4: "Signal",
        h5: "Data on the chain",
        h6: "custom strategy",
        h7: "Rebate",
        h8: "Robot",
        h9: "Trading Robot",
        h10: "DEMO robot",
        h11: "Copy Robot",
        h12: "Arbitrage Robot",
        h13: "Market Maker Robot",
        h14: "About us",
        h15: "OEM",
        h16: "Advertising Cooperation",
        h17: "Business Operations",
        h18: "Social Media",
        h19: "Partner",
        h20: "Community",
        h21: "Service",
        h22: "Download",
        h23: "Service Agreement",
        h24: 'Privacy Agreement',
        h25: "Risk Warning",
        h26: "Help Center",
        h27: "Follow us",
        h28: "Local download",
        h29: "Enterprise credit evaluation AAA grade credit enterprise",
        h30: "National honorary certificate in the field of bidding and bidding",
        h31: "Integrity Archives Enterprise of the Ministry of Commerce of the People's Republic of China",
        h32: "Integrity management demonstration enterprise",
        h33: "Guangdong Public Network Security No. 44030502009469 Guangdong ICP No. 2022043961-1 Value-added Telecommunications Business License B1-20214279 Guangdong Net Text: (2018) No. 5045-1753 © 2018 - 2023 TIANYI. ALL RIGHTS RESERVED",
        h34: "desktop client",


        A1: 'Strategy Backtesting',
        A2: 'Manual Trading',
        A3: 'Candlestick and On-Chain Data',
        A4: 'Signal Marketplace',
        A5: 'Strategy Marketplace',
        A6: 'Trading Bot',
        A7: 'Demo Bot',
        A8: 'Copy Trading Bot',
        A9: 'Arbitrage Bot',
        A10: 'Market Making Bot',
        A11: 'About Us',
        A12: 'OEM',
        A13: 'Social Media',
        A14: 'Contact Us',
        A15: 'Privacy Policy',
        A16: 'Service Agreement',
        A17: 'Disclaimer',
        A18: 'Help Center',
        A19: 'Customized Strategies',
        A20: 'Referral Commission',
        A21: 'Wallet',
        A22: 'DCA',
        A23: 'Download',
        A24: "more",

    },
    home: {
        h1: "Automatic trading, buy low and sell high",
        h2: "A smart trading terminal that gathers many functions",
        h3: 'Customizable digital currency trading robot',
        h4: "Free Trial",
        h5: "Support centralized exchanges & decentralized exchanges",
        h6: "Diversified investment",
        h7: "Professional quantification",
        h8: "Indicator parameters",
        h9: "Transaction Data",
        h10: "Invitation rebate",
        h11: "Connect your wallet and exchange accounts and manage them with our trading terminal",
        h12: "We know the importance of diversified investment for investment portfolio. With Reactor, you can easily manage multiple exchanges and wallets at the same time, disperse funds more flexibly, distribute investments in different assets and markets, and effectively reduce Risk to a single asset or market.",
        h11_1: "Accurate analysis, excellent trading effect",
        h12_1: "Reactor can conduct accurate market analysis and trading strategy optimization to achieve excellent trading results. Use advanced algorithms and data indicators to improve trading efficiency, so as to better control the market and obtain more stable returns.",
        h11_2: "Flexible customization, optimize transaction indicators",
        h12_2: "Provide you with flexible indicator parameter settings, and customize trading strategies according to individual needs. By adjusting indicator parameters to optimize trading performance, better adapt to market changes, realize personalized trading experience, and obtain better return on investment.",
        h11_3: "Real-time monitoring, accurate grasp of market dynamics",
        h12_3: "Through real-time data, you can make more accurate trading decisions and capture investment opportunities in a timely manner. No longer miss any important moments, and through data monitoring, you can accurately grasp market dynamics.",
        h11_4: "Share to make money, invite rewards",
        h12_4: "By inviting friends to participate in the platform, you can get up to 15% of the invitation rewards. Through the invitation anti-commission function, you can share the trading fun with your friends and at the same time, you can also get considerable anti-commission income.",
        h13: "Learn more",
        h14: "Support network",
        h15: "Supported",
        h16: "Liquidity Platform",
        h17: "Cryptocurrency",
        h18: "Processed",
        h19: "Trading order",
        h20: "Ideal for trading like a pro",
        h21: "Use the aggregated liquidity platform on Reactor to trade cryptocurrencies, conduct market order and limit order transactions on the chain, execute automated trading strategies, and view transaction data.",
        h22: "Professional team and technology",
        h23: "We are committed to providing transparent and reliable services, always putting the interests of our clients first. Our investment strategy is based on sufficient research and due diligence to ensure that we provide you with the best investment opportunities.",
        h24: 'Your funds and algorithms are safe',
        h25: "Wallet",
        h26: "We will not record your private key information, and the robot will strictly abide by the security standards of exchanges and wallets",
        h27: "Authentication",
        h28: "Adopt multiple authentication and encryption measures to ensure the security of your assets and transaction information",
        h29: "Safe transaction",
        h30: "You can use our robots to trade with confidence and enjoy a convenient and safe investment experience.",
        h31: "Cryptocurrency trading terminal to conveniently manage all encrypted asset portfolios",
        h32: "Our trading terminal brings together the functions of multiple exchanges and wallets, allowing you to perform asset management and trading operations on a unified interface.",
        h33: "Explore the Infinite Possibilities of Blockchain Together",
        h34: "We provide comprehensive blockchain solutions and professional consulting services. Our team has rich experience and professional knowledge in blockchain, helping you understand the core concepts of blockchain, master key technologies and tools, and explore how to apply blockchain to practical scenarios",
    },
    about: {
        h1: "TIANYI is a provider of digital engineering solution development from server/network to application layer",
        h2: "We are a digital creative community from all over the world.",
        h3: 'Our team consists of a group of experienced and passionate professionals. Our professional solutions and creative services in many fields, including application development, Web3, visual design and creativity, application design and planning, server/network and computer room, marketing and office system. ',
        h4: "One-stop service",
        h5: "We provide a full range of one-stop services, covering all areas from planning, design, development to operation.",
        h6: "Global Standard",
        h7: "Global standard design, using no-code tools, and actively introducing Web3tran technologies such as NFT and Metaverse.",
        h8: "Server and IDC network",
        h9: "We provide high-performance server solutions and scalable IDC network architecture to ensure that your business runs smoothly, safely and reliably.",
        h10: "Mission, Vision and Values",
        h11: "Mission",
        h12: "Use the power of digital creativity to solve problems and make lasting contributions to society. Our mission is to provide society with meaningful solutions and drive social progress through innovation and creativity.",
        h13: "Contact us, or make an appointment to visit our office",
        h14: "Room 608, Baotong Building, Baomin 1st Road, Xin'an Street, Baoan District, Shenzhen",
        h15: "Consult now",
    },
    // chainData: {
    //     h1: "Display transparent and credible on-chain data",
    //     h2: "Explore the infinite potential of blockchain",
    //     h3:'View details',
    //     h4: "Seize the opportunity in the future of blockchain!",
    //     h5: "Our product provides you with a unique opportunity to deeply understand and demonstrate the unlimited potential and transparency of blockchain.",
    //     h6: "In-depth understanding of transaction history",
    //     h7: "Through the display and analysis of data on the chain, you can track and analyze various transactions, understand market trends and trader behavior",
    //     h8: "Asset Flow Tracking",
    //     h9: "Through the visualization of data on the chain, you can track important information such as asset transfer paths and holder changes.",
    //     h10: "Smart contract analysis",
    //     h11: "Our tools allow you to deeply research and analyze the execution of smart contracts, helping you evaluate the security and reliability of contracts.",
    // },
    chainData: {
        h1: "K line and data on the chain",
        h2: "The perfect combination of insights into market trends",
        h3: 'Integration of historical market and real-time blockchain information',
        h4: "Optimize your transaction information",
        h5: "View details",
        h6: "Excellent advantages of integrating K-line and on-chain data",
        h7: "Real-time insight into the market in Reactor, accurate decision-making transactions",
        h8: "Display historical price trends and track transaction data in the blockchain network",
        h9: "Check the latest block chain transaction record block height and transaction confirmation time",
        h10: "Generate accurate trading signals based on the analysis of K-line and on-chain data",
        h11: "Intuitive charts and graphics to display K-line and chain data",
        h12: "Check position status, asset allocation ratio, and market value changes of each asset",
        h13: "There are also Reactor-specific",
        h14: "Improve the insight and efficiency of transaction decision-making",
        h15: "multi-chain",
        h16: "Customizable",
        h17: "Signal",
        h18: "Reactor supports transactions on multiple blockchain networks. Such as Ethereum, Wave Field, Binance Smart Chain, etc.",
        h19: "You can customize trading strategies, risk control rules and test settings to achieve a personalized trading experience",
        h20: "Display points, trading suggestions and operation tips on the K-line chart, and you should understand the relationship between the signal and the market",
        h21: "In-depth insight into the real-time data of the blockchain",
        h22: "Through Reactor, gain an in-depth understanding of the key content and significance of data on the chain",
        h23: 'Real-time market data',
        h24: "Blockchain transaction tracking",
        h25: "Smart contract analysis",
        h26: "Address and balance inquiry",
        h27: "Historical data backtracking",
        h28: "Custom indicator analysis",
        h29: "Includes key indicators such as cryptocurrency prices, trading volumes, and market capitalization to help you fully understand the dynamics of the market.",
        h30: "Track and display transaction history, including transaction time, transaction pair, transaction volume and transaction direction.",
        h31: "Help you deeply understand and evaluate the security and reliability of smart contracts of different projects.",
        h32: "You can enter the address to query the balance and transaction records of any cryptocurrency.",
        h33: "Display historical cryptocurrency price trends, transaction volume changes and other data",
        h34: "You can create personalized indicators according to your own needs and strategies, and apply them to K-line or on-chain data.",
        h35: "Born for trading",
        h36: "Real-time K-line chart, precise indicators and intelligent analysis",
        h37: "Currency Information",
        h38: "Chart Trading",
        h39: "Intelligent Analysis",
        h40: "Basic information such as currency name, total issuance amount, and important indicators such as price, market value, and 24-hour trading volume",
        h41: "You can directly trade on the right side of the K-line chart, from which you can gain insights into market opportunities and make corresponding trading decisions",
        h42: "Signals can identify price trend patterns, morphological structures, as well as resistance and support levels in the market.",
    },
    community: {
        h1: "Join the Reactor community",
        h2: "Get the latest Reactor event information and online support",
        h3: 'Telegram',
        h4: "Quantitative community",
        h5: "Twitter",
        h6: "Chinese Community",
        h7: "Instagram",
        h8: "Facebook",
        h9: "Telegram",
        h10: "Get support and answers from professional staff",
        h11: "Our community has experienced professional staff who have an in-depth understanding of the crypto market and quantitative trading. As a community member, you can interact directly with our team of experts to ask questions, seek advice and answers. Whether it is Regarding market analysis, trading strategies or technical support, our staff will wholeheartedly help you and ensure that you are always in the right direction on the road of quantitative trading.",
        h12: "Capture the trend of the encryption market and explore new quantitative strategies",
        h13: "Joining our community, you will have the opportunity to capture the latest trends and dynamics of the encryption market. We provide advanced quantitative trading tools and technologies to help you analyze market data, formulate strategies and execute transactions in real time. Through communication with other members and share, you can explore new quantitative strategies, broaden your investment horizons, and gain a competitive advantage in an ever-changing market.",
        h14: "Share freely and meet new encrypted friends",
        h15: "Our community is a platform for free sharing and mutual assistance. As a member, you can share your trading experience, insights and strategies, and interact and discuss with other members. By interacting with other like-minded crypto enthusiasts and professional traders Exchange, you can expand your network and meet new crypto friends. In this active and cooperative community, you will have the opportunity to learn from the experience of others, grow together, and create more trading opportunities."
    },
    cooperation: {
        h1: "Global corporate partners",
        h2: "Tianyi has many partner companies in China, Asia, Middle East, Southeast Asia, etc. around the world. We provide new technology solutions with partner companies around the world.",
        h3: 'Shenzhen',
        h4: "headquarters",
        h5: "Singapore",
        h6: "Southeast Asia",
        h7: "Chengdu",
        h8: "Branch",
        h9: "Wuhan",
        h10: "Japan",
        h11: "Asia",
        h12: "Vietnam",
        h13: "Hunan",
        h14: "Henan",
        h15: "South Korea",
        h16: "Cambodia",
        h17: "Hainan",
        h18: "Dubai",
        h19: "Middle East",
        h20: "Thailand"
    },
    copyRobot: {
        // h1:"Reactor's replicating robot",
        // h2:"You can participate in profitable trading without developing a strategy yourself",
        // h3:'See more',
        // h4: "You can choose a signal provider and automatically copy its trading strategy. This means that you can follow in the footsteps of excellent traders and enjoy their trading experience and success.",
        // h5: "Automatically copy the trading behavior of the selected trader to the investor's trading account",
        // h6: "Flexible configuration options, such as setting the replication ratio, setting risk control parameters, etc.",
        // h7: "Risk management function and automatic stop loss mechanism",
        // h8: "Various copy options",
        // h9: "Provide a variety of copy options, and you can choose different copy methods according to the needs and preferences of investors",
        // h10: "Real-time synchronous transactions",
        // h11: "Real-time synchronization of traders' trading behaviors to ensure that investors can follow and copy traders' operations in time.",
        // h12: "Multi-platform support",
        // h13: "Investors can trade on exchanges they are familiar with and trust, and enjoy the convenience and advantages of copy trading.",
        h1: "Follow the extraordinary performance of top traders",
        h2: "Access unique trading strategies and superior performance of top traders with Reactor",
        h3: 'See more',
        h4: "Unique trading strategy",
        h5: "Opportunities to work directly with top traders",
        h6: "Improve your own transaction success rate and performance",
        h7: "Appropriate capital allocation for better risk management",
        h8: "Broader and more diversified trading strategy options",
        h9: "Learn more",
        h10: "To this end, we provide",
        h11: "Follows traders' buying and selling behavior completely automatically",
        h12: "Personalized adjustment parameters, such as take profit, stop report, etc.",
        h13: "View assets and transaction records bought and sold",
        h14: "Carry out capital allocation and risk management according to one's own risk tolerance",
        h15: "View more functions",
        h16: "Provide you with detailed data and information",
        h17: "Easy to find and follow excellent traders, and get a stable return on investment",
        h18: "Transparent robot seller information",
        h19: "Weekly summary of trading results",
        h20: "average profit",
        h21: "Users can judge the activity level of the seller according to the seller's time in Budangjie and the last listing time, and carry out further cooperation with the seller through the joint mining method of supplying and supplying.",
        h22: "The copy robot may provide a weekly summary of trading results, including total profits, buying times, selling times, etc., to help you understand and evaluate trading performance in a timely manner.",
        h23: "The copy robot will calculate and display your average profit, and display it in the form of a chart, so that you can understand the average profit and expected profit of each transaction.",
        h24: "View last month's performance report",
        h25: "Winning rate, losing rate, profit-loss ratio",
        h26: "Transaction currency and transaction times",
        h27: "Includes the total asset trend chart, the best profit of each transaction, the largest drawdown and other data to help you understand the investment situation in the past month.",
        h28: "The copy robot will show you important indicators such as the trader's win rate, loss rate and profit-loss ratio, helping you evaluate the stability and profitability of your trading strategy",
        h29: "The copy robot will provide a list of the trading currencies you copied and the statistics of the total number of transactions, so that you can understand the trading varieties and trading frequency you participate in.",
    },
    dca: {
        h1: "The quantitative journey starts from the fixed investment strategy (DCA)",
        h2: "Regularly invest funds to buy cryptocurrencies regardless of market price fluctuations",
        h3: 'See more',
        h4: "What is DCA?",
        h5: "DCA is a long-term investment strategy that buys cryptocurrencies by investing the same amount of funds on a regular basis, regardless of market price fluctuations. This strategy can help investors spread risk, reduce the impact of market volatility, and benefit from assets in the long run Value-added. You can easily set up a regular investment plan, choose the cryptocurrency you are interested in and the investment cycle. The system will automatically execute the transaction within the specified time interval according to the plan you set, and intelligently purchase cryptocurrency according to the market price. Flexible adjustment Fixed investment plan, optimized according to market changes and individual needs.",
        h6: "What are the advantages of using the DCA strategy in Reactor?",
        h7: "Stable investment",
        h8: "DCA can maintain a stable investment rhythm no matter how the market price fluctuates by investing a fixed amount on a regular basis. This stability can help investors smooth out market fluctuations and reduce investment risks.",
        h9: "Average cost",
        h10: "The DCA strategy can achieve buying cost averaging through regular investment. When the market price falls, you will buy more asset units, and when the market price rises, you will buy fewer asset units. This This method can help you averagely buy assets in market fluctuations and reduce the risk of buying prices.",
    },
    demoRobot: {
        h1: "Reactor's replicating robot",
        h2: "Gain quantitative trading experience without risk",
        h3: 'See more',
        h4: "Why use a DEMO robot",
        h5: "DEMO robots provide a risk-free environment for users to learn, practice and test quantitative trading strategies. They can help users improve trading skills, evaluate robot performance, optimize trading strategies, and enhance understanding of the market. By using DEMO robot, users can build confidence and experience before actual trading, so as to better deal with the challenges in the real market.",
        h6: "Learn and practice",
        h7: "Users can learn and practice trading skills and strategies without using real funds for trading. It can help novices familiarize themselves with the operation of the trading platform, understand different trading strategies, and conduct real-time simulated transactions.",
        h8: "Risk Management",
        h9: "The DEMO robot enables users to test and verify trading strategies in actual market conditions, but does not take the risk of real funds. This allows users to evaluate the effectiveness and potential risks of strategies, and make necessary optimizations and adjustments for real trading achieve better results.",
        h10: "Strategy optimization and adjustment",
        h11: "Through the DEMO robot, users can optimize and adjust strategies. They can try different parameters and settings, observe changes in trading results, and make necessary adjustments based on feedback. This helps to improve and optimize trading strategies to Get better trading results",
    },
    download: {
        h1: "Start trading with Reactor for free!",
        h2: "Perfectly match Windows and Mac, support full business functions",
        h3: 'Reactor PC version',
        h4: "Download Windows and Mac OS clients to enjoy professional trading services.",
        h5: "Local download",
        h6: "Why choose Reactor?",
        h7: "Protect your assets",
        h8: "Reactor adopts the highest level of security protocols and encryption technology to ensure that your digital assets are always protected the strongest.",
        h9: "Insight into market trends",
        h10: "Reactor provides comprehensive data statistics and analysis tools to help you gain an in-depth understanding of market trends and price fluctuations. And adjust your trading strategies according to market conditions.",
        h11: "Quick and efficient trading experience",
        h12: "Reactor provides high-speed transaction execution and order processing to ensure that you can quickly respond to market changes and seize trading opportunities.",
    },
    exchange: {
        h1: "Binance",
        h2: "Chat",
        h3: 'What is Binance? ',
        h4: "Binance is the world's largest cryptocurrency exchange by trading volume. As of August 2022, the Binance exchange has a daily trading volume of $76 billion and has 90 million customers worldwide. The platform has become a trusted Membership, where users can buy, sell and store their digital assets, as well as access a list of over 350 cryptocurrencies and thousands of trading pairs. The Binance ecosystem now includes Binance Exchange, Labs, Launchpad, Info, Academy, Research , Trust Wallet, Charity, NFT, etc.",
        h5: "How does Reactor manage your account in Binance?",
        h6: "Through the API of the Reactor platform, seamlessly connect exchanges to realize automated transactions. With our powerful API functions, you can easily associate with multiple exchanges, automatically execute trading strategies, and achieve efficient trading operations. Fully Take advantage of the convenience of the API to easily manage your trading account and achieve better investment results.",
        h7: "Who is the founder of Binance?",
        h8: "This global company was co-founded in China by Changpeng Zhao and Yi He. Changpeng Zhao (CZ), a Chinese-Canadian developer and business executive, is the CEO of the company. He studied at McGill University in Montreal and has Track record as a successful entrepreneur. His past experience includes head of the Bloomberg Tradebook Futures R&D team, founder of Fusion Systems, and head of technology at Blockchain.com. Yi He is the CMO of Binance and head of Binance Labs, the company's venture capital arm Former Vice President of Yixia Technology, a leading mobile video technology company, and co-founder of OKCoin, a digital asset exchange.",
        h9: "When did Binance launch it?",
        h10: "Binance was launched in June 2017 and grew into the world's largest cryptocurrency exchange within 180 days.",
        h11: "Binance restricted countries?",
        h12: "According to the Binance Terms of Use, restricted regions include the United States, Singapore, and Ontario (Canada). However, certain countries/regions restrict usage or functionality may be restricted for regulatory reasons, including but not limited to China, Malaysia, Japan, UK and Thailand. Germany, Italy and the Netherlands also do not offer futures and derivatives. In September 2019, a separate dedicated platform Binance.US was launched for US clients.",
        h13: "How much is the Binance fee?",
        h14: "The platform is user-friendly, one of the cheapest, offers a large selection of trade types, and provides an advanced set of trading tools for experienced investors. It charges based on a tiered system, from regular users to VIP 9. For ordinary users, a 0.10% maker taker fee is charged for spot transactions. In July 2022, Binance announced that BTC spot transactions will be zero-fee transactions, and ETH/BUSD transactions will be zero-fee transactions in August.",
        h15: "Is it possible to use leverage or margin trading on Binance?",
        h16: "Traders can borrow funds and participate in margin trading on Binance Margin, which allows trading cryptocurrencies with up to 10x leverage. Users can also use derivative products such as Binance Futures settled in USDT, BUSD or other cryptocurrencies and Binance Options to trade.",


        A0: 'OKX',
        A1: 'What is OKX?',
        A2: "OKX is a centralized cryptocurrency exchange (CEX) and wallet platform based in Seychelles, serving millions of customers worldwide. The exchange offers basic trading including spot trading and simple options, as well as derivative trading including margin, futures, perpetual contracts, and options. Other products include trading bots, over-the-counter (OTC) trading, OKX Earn (savings, stablecoins, DeFi, staking, ETH 2.0, etc.), cryptocurrency loans, and the exchange's launch platform, Jumpstart.",
        A3: 'OKX Wallet claims to be a “Web3 Gateway” offering popular crypto wallets, decentralized exchanges (DEXs), NFT marketplaces, and decentralized applications (DApps). The wallet supports over 30 networks, including major blockchains such as Bitcoin, Ethereum, BNB Chain, Solana, Polygon, Avalanche, Fantom, and more.',
        A4: 'OKX also has its own native blockchain called the OKX Chain and a native token called OKB, which allows creators and projects to deploy DApps and enables developers to use infrastructure like OKX Oracle.',
        A5: 'In January 2022, OKEx rebranded to OKX with a new brand and roadmap.',
        A6: 'Who are the founders of OKX?',
        A6: 'OKX (formerly OKEx) is a subsidiary of OKCoin, owned by the OK Group.',
        A7: "The company was founded by Mingxing “Star” Xu in 2013 in China. Xu is a Chinese entrepreneur and holds a bachelor's degree in Applied Physics from Beijing University of Technology. Star Xu is the CEO of the OK Group.",
        A8: 'The current CEO of OKX is Jay Hao.',
        A9: 'When was OKX launched?',
        A10: 'The exchange (formerly known as OKEX) was launched in 2017.',
        A11: 'Where is OKX located?',
        A12: 'The company is headquartered in Seychelles.',
        A13: 'Countries restricted from using OKX',
        A14: 'The platform is available in over 200 countries, but residents of the United States cannot access its services.',
        A15: 'Which currencies does OKX support?',
        A16: 'The exchange lists over 350 cryptocurrencies, with over 500 trading pairs. Tokens available on the platform include BTC, ETH, OKB, AAVE, SOL, MATIC, XRP, DOGE, SHIB, DOT, and more.',
        A17: 'What are the fees on OKX?',
        A18: 'The exchange adopts a maker-taker fee model. Trading fees start at 0.10% and decrease with higher trading volumes. For regular users, the fee structure depends on the number of OKB (native token) held within the OKX ecosystem, while for advanced users, fees are based on their 30-day trading volume.',
        A19: 'Is leverage or margin trading available on OKX?',
        A20: 'OKX offers a maximum leverage of 10x for margin trading. In derivatives, it provides futures trading with up to 125x leverage and perpetual contract swap leverage. Additionally, traders can use leverage with crypto options, including BTC, ETH, and more.',

        B0: 'Fire coin',
        B1: 'What is Huobi Global?',
        B2: 'Huobi Global is an international digital asset exchange that can be used in more than 100 countries worldwide. The platform was founded in China in 2013 and provides trading and investment services to users globally. It has a daily trading volume of $4 billion.',
        B3: 'This centralized exchange (CEX) supports Initial Coin Offerings (ICO) and lists a variety of cryptocurrencies. The platform has over 500 assets and more than 300 trading pairs. Users can use insurance policies to protect their assets. The exchange has multiple customer support channels available 24/7, offering top-notch professional services.',
        B4: 'The exchange ecosystem includes spot trading, margin trading, futures trading, derivatives trading, staking, cryptocurrency loans, etc. Active traders and investors can explore opportunities on the Over-the-Counter (OTC) market platform and use various custom trading tools.',
        B5: 'Who is the founder of Huobi Global?',
        B6: 'The exchange was founded by Leon Li in Beijing in 2013. CEO Leon Li graduated from Tsinghua University with a major in automation in 2013. Before founding Huobi Global, Leon Li worked as a computer engineer at Oracle, one of the largest software companies in the United States.',
        B7: 'Where is Huobi Global located?',
        B8: 'The company is headquartered in the Seychelles and has offices in Hong Kong, South Korea, Japan, and the United States.',
        B9: 'Countries restricted from using Huobi Global',
        B10: 'Users from the following countries are not allowed to use all services provided by Huobi Global: China, the United States, Canada, Japan, Cuba, Iran, North Korea, Sudan, Syria, Venezuela, Singapore, and Crimea.',
        B11: 'Users from the following countries/regions are unable to use derivative trading services: Mainland China, Taiwan, Hong Kong, Israel, Iraq, Bangladesh, Bolivia, Ecuador, Kyrgyzstan, Sevastopol, Spain, the United Kingdom (only for retail users), and New Zealand.',
        B12: 'Japanese users can use the services of Huobi Japan exchange. Additionally, the company has announced plans to re-enter the US market.',
        B13: 'Which cryptocurrencies are supported by Huobi Global?',
        B14: 'The exchange supports over 400 digital assets and cryptocurrencies, including the most popular ones such as BTC, DOGE, ETH, LTC, XMR, HT, HUSD, and USDT.',
        B15: 'As of August 2022, the supported fiat currencies on the exchange include ALL, BGN, CHF, CZK, DKK, EUR, GBP, HRK, HUF, MDL, MKD, NOK, PLN, RON, SEK, TRY, UAH, HKD, AUD, and USD.',
        B16: 'What are the fees on Huobi Global?',
        B17: "Huobi fees have a tiered structure, where the transaction fees depend on the individual user's monthly trading volume.The platform charges a basic maker and taker fee of 0.2%, with discounts available for trading volumes of 5 million USD and above.Holders of Huobi Token(HT) can also enjoy discounts.Professional traders can enjoy the following discounts: maker fees starting from 0.0362% and taker fees starting from 0.0462%.",
        B18: 'Can leverage or margin trading be used on Huobi Global?',
        B19: 'The platform allows margin trading for a variety of cryptocurrencies, enabling users to borrow funds for up to 3x leverage in full-position mode. The interest rate is calculated on an hourly basis.',
        B20: 'Users can utilize up to 200x leverage for trading Bitcoin and Ethereum cryptocurrency futures contracts.',


        C0: 'PancakeSwap',
        C1: 'PancakeSwap Introduction',
        C2: "PancakeSwap is a popular decentralized exchange (DEX) that allows users to exchange BEP-20 tokens on the BSC chain. It is the largest exchange in the BSC ecosystem and one of the most popular decentralized exchanges (DEX) globally in terms of market share. The exchange operates on the automated market maker (AMM) model, allowing users to trade with liquidity pools. You can become a liquidity provider and earn liquidity provider (LP) tokens, which entitle you to a share of the exchange's trading fees.",
        C3: "In addition, LP token holders can participate in liquidity mining to earn the exchange's utility token, CAKE. This token can be staked in “syrup pools” for either a fixed-term or flexible-term period. Flexible-term staking allows users to unstake their tokens at any time, while fixed-term staking offers higher rewards (up to 52 weeks).",
        C4: 'Furthermore, PancakeSwap also offers a lottery game where users can win CAKE prizes. In the NFT marketplace, users can trade collectibles and set personalized profiles through the NFT profile system. Additionally, there is a perpetual futures exchange developed in collaboration with ApolloX, which features a variety of popular token trading pairs and offers perpetual futures trading services. Users can leverage more than their account balance in this exchange for trading popular token pairs.',
        C5: 'Who is the founder of PancakeSwap?',
        C6: "PancakeSwap was founded by anonymous individuals led by over 20 anonymous “chefs” working in the exchange's “kitchen”. The exchange is open-source and has been audited by well-known security companies such as Certik and Slowmist.",
        C7: 'When was PancakeSwap launched?',
        C8: 'PancakeSwap was launched in September 2020.',
        C9: 'Where is PancakeSwap located?',
        C10: 'As a decentralized exchange, the operating team adopts a remote work mode. However, according to Crunchbase, PancakeSwap is headquartered in Fukuoka, Japan.',
        C11: 'Countries restricted from using PancakeSwap',
        C12: 'As a decentralized exchange, there are no country restrictions. However, the following countries have been geographically blocked: Cuba, Iran, Zimbabwe, Iraq, Crimea, Syria, Sudan, Belarus, and the Democratic Republic of Congo.',
        C13: 'Supported token list on PancakeSwap',
        C14: 'All BEP-20 tokens are tradable, and it also supports a variety of popular futures trading pairs such as BTC/USDT, ETH/USDT, BNB/USDT, and all other major layer-one blockchains.',
        C15: 'What are the fees on PancakeSwap?',
        C16: 'At the time of writing, token swaps incur a 0.25% transaction fee. Of this, 0.17% is returned to the liquidity pool as a reward, 0.03% goes towards reserve funds, and the remaining 0.05% is used for buyback and burning.',
        C17: 'Can leverage or margin trading be used on PancakeSwap?',
        C18: 'For the largest trading pairs, the perpetual futures exchange allows up to 150x leverage, while for smaller trading pairs, the maximum leverage is 50x.',


        D0: 'UniSwap',
        D1: 'What is UniSwap?',
        D2: 'UniSwap was launched in 2018 and is one of the most popular decentralized cryptocurrency exchanges (DEX). The exchange was the first to introduce the Automated Market Maker (AMM) model, replacing the traditional order book model used in other exchanges. UniSwap operates on the Ethereum blockchain and securely facilitates the exchange of ERC-20 tokens between users through various smart contracts.',
        D3: 'The decentralized nature of the protocol means that there is no single centralized institution managing the exchange; instead, the exchanges are peer-to-peer (P2P). Additionally, UniSwap aims to address liquidity issues faced by other exchanges. UniSwap V3 is the third version of the protocol. This update not only enhances incentives but also gives individual liquidity providers greater control and flexibility. UniSwap V3 accounts for 46.5% of all decentralized exchange trading volume, and in May 2022, it set a record with a cumulative trading volume of $1 trillion.',
        D4: 'Who is the founder of UniSwap V3?',
        D5: 'Hayden Adams founded UniSwap. He previously studied at Stony Brook University and worked as a mechanical engineer at Siemens. Inspired by a blog post by Vitalik Buterin and a Reddit thread, Adams launched the protocol in November 2018 with funding from the Ethereum Foundation. After raising seed funding from Paradigm, Uniswap Labs raised $11 million in a Series A funding round led by Andreessen Horowitz in August 2020.',
        D6: 'UniSwap V3 was released in May 2021, approximately 2.5 years after the first version and one year after the release of UniSwap V2.',
        D7: 'Where is UniSwap located?',
        D8: 'Uniswap Labs, responsible for developing the UniSwap protocol and interface, is located in New York City, USA. As a decentralized finance (DeFi) protocol, it is accessible to individuals worldwide with internet access. However, users from certain countries face restrictions. As of July 2022, UniSwap has prohibited users from 10 countries from using its protocol. These countries include Belarus, Cuba, Iran, North Korea, Syria, Ivory Coast, Liberia, Sudan, Zimbabwe, and Iraq.',
        D9: 'What cryptocurrencies can be traded on UniSwap?',
        D10: 'As a decentralized peer-to-peer protocol, anyone can list any token on UniSwap. At the time of writing, the most popular trading pairs include USDC, Wrapped BTC, WETH, and DAI.',
        D11: 'What are the fees on UniSwap?',
        D12: 'Liquidity providers on UniSwap can start building liquidity pools with three fee tiers: 0.05%, 0.30%, and 1%. Through UNI governance proposals, additional fee tiers may be added in the future.',
        D13: 'Can leverage or margin trading be done on UniSwap?',
        D14: 'UniSwap does not offer leverage or margin trading functionality.',



        E0: 'DODO',
        E1: 'What is Dodo?',
        E2: 'Dodo is a decentralized exchange (DEX) based on Ethereum that functions as an “active market maker” (a customized version of an automated market maker or AMM). Dodo provides a way to issue and trade digital assets on the blockchain. The exchange offers liquidity from its own reserves as well as aggregated from other exchanges. According to its whitepaper, this allows the exchange to provide the best Web3 asset prices globally.',
        E3: 'Users can exchange different tokens in a permissionless manner using market or limit orders. Dodo also provides gas-free routing for swaps and higher liquidity (with zero slippage and no gas fees) through professional market makers. Additionally, there is an NFT marketplace where ERC-721 and ERC-1155 tokens can be traded. One way for liquidity providers to earn on the exchange is by adding liquidity, earning LP tokens, and receiving a share of trading fees. Another option is mining, where users can mine a single token or provide funds to specific liquidity pools for LP token mining. Users can also utilize flash loans or allocate capital in more efficient ways.',
        E4: 'Lastly, the exchange provides tools for developers to create tokens. Supported blockchains include Ethereum, BNB Chain, Polygon, and OKChain.',
        E5: 'Who are the founders of Dodo?',
        E6: 'According to information on CrunchBase, Dodo was founded by Qi Wang and Shichi Dai, both from China. The project raised $5 million from 17 different venture capital firms, including Coinbase Ventures, Defiance Capital, and Galaxy Digital.',
        E7: 'When was Dodo launched?',
        E8: 'Dodo was launched in August 2020.',
        E9: 'Where is Dodo located?',
        E10: 'According to information on CrunchBase, the exchange is headquartered in Hong Kong.',
        E11: 'Countries restricted from using Dodo',
        E12: 'At the time of writing, there is no information available regarding countries restricted from using the exchange.',
        E13: 'List of tokens supported by Dodo',
        E14: 'Dodo has been deployed on almost all EVM-compatible blockchains such as Arbitrum, Optimistic, Avalanche, and Aurora. As a result, the exchange supports a variety of different tokens on all these blockchains, including major tokens as well as Wrapped Ether (WETH) and Wrapped Bitcoin (WBTC).',
        E15: 'What are the fees on Dodo?',
        E16: 'Dodo charges a small usage fee (0.02 ETH) for creating new tokens, but no information is provided regarding transaction fees.',
        E17: 'Dodo charges a small usage fee (0.02 ETH) for creating new tokens, but no information is provided regarding transaction fees.',
        E18: 'Can leverage or margin trading be used on Dodo?',
        E19: "The platform currently does not support margin trading, but the project's roadmap states that leveraged trading services will be launched in 2022.",


        F2: 'About Curve (Ethereum)',
        F3: 'Introduction to Curve Finance',
        F4: "Curve Finance is a decentralized stablecoin trading liquidity pool. It does not use an order book and instead utilizes an automated market maker (AMM) model to match liquidity. As this protocol is decentralized and trustless, anyone can provide liquidity to one or multiple pools. The AMM's constant product formula ensures high efficiency and minimal slippage for traders.",
        F5: 'Curve pools are a variant of StableSwap executed through smart contracts and can be used to trade multiple tokens. Token exchanges can be made through stablecoin pairs (common pools) or wrapped tokens, with underlying collateral borrowed from another protocol (lending pools). Finally, metapools pair stablecoins with LP (Liquidity Provider) tokens from another pool.',
        F6: 'This protocol is supported by multiple blockchains such as Ethereum, Arbitrum, Aurora, Avalanche, Fantom, Harmony, Optimism, Polygon, xDai, and Moonbeam. To use the Curve protocol, users generally need to bridge funds from Ethereum to these blockchains. Due to the systemic importance of decentralized finance (DeFi), the protocol has attracted various other protocols in what is known as the Curve Wars to compete for governance rights.',
        F7: 'Who are the founders of Curve Finance?',
        F8: 'Curve Finance was founded by Michael Egorov, former co-founder and CTO of the cryptocurrency infrastructure protocol NuCypher. He also created a decentralized bank and lending network called LoanCoin. Prior to entering the cryptocurrency space, he studied at the Moscow Institute of Physics and Technology and Swinburne University of Technology.',
        F9: 'When was Curve Finance launched?',
        F10: 'Curve Finance was launched in June 2020, during the so-called "DeFi Summer." Therefore, it can be considered one of the cornerstones of the emerging DeFi industry.',
        F11: 'Where is Curve Finance located?',
        F12: 'According to CBInsight, Curve Finance is headquartered in Switzerland.',
        F13: 'Countries with restrictions on using Curve Finance',
        F14: 'As of the time of writing this article, there is no information about countries with restrictions on using Curve Finance. However, users from countries under US financial sanctions may face geolocation-based restrictions.',
        F15: 'List of tokens supported by Curve Finance',
        F16: 'The exchange supports liquidity pools for various mainstream stablecoins such as DAI, USDC, USDT, FRAX, and TUSD. Additionally, it also supports token exchanges for wrapped tokens like wBTC, wETH, and stETH (a native variant of Lido for staked Ether).',
        F17: 'What are the fees for Curve Finance?',
        F18: 'The protocol has a standard fee rate of 0.04%. Half of the fee is allocated to liquidity providers, while the other half goes to veCRV holders. Holders of veCRV can earn greater governance rights by periodically staking their tokens.',
        F19: 'Does Curve Finance allow leverage or margin trading?',
        F20: 'The exchange only offers token exchange services and does not provide leverage or margin trading.',

        G0: 'What is Balancer?',
        G1: 'Balancer is a decentralized automated market maker (AMM) protocol based on Ethereum, representing flexible building blocks for programmable liquidity',
        G2: 'By separating the AMM curve logic and mathematics from the core swapping functionality, Balancer becomes a scalable AMM that can contain any number of exchange curves and pool types. These include:',
        G3: 'Traditional 50/50x*y=k weighted pools',
        G4: 'Customizable weights, such as 80/20, for control over exposure',
        G5: 'Stablecoin swapping curves',
        G6: 'Nested pools (e.g., Boosted Pools)',
        G7: 'Dynamic-weight mining pools (e.g., Liquidity Bootstrapping Pools)',
        G8: 'Concentrated liquidity pools',
        G9: 'Managed pools with customizable parameters',
        G10: 'The entire protocol is built on top (e.g., Gyroscope)',
        G11: "This way, swappers, aggregators, and arbitrageurs can easily access all aggregated liquidity. The Balancer Vault optimizes batch trading and path logic, keeping gas costs and capital requirements extremely low. Each individual pool and project builds on top of Balancer's global liquidity, bringing depth to underlying assets and opening up swapping paths.",
        G12: 'Who uses Balancer?',
        G13: 'Balancer is a very useful tool for different participants in the DeFi space.',
        G14: 'Swappers can exchange between any two ERC20 tokens. This can be done through the Balancer Dapp or through aggregators like 1inch, Matcha, or Paraswap.',
        G15: 'Liquidity Providers (LPs) can add liquidity to the pools and earn swap fees, liquidity incentives, and other forms of revenue.',
        G16: 'Passive LPs can earn yield on already-compounded Aave tokens by leveraging the Boosted Pools.',
        G17: 'Arbitrageurs can interact with the pools using techniques like batch swapping and flash loans.',
        G18: 'BAL token holders can lock their tokens in veBAL and participate in the governance of the Balancer protocol.',
        J0: 'What is SushiSwap?',
        J1: 'SushiSwap is a decentralized exchange (DEX) that uses an AMM. The automated market maker creates trading markets for various token pairs using smart contracts. Liquidity providers supply funds to these smart contracts, enabling frictionless trading. It was one of the first exchanges to adopt this model and initially began as a fork of another popular DEX, UniSwap. It has since grown to become one of the largest exchanges in the DeFi space. It now operates on almost all EVM-compatible layer-one blockchains and certain layer-two solutions on Ethereum.',
        J3: 'The exchange aims to cater to DeFi traders and institutions who want to engage in permissionless and non-custodial trading. It eliminates liquidity issues and adopts a more equitable form of governance compared to other exchanges. Its native token can be used for voting on governance issues.',
        J4: 'Its core products include the Bento Box, which is a token vault that generates yield for deposited funds. Kashi lending is a lending and margin trading platform where anyone can create custom and high gas-efficient token markets. At the time of writing, MasterChef V2 is a liquidity provision program with over $1 billion in liquidity. Lastly, Miso provides projects with the opportunity to conduct token sales on the exchange, whether through fixed-price offerings or Dutch auction-style options.',
        J5: 'Who is the founder of SushiSwap?',
        J6: 'SushiSwap was initially founded by Chef Nomi (pseudonym). Later, this group of individuals left the project and handed over its management to two other developers known anonymously as sushiswap and 0xMaki. These two individuals were responsible for product development and operations, and eventually, the ownership of the exchange was transferred to Sam Bankman-Fried, the CEO of centralized exchange FTX.',
        J7: 'When was SushiSwap launched?',
        J8: 'SushiSwap was launched in August 2020.',
        J9: 'Where is SushiSwap located?',
        J10: 'The exchange does not have a central headquarters but operates in a decentralized manner.',
        J11: 'Countries that restrict the use of SushiSwap',
        J12: 'Currently, there is no information on countries that restrict or disable access to this exchange.',
        J13: 'Token list supported by SushiSwap',
        J14: 'Due to its presence on layer-one and layer-two blockchains, SushiSwap supports almost all ERC-20 tokens available in the market.',
        J15: 'What are the fees on SushiSwap?',
        J16: 'The exchange charges a fee of 0.3% for token swaps, following industry standards.',
        J17: 'Can leverage or margin trading be done on SushiSwap?',
        J18: 'SushiSwap provides a lending and margin trading platform called Kashi, which utilizes a unique isolated market framework.',

    },
    exchangeRobot: {
        h1: "Reactor's trading robot",
        h2: "Automatically execute trading strategies through advanced algorithms and real-time data analysis",
        h3: 'View details',
        h4: "The trading robot can conduct autonomous transactions according to the set rules and triggers, ensuring that you will not miss any trading opportunities",
        h5: "Trend market, whether it is an upward trend or a downward trend",
        h6: "Quickly capture price fluctuations and trade",
        h7: "Trading with range-bound volatility",
        h8: "Quick response to emergencies",
        h9: "In-depth understanding of transaction history",
        h10: "Through the display and analysis of data on the chain, you can track and analyze various transactions, understand market trends and trader behavior",
        h11: "Asset Flow Tracking",
        h12: "Through the visualization of data on the chain, you can track important information such as asset transfer paths and holder changes.",
        h13: "Smart contract analysis",
        h14: "Our tools allow you to deeply research and analyze the execution of smart contracts, helping you evaluate the security and reliability of contracts.",
    },
    faq: {
        h1: "Customer Support/FAQ",
        h2: "Looking for an answer? The answer might be here.",
        h3: "Beginner's Guide",
        h4: "What is Reactor?",
        h5: "How to set up Reactor?",
        h6: "Rebate",
        h7: "Liquidity Platform",
        h8: "DEX",
        h9: "Security Question",
        h10: "Member Benefits",
        h11: "Product",
        h12: "Transaction",
        h13: "See more",
        h14: "Reactor is an innovative quantitative trading platform",
        h15: "Reactor integrates advanced algorithms and technologies to help users trade more intelligently and efficiently. By automating the execution of trading strategies, providing real-time market data and analysis, and providing a wealth of trading tools, users can trade in the financial market Achieve better trading results. Whether it is a novice or a professional trader, Reactor provides a powerful trading platform to help them achieve their investment goals and obtain higher trading returns.",
        h16: "FAQ",
        h17: "How much profit can I make?",
        h18: "How does arbitrage work?",
        h19: "Do you guarantee profit?",
        h20: "How do I connect to the exchange?",
        h21: "How many robots can I open on one trading account?",
        h22: "Which base currency should I use?",
        h23: "Is Reactor safe?",
        h24: "Is my account still available after exiting the main HD wallet?",
    },
    helpCenter: {
        h1: "Help Center",
        h2: "Looking for an answer? The answer might be here.",
        h3: 'Browse topics',
        h4: "Explore our supported article and FAQ categories",
        h5: "What is Reactor?",
        h6: "Reactor provides users with accurate market analysis, quantitative strategies and trading tools. Through Reactor, users can achieve a higher return on investment and grow together with other quantitative traders in an active community.",
        h7: "How to set up Reactor?",
        h8: "A short guide on how to set up various basic features of Reactor. This is where you start as a new user!",
        h9: "Rebate",
        h10: "Liquidity Platform",
        h11: "DEX Quantization",
        h12: "Security Question",
        h13: "Member Benefits",
        h14: "DEMO robot",
        h15: "Manual trading",
        h16: "DCA",
        h17: "Track Order",
        h18: "Signal",
        h19: "Wallet",
        h20: "Copy Robot",
        h21: "Data on the chain",
        h22: "trading robot",
        h23: "Arbitrage Robot",
        h24: "FAQ",
        h25: "How much profit can I make?",
        h26: "How does arbitrage work?",
        h27: "Do you guarantee profit?",
        h28: "How do I connect to the exchange?",
        h29: "How many robots can I open on one trading account?",
        h30: "Which base currency should I use?",
        h31: "Is Reactor safe?",
        h32: "Is my account still available after exiting the main HD wallet?",
    },
    interestRobot: {
        // h1:"Reactor's arbitrage robot",
        // h2: "Use the price difference to make a profit",
        // h3:'See more',
        // h4:"Reactor's arbitrage bot exploits price differences to make a profit by conducting rapid transactions between different exchanges or chains at the same time.",
        // h5:"Quickly identify price differences and opportunities in the market",
        // h6:"Monitor prices and order books across multiple exchanges and markets",
        // h7: "Provide monitoring and statistical data, allowing users to track and analyze the performance of arbitrage transactions in real time",
        // h8: "Multiple Arbitrage Strategies",
        // h9: "Support a variety of arbitrage strategies, such as market arbitrage, exchange arbitrage, cross-chain arbitrage, futures and spot arbitrage, etc.",
        // h10: "Real-time monitoring and alarm",
        // h11: "Arbitrage robots will monitor the prices and order books of exchanges and markets in real time, and trigger alarms according to preset conditions",
        // h12: "Customization and flexibility",
        // h13: "Users can configure and adjust according to their own needs and preferences to meet personal trading strategies and risk preferences.",
        h1: "Capture the price difference and achieve stable income",
        h2: "A smart arbitrage robot that exploits price differences between markets",
        h3: 'See more',
        h4: "Arbitrage Robot",
        h5: "Using price differences to carry out cross-exchange arbitrage",
        h6: "Supports simultaneous connection to multiple trading platforms and blockchains",
        h7: "Including but not limited to Binance, Ouyi, Huobi, Ethereum, Binance Smart Chain, Tron, etc., real-time monitoring of market and price changes.",
        h8: "Market Scan and Comparison",
        h9: "Once the price difference is found, it will immediately execute the corresponding trading operation. Buy on one platform and sell on another platform at the same time to obtain the profit of the price difference",
        h10: "Real-time monitoring and reporting",
        h11: "Users can view detailed transaction records, profit statistics and chart displays in order to comprehensively evaluate the effect and potential opportunities of arbitrage transactions.",
        h12: "Execute transactions quickly",
        h13: "We are committed to providing fast and efficient transaction execution to ensure that users can seize market opportunities in a timely manner.",
        h14: "Our trading system has been carefully designed and optimized to handle a large number of orders and maintain a fast response to execute transactions, ensuring that you can trade at the best price. The trading system is built on a stable and reliable infrastructure with a high degree of Fault tolerance and redundancy mechanisms.",
        h15: "As a member of Reactor, you will enjoy exclusive server support, which will further improve the execution speed and efficiency of transactions.",
        h16: 'distributed node',
        h17: "computer room",
        h18: "server cluster",
        h19: "Provide dedicated server for free",
        h20: "Reactor allocates dedicated server resources for professional members for free, providing an independent and efficient operating environment for your robots",
        h21: "View details",
        h22: "Earn stable income every day",
        h23: "Set up coins and amounts, selected markets, and exchange arbitrage settings and market arbitrage settings, so that the robot can accurately capture price differences between different exchanges, and obtain arbitrage opportunities from them.",
        h24: "At the same time, the robot also has recovery and backlog functions, which can flexibly respond to market fluctuations and ensure that your investment continues to grow.",
        h25: "Learn more",
    },
    join: {
        h1: "Expand your Reactor exploration journey and choose the solution that suits you!",
        h2: "novice",
        h3: "Free",
        h4: "One template strategy can be used",
        h5: "One strategy combination can be created",
        h6: "40 strategy combinations can be executed",
        h7: "Support 2 exchanges",
        h8: "Investment Management Portfolio",
        h9: "Let's get started",
        h10: "Advanced",
        h11: "month",
        h12: "5 template strategies can be used",
        h13: "5 strategy combinations can be created",
        h14: "200 strategy combinations can be executed",
        h15: "Support 5 exchanges",
        h16: "Investment Management Portfolio",
        h17: "Can copy and track 5 robot transactions",
        h18: "Free use of simulated trading",
        h19: "Open MarketPlace Strategy",
        h20: "Advanced",
        h21: "10 template strategies can be used",
        h22: "15 strategy combinations can be created",
        h23: "500 strategy combinations can be executed",
        h24: "Support 20 exchanges",
        h25: "Investment Management Portfolio",
        h26: "Can copy and track 20 robot transactions",
        h27: "Get 2 sessions of monthly trading training courses",
        h28: "One-to-one customized strategy designer service",
        h29: "Support Reactor AI strategy and AI designer",
        h30: "Support application to become a cooperation strategist",
        h31: "Free access to VIP exclusive community",
        h32: "Professional",
        h33: "Unlimited use template policy",
        h34: "Unlimited creation of strategy combinations",
        h35: "Unlimited execution strategy combinations",
        h36: "Unlimited exchanges",
        h37: "Investment Management Portfolio",
        h38: "Can copy and track 50 robot transactions",
        h39: "All monthly trading training courses",
        h40: "One-to-one customized strategy designer service",
        h41: "Support Reactor AI strategy and AI designer",
        h42: "Additional technical indicator guidance",
        h43: "Support the application to become a specially invited strategist and share the profits together",
        h44: "Dedicated Server",
        h45: "Exclusive policy interval check",
        h46: "Membership level comparison",
        h47: "Manual trading",
        h48: "Trading Robot",
        h49: "Copy Robot",
        h50: "Market Maker Robot",
        h51: "Arbitrage Robot",
        h52: "Trading Training Course",
        h53: "simulated trading",
        h54: "One-to-one customized strategy",
        h55: "Apply to become a cooperation strategist",
        h56: "VIP Exclusive Community",
        h57: "Technical Indicators Guidance",
        h58: "Apply to become a specially invited strategist and share the profits together",
        h59: "Dedicated Server",
        h60: "Exclusive policy interval check",
    },
    manualTrading: {
        h1: "The power to make independent decisions",
        h2: "The perfect combination of quantitative trading and manual trading",
        h3: "See more",
        h4: "Reactor has excellent capabilities in automated trading, but why does it still require the participation of manual trading?",
        h5: "A more comprehensive and comprehensive trading experience",
        h6: "Reserve your trading decision-making power",
        h7: "Manual trading enables users to retain the final trading decision-making power, and you can still make the final decision on the transaction according to your own judgment and situation.",
        h8: "event-driven transactions",
        h9: "Certain events and news announcements may have a significant impact on the market, and these events may not be fully predictable and adaptable to automated trading systems.",
        h10: "Professional judgment and experience",
        h11: "Experienced traders have rich market knowledge and professional judgment, and can make more accurate decisions based on their own experience.",
        h12: "Quickly respond to market risks and protect your assets",
        h13: "Emergency Stop Clearance",
        h14: "When the market conditions are unfavorable, users can trigger the emergency stop liquidation function in Reactor, and sell all holding assets through one-click operation.",
    },
    market: {
        h1: "Cryptocurrency Market Today",
        h2: "On the Reactor platform, you can choose thousands of cryptocurrencies from more than 10 liquidity platforms for quantitative investment transactions. We have established partnerships with many well-known exchanges to provide you with a wide range of cryptocurrency choices. ",
        h3: 'Cryptocurrency market',
        h4: "USD",
        h5: "24 hours",
        h6: "Binance",
        h7: "Ouyi",
        h8: "Huobi",
        h9: "Currency",
        h10: "Price",
        h11: "24-hour ups and downs",
        h12: "24-hour trading volume",
        h13: "Market value",
        h14: "100 million",
        h15: "Total: 608 items, displayed on each page: 20 items",
    },
    merchantRobot: {
        h1: "Reactor's market maker robot",
        h2: "Better participate in the market, improve trading results, and get a better experience in the investment process",
        h3: 'See more',
        h4: "What is a market maker robot?",
        h5: "The market maker robot is a product that Reactor executes transactions through automated algorithms and programs. It automatically conducts buying and selling operations according to preset trading strategies and rules, and according to changes in market conditions and order books, dynamically Adjust quotations and trading volume. Reactor will monitor the order book of the exchange or the blockchain market, automatically submit buy and sell orders based on market depth and liquidity, and trade with a small spread (Spread). Target It is to obtain profits through frequent trading activities while providing more stable and continuous market liquidity.",
        h6: "Advantages of market maker robots",
        h7: "Profit Potential",
        h8: "Set the price difference between the buying and selling price, when the buying and selling order is executed, get the profit of the difference",
        h9: "Provide liquidity",
        h10: "Provide liquidity to market participants by continuously quoting and providing buy and sell orders.",
        h11: "Automated Trading",
        h12: "Quotes and orders can be adjusted in real time according to market conditions without manual intervention",
        h13: "Market Depth",
        h14: "The depth of the market is increased by continuously adjusting the buying and selling prices and order volumes.",
        h15: "Why use a market maker robot in Reactor?",
        h16: "Compared with traditional ordinary market makers, the market maker robot launched by Reactor has the advantages of automatic execution, technical advantages, rapid market adaptation, data analysis and optimization, and configuration flexibility.",
    },
    policyBacktest: {
        h1: "Strategy backtest",
        h2: "Users conduct simulated transactions on historical market data according to their own trading strategies to evaluate the effectiveness and potential profitability of strategies.",
        h3: 'See more',
        h4: "Flexible selection, precise backtest",
        h5: "Select trading pair and time period",
        h6: "You can choose the appropriate trading pair and time period based on your personal preferences and market conditions to more accurately evaluate the performance and potential risks of the strategy.",
        h7: "Find the best combination to improve strategy performance",
        h8: "Parameter setting and optimization",
        h9: "You can flexibly adjust parameters such as trading frequency, stop-profit and stop-loss conditions to find the best strategy configuration. Constantly trying and optimizing the parameter combination will help improve the profitability and stability of the strategy.",
        h10: "Detailed report for a comprehensive understanding of strategy performance",
        h11: "Backtest result analysis and report",
        h12: "Reactor will generate a detailed backtest result report. The report includes key indicators such as rate of return, winning rate, and maximum retracement, allowing you to fully understand the performance of the strategy. Help you discover the advantages and room for improvement of the strategy, and do it Adjust accordingly.",
        h13: "Visual display, in-depth understanding of strategy changes",
        h14: "Charts and indicators display",
        h15: "Reactor provides intuitive charts and indicators to allow you to better understand the results of strategy backtesting. You can view charts such as price charts, trading signals, and positions, and you can also use various technical indicators to assist in evaluating strategies effectiveness.",
        h16: "Tailor-made backtesting scheme",
        h17: "Reactor provides you with a private and customized backtesting experience: tailor-made backtesting solutions, personalized backtesting result analysis, real-time strategy adjustment and optimization, and customized trading recommendations",
        h18: "See more",
        h19: "Basic Principles of Reactor Strategy Backtesting",
        h20: "Simulated trading based on historical market data and set trading rules to evaluate and verify the performance of strategies",
        h21: "Historical market data",
        h22: "Reactor uses past market data, including indicators such as price and trading volume, to build a simulated historical market environment.",
        h23: "Simulated transaction execution",
        h24: "Based on the set trading rules, Reactor performs simulated trading execution on historical market data.",
        h25: "Evaluation of backtest results",
        h26: "Reactor will calculate key indicators based on the transaction records during the backtest period, such as yield rate, winning rate, maximum retracement, etc.",
    },
    rebate: {
        h1: "Share the link, earn extra income and earn coins with friends!",
        h2: "Invite your friends by sharing the link provided. You can earn up to 15% commission for every subscription your friends buy on Reactor.",
        h3: 'View details',
        h4: "Commission can be withdrawn",
        h5: "Unlimited promotion",
        h6: "The more you promote, the more rewards you get",
        h7: "Up to 15% commission",
        h8: "Why choose Reactor?",
        h9: "Protect your assets",
        h10: "Reactor adopts the highest level of security protocols and encryption technology to ensure that your digital assets are always protected the strongest.",
        h11: "Insight into market trends",
        h12: "Reactor provides comprehensive data statistics and analysis tools to help you gain an in-depth understanding of market trends and price fluctuations. And adjust your trading strategies according to market conditions.",
        h13: "Quick and efficient trading experience",
        h14: "Reactor provides high-speed transaction execution and order processing to ensure that you can quickly respond to market changes and seize trading opportunities.",
        h15: "We will protect your privacy and friend information",
        h16: "The security and privacy of our users is of the utmost importance to us. Reactor is equipped with top-of-the-line security infrastructure to ensure full protection of managed assets at all times.",
        h17: "View Privacy Agreement",
        h18: "How to use referral links?",
        h19: "Send to friends by email or private message.",
        h20: "Share on social media.",
        h21: "Published on a personal website.",
        h22: "Post a video on Youtube and post the link in the comments.",
        h23: "View invitation rules",
    },
    signal: {
        // h1: "Follow the wisdom of top traders to achieve stable profits",
        // h2: "We are proud to launch a new signal trading function, allowing you to easily follow the wisdom of top traders and achieve stable trading profits.",
        // h3:'View details',
        // h4: "Choosing the signal that suits you is the key to successful trading",
        // h5: "Signal Frequency",
        // h6:"Learn how often the signaler sends signals to determine how it fits your trading frequency and preferences.",
        // h7: "Number of Subscribers",
        // h8:"Know the number of subscribers to a signalr, which can be used as an indicator of signalr popularity and community acceptance.",
        // h9: "Rating and Performance",
        // h10: "Look at indicators such as how the signaler is rated, the number of signals in the last 3 months, the average profit per trade, etc., to assess past performance and reliability.",
        // h11: "Statistics and Comments",
        // h12: "Check out Signalr's live stats and other user reviews for more information and user experience about Signalr.",
        // h13: "What is a trading signal?",
        // h14: "Signal trading is an innovative trading method. By copying the trading strategies and operations of professional traders, you can participate in the trading activities of excellent traders without professional knowledge and experience. Let your trading action Sync with top traders.",
        // h15: "See more",
        h1: "The intelligent engine of automated trading",
        h2: "Acquire high-quality trading signal templates to achieve stable return on investment",
        h3: 'View details',
        h4: "High quality trading signal template",
        h5: "Strictly screened and verified",
        h6: "Get the actual operation of professional traders, including key indicators such as buying point, selling point, stop loss position, etc., and can be automatically copied after subscription",
        h7: "Under the strict screening of Reactor, the average winning rate of signals reaches 58.6%",
        h8: "There are 100+ signal providers in the mall, they are professional traders or institutions.",
        h9: "Learn more",
        h10: "Submit application for admission",
        h11: "Show your trading strategies and expertise in the mall, and share your successful experience with traders around the world, thereby expanding your influence and earning potential.",
        h12: "Contact us",
        h13: "You can use these data to more accurately choose the signal that suits you",
        h14: "Signal Frequency",
        h15: "Send the frequency of the signal to determine its adaptation to your trading preferences.",
        h16: "Number of Subscribers",
        h17: "Know the number of subscribers to SignalR, as an indicator of community acceptance",
        h18: 'Rating and performance',
        h19: "The number of signals in the last 3 months, the average profit per transaction and other indicators",
        h20: "Statistics and Comments",
        h21: "Check the real-time statistics and comments of other users at any time",
    },
    strategy: {
        h1: "Customize your unique trading strategy",
        h2: "Provide you with personalized trading solutions",
        h3: 'Contact us',
        h4: "Exclusive quantitative strategist",
        h5: "Personalized trading support and professional advice",
        h6: "Become a specially invited strategist and share the profits together",
        h7: "Dedicated Server",
        h8: "Personalized trading support and professional advice",
        h9: "We know that each client's investment goals and needs are unique, so we will work with you through our dedicated strategists to ensure that your trading strategies are closely aligned with your goals.",
        h10: "Strategy",
        h11: "Our strategists will work with you one-on-one to gain an in-depth understanding of your investment objectives, risk tolerance and time requirements.",
        h12: "A solution tailored for you",
        h13: "Our team will exceed expectations to help you achieve your goals",
        h14: "Private Client",
        h15: "Want to achieve your most ambitious investment goals? We've helped countless clients maximize returns, reduce expenses and grow portfolio assets.",
        h141: "Enterprise",
        h151: "Seeking higher financial efficiency and return on investment? We collaborate with numerous enterprises to provide customized solutions that help you achieve dual goals of capital growth and risk management.",
        h16: "Enterprise",
        h17: "We meet your every need",
        h18: "Reactor has an unrivaled advantage over our leading products",
        h19: "Exclusive quantitative strategist",
        h20: "Able to customize quantitative trading strategies according to your needs and goals. They will work closely with you to gain a deep understanding of your investment preferences and risk tolerance, and provide you with personalized trading advice based on market trends and data analysis.",
        h21: "Personalized trading support and professional advice",
        h22: "We will deeply understand your trading goals and needs, provide you with targeted trading strategies and suggestions, and help you optimize your trading decisions. Whether it is technical analysis, market research or risk management, we will provide you with professional guidance and support.",
        h23: "Become a specially invited strategist and share the profits together",
        h24: "As a guest strategist, you will have the opportunity to share your successful trading strategies and experience, and learn and grow together with other users. At the same time, you will enjoy the opportunity to share profits by cooperating with Reactor to share trading profits. ",
        h25: "Dedicated server",
        h26: "Our servers are characterized by high performance and low latency, which can quickly process transaction orders and ensure timely execution of transactions. By using dedicated servers, you can better grasp transaction opportunities and improve the success rate and effect of transactions. ",
        h27: "Customized service",
        h28: "Help you achieve your trading goals and achieve greater success in the field of quantitative trading. Regardless of your trading style, preference or goal, we will wholeheartedly provide you with the highest quality customized services",
        h29: "Contact us",
    },
    strategyMall: {
        h1: "Discover high-quality strategies and achieve transaction breakthroughs",
        h2: "Explore market opportunities with top traders",
        h3: 'View details',
        h4: "Strategy Mall",
        h5: "Optimize trading experience",
        h6: "Proven and Selected Trading Strategies",
        h7: "One-time purchase for lifetime use, you can obtain and hold the right to use the purchased trading strategy and this strategy can be downloaded.",
        h8: "Detailed performance data, including information such as historical returns, risk indicators, and transaction frequency.",
        h9: "We provide detailed transaction history to give you a complete understanding of the actual performance of each strategy.",
        h10: "Learn more about the strategy mall",
        h11: "Submit application for admission",
        h12: "Show your trading strategies and expertise in the mall, share your successful experience with traders around the world, so as to expand your influence and earning potential.",
        h13: "Contact us",
        h14: "Advantages of Reactor Strategy Mall?",
        h15: "Diversified strategic choices",
        h16: "Flexible autonomous transactions",
        h17: "Professional Strategist",
        h18: "Whether you prefer trend following, swing trading or arbitrage strategies, you can find a strategy that suits you",
        h19: "Ability to flexibly adjust trading parameters on the basis of maintaining strategies to adapt to changing market conditions",
        h20: "Strategists have market insights and successful trading records, they will provide strong support for your transactions",
        h21: "Learn more",
        h22: "Strict policy supervision",
        h23: "In order to ensure the transparency and reliability of trading strategies, each strategy needs to go through our review and testing process before going online.",
        h24: "Time-tested strategy",
        h25: "The strategy has undergone long-term real-time simulation and backtesting in our background, and the historical data we provide is completely real.",
    },
    business: {
        h1: "Create business opportunities and share success",
        h2: "We warmly welcome partners from all walks of life to cooperate with us. By participating in our partner program, you will have the opportunity to become our strategic partner to jointly create business opportunities and achieve win-win results.",
        h3: 'Contact us',
        h4: "Partner support and resource sharing",
        h5: "As a partner, you will enjoy the comprehensive support and resources we provide. We will provide professional training and technical support to help you better understand our products and services, and be able to provide customers with high-quality solutions In addition, you will receive our marketing support, including exclusive partner marketing activities, marketing materials and brand promotion. We will work with you to drive business growth and share success together.",
        h6: "Win-win cooperation, sustainable development",
        h7: "We value close cooperation with our partners, and believe that through joint efforts and mutual support, we can achieve greater success. We provide flexible cooperation models, including partner sales, distribution and promotion plans, to create lucrative opportunities for our partners At the same time, we encourage our partners to provide valuable feedback and suggestions to help us continue to improve and innovate and develop together. We believe that through the concept of win-win cooperation, we will continue to develop in the highly competitive market.",
        h8: "Join us",
        h9: "If you are interested in partnering with us, we welcome you to join us. Whether you are an innovative start-up, an established technology provider, or a company looking for new business opportunities, we would love to Cooperate with you to jointly create a road to success. Joining our partner program, you will have the opportunity to work closely with us, share our resources and experience, and jointly expand the market to achieve a win-win situation.",
    },
    dowView: {
        h1: "Download client",
        h2: "Explore the unlimited potential of quantitative trading and calmly deal with market fluctuations.",
        h3: 'Free trial',
    },
    contactView: {
        h1: "Contact us, or make an appointment to visit our office",
        h2: "Room 608, Baotong Building, Baomin 1st Road, Xin'an Street, Baoan District, Shenzhen",
        h3: 'Consult now',
    },
    helpView: {
        h1: "Need more help?",
        h2: "Leave a message to our team and we will get in touch with you during working hours.",
        h3: 'Contact Support',
    },
    token: {
        h1: "Contract",
        h2: "Market Statistics",
        h3: 'Market value ranking',
        h4: "market value",
        h5: "100 million",
        h6: "24-hour trading volume",
        h7: "24 hours highest",
        h8: "Historical Highest Price",
        h9: "Maximum supply",
        h10: "Ethereum (ETH) real-time market",
        h11: "The current price of Ethereum is $1,786.68, and its value has fallen by 0.31% in the past 24 hours. Ethereum is down 64.33% from its all-time high of $4,878.26. Ethereum ranks second in terms of market capitalization, accounting for 19.08% of the entire crypto market .Ethereum is a decentralized currency, you can buy, sell and quantitatively trade ETH through a centralized & decentralized liquidity platform on Reactor.",
        h12: "What is Ethereum",
        h13: "Ethereum is a decentralized blockchain platform that enables developers to build decentralized applications (dApps) on top of its blockchain. Launched by Vitalik Buterin in 2015, Ethereum With its ecosystem, active community and innovative features, it has become one of the most popular blockchain platforms in the world. Ether (ETH) is the native cryptocurrency of Ethereum and has multiple uses on the platform. It can be used in Gas is paid on the Ethereum network and used as a way to participate in the platform governance system. The two most important features of Ethereum are its interoperability and scalability. The former refers to the ability of Ethereum to integrate with other blockchains Networks and platforms connect and communicate, resulting in greater functionality and compatibility. Scalability, on the other hand, refers to Ethereum's ability to handle large volumes of transactions and data without compromising its performance or security. September 2022 In March, Ethereum underwent a major upgrade called The Merge, which has been called one of the most important upgrade events in the industry due to its complexity. It involves the transition from the proof-of-work consensus algorithm to the more efficient proof-of-stake algorithm , the purpose is to reduce energy consumption, further improve scalability, and improve security. Therefore, this requires verification of transactions on Ethereum through staking rather than mining. Although long-awaited, the merger is only Ethereum to complete Vitalik's vision Rather, it is the beginning of a series of major updates that must be made.",
        h14: "Why Reactor is the right choice for quantitative trading",
        h15: "Diversified investment",
        h16: "Scatter funds in exchanges and decentralized wallets, effectively reducing risks and increasing potential returns.",
        h17: "Support demo account",
        h18: "Provide a real market simulation environment, allowing you to conduct investment practice and strategy testing in a risk-free manner.",
        h19: "Transaction data statistics",
        h20: "You can use our robots to trade with confidence and enjoy a convenient and safe investment experience.",

        BTC0: 'Bitcoin is a decentralized cryptocurrency. In the whitepaper released in 2008, an individual or group using the pseudonym Satoshi Nakamoto first discussed the mechanics of Bitcoin. In January 2009, Bitcoin was officially launched.',
        BTC1: 'Bitcoin is a currency based on a peer-to-peer network, where all transactions are conducted directly between equal and independent network participants without the need for any intermediary or facilitator. In the words of Satoshi Nakamoto himself, creating Bitcoin was intended to allow "a party to make a payment directly to another without going through a financial institution."',
        BTC2: 'Before Bitcoin, there were some similar concepts of decentralized digital currencies, but what sets Bitcoin apart is that it was the first cryptocurrency to be applied in real-life scenarios.',

        ETH0: 'Ethereum is a decentralized open-source blockchain system that has its own native cryptocurrency called Ether. Ethereum also serves as a platform for many other cryptocurrencies, and it can execute decentralized smart contracts.',
        ETH1: 'Vitalik Buterin introduced Ethereum for the first time in a whitepaper published in 2013. Along with other co-founders, Mr. Buterin launched the Ethereum blockchain on July 30, 2015, through a crowdfunded project initiated in the summer of 2014.',
        ETH2: 'Ethereum claims to be a globally decentralized application platform that allows users from all over the world to write and run software without being censored, experiencing downtime, or encountering fraudulent activities.',

        USDT0: 'Tether (USDT) is a digital currency designed to reflect the value of the US dollar. It was launched in 2014 with the intention of creating a stable cryptocurrency that could be used similarly to digital dollars or "stablecoins." The price of Tether is anchored to the US dollar.',
        USDT1: "Initially utilizing the Bitcoin network's Omni Layer as its transport protocol, Tether can now be used as an ERC-20 token on the Ethereum blockchain. Tether has been issued on Bitcoin (Omni and Liquid protocols), Ethereum, EOS, and TRON blockchains.",
        USDT2: "Tether is issued by Tether Limited, a company with the same CEO as the cryptocurrency exchange Bitfinex. Tether has claimed to have 100% reserves in US dollars backing its tokens. However, in 2019, the company's lawyer stated that only 74% of Tether was backed by reserves. Tether's explanation was that loans from its subsidiary were also part of the reserve.",

        BNB0: 'Binance Coin (BNB) was introduced through an initial coin offering in 2017, and 11 days later, the Binance cryptocurrency exchange went live. Initially launched as an ERC-20 token on the Ethereum network, it had a maximum total supply of 200 million tokens, with 100 million tokens issued during the ICO. However, with the launch of the Binance Chain mainnet in April 2019, the ERC-20 BNB tokens were swapped for BEP2 BNB tokens at a 1:1 ratio, no longer relying on the Ethereum network.',
        BNB1: 'As a payment method, Binance Coin allows users to use this utility token to offset trading fees on the Binance exchange and participate in token sales on the Binance Launchpad. Binance Coin is also the primary currency of the Binance Decentralized Exchange (DEX).',

        XRP0: 'First, it is important to understand the difference between RippleCoin (XRP), Ripple, and RippleNet. RippleCoin is the currency running on the digital payment platform called RippleNet. RippleNet is built on a distributed ledger database known as the XRP Ledger. While Ripple operates RippleNet, the XRP Ledger is open-source and not based on blockchain technology.',
        XRP1: 'RippleNet is a real-time gross settlement (RTGS) system designed to facilitate instant currency transfers globally. Although XRP is the native cryptocurrency of the XRP Ledger, users can still transact using any currency on the platform.',
        XRP2: "The idea behind the Ripple payment platform was initially proposed by Ryan Fugger in 2004. However, it wasn't until Jed McCaleb and Chris Larsen took over the project in 2012 that the plans for creating Ripple (then known as OpenCoin) as a company came into fruition.",

        USDC0: 'USD Coin (USDC) is a stablecoin pegged to the US dollar at a 1:1 ratio. Each unit of this cryptocurrency is backed by one US dollar reserve, including cash and short-term US Treasury bonds. The consortium behind this asset claims that USDC is issued by regulated financial institutions.',
        USDC0: 'USDC is a stablecoin pegged to the US dollar at a 1:1 ratio. Each unit of this cryptocurrency is backed by one US dollar reserve, including cash and short-term US Treasury bonds. The consortium behind this asset claims that USDC is issued by regulated financial institutions.',
        USDC1: 'This stablecoin was initially released in limited quantities in September 2018. In simple terms, the slogan for USD Coin is "Digital money for a digital age" - designed for a world where cashless transactions are becoming increasingly common.',
        USDC2: 'USD Coin has already seen several use cases. Apart from providing a safe haven for cryptocurrency traders during volatile periods, the driving force behind the stablecoin also states that it can enable businesses to accept digital asset payments and disrupt a range of industries, including decentralized finance (DeFi) and gaming.',

        DOGE0: 'Dogecoin (DOGE) gets its name from the internet meme "DOGE" and features a Shiba Inu on its logo. This open-source cryptocurrency was created by Billy Markus from Portland, Oregon, and Jackson Palmer from Sydney, Australia, and was derived from Litecoin in December 2013. The founders of Dogecoin aimed to create a fun and lighthearted virtual currency that could appeal to a broader audience beyond the core Bitcoin community, as its image originated from the popular online meme "doge." Tesla CEO Elon Musk has expressed his fondness for Dogecoin multiple times.',

        ADA0: 'Cardano is a blockchain platform that utilizes a proof-of-stake mechanism. Its claimed goal is to bring about positive global change by empowering "changemakers, innovators, and visionaries."',
        ADA1: 'This open-source project also aims to "redistribute power from unaccountable institutions to the individual" - striving to create a more secure, transparent, and fair society.',
        ADA2: "Cardano was founded in 2017, and the ADA token is designed to ensure that owners can participate in the network's operations. Thus, those holding this cryptocurrency have the right to vote on any proposed improvements.",
        ADA3: 'The team believes that their technology is ideal for developing decentralized apps and smart contracts using modular concepts. There are already some compelling use cases; for example, agricultural companies can use Cardano to track the journey of fresh produce from the land to the table, while other products of the platform can securely store educational qualifications or support retailers in combating counterfeit goods.',

        SOL0: 'Solana is a powerful open-source project that leverages the permissionless nature of blockchain technology to provide decentralized finance (DeFi) solutions. While the idea and initial work for this project started in 2017, Solana was officially launched by the Solana Foundation based in Geneva, Switzerland, in March 2020.',
        SOL1: 'For more information about this project, please refer to our in-depth research on Solana.',
        SOL2: 'The Solana protocol aims to facilitate the creation of decentralized applications (DApps). It seeks to improve scalability by combining a historical proof (PoH) consensus with underlying proof-of-stake (PoS) consensus mechanisms.',

        TRX0: "Tron (TRX) was founded by Justin Sun through the Tron Foundation in 2017, with its headquarters located in Singapore. In 2017, the Tron Foundation's Initial Coin Offering (ICO) created 100 billion TRX, raising $70 million in the process.",
        TRX1: 'The Tron protocol describes the entire architecture of an operating system based on blockchain technology that allows developers to create smart contracts and decentralized applications (DApps), freely publish, own, and store data and other content. According to the Tron Foundation, the ecosystem surrounding this network offers scalability on a massive scale and consistent reliability, able to process transactions rapidly through high throughput computing.',
        TRX2: "Originally created on the Ethereum network, Tron migrated to its own network in 2018. ERC-20 TRX token holders exchanged their tokens for TRX digital currency on the Tron network, leading to the destruction of Tron's tokens on Ethereum.",

        Matic0: 'Polygon (formerly known as Matic Network) is the first well-structured, easy-to-use Ethereum scaling and infrastructure development platform. Its core component is the Polygon SDK, a modular and flexible framework that supports building various types of applications.',
        Matic1: 'For more information about this project, please refer to our in-depth research on Polygon Matic.',
        Matic2: 'With Polygon, one can create optimistic rollups, ZK rollups, standalone chains, or any other type of infrastructure that developers require.',

        LTC0: 'Litecoin (LTC) is a cryptocurrency designed to provide fast, secure, and low-cost payment functionality through the use of blockchain technology.',
        LTC1: 'This cryptocurrency is created based on the Bitcoin (BTC) protocol but differs in hash algorithm, hard cap, block transaction time, and some other factors. Litecoin has a block time of just 2.5 minutes and extremely low transaction fees, making it ideal for small transactions and point-of-sale payments.',
        LTC2: 'Litecoin was introduced by an anonymous user on GitHub on October 7, 2011, and its network went live five days later on October 13, 2011. Since then, its usage and acceptance among merchants have grown significantly, consistently maintaining a top 10 market cap position among cryptocurrencies.',
        LTC3: 'Created by former Google employee Charlie Lee, Litecoin aims to be a simplified version of Bitcoin, adopting many of the same features (albeit with smaller importance and universality).',

        AVAX0: 'Avalanche is a layer-one blockchain that serves as a platform for decentralized applications and custom blockchain networks. It is one of the competitors to Ethereum, aiming to replace Ethereum as the most popular smart contract blockchain. Its goal is to achieve up to 6,500 transactions per second without sacrificing scalability.',
        AVAX1: "This is made possible by Avalanche's unique architecture. The Avalanche network consists of three independent chains: X-Chain, C-Chain, and P-Chain. Each chain serves a different purpose, which differs from the method used by Bitcoin and Ethereum (i.e., having all nodes validate all transactions). The Avalanche blockchain even utilizes different consensus mechanisms based on its usage.",
        AVAX2: 'Since the launch of its mainnet in 2020, Avalanche has been actively developing its own DApps and DeFi ecosystem. Various Ethereum-based projects, such as SushiSwap and TrueUSD, have integrated with Avalanche. Additionally, the platform is continuously working to improve its interoperability with Ethereum, for example, through the development of bridges.',

        ETC0: 'Ethereum Classic (ETC) is a hard fork of Ethereum (ETH) launched in July 2016. Its primary function is to serve as a smart contract network capable of hosting and supporting decentralized applications (DApps). Its native token is ETC.',
        ETC1: "Since its launch, Ethereum Classic has sought to differentiate itself from Ethereum, with the two networks' technical roadmaps gradually diverging over time.",
        ETC2: 'Following a major hack that resulted in 3.6 million Ethereum being stolen, Ethereum Classic took the initial steps to maintain the integrity of the existing Ethereum blockchain.',

        ARB0: '',

        SUI0: 'Sui is a low-latency, high-throughput layer-one blockchain. With its instant transaction settlement capability, Sui provides a suitable blockchain for on-chain use cases such as DeFi and GameFi. The blockchain is written in Rust, a programming language focused on fast and secure transaction execution. Rust is also used by another high-speed blockchain, Solana.',
        SUI1: "Sui's stated goal is to 'lead the next billion users into Web 3' by deploying decentralized applications (DApps) with superior smart contract architecture compared to competitors like Ethereum. The blockchain achieves this through a process called 'transaction parallelization,' which allows transactions to be processed in a 'parallel protocol.' As claimed by Sui, this 'parallel scaling' can achieve better data organization within its Byzantine Fault Tolerant Proof-of-Stake (PoS) consensus mechanism.",

        DAI0: '',

        LINK0: '',

        UNI0: 'Uniswap is a popular decentralized trading protocol known for facilitating automatic token swaps in decentralized finance (DeFi).',
        UNI1: 'As an example of an automated market maker (AMM), Uniswap was launched in November 2018 but gained significant popularity this year due to the DeFi phenomenon and increased token trading.',
        UNI2: 'Uniswap aims to automate token trading while remaining fully open to anyone holding tokens, thereby increasing efficiency compared to traditional exchanges.',
        UNI3: 'Uniswap addresses liquidity issues through automated solutions, avoiding problems that plagued early decentralized exchanges and improving efficiency.',
        UNI4: 'In September 2020, Uniswap took it a step further by creating its own governance token, UNI, which was retroactively granted to past users of the protocol. This not only adds profit potential but also gives users the ability to shape its future - an appealing aspect of decentralized entities.',

        EOS0: '',

        OP0: ',',




    },
    wallet: {
        h1: "Web3 Quantitative Transaction Wallet",
        h2: "Unlock unlimited trading potential and achieve an excellent quantitative trading experience.",
        h3: 'Free trial',
        h4: "Diversified investment",
        h5: "Asset Security",
        h6: "Fast and efficient trading experience",
        h7: "Advantages of Reactor Wallet Quantification",
        h8: "Wallet quantification provides a more secure, flexible and personalized transaction experience",
        h9: "Personal asset security escort",
        h10: "Reactor uses a personal wallet account for transactions, and you have greater control and security than exchanges.",
        h11: "Integrated quantification, seamlessly across multiple blockchains",
        h12: "Reactor achieves seamless asset transfer and transactions by integrating multiple blockchain networks, without being restricted by a single chain, and obtaining more investment opportunities.",
        h13: "Smart low gas fee route",
        h14: "Reactor can automatically analyze the current network situation and choose the route with the lowest gas fee to execute the transaction to reduce transaction costs.",
        h15: "Personal asset safety escort",
        h16: "Reactor uses a personal wallet account for transactions, and you have greater control and security than exchanges.",
        h17: "Create your main HD wallet and start exploring the Web3 world",
        h18: "The main HD wallet is your unique identity and can be connected to multiple non-custodial wallets",
        h19: "Through the main HD wallet, you can easily manage and control multiple non-custodial wallets, without creating and remembering different private keys and passwords for each wallet.",
        h20: "The main HD wallet provides detailed statistical functions, allowing you to understand your transaction history, asset distribution and income, and help you make more informed investment decisions.",
        h21: "The main HD wallet is highly flexible and can be personalized according to your needs and preferences, allowing you to better customize and manage your digital assets.",
        h22: "Customized strategy",
        h23: "Reactor can conduct customized transactions according to your own needs and strategies. You can conduct quantitative transactions according to your own trading strategies and risk preferences, without being restricted by the exchange API",
        h24: "Strong support for multi-chain environment",
        h25: "Reactor is able to trade on different chains. This gives you more options and flexibility to trade based on market conditions and asset allocation",
    },
    message: {
        home: {
            h1: "Requires NFT to qualify",
            h2: "Please do not repeat",
            h3: "Bind superior first",
            h4: "Authorization succeeded, please complete subsequent operations",
            h5: "Authorization failure",
            h6: "Participate in success",
            h7: "Failed to participate"
        },
        nft: {
            h1: "Authorization succeeded, please complete subsequent operations",
            h2: "Authorization failure",
            h3: "Transfer successful",
            h4: "Transfer failed",
            h5: "Purchase successful",
            h6: "Purchase failed"
        },
        node: {
            h1: "Bind the superior first",
            h2: "Authorization succeeded, please complete subsequent operations",
            h3: "Authorization failure",
            h4: "Purchase successful",
            h5: "Purchase failed",
            h6: "Bind superior first",
            h7: "Authorization succeeded, please complete subsequent operations",
            h8: "Authorization failure",
            h9: "Purchase successful",
            h10: "Purchase failed",
            h11: "Claim successful",
            h12: "Collection failure"
        },
        team: {
            h1: "Copy successfully",
            h2: "Replication failure",
            h3: "Binding successful",
            h4: "Binding failed"
        },
        game: {
            h1: "Receive successfully",
            h2: "Claim failure"
        },
        unit: {
            h1: "No linked wallet"
        }
    },
}
// {{$t("home.")}}
// this.$t("message.")