<template>
    <div class="token">
        <div class="main">
            <div class="info">
                <div class="left">
                    <div class="h1"> <img :src="codeData.logo" alt=""> {{ codeData.name }} <span>{{ codeData.code }}</span>
                    </div>
                    <!-- <div class="h2">{{ $t('token.h1') }}：0x34a9c05b638020a07bb153bf624c8763bf8b4a86 <img
                            src="../assets/icon_copy.png" alt=""></div> -->
                    <!-- <div class="h3">¥{{ (codeData.market_value / 10000).toFixed(2) }}w</div> -->
                    <div class="h3">¥{{ codeData.current_price_usd }}</div>
                    <div class="h4">
                        <div class="num">≈$ {{ (codeData.market_value_usd / 10000).toFixed(2) }}w <span
                                v-if="codeData.change_percent > 0">+</span><span>{{ codeData.change_percent }} %</span>
                        </div>
                        <div class="btm">
                            <span @click="updateTime(60)" :class="time == 60 ? 'clickColor' : ''">1小时</span>
                            <span @click="updateTime(240)" :class="time == 240 ? 'clickColor' : ''">4小时</span>
                            <span @click="updateTime(1440)" :class="time == 1440 ? 'clickColor' : ''">日</span>
                            <span @click="updateTime(10080)" :class="time == 10080 ? 'clickColor' : ''">周</span>
                            <span @click="updateTime(43200)" :class="time == 43200 ? 'clickColor' : ''">月</span>
                            <span @click="updateTime(525600)" :class="time == 525600 ? 'clickColor' : ''">年</span>
                        </div>
                    </div>
                    <div class="chart">
                        <div id="myChart"></div>
                    </div>
                </div>
                <div class="right">
                    <div class="h1">{{ $t('token.h2') }}</div>
                    <div class="big">
                        <div class="row">
                            {{ $t('token.h3') }}
                            <br>
                            {{ codeData.rank }}
                        </div>
                        <div class="row">
                            {{ $t('token.h4') }}
                            <br>
                            {{ formatNumber(codeData.market_value_usd || 0) }}{{ $t('token.h5') }}
                        </div>
                        <div class="row">
                            {{ $t('token.h6') }}
                            <br>
                            {{ formatNumber(codeData.vol_usd || 0) }}{{ $t('token.h5') }}
                        </div>
                        <div class="row">
                            {{ $t('token.h7') }}
                            <br>
                            {{ codeData.kline_data }}
                        </div>
                        <div class="row">
                            {{ $t('token.h8') }}
                            <br>
                            {{ codeData.high_price }}
                        </div>
                        <div class="row">
                            {{ $t('token.h9') }}
                            <br>
                            <!-- 1.2{{ $t('token.h5') }} {{ codeData.name }} -->
                            {{ codeData.supply }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="zhi === 0">
                <div class="text">
                    {{ $t('token.BTC0') }}
                </div>
                <div class="text">
                    {{ $t('token.BTC1') }}
                </div>
                <div class="text">
                    {{ $t('token.BTC2') }}
                </div>
            </div>
            <div v-if="zhi === 1">
                <!-- hint -->
                <div class="text">
                    {{ $t('token.ETH0') }}
                </div>
                <div class="text">
                    {{ $t('token.ETH1') }}
                </div>
                <div class="text">
                    {{ $t('token.ETH2') }}
                </div>
            </div>
            <div v-if="zhi === 2">
                <div class="text">
                    {{ $t('token.USDT0') }}
                </div>
                <div class="text">
                    {{ $t('token.USDT1') }}
                </div>
                <div class="text">
                    {{ $t('token.USDT2') }}
                </div>
            </div>
            <div v-if="zhi === 3">
                <div class="text">
                    {{ $t('token.BNB0') }}
                </div>
                <div class="text">
                    {{ $t('token.BNB1') }}
                </div>
            </div>
            <div v-if="zhi === 4">
                <div class="text">
                    {{ $t('token.XRP0') }}
                </div>
                <div class="text">
                    {{ $t('token.XRP1') }}
                </div>
                <div class="text">
                    {{ $t('token.XRP2') }}
                </div>
            </div>
            <div v-if="zhi === 5">
                <div class="text">
                    {{ $t('token.USDC0') }}
                </div>
                <div class="text">
                    {{ $t('token.USDC1') }}
                </div>
                <div class="text">
                    {{ $t('token.USDC2') }}
                </div>
            </div>
            <div v-if="zhi === 7">
                <div class="text">
                    {{ $t('token.ADA0') }}
                </div>
                <div class="text">
                    {{ $t('token.ADA1') }}
                </div>
                <div class="text">
                    {{ $t('token.ADA2') }}
                </div>
                <div class="text">
                    {{ $t('token.ADA3') }}
                </div>
            </div>
            <div v-if="zhi === 8">
                <div class="text">
                    {{ $t('token.DOGE0') }}
                </div>
            </div>
            <div v-if="zhi === 9">
                <div class="text">
                    {{ $t('token.SOL0') }}
                </div>
                <div class="text">
                    {{ $t('token.SOL1') }}
                </div>
                <div class="text">
                    {{ $t('token.SOL2') }}
                </div>
            </div>

            <div class="box2">
                <div class="title">{{ $t('token.h14') }}</div>
                <div class="big">
                    <div class="small">
                        <img src="../assets/icon_diversified.png" alt="">
                        <div class="name">{{ $t('token.h15') }}</div>
                        <div class="hint">{{ $t('token.h16') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/icon_simulation.png" alt="">
                        <div class="name">{{ $t('token.h17') }}</div>
                        <div class="hint">{{ $t('token.h18') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/icon_data.png" alt="">
                        <div class="name">{{ $t('token.h19') }}</div>
                        <div class="hint">{{ $t('token.h20') }}</div>
                    </div>
                </div>
            </div>

            <div class="box3">
                <div class="title">{{ $t('token.h21') }}</div>
                <div class="big">
                    <div v-if="currencyList.length > 0" class="shichangTopMain">
                        <div class="my-small" v-for="(data, index) in currencyList" :key="index"
                            @click="goToken(data, index)">
                            <img :src="data.logo" alt="">
                            <div class="my-info">
                                <div class="infoTop">
                                    <div class="infoTopLeft">{{ data.name }} <span>{{ data.code }}</span></div>
                                    <div class="infoTopRight">{{ data.change_percent }}%</div>
                                </div>
                                <div class="infoBtm">${{ data.current_price_usd }}</div>
                                <button class="infoButton">查看</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';
import { NumSplic } from "../unit/tool";
import big from "big.js";
import dowView from './simViews/dowView.vue';
import axios from 'axios';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "token",
    data() {
        return {
            zhi: '',
            currencyList: [],
            time: 240,
            codeData: {},
            timeList: [],
            dataList: [],
            option: {
                xAxis: {
                    data: ['2017-10-24', '2017-10-25', '2017-10-26', '2017-10-27']
                },
                yAxis: {},
                //             dataZoom: [
                //     {
                //       type: 'inside',
                //       start: 50,
                //       end: 100
                //     },
                //     {
                //       show: true,
                //       type: 'slider',
                //       top: '90%',
                //       start: 50,
                //       end: 100
                //     }
                //   ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                grid: {
                    left: '10%',
                    right: '10%',
                    bottom: '15%'
                },
                series: [
                    {
                        type: 'candlestick',
                        data: [
                            [20, 34, 10, 38],
                            [40, 35, 30, 50],
                            [31, 38, 33, 44],
                            [38, 15, 5, 42]
                        ]
                    }
                ]
            }
        };
    },
    created() {
        this.zhi = JSON.parse(this.$route.query.zhi ? this.$route.query.zhi : 0);
        const sad = this.$route.query.asd ? this.$route.query.asd : null;
        if(sad){
            this.allFetchData(sad);
        }
    },
    watch: {
        '$route': function (newRoute, oldRoute) {
            this.zhi = JSON.parse(this.$route.query.zhi ? this.$route.query.zhi : 0);
            // console.log('this.$route.query', JSON.parse(this.$route.query.asd));
            this.codeData = JSON.parse(this.$route.query.asd ? this.$route.query.asd : {});
            if (this.codeData.kline_data) {
                const totalList = this.codeData.kline_data.split(',');
                this.codeData.kline_data = this.getMaxValue(totalList);
            }
            this.getKLineData();
            this.fetchData();
        }
    },
    methods: {
        // 查询货币列表
        allFetchData(sad) {
            // 发起GET请求
            axios.get('https://ow.rac.art/api/coin/web-coinrank?page=1&type=-1&pagesize=2000&webp=1')
            .then(response => {
                if(!response.data.data){
                    return
                }
                this.codeData = response.data.data.find(item=> item.name == sad);
                if (this.codeData.kline_data) {
                    const totalList = this.codeData.kline_data.split(',');
                    this.codeData.kline_data = this.getMaxValue(totalList);
                }
                this.getKLineData();
                this.fetchData();
            })
            .catch(error => {
            });
        },
        goToken(code, n) {
            if (this.$router.currentRoute.path === '/token' && this.zhi == n) {
                // 当前路径已经是目标路径，不进行重复导航
                return
            }
            this.zhi = n;
            this.$router.push({
                path: "/token",
                query: { asd: JSON.stringify(code), zhi: this.zhi },
            });
        },
        // 查询货币列表
        fetchData() {
            // 发起GET请求
            axios.get('https://ow.rac.art/api/coin/web-coinrank?page=1&type=-1&pagesize=6&webp=1')
                .then(response => {
                    this.currencyList = response.data.data;
                })
                .catch(error => {
                });
        },
        formatNumber(num) {
            if (num >= 100000000) {
                return (num / 100000000).toFixed(2);
            } else {
                return num.toString();
            }
        },
        updateTime(time) {
            this.time = time;
            this.getKLineData();
        },
        getMaxValue(arr) {
            if (arr.length === 0) {
                return null; // 如果数组为空，返回null或其他适当的值
            }
            return Math.max(...arr);
        },
        getTimeBytimstamp(timstamp) {
            const date = new Date(timstamp);
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，需要加1，并使用slice函数补零
            const day = ('0' + date.getDate()).slice(-2); // 使用slice函数补零
            const hours = ('0' + date.getHours()).slice(-2); // 使用slice函数补零
            const minutes = ('0' + date.getMinutes()).slice(-2); // 使用slice函数补零
            const seconds = ('0' + date.getSeconds()).slice(-2); // 使用slice函数补零
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        getKLineData() {
            if (!this.codeData.code) {
                return
            }
            const temestamp = Math.floor(new Date().getTime() / 1000);
            this.timeList = [];
            this.dataList = [];
            axios.get('https://ow.rac.art/api/v1/kline/market?tickerid=binance_' +
                this.codeData.name + '_usdt&period=' +
                this.time + '&reach=' +
                temestamp + '&since=&utc=0&webp=1')
                .then(response => {
                    response.data.data.kline.forEach(item => {
                        this.timeList.push(this.getTimeBytimstamp(item[0] * 1000));
                        this.dataList.push([item[1], item[2], item[3], item[4]]);
                    })
                    this.drawLine();
                })
                .catch(error => {
                });
        },
        drawLine() {
            let myChart = echarts.init(document.getElementById("myChart"));
            // 绘制图表
            this.option.xAxis.data = this.timeList;
            this.option.series[0].data = this.dataList;
            myChart.setOption(this.option);
        },
    },
    computed: {},
    mounted() {
        this.drawLine();
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.clickColor {
    color: #1F4ED8 !important;
}

.token {
    background-color: #f0f2f5;
    border: 1px solid #f0f2f5;

    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .info {
            height: 413 * $defaultSize;
            display: flex;
            justify-content: space-between;
            margin-top: 88 * $defaultSize;

            .left {
                width: 73%;
                height: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
                border-radius: 20 * $defaultSize;
                padding: 30 * $defaultSize 25 *$defaultSize;
                display: flex;
                flex-direction: column;

                .h1 {
                    display: flex;
                    align-items: center;
                    font-size: 30 * $defaultSize;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;

                    img {
                        width: 30 * $defaultSize;
                        margin-right: 15 * $defaultSize;
                    }

                    span {
                        font-size: 20 * $defaultSize;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #999999;
                        margin-left: 10 * $defaultSize;
                    }
                }

                .h2 {
                    font-size: 11 * $defaultSize;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    img {
                        width: 15 * $defaultSize;
                    }
                }

                .h3 {
                    margin-top: 18 * $defaultSize;
                    font-size: 24 * $defaultSize;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #00A76A;
                }

                .h4 {
                    font-size: 14 * $defaultSize;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;

                    .num {
                        span {
                            color: #00A76A;
                        }
                    }

                    display: flex;

                    .btm {
                        margin-left: 80 * $defaultSize;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        color: #333333;
                        font-size: 10 * $defaultSize;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;

                        span {
                            cursor: pointer;
                            margin-left: 18 * $defaultSize;

                            &:hover {
                                color: #1F4ED8;
                            }
                        }
                    }
                }

                .chart {
                    width: 100%;
                    flex: 1;

                    #myChart {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .right {
                width: 23%;
                height: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
                border-radius: 20 * $defaultSize;
                padding: 25 * $defaultSize 25 *$defaultSize;

                display: flex;
                flex-direction: column;

                .h1 {
                    font-size: 16 * $defaultSize;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 15 * $defaultSize;
                }

                .big {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    justify-content: space-between;

                    .row {
                        font-size: 11 * $defaultSize;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        border-bottom: 1px solid #E7EBEF;
                        padding: 10 * $defaultSize 0;
                    }
                }
            }
        }

        .hint {
            font-size: 20 * $defaultSize;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-top: 25 * $defaultSize;
        }

        .text {
            font-size: 13 * $defaultSize;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F5559;
            margin-top: 15 * $defaultSize;
        }

        .box2 {
            .title {
                text-align: center;
                font-size: 20 * $defaultSize;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                margin-top: 88 * $defaultSize;
            }

            .big {
                margin-top: 25 * $defaultSize;
                display: flex;
                justify-content: space-between;

                .small {
                    width: 25%;
                    text-align: center;

                    img {
                        width: 88 * $defaultSize;
                        transform: scale(1);
                        transition: transform 0.5s;
                    }

                    img:hover {
                        transform: scale(1.1)
                    }

                    .name {
                        font-size: 20 * $defaultSize;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #000000;
                    }

                    .hint {
                        font-size: 13 * $defaultSize;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4F5559;
                        margin-top: 15 * $defaultSize;
                    }
                }
            }
        }

        .box3 {
            display: block;
            padding: 26 * $defaultSize 0 *$defaultSize;
            margin-top: 50 * $defaultSize;

            .title {
                font-size: 18 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
            }

            .shichangTopMain {
                margin-top: 20 * $defaultSize;
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .my-small:hover {
                    background-color: #e8eaf5;
                    border-radius: 10px;
                    transition: 1s;
                }

                .my-small {
                    padding: 15px;
                    width: 33%;
                    display: flex;
                    align-items: center;
                    margin-top: 20 * $defaultSize;
                    cursor: pointer;
                    transition: 0.5s;


                    img {
                        width: 27 * $defaultSize;
                        margin-right: 10 * $defaultSize;
                    }

                    .my-info {
                        width: 90%;
                        position: relative;
                        cursor: pointer;
                        transition: 0.5s;

                        &:hover .infoButton {
                            display: block;
                            cursor: pointer;
                            transition: 0.5s;
                        }

                        &:hover .infoTopRight {
                            display: none;
                            cursor: pointer;
                            transition: 0.5s;
                        }

                        .infoTop {
                            display: flex;
                            justify-content: space-between;

                            .infoTopLeft {
                                font-size: 14 * $defaultSize;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #333333;

                                span {
                                    font-size: 14 * $defaultSize;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: #A0A4A7;
                                }
                            }

                            .infoTopRight {
                                font-size: 11 * $defaultSize;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FC3B38;
                            }
                        }

                        .infoBtm {
                            font-size: 11 * $defaultSize;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #A0A4A7;
                        }

                        .infoButton {
                            display: none;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);

                            width: 35 * $defaultSize;
                            height: 20 * $defaultSize;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                            background-color: #183ead;
                            border: 0;
                            border-radius: 5 * $defaultSize;
                        }
                    }
                }
            }
        }
    }
}
</style>
  