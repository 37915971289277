module.exports = {  // 越南语
    topBar: {
        linkWallet: "Bắt đầu miễn phí bot đầu tiên của bạn tại Reactor. Tìm hiểu thêm",
        unit: "USD",
        h1: "Nhà",
        h2: "Sản phẩm",
        h2_1: "Khả năng backtest của k-line",
        h2_1_1: "Kiểm tra lại chiến lược",
        h2_1_2: "Thực hiện kiểm tra lại giao dịch mô phỏng",
        h2_1_3: "Giao dịch thủ công",
        h2_1_4: "Đặt lệnh giới hạn cho chuỗi",
        h2_1_5: "K dòng và dữ liệu trên chuỗi",
        h2_1_6: "Dữ liệu K-line và chuỗi giao dịch truy vấn thời gian thực",
        h2_2: "Nhiều loại robot",
        h2_2_1: "Robot sao chép",
        h2_2_2: "Thương nhân chuyên nghiệp tài liệu",
        h2_2_3: "Robot giao dịch",
        h2_2_4: "Làm việc 24 giờ, loại trừ giao dịch tình cảm",
        h2_2_5: "Robot tạo thị trường",
        h2_2_6: "Làm việc 24 giờ, loại trừ giao dịch tình cảm",
        h2_2_7: "Robot kinh doanh chênh lệch giá",
        h2_2_8: "Chênh lệch tỷ giá hối đoái chéo",
        h2_2_9: "Người máy DEMO",
        h2_2_10: "Có được kinh nghiệm giao dịch mà không gặp rủi ro",
        h2_3: "Khu mua sắm mẫu đã sẵn sàng để sử dụng",
        h2_3_1: "Trung tâm thương mại Signal",
        h2_3_2: "Đưa ra quyết định giao dịch chính xác hơn",
        h2_3_3: "Trung tâm mua sắm chiến lược",
        h2_3_4: "Giao dịch như một chuyên gia",
        h2_3_5: "Robot Mall",
        h2_3_6: "Các loại robot tự động",
        h3: "chợ",
        h3_1: "Token",
        h3_2: "Hỗ trợ nền tảng thanh khoản",
        h3_2_1: "Binance",
        h3_2_2: "Binance là sàn giao dịch tiền điện tử lớn nhất thế giới tính theo khối lượng giao dịch, kể từ tháng 8 năm 2022, Binance giao dịch…",
        h3_2_3: "Huobi",
        h3_2_4: "Huobi Global là sàn giao dịch tài sản kỹ thuật số quốc tế hoạt động tại hơn 100 quốc gia. Nền tảng này được ra mắt vào năm 2013…",
        h3_2_5: "Ou Yi",
        h3_2_6: "OKX là một sàn giao dịch tập trung tiền điện tử (CEX) và nền tảng ví có trụ sở tại Seychelles phục vụ hàng triệu khách hàng trên toàn thế giới…",
        h3_2_7: "PancakeSwap",
        h3_2_8: "PancakeSwap là nhà phát triển của một sàn giao dịch phi tập trung (DEX) hàng đầu trong ngành tập trung vào tiền điện tử tiên tiến…",
        h3_2_9: "UniSwap",
        h3_2_10: "Uniswap là một sàn giao dịch phi tập trung (DEX), lần đầu tiên giới thiệu mô hình Nhà tạo lập thị trường tự động (AMM). Với…",
        h3_2_11: "DODO",
        h3_2_12: "Dodo là một sàn giao dịch phi tập trung dựa trên Ethereum với Nhà tạo lập thị trường tích cực hoặc Nhà tạo lập thị trường tự động (AMM…",
        h3_3: "Xem tất cả nội dung được hỗ trợ",
        h3_3: "Nhiều nền tảng giao dịch hơn",
        h4: "Giá",
        h5: "Doanh nghiệp",
        h5_1: "Giới thiệu về chúng tôi",
        h5_2: "Dịch vụ xuất sắc, không ngừng đổi mới. Phối hợp chặt chẽ với khách hàng để cung cấp giải pháp chất lượng cao. Thiết lập mối quan hệ hợp tác lâu dài và cùng nhau phát triển.",
        h5_3: "OEM",
        h5_4: "Các giải pháp tùy chỉnh chuyên nghiệp để đáp ứng nhu cầu riêng của bạn và cung cấp cho bạn các dịch vụ được cá nhân hóa.",
        h5_5: "Hoạt động kinh doanh",
        h5_6: "Quản lý và mở rộng doanh nghiệp của bạn một cách hiệu quả để giúp bạn đạt được tăng trưởng bền vững và dẫn đầu thị trường.",
        h5_7: "Mạng xã hội",
        h5_8: "Khám phá các kênh truyền thông xã hội của chúng tôi, theo dõi những phát triển mới nhất và cùng nhau khám phá các lĩnh vực và xu hướng đổi mới.",
        h5_9: "Đối tác",
        h5_10: "Cùng nhau sáng tạo, chia sẻ tài nguyên và cơ hội, đạt được lợi ích chung và kết quả đôi bên cùng có lợi, và cùng nhau làm việc trên con đường dẫn đến thành công.",
        h5_11: "Liên hệ với chúng tôi",
        h5_12: "Hãy liên hệ với chúng tôi, chúng ta hãy thảo luận về các cơ hội kinh doanh và cộng tác trong một mối quan hệ đối tác xứng đáng.",
        h6: "Trợ giúp",
        h7_1: "Tiếng Trung giản thể",
        h7_2: "Tiếng Anh",
        h7_3: "Tiếng Nhật",
        h7_4: "Hàn Quốc",
        h7_5: "Tiếng Trung phồn thể",
        h7_6: "Thái",
        h7_7: "Tiếng Mã Lai",
        h7_8: "Tiếng Việt",
        h8: "Ví liên kết",
        h9: "Thêm",
        h9_1: "Giảm giá",
        h9_2: "Mời bạn bè của bạn tham gia nền tảng của chúng tôi thông qua liên kết và bạn sẽ có cơ hội nhận thêm phần thưởng hoa hồng.",
        h9_3: "Ví tiền",
        h9_4: "An toàn và đáng tin cậy, thuận tiện cho việc lưu trữ, dễ quản lý tài sản kỹ thuật số của bạn, đảm bảo an ninh tài chính và bảo vệ quyền riêng tư.",
        h9_5: "chiến lược tùy chỉnh",
        h9_6: "Hiểu biết sâu sắc về mục tiêu đầu tư của bạn và các chiến lược đầu tư được thiết kế phù hợp đáp ứng nhu cầu của bạn.",
        h9_7: "DCA",
        h9_8: "Đầu tư cố định thông minh, giá trị gia tăng ổn định, tránh rủi ro biến động thị trường, thu nhập nắm giữ lâu dài ổn định.",
    },
    btm: {
        h1: "Sản phẩm",
        h2: "Ví tiền",
        h3: 'Thị trường',
        h4: "Tín hiệu",
        h5: "Dữ liệu trên chuỗi",
        h6: "chiến lược tùy chỉnh",
        h7: "Giảm giá",
        h8: "Robot",
        h9: "Robot giao dịch",
        h10: "Người máy DEMO",
        h11: "Robot sao chép",
        h12: "Robot kinh doanh chênh lệch giá",
        h13: "Robot tạo thị trường",
        h14: "Giới thiệu về chúng tôi",
        h15: "OEM",
        h16: "Hợp tác quảng cáo",
        h17: "Hoạt động kinh doanh",
        h18: "Mạng xã hội",
        h19: "Đối tác",
        h20: "Cộng đồng",
        h21: "Dịch vụ",
        h22: "Tải xuống",
        h23: "Thỏa thuận dịch vụ",
        h24: 'Thỏa thuận về quyền riêng tư',
        h25: "Cảnh báo rủi ro",
        h26: "Trung tâm trợ giúp",
        h27: "Theo chúng tôi",
        h28: "Tải xuống cục bộ",
        h29: "Đánh giá tín dụng doanh nghiệp doanh nghiệp hạng tín dụng AAA",
        h30: "Bằng danh dự quốc gia trong lĩnh vực đấu thầu và đấu thầu",
        h31: "Xí nghiệp Lưu trữ Liêm chính của Bộ Thương mại Cộng hòa Nhân dân Trung Hoa",
        h32: "Doanh nghiệp trình diễn quản lý liêm chính",
        h33: "An ninh mạng công cộng Quảng Đông số 44030502009469 ICP Quảng Đông số 2022043961-1 Giấy phép kinh doanh viễn thông giá trị gia tăng B1-20214279 Văn bản mạng Quảng Đông: (2018) số 5045-1753 © 2018 - 2023 TIANYI. TẤT CẢ QUYỀN ĐƯỢC ĐẶT CHỖ",
        h34: "Máy khách để bàn",

        A1: 'Kiểm tra lại chiến lược',
        A2: 'Giao dịch thủ công',
        A3: 'Dữ liệu K-line và mạng chuỗi',
        A4: 'Cửa hàng tín hiệu',
        A5: 'Cửa hàng chiến lược',
        A6: 'Robot giao dịch',
        A7: 'Robot DEMO',
        A8: 'Robot sao chép',
        A9: 'Robot thương mại lợi nhuận',
        A10: 'Robot thị trường tạo lập',
        A11: 'Về chúng tôi',
        A12: 'OEM',
        A13: 'Mạng xã hội',
        A14: 'Liên hệ chúng tôi',
        A15: 'Chính sách riêng tư',
        A16: 'Điều khoản dịch vụ',
        A17: 'Tuyên bố miễn trừ trách nhiệm',
        A18: 'Trung tâm trợ giúp',
        A19: 'Chiến lược tùy chỉnh',
        A20: 'Hoa hồng hoàn trả',
        A21: 'Ví tiền',
        A22: 'DCA',
        A23: 'Tải xuống',
        A24: "nhiều hơn",

    },
    home: {
        h1: "Giao dịch tự động, mua thấp bán cao",
        h2: "Bàn giao dịch thông minh hội tụ nhiều chức năng",
        h3: 'Robot giao dịch tiền kỹ thuật số có thể tùy chỉnh',
        h4: "Dùng thử miễn phí",
        h5: "Hỗ trợ sàn giao dịch tập trung & sàn giao dịch phi tập trung",
        h6: "Đầu tư đa dạng",
        h7: "Định lượng chuyên nghiệp",
        h8: "Thông số chỉ thị",
        h9: "Dữ liệu giao dịch",
        h10: "Giảm giá lời mời",
        h11: "Kết nối ví của bạn và trao đổi tài khoản và quản lý chúng với trạm giao dịch của chúng tôi",
        h12: "Chúng tôi biết tầm quan trọng của việc đầu tư đa dạng đối với danh mục đầu tư. Với Reactor, bạn có thể dễ dàng quản lý nhiều sàn giao dịch và ví cùng một lúc, đa dạng hóa quỹ linh hoạt hơn, phân phối đầu tư vào các tài sản và thị trường khác nhau, đồng thời giảm thiểu rủi ro của một tài sản hoặc thị trường một cách hiệu quả.",
        h11_1: "Phân tích chính xác, hiệu quả giao dịch tuyệt vời",
        h12_1: "Reactor có thể tiến hành phân tích thị trường chính xác và tối ưu hóa chiến lược giao dịch để đạt được kết quả giao dịch xuất sắc. Sử dụng các thuật toán và chỉ báo dữ liệu nâng cao để cải thiện hiệu quả giao dịch, nhằm kiểm soát thị trường tốt hơn và thu được lợi nhuận ổn định hơn.",
        h11_2: "Tùy chỉnh linh hoạt, tối ưu các chỉ số giao dịch",
        h12_2: "Cung cấp cho bạn cài đặt tham số chỉ báo linh hoạt và tùy chỉnh chiến lược giao dịch theo nhu cầu cá nhân. Bằng cách điều chỉnh các tham số chỉ báo để tối ưu hóa hiệu suất giao dịch, thích ứng tốt hơn với những thay đổi của thị trường, nhận ra trải nghiệm giao dịch được cá nhân hóa và thu được lợi tức đầu tư tốt hơn.",
        h11_3: "Giám sát thời gian thực, nắm bắt chính xác động thái thị trường",
        h12_3: "Thông qua dữ liệu thời gian thực, bạn có thể đưa ra quyết định giao dịch chính xác hơn và nắm bắt cơ hội đầu tư kịp thời. Không còn bỏ lỡ bất kỳ thời điểm quan trọng nào và thông qua giám sát dữ liệu, bạn có thể nắm bắt chính xác động lực của thị trường.",
        h11_4: "Chia sẻ để kiếm tiền, mời nhận thưởng",
        h12_4: "Bằng cách mời bạn bè tham gia vào nền tảng, bạn có thể nhận được tới 15% phần thưởng lời mời. Thông qua chức năng chống hoa hồng lời mời, bạn có thể chia sẻ niềm vui giao dịch với bạn bè của mình, đồng thời, bạn cũng có thể nhận được thu nhập chống hoa hồng đáng kể.",
        h13: "Tìm hiểu thêm",
        h14: "Mạng hỗ trợ",
        h15: "Được hỗ trợ",
        h16: "Nền tảng thanh khoản",
        h17: "Tiền điện tử",
        h18: "Đã xử lý",
        h19: "Lệnh giao dịch",
        h20: "Lý tưởng để giao dịch chuyên nghiệp",
        h21: "Sử dụng nền tảng thanh khoản tổng hợp trên Reactor để giao dịch tiền điện tử, thực hiện lệnh thị trường và giao dịch lệnh giới hạn trên chuỗi, thực hiện các chiến lược giao dịch tự động và xem dữ liệu giao dịch.",
        h22: "Đội ngũ chuyên nghiệp và công nghệ",
        h23: "Chúng tôi cam kết cung cấp các dịch vụ minh bạch và đáng tin cậy, luôn đặt lợi ích của khách hàng lên hàng đầu. Chiến lược đầu tư của chúng tôi dựa trên nghiên cứu đầy đủ và sự cẩn trọng để đảm bảo rằng chúng tôi mang đến cho bạn những cơ hội đầu tư tốt nhất.",
        h24: 'Tiền và thuật toán của bạn an toàn',
        h25: "Ví tiền",
        h26: "Chúng tôi sẽ không ghi lại thông tin khóa cá nhân của bạn và robot sẽ tuân thủ nghiêm ngặt các tiêu chuẩn bảo mật của sàn giao dịch và ví",
        h27: "Xác thực",
        h28: "Áp dụng nhiều biện pháp xác thực và mã hóa để đảm bảo an toàn cho tài sản và thông tin giao dịch của bạn",
        h29: "Giao dịch an toàn",
        h30: "Bạn có thể tự tin sử dụng robot của chúng tôi để giao dịch và tận hưởng trải nghiệm đầu tư thuận tiện và an toàn.",
        h31: "Thiết bị đầu cuối giao dịch tiền điện tử để quản lý thuận tiện tất cả danh mục tài sản mã hóa",
        h32: "Thiết bị đầu cuối giao dịch của chúng tôi tập hợp các chức năng của nhiều sàn giao dịch và ví, cho phép bạn thực hiện các hoạt động giao dịch và quản lý tài sản trên một giao diện thống nhất.",
        H33: "Khám phá khả năng vô hạn của blockchain cùng nhau",
        h34: "Chúng tôi cung cấp các giải pháp blockchain toàn diện và dịch vụ tư vấn chuyên nghiệp. Đội ngũ của chúng tôi có nhiều kinh nghiệm và chuyên môn về blockchain để giúp bạn hiểu các khái niệm cốt lõi của blockchain, nắm vững các công nghệ và công cụ quan trọng và khám phá cách áp dụng blockchain cho các tình huống thực tế."
    },
    about: {
        h1: "TIANYI là nhà cung cấp dịch vụ phát triển giải pháp kỹ thuật số từ máy chủ/mạng đến lớp ứng dụng",
        h2: "Chúng tôi là một cộng đồng sáng tạo kỹ thuật số từ khắp nơi trên thế giới.",
        h3: 'Đội ngũ của chúng tôi bao gồm một nhóm các chuyên gia giàu kinh nghiệm và đam mê. Các giải pháp chuyên nghiệp và dịch vụ sáng tạo của chúng tôi trong nhiều lĩnh vực, bao gồm phát triển ứng dụng, Web3, thiết kế trực quan và sáng tạo, thiết kế và lập kế hoạch ứng dụng, máy chủ/mạng và phòng máy tính, tiếp thị và hệ thống văn phòng. ',
        h4: "Dịch vụ một cửa",
        h5: "Chúng tôi cung cấp đầy đủ các dịch vụ một cửa, bao gồm tất cả các lĩnh vực từ lập kế hoạch, thiết kế, phát triển đến vận hành.",
        h6: "Tiêu chuẩn chung",
        h7: "Thiết kế tiêu chuẩn toàn cầu, sử dụng các công cụ không có mã và tích cực giới thiệu các công nghệ Web3tran như NFT và Metaverse.",
        h8: "Máy chủ và mạng IDC",
        h9: "Chúng tôi cung cấp các giải pháp máy chủ hiệu suất cao và kiến ​​trúc mạng IDC có thể mở rộng để đảm bảo rằng doanh nghiệp của bạn hoạt động trơn tru, an toàn và đáng tin cậy.",
        h10: "Sứ mệnh, Tầm nhìn và Giá trị",
        h11: "Nhiệm vụ",
        h12: "Sử dụng sức mạnh của sáng tạo kỹ thuật số để giải quyết vấn đề và đóng góp lâu dài cho xã hội. Sứ mệnh của chúng tôi là cung cấp cho xã hội các giải pháp có ý nghĩa và thúc đẩy tiến bộ xã hội thông qua đổi mới và sáng tạo.",
        h13: "Liên hệ với chúng tôi hoặc đặt lịch hẹn đến văn phòng của chúng tôi",
        h14: "Phòng 608, tòa nhà Baotong, đường Baomin 1, phố Xin'an, quận Bao'an, Thâm Quyến",
        h15: "Tham khảo ngay",
    },
    chainData: {
        h1: "K dòng và dữ liệu trên chuỗi",
        h2: "Sự kết hợp hoàn hảo giữa hiểu biết sâu sắc về xu hướng thị trường",
        h3: 'Tích hợp thị trường lịch sử và thông tin chuỗi khối thời gian thực',
        h4: "Tối ưu hóa thông tin giao dịch của bạn",
        h5: "Xem chi tiết",
        h6: "Ưu điểm tuyệt vời của việc tích hợp dữ liệu K-line và on-chain",
        h7: "Thông tin chi tiết theo thời gian thực về thị trường trong Lò phản ứng, giao dịch ra quyết định chính xác",
        h8: "Hiển thị xu hướng giá lịch sử và theo dõi dữ liệu giao dịch trong mạng chuỗi khối",
        h9: "Kiểm tra chiều cao khối bản ghi giao dịch chuỗi khối mới nhất và thời gian xác nhận giao dịch",
        h10: "Tạo tín hiệu giao dịch chính xác dựa trên phân tích dữ liệu K-line và on-chain",
        h11: "Biểu đồ và đồ họa trực quan để hiển thị dữ liệu K-line và chain",
        h12: "Kiểm tra trạng thái vị thế, tỷ lệ phân bổ tài sản và thay đổi giá trị thị trường của từng tài sản",
        h13: "Cũng có Lò phản ứng cụ thể",
        h14: "Nâng cao sự thấu hiểu và hiệu quả của việc ra quyết định giao dịch",
        h15: "đa chuỗi",
        h16: "Có thể tùy chỉnh",
        h17: "Tín hiệu",
        h18: "Reactor hỗ trợ giao dịch trên nhiều mạng chuỗi khối. Chẳng hạn như Ethereum, Wave Field, Chuỗi thông minh Binance, v.v.",
        h19: "Bạn có thể tùy chỉnh chiến lược giao dịch, quy tắc kiểm soát rủi ro và cài đặt thử nghiệm để đạt được trải nghiệm giao dịch được cá nhân hóa",
        h20: "Hiển thị các điểm, đề xuất giao dịch và mẹo hoạt động trên biểu đồ đường K và bạn nên hiểu mối quan hệ giữa tín hiệu và thị trường",
        h21: "Thông tin chuyên sâu về dữ liệu thời gian thực của chuỗi khối",
        h22: "Thông qua Reactor, hiểu sâu hơn về nội dung chính và tầm quan trọng của dữ liệu trên chuỗi",
        h23: 'Dữ liệu thị trường theo thời gian thực',
        h24: "Theo dõi giao dịch chuỗi khối",
        h25: "Phân tích hợp đồng thông minh",
        h26: "Truy vấn địa chỉ và số dư",
        h27: "Quay ngược dữ liệu lịch sử",
        h28: "Phân tích chỉ báo tùy chỉnh",
        h29: "Bao gồm các chỉ số chính như giá tiền điện tử, khối lượng giao dịch và vốn hóa thị trường để giúp bạn hiểu đầy đủ về động lực của thị trường.",
        h30: "Theo dõi và hiển thị lịch sử giao dịch, bao gồm thời gian giao dịch, cặp giao dịch, khối lượng giao dịch và hướng giao dịch.",
        h31: "Giúp bạn hiểu sâu và đánh giá tính bảo mật cũng như độ tin cậy của hợp đồng thông minh của các dự án khác nhau.",
        h32: "Bạn có thể nhập địa chỉ để truy vấn số dư và hồ sơ giao dịch của bất kỳ loại tiền điện tử nào.",
        h33: "Hiển thị xu hướng giá tiền điện tử lịch sử, thay đổi khối lượng giao dịch và dữ liệu khác",
        h34: "Bạn có thể tạo các chỉ báo được cá nhân hóa theo nhu cầu và chiến lược của riêng mình, đồng thời áp dụng chúng cho dữ liệu K-line hoặc on-chain.",
        h35: "Sinh ra để buôn bán",
        h36: "Biểu đồ K-line thời gian thực, chỉ số chính xác và phân tích thông minh",
        h37: "Thông tin tiền tệ",
        h38: "Giao dịch Biểu đồ",
        h39: "Phân tích thông minh",
        h40: "Thông tin cơ bản như tên đồng tiền, tổng số lượng phát hành và các chỉ số quan trọng như giá, giá trị thị trường và khối lượng giao dịch trong 24 giờ",
        h41: "Bạn có thể giao dịch trực tiếp ở phía bên phải của biểu đồ đường K, từ đó bạn có thể hiểu rõ hơn về các cơ hội thị trường và đưa ra các quyết định giao dịch tương ứng",
        h42: "Tín hiệu có thể xác định các mẫu xu hướng giá, cấu trúc hình thái, cũng như mức kháng cự và hỗ trợ trên thị trường.",
    },
    community: {
        h1: "Tham gia cộng đồng Reactor",
        h2: "Nhận thông tin sự kiện Lò phản ứng mới nhất và hỗ trợ trực tuyến",
        h3: 'Điện tín',
        h4: "Cộng đồng định lượng",
        h5: "Twitter",
        h6: "Cộng đồng người Hoa",
        h7: "Instagram",
        h8: "Facebook",
        h9: "Điện tín",
        h10: "Nhận hỗ trợ và giải đáp từ nhân viên chuyên nghiệp",
        h11: "Cộng đồng của chúng tôi có đội ngũ nhân viên chuyên nghiệp giàu kinh nghiệm, những người có hiểu biết sâu sắc về thị trường mã hóa và giao dịch định lượng. Là thành viên cộng đồng, bạn có thể tương tác trực tiếp với nhóm chuyên gia của chúng tôi để đặt câu hỏi, xin lời khuyên và câu trả lời. Cho dù đó là về phân tích thị trường, chiến lược giao dịch hay hỗ trợ kỹ thuật, nhân viên của chúng tôi sẽ hết lòng giúp đỡ bạn và đảm bảo rằng bạn luôn đi đúng hướng trên con đường giao dịch định lượng.",
        h12: "Nắm bắt xu hướng của thị trường mã hóa và khám phá các chiến lược định lượng mới",
        h13: "Tham gia cộng đồng của chúng tôi, bạn sẽ có cơ hội nắm bắt các xu hướng và động lực mới nhất của thị trường mã hóa. Chúng tôi cung cấp các công cụ và công nghệ giao dịch định lượng tiên tiến để giúp bạn phân tích dữ liệu thị trường, xây dựng chiến lược và thực hiện giao dịch trong thời gian thực. Bằng cách giao tiếp và chia sẻ với các thành viên khác, bạn có thể khám phá các chiến lược định lượng mới, mở rộng tầm nhìn đầu tư của mình và đạt được lợi thế cạnh tranh trong thị trường luôn thay đổi.",
        h14: "Chia sẻ tự do và gặp gỡ những người bạn mới được mã hóa",
        h15: "Cộng đồng của chúng tôi là một nền tảng để chia sẻ miễn phí và hỗ trợ lẫn nhau. Với tư cách là thành viên, bạn có thể chia sẻ kinh nghiệm giao dịch, hiểu biết và chiến lược của mình cũng như tương tác và thảo luận với các thành viên khác. Bằng cách giao tiếp với những người đam mê tiền điện tử có cùng chí hướng và các nhà giao dịch chuyên nghiệp, bạn có thể mở rộng mạng lưới của mình và gặp gỡ những người bạn tiền điện tử mới. Trong cộng đồng tích cực và hợp tác này, bạn sẽ có cơ hội học hỏi kinh nghiệm của những người khác, cùng nhau phát triển và tạo ra nhiều cơ hội giao dịch hơn."
    },
    cooperation: {
        h1: "Đối tác doanh nghiệp toàn cầu",
        h2: "Tianyi có nhiều công ty đối tác ở Trung Quốc, Châu Á, Trung Đông, Đông Nam Á, v.v. trên khắp thế giới. Chúng tôi cung cấp các giải pháp công nghệ mới với các công ty đối tác trên khắp thế giới.",
        h3: 'Thâm Quyến',
        h4: "trụ sở chính",
        h5: "Singapore",
        h6: "Đông Nam Á",
        h7: "Thành Đô",
        h8: "Chi nhánh",
        h9: "Vũ Hán",
        h10: "Nhật Bản",
        h11: "Châu Á",
        h12: "Việt Nam",
        h13: "Hồ Nam",
        h14: "Hà Nam",
        h15: "Hàn Quốc",
        h16: "Campuchia",
        h17: "Hải Nam",
        h18: "Dubai",
        h19: "Trung Đông",
        h20: "Thái Lan"
    },
    copyRobot: {
        h1: "Theo dõi hiệu suất phi thường của các nhà giao dịch hàng đầu",
        h2: "Truy cập các chiến lược giao dịch độc đáo và hiệu suất vượt trội của các nhà giao dịch hàng đầu với Reactor",
        h3: 'Xem thêm',
        h4: "Chiến lược giao dịch độc đáo",
        h5: "Cơ hội làm việc trực tiếp với các nhà giao dịch hàng đầu",
        h6: "Cải thiện hiệu suất và tỷ lệ giao dịch thành công của chính bạn",
        h7: "Phân bổ vốn hợp lý để quản trị rủi ro tốt hơn",
        h8: "Tùy chọn chiến lược giao dịch rộng hơn và đa dạng hơn",
        h9: "Tìm hiểu thêm",
        h10: "Với mục đích này, chúng tôi cung cấp",
        h11: "Hoàn toàn tự động theo dõi hành vi mua và bán của thương nhân",
        h12: "Các tham số điều chỉnh được cá nhân hóa, chẳng hạn như chốt lãi, báo cáo dừng, v.v.",
        h13: "Xem tài sản và hồ sơ giao dịch mua bán",
        h14: "Thực hiện phân bổ vốn và quản lý rủi ro theo mức độ chấp nhận rủi ro của bản thân",
        h15: "Xem thêm chức năng",
        h16: "Cung cấp cho bạn dữ liệu và thông tin chi tiết",
        h17: "Dễ tìm và theo dõi các nhà giao dịch xuất sắc, đồng thời nhận được lợi tức đầu tư ổn định",
        h18: "Thông tin người bán robot minh bạch",
        h19: "Tóm tắt kết quả giao dịch hàng tuần",
        h20: "lợi nhuận trung bình",
        h21: "Người dùng có thể đánh giá mức độ hoạt động của người bán theo thời gian của người bán ở Budangjie và thời gian niêm yết cuối cùng, đồng thời tiến hành hợp tác hơn nữa với người bán thông qua phương thức cung cấp và cung cấp khai thác chung.",
        h22: "Robot sao chép có thể cung cấp bản tóm tắt hàng tuần về kết quả giao dịch, bao gồm tổng lợi nhuận, số lần mua, số lần bán, v.v., để giúp bạn hiểu và đánh giá hiệu suất giao dịch một cách kịp thời.",
        h23: "Robot sao chép sẽ tính toán và hiển thị lợi nhuận trung bình của bạn, đồng thời hiển thị dưới dạng biểu đồ để bạn có thể hiểu được lợi nhuận trung bình và lợi nhuận kỳ vọng của từng giao dịch.",
        h24: "Xem báo cáo hiệu suất tháng trước",
        h25: "Tỷ lệ thắng, tỷ lệ thua, tỷ lệ lãi lỗ",
        h26: "Tiền tệ giao dịch và thời gian giao dịch",
        h27: "Bao gồm biểu đồ xu hướng tổng tài sản, lợi nhuận tốt nhất của mỗi giao dịch, khoản rút tiền lớn nhất và các dữ liệu khác để giúp bạn hiểu được tình hình đầu tư trong tháng qua.",
        h28: "Robot sao chép sẽ hiển thị cho bạn các chỉ số quan trọng như tỷ lệ thắng, tỷ lệ thua và tỷ lệ lãi lỗ của nhà giao dịch, giúp bạn đánh giá mức độ ổn định và lợi nhuận của chiến lược giao dịch của mình",
        h29: "Robot sao chép sẽ cung cấp danh sách các loại tiền giao dịch mà bạn đã sao chép và số liệu thống kê về tổng số giao dịch, để bạn có thể hiểu được các loại giao dịch và tần suất giao dịch mà bạn tham gia.",
    },
    dca: {
        h1: "Hành trình định lượng bắt đầu từ chiến lược đầu tư cố định (DCA)",
        h2: "Thường xuyên đầu tư tiền để mua tiền điện tử bất kể biến động giá thị trường",
        h3: 'Xem thêm',
        h4: "DCA là gì?",
        h5: "DCA là chiến lược đầu tư dài hạn mua tiền điện tử bằng cách đầu tư cùng một số tiền theo định kỳ, bất kể biến động giá thị trường. Chiến lược này có thể giúp nhà đầu tư phân tán rủi ro, giảm tác động của biến động thị trường và hưởng lợi từ việc tăng giá tài sản trong dài hạn. Bạn có thể dễ dàng thiết lập kế hoạch đầu tư cố định, chọn loại tiền điện tử mình quan tâm và chu kỳ đầu tư. Hệ thống sẽ tự động thực hiện các giao dịch trong khoảng thời gian xác định theo kế hoạch bạn đặt và mua tiền điện tử một cách thông minh theo giá thị trường. Điều chỉnh linh hoạt kế hoạch đầu tư cố định, tối ưu hóa theo với những thay đổi của thị trường và nhu cầu cá nhân.",
        h6: "Lợi thế của việc sử dụng chiến lược DCA trong Lò phản ứng là gì?",
        h7: "Đầu tư ổn định",
        h8: "DCA có thể duy trì nhịp điệu đầu tư ổn định bất kể giá thị trường biến động như thế nào bằng cách đầu tư một khoản tiền cố định trên cơ sở thường xuyên. Sự ổn định này có thể giúp nhà đầu tư vượt qua những biến động của thị trường và giảm thiểu rủi ro đầu tư.",
        h9: "Chi phí trung bình",
        h10: "Chiến lược DCA có thể đạt được trung bình hóa chi phí mua thông qua đầu tư thông thường. Khi giá thị trường giảm, bạn sẽ mua nhiều đơn vị tài sản hơn và khi giá thị trường tăng, bạn sẽ mua ít đơn vị tài sản hơn. Phương pháp này có thể giúp bạn mua tài sản trung bình trong thời kỳ thị trường biến động và giảm rủi ro mua giá.",
    },
    demoRobot: {
        h1: "Giao dịch mô phỏng theo thời gian thực, trải nghiệm không rủi ro",
        h2: "Robot DEMO giúp bạn khám phá thế giới giao dịch",
        h3: 'Xem thêm',
        h4: "Tại sao nên sử dụng robot DEMO",
        h5: "Robot DEMO cung cấp môi trường không rủi ro để người dùng tìm hiểu, thực hành và thử nghiệm các chiến lược giao dịch định lượng. Chúng có thể giúp người dùng cải thiện kỹ năng giao dịch, đánh giá hiệu suất của robot, tối ưu hóa chiến lược giao dịch và nâng cao hiểu biết về thị trường. Bằng cách sử dụng rô bốt DEMO, người dùng có thể xây dựng sự tự tin và kinh nghiệm trước khi giao dịch thực tế, để họ có thể đối phó tốt hơn với các thách thức trên thị trường thực tế.",
        h6: "Giao dịch mô phỏng không rủi ro",
        h7: "Robot DEMO cho phép bạn thực hiện các giao dịch mô phỏng bằng tiền ảo mà không cần sử dụng tiền thật. Bạn có thể thực hiện các hoạt động giao dịch trong điều kiện thị trường thực, trải nghiệm biến động giá theo thời gian thực và hiểu cách giao dịch thực sự hoạt động mà không gặp bất kỳ rủi ro thực tế nào.",
        h8: "Thông số và lịch sử giao dịch mô phỏng",
        h9: "Robot DEMO sẽ ghi lại lịch sử giao dịch mô phỏng của bạn, bao gồm mua, bán, cắt lỗ, chốt lãi và các hoạt động khác. Bạn có thể xem các bản ghi lịch sử, phân tích kết quả và hiệu suất giao dịch, đánh giá các chiến lược và kỹ năng giao dịch của mình, đồng thời thực hiện các điều chỉnh và cải tiến cần thiết.",
        h10: "Tối ưu hóa và điều chỉnh chiến lược",
        h11: "Thông qua robot DEMO, người dùng có thể tối ưu hóa và điều chỉnh chiến lược của họ. Robot DEMO sẽ tạo báo cáo và phân tích theo thời gian thực, bao gồm lịch sử giao dịch, đường cong lợi suất, thống kê giao dịch và dữ liệu khác. Bạn có thể sử dụng báo cáo và phân tích để hiểu hiệu suất giao dịch, thu nhập và các chỉ số quan trọng khác của mình.",
    },
    download: {
        h1: "Bắt đầu giao dịch miễn phí với Reactor!",
        h2: "Kết hợp hoàn hảo giữa Windows và Mac, hỗ trợ đầy đủ chức năng kinh doanh",
        h3: 'Phiên bản PC của lò phản ứng',
        h4: "Tải ứng dụng khách Windows và Mac OS để tận hưởng các dịch vụ giao dịch chuyên nghiệp.",
        h5: "Tải xuống cục bộ",
        h6: "Tại sao chọn Lò phản ứng?",
        h7: "Bảo vệ tài sản của bạn",
        h8: "Reactor áp dụng các giao thức bảo mật và công nghệ mã hóa ở mức cao nhất để đảm bảo rằng tài sản kỹ thuật số của bạn luôn được bảo vệ mạnh nhất.",
        h9: "Thông tin chi tiết về xu hướng thị trường",
        h10: "Reactor cung cấp các công cụ phân tích và thống kê dữ liệu toàn diện để giúp bạn hiểu sâu hơn về xu hướng thị trường và biến động giá cả. Và điều chỉnh chiến lược giao dịch của bạn theo điều kiện thị trường.",
        h11: "Kinh nghiệm mua bán nhanh chóng hiệu quả",
        h12: "Reactor cung cấp khả năng thực hiện giao dịch và xử lý đơn hàng tốc độ cao để đảm bảo rằng bạn có thể nhanh chóng phản ứng với những thay đổi của thị trường và nắm bắt cơ hội giao dịch.",
    },
    exchange: {
        h1: "Binance",
        h2: "Trò chuyện",
        h3: 'Binance là gì? ',
        h4: "Binance là sàn giao dịch tiền điện tử lớn nhất thế giới tính theo khối lượng giao dịch, với khối lượng giao dịch hàng ngày là 76 tỷ USD tính đến tháng 8 năm 2022 và có 90 triệu khách hàng trên toàn thế giới. Nền tảng này đã trở thành một thành viên đáng tin cậy của không gian tiền điện tử, nơi người dùng có thể mua, bán và lưu trữ tài sản kỹ thuật số của họ, cũng như truy cập hơn 350 loại tiền điện tử được niêm yết và hàng nghìn cặp giao dịch. Hệ sinh thái Binance hiện bao gồm Binance Exchange, Labs, La unchpad, Info, Academy, Research, Trust Wallet, Charity, NFT, v.v.",
        h5: "Reator quản lý tài khoản của bạn trên Binance như thế nào?",
        h6: "Thông qua API của nền tảng Reactor, các sàn giao dịch được kết nối liền mạch để thực hiện các giao dịch tự động. Với các chức năng API mạnh mẽ của chúng tôi, bạn có thể dễ dàng liên kết với nhiều sàn giao dịch, tự động thực hiện các chiến lược giao dịch và đạt được các hoạt động giao dịch hiệu quả. Tận dụng tối đa sự tiện lợi của API để dễ dàng quản lý tài khoản giao dịch của bạn và đạt được kết quả đầu tư tốt hơn.",
        h7: "Ai là người sáng lập Binance?",
        h8: "Công ty toàn cầu này được đồng sáng lập tại Trung Quốc bởi Changpeng Zhao và Yi He. Changpeng Zhao (CZ), một nhà phát triển và điều hành kinh doanh người Canada gốc Hoa, là Giám đốc điều hành của công ty. Ông được đào tạo tại Đại học McGill ở Montreal và có thành tích là một doanh nhân thành công. Binance Labs. Cựu phó chủ tịch của Yixia Technology, một công ty công nghệ video di động hàng đầu và là người đồng sáng lập OKCoin, một sàn giao dịch tài sản kỹ thuật số.",
        h9: "Binance ra mắt khi nào?",
        h10: "Binance ra mắt vào tháng 6 năm 2017 và phát triển thành sàn giao dịch tiền điện tử lớn nhất thế giới trong vòng 180 ngày.",
        h11: "Các quốc gia bị hạn chế sử dụng Binance?",
        h12: "Theo Điều khoản sử dụng của Binance, các khu vực bị hạn chế bao gồm Hoa Kỳ, Singapore và Ontario (Canada). Tuy nhiên, một số quốc gia/khu vực hạn chế việc sử dụng hoặc chức năng có thể bị hạn chế vì lý do pháp lý, bao gồm nhưng không giới hạn ở Trung Quốc, Malaysia, Nhật Bản, Vương quốc Anh và Thái Lan. Đức, Ý và Hà Lan cũng không cung cấp hợp đồng tương lai và sản phẩm phái sinh. Vào tháng 9 năm 2019, một nền tảng chuyên dụng riêng biệt Binance.US đã được ra mắt cho khách hàng Hoa Kỳ.",
        h13: "Phí Binance là bao nhiêu?",
        h14: "Nền tảng này thân thiện với người dùng, một trong những nền tảng rẻ nhất, cung cấp nhiều lựa chọn loại hình giao dịch và cung cấp cho các nhà đầu tư tinh vi một bộ công cụ giao dịch tiên tiến. Nó tính phí dựa trên hệ thống phân cấp, từ người dùng thông thường đến VIP 9. Đối với người dùng thông thường, giao dịch giao ngay tính phí người nhận 0,10%",
        h15: "Có thể sử dụng đòn bẩy hoặc giao dịch ký quỹ trên Binance không?",
        h16: "Các nhà giao dịch có thể vay vốn và tham gia giao dịch ký quỹ trên Binance Margin, cho phép giao dịch tiền điện tử với đòn bẩy lên tới 10 lần. Người dùng cũng có thể giao dịch bằng các công cụ phái sinh như Hợp đồng Binance thanh toán bằng USDT, BUSD hoặc các loại tiền điện tử khác và Quyền chọn Binance.",

        A0: 'OKX',
        A1: 'OKX là gì?',
        A2: 'OKX là một sàn giao dịch trung tâm và ví tiền điện tử tập trung (CEX) đã được thành lập tại Seychelles, phục vụ hàng triệu khách hàng trên toàn cầu. Sàn giao dịch này cung cấp các giao dịch cơ bản bao gồm giao dịch tiền mặt và tùy chọn đơn giản, cũng như các sản phẩm phái sinh bao gồm ký quỹ, hợp đồng tương lai, hợp đồng vĩnh cửu và tùy chọn. Các sản phẩm khác bao gồm: robot giao dịch, giao dịch lớn, OKX Earn (tiết kiệm, stablecoin, DeFi, giao dịch thế chấp, ETH 2.0, v.v.), cho vay tiền điện tử và nền tảng khởi chạy của sàn giao dịch Jumpstart.',
        A3: 'Ví OKX tự xưng là “cổng thông tin Web3”, cung cấp ví tiền điện tử phổ biến, sàn giao dịch phi tập trung (DEX), thị trường NFT và ứng dụng phi tập trung (DApp). Ví này hỗ trợ hơn 30 mạng lưới bao gồm các blockchain chính như Bitcoin, Ethereum, BNB Chain, Solana, Polygon, Avalanche, Fantom, v.v.',
        A4: 'OKX cũng có blockchain riêng của mình - OKX Chain và token gốc OKB, cho phép việc tạo ra các DApp và triển khai các dự án, cũng như cho phép nhà phát triển sử dụng hạ tầng như OKX Oracle.',
        A5: 'Vào tháng 1 năm 2022, OKEx đã đổi tên thành OKX và có một tập đoàn mới và một kế hoạch phát triển mới.',
        A6: 'Ai là người sáng lập OKX?',
        A6: 'OKX (trước đây là OKEx) là một công ty con của OK Group, công ty mẹ là OKCoin.',
        A7: 'Công ty này được thành lập bởi Mingxing “Star” Xu vào năm 2013 tại Trung Quốc. Xu là một doanh nhân Trung Quốc và có bằng cử nhân về Vật lý ứng dụng từ Đại học Khoa học và Công nghệ Bắc Kinh. Star Xu hiện là CEO của OK Group.',
        A8: 'Giám đốc điều hành hiện tại của OKX là Jay Hao.',
        A9: 'OKX được ra mắt vào thời điểm nào?',
        A10: 'Sàn giao dịch này (trước đây là OKEX) đã được ra mắt vào năm 2017.',
        A11: 'OKX ở đâu?',
        A12: 'Trụ sở chính của công ty đặt tại Seychelles.',
        A13: 'Các nước hạn chế sử dụng OKX',
        A14: 'Dự án này có thể sử dụng ở hơn 200 quốc gia. Tuy nhiên, cư dân Hoa Kỳ không thể sử dụng dịch vụ của nền tảng này.',
        A15: 'OKX hỗ trợ loại tiền mã hóa nào?',
        A16: 'Có hơn 350 loại tiền mã hóa được niêm yết trên sàn giao dịch OKX, với hơn 500 cặp giao dịch được hỗ trợ. Các token được niêm yết trên nền tảng này bao gồm BTC, ETH, OKB, AAVE, SOL, MATIC, XRP, DOGE, SHIB và DOT.',
        A17: 'Phí giao dịch trên OKX là bao nhiêu?',
        A18: 'Sàn giao dịch này sử dụng mô hình phí ăn chênh lệnh. Phí giao dịch trên nền tảng này bắt đầu từ 0.10% và giảm dần theo khối lượng giao dịch. Đối với người dùng thông thường, phí giao dịch phụ thuộc vào số lượng OKB (token gốc) trong hệ sinh thái OKX, trong khi đối với người dùng cao cấp, phí giao dịch được tính dựa trên khối lượng giao dịch của họ trong 30 ngày.',
        A19: 'OKX có hỗ trợ giao dịch đòn bẩy hoặc thế chấp không?',
        A20: 'Giao dịch thế chấp trên OKX có đòn bẩy tối đa là 10 lần. Về phương diện sản phẩm phái sinh, OKX cung cấp giao dịch hợp đồng tương lai và hợp đồng vĩnh viễn với đòn bẩy tối đa lên đến 125 lần. Ngoài ra, nhà giao dịch cũng có thể sử dụng tùy chọn tiền mã hóa bằng cách giao dịch đòn bẩy, bao gồm BTC, ETH và các loại tiền khác.',


        B0: 'Đồng xu lửa',
        B1: 'Huobi Global là gì?',
        B2: 'Huobi Global là một sàn giao dịch tài sản điện tử quốc tế, có thể sử dụng ở hơn 100 quốc gia trên toàn thế giới. Nền tảng này được thành lập tại Trung Quốc vào năm 2013, cung cấp dịch vụ giao dịch và đầu tư cho người dùng toàn cầu. Khối lượng giao dịch hàng ngày của nó đạt 4 tỷ đô la Mỹ.',
        B3: 'Sàn giao dịch tập trung này (CEX) hỗ trợ việc phát hành lần đầu token (ICO) và đã liệt kê nhiều loại tiền điện tử khác nhau. Nền tảng này có hơn 500 tài sản và hơn 300 cặp giao dịch. Người dùng có thể sử dụng chính sách bảo hiểm để bảo vệ tài sản của họ. Sàn giao dịch này cung cấp nhiều kênh hỗ trợ khách hàng hoạt động quanh năm để mang đến dịch vụ chuyên nghiệp hàng đầu.',
        B4: 'Hệ sinh thái của sàn giao dịch này bao gồm giao dịch tiền mặt, giao dịch ký quỹ, giao dịch hợp đồng tương lai, giao dịch sản phẩm phái sinh, giao dịch thế chấp, khoản vay tiền điện tử và nhiều hơn nữa. Các nhà giao dịch và nhà đầu tư tích cực có thể tìm kiếm cơ hội trên nền tảng thị trường ngoại vi (OTC) và sử dụng các công cụ giao dịch tùy chỉnh.',
        B5: 'Người sáng lập Huobi Global là ai?',
        B6: 'Sàn giao dịch này được thành lập bởi Lý Lâm (Leon Li) vào năm 2013 tại Bắc Kinh, Trung Quốc. Giám đốc điều hành Lý Lâm tốt nghiệp ngành tự động hóa tại Đại học Thanh Hoa vào năm 2013. Trước khi thành lập Huobi Global, Lý Lâm đã làm việc làm kỹ sư máy tính tại một trong những công ty phần mềm lớn nhất Hoa Kỳ - Oracle.',
        B7: 'Huobi Global đặt trụ sở ở đâu?',
        B8: 'Công ty có trụ sở chính tại Quần đảo Seychelles và có văn phòng tại Hồng Kông, Hàn Quốc, Nhật Bản và Hoa Kỳ.',
        B9: 'Các quốc gia hạn chế sử dụng Huobi Global',
        B10: 'Người dùng từ các quốc gia sau đây không được sử dụng tất cả các dịch vụ do Huobi Global cung cấp: Trung Quốc, Mỹ, Canada, Nhật Bản, Cuba, Iran, Triều Tiên, Sudan, Syria, Venezuela, Singapore và Crimea.',
        B11: 'Người dùng từ các quốc gia/ khu vực sau không thể sử dụng dịch vụ giao dịch sản phẩm phái sinh: Trung Quốc đại lục, Đài Loan, Hồng Kông, Israel, Iraq, Bangladesh, Bolivia, Ecuador, Kyrgyzstan, Sevastopol, Tây Ban Nha, Vương quốc Anh (chỉ dành cho người dùng bán lẻ) và New Zealand.',
        B12: 'Người dùng từ Nhật Bản có thể sử dụng dịch vụ của sàn giao dịch Huobi Japan. Ngoài ra, công ty cũng đã thông báo kế hoạch trở lại thị trường Mỹ.',
        B13: 'Huobi Global hỗ trợ loại tiền điện tử nào?',
        B14: 'Sàn giao dịch này hỗ trợ hơn 400 loại tài sản số và tiền điện tử, bao gồm các loại phổ biến nhất: BTC, DOGE, ETH, LTC, XMR, HT, HUSD, USDT.',
        B15: 'Cho đến tháng 8 năm 2022, sàn giao dịch này chấp nhận các loại tiền tệ pháp định bao gồm ALL, BGN, CHF, CZK, DKK, EUR, GBP, HRK, HUF, MDL, MKD, NOK, PLN, RON, SEK, TRY, UAH, HKD, AUD, USD.',
        B16: 'Phí giao dịch trên Huobi Global là bao nhiêu?',
        B17: 'Phí giao dịch trên Huobi có cấu trúc phân cấp, phí được xác định dựa trên khối lượng giao dịch hàng tháng của từng người dùng. Nền tảng này thu phí order cơ bản và taker fee là 0.2%, có thể nhận được chiết khấu khi giao dịch đạt 5 triệu đô la Mỹ; và chủ sở hữu token HT cũng được hưởng chiết khấu. Các nhà giao dịch chuyên nghiệp có thể nhận được các ưu đãi sau: phí order từ 0.0362% trở lên, phí taker từ 0.0462% trở lên.',
        B18: 'Có thể sử dụng giao dịch ký quỹ hoặc giao dịch đòn bẩy trên Huobi Global không?',
        B19: 'Nền tảng này hỗ trợ giao dịch ký quỹ cho nhiều loại tiền điện tử, cho phép người dùng mượn vốn để thực hiện giao dịch ký quỹ toàn bộ lên đến 3 lần đòn bẩy. Lãi suất được tính theo giờ.',
        B20: 'Người dùng có thể sử dụng đòn bẩy tối đa 200 lần để giao dịch hợp đồng tương lai tiền điện tử Bitcoin và Ethereum.',


        C0: 'PancakeSwap',
        C1: 'Giới thiệu về PancakeSwap',
        C2: 'PancakeSwap là một sàn giao dịch phi tập trung (DEX) phổ biến, cho phép người dùng trao đổi các token BEP-20 trên chuỗi BSC. Nó là sàn giao dịch lớn nhất trong hệ sinh thái chuỗi BSC và là một trong những sàn giao dịch phi tập trung (DEX) phổ biến trên toàn cầu theo thị phần. Sàn giao dịch này sử dụng mô hình làm thị trường tự động (AMM), cho phép người dùng giao dịch với các hồ sơ thanh khoản. Bạn có thể trở thành nhà cung cấp thanh khoản và nhận được mã LP (Liquidation Provider) để chia sẻ phí giao dịch của sàn.',
        C3: 'Ngoài ra, chủ sở hữu mã LP cũng có thể tham gia khai thác thanh khoản để kiếm được token CAKE của sàn giao dịch. Token này có thể được đặt cược trong “hồ nước đường” để gửi cầm cố theo định kỳ hoặc không kỳ hạn. Người dùng gửi cầm cố không kỳ hạn có thể huỷ giao dịch bất kỳ lúc nào, trong khi gửi cầm cố theo định kỳ sẽ tối đa hoá tỷ lệ sinh lợi (tối đa 52 tuần).',
        C4: 'Ngoài ra, PancakeSwap cũng cung cấp trò chơi xổ số để người dùng có thể giành được phần thưởng CAKE. Trên thị trường NFT, người dùng có thể giao dịch các bộ sưu tập và cũng có thể thiết lập hồ sơ cá nhân có cá nhân hóa thông qua hệ thống hồ sơ cá nhân NFT. Ngoài ra, còn có một sàn giao dịch tương lai không thời hạn được phát triển cùng với ApolloX, bao gồm nhiều cặp giao dịch token phổ biến và cung cấp dịch vụ giao dịch hợp đồng tương lai không thời hạn. Người dùng có thể sử dụng tài khoản margin vượt quá số dư tài khoản để giao dịch các cặp giao dịch token phổ biến trên sàn giao dịch này.',
        C5: 'Người sáng lập PancakeSwap là ai?',
        C6: 'PancakeSwap được thành lập bởi một nhóm người ẩn danh dẫn đầu bởi hơn 20 “đầu bếp” ẩn danh làm việc trong “nhà bếp” của sàn giao dịch. Sàn giao dịch này là mã nguồn mở và đã được kiểm tra bảo mật bởi các công ty an ninh nổi tiếng như Certik và Slowmist.',
        C7: 'PancakeSwap được ra mắt vào thời điểm nào?',
        C8: 'PancakeSwap được ra mắt vào tháng 9 năm 2020.',
        C9: 'PancakeSwap có trụ sở ở đâu?',
        C10: 'Vì là một sàn giao dịch phi tập trung, nên đội ngũ vận hành hoạt động từ xa. Tuy nhiên, theo báo cáo từ Crunchbase, trụ sở chính của PancakeSwap đặt tại Fukuoka, Nhật Bản.',
        C11: 'Các quốc gia bị hạn chế sử dụng PancakeSwap',
        C12: 'Do là một sàn giao dịch phi tập trung, không có giới hạn quốc gia. Tuy nhiên, các quốc gia sau đây đã bị chặn địa lý: Cuba, Iran, Zimbabwe, Iraq, Crimea, Syria, Sudan, Belarus và Cộng hòa Dân chủ Congo.',
        C13: 'Danh sách token được hỗ trợ trên PancakeSwap',
        C14: 'Tất cả các token BEP-20 đều có thể giao dịch, ngoài ra còn hỗ trợ nhiều cặp giao dịch tương lai mà phổ biến, chẳng hạn như BTC/USDT, ETH/USDT, BNB/USDT và tất cả các dự án blockchain lớp 1 khác.',
        C15: 'Phí giao dịch trên PancakeSwap như thế nào?',
        C16: 'Vào thời điểm viết bài này, việc đổi token sẽ mất 0.25% phí giao dịch. Trong đó, 0.17% sẽ được trả lại như phần thưởng cho hồ nước động, 0.03% làm kết dự trữ, và phần còn lại 0.05% được sử dụng cho mua lại và tiêu hủy.',
        C17: 'Có thể sử dụng đòn bẩy hoặc giao dịch ký quỹ trên PancakeSwap không?',
        C18: 'Đối với các cặp giao dịch lớn nhất, sàn giao dịch tương lai vĩnh viễn có thể cho phép sử dụng đòn bẩy lên đến 150 lần, trong khi đối với những cặp giao dịch nhỏ hơn, giới hạn là 50 lần.',


        D0: 'UniSwap',
        D1: 'Uniswap là gì?',
        D2: 'Uniswap được ra mắt vào năm 2018 và là một trong những sàn giao dịch phi tập trung (DEX) tiền điện tử phổ biến nhất. Sàn giao dịch này là một trong những nơi đầu tiên sử dụng mô hình Nhà cung cấp thanh khoản tự động (AMM) thay thế mô hình sổ đặt hàng truyền thống vẫn được sử dụng ở các sàn giao dịch khác. Uniswap hoạt động trên blockchain Ethereum và an toàn chuyển đổi các token ERC-20 giữa các người dùng thông qua nhiều hợp đồng thông minh.',
        D3: 'Tính phi tập trung của giao thức này không yêu cầu sự quản lý của một tổ chức tập trung duy nhất; thay vào đó, giao dịch được thực hiện theo mô hình trực tiếp giữa các cặp người dùng (P2P). Ngoài ra, Uniswap cũng tập trung vào giải quyết vấn đề thanh khoản mà các sàn giao dịch khác đem lại. Phiên bản 3 của Uniswap (Uniswap V3) đã cải tiến đáng kể về khả năng đền bù và để nhà cung cấp thanh khoản cá nhân có quyền kiểm soát và linh hoạt hơn. Uniswap V3 chiếm 46,5% tổng lượng giao dịch trên tất cả các sàn giao dịch phi tập trung và đã lập kỷ lục về tổng giá trị giao dịch lên tới 10 ngàn tỷ đô la vào tháng 5 năm 2022.',
        D4: 'Người sáng lập Uniswap V3 là ai?',
        D5: 'Hayden Adams là người sáng lập Uniswap. Trước đây, ông theo học tại Stony Brook và làm việc làm kỹ sư cơ khí tại Siemens. Adams được truyền cảm hứng từ một bài viết trên blog của Vitalik Buterin và một bài viết trên Reddit, sau đó nhận được sự tài trợ từ Quỹ Ethereum. Uniswap Labs đã được huy động vốn từ Paradigm trong vòng gọi hạt giống, sau đó huy động thêm 11 triệu USD trong vòng A do Andreessen Horowitz dẫn đầu vào tháng 8 năm 2020.',
        D6: 'Uniswap V3 đã được ra mắt vào tháng 5 năm 2021, đã trôi qua gần 2,5 năm kể từ phiên bản đầu tiên và 1 năm kể từ phiên bản Uniswap V2.',
        D7: 'Uniswap có trụ sở ở đâu?',
        D8: "Uniswap Labs, tổ chức phát triển giao thức và giao diện Uniswap, đặt trụ sở tại thành phố New York, Hoa Kỳ. Là một giao thức tài chính phi tập trung (DeFi), nó có thể được sử dụng bởi bất kỳ cá nhân nào trên toàn cầu có kết nối internet. Tuy nhiên, một số quốc gia có những hạn chế đối với người dùng. Đến tháng 7 năm 2022, Uniswap đã cấm người dùng từ 10 quốc gia, bao gồm Belarus, Cuba, Iran, North Korea, Syria, Côte d'Ivoire, Liberia, Sudan, Zimbabwe và Iraq.",
        D9: 'Các loại tiền điện tử nào có thể giao dịch trên Uniswap?',
        D10: 'Vì Uniswap là một giao thức phi tập trung và ngang hàng, bất kỳ ai cũng có thể niêm yết bất kỳ token nào trên Uniswap. Hiện tại, các cặp giao dịch phổ biến nhất bao gồm USDC, Wrapped BTC, WETH và DAI.',
        D11: 'Phí giao dịch trên Uniswap là bao nhiêu?',
        D12: 'Người cung cấp thanh khoản trên Uniswap có thể xây dựng một hồ xả chung với ba cấp phí khác nhau là 0,05%, 0,30% và 1%. Các đề xuất quản trị của UNI có thể bổ sung thêm các cấp phí khác.',
        D13: 'Có thể sử dụng đòn bẩy hoặc giao dịch ký quỹ trên Uniswap không?',
        D14: 'Nền tảng Uniswap không cung cấp giao dịch đòn bẩy hoặc ký quỹ.',


        E0: 'DODO',
        E1: 'Dodo là gì?',
        E2: 'Dodo là một sàn giao dịch phi tập trung (DEX) dựa trên Ethereum có chức năng “nhà cung cấp thanh khoản tự động” (AMM). Dodo cung cấp cách để phát hành và giao dịch tài sản số trên blockchain. Sàn giao dịch cung cấp thanh khoản từ nguồn vốn sẵn có và gom từ các sàn giao dịch khác. Theo sách trắng của Dodo, điều này cho phép sàn giao dịch cung cấp giá tài sản Web3 tốt nhất trên toàn cầu.',
        E3: 'Người dùng có thể đổi các token khác nhau bằng cách sử dụng đơn giá thị trường hoặc đơn giá giới hạn mà không cần sự cho phép. Đồng thời, Dodo cũng cung cấp định tuyến trao đổi miễn phí Gas và bảo đảm thanh khoản cao hơn thông qua nhà cung cấp thanh khoản chuyên nghiệp (không trượt giá và miễn phí Gas). Ngoài ra, còn có một thị trường NFT có thể giao dịch các token ERC-721 và ERC-1155. Giao dịch thanh khoản cung cấp là một trong những cách mà nhà cung cấp thanh khoản có thể kiếm tiền trên sàn giao dịch này, người dùng có thể thêm thanh khoản để nhận LP token và',
        E4: 'kiếm lợi nhuận từ phí giao dịch. Phương pháp khác là đào mỏ. Người dùng có thể đào một token duy nhất hoặc nạp vốn vào hồ bơi thanh khoản chỉ định để khai thác LP token. Tất nhiên, người dùng cũng có thể tận dụng vốn hiệu quả hơn bằng cách vay nhanh hoặc cấu hình vốn.',
        E5: 'Cuối cùng, sàn giao dịch cũng cung cấp công cụ cho các nhà phát triển tạo ra token. Các blockchain được hỗ trợ bao gồm Ethereum, BNB Chain, Polygon và OKChain.',
        E6: 'Ai là người sáng lập Dodo?',
        E7: 'Theo thông tin trên CrunchBase, Dodo được thành lập bởi hai người Trung Quốc là Qi Wang và Shichi Dai. Dự án đã thu hút 5 triệu đô la từ 17 công ty đầu tư rủi ro khác nhau, bao gồm Coinbase Ventures, Defiance Capital và Galaxy Digital.',
        E8: 'Dodo được ra mắt vào thời điểm nào?',
        E9: 'Dodo được ra mắt vào tháng 8 năm 2020.',
        E10: 'Dodo đặt trụ sở ở đâu?',
        E11: 'Theo thông tin trên CrunchBase, trụ sở của sàn giao dịch này nằm tại Hồng Kông.',
        E12: 'Các quốc gia có hạn chế sử dụng Dodo',
        E13: 'Vào thời điểm viết bài này, không có thông tin về các quốc gia bị hạn chế sử dụng sàn giao dịch này.',
        E14: 'Danh sách các token được hỗ trợ trên Dodo',
        E15: 'Dodo đã triển khai trên hầu hết các blockchain tương thích với EVM như Arbitrum, Optimistic, Avalanche và Aurora. Do đó, sàn giao dịch này hỗ trợ nhiều loại token khác nhau trên tất cả các blockchain, bao gồm cả các token chính và Bitcoin Wrapped và Ethereum Wrapped.',
        E16: 'Phí giao dịch trên Dodo là bao nhiêu?',
        E17: 'Dodo thu một khoản phí nhỏ khi tạo mới một token (0,02 ETH), nhưng không cung cấp thông tin về phí giao dịch.',
        E18: 'Dodo cho phép giao dịch đòn bẩy hoặc ký quỹ không?',
        E19: 'Hiện tại, nền tảng này không có tính năng giao dịch ký quỹ, nhưng theo kế hoạch phát triển, dự án này sẽ ra mắt dịch vụ giao dịch đòn bẩy vào năm 2022.',


        F2: 'Tentang Curve (Ethereum)',
        F3: 'Pengenalan Curve Finance',
        F4: 'Curve Finance adalah kolam likuiditas perdagangan mata uang stabil yang terdesentralisasi. Ini tidak menggunakan buku pesanan tetapi menggunakan model otomatiser pasar (AMM) untuk mencocokkan likuiditas. Karena protokol ini terdesentralisasi dan tanpa kepercayaan, siapa pun dapat menyediakan likuiditas ke satu atau beberapa kolam likuiditas. Formula perkalian tetap AMM ini tidak hanya memastikan efisiensi tertinggi tetapi juga memberi pengguna jarak geser yang paling kecil.',
        F5: 'Kolam Curve adalah variasi StableSwap yang mengeksekusi kontrak pintar dan dapat digunakan untuk menukar berbagai jenis token. Anda dapat menukar token melalui pasangan perdagangan mata uang stabil (kolam reguler) atau token terbungkus, sementara aset yang mendasari dipinjamkan di protokol lain (kolam pinjaman). Akhirnya, Metapool akan mencocokkan token mata uang stabil dengan LP token dari kolam lain.',
        F6: 'Protokol ini didukung oleh beberapa blockchain, termasuk Ethereum, Arbitrum, Aurora, Avalanche, Fantom, Harmony, Optimism, Polygon, xDai, Moonbeam. Jika ingin menggunakan protokol Curve, pengguna biasanya harus memindahkan dana mereka dari jembatan Ethereum ke blockchain ini. Karena pentingnya terhadap sistem keuangan terdesentralisasi (DeFi), protokol ini juga menjadi subjek persaingan yang disebut Perang Curve, di mana beberapa protokol bersaing untuk memperebutkan kendali protokol ini.',
        F7: 'Siapa pendiri Curve Finance?',
        F8: 'Curve Finance didirikan oleh Michael Egorov, mantan pendiri dan CTO protokol infrastruktur kripto NuCypher. Selain itu, dia juga menciptakan bank terdesentralisasi dan jaringan peminjaman yang disebut LoanCoin. Sebelum terjun ke dunia kripto, ia belajar di Institut Fisika Teknik Moskow dan Universitas Teknologi Swinburne.',
        F9: 'Kapan Curve Finance diluncurkan?',
        F10: 'Curve Finance diluncurkan pada bulan Juni 2020, pada masa yang dikenal sebagai "musim panas DeFi". Oleh karena itu, dapat dikatakan bahwa protokol ini merupakan salah satu dasar industri DeFi yang baru.',
        F11: 'Di mana lokasi Curve Finance?',
        F12: 'Menurut laporan dari CBInsight, Kantor pusat Curve Finance berlokasi di Swiss.',
        F13: 'Negara-negara yang membatasi penggunaan Curve Finance',
        F14: 'Hingga saat artikel ini ditulis, tidak ada informasi tentang negara-negara yang membatasi penggunaan Curve Finance. Namun, pengguna dari negara-negara yang terkena sanksi keuangan Amerika Serikat mungkin terbatas oleh pembatasan geografis.',
        F15: 'Daftar token yang didukung oleh Curve Finance',
        F16: 'Bursa ini mendukung likuiditas untuk beberapa mata uang stabil populer seperti DAI, USDC, USDT, FRAX, dan TUSD. Selain itu, juga mendukung pertukaran token terbungkus seperti wBTC, wETH, dan stETH (sebagai variasi asli dari Lido yang melibatkan penjaminan Ether).',
        F17: 'Bagaimana biaya transaksi Curve Finance?',
        F18: 'Tarif biaya standar yang ditetapkan oleh protokol ini adalah 0,04%. Setengahnya diberikan kepada penyedia likuiditas, sementara setengahnya lagi diberikan kepada pemegang veCRV. Pemegang veCRV yang mengunci token mereka secara berkala akan mendapatkan hak pengambilan keputusan yang lebih besar.',
        F19: 'Apakah Curve Finance mendukung perdagangan leverage atau margin?',
        F20: 'Bursa ini hanya menyediakan layanan pertukaran token dan tidak mendukung perdagangan dengan leverage atau margin.',

        G0: 'Balancer là gì？',
        G1: 'Balancer là một giao thức tự động làm sàn phi tập trung (AMM) dựa trên Ethereum, nó đại diện cho các khối xây dựng linh hoạt của tính thanh khoản có thể lập trình được.',
        G2: 'Bằng cách tách riêng logic và toán học của đường cong AMM khỏi chức năng trao đổi cốt lõi, Balancer trở thành một AMM có thể mở rộng, có thể chứa bất kỳ số lượng đường cong trao đổi và loại hồ nào. Điều này bao gồm:',
        G3: 'Hồ trọng số 50/50x*y=k truyền thống',
        G4: 'Trọng số tùy chỉnh như 80/20 để kiểm soát rủi ro',
        G5: 'Đường cong trao đổi ổn định',
        G6: 'Hồ lồng nhau (ví dụ: Boosted Pools)',
        G7: 'Hồ khai thác với trọng số thay đổi liên tục (ví dụ: Liquidity Bootstrapping Pools)',
        G8: 'Hồ thanh khoản tập trung',
        G9: 'Hồ quản lý cho phép tham số tùy chỉnh',
        G10: 'Toàn bộ giao thức được xây dựng trên cơ sở (ví dụ: Balancer Gyroscopes)',
        G11: 'Như vậy, người trao đổi, người tổng hợp và nhà khai thác có thể dễ dàng tiếp cận với tất cả tổng thanh khoản. Balancer Vault tối ưu hóa quá trình lô và logic đường dẫn, giúp giảm chi phí Gas và nhu cầu vốn xuống thấp. Mỗi hồ cá nhân và dự án được xây dựng trên tổng thanh khoản toàn cầu của Balancer, mang lại sự sâu rộng thanh khoản cho các tài sản cơ bản và mở ra đường dẫn trao đổi.',
        G12: 'Ai sử dụng Balancer?',
        G13: 'Balancer là một công cụ rất hữu ích đối với các bên tham gia khác nhau trong lĩnh vực DeFi.',
        G14: 'Người trao đổi có thể giao dịch giữa hai token ERC20 bất kỳ. Điều này có thể được thực hiện thông qua ứng dụng Balancer hoặc các công cụ gom rỗng như 1inch, Matcha hoặc Paraswap.',
        G15: 'Nhà cung cấp thanh khoản (LP) có thể thêm thanh khoản vào hồ vốn để kiếm lợi từ phí swap, động lực thanh khoản và các hình thức thu nhập khác.',
        G16: 'LP chủ động có thể tận dụng hồ gia tăng để kiếm lợi từ các token Aave đã được tái đầu tư.',
        G17: 'Người cơ cấu có thể sử dụng các phương pháp như trao đổi nhiều lô và vay flash để cung cấp thanh khoản cho các hồ',
        G18: 'Người giữ BAL token có thể khóa token của mình trong veBAL và tham gia vào quyền lực điều hành của giao thức Balancer.',


        J0: 'SushiSwap là gì?',
        J1: 'SushiSwap là một sàn giao dịch phi tập trung (DEX) sử dụng AMM. Nhà làm thịt tự động này tạo ra các thị trường giao dịch cho các cặp token khác nhau. Nhà cung cấp thanh khoản cung cấp vốn cho các hợp đồng thông minh này, giúp thực hiện giao dịch không ma sát. Đây là một trong những sàn giao dịch đầu tiên áp dụng mô hình này và ban đầu là một nhánh của UniSwap, một DEX (sàn giao dịch phi tập trung) phổ biến khác. Sau đó, nó đã phát triển thành một trong những sàn giao dịch lớn nhất trong DeFi. Hiện tại, nó hoạt động trên hầu hết các blockchain lớp một tương thích với EVM và một số giải pháp lớp hai của Ethereum.',
        J3: 'Mục tiêu chủ yếu của sàn giao dịch này là các nhà giao dịch và tổ chức trong lĩnh vực DeFi muốn thực hiện giao dịch phi phép và phi tài trợ. Sàn giao dịch này giải quyết vấn đề thanh khoản và triển khai cách thức quản trị công bằng hơn so với các sàn giao dịch khác. Token gốc của nó có thể được sử dụng để đưa ra phiếu bầu về các vấn đề quản trị.',
        J4: 'Sản phẩm chính của nó bao gồm Bento Box, một kho token có thể tạo ra lợi nhuận từ khoản tiền gửi. Kashi là một nền tảng cho vay và giao dịch ký quỹ, mọi người có thể tạo ra thị trường token tùy chỉnh với hiệu suất gas cao trên nền tảng này. Hiện tại, MasterChef V2 là một chương trình cung cấp thanh khoản với hơn 1 tỷ đô la AUM. Cuối cùng, Miso cung cấp cơ hội cho các dự án tham gia giao dịch trên sàn thông qua giá cố định hoặc đấu giá theo phong cách Hà Lan.',
        J5: 'Ai là người sáng lập SushiSwap?',
        J6: 'Ban đầu, SushiSwap được thành lập bởi Chef Nomi (bút danh). Sau đó, nhóm này đã rời khỏi dự án và chuyển quyền quản lý cho hai nhà phát triển khác, được biết đến với tên gọi sushiswap và 0xMaki. Các nhà phát triển này đã tiếp tục phát triển sản phẩm và vận hành kinh doanh, sau đó sàn giao dịch này được chuyển giao cho CEO của sàn giao dịch trung tâm FTX, Sam Bankman-Fried.',
        J7: 'SushiSwap được ra mắt vào thời điểm nào?',
        J8: 'SushiSwap được ra mắt vào tháng 8 năm 2020.',
        J9: 'SushiSwap đặt trụ sở ở đâu?',
        J10: 'Sàn giao dịch này không có một trụ sở chính cụ thể, mà hoạt động theo cách phi tập trung.',
        J11: 'Các quốc gia có hạn chế sử dụng SushiSwap',
        J12: 'Hiện tại không có thông tin về việc cấm hoặc hạn chế các quốc gia sử dụng sàn giao dịch này.',
        J13: 'Danh sách token được SushiSwap hỗ trợ',
        J14: 'Với sự tồn tại trên blockchain lớp một và lớp hai, SushiSwap hỗ trợ gần như tất cả các token ERC-20 có trên thị trường.',
        J15: 'Phí giao dịch trên SushiSwap là bao nhiêu?',
        J16: 'Sàn giao dịch này tính phí 0,3% cho việc trao đổi token, tuân theo tiêu chuẩn ngành.',
        J17: 'Có thể sử dụng đòn bẩy hoặc giao dịch ký quỹ trên SushiSwap không?',
        J18: 'SushiSwap cung cấp nền tảng Kashi cho vay và giao dịch ký quỹ, sử dụng một khung thị trường cách ly độc đáo.'

    },
    exchangeRobot: {
        h1: "Đạt được các chiến lược giao dịch tự động được cá nhân hóa",
        h2: "robot giao dịch",
        h3: 'Cài đặt tham số linh hoạt và quản lý rủi ro được cá nhân hóa để giúp bạn thực hiện các chiến lược giao dịch một cách chính xác',
        h4: "Xem chi tiết",
        h5: "Thực hiện giao dịch chính xác và hiệu quả hơn",
        h6: "Giúp bạn thực hiện giao dịch tự động và thiết lập các chiến lược giao dịch được cá nhân hóa theo nhu cầu của riêng bạn",
        h7: "Thông số chiến lược linh hoạt",
        h8: "Robot giao dịch của Reactor cung cấp nhiều cài đặt tham số chiến lược để đáp ứng nhu cầu của các nhà đầu tư khác nhau",
        h9: "Điều kiện kích hoạt mua bán",
        h10: "Cài đặt quỹ đầu tư ước tính",
        h11: "Đặt tham số như chốt lời và cắt lỗ",
        h12: "Chiến lược mua hàng đa dạng",
        h13: "Bạn có thể chọn chiến lược mua phù hợp theo điều kiện thị trường và mục tiêu đầu tư, đồng thời tùy chỉnh thêm thông qua cài đặt tham số",
        h14: "Tỷ lệ giá thầu",
        h15: "Thời gian mở mua tối đa",
        h16: "Lãi suất mở tối đa và tỷ lệ phần trăm trên mỗi mã thông báo",
        h17: "Cơ chế làm mát giao dịch",
        h18: "Chiến lược bán hàng thông minh, chớp thời cơ chính xác",
        h19: "Reactor cung cấp các chiến lược bán hàng thông minh giúp bạn nắm bắt cơ hội bán hàng tốt nhất trong giao dịch đồng thời quản lý rủi ro hiệu quả",
        h20: 'Bán cắt lỗ',
        h21: "Dừng bán theo dõi",
        h22: "Tự động đóng",
        h23: "Phương pháp tính trung bình chi phí bằng đô la Mỹ",
        h24: "Tham số cấu hình nâng cao",
        h25: "Robot giao dịch Reactor hỗ trợ các loại lệnh nâng cao, chẳng hạn như mua theo điểm dừng, tự động đóng, tính trung bình chi phí bằng đô la, v.v., cho phép người dùng có quyền kiểm soát chi tiết hơn đối với việc thực hiện giao dịch và quản lý rủi ro.",
        h26: "Cài đặt dừng lỗ được cá nhân hóa",
        h27: "Bạn có thể linh hoạt thiết lập các tham số cắt lỗ để hạn chế các khoản lỗ có thể xảy ra. Đặt số tiền hoặc tỷ lệ phần trăm cắt lỗ cố định hoặc sử dụng chức năng cắt lỗ theo dõi động để tự động điều chỉnh mức cắt lỗ khi giá giảm để bảo vệ tiền của bạn.",
        h28: "Trung bình chi phí đô la (DCA)",
        h29: "Phân chia rủi ro biến động thị trường thông qua các phương pháp đầu tư thông thường và bình đẳng để đạt được tăng trưởng ổn định trong dài hạn.",
    },
    faq: {
        h1: "Hỗ trợ khách hàng/Hỏi đáp",
        h2: "Đang tìm câu trả lời? Câu trả lời có thể ở đây.",
        h3: 'Hướng dẫn cho người mới bắt đầu',
        h4: "Lò phản ứng là gì?",
        h5: "Làm thế nào để thiết lập Lò phản ứng?",
        h6: "Giảm giá",
        h7: "Nền tảng thanh khoản",
        h8: "DEX",
        h9: "Câu hỏi bảo mật",
        h10: "Quyền lợi của thành viên",
        h11: "Sản phẩm",
        h12: "Giao dịch",
        h13: "Xem thêm",
        h14: "Reactor là một nền tảng giao dịch định lượng sáng tạo",
        h15: "Reactor tích hợp các thuật toán và công nghệ tiên tiến để giúp người dùng giao dịch thông minh và hiệu quả hơn. Bằng cách tự động thực hiện các chiến lược giao dịch, cung cấp dữ liệu và phân tích thị trường theo thời gian thực, đồng thời cung cấp vô số công cụ giao dịch, người dùng có thể đạt được kết quả giao dịch tốt hơn trên thị trường tài chính. Cho dù họ là nhà giao dịch mới hay chuyên nghiệp, Reactor cung cấp cho họ một nền tảng giao dịch mạnh mẽ để giúp họ đạt được mục tiêu đầu tư và thu được lợi nhuận giao dịch cao hơn.",
        h16: "Hỏi đáp thường gặp",
        h17: "Tôi có thể kiếm được bao nhiêu lợi nhuận?",
        h18: "Chênh lệch giá hoạt động như thế nào?",
        h19: "Bạn có đảm bảo lợi nhuận không?",
        h20: "Làm cách nào để kết nối với sàn giao dịch?",
        h21: "Tôi có thể mở bao nhiêu robot trên một tài khoản giao dịch?",
        h22: "Tôi nên sử dụng loại tiền cơ bản nào?",
        h23: "Lò phản ứng có an toàn không?",
        h24: "Tài khoản của tôi có còn khả dụng sau khi thoát khỏi ví HD chính không?",
    },
    helpCenter: {
        h1: "Trung tâm trợ giúp",
        h2: "Đang tìm câu trả lời? Câu trả lời có thể ở đây.",
        h3: 'Duyệt chủ đề',
        h4: "Khám phá các danh mục Câu hỏi thường gặp và bài viết được hỗ trợ của chúng tôi",
        h5: "Lò phản ứng là gì?",
        h6: "Reactor cung cấp cho người dùng phân tích thị trường chính xác, chiến lược định lượng và công cụ giao dịch. Thông qua Reactor, người dùng có thể đạt được lợi tức đầu tư cao hơn và cùng phát triển với các nhà giao dịch định lượng khác trong một cộng đồng tích cực.",
        h7: "Làm thế nào để thiết lập Lò phản ứng?",
        h8: "Hướng dẫn ngắn về cách thiết lập các tính năng cơ bản khác nhau của Reactor. Đây là nơi bạn bắt đầu với tư cách là người dùng mới!",
        h9: "Giảm giá",
        h10: "Nền tảng thanh khoản",
        h11: "Số hóa DEX",
        h12: "Câu hỏi bảo mật",
        h13: "Quyền lợi của thành viên",
        h14: "Người máy DEMO",
        h15: "Giao dịch thủ công",
        h16: "DCA",
        h17: "Theo dõi đơn hàng",
        h18: "Tín hiệu",
        h19: "Ví tiền",
        h20: "Robot sao chép",
        h21: "Dữ liệu trên chuỗi",
        h22: "robot giao dịch",
        h23: "Robot kinh doanh chênh lệch giá",
        h24: "Câu hỏi thường gặp",
        h25: "Tôi có thể kiếm được bao nhiêu lợi nhuận?",
        h26: "Chênh lệch giá hoạt động như thế nào?",
        h27: "Bạn có đảm bảo lợi nhuận không?",
        h28: "Làm cách nào để kết nối với sàn giao dịch?",
        h29: "Tôi có thể mở bao nhiêu robot trên một tài khoản giao dịch?",
        h30: "Tôi nên sử dụng loại tiền tệ cơ bản nào?",
        h31: "Lò phản ứng có an toàn không?",
        h32: "Tài khoản của tôi có còn khả dụng sau khi thoát khỏi ví HD chính không?",
    },
    interestRobot: {
        h1: "Nắm bắt chênh lệch giá và đạt thu nhập ổn định",
        h2: "Robot kinh doanh chênh lệch giá thông minh khai thác chênh lệch giá giữa các thị trường",
        h3: 'Xem thêm',
        h4: "Robot kinh doanh chênh lệch giá",
        h5: "Sử dụng chênh lệch giá để thực hiện kinh doanh chênh lệch tỷ giá chéo",
        h6: "Hỗ trợ kết nối đồng thời với nhiều nền tảng giao dịch và chuỗi khối",
        h7: "Bao gồm nhưng không giới hạn ở Binance, Ouyi, Huobi, Ethereum, Binance Smart Chain, Tron, v.v., theo dõi thời gian thực các thay đổi về giá và thị trường.",
        h8: "So sánh và quét thị trường",
        h9: "Sau khi phát hiện chênh lệch giá sẽ lập tức thực hiện thao tác giao dịch tương ứng. Mua trên một nền tảng và bán trên nền tảng khác cùng lúc để thu được lợi nhuận từ chênh lệch giá",
        h10: "Giám sát và báo cáo theo thời gian thực",
        h11: "Người dùng có thể xem hồ sơ giao dịch chi tiết, thống kê lợi nhuận và hiển thị biểu đồ để đánh giá toàn diện tác động và cơ hội tiềm năng của giao dịch chênh lệch giá.",
        h12: "Thực hiện giao dịch nhanh chóng",
        h13: "Chúng tôi cam kết cung cấp khả năng thực hiện giao dịch nhanh chóng và hiệu quả để đảm bảo rằng người dùng có thể nắm bắt cơ hội thị trường một cách kịp thời.",
        h14: "Hệ thống giao dịch của chúng tôi đã được thiết kế cẩn thận và tối ưu hóa để xử lý một số lượng lớn các đơn đặt hàng và duy trì phản ứng nhanh chóng để thực hiện các giao dịch nhằm đảm bảo rằng bạn có thể giao dịch ở mức giá tốt nhất. Hệ thống giao dịch được xây dựng trên cơ sở hạ tầng ổn định và đáng tin cậy với khả năng chịu lỗi và cơ chế dự phòng ở mức độ cao.",
        h15: "Là thành viên của Reactor, bạn sẽ được hưởng hỗ trợ máy chủ độc quyền, điều này sẽ cải thiện hơn nữa tốc độ thực hiện và hiệu quả của các giao dịch.",
        h16: 'nút phân tán',
        h17: "phòng máy tính",
        h18: "cụm máy chủ",
        h19: "Cung cấp máy chủ chuyên dụng miễn phí",
        h20: "Reactor phân bổ miễn phí tài nguyên máy chủ chuyên dụng cho các thành viên chuyên nghiệp, cung cấp môi trường hoạt động độc lập và hiệu quả cho rô-bốt của bạn",
        h21: "Xem chi tiết",
        h22: "Kiếm thu nhập ổn định mỗi ngày",
        h23: "Thiết lập tiền xu và số lượng, thị trường được chọn và cài đặt chênh lệch giá trao đổi cũng như cài đặt chênh lệch giá thị trường để rô-bốt có thể nắm bắt chính xác chênh lệch giá giữa các sàn giao dịch khác nhau và có được cơ hội chênh lệch giá từ chúng.",
        h24: "Đồng thời, rô-bốt còn có các chức năng phục hồi và tồn đọng, có thể linh hoạt ứng phó với những biến động của thị trường và đảm bảo rằng khoản đầu tư của bạn tiếp tục tăng.",
        h25: "Tìm hiểu thêm",
    },
    join: {
        h1: "Mở rộng hành trình khám phá Lò phản ứng của bạn và chọn giải pháp phù hợp với bạn!",
        h2: "người mới",
        h3: "Miễn phí",
        h4: "Có thể sử dụng một chiến lược mẫu",
        h5: "Có thể tạo một tổ hợp chiến lược",
        h6: "Có thể thực hiện 40 tổ hợp chiến lược",
        h7: "Hỗ trợ 2 sàn giao dịch",
        h8: "Danh mục quản lý đầu tư",
        h9: "Hãy bắt đầu",
        h10: "Nâng cao",
        h11: "tháng",
        h12: "Có thể sử dụng 5 chiến lược mẫu",
        h13: "Có thể tạo 5 tổ hợp chiến lược",
        h14: "Có thể thực hiện 200 tổ hợp chiến lược",
        h15: "Hỗ trợ 5 sàn giao dịch",
        h16: "Danh mục quản lý đầu tư",
        h17: "Có thể sao chép và theo dõi 5 giao dịch robot",
        h18: "Miễn phí sử dụng giao dịch mô phỏng",
        h19: "Chiến lược Thị trường mở",
        h20: "Nâng cao",
        h21: "Có thể sử dụng 10 chiến lược mẫu",
        h22: "Có thể tạo 15 kết hợp chiến lược",
        h23: "Có thể thực hiện 500 kết hợp chiến lược",
        h24: "Hỗ trợ 20 sàn giao dịch",
        h25: "Danh mục quản lý đầu tư",
        h26: "Có thể sao chép và theo dõi 20 giao dịch robot",
        h27: "Nhận 2 buổi khóa đào tạo giao dịch hàng tháng",
        h28: "Dịch vụ thiết kế chiến lược tùy chỉnh một-một",
        h29: "Hỗ trợ chiến lược AI của Lò phản ứng và nhà thiết kế AI",
        h30: "Ứng dụng hỗ trợ trở thành chiến lược gia hợp tác",
        h31: "Quyền truy cập miễn phí vào cộng đồng dành riêng cho VIP",
        h32: "Chuyên nghiệp",
        h33: "Chính sách mẫu sử dụng không giới hạn",
        h34: "Tạo kết hợp chiến lược không giới hạn",
        h35: "Kết hợp chiến lược thực thi không giới hạn",
        h36: "Trao đổi không giới hạn",
        h37: "Danh Mục Quản Lý Đầu Tư",
        h38: "Có thể sao chép và theo dõi 50 giao dịch robot",
        h39: "Tất cả các khóa đào tạo giao dịch hàng tháng",
        h40: "Dịch vụ thiết kế chiến lược tùy chỉnh một-một",
        h41: "Hỗ trợ chiến lược AI của Lò phản ứng và nhà thiết kế AI",
        h42: "Hướng dẫn chỉ báo kỹ thuật bổ sung",
        h43: "Hỗ trợ ứng dụng trở thành chiến lược gia được mời đặc biệt và cùng nhau chia sẻ lợi nhuận",
        h44: "Máy chủ chuyên dụng",
        h45: "Kiểm tra khoảng thời gian chính sách độc quyền",
        h46: "So sánh cấp thành viên",
        h47: "Giao dịch thủ công",
        h48: "Robot giao dịch",
        h49: "Robot sao chép",
        h50: "Robot tạo thị trường",
        h51: "Robot kinh doanh chênh lệch giá",
        h52: "Khóa đào tạo giao dịch",
        h53: "giao dịch mô phỏng",
        h54: "Chiến lược tùy chỉnh một đối một",
        h55: "Đăng ký trở thành nhà chiến lược hợp tác",
        h56: "Cộng đồng dành riêng cho VIP",
        h57: "Hướng dẫn chỉ báo kỹ thuật",
        h58: "Đăng ký để trở thành chiến lược gia được mời đặc biệt và cùng nhau chia sẻ lợi nhuận",
        h59: "Máy chủ chuyên dụng",
        h60: "Kiểm tra khoảng thời gian chính sách độc quyền",
    },
    manualTrading: {
        h1: "Quyền ra quyết định độc lập",
        h2: "Sự kết hợp hoàn hảo giữa giao dịch định lượng và giao dịch thủ công",
        h3: "Xem thêm",
        h4: "Reactor có khả năng tuyệt vời trong giao dịch tự động, nhưng tại sao nó vẫn yêu cầu tham gia giao dịch thủ công?",
        h5: "Trải nghiệm giao dịch tổng thể và toàn diện hơn",
        h6: "Bảo lưu quyền quyết định giao dịch của bạn",
        h7: "Giao dịch thủ công cho phép người dùng giữ quyền quyết định giao dịch cuối cùng và bạn vẫn có thể đưa ra quyết định cuối cùng về giao dịch theo phán đoán và tình huống của riêng mình.",
        h8: "giao dịch theo sự kiện",
        h9: "Một số sự kiện và thông báo tin tức có thể có tác động đáng kể đến thị trường và những sự kiện này có thể không hoàn toàn dự đoán được và không thể thích ứng với các hệ thống giao dịch tự động.",
        h10: "Nhận định và kinh nghiệm nghề nghiệp",
        h11: "Các nhà giao dịch có kinh nghiệm có kiến ​​thức thị trường phong phú và khả năng phán đoán chuyên nghiệp, đồng thời có thể đưa ra quyết định chính xác hơn dựa trên kinh nghiệm của chính họ.",
        h12: "Nhanh chóng ứng phó với rủi ro thị trường và bảo vệ tài sản của bạn",
        h13: "Thông quan dừng khẩn cấp",
        h14: "Khi điều kiện thị trường không thuận lợi, người dùng có thể kích hoạt chức năng thanh lý dừng khẩn cấp trong Lò phản ứng và bán tất cả tài sản đang nắm giữ thông qua thao tác một cú nhấp chuột.",
    },
    market: {
        h1: "Thị trường tiền điện tử hôm nay",
        h2: "Trên nền tảng Reactor, bạn có thể chọn hàng nghìn loại tiền điện tử từ hơn 10 nền tảng thanh khoản cho các giao dịch đầu tư định lượng. Chúng tôi đã thiết lập quan hệ đối tác với nhiều sàn giao dịch nổi tiếng để cung cấp cho bạn nhiều lựa chọn về tiền điện tử.",
        h3: 'Thị trường tiền điện tử',
        h4: "USD",
        h5: "24 giờ",
        h6: "Binance",
        h7: "Ouy",
        h8: "Huobi",
        h9: "Tiền tệ",
        h10: "Giá",
        h11: "24h thăng trầm",
        h12: "Khối lượng giao dịch 24 giờ",
        h13: "Giá trị thị trường",
        h14: "100 triệu",
        h15: "Tổng cộng: 608 mục, hiển thị trên mỗi trang: 20 mục",
    },
    merchantRobot: {
        h1: "Robot tạo thị trường của lò phản ứng",
        h2: "Tham gia thị trường tốt hơn, cải thiện kết quả giao dịch và có được trải nghiệm tốt hơn trong quá trình đầu tư",
        h3: 'Xem thêm',
        h4: "Robot tạo thị trường của Reactor hoạt động như thế nào?",
        h5: "Robot tạo thị trường của Reactor sẽ giám sát các điều kiện thị trường, trạng thái đặt hàng và hoạt động giao dịch của nhiều sàn giao dịch. Nó sẽ thu thập dữ liệu thị trường mới nhất theo thời gian thực, bao gồm thông tin như số lượng, giá và độ sâu của lệnh mua và bán, đồng thời tự động thực hiện các hoạt động mua và bán, đồng thời tự động điều chỉnh báo giá và khối lượng giao dịch theo những thay đổi của điều kiện thị trường và sổ lệnh.",
        h6: "Ưu điểm của robot tạo lập thị trường",
        h7: "Mô hình thu nhập ổn định",
        h8: "Các hoạt động tạo thị trường liên tục để thu được thu nhập chênh lệch giao dịch và mô hình thu nhập tương đối ổn định",
        h9: "Cung cấp thanh khoản",
        h10: "Cung cấp tính thanh khoản cho thị trường bằng cách báo giá và cung cấp các lệnh mua và bán.",
        h11: "Giám sát thị trường thời gian thực",
        h12: "Tự động điều chỉnh chiến lược báo giá và thanh khoản theo biến động thị trường và trạng thái đơn hàng",
        h13: "Giao dịch tự động",
        h14: "Điều chỉnh báo giá và lệnh trong thời gian thực theo điều kiện thị trường",
        h15: "Tại sao nên sử dụng robot tạo lập thị trường trong Reactor?",
        h16: "So với các nhà tạo lập thị trường thông thường truyền thống, robot tạo lập thị trường do Reactor ra mắt có ưu điểm là thực thi tự động, lợi thế về kỹ thuật, thích ứng thị trường nhanh chóng, phân tích và tối ưu hóa dữ liệu cũng như cấu hình linh hoạt.",
    },
    policyBacktest: {
        h1: "Kiểm tra lại chiến lược",
        h2: "Người dùng thực hiện các giao dịch mô phỏng trên dữ liệu thị trường lịch sử theo các chiến lược giao dịch của riêng họ để đánh giá hiệu quả của chiến lược và lợi nhuận tiềm năng.",
        h3: 'Xem thêm',
        h4: "Lựa chọn linh hoạt, backtest chính xác",
        h5: "Chọn cặp giao dịch và khoảng thời gian",
        h6: "Bạn có thể chọn cặp giao dịch và khoảng thời gian phù hợp dựa trên sở thích cá nhân và điều kiện thị trường để đánh giá chính xác hơn hiệu suất và rủi ro tiềm ẩn của chiến lược.",
        h7: "Tìm sự kết hợp tốt nhất để cải thiện hiệu suất chiến lược",
        h8: "Cài đặt tham số và tối ưu hóa",
        h9: "Bạn có thể linh hoạt điều chỉnh các tham số như tần suất giao dịch, điều kiện dừng lợi nhuận và dừng lỗ để tìm cấu hình chiến lược tốt nhất. Việc không ngừng thử và tối ưu hóa kết hợp tham số sẽ giúp cải thiện lợi nhuận và tính ổn định của chiến lược.",
        h10: "Báo cáo chi tiết để hiểu toàn diện về hiệu suất chiến lược",
        h11: "Báo cáo và phân tích kết quả backtest",
        h12: "Reactor sẽ tạo báo cáo kết quả kiểm tra lại chi tiết. Báo cáo bao gồm các chỉ số chính như tỷ lệ hoàn vốn, tỷ lệ chiến thắng và mức thoái lui tối đa, cho phép bạn hiểu đầy đủ về hiệu suất của chiến lược. Báo cáo giúp bạn khám phá những lợi thế và khả năng cải thiện của chiến lược cũng như thực hiện các điều chỉnh tương ứng.",
        h13: "Hiển thị trực quan, hiểu sâu về các thay đổi chiến lược",
        h14: "Hiển thị biểu đồ và chỉ báo",
        h15: "Reactor cung cấp các biểu đồ và chỉ báo trực quan để cho phép bạn hiểu rõ hơn về kết quả kiểm tra lại chiến lược. Bạn có thể xem các biểu đồ như biểu đồ giá, tín hiệu giao dịch và vị trí, đồng thời bạn cũng có thể sử dụng các chỉ báo kỹ thuật khác nhau để hỗ trợ đánh giá hiệu quả của các chiến lược.",
        h16: "Sơ đồ kiểm tra lại tùy chỉnh",
        h17: "Reactor cung cấp cho bạn trải nghiệm kiểm tra lại tùy chỉnh và riêng tư: các giải pháp kiểm tra lại được thiết kế riêng, phân tích kết quả kiểm tra lại được cá nhân hóa, điều chỉnh và tối ưu hóa chiến lược theo thời gian thực cũng như các đề xuất giao dịch tùy chỉnh",
        h18: "Xem thêm",
        h19: "Nguyên tắc cơ bản của kiểm tra ngược chiến lược lò phản ứng",
        h20: "Giao dịch mô phỏng dựa trên dữ liệu lịch sử thị trường và thiết lập các quy tắc giao dịch để đánh giá và xác minh hiệu suất của các chiến lược",
        h21: "Dữ liệu thị trường lịch sử",
        h22: "Reactor sử dụng dữ liệu thị trường trong quá khứ, bao gồm các chỉ báo như giá và khối lượng giao dịch, để xây dựng môi trường thị trường lịch sử mô phỏng.",
        h23: "Thực thi giao dịch được mô phỏng",
        h24: "Dựa trên các quy tắc giao dịch đã đặt, Reactor thực hiện khớp lệnh giao dịch mô phỏng trên dữ liệu thị trường lịch sử.",
        h25: "Đánh giá kết quả backtest",
        h26: "Reactor sẽ tính toán các chỉ số chính dựa trên hồ sơ giao dịch trong khoảng thời gian kiểm tra lại, chẳng hạn như lợi suất, tỷ lệ chiến thắng, mức thoái lui tối đa, v.v.",
    },
    rebate: {
        h1: "Chia sẻ liên kết, kiếm thêm thu nhập và kiếm xu với bạn bè!",
        h2: "Mời bạn bè của bạn bằng cách chia sẻ liên kết được cung cấp. Bạn có thể kiếm được tới 15% hoa hồng cho mỗi đăng ký mà bạn bè của bạn mua trên Reactor.",
        h3: 'Xem chi tiết',
        h4: "Có thể rút tiền hoa hồng",
        h5: "Khuyến mãi không giới hạn",
        h6: "Càng thăng hạng, phần thưởng càng nhiều",
        h7: "Hoa hồng lên tới 15%",
        h8: "Tại sao chọn Lò phản ứng?",
        h9: "Bảo vệ tài sản của bạn",
        h10: "Reactor áp dụng các giao thức bảo mật và công nghệ mã hóa ở mức cao nhất để đảm bảo rằng tài sản kỹ thuật số của bạn luôn được bảo vệ mạnh nhất.",
        h11: "Cái nhìn sâu sắc về xu hướng thị trường",
        h12: "Reactor cung cấp các công cụ phân tích và thống kê dữ liệu toàn diện để giúp bạn hiểu sâu hơn về xu hướng thị trường và biến động giá cả. Đồng thời, điều chỉnh chiến lược giao dịch của bạn theo điều kiện thị trường.",
        h13: "Kinh nghiệm mua bán nhanh chóng hiệu quả",
        h14: "Reactor cung cấp khả năng thực hiện giao dịch và xử lý đơn hàng tốc độ cao để đảm bảo rằng bạn có thể nhanh chóng phản ứng với những thay đổi của thị trường và nắm bắt cơ hội giao dịch.",
        h15: "Chúng tôi sẽ bảo vệ quyền riêng tư và thông tin bạn bè của bạn",
        h16: "Tính bảo mật và quyền riêng tư của người dùng là điều tối quan trọng đối với chúng tôi. Reactor được trang bị cơ sở hạ tầng bảo mật hàng đầu để đảm bảo bảo vệ đầy đủ tài sản được quản lý mọi lúc.",
        h17: "Xem Thỏa thuận về quyền riêng tư",
        h18: "Làm cách nào để sử dụng liên kết giới thiệu?",
        h19: "Gửi cho bạn bè qua email hoặc tin nhắn riêng.",
        h20: "Chia sẻ trên mạng xã hội.",
        h21: "Đã đăng trên trang web cá nhân.",
        h22: "Đăng video lên Youtube và đăng liên kết trong phần bình luận.",
        h23: "Xem quy tắc lời mời",
    },
    signal: {
        h1: "Công cụ giao dịch tự động thông minh",
        h2: "Nhận các mẫu tín hiệu giao dịch chất lượng cao để đạt được lợi tức đầu tư ổn định",
        h3: 'Xem chi tiết',
        h4: "Mẫu tín hiệu giao dịch chất lượng cao",
        h5: "Sàng lọc và xác minh nghiêm ngặt",
        h6: "Lấy hoạt động thực tế của các nhà giao dịch chuyên nghiệp, bao gồm các chỉ số chính như điểm mua, điểm bán, vị trí dừng lỗ, v.v. và có thể được sao chép tự động sau khi đăng ký",
        h7: "Dưới sự sàng lọc nghiêm ngặt của Reactor, tỷ lệ thắng trung bình của các tín hiệu đạt 58,6%",
        h8: "Có hơn 100 nhà cung cấp tín hiệu trong trung tâm thương mại, họ là những thương nhân hoặc tổ chức chuyên nghiệp.",
        h9: "Tìm hiểu thêm",
        h10: "Nộp hồ sơ xét tuyển",
        h11: "Hiển thị chiến lược giao dịch và kiến ​​thức chuyên môn của bạn trong trung tâm mua sắm, đồng thời chia sẻ kinh nghiệm thành công của bạn với các nhà giao dịch trên khắp thế giới, từ đó mở rộng tầm ảnh hưởng và tiềm năng kiếm tiền của bạn.",
        h12: "Liên hệ với chúng tôi",
        h13: "Bạn có thể sử dụng những dữ liệu này để chọn tín hiệu phù hợp với mình chính xác hơn",
        h14: "Tần số tín hiệu",
        h15: "Gửi tần số của tín hiệu để xác định mức độ thích ứng của tín hiệu với tùy chọn giao dịch của bạn.",
        h16: "Số người đăng ký",
        h17: "Biết số lượng người đăng ký SignalR, như một chỉ báo về sự chấp nhận của cộng đồng",
        h18: 'Xếp hạng và hiệu suất',
        h19: "Số lượng tín hiệu trong 3 tháng gần nhất, lợi nhuận trung bình trên mỗi giao dịch và các chỉ số khác",
        h20: "Thống kê và bình luận",
        h21: "Kiểm tra số liệu thống kê thời gian thực và nhận xét của người dùng khác bất cứ lúc nào",
    },
    strategy: {
        h1: "Tùy chỉnh chiến lược giao dịch độc đáo của bạn",
        h2: "Cung cấp cho bạn các giải pháp giao dịch được cá nhân hóa",
        h3: 'Liên hệ với chúng tôi',
        h4: "Nhà chiến lược định lượng độc quyền",
        h5: "Hỗ trợ giao dịch được cá nhân hóa và tư vấn chuyên nghiệp",
        h6: "Trở thành chiến lược gia được mời đặc biệt và chia sẻ lợi nhuận cùng nhau",
        h7: "Máy chủ chuyên dụng",
        h8: "Hỗ trợ giao dịch được cá nhân hóa và tư vấn chuyên nghiệp",
        h9: "Chúng tôi biết rằng mục tiêu và nhu cầu đầu tư của mỗi khách hàng là khác nhau, vì vậy chúng tôi sẽ làm việc với bạn thông qua các chiến lược gia chuyên dụng của chúng tôi để đảm bảo rằng các chiến lược giao dịch của bạn phù hợp chặt chẽ với mục tiêu của bạn.",
        h10: "Chiến lược",
        h11: "Các chiến lược gia của chúng tôi sẽ làm việc trực tiếp với bạn để hiểu sâu hơn về các mục tiêu đầu tư, mức độ chấp nhận rủi ro và yêu cầu về thời gian của bạn.",
        h12: "Giải pháp phù hợp với bạn",
        h13: "Nhóm của chúng tôi sẽ vượt quá mong đợi để giúp bạn đạt được mục tiêu của mình",
        h14: "Khách hàng cá nhân",
        h15: "Bạn muốn đạt được mục tiêu đầu tư đầy tham vọng nhất của mình? Chúng tôi đã giúp vô số khách hàng tối đa hóa lợi nhuận, giảm chi phí và tăng tài sản danh mục đầu tư.",
        H141: "Doanh nghiệp",
        H151: "Tìm kiếm hiệu quả tài chính và ROI cao hơn? Chúng tôi làm việc với nhiều doanh nghiệp để cung cấp cho bạn các giải pháp phù hợp để giúp bạn đạt được mục tiêu kép là tăng trưởng vốn và quản lý rủi ro.",
        h16: "Doanh nghiệp",
        h17: "Chúng tôi đáp ứng mọi nhu cầu của bạn",
        h18: "Lò phản ứng có lợi thế vô song so với các sản phẩm hàng đầu của chúng tôi",
        h19: "Nhà chiến lược định lượng độc quyền",
        h20: "Có thể tùy chỉnh các chiến lược giao dịch định lượng theo nhu cầu và mục tiêu của bạn. Họ sẽ hợp tác chặt chẽ với bạn để hiểu sâu hơn về sở thích đầu tư và mức độ chấp nhận rủi ro của bạn, đồng thời cung cấp cho bạn lời khuyên giao dịch được cá nhân hóa dựa trên xu hướng thị trường và phân tích dữ liệu.",
        h21: "Hỗ trợ giao dịch được cá nhân hóa và tư vấn chuyên nghiệp",
        h22: "Chúng tôi sẽ hiểu sâu sắc các mục tiêu và nhu cầu giao dịch của bạn, cung cấp cho bạn các đề xuất và chiến lược giao dịch được nhắm mục tiêu, đồng thời giúp bạn tối ưu hóa các quyết định giao dịch của mình. Cho dù đó là phân tích kỹ thuật, nghiên cứu thị trường hay quản lý rủi ro, chúng tôi sẽ cung cấp cho bạn hướng dẫn và hỗ trợ chuyên nghiệp.",
        h23: "Trở thành chiến lược gia được mời đặc biệt và cùng nhau chia sẻ lợi nhuận",
        h24: "Là một nhà chiến lược khách mời, bạn sẽ có cơ hội chia sẻ kinh nghiệm và chiến lược giao dịch thành công của mình, đồng thời học hỏi và phát triển cùng với những người dùng khác. Đồng thời, bạn sẽ có cơ hội chia sẻ lợi nhuận và chia sẻ lợi nhuận giao dịch bằng cách hợp tác với Reactor.",
        h25: "Máy chủ chuyên dụng",
        h26: "Máy chủ của chúng tôi có hiệu suất cao và độ trễ thấp, có thể nhanh chóng xử lý các lệnh giao dịch và đảm bảo thực hiện giao dịch kịp thời. Bằng cách sử dụng máy chủ chuyên dụng, bạn có thể nắm bắt tốt hơn các cơ hội giao dịch và cải thiện tỷ lệ thành công cũng như hiệu quả của giao dịch.",
        h27: "Dịch vụ tùy chỉnh",
        h28: "Giúp bạn đạt được mục tiêu giao dịch của mình và đạt được thành công lớn hơn trong lĩnh vực giao dịch định lượng. Bất kể phong cách, sở thích hay mục tiêu giao dịch của bạn là gì, chúng tôi sẽ hết lòng cung cấp cho bạn các dịch vụ tùy chỉnh chất lượng cao nhất",
        h29: "Liên hệ với chúng tôi",
    },
    strategyMall: {
        h1: "Khám phá các chiến lược chất lượng cao và đạt được bước đột phá trong giao dịch",
        h2: "Khám phá cơ hội thị trường với các nhà giao dịch hàng đầu",
        h3: 'Xem chi tiết',
        h4: "Trung tâm thương mại chiến lược",
        h5: "Tối ưu hóa trải nghiệm giao dịch",
        h6: "Chiến lược giao dịch đã được chứng minh và lựa chọn",
        h7: "Mua một lần để sử dụng trọn đời, bạn có thể nhận và giữ quyền sử dụng chiến lược giao dịch đã mua và có thể tải xuống chiến lược này.",
        h8: "Dữ liệu hiệu suất chi tiết, bao gồm thông tin như lợi nhuận lịch sử, chỉ số rủi ro và tần suất giao dịch.",
        h9: "Chúng tôi cung cấp lịch sử giao dịch chi tiết để giúp bạn hiểu đầy đủ về hiệu suất thực tế của từng chiến lược.",
        h10: "Tìm hiểu thêm về trung tâm chiến lược",
        h11: "Nộp hồ sơ xét tuyển",
        h12: "Hiển thị chiến lược giao dịch và kiến ​​thức chuyên môn của bạn trong trung tâm mua sắm, chia sẻ kinh nghiệm thành công của bạn với các nhà giao dịch trên khắp thế giới, để mở rộng tầm ảnh hưởng và tiềm năng kiếm tiền của bạn.",
        h13: "Liên hệ với chúng tôi",
        h14: "Ưu điểm của Reactor Strategy Mall?",
        h15: "Đa dạng lựa chọn chiến lược",
        h16: "Giao dịch tự chủ linh hoạt",
        h17: "Nhà chiến lược chuyên nghiệp",
        h18: "Cho dù bạn thích chiến lược theo xu hướng, giao dịch xoay vòng hay chênh lệch giá, bạn đều có thể tìm thấy chiến lược phù hợp với mình",
        h19: "Khả năng điều chỉnh linh hoạt các tham số giao dịch trên cơ sở duy trì các chiến lược thích ứng với sự thay đổi của điều kiện thị trường",
        h20: "Các nhà chiến lược có hiểu biết sâu sắc về thị trường và hồ sơ giao dịch thành công, họ sẽ hỗ trợ đắc lực cho các giao dịch của bạn",
        h21: "Tìm hiểu thêm",
        h22: "Giám sát chính sách nghiêm ngặt",
        h23: "Để đảm bảo tính minh bạch và độ tin cậy của các chiến lược giao dịch, mỗi chiến lược cần phải trải qua quá trình xem xét và thử nghiệm của chúng tôi trước khi đưa lên mạng.",
        h24: "Chiến lược đã được thử nghiệm theo thời gian",
        h25: "Chiến lược đã trải qua quá trình mô phỏng và kiểm tra ngược thời gian thực trong thời gian dài trong nền tảng của chúng tôi và dữ liệu lịch sử mà chúng tôi cung cấp là hoàn toàn có thật.",
    },
    business: {
        h1: "Tạo cơ hội kinh doanh và chia sẻ thành công",
        h2: "Chúng tôi nồng nhiệt chào đón các đối tác từ mọi tầng lớp xã hội hợp tác với chúng tôi. Khi tham gia chương trình đối tác của chúng tôi, bạn sẽ có cơ hội trở thành đối tác chiến lược của chúng tôi để cùng tạo ra các cơ hội kinh doanh và đạt được kết quả đôi bên cùng có lợi.",
        h3: 'Liên hệ với chúng tôi',
        h4: "Hỗ trợ đối tác và chia sẻ tài nguyên",
        h5: "Là đối tác, bạn sẽ được hưởng sự hỗ trợ toàn diện và các tài nguyên mà chúng tôi cung cấp. Chúng tôi sẽ cung cấp đào tạo chuyên nghiệp và hỗ trợ kỹ thuật để giúp bạn hiểu rõ hơn về sản phẩm và dịch vụ của chúng tôi, đồng thời có thể cung cấp cho khách hàng các giải pháp chất lượng cao. Ngoài ra, bạn cũng sẽ nhận được hỗ trợ tiếp thị của chúng tôi, bao gồm các hoạt động tiếp thị dành riêng cho đối tác, tài liệu tiếp thị và quảng bá thương hiệu. Chúng tôi sẽ hợp tác với bạn để cùng thúc đẩy tăng trưởng kinh doanh và chia sẻ thành công.",
        h6: "Hợp tác cùng có lợi, phát triển bền vững",
        h7: "Chúng tôi đánh giá cao sự hợp tác chặt chẽ với các đối tác và tin rằng thông qua nỗ lực chung và hỗ trợ lẫn nhau, chúng tôi có thể đạt được thành công lớn hơn. Chúng tôi cung cấp các mô hình hợp tác linh hoạt, bao gồm kế hoạch bán hàng, phân phối và quảng cáo của đối tác, để tạo ra lợi nhuận kinh tế phong phú cho đối tác. Đồng thời, chúng tôi khuyến khích các đối tác đưa ra phản hồi và đề xuất có giá trị để giúp chúng tôi tiếp tục cải tiến, đổi mới và cùng nhau phát triển. Chúng tôi tin rằng thông qua khái niệm hợp tác cùng có lợi, chúng tôi sẽ tiếp tục phát triển trong thị trường cạnh tranh cao.",
        h8: "Tham gia cùng chúng tôi",
        h9: "Nếu bạn quan tâm đến chương trình đối tác của chúng tôi, chúng tôi hoan nghênh bạn tham gia cùng chúng tôi. Cho dù bạn là một công ty khởi nghiệp sáng tạo, một nhà cung cấp công nghệ trưởng thành hay một công ty đang tìm kiếm cơ hội kinh doanh mới, chúng tôi sẵn sàng hợp tác với bạn và cùng nhau tạo ra con đường dẫn đến thành công. Tham gia chương trình đối tác của chúng tôi, bạn sẽ có cơ hội hợp tác chặt chẽ với chúng tôi, chia sẻ tài nguyên và kinh nghiệm của chúng tôi, đồng thời cùng mở rộng thị trường để đạt được một tình huống đôi bên cùng có lợi.",
    },
    dowView: {
        h1: "Tải ứng dụng khách",
        h2: "Khám phá tiềm năng vô hạn của giao dịch định lượng và bình tĩnh đối phó với những biến động của thị trường.",
        h3: 'Dùng thử miễn phí',
    },
    contactView: {
        h1: "Liên hệ với chúng tôi, hoặc đặt lịch hẹn đến văn phòng của chúng tôi",
        h2: "Phòng 608, tòa nhà Baotong, đường Baomin 1, đường Xin'an, quận Baoan, Thâm Quyến",
        h3: 'Tham khảo ngay',
    },
    helpView: {
        h1: "Bạn cần thêm trợ giúp?",
        h2: "Hãy để lại tin nhắn cho nhóm của chúng tôi và chúng tôi sẽ liên lạc với bạn trong giờ làm việc.",
        h3: 'Liên hệ Hỗ trợ',
    },
    token: {
        h1: "Hợp đồng",
        h2: "Thống kê thị trường",
        h3: 'Xếp hạng giá trị thị trường',
        h4: "giá trị thị trường",
        h5: "100 triệu",
        h6: "Khối lượng giao dịch 24 giờ",
        h7: "24 giờ cao nhất",
        h8: "Giá cao nhất trong lịch sử",
        h9: "Cung cấp tối đa",
        h10: "Thị trường thời gian thực Ethereum (ETH),",
        h11: "Giá Ethereum hiện tại là 1.786,68 USD và giá trị của nó đã giảm 0,31% trong 24 giờ qua. Ethereum giảm 64,33% so với mức cao nhất mọi thời đại là 4.878,26 USD. Ethereum đứng thứ hai về vốn hóa thị trường, chiếm 19,08% toàn bộ thị trường tiền điện tử. Ethereum là một loại tiền tệ phi tập trung mà bạn có thể mua, bán và giao dịch định lượng ETH.",
        h12: "Ethereum là gì",
        h13: "Ethereum là một nền tảng chuỗi khối phi tập trung cho phép các nhà phát triển xây dựng các ứng dụng phi tập trung (dApps) trên nền tảng chuỗi khối của nó. Được Vitalik Buterin ra mắt vào năm 2015, Ethereum đã trở thành một trong những nền tảng chuỗi khối phổ biến nhất trên thế giới nhờ hệ sinh thái mạnh mẽ, cộng đồng tích cực và các tính năng sáng tạo. Ether (ETH) là tiền điện tử gốc của Ethereum và có nhiều cách sử dụng trên nền tảng. Một cách hệ thống quản trị nền tảng. Hai trong số các tính năng quan trọng nhất của Ethereum là khả năng tương tác và khả năng mở rộng. Khả năng kết nối và giao tiếp của Ethereum với các mạng và nền tảng chuỗi khối khác, dẫn đến chức năng và khả năng tương thích cao hơn.Mặt khác, khả năng mở rộng đề cập đến khả năng của Ethereum trong việc xử lý một lượng lớn giao dịch và dữ liệu mà không ảnh hưởng đến hiệu suất hoặc tính bảo mật của nó. cải thiện khả năng mở rộng và cải thiện bảo mật. Do đó, điều này đòi hỏi phải đặt cược thay vì khai thác để xác minh các giao dịch trên Ethereum. Mặc dù đã được chờ đợi từ lâu, việc sáp nhập chỉ là khởi đầu của một loạt các cập nhật lớn mà Ethereum phải trải qua để hoàn thành tầm nhìn của Vitalik.",
        h14: "Tại sao Reactor là lựa chọn phù hợp cho giao dịch định lượng",
        h15: "Đầu tư đa dạng",
        h16: "Phân bổ tiền trong các sàn giao dịch và ví phi tập trung, giúp giảm thiểu rủi ro một cách hiệu quả và tăng lợi nhuận tiềm năng.",
        h17: "Hỗ trợ tài khoản demo",
        h18: "Cung cấp môi trường mô phỏng thị trường thực, cho phép bạn tiến hành thử nghiệm chiến lược và thực hành đầu tư theo cách không có rủi ro.",
        h19: "Thống kê dữ liệu giao dịch",
        h20: "Bạn có thể tự tin sử dụng robot của chúng tôi để giao dịch và tận hưởng trải nghiệm đầu tư thuận tiện và an toàn.",
        h21: "Duyệt qua các nội dung khác",

        BTC0: '比特币 là một loại tiền điện tử phi tập trung. Trong bài viết trắng được công bố vào năm 2008, được một cá nhân hoặc một nhóm người sử dụng bút danh Satoshi Nakamoto đề cập lần đầu về cơ chế của Bitcoin. Tháng 1 năm 2009, Bitcoin chính thức ra mắt.',

        BTC1: '比特币 là một loại tiền tệ dựa trên mạng lưới ngang hàng, tất cả các giao dịch diễn ra trực tiếp giữa các thành viên mạng lưới độc lập và bình đẳng, mà không cần phải thông qua bất kỳ bên trung gian nào để cho phép hay khuyến khích. Theo lời của chính Satoshi Nakamoto, mục đích tạo ra Bitcoin là để cho "một bên có thể thanh toán trực tuyến cho bên kia mà không cần thông qua các tổ chức tài chính".',

        BTC2: 'Trước khi Bitcoin ra đời, đã xuất hiện một số khái niệm tiền điện tử phi tập trung tương tự, nhưng điểm độc đáo của Bitcoin trong việc áp dụng tiền điện tử vào cuộc sống hàng ngày là chưa từng có trong lịch sử.',

        ETH0: 'Ethereum là một hệ thống blockchain mã nguồn mở phi tập trung, nó có đồng tiền điện tử gốc riêng của mình - Ether. Ethereum cũng là nền tảng giao dịch cho nhiều loại tiền điện tử khác nhau và có thể thực thi hợp đồng thông minh phi tập trung.',

        ETH1: 'Vitalik Buterin đã giới thiệu Ethereum lần đầu trong bài viết trắng được xuất bản vào năm 2013. Buterin và các đồng sáng lập khác đã cùng nhau thu thập quỹ trực tuyến cho dự án vào mùa hè năm 2014 và chính thức ra mắt Ethereum Blockchain vào ngày 30 tháng 7 năm 2015.',

        ETH2: 'Ethereum tuyên bố mục tiêu trở thành một nền tảng ứng dụng phi tập trung toàn cầu, cho phép người dùng trên toàn thế giới có thể viết và chạy phần mềm mà không cần được kiểm duyệt, không có lỗi hoặc gian lận.',

        USDT0: 'Tether (USDT) là một loại tiền điện tử, với mục tiêu phản ánh giá trị của Đô la Mỹ. Tether được ra mắt vào năm 2014, với mục tiêu tạo ra một loại tiền điện tử ổn định có thể sử dụng như Đô la số hoặc "stablecoin". Giá trị của Tether được gắn liền với Đô la Mỹ.',

        USDT1: 'Ban đầu, Tether sử dụng lớp Omni của mạng Bitcoin làm giao thức chuyển tiền, hiện nay nó cũng có thể sử dụng làm token ERC-20 trên Ethereum. Tether đã phát hành trên mạng Bitcoin (giao thức Omni và Liquid), Ethereum, EOS và Tron.',

        USDC2: 'Đồng Mỹ (USDC) đã phát hành một số trường hợp sử dụng. Ngoài việc cung cấp nơi trú ẩn an toàn cho nhà giao dịch tiền điện tử trong giai đoạn biến động, những người đứng sau stablecoin cũng cho biết, nó còn cho phép các doanh nghiệp chấp nhận thanh toán bằng tài sản số và tác động đến một loạt ngành công nghiệp bao gồm tài chính phi tập trung và trò chơi.',

        DOGE0: 'Đồng Dogecoin (DOGE) được đặt tên theo từ ngôn ngữ Internet phổ biến "DOGE" và logo của nó có một con chó Shiba Inu. Đồng tiền điện tử mã nguồn mở này được sáng tạo bởi Billy Markus ở Portland, Oregon, Hoa Kỳ và Jackson Palmer ở Sydney, Australia, và được phát triển từ Litecoin vào tháng 12 năm 2013. Người sáng lập Dogecoin đã thiết lập nó là một loại tiền ảo vui nhộn và thoải mái, hy vọng nó sẽ thu hút nhiều người dùng hơn là khách hàng tiềm năng của Bitcoin, vì hình ảnh của nó xuất phát từ biểu tượng "doge" trên mạng. Elon Musk, CEO của Tesla, đã nhiều lần tweet rằng Dogecoin là sự lựa chọn yêu thích của ông.',

        ADA0: 'Cardano là một nền tảng blockchain sử dụng cơ chế chứng minh cổ phần, với mục tiêu tuyên bố là để các "thay đổi gia", "nhà sáng tạo" và "nhìn xa trông rộng" mang lại sự thay đổi tích cực một cách toàn cầu.',
        ADA1: 'Dự án mã nguồn mở này cũng có mục tiêu khác, đó là "phân phối lại quyền lực từ các tổ chức không chịu trách nhiệm cho cá nhân" - nhằm tạo ra một xã hội an toàn, minh bạch và công bằng hơn.',
        ADA2: 'Cardano được thành lập vào năm 2017, đồng token ADA được thiết kế để đảm bảo người sở hữu có thể tham gia vào hoạt động mạng lưới. Do đó, những người nắm giữ tiền điện tử này có quyền bỏ phiếu cho bất kỳ đề xuất cải tiến nào.',
        ADA3: 'Nhóm này cho rằng công nghệ của họ được phát triển để phát triển ứng dụng phi tập trung và hợp đồng thông minh theo khái niệm mô-đun, hiện đã có một số trường hợp sử dụng rất tốt,',
        ADA4: 'Công ty nông nghiệp có thể sử dụng Cardano để theo dõi quy trình từ sản xuất nông sản từ đất đến bàn ăn, trong khi các sản phẩm khác của nền tảng này có thể giúp lưu trữ chứng chỉ giáo dục được bảo tồn không thể giả mạo hoặc hỗ trợ các nhà bán lẻ trong việc loại bỏ hàng giả.',
        SOL0: 'Solana là một dự án mã nguồn mạnh mẽ, sử dụng tính không cần phép của công nghệ blockchain để cung cấp giải pháp tài chính phi tập trung (DeFi). Mặc dù ý tưởng và công việc ban đầu của dự án bắt đầu từ năm 2017, nhưng Solana chính thức được ra mắt vào tháng 3 năm 2020 bởi Quỹ Solana có trụ sở tại Geneva, Thụy Sĩ.',

        SOL1: 'Để biết thêm thông tin về dự án này, xin vui lòng xem nghiên cứu chi tiết của chúng tôi về Solana.',

        SOL2: 'Giao thức Solana được thiết kế để thúc đẩy sự phát triển ứng dụng phi tập trung (DApp). Nó nhằm cải thiện khả năng mở rộng bằng cách kết hợp sự thống nhất của chứng minh lịch sử (PoH) với sự thống nhất căn cứ trên quyền lợi (PoS) của blockchain.',

        TRX0: 'Đồng TRON (TRX) được thành lập bởi Justin Sun thông qua Quỹ TRON vào năm 2017, với trụ sở chính tại Singapore. Vào năm 2017, lần phát hành đồng token đầu tiên (ICO) của Quỹ TRON đã tạo ra 100 tỷ đồng TRX và gọi vốn được 70 triệu USD.',

        TRX1: 'Giao thức TRON mô tả kiến trúc của toàn bộ hệ điều hành, dựa trên công nghệ blockchain, cho phép các nhà phát triển tạo ra hợp đồng thông minh và ứng dụng phi tập trung (DApps), tự do phát hành, sở hữu và lưu trữ dữ liệu cùng nội dung khác. Theo Quỹ TRON, hệ sinh thái xung quanh mạng lưới này cung cấp khả năng mở rộng quy mô lớn và tính nhất quán đáng tin cậy, có thể xử lý giao dịch một cách nhanh chóng qua tính toán với số lượng lớn.',

        TRX2: 'Ban đầu, đồng TRON được tạo dựa trên mạng Ethereum, sau đó vào năm 2018 chuyển sang mạng lưới riêng của mình. Chủ sở hữu đồng TRON dựa trên chuẩn ERC-20 đã đổi đồng thành đồng số TRX trên mạng TRON và đồng TRON trên Ethereum đã bị hủy.',
        Matic0: 'Polygon (trước đây là Matic Network) là một nền tảng phát triển cơ sở hạ tầng và mở rộng Ethereum đầu tiên có cấu trúc hợp lý và dễ sử dụng. Thành phần cốt lõi của nó là Polygon SDK, một khung linh hoạt và có tính module hóa, hỗ trợ xây dựng nhiều loại ứng dụng.',

        Matic1: 'Để biết thêm thông tin về dự án này, xin vui lòng xem nghiên cứu chi tiết của chúng tôi về Polygon Matic.',

        Matic2: 'Sử dụng Polygon, bạn có thể tạo ra chuỗi tích cực đủ tin cậy, chuỗi tích cực ZK, chuỗi độc lập hoặc bất kỳ cơ sở hạ tầng loại khác nào mà nhà phát triển cần.',

        LTC0: 'Litecoin (LTC) là một loại tiền điện tử được thiết kế để cung cấp chức năng thanh toán nhanh, an toàn và chi phí thấp thông qua việc sử dụng các tính năng của công nghệ blockchain.',

        LTC1: 'Tiền điện tử này được tạo ra dựa trên giao thức Bitcoin (BTC), nhưng khác biệt trong thuật toán hash, cố định cung cấp tối đa (Hard Cap), thời gian giao dịch khối và một số yếu tố khác. Thời gian tạo khối Litecoin chỉ mất 2,5 phút và phí giao dịch rất thấp, vì vậy nó rất phù hợp cho giao dịch nhỏ và thanh toán điểm bán hàng (point-of-sale).',

        LTC2: 'Litecoin được ra mắt vào ngày 7 tháng 10 năm 2011 bởi một người dùng mã nguồn mở trên GitHub, và chỉ sau 5 ngày, vào ngày 13 tháng 10 năm 2011, mạng lưới Litecoin chính thức được triển khai. Từ đó, việc sử dụng và chấp nhận Litecoin trong các cửa hàng đã tăng mạnh. Từ khi ra mắt, Litecoin đã duy trì vị trí trong top 10 tiền điện tử có vốn hóa thị trường.',

        LTC3: 'Tiền điện tử này được tạo ra bởi Charlie Lee, một cựu nhân viên Google, anh muốn biến Litecoin thành phiên bản nhỏ gọn của Bitcoin, do đó, anh đã phát triển nhiều tính năng giống với Bitcoin (ngoại trừ sự quan trọng và phổ biến nhỏ hơn).',

        AVAX0: 'Avalanche là một blockchain tầng 1 có thể hoạt động như một nền tảng cho các ứng dụng phi tập trung và các mạng blockchain tùy chỉnh. Nó là một trong những đối thủ của Ethereum, với mục tiêu thay thế Ethereum trở thành blockchain hợp đồng thông minh phổ biến nhất. Mục tiêu của Avalanche là thực hiện giao dịch lên đến 6.500 giao dịch mỗi giây mà không ảnh hưởng đến khả năng mở rộng.',

AVAX1: 'Điều này được thực hiện nhờ kiến trúc độc đáo của Avalanche. Mạng Avalanche bao gồm ba blockchain độc lập: X Chain, C Chain và P Chain. Mỗi chuỗi có mục tiêu khác nhau, khác với cách Bitcoin và Ethereum sử dụng (tức là để tất cả các nút xác minh tất cả các giao dịch). Thậm chí, blockchain của Avalanche sử dụng cơ chế đồng thuận khác nhau dựa trên việc sử dụng.',

AVAX2: 'Sau khi ra mắt mainnet vào năm 2020, Avalanche đã liên tục phát triển hệ sinh thái DApps và DeFi riêng của mình. Các dự án dựa trên Ethereum khác như SushiSwap và TrueUSD đã tích hợp với Avalanche. Ngoài ra, nền tảng này còn liên tục nỗ lực nâng cao khả năng tương tác giữa hệ sinh thái của mình và Ethereum, ví dụ như thông qua việc phát triển các bộ cầu nối.',

ETC0: 'Ethereum Classic (ETC) là một phiên bản cứng của Ethereum (ETH), được ra mắt vào tháng 7 năm 2016. Chức năng chính của nó là làm việc như một mạng lưới hợp đồng thông minh, có thể định chế và hỗ trợ các ứng dụng phi tập trung (DApps). Token gốc của nó là ETC.',

ETC1: 'Kể từ khi ra mắt, Ethereum Classic luôn tìm kiếm sự khác biệt so với Ethereum, và theo thời gian, lộ trình công nghệ của hai mạng này càng ngày càng khác nhau.',

ETC2: 'Sau một vụ hack lớn dẫn đến việc mất đi 3,6 triệu ETH, Ethereum Classic đã ưu tiên duy trì tính toàn vẹn của blockchain Ethereum hiện có.',

ARB0: '',

SUI0: 'Sui là một blockchain tầng 1 có độ trễ thấp và khả năng xử lý lớn. Khả năng thanh toán giao dịch ngay lập tức của Sui làm cho nó trở thành một blockchain phù hợp cho các ứng dụng trên chuỗi (ví dụ: DeFi và GameFi). Sui được viết bằng Rust, một ngôn ngữ lập trình tập trung vào việc thực thi giao dịch nhanh chóng và an toàn. Rust cũng là ngôn ngữ lập trình được sử dụng trong Solana, một blockchain tốc độ cao khác.',
SUI1: 'Sui tuyên bố mục tiêu của mình là: thông qua kiến trúc hợp đồng thông minh vượt trội so với các đối thủ blockchain như Ethereum, triển khai ứng dụng phi tập trung (DApp) để "dẫn dắt hàng tỷ người dùng tiếp theo đến Web 3". Blockchain sử dụng quá trình gọi là "đa luồng giao dịch" để đạt được mục tiêu này, cho phép xử lý giao dịch trong "giao thức đa luồng". Như Sui đã chỉ ra, việc mở rộng "đa luồng" này có thể cung cấp cách tổ chức dữ liệu tốt hơn trong cơ chế đồng thuận Chứng khoán chống Byzantine (PoS).',

DAI0: '',

LINK0: '',

UNI0: 'Uniswap là một giao thức giao dịch phi tập trung phổ biến, nổi tiếng với vai trò của nó trong việc thúc đẩy giao dịch tự động hóa của token trong tài chính phi tập trung (DeFi).',
UNI1: 'Là một ví dụ về người làm thị trường tự động (AMM), Uniswap được ra mắt vào tháng 11 năm 2018, nhưng do sự bùng nổ của DeFi và giao dịch liên quan đến token, nó đã thu hút được sự quan tâm lớn trong năm nay.',
UNI2: 'Uniswap nhằm mục tiêu duy trì tính tự động hóa của giao dịch token và mở cửa hoàn toàn cho bất kỳ ai nắm giữ token, đồng thời nâng cao hiệu suất giao dịch so với các sàn giao dịch truyền thống.',
UNI3: 'Uniswap giải quyết vấn đề về thanh khoản thông qua giải pháp tự động hóa, tránh những khó khăn mà các sàn giao dịch phi tập trung đầu tiên gặp phải, từ đó nâng cao hiệu suất.',
UNI4: 'Vào tháng 9 năm 2020, Uniswap đã đi một bước xa hơn bằng việc tạo ra token quản trị UNI riêng và trao quyền cho người dùng trong quá khứ của giao thức. Điều này không chỉ tăng khả năng sinh lời mà còn cho phép người dùng có khả năng tạo hình tương lai - điều này là một yếu tố hấp dẫn của thực thể phi tập trung.',

EOS0: '',

OP0: '',

    },
    wallet: {
        h1: "Ví giao dịch định lượng Web3",
        h2: "Mở khóa tiềm năng giao dịch không giới hạn và đạt được trải nghiệm giao dịch định lượng tuyệt vời.",
        h3: 'Dùng thử miễn phí',
        h4: "Đầu tư đa dạng",
        h5: "Bảo mật tài sản",
        h6: "Kinh nghiệm giao dịch nhanh và hiệu quả",
        h7: "Ưu điểm của việc định lượng ví lò phản ứng",
        h8: "Số lượng ví cung cấp trải nghiệm giao dịch an toàn, linh hoạt và được cá nhân hóa hơn",
        h9: "Hộ tống bảo vệ tài sản cá nhân",
        h10: "Reactor sử dụng tài khoản ví cá nhân cho các giao dịch và bạn có quyền kiểm soát cũng như bảo mật cao hơn so với các sàn giao dịch.",
        h11: "Số lượng tích hợp, liền mạch trên nhiều chuỗi khối",
        h12: "Reactor đạt được các giao dịch và chuyển giao tài sản liền mạch bằng cách tích hợp nhiều mạng chuỗi khối mà không bị hạn chế bởi một chuỗi duy nhất và có được nhiều cơ hội đầu tư hơn.",
        h13: "Tuyến đường tiết kiệm xăng thông minh",
        h14: "Reactor có thể tự động phân tích tình hình mạng hiện tại và chọn tuyến đường có phí gas thấp nhất để thực hiện giao dịch nhằm giảm chi phí giao dịch.",
        h15: "Hộ tống tài sản cá nhân an toàn",
        h16: "Reactor sử dụng tài khoản ví cá nhân cho các giao dịch và bạn có quyền kiểm soát cũng như bảo mật tốt hơn so với các sàn giao dịch.",
        h17: "Tạo ví HD chính của bạn và bắt đầu khám phá thế giới Web3",
        h18: "Ví HD chính là danh tính duy nhất của bạn và có thể được kết nối với nhiều ví không giam giữ",
        h19: "Thông qua ví HD chính, bạn có thể dễ dàng quản lý và kiểm soát nhiều ví không giam giữ mà không cần tạo và ghi nhớ các khóa cá nhân và mật khẩu khác nhau cho từng ví.",
        h20: "Ví HD chính cung cấp các chức năng thống kê chi tiết, cho phép bạn hiểu lịch sử giao dịch, phân phối tài sản và thu nhập của mình, đồng thời giúp bạn đưa ra quyết định đầu tư sáng suốt hơn.",
        h21: "Ví HD chính rất linh hoạt và có thể được cá nhân hóa theo nhu cầu và sở thích của bạn, cho phép bạn tùy chỉnh và quản lý tài sản kỹ thuật số của mình tốt hơn.",
        h22: "Chiến lược tùy chỉnh",
        h23: "Reactor có thể thực hiện các giao dịch tùy chỉnh theo nhu cầu và chiến lược của riêng bạn. Bạn có thể thực hiện các giao dịch định lượng theo chiến lược giao dịch và sở thích rủi ro của riêng mình mà không bị hạn chế bởi API trao đổi",
        h24: "Hỗ trợ mạnh mẽ cho môi trường đa chuỗi",
        h25: "Reactor có thể giao dịch trên các chuỗi khác nhau. Điều này mang đến cho bạn nhiều tùy chọn và tính linh hoạt hơn để giao dịch dựa trên điều kiện thị trường và phân bổ tài sản",
    },
}