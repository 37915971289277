<template>
  <div class="community">
    <div class="bg">
      <div class="box1">
        <div class="left">
          <div class="h1">{{ $t('community.h1') }}</div>
          <div class="h2">{{ $t('community.h2') }}</div>
        </div>
        <img src="../assets/community1.png" alt="">
      </div>
      <div class="box2">
        <div class="small small1">
          <div class="img"></div>
          <div class="right">
            <div class="name">{{ $t('community.h3') }}</div>
            <div class="hint">{{ $t('community.h4') }}</div>
          </div>
        </div>
        <div class="small small2">
          <div class="img"></div>
          <div class="right">
            <div class="name">{{ $t('community.h5') }}</div>
            <div class="hint">{{ $t('community.h6') }}</div>
          </div>
        </div>
        <div class="small small3">
          <div class="img"></div>
          <div class="right">
            <div class="name">{{ $t('community.h7') }}</div>
            <div class="hint">{{ $t('community.h6') }}</div>
          </div>
        </div>
        <div class="small small4">
          <div class="img"></div>
          <div class="right">
            <div class="name">{{ $t('community.h8') }}</div>
            <div class="hint">{{ $t('community.h6') }}</div>
          </div>
        </div>
        <div class="small small1">
          <div class="img"></div>
          <div class="right">
            <div class="name">{{ $t('community.h9') }}</div>
            <div class="hint">{{ $t('community.h6') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="box3">
        <div class="small">
          <img src="../assets/community2.png" alt="" v-animate="'animate__animated animate__lightSpeedInLeft'">
          <div class="info" v-animate="'animate__animated animate__lightSpeedInRight'">
            <div class="title">{{ $t('community.h10') }}</div>
            <div class="hint">{{ $t('community.h11') }}</div>
          </div>
        </div>
        <div class="small" v-animate="'animate__animated animate__lightSpeedInLeft'">
          <div class="info" v-animate="'animate__animated animate__lightSpeedInLeft'">
            <div class="title">{{ $t('community.h12') }}</div>
            <div class="hint">{{ $t('community.h13') }}</div>
          </div>
          <img v-animate="'animate__animated animate__lightSpeedInRight'" src="../assets/community3.png" alt="">
        </div>
        <div class="small">
          <img src="../assets/community4.png" alt="" v-animate="'animate__animated animate__lightSpeedInLeft'">
          <div class="info" v-animate="'animate__animated animate__lightSpeedInRight'">
            <div class="title">{{ $t('community.h14') }}</div>
            <div class="hint">{{ $t('community.h15') }}</div>
          </div>
        </div>
      </div>
      <div>
        <dow-view></dow-view>
      </div>
    </div>
  </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import 'animate.css';
import big from "big.js";
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
  components: { dowView },
  name: "community",
  data() {
    return {
      animationStatus: 0
    };
  },
  methods: {
    handleScroll(e) {
      this.animationStatus = 0
      if (this.getScrollTop() >= 300) {
        this.animationStatus = 1
      }
      if (this.getScrollTop() >= 400) {
        this.animationStatus = 2
      }
      if (this.getScrollTop() >= 500) {
        this.animationStatus = 3
      }

    },
    //获取当前滚动条的位置
    getScrollTop() {
      var scrollTop = 0
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop
      } else if (document.body) {
        scrollTop = document.body.scrollTop
      }
      return scrollTop
    },
  },
  mounted() {
    // 获取滚动容器的引用
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.community {

  // background-color: #f0f2f5;
  // border: 1px solid #f0f2f5;
  .bg {

    background: linear-gradient(180deg, #E5EFFD 0%, #ECF3FD 55%, #FFFFFF 100%);
    border-radius: 0px 0px 0px 0px;
    height: 539 * $defaultSize;

    .box1 {
      display: flex;
      width: 750 * $defaultSize;
      margin: auto;
      padding-top: 80 * $defaultSize;
      padding-bottom: 27 * $defaultSize;
      animation: tracking-in-expand-fwd-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

      .left {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .h1 {
          font-size: 40 * $defaultSize;
          font-family: Inter-Semi Bold, Inter;
          font-weight: normal;
          font-weight: 600;
          color: #333333;
          margin-bottom: 13 * $defaultSize;
        }

        .h2 {
          font-size: 16 * $defaultSize;
          font-family: Inter-Regular, Inter;
          font-weight: 400;
          color: #4F5559;
          line-height: 19px;
        }
      }

      img {
        width: 40%;
      }
    }

    .box2 {
      display: flex;
      flex-wrap: wrap;

      width: 740 * $defaultSize;
      margin: auto;

      background: #FFFFFF;
      box-shadow: 0px 0px 30px 0px rgba(201, 220, 246, 1);
      border-radius: 20px 20px 20px 20px;
      animation: tracking-in-expand-fwd-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      padding: 50 * $defaultSize;
      padding-bottom: 0;

      .small {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 46* $defaultSize;
        width: 33.3%;
        margin-bottom: 40 * $defaultSize;

        .right {
          .name {
            font-size: 16 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: 600;
            color: #333333;
            line-height: 16 * $defaultSize;
          }

          .hint {
            margin-top: 5 * $defaultSize;
            font-size: 11 * $defaultSize;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            color: #4F5559;
            line-height: 11 * $defaultSize;
          }
        }
      }

      .small1{
        .img {
          width: 46 * $defaultSize;
          height: 100%;
          margin-right: 10 * $defaultSize;
          background-image: url('../assets/shequ_icon_telegram_normal.png');
          background-size: 100%;
        }
      }
      
      .small1:hover{
        .img {
          background-image: url('../assets/community/icon_telegram_hover.svg');
        }
      }

      .small2{
        .img {
          width: 46 * $defaultSize;
          height: 100%;
          margin-right: 10 * $defaultSize;
          background-image: url('../assets/shequ_icon_twitter_normal.png');
          background-size: 100%;
        }
      }
      .small2:hover{
        .img {
          background-image: url('../assets/community/icon_twitter_normal.svg');
        }
      }

      .small3{
        .img {
          width: 46 * $defaultSize;
          height: 100%;
          margin-right: 10 * $defaultSize;
          background-image: url('../assets/community/home_icon_ins_normal.svg');
          background-size: 100%;
        }
      }
      .small3:hover{
        .img {
          background-image: url('../assets/community/icon_ins_hover.svg');
        }
      }

      .small4{
        .img {
          width: 46 * $defaultSize;
          height: 100%;
          margin-right: 10 * $defaultSize;
          background-image: url('../assets/shequ_icon_facebook_normal.png');
          background-size: 100%;
        }
      }
      .small4:hover{
        .img {
          background-image: url('../assets/community/icon_facebook_hover.svg');
        }
      }
      

      .small:hover {
        
        .right {
          .name {
            color: #1e4dd8;
          }
        }
      }
    }
  }

  .main {
    width: 750 * $defaultSize;
    margin: auto;

    .box3 {
      .small {
        display: flex;
        align-items: center;
        margin-bottom: 25 * $defaultSize;

        // animation: tracking-in-expand-fwd-right 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        img {
          width: 300 * $defaultSize;
        }

        .info {
          .title {
            font-size: 20 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: normal;
            color: #333333;
          }

          .hint {
            margin-top: 9 * $defaultSize;
            font-size: 13 * $defaultSize;
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            color: #4F5559;
          }
        }
      }
    }
  }
}

@keyframes tracking-in-expand-fwd-left {
  0% {
    -webkit-transform: translateX(50 * $defaultSize);
    transform: translateX(50 * $defaultSize);
    opacity: 0;
  }

  40% {
    opacity: 0.1;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes tracking-in-expand-fwd-right {
  0% {
    -webkit-transform: translateX(-50 * $defaultSize);
    transform: translateX(-50 * $defaultSize);
    opacity: 0;
  }

  40% {
    opacity: 0.1;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes tracking-in-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
    opacity: 0;
  }

  40% {
    opacity: 0.1;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
</style>
  