<template>
    <div class="copyRobot">

        <div class="main">
            <div class="box1">
                <div class="left">
                    <div class="top">
                        <div class="leftH1">
                            <span>{{ $t('policyBacktest.h1').substring(0, 2) }}</span>
                            <span style="color:#224FD9;">{{ $t('policyBacktest.h1').substring(2, 4) }}</span>
                        </div>
                        <div class="leftH2">{{ $t('policyBacktest.h2') }}</div>
                    </div>
                    <div class="btm" style="border-radius: 8px;" @click="asd">
                        <span>{{ $t('policyBacktest.h3') }}</span>
                        <span class="solidSpan">
                            <img src="../assets/policyBacktest/home_button_right_normal (2).svg" alt="">
                        </span>
                    </div>
                </div>
                <div class="right animate__animated animate__fadeInBottomLeft">
                    <img src="../assets/policyImg.svg" alt="">
                </div>
            </div>

            <div class="box3" v-animate="'animate__animated animate__fadeInLeft'">
                <div class="title">
                    <img class="right" src="../assets/Group 118.svg" alt="">
                    <div class="left">
                        <div class="h0">{{ $t('policyBacktest.h4') }}</div>
                        <div class="h1">{{ $t('policyBacktest.h5') }}</div>
                        <div class="h2">
                            {{ $t('policyBacktest.h6') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="box3" v-animate="'animate__animated animate__fadeInRight'">
                <div class="title">
                    <div class="left">
                        <div class="h0">{{ $t('policyBacktest.h7') }}</div>
                        <div class="h1">{{ $t('policyBacktest.h8') }}</div>
                        <div class="h2">
                            {{ $t('policyBacktest.h9') }}
                        </div>
                    </div>
                    <img class="right" src="../assets/Group 117.svg" alt="">
                </div>
            </div>
            <div class="box3" v-animate="'animate__animated animate__fadeInLeft'">
                <div class="title">
                    <img class="right" src="../assets/Group 113.svg" alt="">
                    <div class="left">
                        <div class="h0">{{ $t('policyBacktest.h10') }}</div>
                        <div class="h1">{{ $t('policyBacktest.h11') }}</div>
                        <div class="h2">
                            {{ $t('policyBacktest.h12') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="box3 " v-animate="'animate__animated animate__fadeInRight'">
                <div class="title">
                    <div class="left">
                        <div class="h0">{{ $t('policyBacktest.h13') }}</div>
                        <div class="h1">{{ $t('policyBacktest.h14') }}</div>
                        <div class="h2">
                            {{ $t('policyBacktest.h15') }}
                        </div>
                    </div>
                    <img class="right" src="../assets/Group 116.svg" alt="">
                </div>
            </div>

            <div class="box4">
                <div class="left">
                    <div class="top">
                        <div class="leftH1">{{ $t('policyBacktest.h16') }}</div>
                        <div class="leftH2">{{ $t('policyBacktest.h17') }}</div>
                    </div>
                    <div class="btm" @click="goStrategy">
                        <span>{{ $t('policyBacktest.h18') }}</span>
                        <span class="solidSpan">
                            <img src="../assets/policyBacktest/home_button_right_normal (1).svg" alt="">
                        </span>
                    </div>
                </div>
                <div class="right">

                </div>
            </div>
            <div class="box5">
                <div class="left">
                    <div class="h1">{{ $t('policyBacktest.h19') }}</div>
                    <div class="h2">{{ $t('policyBacktest.h20') }}</div>
                    <div class="row " v-animate="'animate__animated animate__fadeInLeft'">
                        <img src="../assets/Group 122.svg" alt="">
                        <div class="rowright">
                            <div class="h3">{{ $t('policyBacktest.h21') }}</div>
                            <div class="h4">{{ $t('policyBacktest.h22') }}</div>
                        </div>
                    </div>
                    <div class="row " v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.3s">
                        <img src="../assets/Group 121.svg" alt="">
                        <div class="rowright">
                            <div class="h3">{{ $t('policyBacktest.h23') }}</div>
                            <div class="h4">{{ $t('policyBacktest.h24') }}</div>
                        </div>
                    </div>
                    <div class="row" v-animate="'animate__animated animate__fadeInLeft'" style="animationDelay:0.6s">
                        <img src="../assets/Group 120.svg" alt="">
                        <div class="rowright">
                            <div class="h3">{{ $t('policyBacktest.h25') }}</div>
                            <div class="h4">{{ $t('policyBacktest.h26') }}</div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img src="../assets/policyImg2.svg" alt="">
                </div>
            </div>
            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "copyRobot",
    data() {
        return {
        };
    },
    methods: {
        asd() {
            window.open('https://support.rac.art/chan-pin/ce-lve-hui-ce/shen-me-shi-hui-ce-fang-an-zai-reactor-zhong-ru-he-shi-yong-hui-ce-gong-neng', '_blank')
        },
        goStrategy() {
            // if (this.$router.currentRoute.path === '/strategy') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/strategy",
            // });
            window.open('https://support.rac.art/chan-pin/ce-lve-hui-ce/ru-he-zai-reactor-zhong-liang-shen-ding-zhi-wo-de-hui-ce-fang-an-man-zu-ge-xing-hua-de-xu-qiu', '_blank')
        },
    },
    computed: {},
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}

.copyRobot {

    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;
    .box1 {
        padding-top: 60 * $defaultSize;
        display: flex;
        align-items: center;

        .left {
            width: 55%;

            .top {
                .leftH1 {
                    font-size: 40 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 700;
                    color: #000000;
                    margin-bottom: 10 * $defaultSize;
                }

                .leftH2 {
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                }
            }

            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 2 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                text-align: center;
                font-weight: 600;
                color: #FFFFFF;

                img {
                    width: 12 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .btm:hover {
                background: #1F41A7;

                .solidSpan {
                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }

        .right {
            width: 45%;

            img {
                width: 100%;
            }
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .box2 {
            .h1 {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 600;
                color: #333333;
                margin-top: 50 * $defaultSize;
            }

            .h2 {
                font-size: 14 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #333333;
                margin-top: 25 * $defaultSize;
            }
        }

        .box3 {
            margin: 88 * $defaultSize 0;

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    width: 48%;

                    .h0 {
                        font-size: 14 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: normal;
                        color: #224FD9;
                        margin-bottom: 10 * $defaultSize;
                    }

                    .h1 {
                        font-size: 24 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: 600;
                        color: #333333;
                        margin-bottom: 10 * $defaultSize;
                    }

                    .h2 {
                        display: flex;
                        align-items: center;
                        font-size: 13 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                        margin-bottom: 15 * $defaultSize;

                        img {
                            width: 15 * $defaultSize;
                        }
                    }
                }

                .right {
                    width: 48%;
                    width: 242 * $defaultSize;
                }
            }

            .big {
                display: flex;
                justify-content: space-between;

                .small {
                    width: 23%;
                    text-align: center;
                    border-radius: 20 * $defaultSize;
                    opacity: 1;
                    // border: 1px solid #CCCCCC;
                    padding: 25 * $defaultSize;

                    img {
                        width: 75 * $defaultSize;
                    }

                    .smallH1 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 19 * $defaultSize;
                    }

                    .smallH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }

        .box4 {
            background-image: url("../assets/Group 119.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            height: 318 * $defaultSize;

            display: flex;
            align-items: center;

            .left {
                width: 40%;
                margin-left: 50 * $defaultSize;

                .top {
                    .leftH1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: 700;
                        color: #fff;
                        margin-bottom: 10 * $defaultSize;
                    }

                    .leftH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #D8D8D8;
                    }
                }

                .btm {
                    display: inline-block;
                    padding: 10 * $defaultSize 16 * $defaultSize;
                    background: #E1A163;
                    border-radius: 5 * $defaultSize;
                    font-size: 14 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 600;
                    color: #030405;
                    margin-top: 25 * $defaultSize;
                    cursor: pointer;

                    img {
                        width: 12 * $defaultSize;
                        color: #FFFFFF;
                    }

                }

                .btm:hover {
                    background-color: #FFFFFF;

                    .solidSpan {
                        img {
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }
                }
            }

            .right {
                width: 45%;
            }
        }

        .box5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 88 * $defaultSize 0;

            .left {
                width: 50%;

                .h1 {
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 7 * $defaultSize;
                }

                .h2 {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 34 * $defaultSize;
                }

                .row {
                    display: flex;
                    align-items: start;

                    img {
                        width: 25 * $defaultSize;
                        margin-right: 16 * $defaultSize;
                    }

                    .rowright {
                        margin-bottom: 31 * $defaultSize;

                        .h3 {
                            font-size: 18 * $defaultSize;
                            font-family: Inter-Semi Bold, Inter;
                            font-weight: 600;
                            color: #333333;
                            margin-bottom: 3 * $defaultSize;
                        }

                        .h4 {
                            font-size: 14 * $defaultSize;
                            font-family: Inter-Regular, Inter;
                            font-weight: 400;
                            color: #4F5559;
                        }
                    }
                }
            }

            .right {
                width: 48%;

                img {
                    width: 100%;
                }
            }
        }
    }
}</style>
  
  