<template>
    <div class="ziyuan">
        <div class="z-banner">
            <div class="banner-text">
                <span>Reactor 资源下载中心</span>
                <span>Reactor品牌以其独特的存在感而著称，代表着高科技与独特的个性。我们的品牌定位于追求科技、创新和个性化的消费者群体，致力于为他们提供与众不同的产品和体验。</span>
            </div>
        </div>
        <div class="z-part1">
            <div class="part1-item">
                <img src="../../assets/ziyuan/Frame2.png" alt="">
                <span>科技</span>
                <span>Reactor代表了未来科技的前沿和高度，传达出品牌对技术创新的执着追求。以最新的技术和创新的方法开发产品，为消费者提供与众不同的科技体验。</span>
            </div>
            <div class="part1-item">
                <img src="../../assets/ziyuan/ziyuan2.svg" alt="">
                <span>创新</span>
                <span>Reactor的创新精神表现在不断寻求新的可能性。我们鼓励投资者敢于尝试新事物，勇于突破传统界限。这种创新精神在产品的设计和功能上得以彰显。</span>
            </div>
            <div class="part1-item">
                <img src="../../assets/ziyuan/ziyuan3.svg" alt="">
                <span>个性化</span>
                <span>Reactor品牌致力于提供个性化的产品和体验。我们理解每个投资者都是独一无二的，因此提供了多样化的选择，允许根据用户的需求和偏好来定制策略。</span>
            </div>
        </div>
        <span
            class="z-text">Reactor品牌通过强调科技感、创新精神和个性化定制，创造了一个兼具现代性和个性化的品牌形象。这种形象吸引了那些追求独特体验、钟爱科技和勇于尝试创新的消费者，使他们能够在品牌下找到真正属于自己的独特存在感。</span>
        <div class="z-part2">
            <span class="part2-title">资源下载中心</span>
            <div class="part2-item1">
                <div>
                    <div class="item1-left">
                        <span class="title">标志</span>
                        <span>通过在您的网站上添加我们的Reactor徽章来与您的用户建立信任。</span>
                        <div class="left-items1">
                            <div class="left-item1">
                                <div>
                                    <img src="../../assets/ziyuan/Group 327.png" alt="">
                                </div>
                                <div>下载LOGO
                                    <DownloadOutlined />
                                </div>
                            </div>
                            <div class="left-item1">
                                <div>
                                    <img src="../../assets/ziyuan/Group 332.png" alt="">
                                </div>
                                <div>下载LOGO
                                    <DownloadOutlined />
                                </div>
                            </div>
                            <div class="left-item1">
                                <div>
                                    <img src="../../assets/ziyuan/Group 334.png" alt="">
                                </div>
                                <div>下载LOGO
                                    <DownloadOutlined />
                                </div>
                            </div>
                            <div class="left-item1">
                                <div>
                                    <img src="../../assets/ziyuan/Group 334-4.png" alt="">
                                </div>
                                <div>下载LOGO
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                        <span class="title">标识</span>
                        <div class="left-items2">
                            <div class="left-item2">
                                <div>
                                    <img src="../../assets/ziyuan/home_logo_reactor11.png" alt="">
                                </div>
                                <div>下载
                                    <DownloadOutlined />
                                </div>
                            </div>
                            <div class="left-item2">
                                <div>
                                    <img src="../../assets/ziyuan/home_logo_reactor12.png" alt="">
                                </div>
                                <div>下载
                                    <DownloadOutlined />
                                </div>
                            </div>
                            <div class="left-item2">
                                <div>
                                    <img src="../../assets/ziyuan/home_logo_reactor13.png" alt="">
                                </div>
                                <div>下载
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item1-right">
                        <span class="title">徽章</span>
                        <span>通过在您的网站上添加我们的Reactor徽章来与您的用户建立信任。</span>
                        <div class="right-item">
                            <img src="../../assets/ziyuan/Group 366.png" alt="">
                            <div>
                                <div>
                                    <span>var view = UIView()</span>
                                    <span>view.frame = CGRect(x: 0, y: 0, width: 141, height:</span>
                                    <span>view.layer.backgroundColor = UIColor(red: 0, green:</span>
                                </div>
                                <span>复制</span>
                            </div>
                        </div>
                        <div class="right-item">
                            <img src="../../assets/ziyuan/Group 330.png" alt="">
                            <div>
                                <div>
                                    <span>var view = UIView()</span>
                                    <span>view.frame = CGRect(x: 0, y: 0, width: 141, height:</span>
                                    <span>view.layer.backgroundColor = UIColor(red: 0, green:</span>
                                </div>
                                <span>复制</span>
                            </div>
                        </div>
                        <div class="right-item">
                            <img src="../../assets/ziyuan/Group 372.png" alt="">
                            <div>
                                <div>
                                    <span>var view = UIView()</span>
                                    <span>view.frame = CGRect(x: 0, y: 0, width: 141, height:</span>
                                    <span>view.layer.backgroundColor = UIColor(red: 0, green:</span>
                                </div>
                                <span>复制</span>
                            </div>
                        </div>
                        <div class="right-item">
                            <img src="../../assets/ziyuan/Group 363.png" alt="">
                            <div>
                                <div>
                                    <span>var view = UIView()</span>
                                    <span>view.frame = CGRect(x: 0, y: 0, width: 141, height:</span>
                                    <span>view.layer.backgroundColor = UIColor(red: 0, ...</span>
                                </div>
                                <span>复制</span>
                            </div>
                        </div>
                    </div>
                </div>
                <span>您可以将Reactor的徽章添加到您的网站，以建立用户的信任。根据站点的配色方案，您可以选择添加浅色或深色的Reactor徽章。</span>
            </div>
            <div class="part2-item2">
                <span>Banner</span>
                <div class="items">
                    <div class="item item2" v-for="(item, index) in 4" :key="index">
                        <div> </div>
                        <span>Banner500*280
                            <DownloadOutlined />
                        </span>
                    </div>
                </div>
            </div>
            <div class="part2-item3">
                <span>海报</span>
                <div class="items">
                    <div class="item item3" v-for="(item, index) in 4" :key="index">
                        <div> </div>
                        <span>海报750*1334
                            <DownloadOutlined />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DownloadOutlined } from 'ant-design-vue'
export default {
    components: {
        DownloadOutlined
    },
};
</script>

<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.ziyuan {
    margin-top: 50 * $defaultSize;
    // margin-top: 0px !important;

    .z-banner {
        height: 300 * $defaultSize;
        background: url('../../assets/ziyuan/物料页 1.png') no-repeat center;
        background-size: 100% 100%;
        padding-top: 85 * $defaultSize;

        .banner-text {
            width: 750 * $defaultSize;
            margin: auto;

            >:first-child {
                color: #224FD9;
                font-size: 28 * $defaultSize;
                font-weight: bold;
            }

            >:last-child {
                display: block;
                max-width: 320 * $defaultSize;
                color: #4F5559;
                font-size: 15 * $defaultSize;
                margin-top: 12 * $defaultSize;
            }
        }
    }

    .z-part1 {
        width: 750 * $defaultSize;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 30 * $defaultSize;

        .part1-item {
            cursor: pointer;
            width: 31.5%;
            text-align: center;
            background: #F9F9F9;
            padding: 25 * $defaultSize 15 * $defaultSize;
            border-radius: 15 * $defaultSize;

            img {
                width: 45 * $defaultSize;
                height: 45 * $defaultSize;
                margin-top: 40 * $defaultSize;
            }

            >:nth-child(2) {
                // color: #999999;
                color: #224FD9;
                font-size: 20 * $defaultSize;
                font-weight: bold;
                margin-top: 40 * $defaultSize;
                display: block;
            }

            >:last-child {
                display: block;
                text-align: left !important;
                color: #999999;
                font-size: 13 * $defaultSize;
                margin-top: 15 * $defaultSize;
            }
        }

        >:first-child {
            background: url('../../assets/ziyuan/Rectangle 156.png') no-repeat center;
            background-size: 100% 100%;

            >:nth-child(2) {
                color: #224FD9;
            }

            >:last-child {
                color: #666666;
            }
        }

        >:nth-child(2) {
            background: url('../../assets/ziyuan/Rectangle 156.png') no-repeat center;
            background-size: 100% 100%;
            >:last-child {
                color: #666666;
            }
        }

        >:nth-child(3) {
            background: url('../../assets/ziyuan/Rectangle 156.png') no-repeat center;
            background-size: 100% 100%;
            >:last-child {
                color: #666666;
            }
        }
    }

    .z-text {
        color: #224FD9;
        display: block;
        text-align: center;
        width: 750 * $defaultSize;
        margin: auto;
        font-size: 13 * $defaultSize;
        margin-top: 40 * $defaultSize;
    }

    .z-part2 {
        width: 750 * $defaultSize;
        margin: auto;

        .part2-title {
            display: block;
            text-align: center;
            font-weight: bold;
            font-size: 20 * $defaultSize;
            margin-top: 60 * $defaultSize;
        }

        .part2-item1 {
            background: #F5F5F5;
            border-radius: 15 * $defaultSize;
            margin-top: 30 * $defaultSize;
            padding: 30 * $defaultSize;

            >div {
                display: flex;
                justify-content: space-between;

                >div {
                    background: #FFFFFF;
                    border-radius: 15 * $defaultSize;
                    padding: 15 * $defaultSize;

                    >span {
                        display: block;
                    }

                    .title {
                        font-size: 15 * $defaultSize;
                        font-weight: bold;
                    }

                    >:nth-child(2) {
                        font-size: 12 * $defaultSize;
                        margin-top: 8 * $defaultSize;
                    }
                }
            }

            .item1-left {
                width: 50%;

                .left-items1 {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-top: 20 * $defaultSize;

                    .left-item1 {
                        width: 47%;
                        text-align: center;
                        margin-bottom: 20 * $defaultSize;

                        >:first-child {
                            height: 80 * $defaultSize;
                            background: #E4E9EC;
                            border-radius: 10 * $defaultSize;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                height: auto;
                            }
                        }

                        >:last-child {
                            color: #224FD9;
                            font-size: 10 * $defaultSize;
                            margin-top: 10 * $defaultSize;
                        }
                    }

                    >:first-child,
                    >:nth-child(2) {
                        img {
                            width: 80%;
                        }
                    }

                    >:nth-child(3),
                    >:last-child {
                        img {
                            width: 50%;
                        }
                    }
                }

                .left-items2 {
                    display: flex;
                    justify-content: space-between;

                    .left-item2 {
                        width: 25%;
                        margin-top: 20 * $defaultSize;
                        text-align: center;

                        >:first-child {
                            height: 80 * $defaultSize;
                            background: #E4E9EC;
                            border-radius: 10 * $defaultSize;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 38 * $defaultSize;
                                height: 38 * $defaultSize;
                            }
                        }

                        >:last-child {
                            color: #224FD9;
                            font-size: 10 * $defaultSize;
                            margin-top: 10 * $defaultSize;
                        }
                    }
                }
            }

            .item1-right {
                width: 48%;

                .right-item {
                    position: relative;
                    margin-top: 40 * $defaultSize;

                    img {
                        width: 40%;
                        position: absolute;
                        top: -15 * $defaultSize;
                    }

                    >div {
                        background: #F6F6F6;
                        border-radius: 10 * $defaultSize;
                        padding: 20 * $defaultSize 20 * $defaultSize 10 * $defaultSize 20 * $defaultSize;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        >:first-child {
                            width: 90%;

                            span {
                                display: block;
                                font-size: 10 * $defaultSize;
                                color: #999999;
                            }
                        }

                        >:last-child {
                            font-size: 10 * $defaultSize;
                            color: #224FD9;
                        }
                    }
                }
            }

            >span {
                display: block;
                color: #666666;
                font-size: 12 * $defaultSize;
                margin-top: 15 * $defaultSize;
            }
        }

        .part2-item2,
        .part2-item3 {
            background: #F5F5F5;
            padding: 20 * $defaultSize;
            border-radius: 15 * $defaultSize;
            margin-top: 40 * $defaultSize;

            >span {
                font-size: 12 * $defaultSize;
                color: #333333;
            }

            .items {
                display: flex;
                justify-content: space-between;
                margin-top: 20 * $defaultSize;

                .item {
                    text-align: center;
                    width: 23%;

                    div {
                        background: #A5A5A5;
                        border-radius: 10 * $defaultSize;
                    }

                    span {
                        font-size: 10 * $defaultSize;
                        color: #224FD9;
                        display: block;
                        margin-top: 10 * $defaultSize;
                    }
                }
            }
        }

        .part2-item2 .item2 div {
            height: 100 * $defaultSize;
        }

        .part2-item3 .item3 div {
            height: 300 * $defaultSize;
        }
    }
}
</style>