<template>
    <div class="faq">
        <div class="box1">
            <div class="h1">{{ $t('faq.h1') }}</div>
            <div class="h2">{{ $t('faq.h2') }}</div>
            <div class="elInput">
                <el-input placeholder="搜索" prefix-icon="el-icon-search" v-model="input2">
                </el-input>
            </div>
        </div>
        <div class="main">

            <!-- <iframe src="https://www.74dns.com/style/info/shownews.asp?id=56&wd=&eqid=9a29098a0013cc91000000066468ef18" width="100%" height="600" frameborder="0"></iframe> -->
            <iframe src="https://support.rac.art/chan-pin-gong-gao/gong-gao-zhuan-qu" width="100%" height="700" frameborder="0"></iframe>


            <!-- <div class="left">
                <el-row class="tac">
                        <el-menu
                        default-active="2"
                        class="el-menu-vertical-demo"
                        @open="handleOpen"
                        @close="handleClose">
                        <el-submenu index="1">
                            <template slot="title">
                                <img class="icon" src="../assets/Group 44.png" alt="">
                                <span>{{$t('faq.h3')}}</span>
                            </template>  
                            <el-menu-item index="1-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="1-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>
                        <el-submenu index="2">
                            <template slot="title">
                                <img class="icon" src="../assets/icon_demo11.png" alt="">
                                <span>{{$t('faq.h6')}}</span>
                            </template>  
                            <el-menu-item index="2-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="2-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>

                        <el-submenu index="3">
                            <template slot="title">
                                <img class="icon" src="../assets/icon_demo (1).png" alt="">
                                <span>{{$t('faq.h7')}}</span>
                            </template>  
                            <el-menu-item index="3-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="3-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>

                        <el-submenu index="4">
                            <template slot="title">
                                <img class="icon" src="../assets/icon_demo (2).png" alt="">
                                <span>{{$t('faq.h8')}}</span>
                            </template>  
                            <el-menu-item index="4-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="4-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>

                        <el-submenu index="5">
                            <template slot="title">
                                <img class="icon" src="../assets/icon_demo (3).png" alt="">
                                <span>{{$t('faq.h9')}}</span>
                            </template>  
                            <el-menu-item index="5-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="5-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>

                        <el-submenu index="6">
                            <template slot="title">
                                <img class="icon" src="../assets/icon_demo (4).png" alt="">
                                <span>{{$t('faq.h10')}}</span>
                            </template>  
                            <el-menu-item index="6-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="6-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>

                        <el-submenu index="7">
                            <template slot="title">
                                <img class="icon" src="../assets/icon_demo (5).png" alt="">
                                <span>{{$t('faq.h11')}}</span>
                            </template>  
                            <el-menu-item index="7-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="7-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>

                        <el-submenu index="8">
                            <template slot="title">
                                <img class="icon" src="../assets/copy-trading-school 1.png" alt="">
                                <span>{{$t('faq.h12')}}</span>
                            </template>  
                            <el-menu-item index="8-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="8-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>
                        
                        <el-submenu index="9">
                            <template slot="title">
                                <img class="icon" src="../assets/other-derivative 1.png" alt="">
                                <span>{{$t('faq.h13')}}</span>
                            </template>  
                            <el-menu-item index="9-1">{{$t('faq.h4')}}</el-menu-item>
                            <el-menu-item index="9-2">{{$t('faq.h5')}}</el-menu-item>
                        </el-submenu>
                        </el-menu>
                </el-row>
            </div>
            <div class="right">
                <img src="../assets/Group 45.png" alt="">
                <div class="h1">{{$t('faq.h14')}}</div>
                <div class="h2">{{$t('faq.h15')}}</div>
            </div> -->
        </div>
        <div class="box3">
            <div class="title">{{ $t('faq.h16') }}</div>
            <div class="body">
                <div class="small">· {{ $t('faq.h17') }}</div>
                <div class="small">· {{ $t('faq.h18') }}</div>
                <div class="small">· {{ $t('faq.h19') }}</div>
                <div class="small">· {{ $t('faq.h20') }}</div>
                <div class="small">· {{ $t('faq.h21') }}</div>
                <div class="small">· {{ $t('faq.h22') }}</div>
                <div class="small">· {{ $t('faq.h23') }}</div>
                <div class="small">· {{ $t('faq.h24') }}</div>
            </div>
        </div>
        <div>
            <help-view></help-view>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import helpView from './simViews/helpView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { helpView },
    name: "faq",
    data() {
        return {
            input2: undefined
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    computed: {},
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.faq {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        background-image: url("../assets/helpCenterBgi.png");
        background-size: 100%;
        background-repeat: no-repeat;
        height: 399 * $defaultSize;
        text-align: center;
        padding-top: 88 * $defaultSize;

        .h1 {
            text-align: center;
            font-size: 40 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: normal;
            color: #333333;
            margin-bottom: 15 * $defaultSize;
        }

        .h2 {
            text-align: center;
            font-size: 20 * $defaultSize;
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            color: #4F5559;
            margin-bottom: 50 * $defaultSize;
        }

        .elInput {
            width: 570 * $defaultSize;
            margin: auto;
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;
        display: flex;

        .left {
            width: 30%;

            .icon {
                width: 25 * $defaultSize;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: 70%;

            img {
                width: 100%;
            }

            .h1 {
                font-size: 14 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 600;
                color: #333333;
                margin: 25 * $defaultSize 0;
            }

            .h2 {
                font-size: 14 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: normal;
                color: #4F5559;
            }
        }

    }

    .box3 {
        width: 750 * $defaultSize;
        margin: auto;

        .title {
            font-size: 20 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: normal;
            color: #000000;
            margin-top: 88 * $defaultSize;
            margin-bottom: 25 * $defaultSize;
        }

        .body {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .small {
                cursor: pointer;
                width: 50%;
                font-size: 14 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #4F5559;
                margin-bottom: 25 * $defaultSize;
            }

            .small:hover {
                color: #333333;
            }
        }
    }
}

.el-menu-item.is-active {
    color: #1E4DD8;
}

.is-opened /deep/ .el-submenu__title {
    color: #1E4DD8;
}

.is-opened /deep/ .el-submenu__title i {
    color: #1E4DD8;
}
</style>

