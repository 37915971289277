<template>
    <div class="signal">
        <div class="box1">
            <div class="main animate__animated animate__fadeInDown">
                <div class="left">
                    <div class="top">
                        <div class="leftH1"> {{ $t('business.h1') }} </div>
                        <div class="leftH2"> {{ $t('business.h2') }} </div>
                    </div>
                    <div class="btm " @click="goFaq">
                        <span class="textSpan">
                            {{ $t('business.h3') }}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box3">
                <div class="top">
                    <div class="small animate__animated animate__fadeInLeft">
                        <img src="../assets/business/2.png" alt="">
                        <div class="smallH1">{{ $t('business.h4') }}</div>
                        <div class="smallH2">{{ $t('business.h5') }}</div>
                    </div>
                    <div class="small animate__animated animate__fadeInRight">
                        <img src="../assets/business/3.png" alt="">
                        <div class="smallH1">{{ $t('business.h6') }}</div>
                        <div class="smallH2">{{ $t('business.h7') }}</div>
                    </div>
                </div>
                <div class="bom animate__animated animate__fadeInDown">
                    <img src="../assets/business/4.png" alt="">
                    <div class="smallH1">{{ $t('business.h8') }}</div>
                    <div class="smallH2">{{ $t('business.h9') }}</div>
                </div>
            </div>

            <div>
                <contact-view></contact-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import { mapGetters } from "vuex"
import contactView from './simViews/contactView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { contactView },
    name: "signal",
    data() {
        return {
        };
    },
    methods: {
        goFaq() {
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });-
            window.open('https://tianyi.art/contact/inquiry', '_blank')
        },
    },
    computed: {
    },
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.textAnimation {
    display: block;
    animation: tracking-in-expand-fwd-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none {
    animation: none;
    animation-play-state: paused;
    animation-fill-mode: forwards;
}
@keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}

@keyframes tracking-in-expand-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        opacity: 0;
    }

    40% {
        opacity: 0.1;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

.signal {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        padding-top: 10 * $defaultSize;
        display: flex;
        height: 366 * $defaultSize;
        background: url("../assets/business/1.png") no-repeat;
        background-size: 100%;
        margin-top: 120px;

        .left {
            width: 60%;

            .top {
                .leftH1 {
                    font-size: 37 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 15 * $defaultSize;
                }

                .leftH2 {
                    font-size: 18 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 400;
                    color: #4F5559;
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #03A76A;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                font-weight: 600;
                color: #FFFFFF;

                .textSpan {
                    margin-left: 10 * $defaultSize;
                }

                img {
                    width: 13 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .btm:hover {
                background: #3A8459;

                .solidSpan {
                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;;
                    }
                }
            }
        }

        .right {
            width: 60%;

            img {
                width: 100%;
            }
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;
        margin-top: 30 * $defaultSize;

        .box2 {
            display: flex;

            .left {
                width: 50%;

                .h1 {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 600;
                    color: #224FD9;
                    margin-bottom: 15 * $defaultSize;
                }

                .h2 {
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 25 * $defaultSize;
                }

                .h5 {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Medium, Medium;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 25 * $defaultSize;
                }

                .h3 {
                    font-size: 12 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    margin-bottom: 15 * $defaultSize;

                    .h3-top {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        border-bottom: 1px solid #E8EBEF;
                        padding: 10 * $defaultSize 0;

                        .h3-top2 {
                            font-size: 20 * $defaultSize;
                            font-family: Inter-Bold, Bold;
                            font-weight: 600;
                            color: #333333;
                            margin-right: 10 * $defaultSize;
                        }
                    }

                    .h3-bom {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 10 * $defaultSize 0;

                        .h3-bom2 {
                            width: 25%;
                            font-size: 20 * $defaultSize;
                            font-family: Inter-Bold, Bold;
                            font-weight: 600;
                            color: #333333;
                            margin-right: 10 * $defaultSize;
                        }
                    }

                    img {
                        width: 30 * $defaultSize;
                        margin-right: 10 * $defaultSize;
                    }
                }

                .h4 {
                    cursor: pointer;
                    margin-top: 50* $defaultSize;
                    color: #333333;
                    font-size: 15 * $defaultSize;
                    font-weight: 600;
                    display: flex;

                    .solidSpan {
                        margin-left: 5* $defaultSize;
                        display: flex;
                        align-items: center;
                        /* 将子元素垂直居中对齐 */
                    }
                }

                .h4:hover {
                    color: #224FD9;
                }
            }

            .right {
                margin-left: 80 * $defaultSize;
                width: 45%;
            }
        }

        .box3 {
            text-align: center;

            .top {
                display: flex;

                .small {
                    width: 50%;
                    text-align: center;
                    border-radius: 20 * $defaultSize;
                    opacity: 1;
                    // border: 1px solid #CCCCCC;
                    margin-bottom: 25 * $defaultSize;

                    &:nth-of-type(1) {
                        margin-right: 20 * $defaultSize;
                    }

                    img {
                        width: 75 * $defaultSize;
                    }

                    .smallH1 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 19 * $defaultSize;
                    }

                    .smallH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                        text-align: left;
                    }
                }
            }

            .bom {
                width: 100%;

                img {
                    width: 75 * $defaultSize;
                }

                .smallH1 {
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Medium, Inter;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 19 * $defaultSize;
                }

                .smallH2 {
                    font-size: 11 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    text-align: left;
                }
            }
        }
    }
}
</style>
  
  