<template>
    <div class="chainData">

        <div class="box1">
            <div class="topMain">
                <img class="topImg" src="../assets/chainData/top1.svg">
                <div class="left fadeInDown">
                    <div class="top">
                        <div class="leftH1">{{ $t('chainData.h1') }}</div>
                        <div class="leftH2">{{ $t('chainData.h2') }}</div>
                        <div class="leftH3">{{ $t('chainData.h3') }}</div>
                        <div class="leftH3">{{ $t('chainData.h4') }}</div>
                    </div>
                    <div class="btm" @click="goFaq">
                        <span>
                            {{ $t('chainData.h5') }}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                </div>
                <div class="bomDiv">
                    <div class="smallH1">
                        {{ $t('chainData.h6') }}
                    </div>
                    <div class="smallH2">
                        {{ $t('chainData.h7') }}
                    </div>
                    <div class="smallH3">
                        <div class="smallH3-left animate__animated animate__fadeInLeft">
                            <div class="small">
                                <img src="../assets/chainData/tab1.svg">
                                <span> {{ $t('chainData.h8') }} </span>
                            </div>
                            <div class="small">
                                <img src="../assets/chainData/tab1.svg">
                                <span> {{ $t('chainData.h9') }} </span>
                            </div>
                            <div class="small">
                                <img src="../assets/chainData/tab1.svg">
                                <span> {{ $t('chainData.h10') }} </span>
                            </div>
                        </div>
                        <div class="smallH3-right animate__animated animate__fadeInRight">
                            <div class="small">
                                <img src="../assets/chainData/tab1.svg">
                                <span> {{ $t('chainData.h11') }} </span>
                            </div>
                            <div class="small">
                                <img src="../assets/chainData/tab1.svg">
                                <span> {{ $t('chainData.h12') }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="box3">
                <div class="title">{{ $t('chainData.h13') }}</div>
                <div class="hint">{{ $t('chainData.h14') }}</div>
                <div class="big" v-animate="'animate__animated animate__fadeIn'">
                    <div class="small">
                        <div class="circular">
                            <div>1</div>
                        </div>
                        <div class="smallH1">{{ $t('chainData.h15') }}</div>
                        <div class="smallH2">{{ $t('chainData.h18') }}</div>
                    </div>
                    <div class="small">
                        <div class="circular">
                            <div>2</div>
                        </div>
                        <div class="smallH1">{{ $t('chainData.h16') }}</div>
                        <div class="smallH2">{{ $t('chainData.h19') }}</div>
                    </div>
                    <div class="small">
                        <div class="circular">
                            <div>3</div>
                        </div>
                        <div class="smallH1">{{ $t('chainData.h17') }}</div>
                        <div class="smallH2">{{ $t('chainData.h20') }}</div>
                    </div>
                </div>
            </div>
            <div class="box5">
                <div class="title">{{ $t('chainData.h21') }}</div>
                <div class="hint">{{ $t('chainData.h22') }}</div>
                <div class="big">
                    <div class="big1" v-animate="'animate__animated animate__slideInUp'">
                        <div class="small" v-for="(data, index) in [1, 2, 3]" :key="index" :style="'animationDelay:' + 0.1 + 's'">
                            <div class="circular">
                                <img :src="require(`../assets/chainData/${data}.svg`)">
                            </div>
                            <div class="smallH1">{{ $t('chainData.h' + (22 + data)) }}</div>
                            <div class="smallH2">{{ $t('chainData.h' + (28 + data)) }}</div>
                        </div>
                    </div>
                    <div class="big2" v-animate="'animate__animated animate__slideInUp'" :style="'animationDelay:' + 0.5 + 's'">
                        <div class="small" v-for="(data, index) in [4, 5, 6]" :key="index" :style="'animationDelay:' + 0.1 + 's'">
                            <div class="circular">
                                <img :src="require(`../assets/chainData/${data}.svg`)">
                            </div>
                            <div class="smallH1">{{ $t('chainData.h' + (22 + data)) }}</div>
                            <div class="smallH2">{{ $t('chainData.h' + (28 + data)) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box4" v-animate="'animate__animated animate__fadeIn'">
                <img src="../assets/Group 69.svg" alt="">
            </div>
            <div class="box6">
                <div class="title">{{ $t('chainData.h35') }}</div>
                <div class="hint">{{ $t('chainData.h36') }}</div>
                <div class="imgDiv " v-animate="'animate__animated animate__fadeIn'">
                    <div class="img"></div>
                </div>
            </div>
            <div class="box7">
                <div class="big">
                    <div class="small" v-for="(data, index) in [7, 8, 9]" :key="index">
                        <div class="circular">
                            <img :src="require(`../assets/chainData/${data}.svg`)">
                        </div>
                        <div class="smallH1">{{ $t('chainData.h' + (37 + index)) }}</div>
                        <div class="smallH2">{{ $t('chainData.h' + (40 + index)) }}</div>
                    </div>
                </div>
            </div>

            <div class="box8">
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "chainData",
    data() {
        return {
        };
    },
    methods: {
        goFaq() {
            //     if (this.$router.currentRoute.path === '/faq') {
            //         // 当前路径已经是目标路径，不进行重复导航
            //         return
            //     }
            //     this.$router.push({
            //         path: "/faq",
            //     });
            window.open('https://support.rac.art/chan-pin/k-xian-yu-lian-shang-shu-ju/reactor-ru-he-rong-hekxian-yu-lian-shang-shu-ju-bang-zhu-wo-dong-cha-shi-chang-qu-shi', '_blank')
        },
    },
    computed: {},
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.fadeInDown {
    animation: tracking-in-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes tracking-in-top {
    0% {
        transform: translateY(-50 * $defaultSize);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes tracking-in-bom {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-5 * $defaultSize);
    }
}

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}

.chainData {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        // display: flex;
        height: 607 * $defaultSize;

        .topMain {
            height: 707 * $defaultSize;

            .topImg {
                z-index: -1;
                width: 100%;
                /* 图片宽度占满容器 */
                height: auto;
                /* 图片高度自适应 */
                position: absolute;
                /* 相对于容器绝对定位 */
                top: 0;
                /* 图片相对于容器顶部对齐 */
                left: 0;
                /* 图片相对于容器左侧对齐 */
            }

            text-align: center;

            .left {
                padding-top: 88 * $defaultSize;
                width: 100%;

                .top {
                    .leftH1 {
                        font-size: 10 * $defaultSize;
                        font-family: Inter-Medium, Medium;
                        // font-weight: 700;
                        color: #224FD9;
                    }

                    .leftH2 {
                        font-size: 40 * $defaultSize;
                        font-family: Inter-Semi Bold, Semi Bold;
                        font-weight: 600;
                        color: #333333;
                    }

                    .leftH3 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Medium, Medium;
                        font-weight: 300;
                        color: #4F5559;
                    }
                }

                .btm {
                    cursor: pointer;
                    display: inline-block;
                    width: 100 * $defaultSize;
                    height: 35 * $defaultSize;
                    background-color: #224FD9;
                    line-height: 35 * $defaultSize;
                    border-radius: 5 * $defaultSize;
                    font-size: 15 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    margin-top: 25 * $defaultSize;
                    font-weight: 600;
                    color: #FFFFFF;

                    img {
                        width: 13 * $defaultSize;
                        color: #FFFFFF;
                    }
                }

                .btm:hover {
                    background: #1F41A7;

                    .solidSpan {
                        img {
                            animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                        }
                    }
                }
            }

            .bomDiv {
                display: inline-block;
                margin-top: 68 * $defaultSize;
                // width: 600 * $defaultSize;
                height: 310 * $defaultSize;
                background: url('../assets/chainData/Rectangle 216.svg');
                background-size: 100%;
                border-radius: 40px;

                .smallH1 {
                    margin-top: 30 * $defaultSize;
                    font-size: 20 * $defaultSize;
                    font-family: Inter-Semi Bold, Semi Bold;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .smallH2 {
                    margin-top: 5 * $defaultSize;
                    font-size: 14 * $defaultSize;
                    font-family: Inter-Regular, Regular;
                    font-weight: 200;
                    color: #FFFFFF;
                }

                .smallH3 {
                    width: 100%;
                    text-align: left;
                    margin-top: 10 * $defaultSize;
                    font-size: 12 * $defaultSize;
                    font-family: Inter-Regular, Regular;
                    font-weight: 200;
                    color: #FFFFFF;

                    .smallH3-left {
                        height: 100%;
                        float: left;
                        width: 50%;

                        .small {
                            width: 100%;
                            padding: 15 * $defaultSize 0 15 * $defaultSize 50 * $defaultSize;

                            span {
                                margin-left: 10 * $defaultSize;
                                display: inline-block;
                                width: 70%;
                            }

                            img {
                                width: 23 * $defaultSize;
                            }
                        }
                    }

                    .smallH3-right {
                        height: 100%;
                        float: right;
                        width: 50%;

                        .small {
                            width: 100%;
                            padding: 15 * $defaultSize 0 15 * $defaultSize 50 * $defaultSize;

                            span {
                                margin-left: 10 * $defaultSize;
                                display: inline-block;
                                width: 70%;
                            }

                            img {
                                width: 23 * $defaultSize;
                            }
                        }
                    }
                }
            }
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .box3 {
            margin: 100 * $defaultSize 0;

            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                color: #000000;
                margin-bottom: 5 * $defaultSize;
                text-align: center;
            }

            .hint {
                font-size: 15 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #4F5559;
                text-align: center;
            }

            .big {
                margin-top: 10 * $defaultSize;
                display: flex;
                justify-content: space-between;

                .small {
                    width: 230%;
                    text-align: center;
                    opacity: 1;
                    padding: 25 * $defaultSize;

                    .circular {
                        display: flex;
                        /* 将容器设置为弹性布局 */
                        align-items: center;
                        /* 将子元素垂直居中对齐 */
                        justify-content: center;

                        div {
                            display: flex;
                            /* 将容器设置为弹性布局 */
                            align-items: center;
                            /* 将子元素垂直居中对齐 */
                            justify-content: center;
                            border-radius: 50%;
                            color: #FFFFFF;
                            font-size: 10 * $defaultSize;
                            background: #224FD9;
                            width: 20 * $defaultSize;
                            height: 20 * $defaultSize;
                        }
                    }

                    .smallH1 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-top: 10 * $defaultSize;
                        margin-bottom: 10 * $defaultSize;
                    }

                    .smallH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }

        .box4 {
            margin-top: 30 * $defaultSize;
            padding-top: 160 *$defaultSize;
            padding: 50 * $defaultSize;

            img {
                width: 100%;
            }
        }

        .box5 {
            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                color: #000000;
                margin-bottom: 5 * $defaultSize;
                text-align: center;
            }

            .hint {
                font-size: 15 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #4F5559;
                text-align: center;
            }

            .big {
                .big1 {
                    display: flex;
                    margin-top: 20 * $defaultSize;

                    .small {
                        width: 230%;
                        text-align: left;
                        opacity: 1;
                        padding: 25 * $defaultSize;

                        .circular {
                            img {
                                width: 40 * $defaultSize;
                            }
                        }

                        .smallH1 {
                            font-size: 15 * $defaultSize;
                            font-family: Inter-Medium, Inter;
                            font-weight: 500;
                            color: #000000;
                            margin-top: 10 * $defaultSize;
                            margin-bottom: 10 * $defaultSize;
                        }

                        .smallH2 {
                            font-size: 11 * $defaultSize;
                            font-family: Inter-Regular, Inter;
                            font-weight: 400;
                            color: #4F5559;
                        }
                    }

                    .small:hover {
                        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        animation: tracking-in-bom 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }

                    .small:active {
                        animation: none;
                        animation-play-state: paused;
                        animation-fill-mode: forwards;
                    }
                }

                .big2 {
                    display: flex;

                    .small {
                        width: 230%;
                        text-align: left;
                        opacity: 1;
                        padding: 25 * $defaultSize;

                        .circular {
                            img {
                                width: 40 * $defaultSize;
                            }
                        }

                        .smallH1 {
                            font-size: 15 * $defaultSize;
                            font-family: Inter-Medium, Inter;
                            font-weight: 500;
                            color: #000000;
                            margin-top: 10 * $defaultSize;
                            margin-bottom: 10 * $defaultSize;
                        }

                        .smallH2 {
                            font-size: 11 * $defaultSize;
                            font-family: Inter-Regular, Inter;
                            font-weight: 400;
                            color: #4F5559;
                        }
                    }

                    .small:hover {
                        border-radius: 10px;
                        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
                        animation: tracking-in-bom 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }

        }

        .box6 {
            margin-top: 30 * $defaultSize;

            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                color: #000000;
                margin-bottom: 5 * $defaultSize;
                text-align: center;
            }

            .hint {
                font-size: 15 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #4F5559;
                text-align: center;
            }

            .imgDiv {
                margin-top: 20 * $defaultSize;
                width: 100%;
                display: flex;
                /* 将容器设置为弹性布局 */
                justify-content: center;

                .img {
                    width: 100%;
                    height: 400 * $defaultSize;
                    background: url('../assets/chainData/Group 144.svg');
                    background-size: cover;
                }
            }
        }

        .box7 {
            .big {
                display: flex;
                margin-top: 20 * $defaultSize;

                .small {
                    width: 230%;
                    text-align: left;
                    opacity: 1;
                    padding: 25 * $defaultSize;

                    .circular {
                        img {
                            width: 40 * $defaultSize;
                        }
                    }

                    .smallH1 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-top: 10 * $defaultSize;
                        margin-bottom: 10 * $defaultSize;
                    }

                    .smallH2 {
                        font-size: 11 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }
    }
}</style>
  
  