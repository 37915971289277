module.exports = { // 繁体
    topBar: {
        linkWallet: "在 Reactor 免費啟動你的第一個機器人。了解更多",
        unit: "美元",
        h1: "首頁",
        h2: "產品",
        h2_1: "k線的回測能力",
        h2_1_1: "策略回測",
        h2_1_2: "進行模擬交易回測",
        h2_1_3: "手動交易",
        h2_1_4: "在鏈上掛限價單",
        h2_1_5: "K線與鏈上數據",
        h2_1_6: "實時查閱交易K線與鏈上數據",
        h2_2: "機器人多種類",
        h2_2_1: "複製機器人",
        h2_2_2: "跟單專業的交易員",
        h2_2_3: "交易機器人",
        h2_2_4: "24小時工作,排除情緒化交易",
        h2_2_5: "做市商機器人",
        h2_2_6: "24小時工作,排除情緒化交易",
        h2_2_7: "套利機器人",
        h2_2_8: "跨交易所套利",
        h2_2_9: "DEMO機器人",
        h2_2_10: "沒有風險的獲得交易經驗",
        h2_3: "模版商城即開即用",
        h2_3_1: "信號商城",
        h2_3_2: "進行更準確的交易決策",
        h2_3_3: "策略商城",
        h2_3_4: "像專業人士一樣交易",
        h2_3_5: "機器人商城",
        h2_3_6: "各類自動化機器人",
        h3: "市場",
        h3_1: "Token",
        h3_2: "支持流動性平台",
        h3_2_1: "幣安",
        h3_2_2: "Binance 是全球交易量最大的加密貨幣交易所，截至 2022 年 8 月，Binance 交易…",
        h3_2_3: "火幣",
        h3_2_4: "Huobi Global 是一家國際數字資產交易所，業務遍及 100 多個國家。該平台於2013…",
        h3_2_5: "歐易",
        h3_2_6: "OKX 是一個位於塞舌爾的加密貨幣集中交易(CEX) 和錢包平台，為全球數百萬客戶…",
        h3_2_7: "PancakeSwap",
        h3_2_8: "PancakeSwap 是一種業內領先的非託管化去中心化交易所 (DEX) 的開發者，其專注於先進的加…",
        h3_2_9: "UniSwap",
        h3_2_10: "Uniswap 是一個去中心化交易所(DEX)，它首先引入了自動做市商(AMM) 模型。與…",
        h3_2_11: "DODO",
        h3_2_12: "Dodo 是一個基於以太坊的去中心化交易所，具有“主動做市商”，即自動做市商 (AMM…",
        h3_3: "查看全部受支持的資產",
        h3_3: "更多交易平臺",
        h4: "價格",
        h5: "企業",
        h5_1: "關於我們",
        h5_2: "卓越服務，持續創新。與客戶緊密合作，提供高質量解決方案。建立長期合作關係，共同成長。",
        h5_3: "OEM",
        h5_4: "專業定制解決方案，滿足您的獨特需求，為您提供個性化服務。",
        h5_5: "商務運營",
        h5_6: "高效管理和拓展業務，助力您實現持續增長和市場領先。",
        h5_7: "社交媒體",
        h5_8: "發掘我們的社交媒體渠道，追踪最新動態，共同探索行業前沿和創新趨勢。",
        h5_9: "合作夥伴",
        h5_10: "攜手共創，共享資源和機遇，實現互利共贏，共同邁向成功之路。",
        h5_11: "聯繫我們",
        h5_12: "與我們取得聯繫，讓我們一起探討商機並合作開展有益的合作關係。",
        h6: "幫助",
        h7_1: "簡體中文",
        h7_2: "英文",
        h7_3: "日文",
        h7_4: "韓文",
        h7_5: "繁體中文",
        h7_6: "泰語",
        h7_7: "馬來語",
        h7_8: "越南語",
        h8: "鏈接錢包",
        h9: "更多",
        h9_1: "返佣",
        h9_2: "通過鏈接邀請您的朋友加入我們的平台，您將有機會獲得額外的佣金回報。",
        h9_3: "錢包",
        h9_4: "安全可靠，方便存儲，輕鬆管理您的數字資產，保障資金安全與隱私保護。",
        h9_5: "定制策略",
        h9_6: "深入了解您的投資目標，為您量身定制符合您需求的投資策略。",
        h9_7: "DCA",
        h9_8: "智能定投，穩健增值，規避市場波動風險，長期持有收益穩定。",
    },
    btm: {
        h1: "產品",
        h2: "錢包",
        h3: '市場 ',
        h4: "信號",
        h5: "鏈上數據",
        h6: "定制策略",
        h7: "返佣",
        h8: "機器人",
        h9: "交易機器人",
        h10: "DEMO機器人",
        h11: "複製機器人",
        h12: "套利機器人",
        h13: "做市商機器人",
        h14: "關於我們",
        h15: "OEM",
        h16: "廣告合作",
        h17: "商務運營",
        h18: "社交媒體",
        h19: "合作夥伴",
        h20: "社區",
        h21: "服務",
        h22: "下載",
        h23: "服務協議",
        h24: '隱私協議',
        h25: "風險提示",
        h26: "幫助中心",
        h27: "關注我們",
        h28: "本地下載",
        h29: "企業信用評價AAA級信用企業",
        h30: "招標投標領域國家級榮譽證書",
        h31: "中華人民共和國商務部誠信檔案企業",
        h32: "誠信經營示範企業",
        h33: "粵公網安備44030502009469號 粵ICP備2022043961號-1 增值電信業務經營許可證 B1-20214279 粵網文:（2018）5045-1753號 © 2018 - 2023 TIANYI. ALL RIGHTS RESERVED",
        h34: "桌面客戶端",

        A1: '策略回測',
        A2: '手動交易',
        A3: 'K線與鏈上數據',
        A4: '信號商城',
        A5: '策略商城',
        A6: '交易機器人',
        A7: 'DEMO機器人',
        A8: '複製機器人',
        A9: '套利機器人',
        A10: '做市商機器人',
        A11: '關於我們',
        A12: 'OEM',
        A13: '社交媒體',
        A14: '聯繫我們',
        A15: '隱私政策',
        A16: '服務協議',
        A17: '免責聲明',
        A18: '幫助中心',
        A19: '定制策略',
        A20: '返佣',
        A21: '錢包',
        A22: 'DCA',
        A23: '下載',
        A24: "更多",

    },
    home: {
        h1: "自動交易，低買高賣",
        h2: "聚集諸多功能的智能交易終端",
        h3: '可定制的數字貨幣交易機器人 ',
        h4: "免費體驗",
        h5: "支持中心化交易所&去中心化交易所 ",
        h6: "多元化投資",
        h7: "專業量化",
        h8: "指標參數",
        h9: "交易數據",
        h10: "邀請返佣",
        h11: "連接您的錢包和交易所賬戶 並使用我們的交易終端管理它們",
        h12: "我們深知多元化投資對於投資組合的重要性。通過Reactor您可以輕鬆地同時管理多個交易所和錢包，更加靈活地分散資金，將投資分佈在不同的資產和市場上，有效降低單一資產或市場的風險。",
        h11_1: "精準分析，卓越交易效果",
        h12_1: "Reactor可以進行精準的市場分析和交易策略優化，實現卓越的交易效果。利用先進的算法和數據指標提高交易效率，從而更好地掌控市場，獲得更穩定的收益。",
        h11_2: "靈活定制，優化交易指標",
        h12_2: "為您提供靈活的指標參數設置，根據個人需求定制化交易策略。通過調整指標參數來優化交易表現，更好地適應市場變化，實現個性化的交易體驗，獲得更好的投資回報。",
        h11_3: "實時監控，精準把握市場動態",
        h12_3: "通過實時數據，您可以更準確地做出交易決策，及時捕捉投資機會。不再錯過任何重要時刻，通過數據監控，讓您精準把握市場動態。",
        h11_4: "分享賺錢，邀請獎勵",
        h12_4: "通過邀請好友參與平台，獲得至高15%的邀請獎勵。通過邀請反傭功能，您與好友一起分享交易樂趣的同時，還可以獲得可觀的反傭收益。",
        h13: "了解更多",
        h14: "支持網絡",
        h15: "支持的",
        h16: "流動性平台",
        h17: "加密貨幣",
        h18: "處理的",
        h19: "交易訂單",
        h20: "讓您像專業人士一樣交易的理想之選",
        h21: "在 Reactor 上使用聚合流動性平台進行交易加密貨幣，在鏈上進行市價單和限價單交易，執行自動化交易策略，查看交易數據。",
        h22: "專業的團隊和技術",
        h23: "我們致力於提供透明、可靠的服務，始終把客戶的利益放在首位。我們的投資策略建立在充分的研究和盡職調查的基礎上，以確保為您提供最佳的投資機會。",
        h24: '您的資金和算法是安全的',
        h25: "錢包",
        h26: "我們不會記錄您的私鑰信息，機器人將嚴格遵守交易所和錢包的安全標準",
        h27: "身份驗證",
        h28: "採取多重身份驗證和加密措施，確保您的資產和交易信息的安全",
        h29: "安全交易",
        h30: "您可以放心地使用我們的機器人進行交易，享受便捷和安全的投資體驗。",
        h31: "加密貨幣交易終端便捷管理所有加密資產組合",
        h32: "我們的交易終端匯聚了多個交易所和錢包的功能，讓您能夠在一個統一的界面上進行資產管理和交易操作。",
        h33: "一起探索區塊鏈的無限可能",
        h34: "我們提供全面的區塊鏈解決方案和專業的諮詢服務。我們的團隊擁有豐富的區塊鏈經驗和專業知識，幫助您理解區塊鏈的覈心概念，掌握關鍵技術和工具，並探索如何將區塊鏈應用於實際場景。",
    },
    about: {
        h1: "TIANYI 是一家集服務器/網絡等底層到應用層數字化工程解決方案開發提供商",
        h2: "我們是一個來自世界各地的數字創意團體。",
        h3: '我們的團隊由一群富有經驗、充滿激情的專業人士組成。我們多項領域的專業解決方案和創意服務，包括應用程序開發、Web3、視覺設計與創意、應用設計與策劃、服務器/網絡與機房、營銷與辦公系統。 ',
        h4: "一站式服務",
        h5: "我們提供全方位的一站式服務，涵蓋了從規劃、設計、開發到運營的所有領域。",
        h6: "全球標準",
        h7: "全球標准設計，採用無代碼工具，並積極引入NFT和元界等Web3tran技術。",
        h8: "服務器和IDC網絡",
        h9: "我們提供高性能的服務器解決方案和可擴展的IDC網絡架構，確保您的業務運行平穩、安全可靠。",
        h10: "使命、願景和價值觀",
        h11: "使命",
        h12: "用數字創意的力量，解決問題，為社會做出持久貢獻。我們的使命是通過創新和創意，為社會提供有意義的解決方案，推動社會進步。",
        h13: "聯繫我們，或者預約參觀我們的辦公室",
        h14: "深圳市寶安區新安街道寶民一路寶通大廈608",
        h15: "立即諮詢",
    },
    chainData: {
        h1: "K線與鏈上數據",
        h2: "洞察市場趨勢的完美組合",
        h3: '融合歷史行情與實時區塊鏈信息',
        h4: "優化您的交易信息",
        h5: "查看詳情",
        h6: "融合K線與鏈上數據的卓越優勢",
        h7: "在Reactor實時洞察市場，精準決策交易",
        h8: "顯示歷史價格走勢，並追踪區塊鍊網絡中的交易數據",
        h9: "查看最新的區塊鏈交易記錄區塊高度和交易確認時間",
        h10: "基於K線和鏈上數據的分析生成準確的交易信號",
        h11: "直觀的圖表和圖形方式展示K線和鏈上數據",
        h12: "查看持倉情況、資產分配比例、每個資產的市值變化",
        h13: "還有Reactor特有的",
        h14: "提升交易決策的洞察力和效率",
        h15: "多鏈",
        h16: "可定制化",
        h17: "信號",
        h18: "Reactor支持多條區塊鍊網絡上的交易。如以太坊、波場、幣安智能鍊等",
        h19: "您可以自定義交易策略、風控規則和考數設遭，從而實現個性化的交易體驗",
        h20: "在K線圖上顯示點位、交易建議和操作提示，應觀的了解信號與市場的關係",
        h21: "深入洞察區塊鏈的實時數據",
        h22: "通過Reactor，深入了解鏈上數據的關鍵內容和意義",
        h23: '實時市場數據',
        h24: "區塊鏈交易追踪",
        h25: "智能合約分析",
        h26: "地址和余額查詢",
        h27: "歷史數據回溯",
        h28: "自定義指標分析",
        h29: "包括加密貨幣價格、交易量和市值等關鍵指標，幫助您全面了解市場的動態。",
        h30: "追踪和顯示交易歷史，包括交易時間、交易對、交易量和交易方向。",
        h31: "幫助您深入了解和評估不同項目的智能合約的安全性和可靠性。",
        h32: "您可以輸入地址查詢任何加密貨幣的餘額和交易記錄。",
        h33: "展示歷史加密貨幣的價格走勢、交易量變化等數據",
        h34: "您可以根據自己的需求和策略，創建個性化的指標，並應用於K線或鏈上數據。",
        h35: "為交易而生",
        h36: "實時K線圖、精準指標和智能分析",
        h37: "幣種信息",
        h38: "圖表交易",
        h39: "智能分析",
        h40: "幣種名稱、發行總量等基本信息和價格、市值、24小時交易量等重要指標",
        h41: "可以百接在K線圖右側進行交易，從中洞察市場的機會井做出相應的交易決策",
        h42: "信號可以識別價格的趨勢模式、形態結構，以及市場的阻力和支撐位。",
    },
    community: {
        h1: "加入Reactor社區",
        h2: "獲取最新的Reactor活動資訊和在線支持",
        h3: 'Telegram',
        h4: "量化社區",
        h5: "Twitter",
        h6: "華語社區",
        h7: "Instagram",
        h8: "Facebook",
        h9: "Telegram",
        h10: "獲得專業工作人員的支持和解答",
        h11: "我們的社區擁有經驗豐富的專業工作人員，他們對加密市場和量化交易有著深入的了解。作為社區會員，您可以與我們的專家團隊直接互動，提出問題、尋求建議和解答。無論是關於市場分析、交易策略還是技術支持，我們的工作人員將竭誠為您提供幫助，確保您在量化交易的道路上始終走在正確的方向。",
        h12: "捕捉加密市場趨勢，探索新的量化策略",
        h13: "加入我們的社區，您將有機會捕捉加密市場的最新趨勢和動態。我們提供先進的量化交易工具和技術，幫助您分析市場數據、制定策略並實時執行交易。通過與其他會員的交流和分享，您可以探索新的量化策略，拓展投資視野，並在不斷變化的市場中獲取競爭優勢。",
        h14: "自由分享，結識新的加密朋友",
        h15: "我們的社區是一個自由分享和互助的平台。作為會員，您可以分享您的交易經驗、洞察和策略，與其他會員進行互動和討論。通過與其他志同道合的加密愛好者和專業交易者交流，您可以擴大您的人脈圈並結識新的加密朋友。在這個積極和合作的社區中，您將有機會從他人的經驗中學習，共同成長，並創造更多的交易機會。"
    },
    cooperation: {
        h1: "全球公司合作夥伴",
        h2: "Tianyi 在中國、亞洲、中東、東南亞、等世界各地擁有眾多合作夥伴公司。我們與世界各地的合作夥伴 公司一起提供新技術解決方案。",
        h3: '深圳',
        h4: "總公司",
        h5: "新加坡",
        h6: "東南亞",
        h7: "成都",
        h8: "分公司",
        h9: "武漢",
        h10: "日本",
        h11: "亞洲",
        h12: "越南",
        h13: "湖南",
        h14: "河南",
        h15: "韓國",
        h16: "柬埔寨",
        h17: "海南",
        h18: "迪拜",
        h19: "中東",
        h20: "泰國"
    },
    copyRobot: {
        h1: "跟隨頂級交易者的超凡表現",
        h2: "通過Reactor獲取頂級交易者的獨特交易策略和卓越表現",
        h3: '查看更多',
        h4: "獨特的交易策略",
        h5: "與頂級交易者直接合作的機會",
        h6: "提高自己的交易成功率和績效",
        h7: "適當的資金分配，實現更好的風險管理",
        h8: "更廣泛和多樣化的交易策略選擇",
        h9: "了解詳情",
        h10: "為此，我們提供了",
        h11: "完全自動地盟隨交易者的買入和賣出行為",
        h12: "個性化的調整參數，如止盈、止報等",
        h13: "查看買入和賣出的資產、交易記錄",
        h14: "根據自己的鳳險承受能力進行資金配置和風險管理",
        h15: "查看更多功能",
        h16: "為您提供詳細數據和信息",
        h17: "輕鬆尋找並跟隨優秀交易者，獲得穩定的投資回報",
        h18: "機器人賣方信息透明",
        h19: "每週的交易結果匯總",
        h20: "平均的利潤",
        h21: "用戶可以根據賣方的在步狀杰和最後上埃時間來判斷其活躍程度，區時通過揮供的郎箱聯採方式與賣方進行進一步的合作",
        h22: "複製機器人恐為現探供每週的交易結果匯總，包括總利潤、買入次數、賣出次數等，幫助您及時了解和評估交易的表現。",
        h23: "複製機器人將計算並展示您的平均利潤，以圖表的形式展現，讓您了解每筆交易的平均收益以及預期收益。",
        h24: "查看上個月的表現報告",
        h25: "勝率、負率、盈虧比",
        h26: "交易幣種和交易次數",
        h27: "包括總資產走勢圖表、每筆交易的最佳利潤、最大回撤等數據，以幫助您了解過去一個月的投資情況。",
        h28: "複製機器人將為您展示交易者的勝率、負率和盈虧比等重要指標，幫助您評估交易策略的穩定性和盈利能力",
        h29: "複製機器人將提供您複製的交易幣種列表以及總交易次數的統計，讓您了解您參與的交易品種和交易頻率。",
    },
    dca: {
        h1: "量化之旅從定投策略(DCA)出發",
        h2: "定期投資資金來購買加密貨幣無論市場價格如何波動",
        h3: '查看更多',
        h4: "什麼是DCA？",
        h5: "DCA是一種長期投資策略，通過定期投資相同金額的資金來購買加密貨幣，無論市場價格如何波動。這種策略可以幫助投資者分散風險、減少市場波動的影響，並長期受益於資產增值。您可以輕鬆設置定投計劃，選擇您感興趣的加密貨幣和投資週期。系統將自動按照您設定的計劃，在指定的時間間隔內執行交易，並根據市場價格智能購買加密貨幣。靈活調整定投計劃，根據市場變化和個人需求進行優化。",
        h6: "在 Reactor 使用DCA策略有什麼優勢？",
        h7: "穩定投資",
        h8: "DCA通過定期投資固定金額，無論市場價格如何波動，都能保持穩定的投資節奏。這種穩定性可以幫助投資者攤平市場的波動，減少投資的風險。",
        h9: "平均成本",
        h10: "DCA策略通過定期投資，可以實現“買入成本平均化”。當市場價格下跌時，您會購買更多的資產單位，而當市場價格上漲時，您購買的資產單位會減少。這種方式可以幫助您在市場波動中平均購買資產，降低買入價格的風險。",
    },
    demoRobot: {
        h1: "實時模擬交易，零風險體驗",
        h2: "DEMO機器人幫您探索交易世界",
        h3: '查看更多',
        h4: "為什麼要用DEMO機器人",
        h5: "DEMO機器人提供了一個無風險的環境，供用戶學習、實踐和測試量化交易策略。它們可以幫助用戶提高交易技能，評估機器人的性能，優化交易策略，並增強對市場的理解。通過使用DEMO機器人，用戶可以在實際交易之前建立信心和經驗，從而更好地應對真實市場中的挑戰。",
        h6: "零風險模擬交易",
        h7: "DEMO機器人允許您以虛擬資金進行模擬交易，無需使用真實資金。您可以在真實市場條件下進行交易操作，體驗實時價格波動，了解交易的實際運作方式，而無需承擔任何實際風險。",
        h8: "模擬交易參數和歷史記錄",
        h9: "DEMO機器人會記錄您的模擬交易歷史，包括買入、賣出、止損、止盈等操作。您可以查看歷史記錄，分析交易的結果和績效，評估您的交易策略和技巧，並進行必要的調整和改進。",
        h10: "策略優化和調整",
        h11: "通過DEMO機器人，用戶可以進行策略的優化和調整。DEMO機器人會生成實時報告和分析，包括交易歷史、收益曲線、交易統計等數據。您可以通過報告和分析了解您的交易表現、收益情況以及其他關鍵指標。",
    },
    download: {
        h1: "開始免費使用 Reactor 進行交易！",
        h2: "完美匹配Windows與Mac，支持全業務功能",
        h3: 'Reactor 電腦版',
        h4: "下載Windows與Mac OS客戶端，享受專業的交易服務。",
        h5: "本地下載",
        h6: "為什麼選擇Reactor？",
        h7: "保護您的資產安全",
        h8: "Reactor採用最高級別的安全協議和加密技術，確保您的數字資產始終得到最強大的保護。",
        h9: "洞察市場趨勢",
        h10: "Reactor提供全面的數據統計和分析工具，幫助您深入了解市場趨勢和價格波動。並根據市場情況調整您的交易策略。",
        h11: "迅捷高效的交易體驗",
        h12: "Reactor提供高速的交易執行和訂單處理，確保您能夠快速響應市場變化，抓住交易機會。",
    },
    exchange: {
        h1: "幣安",
        h2: "聊天",
        h3: '什麼是幣安？ ',
        h4: "Binance 是全球交易量最大的加密貨幣交易所，截至 2022 年 8 月，Binance 交易所的每日交易量為 760 億美元，在全球擁有 9000 萬客戶。該平台已成為加密領域值得信賴的成員，用戶可以在其中購買、出售和存儲他們的數字資產，以及訪問列出的 350 多種加密貨幣和數千個交易對。Binance 生態系統現在包括 Binance Exchange、Labs、Launchpad、Info、Academy、Research、Trust Wallet、Charity、NFT 等。",
        h5: "Reactor如何在幣安管理您的賬戶？",
        h6: "通過 Reactor 平台的 API，無縫連接交易所，實現自動化交易。借助我們強大的 API 功能，您可以方便地與多個交易所進行關聯，自動執行交易策略，實現高效的交易操作。充分利用 API 的便利性，輕鬆管理您的交易賬戶，並實現更好的投資結果。",
        h7: "誰是幣安創始人？",
        h8: "這家全球公司由趙長鵬和何一在中國共同創立。趙長鵬（CZ）是加拿大華裔開發商和業務主管，是該公司的首席執行官。他曾就讀於麥吉爾大學蒙特利爾分校，並擁有成功的企業家記錄。他過去的經歷包括 Bloomberg Tradebook 期貨研發團隊負責人、Fusion Systems 創始人和 Blockchain.com 技術負責人。 Yi He 是 Binance 的首席營銷官，也是該公司風險投資部門 Binance Labs 的負責人。曾任領先移動視頻科技公司一俠科技的副總裁，以及數字資產交易所 OKCoin 的聯合創始人。",
        h9: "幣安什麼時候推出的？",
        h10: "Binance 於 2017 年 6 月推出，並在 180 天內成長為全球最大的加密貨幣交易所。",
        h11: "幣安受限國家？",
        h12: "根據幣安使用條款，受限地區包括美國、新加坡和安大略省（加拿大）。但是，某些國家/地區限制使用或功能可能由於監管原因而受到限制，包括但不限於中國、馬來西亞、日本、英國和泰國。德國、意大利和荷蘭也不提供期貨和衍生產品。2019 年 9 月，為美國客戶推出了一個單獨的專用平台 Binance.US。",
        h13: "幣安費用是多少？",
        h14: "該平台用戶友好，是最便宜的平台之一，提供大量交易類型選擇，並為經驗豐富的投資者提供一套先進的交易工具。它基於分級系統收費，從普通用戶到VIP 9。對於普通用戶，現貨交易收取0.10%的maker taker費用。2022 年 7 月，幣安宣布 BTC 現貨交易對零手續費交易，並於 8 月宣布 ETH/BUSD 交易對零手續費交易。",
        h15: "是否可以在 Binance 上使用槓桿或保證金交易？",
        h16: "交易者可以藉入資金並參與幣安保證金的保證金交易，這允許以高達 10 倍的槓桿率交易加密貨幣。用戶還可以使用以 USDT、BUSD 或其他加密貨幣結算的幣安合約等衍生產品和幣安期權來進行交易。",

        A0: 'OKX',
        A1: '什麼是 OKX？',
        A2: 'OKX 是設立於塞舌爾的加密貨幣中心化交易所 (CEX) 和錢包平台，服務於全球數百萬客戶。該交易所提供包括現貨和簡單期權在內的基本交易，以及包括保證金、期貨、永續合約和期權在內的衍生品。其他產品包括：交易機器人、大宗交易、OKX Earn（儲蓄、穩定幣、DeFi、質押、ETH 2.0等）、加密貨幣貸款和交易所的啟動平台Jumpstart。',
        A3: 'OKX錢包聲稱是“Web3門戶”，提供加密熱門錢包、去中心化交易所(DEX)、NFT市場和去中心化應用程序 (DApp)。該錢包支持超過 30 種網絡，包括主要區塊鏈，如比特幣、以太坊、BNB 鏈、Solana、Polygon、Avalanche、Fantom等。',
        A4: 'OKX也有自己的原生區塊鏈 —— OKX 鏈與原生代幣 OKB，它允許創建者和項目部署DApp，並允許開發人員使用OKX Oracle等基礎架構。',
        A5: '2022年1月，OKEx更名為OKX，有了新的品牌和路線圖。',
        A6: 'OKX 的創始人是誰？',
        A6: 'OKX（原OKEx）是 OK 集團旗下 OKCoin 的子公司。',
        A7: '該公司由 Mingxing “Star” Xu 於2013年在中國創立。Xu是一名中國企業家。他擁有北京科技大學應用物理學學士學位。Star Xu是OK集團的首席執行官。',
        A8: 'OKX 的現任首席執行官是 Jay Hao。',
        A9: 'OKX 於何時推出？',
        A10: '該交易所（前身為 OKEX）於2017年推出。',
        A11: 'OKX 位於何處？',
        A12: '公司總部設在塞舌爾。',
        A13: '限制使用 OKX 的國家',
        A14: '該項目可在 200 個以上國家使用。然而，美國居民無法使用該平台的服務。',
        A15: 'OKX 可支持哪些幣種？',
        A16: '有 350 種以上的加密貨幣在該交易所上架，其支持的交易對在 500 種以上。該平台上架的代幣包括BTC、ETH、OKB、AAVE、SOL、MATIC、XRP、DOGE、SHIB和DOT。',
        A17: 'OKX 的手續費如何？',
        A18: '該交易所採用掛單吃單費用模式。平台上的交易費用起始於0.10%，並隨著交易量的增加而降低。對於普通用戶而言，其手續費取決於OKX生態系統中OKB（原生代幣）的數量，而對於高級用戶而言，手續費是基於他們30天的交易量計算的。',
        A19: 'OKX 上可使用槓桿或保證金交易嗎？',
        A20: 'OKX的保證金交易最高槓桿為10倍。在衍生品方面，OKX 提供最高 125 倍的期貨交易、永續合約掉期槓桿。此外，交易者還可以通過加密期權，包括BTC、ETH等進行槓桿操作。',


        B0: '火幣',
        B1: '什麼是 Huobi Global？',
        B2: 'Huobi Global是一個國際數字資產交易所，在全球 100 多國均可使用。該平台於2013年在中國成立，為全球用戶提供交易和投資服務。其每天的交易量達40億美元。',
        B3: '該中心化交易所 (CEX) 支持首次代幣發行 (ICO)，也上架了多種加密貨幣。該平台上有超過 500 種資產與 300 種以上交易對。用戶可以使用保險政策來保護他們的資產。該交易所擁有多個全年無休的客服支持渠道，讓客戶享受一流的專業服務。',
        B4: '該交易所的生態系統包括現貨交易、保證金交易、期貨交易、衍生品交易、質押、加密貨幣貸款等。活躍交易者與投資人可在場外交易 (OTC) 市場平台尋覓機會，並使用各種自定義交易工具。',
        B5: 'Huobi Global 的創始人是誰？',
        B6: '該交易所由李林 (Leon Li) 於2013年在北京創辦。其首席執行官李林於2013年畢業於清華大學的自動化專業。在創辦 Huobi Global 前，李林在美國最大的級軟件公司之一——甲骨文擔任計算機工程師。',
        B7: 'Huobi Global 位於何處？',
        B8: '該公司總部位於塞舌爾群島，並在香港、韓國、日本和美國設有辦事處。',
        B9: '限制使用 Huobi Global 的國家',
        B10: '來自下列國家的用戶不得使用 Huobi Global 提供的所有服務：中國、美國、加拿大、日本、古巴、伊朗、朝鮮、蘇丹、敘利亞、委內瑞拉、新加坡和克里米亞。',
        B11: '下列國家/地區的用戶無法使用衍生品交易服務：中國大陸、台灣、香港、以色列、伊拉克、孟加拉國國、玻利維亞、厄瓜多爾、吉爾吉斯斯坦、塞瓦斯托波爾、西班牙、英國（僅限零售用戶）和新西蘭。',
        B12: '日本用戶可以使用 Huobi Japan 交易所的服務。此外，該公司也宣布重返美國市場的計劃。',
        B13: 'Huobi Global 可支持哪些幣種？',
        B14: '該交易所支持 400 種以上數字資產與加密貨幣，包括最熱門的：BTC、DOGE、ETH、LTC、XMR、HT、HUSD、USDT。',
        B15: '截至 2022 年 8 月，該交易所支持的法幣包括 ALL、BGN、CHF、CZK、DKK、EUR、GBP、HRK、HUF、MDL、MKD、NOK、PLN、RON、SEK、TRY、UAH、HKD、AUD、USD。',
        B16: 'Huobi Global 的手續費如何？',
        B17: 'Huobi 手續費具有等級架構，交易費用視個別用戶的月交易量而定。該平台收取基本掛單吃單費用 0.2%，交易量達 500 萬美元可享折扣；而 Huobi 代幣 (HT) 的持有者亦可享受折扣。專業交易者可享受以下優惠：掛單費 0.0362% 起，吃單費 0.0462% 起。',
        B18: 'Huobi Global 上可使用槓桿或保證金交易嗎？',
        B19: '該平台可針對多種加密貨幣使用保證金交易，讓用戶借用資金進行最多 3 倍槓桿的全倉模式保證金交易。其利率按小時計算。',
        B20: '用戶可以使用最高 200 倍槓桿，進行比特幣和以太幣加密期貨合約交易。',



        C0: 'PancakeSwap',
        C1: 'PancakeSwap簡介',
        C2: 'PancakeSwap是一種熱門的去中心化交易所 (DEX)，它可允許用戶在BSC鏈上進行 BEP-20 代幣兌換。其為 BSC 鏈生態系統上最大的交易所，且從市場佔有率而言，也是全球熱門的去中心化交易所 (DEX) 之一。該交易所採用自動做市商 (AMM) 模式，讓用戶能夠與流動性池進行交易。您可以成為流動性提供者，並獲得流動性提供 (LP) 代幣，便可分得交易所的交易手續費。',
        C3: '此外，LP 代幣持有者也可以參與流動性挖礦，以賺取該交易所的實用代幣——CAKE。該代幣可以在所謂的“糖漿池”中進行定期或活期質押。活期質押用戶可隨時取消質押，而定期質押會將報酬率拉到最大值（質押期最長為 52 週）。',
        C4: '此外，PancakeSwap也會提供彩票抽獎遊戲，讓其用戶可以贏取 CAKE 獎金。在 NFT 市場中，用戶可以交易收藏品，並且也可通過 NFT 個人資料系統中設定個性化的個人簡介。另外，也有一個與 ApolloX 共同開發的永續交易所，它包含多種熱門代幣交易對，並可提供無限期的期貨交易服務。用戶可在此交易所中利用比帳戶餘額還多的資金槓桿，來進行熱門代幣對的交易。',
        C5: 'PancakeSwap的創始人是誰？',
        C6: 'PancakeSwap為匿名人士創辦，其帶領二十多名匿名“廚師”，在交易所的“廚房”內工作。該交易所是開源的，並已通過 Certik 和 Slowmist 等知名安全公司的審計。',
        C7: 'PancakeSwap於何時推出？',
        C8: 'PancakeSwap於2020年9月推出。',
        C9: 'PancakeSwap位於何處？',
        C10: '由於其為去中心化交易所，因此其運營團隊都採取了遠程工作的模式。不過據 Crunchbase 報道，PancakeSwap總部位於日本福岡。',
        C11: '限制使用PancakeSwap的國家',
        C12: '由於其為去中心化交易所，因此不存在國家限制。不過，下列國家已受到地理封鎖：古巴、伊朗、津巴布韋、伊拉克、克里米亞、敘利亞、蘇丹、白俄羅斯和剛果民主共和國。',
        C13: 'PancakeSwap支持的代幣列表',
        C14: '所有的 BEP-20 代幣皆可交易，此外也支持多種熱門期貨交易對，例如 BTC/USDT、ETH/USDT、BNB/USDT 與其他所有大型第一層區塊鏈。',
        C15: 'PancakeSwap的手續費如何？',
        C16: '在撰寫此文時，代幣兌換會收取 0.25% 的交易費用。其中，0.17% 會當做獎勵回到流動性池，0.03% 作為儲備金，剩下的 0.05% 用於回購與銷毀。',
        C17: 'PancakeSwap上可使用槓桿或保證金交易嗎？',
        C18: '對於最大型的交易對，其永續期貨交易所可允許使用 150 倍槓桿，而對於較小型的交易對，上限則為 50 倍。',


        D0: 'UniSwap',
        D1: '什麼是 Uniswap？',
        D2: 'Uniswap 於 2018 年推出，是最受歡迎的加密貨幣去中心化交易所 (DEX) 之一。該交易所率先引入了自動做市商 (AMM) 模型，以取代仍在其他交易所中使用的傳統訂單簿模式。Uniswap 在以太坊區塊鏈上運作，通過多個智能合約來安全地在用戶之間兌換 ERC-20 代幣。',
        D3: '該協議的去中心化屬性，使得沒有單一集中式機構來運營管理該交易所；相對的，兌換也是以點對點 (P2P) 的方式進行的。此外，Uniswap 也致力於解決其他交易所帶來的流動性問題。Uniswap V3 是該協議的第三版。該更新後協議的不但提高了補償力度，且讓個人流動性提供者擁有更大的控制權與靈活度。Uniswap V3 佔所有去中心化交易所交易量的 46.5%，此外，該協議也在 2022 年 5 月創下了累計交易量達 1 萬億美元的紀錄。',
        D4: 'Uniswap V3 的創始人是誰？',
        D5: 'Hayden Adams 創辦了 Uniswap，他之前就讀於 Stony Brook（紐約州立大學石溪分校），之後在西門子擔任機械工程師。Adams 受到了Vitalik Buterin 一篇博客文章與一篇 Reddit 貼子的啟發，並在得到以太坊基金會的資助後，於 2018 年 11 月推出該協議。Uniswap Labs 先從 Paradigm 募得種子輪資金後，接著又在 2020 年 8 月通過 Andreessen Horowitz 領投的 A 輪中募得 1,100 萬美元。',
        D6: 'Uniswap V3已於2021年5月發布，距離第一版發布已經過去了將近2.5年，距離Uniswap V2發布也已經過去了一年。',
        D7: 'Uniswap 位於何處？',
        D8: '負責開發 Uniswap 協議與 Uniswap 界面的 Uniswap Labs 位於美國紐約市。作為一種去中心化金融(DeFi)協議，全世界範圍內任何能夠上網的個人均可使用它。不過，有些國家的用戶受到了一些限制。截至 2022 年 7 月，Uniswap 禁止 10個國家的用戶使用其協議。這些國家包括：白俄羅斯、古巴、伊朗、朝鮮、敘利亞、科特迪瓦、利比里亞、蘇丹、津巴布韋和伊拉克。',
        D9: '哪些加密貨幣可在 Uniswap 交易？',
        D10: '由於是去中心化的點對點協議，任何人都可在 Uniswap 上架任何代幣。截止本文撰寫時，最熱門的交易對包括USDC、Wrapped BTC、WETH和DAI。',
        D11: 'Uniswap 的手續費如何？',
        D12: 'Uniswap 的流動性提供者能夠以 0.05%、0.30%、和 1% 的三種費用等級開始建立流動性池。通過 UNI 治理提案，可能會新增更多的費用等級。',
        D13: 'Uniswap 上可使用槓桿或保證金交易嗎？',
        D14: 'Uniswap 平台不提供槓桿或保證金交易。',


        E0: 'DODO',
        E1: '什麼是 Dodo？',
        E2: 'Dodo 是一種具有「主動做市商」（即為自動做市商 (AMM) 的定制版本）功能且基於以太坊的去中心化交易所。Dodo提供了一種在區塊鏈上發行和交易數字資產的方法。該交易所提供的流動性，有自有以及聚合於其他交易所兩種。據其白皮書指出，這可讓該交易所提供全球最佳的 Web3 資產價格。',
        E3: '使用者可以通過市價單或限價單，以免許可的方式兌換不同代幣。同時，Dodo 也提供免 gas 費用的兌換路由，並通過專業做市商提供更高的流動性（零滑點且免 gas 費）。除此以外，還有一個可交易 ERC-721 與 ERC-1155 代幣的 NFT 市場。其流動性提供者計劃是在該交易所上賺錢的方法之一，使用者可以添加流動性，以取得 LP 代幣並',
        E4: '賺取交易費用分成。另一種方式是挖礦。使用者挖掘單一代幣，或是將資金加進指定流動性池中以進行 LP 做市挖礦。當然，使用者也可以通過更有效率的方式進行閃電貸款或配置資本。',
        E5: '最後，該交易所也提供工具，讓開發者創建代幣。該交易所支援的區塊鏈包括以太坊、BNB Chain、Polygon 與 OKChain。',
        E6: 'Dodo 的創始人是誰？',
        E7: '根據 CrunchBase 上的資訊，Dodo 是由兩名中國人 Qi Wang 與 Shichi Dai 創辦的。該項目從17家不同的風險投資公司共籌集了500萬美元，其中包括 Coinbase ventures、Defiance Capital 和 Galaxy Digital。',
        E8: 'Dodo 於何時推出？',
        E9: 'Dodo 於2020年8月推出。',
        E10: 'Dodo 位於何處？',
        E11: '根據 CrunchBase 的資訊，該交易所的總部位於香港。',
        E12: '限制使用 Dodo 的國家',
        E13: '本文撰稿之時，並沒有任何關於限制使用該交易所國家的資訊。',
        E14: 'Dodo 支持的代幣列表',
        E15: 'Dodo已經部署在幾乎所有與EVM兼容的區塊鏈上，如 arbitrrum、optimistic、Avalanche和 Aurora。因此，該交易所支援所有區塊鏈上的多種不同的代幣，包括所有主要的代幣，以及包裝以太幣 (Wrapped Ether) 和 包裝比特幣 (Wrapped Bitcoin)。',
        E16: 'Dodo 的手續費如何？',
        E17: 'Dodo 對創建新代幣收取少量的使用費 (0.02 ETH)，但未提供任何關於交易費用的資訊。',
        E18: 'Dodo 上可使用槓桿或保證金交易嗎？',
        E19: '該平台目前沒有保證金交易功能，但該項目的發展藍圖中指出，其將於 2022 年推出槓桿交易服務。',


        F2: '關於Curve (Ethereum)',
        F3: 'Curve Finance 簡介',
        F4: 'Curve Finance 是一種去中心化的穩定幣交易流動性池。它不使用訂單簿，而是利用自動做市商 (AMM) 模型來匹配流動性。由於本協議是去中心化且免信任的協議，所以任何人都可以向一或多個流動性池提供流動性。該 AMM 的恆定乘積公式，不但能確保最高效率，也為交易者帶來最小的滑點。',
        F5: 'Curve 池是一種執行智能合約的 StableSwap 變體，可用以交易多種代幣。可通過穩定幣交易對 (普通池) 或包裝代幣來進行代幣兌換，且底層的抵押品則在另一個協議 (貸出池) 中貸出。最後，Metapools將穩定幣與來自另一個池的 LP 代幣進行配對。',
        F6: '多條區塊鏈均支持該協議，如以太坊、Arbitrum、Aurora、Avalanche、Fantom、Harmony、Optimism、Polygon、xDai、Moonbeam。若需要使用 Curve 協議，用戶一般必須從以太坊橋接資金到這些區塊鏈。由於本協議對去中心化金融 (DeFi) 的系統化重要性，因此本協議也在所謂的 Curve 戰爭中引來多組其他協議來爭奪其治理權。',
        F7: 'Curve Finance 的創始人是誰？',
        F8: 'Curve Finance 由加密貨幣底層架構協議 NuCypher 的前任共同創始人兼首席技術官 Michael Egorov 創立。此外他也創建了一種去中心化的銀行與借貸網絡——LoanCoin。他在跨入加密貨幣領域前，於莫斯科物理技術學院與 Swinburne 科技大學求學。',
        F9: 'Curve Finance 於何時推出？',
        F10: 'Curve Finance於 2020 年 6 月推出，即在所謂的「去中心化金融（DeFi）之夏」。因此，它可說是新生的DeFi產業的基石之一。',
        F11: 'Curve Finance 位於何處？',
        F12: '根據 CBInsight 的消息，Curve Finance 的總部設於瑞士。',
        F13: '限制使用 Curve Finance 的國家',
        F14: '截至本文撰稿之時，並沒有任何關於限制使用 Curve Finance 的國家的信息。不過，受美國金融制裁的國家，其用戶可能受到地理位置封鎖的限制。',
        F15: 'Curve Finance 支持的代幣列表',
        F16: '該交易所支持多種主流穩定幣的流動性池，例如DAI、USDC、USDT、FRAX和TUSD。此外，它同時也支持包裝代幣的兌換，例如wBTC、wETH和stETH（其為一種原生的 Lido 的質押以太幣變體）。',
        F17: 'Curve Finance 的手續費如何？',
        F18: '該協議規定的標準手續費率為 0.04%。其中一半分配給流動性提供者，另一半則分配給 veCRV 的持有者。veCRV 的持有者如定期質押其代幣，即可獲得更大的治理權。',
        F19: 'Curve Finance 可使用槓桿或保證金交易嗎？',
        F20: '該交易所僅提供代幣兌換服務，且不提供槓桿或保證金交易。',


        G0: '什麼是Balancer？',
        G1: 'Balancer 是一種基於以太坊的去中心化自動做市商 (AMM) 協議，它代表了可編程流動性的靈活構建塊。',
        G2: '通過將 AMM 曲線邏輯和數學與核心交換功能分離，Balancer 成為一個可擴展的 AMM，可以包含任意數量的交換曲線和池類型。這包括：',
        G3: '傳統 50/50x*y=k加權池',
        G4: '自定義權重，例如 80/20，用於控制曝光',
        G5: '穩定的互換曲線',
        G6: '嵌套池（例如：Boosted Pools）',
        G7: '權重不斷變化的礦池（例如：流動性自並礦池）',
        G8: '集中的流動性池',
        G9: '允許自定義參數的托管池',
        G10: '整個協議都構建在頂部（例如：陀螺儀）',
        G11: '這樣，交換者、聚合者和套利者就可以輕鬆獲得所有總流動性。Balancer Vault優化了批處理和路徑邏輯，使Gas成本和資本需求保持極低。每個單獨的池和項目都建立在Balancer的全球流動性之上，這為基礎資產帶來了深度的流動性並開辟了互換路徑。',
        G12: '誰使用Balance？',
        G13: 'Balancer 對於Defi領域的不同參與者來說是一個非常有用的工具。',
        G14: '交換者可以在任意兩個ERC20代幣之間進行交換。這可以通過Balancer Dapp來完成或像1inch這樣的聚合器,抹茶，或Paraswap。',
        G15: '流動性提供者 (LP) 可以向資金池添加流動性，以賺取掉期費用、流動性激勵和其他形式的收益',
        G16: '被動 LP 可以利用增強池在已經複利的Aave代幣之上賺取收益',
        G17: '套利者可以使用批量互換和閃貸等方式與礦池進行互換',
        G18: 'BAL 代幣持有者可以將其代幣鎖定在veBAL中並參與Balancer協議發展的治理',
        J0: '什麼是 SushiSwap？',
        J1: 'SushiSwap 是一家使用AMM的去中心化交易所 (DEX)。該自動做市商利用智能合約為不同的代幣對創建交易市場。流動性提供者為這些智能合約提供資金，實現無摩擦交易。該交易所是最早採用這種模式的交易所之一，且最初是另一家熱門的 DEX （去中心化交易所）—— UniSwap 的分支。後來，它成長為所有DeFi中最大的交易所之一。現在，它幾乎可以在所有與EVM兼容的第一層區塊鏈和以太坊的某些第二層解決方案上運作。',
        J3: '該交易所的目標主要是希望進行免許可和非托管交易的 DeFi 交易者和機構。該交易所消除了流動性問題，並採取了比其他交易所更平等的治理方式。其原生代幣可用於對治理議題投票。',
        J4: '其核心產品包括 Bento Box，這是一種代幣庫，可以為存入其中的資金產生收益。Kashi 借貸是一個貸款與保證金交易平台，任何人均可在此創造自定義且高 gas 效率的代幣市場。在撰寫本文時，MasterChef V2是一個包含超過10億美元的流動性提供計劃。最後，無論是通過固定價格還是荷蘭式拍賣期權，Miso 為項目提供了在交易所上進行交易的機會。',
        J5: 'SushiSwap 的創始人是誰？',
        J6: 'SushiSwap 最初由 Chef Nomi（化名）創立。後來這批人離開了該項目，並把它的管理權交給了另外兩個匿名為 sushiswap 和 0xMaki 的開發人員。這兩人進行了產品開發與業務運營，後來該交易所的擁有權轉給了另一家中心化交易所 FTX 的首席執行官 Sam Bankman-Fried。',
        J7: 'SushiSwap 於何時推出？',
        J8: 'SushiSwap 於2020年8月推出。',
        J9: 'SushiSwap 位於何處？',
        J10: '該交易所沒有一個中心總部，而是以去中心化的方式運作。',
        J11: '限制使用 SushiSwap 的國家',
        J12: '目前沒有禁用或限制該交易所的國家的信息。',
        J13: 'SushiSwap 支持的代幣列表',
        J14: '由於其存在於第一層和二層區塊鏈上，SushiSwap 支持市場上幾乎所有的ERC-20代幣。',
        J15: 'SushiSwap 的手續費如何？',
        J16: '該交易所依據業界標準，對代幣兌換收取 0.3% 的費用。',
        J17: 'SushiSwap 上可使用槓桿或保證金交易嗎？',
        J18: 'Sushiswap 提供了貸款和保證金交易平台 Kashi，該平台使用了一種獨特的隔離市場框架。',

    },
    exchangeRobot: {
        h1: "實現個性化的自動化交易策略",
        h2: "交易機器人",
        h3: '靈活參數設置和個性化的風險管理，助您精確執行交易策略',
        h4: "查看詳情",
        h5: "更精確、有效的交易執行",
        h6: "幫助您實現自動化交易並根據自身需求進行個性化的交易策略設置",
        h7: "靈活的策略參數",
        h8: "Reactor的交易機器人提供廣泛的策略參數設置，以滿足不同投資者的需求",
        h9: "買入和賣出的觸發條件",
        h10: "預計投入資金設置",
        h11: "設置止盈、止損等參數",
        h12: "多樣化的買入策略",
        h13: "您可以根據市場狀況和投資目標選擇適合的買入策略，並通過參數設置進行進一步定制",
        h14: "百分比出價格",
        h15: "最大開放時間購買",
        h16: "最大未平倉頭寸和每個代幣百分比",
        h17: "交易冷卻機制",
        h18: "智能賣出策略，精準掌握時機",
        h19: "Reactor提供智能賣出策略，幫助您在交易中掌握最佳賣出時機，同時有效管理風險",
        h20: '止損賣出',
        h21: "追踪止損賣出",
        h22: "自動關閉",
        h23: "美元平均成本法",
        h24: "高級配置參數",
        h25: "Reactor交易機器人支持高級訂單類型，例如追踪止損買入、自動關閉、美元平均成本法等，使用戶能夠更精細地控制交易執行和風險管理。",
        h26: "個性化的止損設置",
        h27: "您可以靈活設置止損參數，以限制潛在損失。設置固定止損金額或百分比，或者使用動態的跟踪止損功能，在價格下跌時自動調整止損水平，以保護您的資金安全。",
        h28: "美元平均成本法（DCA）",
        h29: "通過定期、等額的投資方式來分散市場波動風險，實現長期穩健增長。",
    },
    faq: {
        h1: "客戶支持/FAQ",
        h2: "尋找答案？答案可能就在這裡。",
        h3: '新手指導',
        h4: "什麼是Reactor？",
        h5: "如何設置Reactor？",
        h6: "返佣",
        h7: "流動性平台",
        h8: "DEX",
        h9: "安全問題",
        h10: "會員權益",
        h11: "產品",
        h12: "交易",
        h13: "查看更多",
        h14: "Reactor是一款創新的量化交易平台",
        h15: "Reactor整合了先進的算法和技術，旨在幫助用戶更加智能、高效地進行交易。通過自動化執行交易策略、提供實時市場數據和分析，以及提供豐富的交易工具，使用戶能夠在金融市場中取得更好的交易結果。無論是新手還是專業交易者，Reactor都為其提供了一個強大的交易平台，幫助他們實現投資目標並獲得更高的交易收益。",
        h16: "常見問題",
        h17: "我可以賺取多少利潤？",
        h18: "套利是如何運作的？",
        h19: "你們保證盈利嗎？",
        h20: "我該如何連接到交易所？",
        h21: "我可以在一個交易賬戶上開啟幾個機器人？",
        h22: "我應該使用哪種基礎貨幣？",
        h23: "Reactor安全嗎？",
        h24: "退出主HD錢包後我的賬戶還在嗎？",
    },
    helpCenter: {
        h1: "幫助中心",
        h2: "尋找答案？答案可能就在這裡。",
        h3: '瀏覽主題',
        h4: "探索我們支持的文章和常見問題解答類別",
        h5: "什麼是Reactor？",
        h6: "Reactor 為用戶提供了準確的市場分析、量化策略和交易工具。通過 Reactor，用戶可以實現更高的投資回報率，並在活躍的社區中與其他量化交易者共同成長。",
        h7: "如何設置Reactor？",
        h8: "關於如何設置 Reactor 的各種基本功能的簡短指南。這是您作為新用戶開始的地方！",
        h9: "返佣",
        h10: "流動性平台",
        h11: "DEX量化",
        h12: "安全問題",
        h13: "會員權益",
        h14: "DEMO機器人",
        h15: "手動交易",
        h16: "DCA",
        h17: "追踪訂單",
        h18: "信號",
        h19: "錢包",
        h20: "複製機器人",
        h21: "鏈上數據",
        h22: "交易機器人",
        h23: "套利機器人",
        h24: "常見問題",
        h25: "我可以賺取多少利潤？",
        h26: "套利是如何運作的？",
        h27: "你們保證盈利嗎？",
        h28: "我該如何連接到交易所？",
        h29: "我可以在一個交易賬戶上開啟幾個機器人？",
        h30: "我應該使用哪種基礎貨幣？",
        h31: "Reactor安全嗎？",
        h32: "退出主HD錢包後我的賬戶還在嗎？",
    },
    interestRobot: {
        h1: "捕捉價差，實現穩定收益",
        h2: "利用市場間價格差異的智能套利機器人",
        h3: '查看更多',
        h4: "套利機器人",
        h5: "利用價格差異，進行跨交易所套利",
        h6: "支持同時連接多個交易平台和區塊鏈",
        h7: "包括但不限於幣安、歐易、火幣、以太坊、幣安智能鏈、波場等，實時監測的行情和價格變動。",
        h8: "市場掃描和比較",
        h9: "一旦發現價格差異，它會立即執行相應的交易操作。在一個平台上進行買入，同時在另一個平台上進行賣出，以獲得價格差的利潤",
        h10: "實時監測和報告",
        h11: "用戶可以查看詳細的交易記錄、利潤統計和圖表展示，以便全面評估套利交易的效果和潛在機會。",
        h12: "快速執行交易",
        h13: "我們致力於提供快速、高效的交易執行，確保用戶能夠及時抓住市場機會。",
        h14: "我們的交易系統經過精心設計和優化，能夠處理大量的訂單並保持快速響應執行交易，確保您能夠以最佳價格進行買賣操作。交易系統建立在穩定可靠的基礎設施上，具備高度的容錯性和冗餘機制。",
        h15: "作為Reactor的會員，您將享有專屬服務器支持，這將進一步提升交易的執行速度和效率。",
        h16: '分佈式節點',
        h17: "機房",
        h18: "服務器集群",
        h19: "免費提供專屬服務器",
        h20: "Reactor為專業級會員免費分配了專用的服務器資源，為您的機器人提供獨立且高效的運行環境",
        h21: "查看詳情",
        h22: "每日穩定賺取收益",
        h23: "設置硬幣和金額、選定的市場以及交易所套利設置和市場套利設置，讓機器人能夠準確捕捉不同交易所之間的價格差異，從中獲得套利機會。",
        h24: "同時，機器人還具備恢復和積壓功能，能夠靈活應對市場波動，確保您的投資持續增長。",
        h25: "了解更多",
    },
    join: {
        h1: "拓展您的 Reactor 探索之旅，選擇適合您的方案！",
        h2: "新手",
        h3: "免費",
        h4: "可使用1個模板策略",
        h5: "可創建1個策略組合",
        h6: "可執行40次策略組合",
        h7: "支持2個交易所",
        h8: "投資管理組合",
        h9: "開始吧",
        h10: "進階",
        h11: "月",
        h12: "可使用5個模板策略",
        h13: "可創建5個策略組合",
        h14: "可執行200次策略組合",
        h15: "支持5個交易所",
        h16: "投資管理組合",
        h17: "可複制跟踪5個機器人交易",
        h18: "免費使用模擬交易",
        h19: "開放MarketPlace策略",
        h20: "高級",
        h21: "可使用10個模板策略",
        h22: "可創建15個策略組合",
        h23: "可執行500次策略組合",
        h24: "支持20個交易所",
        h25: "投資管理組合",
        h26: "可複制跟踪20個機器人交易",
        h27: "獲取每月交易培訓課程2節",
        h28: "一對一定制化策略設計師服務",
        h29: "支持Reactor AI策略和AI設計師",
        h30: "支持申請成為合作策略師",
        h31: "免費進入VIP專屬社群",
        h32: "專業",
        h33: "無限使用模板策略",
        h34: "無限創建策略組合",
        h35: "無限次執行策略組合",
        h36: "不限交易所",
        h37: "投資管理組合",
        h38: "可複制跟踪50個機器人交易",
        h39: "每月交易培訓全部課程",
        h40: "一對一定制化策略設計師服務",
        h41: "支持Reactor AI策略和AI設計師",
        h42: "額外的技術指標指導",
        h43: "支持申請成為特邀策略師並共同分潤",
        h44: "專用服務器",
        h45: "專屬策略間隔檢查",
        h46: "會員等級比較",
        h47: "手動交易",
        h48: "交易機器人",
        h49: "複製機器人",
        h50: "做市商機器人",
        h51: "套利機器人",
        h52: "交易培訓課程",
        h53: "模擬交易",
        h54: "一對一定制化策略",
        h55: "申請成為合作策略師",
        h56: "VIP專屬社群",
        h57: "技術指標指導",
        h58: "申請成為特邀策略師並共同分潤",
        h59: "專用服務器",
        h60: "專屬策略間隔檢查",
    },
    manualTrading: {
        h1: "自主決策的權力",
        h2: "量化交易與手動交易的完美結合",
        h3: "查看更多",
        h4: "Reactor在自動化交易方面具有卓越的能力，但為什麼仍然需要手動交易的參與？",
        h5: "更全面和綜合的交易體驗",
        h6: "保留您的交易決策權",
        h7: "手動交易使用戶能夠保留最終的交易決策權，您仍然可以根據自己的判斷和情況，對交易進行最終決策。",
        h8: "事件驅動交易",
        h9: "某些事件和新聞公告可能會對市場產生重大影響，這些事件可能無法完全預測和適應於自動化交易系統。",
        h10: "專業判斷和經驗",
        h11: "經驗豐富的交易者俱有豐富的市場知識和專業判斷力，能夠根據自己的經驗做出更準確的決策。",
        h12: "快速應對市場風險，保護您的資產",
        h13: "緊急停止清倉",
        h14: "當市場行情不利時，用戶可以在Reactor觸發緊急停止清倉功能，通過一鍵操作將所有持倉資產賣出。",
    },
    market: {
        h1: "今天的加密貨幣市場",
        h2: "在 Reactor 平台上，您可以從10多個流動性平台中選擇數千種加密貨幣進行量化投資交易。我們與多家知名交易所建立了合作關係，為您提供廣泛的加密貨幣選擇。",
        h3: '加密貨幣市場',
        h4: "美元",
        h5: "24小時",
        h6: "幣安",
        h7: "歐易",
        h8: "火幣",
        h9: "幣種",
        h10: "價格",
        h11: "24小時漲跌",
        h12: "24小時成交量",
        h13: "市值",
        h14: "億",
        h15: "合計:608條,每頁顯示:20條",
    },
    merchantRobot: {
        h1: "Reactor 的做市商機器人",
        h2: "更好地參與市場，提高交易效果，並在投資過程中獲得更好的體驗",
        h3: '查看更多',
        h4: "Reactor 的做市商機器人是如何工作的？",
        h5: "Reactor的做市商機器人會監控多個交易所的市場行情、訂單狀況和交易活動。它會實時獲取最新的市場數據，包括買賣訂單的數量、價格和深度等信息並自動進行買入和賣出操作，根據市場條件和訂單簿的變化，動態調整報價和交易量。",
        h6: "做市商機器人的優勢",
        h7: "穩定的收益模式",
        h8: "持續的做市活動獲取交易差價收益，收益模式相對穩定",
        h9: "提供流動性",
        h10: "通過報價和提供買賣訂單，為市場提供流動性。",
        h11: "實時市場監控",
        h12: "根據市場波動和訂單狀況自動調整報價和流動性策略",
        h13: "自動化交易",
        h14: "根據市場條件實時調整報價和訂單",
        h15: "為什麼要在Reactor使用做市商機器人？",
        h16: "Reactor開啟的做市商機器人相較於傳統的普通做市商具有自動化執行、技術優勢、快速適應市場、數據分析和優化以及配置靈活性等優勢。",
    },
    policyBacktest: {
        h1: "策略回測",
        h2: "用戶根據自己的交易策略，在歷史市場數據上進行模擬交易，以評估策略的效果和潛在盈利能力。",
        h3: '查看更多',
        h4: "靈活選擇，精確回測",
        h5: "選擇交易對和時間段",
        h6: "您可以根據個人偏好和市場狀況，選擇合適的交易對和時間段能夠更準確地評估策略的表現和潛在風險。",
        h7: "找到最佳組合，提升策略表現",
        h8: "參數設置和優化",
        h9: "您可以靈活調整交易頻率、止盈止損條件等參數，以找到最佳的策略配置。不斷嘗試和優化參數組合，有助於提高策略的盈利能力和穩定性。",
        h10: "詳細報告，全面了解策略表現",
        h11: "回測結果分析和報告",
        h12: "Reactor會生成詳細的回測結果報告。報告中包括收益率、勝率、最大回撤等關鍵指標，讓您能夠全面了解策略的表現。幫助您發現策略的優勢和改進的空間，並做出相應的調整。",
        h13: "直觀展示，深入了解策略變化",
        h14: "圖表和指標展示",
        h15: "Reactor提供直觀的圖表和指標，讓您更好地理解策略回測的結果。您可以查看價格走勢圖、交易信號以及持倉情況等圖表，同時也可以利用各種技術指標來輔助評估策略的有效性。",
        h16: "量身定制的回測方案",
        h17: "Reactor為您提供私人定制的回測體驗：量身定制的回測方案、個性化的回測結果分析、實時策略調整和優化、定制化的交易建議",
        h18: "查看更多",
        h19: "Reactor策略回測的基本原理",
        h20: "基於歷史市場數據和設定的交易規則進行模擬交易，以評估和驗證策略的表現",
        h21: "歷史市場數據",
        h22: "Reactor使用過去的市場數據，包括價格、成交量等指標，構建一個模擬的歷史市場環境。",
        h23: "模擬交易執行",
        h24: "基於設定的交易規則，Reactor對歷史市場數據進行模擬交易執行。",
        h25: "回測結果評估",
        h26: "Reactor會根據回測期間的交易記錄計算關鍵指標，例如收益率、勝率、最大回撤等",
    },
    rebate: {
        h1: "分享鏈接，賺取額外收益與朋友一起賺幣！",
        h2: "通過分享提供的鏈接來邀請您的朋友。您的朋友在 Reactor 購買的每次訂閱您均可賺取高達 15%的佣金。",
        h3: '查看詳情',
        h4: "佣金可提現",
        h5: "進行無限制的推廣",
        h6: "推廣越多獲得獎勵越多",
        h7: "最高15%的佣金",
        h8: "為什麼選擇Reactor？",
        h9: "保護您的資產安全",
        h10: "Reactor採用最高級別的安全協議和加密技術，確保您的數字資產始終得到最強大的保護。",
        h11: "洞察市場趨勢",
        h12: "Reactor提供全面的數據統計和分析工具，幫助您深入了解市場趨勢和價格波動。並根據市場情況調整您的交易策略。",
        h13: "迅捷高效的交易體驗",
        h14: "Reactor提供高速的交易執行和訂單處理，確保您能夠快速響應市場變化，抓住交易機會。",
        h15: "我們將會保護您的隱私和好友信息",
        h16: "用戶的安全和隱私對我們來說至關重要。Reactor 配備頂級安全基礎設施，確保始終對託管資產進行全面保護。",
        h17: "查看隱私協議",
        h18: "如何使用推薦鏈接？",
        h19: "通過電子郵件或私信發送給好友。",
        h20: "分享在社交媒體上。",
        h21: "發佈在個人網站上。",
        h22: "在 Youtube 發布視頻並在評論中發布鏈接。",
        h23: "查看邀請規則",
    },
    signal: {
        h1: "自動化交易的智慧引擎",
        h2: "獲取高質量交易信號模版，實現穩定投資回報",
        h3: '查看詳情',
        h4: "高質量交易信號模版",
        h5: "經過嚴格的篩选和驗證的",
        h6: "獲取專業交易者的實際操作，包括買入點、賣出點、止損位等關鍵指標，訂閱後還可以自動複製",
        h7: "在Reactor的嚴格篩選下，信號的平均勝率達到58.6%",
        h8: "商城中有100+個信號提供者，他們是專業的交易者或機構。",
        h9: "了解更多",
        h10: "提交入駐申請",
        h11: "在商城中展示您的交易策略和專業知識，與全球的交易者分享您的成功經驗，從而拓展您的影響力和收益潛力。",
        h12: "聯繫我們",
        h13: "您可以通過這些數據更準確地選擇適合自己的信號",
        h14: "信號頻率",
        h15: "發送信號的頻率，以確定其適應您的交易偏好。",
        h16: "訂閱者數量",
        h17: "了解信號器的訂閱者數量，作為社區認可度的指標",
        h18: '評級和性能',
        h19: "最近3個月的信號數量、每筆交易的平均利潤等指標",
        h20: "統計和評論",
        h21: "隨時查看信號器的實時統計數據和其他用戶的評論",
    },
    strategy: {
        h1: "定制您的獨特交易策略",
        h2: "為您提供個性化的交易解決方案",
        h3: '聯繫我們',
        h4: "專屬的量化策略師",
        h5: "個性化的交易支持 和專業建議",
        h6: "成為特邀策略師 並共同分潤",
        h7: "專用服務器",
        h8: "個性化的交易支持和專業建議",
        h9: "我們深知每位客戶的投資目標和需求都獨一無二，因此我們將通過專屬策略師與您合作，確保您的交易策略與目標緊密契合。",
        h10: "策略",
        h11: "我們的策略師將與您一對一合作，深入了解您的投資目標、風險承受能力和時間要求。",
        h12: "為您量身定制的解決方案",
        h13: "我們的團隊將超預期的幫助您實現目標",
        h14: "私人客戶",
        h15: "希望實現您最雄心勃勃的投資目標？我們已協助無數客戶實現收益最大化、減少支出和增加投資組合資產。",
        h141: "企業",
        h151: "尋求更高的財務效率和投資回報率？我們與眾多企業合作，為您提供度身定制的解決方案，助您實現資金增長和風險管理的雙重目標。",
        h16: "企業",
        h17: "我們滿足您的每一個需求",
        h18: "Reactor 在我們領先的產品上擁有無與倫比的優勢",
        h19: "專屬的量化策略師",
        h20: "能夠根據您的需求和目標定制量化交易策略。他們將與您密切合作，深入了解您的投資偏好和風險承受能力，並根據市場趨勢和數據分析為您提供個性化的交易建議。",
        h21: "個性化的交易支持和專業建議",
        h22: "我們將深入了解您的交易目標和需求，為您提供針對性的交易策略和建議，幫助您優化交易決策。無論是技術分析、市場研究還是風險管理，我們將為您提供專業的指導和支持。",
        h23: "成為特邀策略師並共同分潤",
        h24: "作為特邀策略師，您將有機會分享您的成功交易策略和經驗，與其他用戶共同學習和成長。同時，您將享受到分潤的機會，通過與Reactor合作共同分享交易盈利。",
        h25: "專用服務器",
        h26: "我們的服務器具備高性能和低延遲的特點，能夠快速處理交易指令並確保交易的及時執行。通過使用專用服務器，您可以更好地把握交易機會，並提升交易的成功率和效果。",
        h27: "定制化服務",
        h28: "幫助您實現交易目標，並在量化交易領域取得更大的成功。無論您的交易風格、偏好或目標如何，我們都將竭誠為您提供最優質的定制化服務",
        h29: "聯繫我們",
    },
    // 策略商城
    strategyMall: {
        h1: "發現優質策略，實現交易突破",
        h2: "與頂級交易達人共同探索市場機會",
        h3: '查看詳情',
        h4: "策略商城",
        h5: "優化交易體驗",
        h6: "經過驗證的精選交易策略",
        h7: "一次購買終身使用，您可以獲取並持有所購買的交易策略使用權並且這個策略是可以下載的。",
        h8: "詳細的績效數據，包括歷史回報率、風險指標和交易頻率等信息。",
        h9: "我們提供詳細的交易歷史記錄，讓您全面了解每個策略的實際表現。",
        h10: "詳細了解策略商城",
        h11: "提交入駐申請",
        h12: "在商城中展示您的交易策略和專業知識，與全球的交易者分享您的成功經驗，從而拓展您的影響力和收益潛力。",
        h13: "聯繫我們",
        h14: "Reactor策略商城的優勢？",
        h15: "多元化的策略選擇",
        h16: "靈活的自主交易",
        h17: "專業策略師",
        h18: "無論您是偏好趨勢跟踪、波段交易還是套利策略，都能找到適合您的策略",
        h19: "能夠在保持策略的基礎上靈活調整交易參數，以適應不斷變化的市場條件",
        h20: "策略師擁有市場洞察力和成功的交易記錄，他們將為您的交易提供有力支持",
        h21: "了解更多",
        h22: "嚴格的策略監管",
        h23: "為確保交易策略的透明度和可靠性，每個策略在上線之前都需要經過我們的審核和測試流程。",
        h24: "經過時間驗證的策略",
        h25: "策略在我們的後台進行了長時間的實時模擬和回測，我們提供的歷史數據完全真實。",
    },
    business: {
        h1: "開創商機，共享成功",
        h2: "我們熱忱歡迎各行各業的合作夥伴與我們共同合作。通過參與我們的合作夥伴計劃，您將有機會成為我們的戰略夥伴，共同開創商業機會，實現共贏。",
        h3: '聯繫我們',
        h4: "合作夥伴支持和資源共享",
        h5: "作為合作夥伴，您將享受我們提供的全方位支持和資源。我們將提供專業的培訓和技術支持，以幫助您更好地了解我們的產品和服務，並能夠為客戶提供優質的解決方案。此外，您還將獲得我們的市場推廣支持，包括合作夥伴專屬的市場活動、營銷資料和品牌宣傳。我們將與您合作，共同推動業務增長，共享成功。",
        h6: "合作共贏，持續發展",
        h7: "我們重視與合作夥伴的緊密合作，相信通過共同努力和互相支持，我們能夠取得更大的成功。我們提供靈活的合作模式，包括合作夥伴銷售、分銷和推廣計劃，為合作夥伴創造豐厚的經濟回報。同時，我們鼓勵合作夥伴提供寶貴的反饋和建議，以幫助我們不斷改進和創新，共同發展。我們相信通過合作共贏的理念，我們將在競爭激烈的市場中持續發展。",
        h8: "加入我們",
        h9: "如果您對與我們合作夥伴計劃感興趣，我們非常歡迎您加入我們的行列。無論您是一家創新型初創企業、一家成熟的技術提供商，還是一家尋求新商機的公司，我們都願意與您合作，共同開創成功之路。加入我們的合作夥伴計劃，您將獲得與我們緊密合作的機會，共享我們的資源和經驗，共同拓展市場，實現共贏。",
    },
    dowView: {
        h1: "下載客戶端",
        h2: "探索量化交易的無限潛力，從容應對市場波動。",
        h3: '免費體驗',
    },
    contactView: {
        h1: "聯繫我們，或者預約參觀我們的辦公室",
        h2: "深圳市寶安區新安街道寶民一路寶通大廈608",
        h3: '立即諮詢',
    },
    helpView: {
        h1: "需要更多幫助？",
        h2: "給我們的團隊留言，我們會在工作時間與您取得聯繫。",
        h3: '聯繫支持',
    },
    token: {
        h1: "合約",
        h2: "市場統計",
        h3: '市值排名',
        h4: "市值",
        h5: "億",
        h6: "24小時成交量",
        h7: "24小時最高",
        h8: "歷史最高價",
        h9: "最大供應量",
        h10: "以太坊（ETH）實時行情",
        h11: "當前以太坊價格為 1,786.68 美元，在過去 24 小時內其價值下跌了 0.31%。以太坊較歷史高位 4,878.26 美元下跌 64.33%。以太坊在市值方面排名第二，佔整個加密市場的 19.08%。以太坊是一種去中心化貨幣，您可以在Reactor通過中心化&去中心化流動性平台購買、出售和對ETH進行量化交易。",
        h12: "什麼是以太坊",
        h13: "以太坊是一個去中心化的區塊鏈平台，使開發人員能夠在其區塊鏈之上構建去中心化應用程序 (dApp)。以太坊於 2015 年由 Vitalik Buterin 推出，憑藉其強大的生態系統、活躍的社區和創新功能，已成為世界上最受歡迎的區塊鏈平台之一。以太 (ETH) 是以太坊的原生加密貨幣，在平台上有多種用途。它可用於在以太坊網絡上支付Gas,並作為參與平台治理系統的一種方式。以太坊的兩個最重要的特徵是它的互操作性和可擴展性。前者指的是以太坊能夠與其他區塊鍊網絡和平台連接和通信，從而實現更強大的功能和兼容性。另一方面，可擴展性是指以太坊在不影響其性能或安全性的情況下處理大量交易和數據的能力。2022 年 9 月，以太坊經歷了一次名為 The Merge 的重大升級，因其複雜性而被稱為該行業最重要的升級事件之一。它涉及從工作量證明共識算法到更高效的權益證明算法的過渡，目的是降低能耗，進一步提高可擴展性，並提高安全性。因此，這要求通過質押而不是挖礦來驗證以太坊上的交易。儘管期待已久，合併只是以太坊為完成 Vitalik 的願景而必須進行的一系列重大更新的開始。",
        h14: "為什麼 Reactor 是量化交易的正確",
        h15: "多元化投資",
        h16: "將資金分散於交易所和去中心化錢包，有效降低風險並提高潛在收益。",
        h17: "支持模擬賬戶",
        h18: "提供真實市場模擬環境，讓您以零風險的方式進行投資實踐和策略測試。",
        h19: "交易數據統計",
        h20: "您可以放心地使用我們的機器人進行交易，享受便捷和安全的投資體驗。",
        h21: "瀏覽其他資產",

        BTC0: '比特幣是一種去中心化的加密貨幣。在2008年發布的白皮書中，由某個人或某個群體化名為中本聰（Satoshi Nakamoto），首度探討了比特幣的機制。2009年1月，比特幣正式問世。',
        BTC1: '比特幣是一種基於點對點網絡的貨幣，所有交易都是在平等獨立的網絡參與者之間直接進行，而無需任何中間方的許可或促成。用中本聰自己的話來說，創造比特幣就是為了讓“一方無需通過金融機構就能直接對另一方在線付款”。',
        BTC2: '在比特幣出現之前，出現過一些類似的去中心化電子貨幣概念，但比特幣的獨特之處在於，它是有史以來首個被應用於現實生活中的加密貨幣。',

        ETH0: '以太坊是一個去中心化開源區塊鏈系統，它擁有自己的原生加密貨幣——以太幣。以太坊還是很多其他加密貨幣的交易平台，同時它也可以執行去中心化智能合約。',
        ETH1: 'Vitalik Buterin在發表於2013年的白皮書中首次介紹了以太坊。Buterin先生和其他聯合創始人一起，於2014年夏天通過項目線上眾籌的方式，在2015年7月30日正式推出了以太坊區塊鏈。',
        ETH2: '以太坊聲稱，其目的是成為一個全球性去中心化應用平台，讓全世界的使用者都能編寫和運行軟件，而無需被審查、不會出現故障停機以及欺詐行為。',

        USDT0: '泰達幣(USDT)是一種數字貨幣，其價值意在反映美元的價值。 泰達幣於2014年推出，其目的是創建一種穩定的加密貨幣，可像數字美元或“穩定幣”一樣使用。泰達幣的價格與美元錨定。',
        USDT1: '泰達幣最開始使用比特幣網絡的Omni層作為其傳輸協議，現在它可以在以太坊上作為ERC-20代幣使用。泰達幣已經在比特幣(Omni和Liquid協議)、以太坊、EOS、波場區塊鏈上發行。',
        USDT2: '泰達幣由Tether Limited發行，這家公司與加密貨幣交易所Bitfinex擁有同一個CEO。Tether曾聲稱為泰達幣儲備了100%的美元準備金。2019年，該公司的律師表示只有74%的泰達幣有準備金做支撐。對此Tether的解釋是，其子公司的貸款也屬於儲備金的一部分。',

        BNB0: '幣安幣於2017年通過首次代幣發行推出，11天之後，幣安加密貨幣交易所上線。幣安幣最初以ERC-20代幣形式在以太坊網絡中發行，最大總供應量為2億枚代幣，在首次代幣發售中發行了1億枚代幣。然而，隨著2019年4月幣安鏈主網上線，ERC-20幣安幣與BEP2幣安幣按1：1的比率兌換，現在不再托管在以太坊上。',
        BNB1: '幣安幣作為一種支付方式，用戶可以使用這種實用型代幣，在幣安交易所抵扣手續費，並參與幣安資產發行平台（LaunchPad）上的代幣銷售。幣安幣還是幣安去中心化交易（DEX）的主要貨幣。',

        XRP0: '首先，要了解瑞波幣、瑞波和瑞波網（RippleNet）之間的區別。瑞波幣是在數字支付平台——瑞波網上運行的貨幣。瑞波網是基於一種分佈式賬本數據庫（也叫做瑞波賬本（XRP Ledger））創建的。雖然瑞波網由瑞波（Ripple）公司運營，但瑞波賬本卻是開源的，並未採用區塊鏈技術，而是根據之前提到的分佈式賬本數據庫創建的。',
        XRP1: '瑞波網支付平台是一個實時全額支付（RTGS）系統，旨在實現全球範圍內的即時貨幣交易。雖然瑞波幣是瑞波賬本的原生加密貨幣，用戶仍然可以在該平台上使用任何一種貨幣進行交易。',
        XRP2: '瑞波支付平台背後的理念由Ryan Fugger於2004年首次提出，但當Jed McCaleb和Chris Larson在2012年接管了項目後，創建瑞波公司的計劃才提上日程（當時，瑞波也被稱為OpenCoin）。',
        USDC0: 'USDC 是一種穩定幣，與美元以 1:1 的比例掛鉤。這種加密貨幣的每一個流通單位都有 1 美元的儲備作為支持，其中包括現金和短期美國國債。該資產背後的中心財團表示，USDC 是由受監管的金融機構發行的。',
        USDC1: '該穩定幣最初於2018年9月限量發行。簡單地說，美元幣的口號是 "數字時代的數字貨幣"--這種穩定幣是為無現金交易越來越普遍的世界而設計的。',
        USDC2: '美元幣已經發布了幾種使用案例。除了在波動時期為加密貨幣交易者提供安全避風港之外，穩定幣的幕後推手還表示，它還可以讓企業接受數字資產支付，並撼動包括去中心化金融和遊戲在內的一系列行業。',

        DOGE0: '狗狗幣（DOGE）的名稱源於網絡流行語“DOGE”，它的logo上有一隻柴犬。這一開源型數字貨幣由美國俄勒岡州波特蘭市的比利·馬庫斯(Billy Markus)和澳大利亞悉尼市的傑克遜·帕爾默(Jackson Palmer)共同創造，並在2013年12月由萊特幣中派生出來。狗狗幣的創始人們將其設定為一種輕鬆有趣的虛擬貨幣，希望它能吸引比特幣核心受眾之外的更多用戶，畢竟它的形象來源於在網上超火的表情包"doge"。Telsa公司的CEO埃隆·馬斯克曾數次發推說狗狗幣是他的最愛。',
        ADA0: 'Cardano是采用權益證明機制的區塊鏈平台，其宣稱的目標是讓“改變者、創新者和遠見者”帶來積極的全球性改變。',
        ADA1: '這個開源項目還有一個目標，就是“將權力從不負責任的機構處重新分配給個人”——以致力於創造一個更加安全、透明和公平的社會。',
        ADA2: 'Cardano成立於2017年，ADA（艾達幣）代幣的設計是為了保證其擁有者能參與網絡運營。因此，持有該加密貨幣的人有權對任何改進提議進行投票表決。',
        ADA0: '該團隊認為，其技術旨在使用模塊化概念開發去中心化app和智能合約，目前已有一些非常好的使用案例，',
        ADA4: '農業公司可以使用Cardano追蹤從土地出產的新鮮農產品達到餐桌的流程，而該平臺的其他產品則可以完好保存教育資格證明不受篡改，或者為零售商們取締假冒商品提供支持。',

        SOL0: 'Solana是一個功能強大的開源項目，它利用區塊鏈技術的無權限特性提供去中心化金融（DeFi）解決方案。雖然該項目的想法和初期工作始於2017年，但Solana於2020年3月由總部位於瑞士日內瓦的Solana基金會正式推出。',
        SOL1: '要了解有關該項目的更多信息，請查看我們對 Solana 的深入研究。',
        SOL2: 'Solana 協議旨在促進去中心化應用程序（DApp）的創建。它旨在通過引入歷史證明（PoH）共識與區塊鏈的底層權益證明（PoS）共識相結合來提高可擴展性。',

        TRX0: '波場幣(TRX)由孫宇晨(Justin Sun)於2017年通過波場基金會創立，該基金會總部位於新加坡。2017年，波場基金會的首次代幣發行（ICO）創造了1000億枚TRX，共籌集了7000萬美元。',
        TRX1: '波場協議對整個操作系統的架構進行了描述，該系統基於區塊鏈技術，能夠讓開發人員創建智能合約和去中心化應用(DApps)，自由發布、擁有和存儲數據以及其他內容。根據波場基金會的說法，圍繞這個網絡的生態系統專門提供大規模的可擴展性和一致的可靠性，能夠通過高吞吐量計算來高速處理事務。',
        TRX2: '波場幣原本基於以太坊網絡創建，在2018年遷移到了自己的網絡上。ERC-20波場幣持有者將代幣換成了波場網絡上的TRX數字貨幣，以太坊上的波場代幣隨即被銷毀。',

        Matic0: 'Polygon （前身為 Matic Network）是首個結構合理、易於使用的以太坊擴展和基礎設施開發平台。其核心組件是 Polygon SDK，這是一個模塊化的靈活框架，支持構建多種類型的應用程序。',
        Matic1: '要了解有關此項目的更多信息，請查看我們對 Polygon Matic 的深入研究。',
        Matic2: '使用 Polygon，可以創建樂觀卷積鏈、ZK 卷積鏈、獨立鏈或開發人員需要的任何其他類型的基礎設施。',

        LTC0: '萊特幣（LTC）是一種加密貨幣，旨在通過使用區塊鏈技術的特性，提供快速、安全且低成本的支付功能。',
        LTC1: '該加密貨幣是基於比特幣（BTC）協議創建的，但在哈希算法、硬頂（Hard Cap）、區塊交易時間和一些其他因素上有所不同。萊特幣的出塊時間僅需2.5分鐘，且交易費用極低，因此非常適合小額交易和銷售點（point-of-sale）支付方式。',
        LTC2: '萊特幣於2011年10月7日由一位開源用戶在GitHub上推出，五天後，即2011年10月13日，萊特幣網絡正式上線。從那時起，其在商家中的使用量和接受度開始暴增，自問世以來，萊特幣長期維持在市值排名前10的加密貨幣。',
        LTC3: '該加密貨幣由前谷歌員工李啟威（Charlie Lee）創建，他希望把萊特幣做成比特幣的精簡版，因此開發了很多與比特幣相同的特性（除了重要性和通用性更小一些）。',
        AVAX0: '雪崩是一種第一層區塊鏈，可作為去中心化應用程序和定制區塊鏈網絡的平台。它是以太坊的競爭對手之一，旨在取代以太坊成為最受歡迎的智能合約區塊鏈。它的目標是在不影響可擴展性的前提下，實現高達每秒 6,500 次的交易輸出。',
        AVAX1: '這得益於 Avalanche 獨特的架構。雪崩網絡由三個獨立的區塊鏈組成：X 鏈、C 鏈和 P 鏈。每條鏈都有不同的目的，這與比特幣和以太坊使用的方法（即讓所有節點驗證所有交易）截然不同。雪崩區塊鏈甚至根據其使用情況使用不同的共識機制。',
        AVAX2: '在 2020 年推出主網後，雪崩一直致力於開發自己的 DApps 和 DeFi 生態系統。不同的基於以太坊的項目，如 SushiSwap 和 TrueUSD，都已與 Avalanche 集成。此外，該平台還在不斷努力提高自身生態系統與以太坊之間的互操作性，例如通過開發橋接器。',
        
        ETC0: '以太坊經典（ETC）是以太坊（ETH）的硬分叉，於 2016 年 7 月推出。其主要功能是作為一個智能合約網絡，能夠托管和支持去中心化應用程序（DApps）。其原生代幣為 ETC。',
        ETC1: '自推出以來，以太坊經典一直在尋求與以太坊的差異化，隨著時間的推移，這兩個網絡的技術路線圖也越來越背離。',
        ETC2: '在一次重大黑客事件導致 360 萬以太坊被盜後，以太坊經典首先著手維護現有以太坊區塊鏈的完整性。',
    
        ARB0: '',
    
        SUI0: 'Sui 是一種低延遲、高吞吐量的第一層區塊鏈。其即時交易結算能力，使 Sui 成為鏈上用例（如 DeFi和 GameFi）的適當區塊鏈。該區塊鏈是用Rust編寫的（Rust是一種專注於快速和安全交易執行的編程語言）。Rust 也是另一種高速區塊鏈 Solana 采用的編程語言。',
        SUI1: 'Sui 宣稱的目標是：要通過比以太坊等區塊鏈競爭對手更卓越的智能合約架構，來佈署去中心化應用 (DApp），以“帶領下一批十億用戶來到 Web 3”。區塊鏈利用一種稱為“交易並行化”的過程來實現這一點，它允許在“並行協議”中處理交易。正如 Sui 所稱的那樣，這種“並行擴展”可以在拜占庭容錯權益證明(PoS)共識機制中實現更好的數據組織方式。',

        DAI0: '',

        LINK0: '',

        UNI0: 'Uniswap 是一種流行的去中心化交易協議，因其在促進去中心化金融（DeFi）代幣自動交易方面的作用而聞名。',
        UNI1: '作為自動做市商（AMM）的一個範例，Uniswap 於 2018 年 11 月推出，但由於 DeFi 現象和相關代幣交易的激增，它在今年獲得了相當高的人氣。',
        UNI2: 'Uniswap 旨在保持代幣交易的自動化，並對持有代幣的任何人完全開放，同時提高與傳統交易所相比的交易效率。',
        UNI3: 'Uniswap 通過自動化解決方案解決流動性問題，避免了困擾第一批去中心化交易所的問題，從而提高了效率。',
        UNI4: '2020 年 9 月，Uniswap 更進一步，創建了自己的治理代幣 UNI，並授予協議的過去用戶。這既增加了盈利潛力，又讓用戶有能力塑造其未來--這是去中心化實體的一個吸引人的方面。',

        EOS0: '',

        OP0: '',
          
    },
    wallet: {
        h1: "Web3的量化交易錢包",
        h2: "解鎖無限交易潛能，實現卓越的量化交易體驗。",
        h3: '免費體驗',
        h4: "多元化投資",
        h5: "資產安全保障",
        h6: "快速、高效的交易體驗",
        h7: "Reactor 錢包量化的優勢",
        h8: "錢包量化提供更加安全、靈活和個性化的交易體驗",
        h9: "個人資產安全護航",
        h10: "Reactor 使用個人錢包賬戶進行交易，相比交易所您擁有更大的控制權和安全性。",
        h11: "融合量化，無縫跨越多個區塊鏈",
        h12: "Reactor 通過集成多個區塊鍊網絡，實現無縫的資產轉移和交易，不受單一鏈的限制，獲得更多的投資機會。",
        h13: "智能低Gas費路線",
        h14: "Reactor 可以自動分析當前網絡情況，並選擇最低的gas費路線來執行交易，以減少交易成本。",
        h15: "個人資產安全護航",
        h16: "Reactor 使用個人錢包賬戶進行交易，相比交易所您擁有更大的控制權和安全性。",
        h17: "創建您的主HD錢包，開啟Web3世界的探索",
        h18: "主HD錢包是您獨一無二的身份，可連接到多個非託管錢包",
        h19: "通過主HD錢包，您可以方便地管理和控制多個非託管錢包，無需為每個錢包創建和記憶不同的私鑰和密碼。",
        h20: "主HD錢包提供詳盡的數據統計功能，讓您了解您的交易歷史、資產分佈和收益情況，幫助您做出更明智的投資決策。",
        h21: "主HD錢包具有高度的靈活性，可根據您的需求和偏好進行個性化設置，讓您更好地定制和管理您的數字資產。",
        h22: "定制化策略",
        h23: "Reactor 可以根據自己的需求和策略進行定制化的交易。您可以根據自己的交易策略和風險偏好進行量化交易，而不受交易所API的限制",
        h24: "多鏈環境強力支持",
        h25: "Reactor 能夠在不同的鏈上進行交易。這為您提供了更多的選擇和靈活性，可以根據市場情況和資產配置進行交易",
    },
}