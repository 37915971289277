module.exports = {
    topBar: {
        linkWallet: "Reactor에서 첫 번째 로봇을 무료로 시작합니다.자세히 알아보기",
        unit: "달러",
        h1: "첫 페이지",
        h2: "제품",
        h2_1: "k선의 재측정 능력",
        h2_1_1: "정책 재테스트",
        h2_1_2: "모의거래 재테스트 진행",
        h2_1_3: "수동 거래",
        h2_1_4: "체인에 가격표 달기",
        h2_1_5: "K선 및 체인 데이터",
        h2_1_6: "거래 K라인 및 체인 데이터의 실시간 조회",
        h2_2: "로봇의 다양한 종류",
        h2_2_1: '복제 로봇',
        h2_2_2: "주문 전문 트레이더",
        h2_2_3: "거래 로봇",
        h2_2_4: "24시간 근무, 정서적 거래 배제",
        h2_2_5: "시장사 로봇",
        h2_2_6: "24시간 근무, 정서적 거래 배제",
        h2_2_7: "차익 실현 로봇",
        h2_2_8: "거래소 간 차익 실현",
        h2_2_9: "DEMO 로봇",
        h2_2_10: "위험 없이 거래 경험치 획득",
        h2_3: "모델 쇼핑몰 오픈 즉시 사용",
        h2_3_1: "신호 상점",
        h2_3_2: "더 정확한 거래 의사 결정",
        h2_3_3: "전략 쇼핑몰",
        h2_3_4: "프로처럼 거래",
        h2_3_5: "로봇 쇼핑몰",
        h2_3_6: "각종 자동화 로봇",
        h3: "시장",
        h3_1: "Token",
        h3_2: "유동성 플랫폼 지원",
        h3_2_1: "코인",
        h3_2_2: "Binance는 세계에서 가장 많이 거래되는 암호화폐 거래소이며 2022 년 8 월 현재 Binance 거래는...",
        h3_2_3: "화폐",
        h3_2_4: "Huobi Global은 100 개국 이상에서 운영되는 국제 디지털 자산 거래소입니다.이 플랫폼은 2013...",
        h3_2_5: "오이",
        h3_2_6: "OKX는 세이셸에 위치한 암호화폐 중앙 집중식 거래 (CEX) 및 지갑 플랫폼으로 전 세계 수백만 명의 고객을 위해...",
        h3_2_7: "PancakeSwap",
        h3_2_8: "PancakeSwap는 업계 최고의 비관리형 탈중심화 거래소 (DEX) 의 개발자로 첨단 플러스에 집중합니다...",
        h3_2_9: "UniSwap",
        h3_2_10: "Uniswap은 탈중심화 거래소 (DEX) 이며, 먼저 자동 시장 조성자 (AMM) 모델을 도입했습니다.....",
        h3_2_11: "DODO",
        h3_2_12: "Dodo는 이더리움 기반의 탈중심화 거래소로,주동적인 시장 조성자, 즉 자동 시장 조성자 (AMM...",
        h3_3: "지원되는 모든 자산 보기",
        h3_3: "더 많은 거래 플랫폼",
        h4: "가격",
        h5: "기업",
        h5_1: "우리에 관하여",
        h5_2: "탁월한 서비스, 지속적인 혁신. 고객과 긴밀히 협력하여 고품질 솔루션을 제공합니다.장기적인 협력 관계를 구축하고 함께 성장하십시오.",
        h5_3: "OEM",
        h5_4: "특별한 요구 사항을 충족하고 개인화된 서비스를 제공하는 전문 맞춤형 솔루션",
        h5_5: "비즈니스 운영",
        h5_6: "효율적인 관리 및 확장을 통해 지속적인 성장과 시장 선도를 달성할 수 있습니다.",
        h5_7: "소셜 미디어",
        h5_8: "우리의 소셜 미디어 채널을 발굴하고 최신 동태를 추적하며 업계의 최전방과 혁신 추세를 함께 탐색합니다.",
        h5_9: "파트너",
        h5_10: "손잡고 함께 창조하고 자원과 기회를 공유하며 호혜상생을 실현하고 공동으로 성공의 길로 나아간다.",
        h5_11: "연락해주세요",
        h5_12: "우리와 연락하여 비즈니스 기회를 논의하고 유익한 협력 관계를 위해 협력합시다.",
        h6: "도움말",
        h7_1: "중국어 간체",
        h7_2: "영어",
        h7_3: "일본어",
        h7_4: "한국어",
        h7_5: "중국어 번체",
        h7_6: "태국어",
        h7_7: "말레이어",
        h7_8: "베트남어",
        h8: "지갑 링크",
        h9: "자세히",
        h9_1: "리베이트",
        h9_2: "링크를 통해 친구를 우리 플랫폼에 초대하면 추가 커미션 보상을 받을 수 있는 기회가 주어집니다.",
        h9_3: "지갑",
        h9_4: "안전하고 신뢰할 수 있으며 보관이 편리하고 디지털 자산을 쉽게 관리할 수 있으며 금융 보안 및 개인 정보 보호를 보장합니다.",
        h9_5: "맞춤 전략",
        h9_6: "귀하의 투자 목표에 대한 심층적인 이해와 귀하의 필요에 맞는 맞춤형 투자 전략.",
        h9_7: "DCA",
        h9_8: "지능형 고정 투자, 안정적인 부가가치, 시장 변동 위험 방지, 안정적인 장기 보유 수익.",
    },
    btm: {
        h1: "제품",
        h2: "지갑",
        h3: '시장',
        h4: "신호",
        h5: "체인의 데이터",
        h6: "맞춤 전략",
        h7: "리베이트",
        h8: "로봇",
        h9: "거래 로봇",
        h10: "데모 로봇",
        h11: "로봇 복사",
        h12: "차익거래 로봇",
        h13: "마켓 메이커 로봇",
        h14: "회사 소개",
        h15: "OEM",
        h16: "광고 협력",
        h17: "사업 운영",
        h18: "소셜 미디어",
        h19: "파트너",
        h20: "커뮤니티",
        h21: "서비스",
        h22: "다운로드",
        h23: "서비스 계약",
        h24: '개인정보 동의',
        h25: "위험 경고",
        h26: "도움말 센터",
        h27: "우리를 따라와",
        h28: "로컬 다운로드",
        h29: "기업 신용 평가 AAA급 신용 기업",
        h30: "입찰 및 입찰 분야 국가명예증서",
        h31: "중화인민공화국 상무부 무결성 기록 보관소 기업",
        h32: "정도경영 실증기업",
        h33: "광동 공용 네트워크 보안 번호 44030502009469 광동 ICP 번호 2022043961-1 부가 통신 사업 허가 B1-20214279 광동 인터넷 텍스트: (2018) 번호 5045-1753 © 2018 - 2023 TIANYI. 모든 권리 보유",
        h34: '데스크톱 클라이언트',


        A1: '전략 백테스팅',
        A2: '수동 거래',
        A3: 'K선과 블록체인 데이터',
        A4: '신호 상점',
        A5: '전략 상점',
        A6: '거래 로봇',
        A7: '데모 로봇',
        A8: '복사 거래 로봇',
        A9: '애드 아비트리지 로봇',
        A10: '메이커 메이킹 로봇',
        A11: '회사 정보',
        A12: 'OEM (오리지널 장비 제조업체)',
        A13: '소셜 미디어',
        A14: '연락처 정보',
        A15: '개인 정보 보호 정책',
        A16: '서비스 계약',
        A17: '책임 면제 성명',
        A18: '도움말 센터',
        A19: '맞춤 전략',
        A20: '송금 수수료',
        A21: '지갑',
        A22: 'DCA (달러 평균 가격 할당)',
        A23: '다운로드',
        A24: "더 많은",


    },
    home: {
        h1: "자동 거래, 저가 매수 및 고가 매도",
        h2: "많은 기능을 모은 스마트 트레이딩 단말기",
        h3: '맞춤형 디지털 통화 거래 로봇',
        h4: "무료 평가판",
        h5: "중앙화 거래소 및 탈중앙화 거래소 지원",
        h6: "분산 투자",
        h7: "전문 수량화",
        h8: "인디케이터 파라미터",
        h9: "거래 데이터",
        h10: "초대 리베이트",
        h11: "지갑과 거래소 계정을 연결하고 거래 터미널로 관리하세요",
        h12: "우리는 투자 포트폴리오에 대한 다양한 투자의 중요성을 알고 있습니다. Reactor를 사용하면 동시에 여러 거래소와 지갑을 쉽게 관리하고, 자금을 보다 유연하게 분산하고, 다양한 자산과 시장에 투자를 분산하고, 효과적으로 위험을 단일로 줄일 수 있습니다. 자산 또는 시장.",
        h11_1: "정확한 분석, 탁월한 거래 효과",
        h12_1: "Reactor는 정확한 시장 분석 및 거래 전략 최적화를 수행하여 우수한 거래 결과를 달성할 수 있습니다. 고급 알고리즘 및 데이터 지표를 사용하여 거래 효율성을 개선하여 시장을 더 잘 통제하고 보다 안정적인 수익을 얻을 수 있습니다.",
        h11_2: "유연한 사용자 정의, 거래 지표 최적화",
        h12_2: "유연한 지표 매개변수 설정을 제공하고 개인의 필요에 따라 거래 전략을 사용자 정의합니다. 지표 매개변수를 조정하여 거래 성과를 최적화하고 시장 변화에 더 잘 적응하며 개인화된 거래 경험을 실현하고 더 나은 투자 수익을 얻습니다.",
        h11_3: "실시간 모니터링, 정확한 시장 역학 파악",
        h12_3: "실시간 데이터를 통해 보다 정확한 거래 결정을 내리고 적시에 투자 기회를 포착할 수 있습니다. 더 이상 중요한 순간을 놓치지 않고 데이터 모니터링을 통해 시장 역학을 정확하게 파악할 수 있습니다.",
        h11_4: "돈을 벌기 위해 공유하고, 리워드를 초대하세요",
        h12_4: "플랫폼에 친구를 초대하면 초대 보상의 최대 15%를 받을 수 있습니다. 초대 리베이트 기능을 통해 친구와 거래의 즐거움을 공유할 수 있으며 동시에 상당한 리베이트 수입도 얻을 수 있습니다.",
        h13: "자세히 알아보기",
        h14: "지원 네트워크",
        h15: "지원됨",
        h16: "유동성 플랫폼",
        h17: "암호화폐",
        h18: "처리 완료",
        h19: "거래 주문",
        h20: "프로처럼 거래하기에 이상적",
        h21: "Reactor에서 집계된 유동성 플랫폼을 사용하여 암호화폐를 거래하고, 시장 주문을 수행하고, 체인에서 주문 거래를 제한하고, 자동화된 거래 전략을 실행하고, 거래 데이터를 봅니다.",
        h22: "전문 팀 및 기술",
        h23: "우리는 항상 고객의 이익을 최우선으로 투명하고 신뢰할 수 있는 서비스를 제공하기 위해 최선을 다하고 있습니다. 우리의 투자 전략은 귀하에게 최고의 투자 기회를 제공할 수 있도록 충분한 조사와 실사를 기반으로 합니다.",
        h24: '당신의 자금과 알고리즘은 안전합니다',
        h25: "지갑",
        h26: "우리는 당신의 개인 키 정보를 기록하지 않을 것이며, 로봇은 거래소와 지갑의 보안 기준을 엄격히 준수할 것입니다",
        h27: "인증",
        h28: "자산 및 거래 정보의 보안을 보장하기 위해 여러 인증 및 암호화 조치를 채택하십시오",
        h29: "안전한 거래",
        h30: "당신은 우리 로봇을 사용하여 안심하고 거래하고 편리하고 안전한 투자 경험을 즐길 수 있습니다.",
        h31: "모든 암호화된 자산 포트폴리오를 편리하게 관리할 수 있는 암호화폐 거래 단말기",
        h32: "저희 거래 터미널은 여러 거래소와 지갑의 기능을 통합하여 통합 인터페이스에서 자산 관리 및 거래 작업을 수행할 수 있도록 합니다.",
        h33: "블록체인의 무한한 가능성을 함께 탐색하자",
        h34: "우리는 포괄적인 블록체인 솔루션과 전문적인 컨설팅 서비스를 제공합니다.우리 팀은 블록체인의 핵심 개념을 이해하고 핵심 기술과 도구를 파악하며 블록체인을 실제 장면에 적용하는 방법을 탐구하는 데 도움이 되는 풍부한 블록체인 경험과 전문 지식을 가지고 있습니다.",
    },
    about: {
        h1: "TIANYI는 서버/네트워크의 최하위 계층에서 애플리케이션 계층까지 디지털 엔지니어링 솔루션을 개발하는 공급업체입니다.",
        h2: "우리는 전 세계의 디지털 크리에이티브 커뮤니티입니다.",
        h3: '저희 팀은 경험이 풍부하고 열정적인 전문가 그룹으로 구성되어 있습니다. 응용 프로그램 개발, Web3, 시각 디자인 및 창의성, 응용 프로그램 디자인 및 계획, 서버/네트워크 및 컴퓨터실, 마케팅 및 사무 시스템을 포함한 많은 분야에서 당사의 전문 솔루션 및 창의적인 서비스를 제공합니다. ',
        h4: "원스톱 서비스",
        h5: "기획, 디자인, 개발, 운영까지 모든 영역을 포괄하는 원스톱 서비스를 제공합니다.",
        h6: "글로벌 스탠다드",
        h7: "글로벌 표준 디자인, 코드 없는 도구 사용, NFT 및 Metaverse와 같은 Web3tran 기술을 적극적으로 도입합니다.",
        h8: "서버 및 IDC 네트워크",
        h9: "우리는 고성능 서버 솔루션과 확장 가능한 IDC 네트워크 아키텍처를 제공하여 귀하의 비즈니스가 원활하고 안전하며 안정적으로 운영되도록 합니다.",
        h10: "미션, 비전 및 가치",
        h11: "미션",
        h12: "디지털 창의성의 힘을 사용하여 문제를 해결하고 사회에 지속적으로 기여합니다. 우리의 사명은 혁신과 창의성을 통해 사회에 의미 있는 솔루션을 제공하고 사회 발전을 주도하는 것입니다.",
        h13: "저희에게 연락하거나 저희 사무실 방문 약속을 잡으십시오",
        h14: "심천 바오안구 신안가 바오민 1로 바오통 빌딩 608호",
        h15: "지금 상담하세요",
    },
    // chainData: {
    //     h1: "투명하고 신뢰할 수 있는 온체인 데이터 표시",
    //     h2: "블록체인의 무한한 가능성을 탐구하다",
    //     h3:'세부정보 보기',
    //     h4: "블록체인의 미래에서 기회를 잡아라!",
    //     h5: "저희 제품은 블록체인의 무한한 잠재력과 투명성을 깊이 이해하고 보여줄 수 있는 특별한 기회를 제공합니다.",
    //     h6: "거래 내역에 대한 심층적 이해",
    //     h7: "체인의 데이터 표시 및 분석을 통해 다양한 거래를 추적 및 분석하고 시장 동향 및 거래자 행동을 이해할 수 있습니다.",
    //     h8: "자산 흐름 추적",
    //     h9: "체인의 데이터 시각화를 통해 자산 이전 경로 및 홀더 변경과 같은 중요한 정보를 추적할 수 있습니다.",
    //     h10: "스마트 계약 분석",
    //     h11: "우리 도구를 사용하면 스마트 계약 실행을 심도 있게 조사하고 분석하여 계약의 보안과 신뢰성을 평가할 수 있습니다.",
    // },
    chainData: {
        h1: "체인의 K 라인 및 데이터",
        h2: "시장 동향에 대한 통찰력의 완벽한 조합",
        h3: '역사적 시장과 실시간 블록체인 정보의 통합',
        h4: "거래 정보 최적화",
        h5: "세부정보 보기",
        h6: "K-라인과 온체인 데이터 통합의 탁월한 이점",
        h7: "Reactor에서 시장에 대한 실시간 통찰력, 정확한 의사 결정 트랜잭션",
        h8: "블록체인 네트워크에서 과거 가격 추세 표시 및 거래 데이터 추적",
        h9: "최신 블록체인 트랜잭션 기록 블록 높이 및 트랜잭션 확인 시간 확인",
        h10: "K-line 및 on-chain 데이터 분석을 기반으로 정확한 거래 신호 생성",
        h11: "K-라인 및 체인 데이터를 표시하는 직관적인 차트 및 그래픽",
        h12: "각 자산의 포지션 현황, 자산 배분 비율, 시가 변동 확인",
        h13: "리액터 전용도 있습니다",
        h14: "거래 의사 결정의 통찰력과 효율성 향상",
        h15: "다중 사슬",
        h16: "사용자 지정 가능",
        h17: "신호",
        h18: "Reactor는 Ethereum, Wave Field, Binance Smart Chain 등과 같은 여러 블록체인 네트워크에서 트랜잭션을 지원합니다.",
        h19: "개인화된 거래 경험을 달성하기 위해 거래 전략, 위험 통제 규칙 및 테스트 설정을 사용자 정의할 수 있습니다.",
        h20: "K-라인 차트에 포인트, 거래 제안 및 운영 팁을 표시하고 신호와 시장 간의 관계를 이해해야 합니다.",
        h21: "블록체인의 실시간 데이터에 대한 심층적인 통찰력",
        h22: "Reactor를 통해 체인에 있는 데이터의 주요 내용과 중요성에 대한 심층적인 이해를 얻습니다.",
        h23: '실시간 시장 데이터',
        h24: "블록체인 트랜잭션 추적",
        h25: "스마트 컨트랙트 분석",
        h26: "주소 및 잔액조회",
        h27: "과거 데이터 역추적",
        h28: "커스텀 지표 분석",
        h29: "암호화폐 가격, 거래량, 시가 총액과 같은 주요 지표를 포함하여 시장의 역학 관계를 완전히 이해하는 데 도움을 줍니다.",
        h30: "거래 시간, 거래 쌍, 거래량 및 거래 방향을 포함한 거래 내역을 추적하고 표시합니다.",
        h31: "다른 프로젝트의 스마트 계약의 보안 및 신뢰성을 깊이 이해하고 평가하도록 도와주세요.",
        h32: "주소를 입력하면 모든 암호화폐의 잔액과 거래내역을 조회할 수 있습니다.",
        h33: "과거 암호화폐 가격 동향, 거래량 변화 및 기타 데이터 표시",
        h34: "자신의 필요와 전략에 따라 개인화된 지표를 생성하고 K-line 또는 온체인 데이터에 적용할 수 있습니다.",
        h35: "거래를 위해 태어났다",
        h36: "실시간 K-라인 차트, 정확한 지표 및 지능형 분석",
        h37: "통화 정보",
        h38: "차트 거래",
        h39: "지능형 분석",
        h40: "통화명, 총 발행량 등의 기본 정보와 가격, 시가, 24시간 거래량 등 중요 지표",
        h41: "K-라인 차트의 오른쪽에서 직접 거래할 수 있으며, 여기에서 시장 기회에 대한 통찰력을 얻고 해당 거래 결정을 내릴 수 있습니다.",
        h42: "신호는 가격 추세 패턴, 형태적 구조, 시장의 저항 및 지지 수준을 식별할 수 있습니다.",
    },
    community: {
        h1: "리액터 커뮤니티 가입",
        h2: "최신 Reactor 이벤트 정보 및 온라인 지원 받기",
        h3: '텔레그램',
        h4: "양적 커뮤니티",
        h5: "트위터",
        h6: "중국어 커뮤니티",
        h7: "인스타그램",
        h8: "페이스북",
        h9: "전보",
        h10: "전문 직원의 지원 및 답변 받기",
        h11: "저희 커뮤니티에는 암호화폐 시장과 퀀트 거래에 대한 심도 있는 이해를 가진 숙련된 전문 직원이 있습니다. 커뮤니티 구성원으로서 귀하는 저희 전문가 팀과 직접 상호 작용하여 질문하고 조언과 답변을 구할 수 있습니다. 관련 여부 시장 분석, 거래 전략 또는 기술 지원을 통해 저희 직원이 전심으로 귀하를 돕고 귀하가 양적 거래의 길에서 항상 올바른 방향에 있도록 보장할 것입니다.",
        h12: "암호화 시장 트렌드 파악 및 새로운 퀀트 전략 모색",
        h13: "커뮤니티에 가입하면 암호화 시장의 최신 트렌드와 역학을 포착할 수 있는 기회를 갖게 됩니다. 우리는 시장 데이터를 분석하고 전략을 수립하고 거래를 실시간으로 실행하는 데 도움이 되는 고급 양적 거래 도구와 기술을 제공합니다. 커뮤니케이션을 통해 다른 구성원들과 공유하고 새로운 퀀트 전략을 모색하고 투자 지평을 넓히며 급변하는 시장에서 경쟁 우위를 확보할 수 있습니다.",
        h14: "자유롭게 공유하고 새로운 암호화된 친구들을 만나세요",
        h15: "우리 커뮤니티는 무료 공유 및 상호 지원을 위한 플랫폼입니다. 회원으로서 거래 경험, 통찰력 및 전략을 공유하고 다른 회원과 상호 작용하고 토론할 수 있습니다. 같은 생각을 가진 다른 암호화 애호가 및 전문 거래자와 상호 작용함으로써 Exchange를 통해 네트워크를 확장하고 새로운 암호화폐 친구들을 만날 수 있습니다. 이 활발하고 협력적인 커뮤니티에서 다른 사람들의 경험을 통해 배우고 함께 성장하며 더 많은 거래 기회를 창출할 수 있는 기회를 갖게 될 것입니다."
    },
    cooperation: {
        h1: "글로벌 기업 파트너",
        h2: "Tianyi는 전 세계 중국, 아시아, 중동, 동남아시아 등에 많은 파트너 회사를 보유하고 있습니다. 우리는 전 세계 파트너 회사와 새로운 기술 솔루션을 제공합니다.",
        h3: '선전',
        h4: "본부",
        h5: "싱가포르",
        h6: "동남아시아",
        h7: "청두",
        h8: "분기",
        h9: "우한",
        h10: "일본",
        h11: "아시아",
        h12: "베트남",
        h13: "후난",
        h14: "허난",
        h15: "한국",
        h16: "캄보디아",
        h17: "하이난",
        h18: "두바이",
        h19: "중동",
        h20: "태국"
    },
    copyRobot: {
        // h1:"리액터의 복제 로봇",
        // h2:"스스로 전략을 개발하지 않고도 수익성 있는 거래에 참여할 수 있습니다.",
        // h3:'더 보기',
        // h4: "신호 제공자를 선택하고 거래 전략을 자동으로 복사할 수 있습니다. 즉, 우수한 거래자의 발자취를 따라 그들의 거래 경험과 성공을 즐길 수 있습니다.",
        // h5: "선택한 거래자의 거래 행동을 투자자의 거래 계좌에 자동으로 복사",
        // h6: "복제 비율 설정, 위험 제어 매개변수 설정 등과 같은 유연한 구성 옵션",
        // h7: "리스크 관리 기능 및 자동 손절 매커니즘",
        // h8: "다양한 복사 옵션",
        // h9: "다양한 복사 옵션을 제공하고 투자자의 필요와 선호도에 따라 다른 복사 방법을 선택할 수 있습니다",
        // h10: "실시간 동기 트랜잭션",
        // h11: "투자자가 트레이더의 작업을 적시에 추적하고 복사할 수 있도록 트레이더의 거래 행동을 실시간으로 동기화합니다.",
        // h12: "다중 플랫폼 지원",
        // h13: "투자자는 익숙하고 신뢰하는 거래소에서 거래할 수 있으며, 카피 트레이딩의 편리함과 이점을 누릴 수 있습니다.",
        h1: "최고 거래자들의 놀라운 성과를 따르십시오",
        h2: "Reactor를 통해 고유한 거래 전략과 최고 거래자의 우수한 실적에 액세스",
        h3: '더 보기',
        h4: "독특한 거래 전략",
        h5: "최고의 트레이더와 직접 작업할 수 있는 기회",
        h6: "자신의 트랜잭션 성공률 및 성능 향상",
        h7: "더 나은 위험 관리를 위한 적절한 자본 배분",
        h8: "더 광범위하고 다양한 거래 전략 옵션",
        h9: "자세히 알아보기",
        h10: "이를 위해 제공합니다",
        h11: "완전히 자동으로 트레이더의 구매 및 판매 행동을 따릅니다",
        h12: "이익 실현, 보고서 중지 등과 같은 개인화된 조정 매개변수",
        h13: "매매 자산 및 거래 기록 보기",
        h14: "자신의 위험 허용 범위에 따라 자본 배분 및 위험 관리 수행",
        h15: "더 많은 기능 보기",
        h16: "자세한 데이터 및 정보 제공",
        h17: "우수한 트레이더를 쉽게 찾고 팔로우할 수 있으며 안정적인 투자 수익을 얻을 수 있습니다",
        h18: "투명한 로봇 판매자 정보",
        h19: "주간 거래 결과 요약",
        h20: "평균 이익",
        h21: "사용자는 Budangjie에서 판매자의 시간과 마지막 상장 시간에 따라 판매자의 활동 수준을 판단할 수 있으며 공급 및 공급의 공동 채굴 방식을 통해 판매자와 추가 협력을 수행할 수 있습니다.",
        h22: "복사 로봇은 적시에 거래 실적을 이해하고 평가할 수 있도록 총 이익, 매수 시간, 매도 시간 등을 포함한 거래 결과의 주간 요약을 제공할 수 있습니다.",
        h23: "복사 로봇이 평균 수익을 계산하여 표시하고 차트 형태로 표시하므로 각 거래의 평균 수익과 예상 수익을 이해할 수 있습니다.",
        h24: "지난 달 실적 보고서 보기",
        h25: "승률, 패율, 손익률",
        h26: "거래 통화 및 거래 시간",
        h27: "총 자산 추세 차트, 각 거래의 최고 수익, 최대 하락률 및 지난 달 투자 상황을 이해하는 데 도움이 되는 기타 데이터를 포함합니다.",
        h28: "복사 로봇은 트레이더의 승률, 손실률, 손익 비율과 같은 중요한 지표를 보여주므로 트레이딩 전략의 안정성과 수익성을 평가하는 데 도움이 됩니다.",
        h29: "복사 로봇은 귀하가 복사한 거래 통화 목록과 총 거래 수에 대한 통계를 제공하여 귀하가 참여하는 거래 종류와 거래 빈도를 이해할 수 있도록 합니다.",
    },
    dca: {
        h1: "양적 여정은 고정 투자 전략(DCA)에서 시작됩니다",
        h2: "시장 가격 변동에 관계없이 암호화폐를 구매하기 위해 정기적으로 자금을 투자하십시오",
        h3: '더 보기',
        h4: "DCA가 무엇인가요?",
        h5: "DCA는 시장 가격 변동에 관계없이 정기적으로 동일한 금액의 자금을 투자하여 암호화폐를 구매하는 장기 투자 전략입니다. 이 전략은 투자자가 위험을 분산시키고 시장 변동성의 영향을 줄이고 정기적인 투자 계획을 쉽게 설정하고, 관심 있는 암호화폐와 투자 주기를 선택할 수 있습니다. 설정한 계획에 따라 지정된 시간 간격 내에 시스템이 자동으로 거래를 실행합니다. 시장 가격에 따라 지능적으로 암호 화폐를 구매합니다. 유연한 조정 고정 투자 계획, 시장 변화 및 개인의 필요에 따라 최적화됩니다.",
        h6: "Reactor에서 DCA 전략을 사용하면 어떤 이점이 있나요?",
        h7: "안정적인 투자",
        h8: "DCA는 정기적으로 일정 금액을 투자함으로써 시장 가격이 어떻게 변동하더라도 안정적인 투자 리듬을 유지할 수 있습니다. 이러한 안정성은 투자자가 시장 변동을 완화하고 투자 위험을 줄이는 데 도움이 될 수 있습니다.",
        h9: "평균 비용",
        h10: "DCA 전략은 정기적인 투자를 통해 구매 비용 평균화를 달성할 수 있습니다. 시장 가격이 하락하면 더 많은 자산 단위를 구매하고 시장 가격이 상승하면 더 적은 자산 단위를 구매합니다. 이 방법이 도움이 될 수 있습니다. 시장 변동에 따라 평균적으로 자산을 구매하고 구매 가격의 위험을 줄입니다.",
    },
    demoRobot: {
        h1: "리액터의 복제 로봇",
        h2: "리스크 없이 양적 거래 경험 얻기",
        h3: '더 보기',
        h4: "DEMO 로봇을 사용하는 이유",
        h5: "DEMO 로봇은 사용자가 정량적 거래 전략을 배우고, 연습하고, 테스트할 수 있는 위험 없는 환경을 제공합니다. 사용자가 거래 기술을 개선하고, 로봇 성능을 평가하고, 거래 전략을 최적화하고, 시장에 대한 이해를 높이는 데 도움이 될 수 있습니다. DEMO 로봇을 사용하면 , 사용자는 실제 거래 전에 자신감과 경험을 쌓을 수 있으므로 실제 시장의 문제에 더 잘 대처할 수 있습니다.",
        h6: "학습 및 연습",
        h7: "사용자는 거래에 실제 자금을 사용하지 않고 거래 기술과 전략을 배우고 연습할 수 있습니다. 초보자가 거래 플랫폼 운영에 익숙해지고 다양한 거래 전략을 이해하며 실시간 시뮬레이션 거래를 수행하는 데 도움이 될 수 있습니다.",
        h8: "리스크 관리",
        h9: "DEMO 로봇은 사용자가 실제 시장 조건에서 거래 전략을 테스트하고 검증할 수 있게 해주지만 실제 자금의 위험을 감수하지는 않습니다. 이를 통해 사용자는 전략의 효과와 잠재적 위험을 평가하고 실제 필요한 최적화 및 조정을 할 수 있습니다. 거래를 통해 더 나은 결과를 얻을 수 있습니다.",
        h10: "전략 최적화 및 조정",
        h11: "DEMO 로봇을 통해 사용자는 전략을 최적화하고 조정할 수 있습니다. 다른 매개변수와 설정을 시도하고, 거래 결과의 변화를 관찰하고, 피드백을 기반으로 필요한 조정을 할 수 있습니다. 이것은 더 나은 거래 결과를 얻기 위해 거래 전략을 개선하고 최적화하는 데 도움이 됩니다. ",
    },
    download: {
        h1: "무료로 Reactor로 거래를 시작하세요!",
        h2: "Windows와 Mac의 완벽한 조화, 완전한 비즈니스 기능 지원",
        h3: '리액터 PC 버전',
        h4: "Windows 및 Mac OS 클라이언트를 다운로드하여 전문 거래 서비스를 즐기십시오.",
        h5: "로컬 다운로드",
        h6: "왜 Reactor를 선택해야 하나요?",
        h7: "자산 보호",
        h8: "Reactor는 최고 수준의 보안 프로토콜과 암호화 기술을 채택하여 디지털 자산을 항상 가장 강력하게 보호합니다.",
        h9: "시장 동향에 대한 통찰력",
        h10: "Reactor는 포괄적인 데이터 통계 및 분석 도구를 제공하여 시장 동향 및 가격 변동에 대한 심층적인 이해를 돕고 시장 상황에 따라 거래 전략을 조정할 수 있습니다.",
        h11: "빠르고 효율적인 거래 경험",
        h12: "Reactor는 고속 트랜잭션 실행 및 주문 처리를 제공하여 시장 변화에 신속하게 대응하고 거래 기회를 잡을 수 있도록 합니다.",
    },
    exchange: {
        h1: "바이낸스",
        h2: "채팅",
        h3: '바이낸스가 무엇인가요? ',
        h4: "바이낸스는 거래량 기준 세계 최대 암호화폐 거래소입니다. 2022년 8월 현재 바이낸스 거래소의 일일 거래량은 760억 달러이며 전 세계적으로 9천만 명의 고객이 있습니다. 플랫폼은 사용자가 구매할 수 있는 신뢰할 수 있는 멤버십이 되었습니다. 디지털 자산을 판매 및 저장하고 350개 이상의 암호화폐 및 수천 개의 거래 쌍 목록에 액세스할 수 있습니다.바이낸스 생태계에는 이제 바이낸스 거래소, 연구소, 런치패드, 정보, 아카데미, 연구, 트러스트 월렛, 자선 단체, NFT 등이 포함됩니다. ",
        h5: "Reactor는 Binance에서 계정을 어떻게 관리합니까?",
        h6: "Reactor 플랫폼의 API를 통해 거래소를 원활하게 연결하여 자동화된 거래를 실현합니다. 우리의 강력한 API 기능을 통해 여러 거래소와 쉽게 연결하고 거래 전략을 자동으로 실행하며 효율적인 거래 작업을 달성할 수 있습니다. 편리함을 충분히 활용하십시오. API를 통해 거래 계좌를 쉽게 관리하고 더 나은 투자 결과를 얻을 수 있습니다.",
        h7: "바이낸스의 창립자는 누구입니까?",
        h8: "이 글로벌 회사는 Changpeng Zhao와 Yi He가 중국에서 공동 설립했습니다. 중국계 캐나다인 개발자이자 비즈니스 임원인 Changpeng Zhao(CZ)는 회사의 CEO입니다. 그는 몬트리올의 McGill University에서 공부했으며 성공적인 기업가로서의 실적 그의 과거 경력에는 Bloomberg Tradebook Futures R&D 팀장, Fusion Systems 설립자, Blockchain.com 기술 책임자 등이 있습니다. Yi He는 Binance의 CMO이자 회사의 벤처 기업인 Binance Labs의 책임자입니다. 주요 모바일 비디오 기술 회사인 Yixia Technology의 전 부사장이자 디지털 자산 거래소인 OKCoin의 공동 설립자입니다.",
        h9: "바이낸스가 언제 출시했습니까?",
        h10: "바이낸스는 2017년 6월에 출시되었고 180일 만에 세계 최대 암호화폐 거래소로 성장했습니다.",
        h11: "바이낸스 제한 국가?",
        h12: "바이낸스 이용약관에 따르면 제한 지역에는 미국, 싱가포르 및 온타리오(캐나다)가 포함됩니다. 그러나 특정 국가/지역은 규제상의 이유로 사용을 제한하거나 기능이 제한될 수 있습니다. 말레이시아, 일본, 영국, 태국, 독일, 이탈리아, 네덜란드도 선물 및 파생 상품을 제공하지 않습니다. 2019년 9월에는 미국 고객을 위해 별도의 전용 플랫폼인 Binance.US가 출시되었습니다.",
        h13: "바이낸스 수수료는 얼마인가요?",
        h14: "플랫폼은 사용자 친화적이고 가장 저렴한 플랫폼 중 하나이며 다양한 거래 유형을 제공하고 숙련된 투자자를 위한 고급 거래 도구 세트를 제공합니다. 일반 사용자부터 VIP 9까지 계층화된 시스템을 기반으로 요금을 부과합니다. 일반 사용자의 경우 현물 거래에 대해 0.10%의 메이커 테이커 수수료가 부과됩니다. 2022년 7월 바이낸스는 BTC 현물 거래가 수수료가 없을 것이며 8월에는 ETH/BUSD 거래가 수수료가 없을 것이라고 발표했습니다.",
        h15: "바이낸스에서 레버리지 또는 마진 거래를 사용할 수 있습니까?",
        h16: "거래자는 최대 10배의 레버리지로 암호화폐를 거래할 수 있는 바이낸스 마진에서 자금을 빌리고 마진 거래에 참여할 수 있습니다. 또한 사용자는 USDT, BUSD 또는 기타 암호화폐로 결제되는 바이낸스 선물과 같은 파생 상품과 바이낸스 옵션을 사용하여 거래할 수 있습니다. ",

        A0: 'OKX',
        A1: 'OKX는 무엇인가요?',
        A2: 'OKX는 세이셸에 위치한 암호화폐 중앙화 거래소(CEX) 및 지갑 플랫폼으로, 전 세계 수백만 명의 고객을 대상으로 서비스를 제공합니다. 이 거래소는 스팟 및 간편한 옵션 거래를 포함한 기본 거래를 제공하며, 마진, 선물, 영구 계약 및 옵션을 포함한 파생상품도 제공합니다. 다른 제품으로는 거래 봇, 대량 거래, OKX Earn(저축, 스테이블코인, DeFi, 스테이킹, ETH 2.0 등), 암호화폐 대출 및 거래소의 시작 플랫폼인 Jumpstart 등이 있습니다.',
        A3: 'OKX 지갑은 "Web3 포털"이라 주장하며, 인기있는 암호화폐 지갑, 탈중앙화 거래소(DEX), NFT 마켓 및 탈중앙화 애플리케이션(DApp)을 제공합니다. 이 지갑은 비트코인, 이더리움, BNB 체인, 솔라나, 폴리곤, 아발란치, 팬텀 등과 같은 주요 블록체인을 포함한 30가지 이상의 네트워크를 지원합니다.',
        A4: 'OKX는 자체 블록체인인 OKX 체인과 네이티브 토큰 OKB를 보유하고 있으며, 이를 통해 개발자들은 DApp을 만들고 프로젝트를 배포할 수 있으며, 개발자들은 OKX Oracle 등의 인프라를 사용할 수 있습니다.',
        A5: '2022년 1월, OKEx는 OKX로 변경되었으며 새로운 브랜드와 로드맵을 가지게 되었습니다.',
        A6: 'OKX의 창립자는 누구인가요?',
        A6: 'OKX(이전 OKEx)는 OK 그룹의 자회사인 OKCoin의 자회사입니다.',
        A7: '이 회사는 Mingxing “Star” Xu에 의해 2013년에 중국에서 설립되었습니다. Xu는 중국의 기업가로, 베이징과학기술대학 응용물리학 학사 학위를 보유하고 있습니다. Star Xu는 OK 그룹의 최고경영자입니다.',
        A8: 'OKX의 현직 최고경영자는 Jay Hao입니다.',
        A9: 'OKX는 언제 출시되었나요?',
        A10: '이 거래소(이전 OKEX)는 2017년에 출시되었습니다.',
        A11: 'OKX는 어디에 위치해 있나요?',
        A12: '회사의 본부는 세이셸에 위치해 있습니다.',
        A13: 'OKX의 이용이 제한된 국가들',
        A14: '이 프로젝트는 200개 이상의 국가에서 이용할 수 있습니다. 그러나 미국 거주자는 이 플랫폼의 서비스를 이용할 수 없습니다.',
        A15: 'OKX는 어떤 암호화폐를 지원하나요?',
        A16: '암호화폐 거래소에는 350종 이상의 암호화폐가 상장되어 있으며, 지원되는 거래 쌍은 500종 이상입니다. 이 플랫폼에는 BTC, ETH, OKB, AAVE, SOL, MATIC, XRP, DOGE, SHIB, DOT 등이 포함됩니다.',
        A17: 'OKX의 수수료는 어떻게 되나요?',
        A18: '이 거래소는 메이커-테이커 수수료 모델을 채용하고 있습니다. 거래 수수료는 0.10%부터 시작하여 거래량에 따라 감소합니다. 일반 사용자의 경우, 수수료는 OKX 생태계에 있는 OKB(네이티브 토큰)의 보유량에 따라 결정되며, 고급 사용자의 경우, 수수료는 30일간의 거래량에 기반하여 계산됩니다.',
        A19: 'OKX에서 마진 또는 보증금 거래를 할 수 있나요?',
        A20: 'OKX의 보증금 거래 최대 레버리지는 10배입니다. 파생상품 측면에서, OKX는 최대 125배의 선물 거래, 퍼페츄얼 스왑 레버리지를 제공합니다. 추가적으로, 트레이더는 BTC, ETH 등을 포함한 암호화폐 옵션을 통해 레버리지 거래를 할 수 있습니다.',



        B0: '화전',
        B1: 'Huobi Global는 무엇인가요?',
        B2: 'Huobi Global은 국제 디지털 자산 거래소로, 전 세계 100 개국에서 이용할 수 있습니다. 이 플랫폼은 2013년에 중국에서 설립되었으며, 글로벌 사용자에게 거래 및 투자 서비스를 제공합니다. 매일 거래량은 40 억 달러에 달합니다.',
        B3: '이 중앙 집중식 거래소 (CEX)는 초기 토큰 발행 (ICO)을 지원하며, 다양한 암호화폐도 상장되어 있습니다. 이 플랫폼에는 500 개 이상의 자산과 300 개 이상의 거래 쌍이 있습니다. 사용자는 자산을 보호하기 위해 보험 정책을 사용할 수 있습니다. 이 거래소는 연중무휴로 다양한 고객 지원 채널을 보유하고 있어 고객들에게 일류의 전문 서비스를 제공합니다.',
        B4: '이 거래소의 생태계에는 현물 거래, 마진 거래, 선물 거래, 파생 상품 거래, 담보 제공, 암호화폐 대출 등이 포함됩니다. 활발한 거래자와 투자자는 장외 거래 (OTC) 시장 플랫폼에서 기회를 찾을 수 있으며, 다양한 맞춤형 거래 도구를 사용할 수 있습니다.',
        B5: 'Huobi Global의 창립자는 누구인가요?',
        B6: '이 거래소는 2013년에 베이징에서 리 린 (Leon Li)에 의해 설립되었습니다. CEO인 리 린은 2013년에 중국의 시안푸아 대학에서 자동화 전공으로 졸업했습니다. Huobi Global을 설립하기 전에 리 린은 미국의 최대 소프트웨어 회사 중 하나 인 오라클에서 컴퓨터 엔지니어로 일했습니다.',
        B7: 'Huobi Global은 어디에 위치하고 있나요?',
        B8: '이 회사의 본사는 세이셸 제도에 있으며, 홍콩, 한국, 일본 및 미국에 사무소를 두고 있습니다.',
        B9: 'Huobi Global 사용이 제한되는 국가',
        B10: '다음 국가의 사용자는 Huobi Global에서 제공하는 모든 서비스를 이용할 수 없습니다 : 중국, 미국, 캐나다, 일본, 쿠바, 이란, 북한, 수단, 시리아, 베네수엘라, 싱가포르 및 크리미아.',
        B11: '다음 국가/지역의 사용자는 파생 상품 거래 서비스를 이용할 수 없습니다: 중국 본토, 대만, 홍콩, 이스라엘, 이라크, 방글라데시, 볼리비아, 에콰도르, 키르기스스탄, 세바스토폴, 스페인, 영국 (소매 고객 전용), 뉴질랜드.',
        B12: '일본 사용자는 Huobi Japan 거래소의 서비스를 이용할 수 있으며, 해당 회사는 미국 시장으로 돌아가기 위한 계획을 발표했습니다.',
        B13: 'Huobi Global은 어떤 암호화폐를 지원하나요?',
        B14: '이 거래소는 BTC, DOGE, ETH, LTC, XMR, HT, HUSD, USDT를 포함하여 400종 이상의 디지털 자산 및 암호화폐를 지원합니다.',
        B15: '2022년 8월 기준, 이 거래소는 ALL, BGN, CHF, CZK, DKK, EUR, GBP, HRK, HUF, MDL, MKD, NOK, PLN, RON, SEK, TRY, UAH, HKD, AUD, USD를 포함한 현금을 지원합니다.',
        B16: 'Huobi Global의 수수료는 어떻게 되나요?',
        B17: 'Huobi는 수수료 등급 구조를 가지고 있으며, 거래 수수료는 개별 사용자의 월간 거래량에 따라 달라집니다. 플랫폼은 기본 주문 수수료를 0.2%로 부과하며, 거래량이 500만 달러 이상이면 할인 혜택을 받을 수 있습니다. 또한 Huobi Token (HT) 소지자도 할인 혜택을 받을 수 있습니다. 전문 거래자는 다음 할인 혜택을 받을 수 있습니다: 순 주문 수수료는 0.0362%부터 시작하고, 거래 수수료는 0.0462%부터 시작합니다.',
        B18: 'Huobi Global에서 레버리지 또는 마진 거래를 할 수 있나요?',
        B19: '이 플랫폼은 여러 암호화폐에 대해 마진 거래를 지원하며, 사용자는 최대 3배 레버리지로 전체 포지션 모드의 마진 거래를 할 수 있습니다. 이자는 시간당 계산됩니다.',
        B20: '사용자는 최고 200배 레버리지를 사용하여 비트코인과 이더리움 암호화폐 선물 계약 거래를 할 수 있습니다.',



        C0: '팬케이크스왑',
        C1: '팬케이크스왑 소개',
        C2: '팬케이크스왑은 인기 있는 탈중앙화 거래소 (DEX)로, 사용자들이 BSC 체인에서 BEP-20 토큰을 교환할 수 있게 해줍니다. 이는 BSC 체인 생태계에서 가장 큰 거래소이며 시장 점유율 측면에서도 인기 있는 탈중앙화 거래소 (DEX) 중 하나입니다. 이 거래소는 자동형 마켓 메이커 (AMM) 모델을 사용하여 사용자들이 유동성 풀과 거래를 할 수 있도록 합니다. 사용자는 유동성 공급자가 되어 거래소의 거래 수수료를 얻을 수 있습니다.',
        C3: '또한, 유동성 제공자 (LP) 토큰을 보유한 사람들은 유동성 마이닝에 참여하여 이 거래소의 유용한 토큰인 CAKE를 벌 수 있습니다. 이 토큰은 "시럽 풀"에서 주기적 또는 예치로 얻을 수 있습니다. 예치로 얻은 사용자들은 언제든지 예치를 취소할 수 있지만, 주기적 예치는 최대 수익률 (최대 예치 기간 52 주)을 얻을 수 있습니다.',
        C4: '또한, 팬케이크스왑은 로또 당첨 게임을 제공하여 사용자가 CAKE 보상을 받을 수 있게 합니다. NFT 마켓에서 사용자들은 컬렉션 아이템을 거래할 수 있으며, NFT 프로필 시스템에서는 개인화된 프로필을 설정할 수 있습니다. 또한, ApolloX와 함께 개발한 영구 계약 거래소가 있으며, 다양한 인기 토큰 거래 쌍을 제공하며 무기한 선물 거래 서비스를 제공합니다. 사용자는 이 거래소에서 자금을 레버리지로 활용하여 인기 토큰 간 거래를 할 수 있습니다.',
        C5: '팬케이크스왑의 창시자는 누구인가요?',
        C6: '팬케이크스왑은 익명의 개인이 창설하였으며, 20명 이상의 익명의 "요리사"들이 거래소의 "주방"에서 일하고 있습니다. 이 거래소는 오픈 소스이며 Certik 및 Slowmist 등의 주요 보안 회사에 의해 감사를 받았습니다.',
        C7: '팬케이크스왑은 언제 출시되었나요?',
        C8: '팬케이크스왑은 2020년 9월에 출시되었습니다.',
        C9: '팬케이크스왑은 어디에 위치해 있나요?',
        C10: '탈중앙화 거래소이기 때문에 운영 팀은 원격 작업을 채택하고 있습니다. 그러나 Crunchbase에 따르면 팬케이크스왑 본사는 일본 후쿠오카에 위치해 있다고 보고되었습니다.',
        C11: 'PancakeSwap 사용 제한 국가',
        C12: '탈중앙화 거래소이기 때문에 국가 제한이 없습니다. 그러나 다음 국가는 지리적인 차단을 받고 있습니다: 쿠바, 이란, 짐바브웨, 이라크, 크림반 반도, 시리아, 수단, 벨라루스 및 콩고 민주 공화국.',
        C13: 'PancakeSwap 지원 토큰 목록',
        C14: '모든 BEP-20 토큰은 거래할 수 있으며, BTC/USDT, ETH/USDT, BNB/USDT 및 모든 주요 레이어1 블록체인을 포함한 여러 인기 기간계 트레이딩 쌍도 지원합니다.',
        C15: 'PancakeSwap의 수수료는 어떻게 되나요?',
        C16: '현재 기준으로 토큰 교환에는 0.25%의 거래 수수료가 있습니다. 그 중 0.17%는 유동성 풀로 돌아가는 보상으로, 0.03%는 예비 자금으로 사용되며 나머지 0.05%는 매입 및 소각에 사용됩니다.',
        C17: 'PancakeSwap에서 레버리지 거래 또는 마진 거래를 할 수 있나요?',
        C18: '가장 큰 트레이딩 쌍의 경우, 영구 계약 거래소에서 최대 150배 레버리지 거래를 허용하며, 작은 트레이딩 쌍의 경우 50배까지 가능합니다.',



        D0: '유니스왑',
        D1: '유니스왑이란 무엇인가요?',
        D2: '유니스왑은 2018년에 출시된 가장 인기 있는 암호화폐 탈중앙화 거래소(DEX) 중 하나입니다. 이 거래소는 자동시장제공자(AMM) 모델을 도입하여 전통적인 주문북 모델을 사용하는 다른 거래소에서 아직 사용되고 있는 것을 대체했습니다. 유니스왑은 이더리움 블록체인 상에서 작동하며, 사용자간에 ERC-20 토큰을 안전하게 교환하기 위해 여러 스마트 컨트랙트를 사용합니다.',
        D3: '이 프로토콜의 탈중앙화 특성으로 인해, 이 거래소를 운영하기 위한 단일 중앙화 기관은 없습니다. 대신 교환은 피어 투 피어(P2P) 방식으로 진행됩니다. 또한, 유니스왑은 다른 거래소에서 발생하는 유동성 문제를 해결하기 위해 노력하고 있습니다. 유니스왑 V3는 이 프로토콜의 세 번째 버전입니다. 이 업데이트로 인해 보상 수준이 향상되었으며 개인 유동성 제공자가 더 큰 통제력과 유연성을 갖게 되었습니다. 유니스왑 V3은 탈중앙화 거래소 거래량의 46.5%를 차지하고 있으며, 또한 2022년 5월에는 누적 거래량 1조 달러를 기록했습니다.',
        D4: '유니스왑 V3의 창시자는 누구인가요?',
        D5: 'Hayden Adams가 유니스왑을 설립했습니다. 그는 이전에 Stony Brook(뉴욕 주립 대학교 스토니브룩 캠퍼스)에서 공부한 후 Siemens에서 기계공학자로 일했습니다. Adams는 Vitalik Buterin의 한 블로그 글과 Reddit 게시물에서 영감을 받았으며, 이더리움 재단의 지원을 받아 2018년 11월에 이 프로토콜을 출시했습니다. 유니스왑 랩스(Uniswap Labs)는 먼저 Paradigm에서 시드 투자를 받은 후 2020년 8월 Andreessen Horowitz가 주도하는 A 라운드에서 1100만 달러를 조달했습니다.',
        D6: '유니스왑 V3는 2021년 5월에 출시되었으며, 첫 번째 버전의 출시로부터 거의 2.5년이 지났으며, 유니스왑 V2의 출시로부터 1년이 지났습니다.',
        D7: '유니스왑은 어디에 위치해 있나요?',
        D8: 'Uniswap 프로토콜과 Uniswap 인터페이스를 개발하고 있는 Uniswap Labs는 미국 뉴욕에 위치해 있습니다. 이는 탈중앙화 금융(DeFi) 프로토콜로, 전 세계에서 인터넷에 접속할 수 있는 모든 개인이 사용할 수 있습니다. 그러나 일부 국가의 사용자는 제한을 받을 수 있습니다. 2022년 7월까지 Uniswap은 10개 국가의 사용자가 이 프로토콜을 사용하는 것을 금지했습니다. 이 국가들은 벨라루스, 쿠바, 이란, 북한, 시리아, 코트디부아르, 리베리아, 수단, 짐바브웨 및 이라크입니다.',
        D9: '어떤 암호화폐가 Uniswap에서 거래 가능한가요?',
        D10: 'Uniswap은 탈중앙화된 피어 투 피어 프로토콜이므로 누구나 어떤 토큰이든 Uniswap에 상장할 수 있습니다. 현재 가장 인기 있는 거래 쌍은 USDC, Wrapped BTC, WETH 및 DAI입니다.',
        D11: 'Uniswap의 수수료는 어떻게 되나요?',
        D12: 'Uniswap의 유동성 공급자는 0.05%, 0.30% 및 1%의 세 가지 수수료 수준으로 유동성 풀을 구성할 수 있습니다. UNI 거버넌스 제안을 통해 추가 수수료 수준이 도입될 수 있습니다.',
        D13: 'Uniswap에서 레버리지 또는 마진 트레이딩을 할 수 있나요?',
        D14: 'Uniswap 플랫폼은 레버리지 또는 마진 트레이딩을 제공하지 않습니다.',



        E0: 'DODO',
        E1: 'Dodo란 무엇인가요?',
        E2: 'Dodo는 이더리움 기반의 탈중앙화된 거래소로, "액티브 메이커 메이커"라고 불리는 기능을 갖춘 커스텀 버전의 자동 메이커 메이커(AMM)입니다. Dodo는 블록체인 상에서 디지털 자산을 발행하고 거래하는 방법을 제공합니다. 이 거래소는 자체 유동성과 다른 거래소들로부터 집힌 유동성 두 가지를 제공합니다. 화이트페이퍼에 따르면, 이러한 조합은 이 거래소가 전 세계적인 웹3 자산에 대한 최적의 가격을 제공할 수 있게 합니다.',
        E3: '사용자들은 마켓 오더 또는 리미트 오더를 사용하여 다른 토큰들을 허가 없이 교환할 수 있습니다. 동시에, Dodo는 가스 비용이 없는 교환 경로를 제공하며 전문 메이커들에 의해 더 높은 유동성(가스 비용 및 슬리피지 없음)을 제공합니다. 또한, ERC-721 및 ERC-1155 토큰의 NFT 마켓도 있습니다. 유동성 공급자는 이 거래소에서 돈을 벌 수 있는 한 가지 방법이며, 사용자들은 유동성을 추가하여 LP 토큰을 얻고 거래 수수료를 받을 수 있습니다. 또 다른 방법은 마이닝입니다. 사용자들은 단일 토큰을 마이닝하거나 지정된 유동성 풀에 자금을 추가하여 LP 메이킹 마이닝을 할 수 있습니다. 물론, 사용자들은 플래시 론을 더 효율적인 방법으로 실행하거나 자본을 배분하는 등의 방법도 사용할 수 있습니다.',
        E4: '마지막으로, 이 거래소는 개발자들이 토큰을 생성할 수 있는 도구도 제공합니다. 이 거래소에서는 이더리움, BNB Chain, Polygon, OKChain 등을 포함한 블록체인을 지원합니다.',
        E6: 'Dodo의 창립자는 누구인가요?',
        E7: 'CrunchBase에 따르면, Dodo는 중국인인 Qi Wang과 Shichi Dai에 의해 창립되었습니다. 이 프로젝트는 Coinbase ventures, Defiance Capital, Galaxy Digital을 포함한 17개의 다른 벤처 캐피탈로부터 500만 달러의 자금을 조달했습니다.',
        E8: 'Dodo는 언제 출시되었나요?',
        E9: 'Dodo는 2020년 8월에 출시되었습니다.',
        E10: 'Dodo는 어디에 위치하나요?',
        E11: 'CrunchBase의 정보에 따르면, 이 거래소의 본부는 홍콩에 위치해 있습니다.',
        E12: 'Dodo의 사용이 제한되는 국가',
        E13: '본 문서 작성 시점에서는, 이 거래소의 사용에 제한이 있는 국가에 대한 정보는 제공되지 않았습니다.',
        E14: 'Dodo가 지원하는 토큰 목록',
        E15: 'Dodo는 arbitrrum, optimistic, Avalanche, Aurora 등 대부분의 EVM 호환 블록체인에 배포되어 있습니다. 따라서, 이 거래소는 메이저 토큰 및 랩 토큰 (Wrapped Ether, Wrapped Bitcoin 등)을 포함한 다양한 블록체인의 토큰을 지원합니다.',
        E16: 'Dodo의 수수료는 어떻게 되나요?',
        E17: 'Dodo는 새로운 토큰 생성 시 일부 수수료 (0.02 ETH)를 청구하지만, 거래 수수료에 관한 정보는 제공되지 않습니다.',
        E18: 'Dodo에서 레버리지 및 마진 거래를 할 수 있나요?',
        E19: '현재로서 이 플랫폼은 마진 거래 기능을 지원하지 않지만, 프로젝트의 로드맵에 따르면 2022년에 레버리지 트레이딩 서비스가 도입될 예정입니다.',


        F2: 'Curve에 대하여 (이더리움)',
        F3: 'Curve Finance 소개',
        F4: 'Curve Finance는 분산형 스테이블 코인 거래의 유동성 풀입니다. 주문서를 사용하지 않고 자동 시장 메이커 (AMM) 모델을 사용하여 유동성을 매칭합니다. 이 프로토콜은 분산형이며 신뢰할 수 없기 때문에 누구나 하나 이상의 풀에서 유동성을 제공할 수 있습니다. AMM의 곱 집합 공식을 통해 높은 효율성과 최소한의 슬리피지를 거래자에게 제공합니다.',
        F5: 'Curve 풀은 스마트 계약을 실행하는 StableSwap의 변형입니다. 여러 토큰을 거래하는 데 사용할 수 있으며, 스테이블 코인 페어 (일반적인 풀)이나 랩 토큰을 통한 토큰 교환도 가능하며, 기반이 되는 담보는 다른 프로토콜 (대출 풀)에서 빌릴 수 있습니다. 마지막으로, 메타 풀은 스테이블 코인을 다른 풀에서의 LP (유동성 공급자) 토큰과 결합시킵니다.',
        F6: '이 프로토콜은 Ethereum, Arbitrum, Aurora, Avalanche, Fantom, Harmony, Optimism, Polygon, xDai, Moonbeam 등 다양한 블록체인을 지원합니다. Curve 프로토콜을 사용하려면 일반적으로 자금을 Ethereum에서 이러한 블록체인에 브리징해야 합니다. 분산형 금융 (DeFi) 시스템의 중요성으로 인해 본 프로토콜은 "Curve 전쟁"이라는 경쟁에서 다른 여러 프로토콜과 경쟁하고 있습니다.',
        F7: 'Curve Finance의 창립자는 누구인가요?',
        F8: 'Curve Finance는 암호화폐 인프라 프로토콜 NuCypher의 전 공동창립자 겸 CTO인 Michael Egorov에 의해 설립되었습니다. 그는 LoanCoin이라는 분산형 은행 및 대출 네트워크도 만들었습니다. 암호화폐 세계로 진입하기 전에 그는 모스크바 물리기술대학교와 Swinburne 대학교에서 공부했습니다.',
        F9: 'Curve Finance는 언제 시작되었나요?',
        F10: 'Curve Finance는 2020년 6월에 "DeFi의 여름"으로 알려진 시기에 시작되었습니다. 따라서 신생 DeFi 업계의 기반 중 하나로 간주됩니다.',
        F11: 'Curve Finance는 어디에 위치해 있나요?',
        F12: 'CBInsight에 따르면, Curve Finance의 본사는 스위스에 있습니다.',
        F13: 'Curve Finance에서 이용이 제한되는 국가',
        F14: '기사 작성 당시에는 Curve Finance에서 제한되는 국가에 대한 정보가 없습니다. 그러나 미국의 금융 제재 대상 국가 사용자는 지리적 제한을 받을 수 있습니다.',
        F15: 'Curve Finance가 지원하는 토큰 목록',
        F16: '이 거래소는 DAI, USDC, USDT, FRAX 및 TUSD와 같은 주요 스테이블 코인의 유동성 풀을 지원합니다. 또한 wBTC, wETH 및 stETH (이것은 Lido의 스테이킹 이더 변형입니다)와 같은 랩 토큰의 교환도 지원합니다.',
        F17: 'Curve Finance의 수수료는 어떻게 되나요?',
        F18: '이 프로토콜에서 기준 수수료율은 0.04%입니다. 이 중 절반은 유동성 공급자에게 할당되고, 나머지 절반은 veCRV 소지자에게 할당됩니다. veCRV 소지자들은 정기적으로 토큰을 스테이킹하여 더 큰 거버넌스 권한을 얻을 수 있습니다.',
        F19: 'Curve Finance에서 레버리지 거래나 마진 거래를 사용할 수 있나요?',
        F20: '이 거래소는 토큰 교환 서비스만 제공하며, 레버리지 거래나 마진 거래는 제공하지 않습니다.',


        G0: '什么是Balancer？',
        G1: 'Balancer는 이더리움 기반의 탈중앙화된 자동 시장 메이커(AMM) 프로토콜로, 프로그래밍 가능한 유동성을 위한 유연한 구성 요소를 대표합니다.',
        G2: 'AMM 곡선 로직과 수학적인 코어 스왑 기능을 분리함으로써 Balancer는 확장 가능한 AMM이 되었습니다. 임의의 수량의 스왑 곡선과 풀 유형을 포함할 수 있습니다. 다음을 포함합니다:',
        G3: '전통적인 50/50x*y=k 가중치 풀',
        G4: '노출을 제어하기 위해 사용하는 사용자 정의 가중치(예: 80/20)',
        G5: '안정적인 교환 곡선',
        G6: '중첩 풀 (예: Boosted Pools)',
        G7: '가중치가 지속적으로 변하는 마이닝 풀 (예: Liquidity Bootstrapping Pool)',
        G8: '중앙 집중식 유동성 풀',
        G9: '사용자 정의 매개변수 풀',
        G10: '전체 프로토콜은 상단에 구축되며 (예: Gyroscope)',
        G11: '이렇게 함으로써 거래자, 집계자 및 애로비저를 손쉽게 통합하여 전체 유동성을 확보할 수 있습니다. Balancer Vault는 배치 처리 및 경로 로직을 최적화하여 가스 비용과 자본 요구를 극소화합니다. 개별 풀 및 프로젝트는 Balancer의 글로벌 유동성 위에 구축되어 기본 자산에 깊은 유동성을 제공하고 교환 경로를 열어줍니다.',
        G12: '누가 Balancer를 사용하나요?',
        G13: 'Balancer는 DeFi 분야의 다양한 참여자에게 매우 유용한 도구입니다.',
        G14: '거래자는 임의의 두 개의 ERC20 토큰 간에 교환이 가능합니다. 이는 Balancer Dapp을 통해 수행되거나 1inch와 같은 집약기, Matcha 또는 Paraswap과 같은 방법으로 수행될 수 있습니다.',
        G15: '유동성 제공자(LP)는 자금 풀에 유동성을 추가하여 스왑 수수료, 유동성 인센티브 및 기타 수익을 얻을 수 있습니다.',
        G16: '패시브 LP는 이미 복리화된 Aave 토큰을 기반으로 한 증폭 풀을 활용하여 수익을 창출할 수 있습니다.',
        G17: '애로비저는 배치 스왑 및 플래시 대출 등의 방법으로 마이닝 풀과 교환할 수 있습니다.',
        G18: 'BAL 토큰 소지자는 그들의 토큰을 veBAL에 잠금 처리하고 Balancer 프로토콜의 거버넌스에 참여할 수 있습니다.',

        J1: 'SushiSwap은 AMM을 사용하는 탈중앙화된 거래소(DEX)입니다. 이 자동 시장 메이커는 스마트 계약을 사용하여 다른 토큰 쌍에 대한 거래 시장을 생성합니다. 유동성 공급자는 이러한 스마트 계약에 자금을 제공하여 마찰 없는 거래를 가능하게 합니다. 이 거래소는 이러한 모델을 최초로 도입한 거래소 중 하나로, 원래 인기 있는 DEX(UniSwap)의 분기점으로 시작되었습니다. 그 후, SushiSwap은 DeFi 분야에서 가장 큰 거래소 중 하나로 성장했습니다. 현재 거의 모든 EVM 호환 체인 및 일부 Ethereum Layer 2 솔루션에서 운영될 수 있습니다.',
        J3: '이 거래소는 무면허 및 비보관 거래를 원하는 DeFi 거래자와 기관을 대상으로 합니다. 이 거래소는 유동성 문제를 해결하고 다른 거래소보다 더 공정한 거버넌스 방식을 채택하였습니다. 자체 토큰은 거버넌스 이슈에 대한 투표에 사용될 수 있습니다.',
        J4: '주요 제품으로는 Bento Box가 있으며, 이는 예금을 넣으면 수익을 창출할 수 있는 토큰 보관고입니다. Kashi 대출은 맞춤형 및 가스 효율적인 토큰 시장을 만들 수 있는 대출 및 마진 거래 플랫폼입니다. 현재 MasterChef V2에는 10억 달러 이상의 유동성 공급 계획이 포함되어 있습니다. 마지막으로 Miso는 프로젝트가 거래소에서 거래할 수 있는 공개 옵션을 제공하는 동안 고정 가격 또는 네덜란드식 경매 기반의 옵션 중 하나를 사용합니다.',
        J5: 'SushiSwap의 창시자는 누구인가요?',
        J6: '최초로 SushiSwap을 만든 사람은 Chef Nomi(별명)입니다. 이후 그들은 프로젝트를 떠나고, sushiswap과 0xMaki라는 익명의 개발자 두 명에게 관리 권한을 이전했습니다. 이 두 명은 제품 개발과 비즈니스 운영을 담당하였으며, 나중에 해당 거래소의 소유권은 FTX 거래소의 CEO 인 Sam Bankman-Fried에게 양도되었습니다.',
        J7: 'SushiSwap은 언제 시작되었나요?',
        J8: 'SushiSwap은 2020년 8월에 시작되었습니다.',
        J9: 'SushiSwap은 어디에 위치하고 있나요?',
        J10: '이 거래소는 중앙 본부가 아닌 탈중앙화 방식으로 운영됩니다.',
        J11: 'SushiSwap의 사용이 제한된 국가',
        J12: '현재 SushiSwap의 사용이 제한되거나 금지된 국가에 대한 정보는 없습니다.',
        J13: 'SushiSwap에서 지원하는 토큰 목록',
        J14: '첫 번째 및 두 번째 계층 블록체인에 존재하기 때문에 SushiSwap은 시장의 거의 모든 ERC-20 토큰을 지원합니다.',
        J15: 'SushiSwap의 수수료는 어떻게 되나요?',
        J16: '이 거래소는 업계 표준에 따라 토큰 교환에 대해 0.3%의 수수료를 부과합니다.',
        J17: 'SushiSwap에서 레버리지 또는 마진 거래를 할 수 있나요?',
        J18: 'SushiSwap은 융자 및 마진 거래 플랫폼인 Kashi를 제공하며, 이 플랫폼은 독특한 격리된 시장 프레임워크를 사용합니다.',



    },
    exchangeRobot: {
        h1: "리액터의 거래 로봇",
        h2: "고급 알고리즘 및 실시간 데이터 분석을 통해 자동으로 거래 전략 실행",
        h3: '세부정보 보기',
        h4: "거래 로봇은 설정된 규칙 및 트리거에 따라 자율 거래를 수행할 수 있으므로 거래 기회를 놓치지 않도록 보장합니다.",
        h5: "트렌드 시장, 그것이 상승 추세인지 하락 추세인지",
        h6: "빠른 가격 변동 포착 및 거래",
        h7: "범위 제한 변동성 거래",
        h8: "긴급 상황에 대한 빠른 대응",
        h9: "거래 내역에 대한 심층적 이해",
        h10: "체인의 데이터 표시 및 분석을 통해 다양한 거래를 추적 및 분석하고 시장 동향 및 거래자 행동을 이해할 수 있습니다.",
        h11: "자산 흐름 추적",
        h12: "체인의 데이터 시각화를 통해 자산 이전 경로 및 홀더 변경과 같은 중요한 정보를 추적할 수 있습니다.",
        h13: "스마트 컨트랙트 분석",
        h14: "저희 도구를 사용하면 스마트 계약의 실행을 심도 있게 조사하고 분석하여 계약의 보안과 신뢰성을 평가할 수 있습니다.",
    },
    faq: {
        h1: "고객 지원/FAQ",
        h2: "답을 찾고 있나요? 답이 여기에 있을지도 모릅니다.",
        h3: '초보자 가이드',
        h4: "리액터가 무엇인가요?",
        h5: "리액터 설정 방법",
        h6: "리베이트",
        h7: "유동성 플랫폼",
        h8: "덱스",
        h9: "보안 질문",
        h10: "회원 혜택",
        h11: "제품",
        h12: "트랜잭션",
        h13: "더 보기",
        h14: "Reactor는 혁신적인 양적 거래 플랫폼입니다",
        h15: "Reactor는 고급 알고리즘과 기술을 통합하여 사용자가 보다 지능적이고 효율적으로 거래할 수 있도록 지원합니다. 거래 전략 실행을 자동화하고 실시간 시장 데이터 및 분석을 제공하며 풍부한 거래 도구를 제공함으로써 사용자는 금융 시장에서 거래할 수 있습니다. 더 나은 거래 결과를 얻으십시오. 초보자이든 전문 거래자이든 Reactor는 투자 목표를 달성하고 더 높은 거래 수익을 얻을 수 있도록 강력한 거래 플랫폼을 제공합니다.",
        h16: "자주 묻는 질문",
        h17: "얼마나 많은 수익을 올릴 수 있나요?",
        h18: "차익 거래는 어떻게 이루어지나요?",
        h19: "이익을 보장합니까?",
        h20: "거래소에 어떻게 연결하나요?",
        h21: "하나의 거래 계정에 몇 개의 로봇을 열 수 있나요?",
        h22: "어떤 기본 통화를 사용해야 하나요?",
        h23: "리액터는 안전한가요?",
        h24: "기본 HD 지갑을 종료한 후에도 내 계정을 계속 사용할 수 있습니까?",
    },
    helpCenter: {
        h1: "도움말 센터",
        h2: "답을 찾고 있나요? 답이 여기에 있을지도 모릅니다.",
        h3: '주제 찾아보기',
        h4: "지원되는 문서 및 FAQ 카테고리 탐색",
        h5: "리액터가 무엇인가요?",
        h6: "Reactor는 사용자에게 정확한 시장 분석, 퀀트 전략 및 거래 도구를 제공합니다. Reactor를 통해 사용자는 더 높은 투자 수익을 달성하고 활성 커뮤니티에서 다른 퀀트 트레이더와 함께 성장할 수 있습니다.",
        h7: "Reactor는 어떻게 설정하나요?",
        h8: "Reactor의 다양한 기본 기능을 설정하는 방법에 대한 간단한 가이드입니다. 여기에서 신규 사용자가 시작합니다!",
        h9: "리베이트",
        h10: "유동성 플랫폼",
        h11: "DEX 양자화",
        h12: "보안 질문",
        h13: "회원 혜택",
        h14: "데모 로봇",
        h15: "수동 거래",
        h16: "DCA",
        h17: "주문 추적",
        h18: "시그널",
        h19: "지갑",
        h20: "로봇 복사",
        h21: "체인의 데이터",
        h22: "거래 로봇",
        h23: "차익거래 로봇",
        h24: "자주 묻는 질문",
        h25: "얼마나 많은 수익을 올릴 수 있나요?",
        h26: "차익 거래는 어떻게 이루어지나요?",
        h27: "이익을 보장합니까?",
        h28: "거래소에 어떻게 연결하나요?",
        h29: "하나의 거래 계정에 몇 개의 로봇을 열 수 있나요?",
        h30: "어떤 기본 통화를 사용해야 하나요?",
        h31: "리액터는 안전한가요?",
        h32: "메인 HD 지갑을 종료한 후에도 내 계정을 계속 사용할 수 있나요?",
    },
    interestRobot: {
        // h1:"리액터 차익거래 로봇",
        // h2: "이익을 내기 위해 가격 차이를 사용하세요",
        // h3:'더 보기',
        // h4:"Reactor의 차익 거래 봇은 서로 다른 거래소 또는 체인 간에 동시에 빠른 거래를 수행하여 이익을 내기 위해 가격 차이를 이용합니다.",
        // h5:"시장에서 가격 차이와 기회를 빠르게 식별",
        // h6:"여러 거래소 및 시장에서 가격 및 주문장 모니터링",
        // h7: "모니터링 및 통계 데이터를 제공하여 사용자가 실시간으로 재정 거래의 성과를 추적하고 분석할 수 있도록 합니다.",
        // h8: "다중 차익거래 전략",
        // h9: "시장 차익 거래, 거래소 차익 거래, 교차 체인 차익 거래, 선물 및 현물 차익 거래 등과 같은 다양한 차익 거래 전략을 지원합니다.",
        // h10: "실시간 모니터링 및 알람",
        // h11: "Arbitrage 로봇은 실시간으로 거래소 및 시장의 가격과 주문서를 모니터링하고 미리 설정된 조건에 따라 알람을 트리거합니다.",
        // h12: "맞춤화 및 유연성",
        // h13: "사용자는 개인 거래 전략 및 위험 선호도를 충족하기 위해 자신의 필요와 선호도에 따라 구성하고 조정할 수 있습니다.",
        h1: "가격 차이를 포착하고 안정적인 수입을 달성하십시오",
        h2: "시장 간 가격 차이를 이용하는 스마트 차익 거래 로봇",
        h3: '더 보기',
        h4: "차익거래 로봇",
        h5: "가격 차이를 사용하여 교차 교환 차익 거래 수행",
        h6: "여러 거래 플랫폼 및 블록체인에 대한 동시 연결 지원",
        h7: "Binance, Ouyi, Huobi, Ethereum, Binance Smart Chain, Tron 등을 포함하되 이에 국한되지 않고 시장 및 가격 변동을 실시간으로 모니터링합니다.",
        h8: "시장 스캔 및 비교",
        h9: "가격 차이가 발견되면 즉시 해당 거래 작업을 실행합니다. 한 플랫폼에서 매수하고 동시에 다른 플랫폼에서 매도하여 가격 차이의 이익을 얻습니다.",
        h10: "실시간 모니터링 및 보고",
        h11: "사용자는 차익 거래의 효과와 잠재적 기회를 종합적으로 평가하기 위해 자세한 거래 기록, 수익 통계 및 차트 표시를 볼 수 있습니다.",
        h12: "신속한 트랜잭션 실행",
        h13: "우리는 사용자가 적시에 시장 기회를 잡을 수 있도록 빠르고 효율적인 거래 실행을 제공하기 위해 최선을 다하고 있습니다.",
        h14: "우리의 거래 시스템은 많은 수의 주문을 처리하고 거래 실행에 대한 빠른 응답을 유지하도록 신중하게 설계되고 최적화되어 최상의 가격으로 거래할 수 있습니다. 거래 시스템은 안정적이고 신뢰할 수 있는 인프라에 구축되어 있습니다. 높은 수준의 내결함성 및 중복 메커니즘.",
        h15: "Reactor의 회원으로서 귀하는 독점적인 서버 지원을 즐길 수 있으며, 이는 거래의 실행 속도와 효율성을 더욱 향상시킬 것입니다.",
        h16: '분산 노드',
        h17: "컴퓨터실",
        h18: "서버 클러스터",
        h19: "전용 서버 무료 제공",
        h20: "Reactor는 전문 회원을 위한 전용 서버 리소스를 무료로 할당하여 로봇을 위한 독립적이고 효율적인 운영 환경을 제공합니다.",
        h21: "세부정보 보기",
        h22: "매일 안정적인 수입을 올리세요",
        h23: "로봇이 서로 다른 거래소 간의 가격 차이를 정확하게 포착하고 그들로부터 차익 거래 기회를 얻을 수 있도록 코인 및 금액, 선택한 시장, 교환 차익 거래 설정 및 시장 차익 거래 설정을 설정합니다.",
        h24: "동시에 로봇에는 복구 및 백로그 기능도 있어 시장 변동에 유연하게 대응하고 투자가 계속 성장하도록 보장할 수 있습니다.",
        h25: "자세히 알아보기",
    },
    join: {
        h1: "원자로 탐색 여정을 확장하고 적합한 솔루션을 선택하세요!",
        h2: "초보자",
        h3: "무료",
        h4: "하나의 템플릿 전략을 사용할 수 있습니다.",
        h5: "하나의 전략 조합 생성 가능",
        h6: "40가지 전략 조합 실행 가능",
        h7: "교환 2개 지원",
        h8: "투자 관리 포트폴리오",
        h9: "시작하자",
        h10: "고급",
        h11: "월",
        h12: "5가지 템플릿 전략을 사용할 수 있습니다.",
        h13: "5가지 전략 조합 생성 가능",
        h14: "200개의 전략 조합 실행 가능",
        h15: "교환 5개 지원",
        h16: "투자 관리 포트폴리오",
        h17: "로봇 트랜잭션 5개 복사 및 추적 가능",
        h18: "모의 거래 무료 사용",
        h19: "개방형 마켓플레이스 전략",
        h20: "고급",
        h21: "10가지 템플릿 전략을 사용할 수 있습니다.",
        h22: "15가지 전략 조합 생성 가능",
        h23: "500개의 전략 조합 실행 가능",
        h24: "교환 20개 지원",
        h25: "투자 관리 포트폴리오",
        h26: "로봇 트랜잭션 20개 복사 및 추적 가능",
        h27: "월간 거래 교육 과정 2회 받기",
        h28: "1:1 맞춤형 전략디자이너 서비스",
        h29: "Reactor AI 전략 및 AI 디자이너 지원",
        h30: "협력 전략가가 되기 위한 지원 신청서",
        h31: "VIP 전용 커뮤니티 무료 이용",
        h32: "전문",
        h33: "무제한 템플릿 사용 정책",
        h34: "전략 조합 무제한 생성",
        h35: "무제한 실행 전략 조합",
        h36: "무제한 교환",
        h37: "투자 관리 포트폴리오",
        h38: "로봇 트랜잭션 50개 복사 및 추적 가능",
        h39: "모든 월간 거래 교육 과정",
        h40: "1:1 맞춤형 전략디자이너 서비스",
        h41: "Reactor AI 전략 및 AI 디자이너 지원",
        h42: "추가 기술 지표 안내",
        h43: "초청 전략가가 되어 함께 수익을 공유할 수 있는 지원서를 지원합니다.",
        h44: "전용 서버",
        h45: "독점 정책 간격 확인",
        h46: "회원등급 비교",
        h47: "수동 거래",
        h48: "거래 로봇",
        h49: "로봇 복사",
        h50: "마켓 메이커 로봇",
        h51: "차익거래 로봇",
        h52: "무역 교육 과정",
        h53: "시뮬레이션 거래",
        h54: "일대일 맞춤형 전략",
        h55: "협력 전략가가 되기 위해 지원하세요",
        h56: "VIP 전용 커뮤니티",
        h57: "기술 지표 안내",
        h58: "특별초청 전략가가 되기 위해 신청하고 수익을 함께 나누세요",
        h59: "전용 서버",
        h60: "독점 정책 간격 확인",
    },
    manualTrading: {
        h1: "독립적인 결정을 내릴 수 있는 권한",
        h2: "양적 거래와 수동 거래의 완벽한 조합",
        h3: "더 보기",
        h4: "Reactor는 자동 거래 기능이 뛰어난데 왜 여전히 수동 거래 참여가 필요합니까?",
        h5: "보다 포괄적이고 포괄적인 거래 경험",
        h6: "거래 의사결정 권한을 유지하십시오",
        h7: "수동 거래는 사용자가 최종 거래 의사 결정 권한을 유지할 수 있도록 하며, 여전히 자신의 판단과 상황에 따라 거래에 대한 최종 결정을 내릴 수 있습니다.",
        h8: "이벤트 기반 트랜잭션",
        h9: "특정 이벤트 및 뉴스 발표는 시장에 상당한 영향을 미칠 수 있으며 이러한 이벤트는 완전히 예측 가능하지 않고 자동 거래 시스템에 적용되지 않을 수 있습니다.",
        h10: "전문적인 판단 및 경험",
        h11: "경험이 풍부한 거래자는 풍부한 시장 지식과 전문적인 판단력을 가지고 있으며 자신의 경험을 바탕으로 보다 정확한 결정을 내릴 수 있습니다.",
        h12: "시장 위험에 빠르게 대응하고 자산을 보호하십시오",
        h13: "비상 정지 해제",
        h14: "시장 상황이 좋지 않을 때 사용자는 Reactor에서 긴급 정지 청산 기능을 트리거할 수 있으며 원클릭 작업을 통해 모든 보유 자산을 판매할 수 있습니다.",
    },
    market: {
        h1: "현재의 암호화폐 시장",
        h2: "Reactor 플랫폼에서는 양적 투자 거래를 위해 10개 이상의 유동성 플랫폼에서 수천 개의 암호화폐를 선택할 수 있습니다. 우리는 다양한 암호화폐 선택을 제공하기 위해 많은 유명 거래소와 파트너십을 구축했습니다. ",
        h3: '암호화폐 시장',
        h4: "USD",
        h5: "24시간",
        h6: "바이낸스",
        h7: "우이",
        h8: "후오비",
        h9: "통화",
        h10: "가격",
        h11: "24시간 기복",
        h12: "24시간 거래량",
        h13: "시장 가치",
        h14: "1억",
        h15: "전체: 608개 항목, 각 페이지에 표시되는 항목: 20개 항목",
    },
    merchantRobot: {
        h1: "리액터의 마켓 메이커 로봇",
        h2: "시장에 더 잘 참여하고, 거래 결과를 개선하고, 투자 과정에서 더 나은 경험을 얻으십시오",
        h3: '더 보기',
        h4: "마켓 메이커 로봇이란 무엇입니까?",
        h5: "마켓 메이커 로봇은 Reactor가 자동화된 알고리즘과 프로그램을 통해 거래를 실행하는 제품입니다. 사전 설정된 거래 전략 및 규칙에 따라 자동으로 구매 및 판매 작업을 수행하고 시장 조건 및 주문서의 변화에 ​​따라 동적으로 견적을 조정하고 거래량.Reactor는 거래소 또는 블록체인 시장의 오더북을 모니터링하여 시장 깊이와 유동성에 따라 자동으로 매수 및 매도 주문을 제출하고 소액의 스프레드(Spread)로 거래합니다.대상 빈번한 거래 활동을 통해 수익을 얻는 것입니다. 보다 안정적이고 지속적인 시장 유동성을 제공합니다.",
        h6: "마켓 메이커 로봇의 장점",
        h7: "수익 잠재력",
        h8: "구매 가격과 판매 가격의 차이를 설정하고, 구매 및 판매 주문이 실행될 때 차액의 이익을 얻습니다.",
        h9: "유동성 제공",
        h10: "매수 및 매도 주문을 지속적으로 인용하고 제공하여 시장 참여자에게 유동성을 제공합니다.",
        h11: "자동 거래",
        h12: "호가 및 주문은 수동 개입 없이 시장 상황에 따라 실시간으로 조정될 수 있습니다.",
        h13: "시장 깊이",
        h14: "구매 및 판매 가격과 주문량을 지속적으로 조정하여 시장의 깊이를 높입니다.",
        h15: "왜 Reactor에서 마켓 메이커 로봇을 사용합니까?",
        h16: "전통적인 일반 마켓 메이커와 비교하여 Reactor가 출시한 마켓 메이커 로봇은 자동 실행, 기술적 이점, 빠른 시장 적응, 데이터 분석 및 최적화, 구성 유연성의 이점이 있습니다.",
    },
    policyBacktest: {
        h1: "전략 백테스트",
        h2: "사용자는 전략의 효과와 잠재적 수익성을 평가하기 위해 자신의 거래 전략에 따라 과거 시장 데이터에 대해 시뮬레이션 거래를 수행합니다.",
        h3: '더 보기',
        h4: "유연한 선택, 정확한 백테스트",
        h5: "거래쌍 및 기간 선택",
        h6: "개인 취향과 시장 상황에 따라 적절한 거래 쌍과 기간을 선택하여 전략의 성과와 잠재적 위험을 보다 정확하게 평가할 수 있습니다.",
        h7: "전략 성과를 향상시키기 위한 최상의 조합 찾기",
        h8: "파라미터 설정 및 최적화",
        h9: "거래 빈도, 손절, 손절 조건과 같은 매개 변수를 유연하게 조정하여 최상의 전략 구성을 찾을 수 있습니다. 매개 변수 조합을 지속적으로 시도하고 최적화하면 전략의 수익성과 안정성을 향상시키는 데 도움이 됩니다.",
        h10: "전략 성과에 대한 포괄적인 이해를 위한 상세 보고서",
        h11: "백테스트 결과 분석 및 보고서",
        h12: "Reactor는 자세한 백테스트 결과 보고서를 생성합니다. 보고서에는 수익률, 승률, 최대 되돌림과 같은 주요 지표가 포함되어 있어 전략의 성과를 완전히 이해할 수 있습니다. 장점과 개선의 여지를 발견하도록 도와주세요. 그에 따라 조정하십시오.",
        h13: "비주얼 디스플레이, 전략 변경에 대한 심층적 이해",
        h14: "차트 및 지표 표시",
        h15: "Reactor는 전략 백테스트 결과를 더 잘 이해할 수 있도록 직관적인 차트와 지표를 제공합니다. 가격 차트, 거래 신호 및 포지션과 같은 차트를 볼 수 있으며 다양한 기술 지표를 사용하여 전략 효과를 평가할 수도 있습니다. .",
        h16: "맞춤형 백테스팅 체계",
        h17: "Reactor는 맞춤형 백테스팅 솔루션, 맞춤형 백테스팅 결과 분석, 실시간 전략 조정 및 최적화, 맞춤형 거래 권장 사항 등 개인 맞춤형 백테스팅 경험을 제공합니다.",
        h18: "더 보기",
        h19: "원자로 전략 백테스팅의 기본 원칙",
        h20: "과거 시장 데이터를 기반으로 한 모의 거래 및 전략의 성과를 평가하고 검증하기 위한 거래 규칙 설정",
        h21: "과거 시장 데이터",
        h22: "Reactor는 시뮬레이트된 과거 시장 환경을 구축하기 위해 가격 및 거래량과 같은 지표를 포함한 과거 시장 데이터를 사용합니다.",
        h23: "시뮬레이션 트랜잭션 실행",
        h24: "설정된 거래 규칙에 따라 Reactor는 과거 시장 데이터에 대해 모의 거래 실행을 수행합니다.",
        h25: "백테스트 결과 평가",
        h26: "Reactor는 수율, 승률, 최대 되돌림 등과 같은 백테스트 기간 동안의 거래 기록을 기반으로 주요 지표를 계산합니다.",
    },
    rebate: {
        h1: "링크를 공유하고 추가 수입을 얻고 친구들과 코인을 받으세요!",
        h2: "제공된 링크를 공유하여 친구를 초대하세요. 친구가 Reactor에서 구매하는 모든 구독에 대해 최대 15% 커미션을 받을 수 있습니다.",
        h3: '세부정보 보기',
        h4: "수수료 철회 가능",
        h5: "무제한 프로모션",
        h6: "더 많이 홍보할수록 더 많은 보상을 받을 수 있습니다",
        h7: "최대 15% 수수료",
        h8: "왜 Reactor를 선택해야 하나요?",
        h9: "자산 보호",
        h10: "Reactor는 최고 수준의 보안 프로토콜과 암호화 기술을 채택하여 디지털 자산을 항상 가장 강력하게 보호합니다.",
        h11: "시장 동향에 대한 통찰력",
        h12: "Reactor는 포괄적인 데이터 통계 및 분석 도구를 제공하여 시장 동향 및 가격 변동에 대한 심층적인 이해를 돕고 시장 상황에 따라 거래 전략을 조정할 수 있습니다.",
        h13: "빠르고 효율적인 거래 경험",
        h14: "Reactor는 고속 트랜잭션 실행 및 주문 처리를 제공하여 시장 변화에 신속하게 대응하고 거래 기회를 잡을 수 있도록 합니다.",
        h15: "개인 정보 및 친구 정보를 보호하겠습니다",
        h16: "사용자의 보안과 개인정보 보호는 우리에게 가장 중요합니다. Reactor는 최고 수준의 보안 인프라를 갖추고 있어 관리 자산을 항상 완벽하게 보호합니다.",
        h17: "개인정보 동의 보기",
        h18: "추천 링크는 어떻게 사용하나요?",
        h19: "이메일 또는 개인 메시지로 친구에게 보내기.",
        h20: "소셜 미디어에 공유하세요.",
        h21: "개인 웹사이트에 게시됨.",
        h22: "YouTube에 동영상을 게시하고 댓글에 링크를 게시하세요.",
        h23: "초대 규칙 보기",
    },
    signal: {
        // h1: "안정적인 수익을 달성하기 위해 최고의 트레이더의 지혜를 따르십시오",
        // h2: "최고 거래자들의 지혜를 쉽게 따르고 안정적인 거래 수익을 얻을 수 있는 새로운 신호 거래 기능을 출시하게 된 것을 자랑스럽게 생각합니다.",
        // h3:'세부정보 보기',
        // h4: "나에게 맞는 신호를 선택하는 것이 성공적인 거래의 열쇠",
        // h5: "신호 주파수",
        // h6:"신호자가 거래 빈도 및 선호도에 얼마나 적합한지 결정하기 위해 신호를 보내는 빈도를 알아보세요.",
        // h7: "구독자 수",
        // h8:"신호기 인기도 및 커뮤니티 수용의 지표로 사용할 수 있는 신호기 가입자 수를 알 수 있습니다.",
        // h9: "등급 및 성능",
        // h10: "과거 실적과 신뢰성을 평가하기 위해 시그널러의 평가 방법, 지난 3개월 동안의 시그널 수, 거래당 평균 이익 등과 같은 지표를 살펴보십시오.",
        // h11: "통계 및 의견",
        // h12: "Signalr에 대한 자세한 정보와 사용자 경험은 Signalr의 실시간 통계 및 기타 사용자 리뷰를 확인하세요.",
        // h13: "거래 신호란 무엇입니까?",
        // h14: "시그널 트레이딩은 혁신적인 트레이딩 방법입니다. 전문 트레이더의 트레이딩 전략과 운영을 복사하여 전문 지식과 경험이 없어도 우수한 트레이더의 트레이딩 활동에 참여할 수 있습니다. 귀하의 트레이딩 행동을 최고의 트레이더와 동기화하십시오.",
        // h15: "더 보기",
        h1: "자동 거래의 지능형 엔진",
        h2: "고품질 거래 신호 템플릿을 획득하여 안정적인 투자 수익 달성",
        h3: '세부정보 보기',
        h4: "고품질 거래 신호 템플릿",
        h5: "엄격하게 선별 및 검증됨",
        h6: "매수 포인트, 매도 포인트, 손절 포지션 등과 같은 주요 지표를 포함하여 전문 트레이더의 실제 작업을 가져오고 구독 후 자동으로 복사할 수 있습니다.",
        h7: "Reactor의 엄격한 심사 하에서 신호의 평균 승률은 58.6%에 도달합니다.",
        h8: "몰에는 100개 이상의 신호 제공자가 있으며, 그들은 전문 거래자 또는 기관입니다.",
        h9: "자세히 알아보기",
        h10: "입학 지원서 제출",
        h11: "몰에서 거래 전략과 전문성을 보여주고 전 세계 거래자들과 성공적인 경험을 공유함으로써 영향력을 확대하고 잠재력을 얻을 수 있습니다.",
        h12: "문의하기",
        h13: "이 데이터를 사용하여 자신에게 맞는 신호를 보다 정확하게 선택할 수 있습니다.",
        h14: "신호 주파수",
        h15: "거래 기본 설정에 대한 적응을 결정하기 위해 신호의 빈도를 보냅니다.",
        h16: "구독자 수",
        h17: "커뮤니티 수용의 지표로 SignalR 구독자 수 파악",
        h18: '평점 및 성능',
        h19: "지난 3개월 동안의 신호 수, 거래당 평균 수익 및 기타 지표",
        h20: "통계 및 의견",
        h21: "언제든지 다른 사용자의 실시간 통계 및 댓글 확인",
    },
    strategy: {
        h1: "독특한 거래 전략을 사용자 정의하십시오",
        h2: "개인화된 거래 솔루션 제공",
        h3: '문의하기',
        h4: "전담 퀀트 전략가",
        h5: "맞춤형 거래 지원 및 전문적인 조언",
        h6: "초청 전략가가 되어 함께 수익을 공유하세요",
        h7: "전용 서버",
        h8: "맞춤형 거래 지원 및 전문적인 조언",
        h9: "우리는 각 고객의 투자 목표와 요구가 고유하다는 것을 알고 있으므로 전담 전략가를 통해 귀하와 협력하여 귀하의 거래 전략이 귀하의 목표와 밀접하게 일치하도록 할 것입니다.",
        h10: "전략",
        h11: "저희 전략가는 귀하와 일대일로 협력하여 귀하의 투자 목표, 위험 허용 범위 및 시간 요건에 대한 심층적인 이해를 얻을 것입니다.",
        h12: "맞춤형 솔루션",
        h13: "저희 팀은 귀하의 목표 달성을 돕기 위해 기대를 뛰어넘을 것입니다",
        h14: "비공개 클라이언트",
        h15: "가장 야심 찬 투자 목표를 달성하고 싶습니까? 우리는 수많은 고객이 수익을 극대화하고 비용을 절감하며 포트폴리오 자산을 늘리도록 도왔습니다.",
        h141: "기업",
        h151: "더 높은 재무 효율성과 투자 수익률을 추구하시나요? 우리는 다양한 기업과 협력하여 맞춤형 솔루션을 제공하여 자본 성장과 리스크 관리의 이중 목표를 달성하는 데 도움을 드립니다.",
        h16: "엔터프라이즈",
        h17: "우리는 귀하의 모든 필요를 충족합니다",
        h18: "Reactor는 당사의 주요 제품에 비해 독보적인 이점을 가지고 있습니다.",
        h19: "전담 퀀트 전략가",
        h20: "귀하의 필요와 목표에 따라 양적 거래 전략을 맞춤화할 수 있습니다. 그들은 귀하와 긴밀히 협력하여 귀하의 투자 선호도와 위험 허용 범위를 깊이 이해하고 시장 동향 및 데이터 분석을 기반으로 맞춤형 거래 조언을 제공할 것입니다. ",
        h21: "맞춤형 거래 지원 및 전문적인 조언",
        h22: "우리는 귀하의 거래 목표와 요구 사항을 깊이 이해하고, 목표 거래 전략과 제안을 제공하고, 거래 결정을 최적화하는 데 도움을 드릴 것입니다. 기술적 분석이든, 시장 조사 또는 위험 관리이든, 우리는 귀하에게 전문적인 지침과 지원하다.",
        h23: "초청 전략가가 되어 함께 수익을 나눠보세요",
        h24: "게스트 전략가로서 성공적인 거래 전략과 경험을 공유하고 다른 사용자와 함께 배우고 성장할 수 있는 기회를 갖게 될 것입니다. 거래 이익. ",
        h25: "전용 서버",
        h26: "저희 서버는 높은 성능과 짧은 대기 시간이 특징입니다. 거래 주문을 신속하게 처리하고 적시에 거래를 실행할 수 있습니다. 전용 서버를 사용하면 거래 기회를 더 잘 파악하고 거래의 성공률과 효과를 높일 수 있습니다. ",
        h27: "맞춤형 서비스",
        h28: "귀하의 거래 목표를 달성하고 양적 거래 분야에서 더 큰 성공을 거둘 수 있도록 도와드립니다. 귀하의 거래 스타일, 선호도 또는 목표에 관계없이 최선을 다해 최고 품질의 맞춤형 서비스를 제공하겠습니다.",
        h29: "문의하기",
    },
    strategyMall: {
        h1: "고품질 전략을 발견하고 거래 혁신을 달성하십시오",
        h2: "최고의 트레이더와 함께 시장 기회 탐색",
        h3: '세부정보 보기',
        h4: "전략 몰",
        h5: "거래 경험 최적화",
        h6: "검증되고 선택된 거래 전략",
        h7: "일회성 구매 평생 사용, 구매한 매매 전략을 사용할 수 있는 권리를 획득 및 보유할 수 있으며 본 전략을 다운로드 할 수 있습니다.",
        h8: "과거 수익률, 위험 지표 및 거래 빈도와 같은 정보를 포함한 자세한 성과 데이터.",
        h9: "각 전략의 실제 성과를 완전히 이해할 수 있도록 자세한 거래 내역을 제공합니다.",
        h10: "전략몰 자세히 알아보기",
        h11: "입학 지원서 제출",
        h12: "쇼핑몰에서 거래 전략과 전문성을 보여주고 전 세계 거래자들과 성공적인 경험을 공유하여 영향력과 수익 잠재력을 확장하십시오.",
        h13: "문의하기",
        h14: "리액터 전략몰의 장점은?",
        h15: "다양한 전략적 선택",
        h16: "유연한 자율 트랜잭션",
        h17: "전문 전략가",
        h18: "추세 추종, 스윙 트레이딩 또는 차익 거래 전략을 선호하든 자신에게 맞는 전략을 찾을 수 있습니다.",
        h19: "변화하는 시장 상황에 적응하기 위한 전략 유지를 기반으로 거래 매개변수를 유연하게 조정할 수 있는 능력",
        h20: "전략가는 시장 통찰력과 성공적인 거래 기록을 보유하고 있으며 귀하의 거래에 대한 강력한 지원을 제공할 것입니다.",
        h21: "자세히 알아보기",
        h22: "엄격한 정책 감독",
        h23: "거래 전략의 투명성과 신뢰성을 보장하기 위해 각 전략은 온라인에 들어가기 전에 검토 및 테스트 프로세스를 거쳐야 합니다.",
        h24: "시간이 검증된 전략",
        h25: "전략은 장기간 실시간 시뮬레이션과 배경에서 백테스팅을 거쳤으며 우리가 제공하는 과거 데이터는 완전히 실제입니다.",
    },
    business: {
        h1: "비즈니스 기회 창출 및 성공 공유",
        h2: "우리는 각계 각층의 파트너가 우리와 협력하기를 진심으로 환영합니다. 우리의 파트너 프로그램에 참여함으로써 당신은 우리의 전략적 파트너가 되어 공동으로 비즈니스 기회를 창출하고 윈윈 결과를 달성할 수 있는 기회를 갖게 될 것입니다.",
        h3: '문의하기',
        h4: "파트너 지원 및 리소스 공유",
        h5: "파트너로서 귀하는 우리가 제공하는 포괄적인 지원과 리소스를 즐길 수 있습니다. 우리는 귀하가 우리 제품과 서비스를 더 잘 이해하고 고객에게 고품질 솔루션을 제공할 수 있도록 전문 교육 및 기술 지원을 제공할 것입니다. 또한, 독점적인 파트너 마케팅 활동, 마케팅 자료 및 브랜드 프로모션을 포함한 마케팅 지원을 받게 됩니다. 우리는 비즈니스 성장을 주도하고 함께 성공을 공유하기 위해 귀하와 협력할 것입니다.",
        h6: "상생협력, 지속가능한 발전",
        h7: "우리는 파트너와의 긴밀한 협력을 중요하게 생각하며, 공동의 노력과 상호 지원을 통해 더 큰 성공을 거둘 수 있다고 믿습니다. 우리는 파트너 판매, 유통 및 판촉 계획을 포함한 유연한 협력 모델을 제공하여 파트너에게 수익성 있는 기회를 창출합니다. At 동시에, 우리는 우리가 지속적으로 개선하고 혁신하고 함께 발전할 수 있도록 파트너가 귀중한 피드백과 제안을 제공할 것을 권장합니다. 우리는 상생 협력의 개념을 통해 경쟁이 치열한 시장에서 계속 발전할 것이라고 믿습니다.",
        h8: "함께해요",
        h9: "우리와 협력하는 데 관심이 있다면 우리와 함께하는 것을 환영합니다. 당신이 혁신적인 스타트업이든, 확고한 기술 제공자이든, 새로운 비즈니스 기회를 찾고 있는 회사이든 관계없이 우리는 당신과 협력하고 싶습니다. 우리의 파트너 프로그램에 가입하면 우리와 긴밀히 협력하고 우리의 자원과 경험을 공유하며 공동으로 시장을 확장하여 윈-윈 상황을 달성할 수 있는 기회를 갖게 될 것입니다.",
    },
    dowView: {
        h1: "클라이언트 다운로드",
        h2: "양적 거래의 무한한 가능성을 탐색하고 시장 변동에 침착하게 대처하십시오.",
        h3: '무료 평가판',
    },
    contactView: {
        h1: "저희에게 연락하거나 저희 사무실 방문 약속을 잡으십시오",
        h2: "심천시 바오안구 신안가 바오민 1로 바오통 빌딩 608호",
        h3: '지금 상담하기',
    },
    helpView: {
        h1: "도움이 더 필요하세요?",
        h2: "저희 팀에 메시지를 남겨주시면 근무 시간 중에 연락드리겠습니다.",
        h3: '지원팀에 문의하기',
    },
    token: {
        h1: "계약",
        h2: "시장 통계",
        h3: '시장 가치 순위',
        h4: "시장 가치",
        h5: "1억",
        h6: "24시간 거래량",
        h7: "24시간 최고",
        h8: "역사상 최고가",
        h9: "최대 공급량",
        h10: "이더리움(ETH) 실시간 시장",
        h11: "이더리움의 현재 가격은 $1,786.68이며, 그 가치는 지난 24시간 동안 0.31% 하락했습니다. 이더리움은 사상 최고가인 $4,878.26에서 64.33% 하락했습니다. 이더리움은 시가총액에서 2위를 차지하고 있으며, 전체 암호화폐 시장의 19.08% .Ethereum은 분산형 통화이며 Reactor의 중앙화 및 분산형 유동성 플랫폼을 통해 ETH를 사고 팔고 정량적으로 거래할 수 있습니다.",
        h12: "이더리움이란 무엇입니까?",
        h13: "Ethereum은 개발자가 블록체인 위에 분산 응용 프로그램(dApp)을 구축할 수 있는 분산형 블록체인 플랫폼입니다. 2015년 Vitalik Buterin이 출시한 Ethereum은 생태계, 활성 커뮤니티 및 혁신적인 기능을 통해 가장 이더리움(ETH)은 이더리움 고유의 암호화폐로 플랫폼에서 다양한 용도로 사용 가능하며 이더리움 네트워크에서 가스를 지불하고 플랫폼 거버넌스 시스템에 참여하는 수단으로 사용된다. 이더리움의 가장 중요한 두 가지 기능은 상호 운용성과 확장성 전자는 이더리움이 다른 블록체인과 통합할 수 있는 능력을 말하며 네트워크와 플랫폼이 연결되고 통신하여 더 큰 기능과 호환성을 제공합니다. 2022년 9월 이더리움은 복잡성으로 인해 업계에서 가장 중요한 업그레이드 이벤트 중 하나로 불리는 The Merge라는 주요 업그레이드를 거쳤습니다. 작업 증명 합의 알고리즘에서 보다 효율적인 지분 증명 알고리즘으로의 전환을 포함하며, 목적은 에너지 소비 감소, 확장성 향상 및 보안 향상이므로 스테이킹을 통한 이더리움 트랜잭션 검증이 필요합니다. 오랫동안 기다려온 합병이지만, 비탈릭의 비전을 완성하기 위한 이더리움의 합병은 오히려 반드시 이루어져야 할 일련의 주요 업데이트의 시작입니다.",
        h14: "왜 Reactor가 양적 거래에 적합한 선택인지",
        h15: "분산 투자",
        h16: "거래소와 탈중앙화 지갑에 자금을 분산시켜 효과적으로 위험을 줄이고 잠재적인 수익을 높입니다.",
        h17: "데모 계정 지원",
        h18: "실제 시장 시뮬레이션 환경을 제공하여 무위험 방식으로 투자 관행 및 전략 테스트를 수행할 수 있습니다.",
        h19: "거래 데이터 통계",
        h20: "당신은 우리 로봇을 사용하여 안심하고 거래하고 편리하고 안전한 투자 경험을 즐길 수 있습니다.",

        BTC0: '비트코인은 탈중앙화된 암호화폐입니다. 2008년에 발표된 백서에서 중본춘(Satoshi Nakamoto)이라는 가명으로 알려진 개인 또는 집단이 비트코인의 메커니즘을 처음으로 논의했습니다. 2009년 1월에 비트코인이 공식적으로 세상에 나왔습니다.',

        BTC1: '비트코인은 피어 투 피어 네트워크 기반의 화폐로, 모든 거래는 중간 사람의 승인이나 중재 없이 동등하고 독립적인 네트워크 참여자들 간에 직접 이루어집니다. 중본춘 본인의 말에 따르면 비트코인을 만든 목적은 "한 쪽이 금융 기관을 거치지 않고 다른 쪽에게 직접 온라인 결제를 할 수 있게 하기 위함"입니다.',

        BTC2: '비트코인 이전에도 유사한 탈중앙화 전자화폐 컨셉이 등장했지만, 비트코인의 독특한 점은 실제 생활에서 적용된 최초의 암호화폐라는 것입니다.',

        ETH0: '이더리움은 탈중앙화된 오픈 소스 블록체인 시스템으로, 자체적인 암호화폐인 이더를 보유하고 있습니다. 이더리움은 다른 많은 암호화폐의 거래 플랫폼이기도 하며, 탈중앙화된 스마트 계약을 실행할 수도 있습니다.',

        ETH1: 'Vitalik Buterin은 2013년에 발표한 백서에서 처음으로 이더리움을 소개했습니다. Buterin과 다른 공동 창업자들은 2014년 여름에 프로젝트의 온라인 크라우드 펀딩을 통해 이더리움 블록체인을 공식적으로 출시했습니다.',

        ETH2: '이더리움은 전 세계 사용자 모두가 검열을 받지 않고 소프트웨어를 작성하고 실행할 수 있는 글로벌 탈중앙화 어플리케이션 플랫폼을 목표로 합니다. 시스템 장애나 사기 행위 없이 사용될 수 있습니다.',

        USDT0: '테더(USDT)는 디지털 화폐로, 그 가치는 미국 달러를 반영합니다. 테더는 2014년에 출시되었으며, 디지털 달러 또는 "스테이블코인"으로 사용할 수 있는 안정적인 암호화폐를 만들기 위한 목적으로 개발되었습니다. 테더의 가격은 미국 달러에 매칭됩니다.',
        USDT1: 'テザー（USDT）は最初、ビットコインネットワークのオムニレイヤーを伝送プロトコルとして使用していましたが、現在はイーサリアム上でもERC-20トークンとして利用することができます。テザーはビットコイン（オムニおよびLiquidプロトコル）、イーサリアム、EOS、トロンブロックチェーン上で発行されています。',

        USDT2: 'テザーはTether Limitedによって発行されており、この会社は暗号通貨取引所Bitfinexと同じCEOを持っています。Tetherはかつて100％のドル準備金をテザーに保有していると主張していましたが、2019年に同社の弁護士はサポートされているテザーの割合が74％しかないと述べました。これに対するTetherの説明は、子会社の融資も準備金の一部であるというものです。',

        BNB0: 'バイナンスコイン（BNB）は、2017年に初めてトークンセールを通じて発行され、11日後にバイナンス暗号通貨取引所が開始しました。バイナンスコインは最初、イーサリアムネットワークでERC-20トークン形式で発行されましたが、現在はバイナンスチェーンでのメインネットの稼働に伴い、ERC-20バイナンスコインとBEP2バイナンスコインが1：1の比率で交換可能であり、イーサリアム上では利用されなくなりました。',

        BNB1: 'バイナンスコインは支払い手段として使用することができ、ユーザーはこの実用的なトークンを使用してバイナンス取引所で手数料を差し引き、LaunchPadでのトークンセールに参加することもできます。バイナンスコインはまた、バイナンス分散型取引（DEX）の主要な通貨です。',


        XRP0: 'まず、リップルコイン（XRP）、リップル（Ripple）およびリップルネット（RippleNet）の違いを理解する必要があります。リップルコインはデジタル決済プラットフォームであるリップルネット上で動作する通貨です。リップルネットは分散台帳データベース（またはXRPレジャー（リップル台帳）とも呼ばれる）に基づいて作成されました。リップルネットはリップル（Ripple）社が運営していますが、リップル台帳はオープンソースであり、ブロックチェーン技術ではなく、先述の分散台帳データベースに基づいています。',
        XRP1: '瑞波网支付平台是一个实时全额支付（RTGS）系统，旨在实现全球范围内的即时货币交易。虽然瑞波币是瑞波账本的原生加密货币，用户仍然可以在该平台上使用任何一种货币进行交易。',

        XRP2: '瑞波支付平台背后的理念由Ryan Fugger于2004年首次提出，但当Jed McCaleb和Chris Larson在2012年接管了项目后，创建瑞波公司的计划才提上日程（当时，瑞波也被称为OpenCoin）。',

        USDC0: 'USDC는 1대 1로 묶인 달러와 연동된 안정화토큰입니다. 이 암호화폐는 1달러의 예비 자금을 포함한 현금 및 미국 정부 단기채로 지원됩니다. 이 자산의 중앙 기반 단체는 규제 받는 금융 기관에 의해 발행된 USDC라고 설명했습니다.',

        USDC1: '이 안정화토큰은 2018년 9월에 한정적으로 발행되었습니다. 간단히 말해서, 미국달러 코인의 슬로건은 "디지털 시대의 디지털 화폐"입니다 - 이 안정화토큰은 현금 사용이 점차 흔해지는 세계를 위해 설계되었습니다.',

        USDC2: 'アメリカドルコイン（USDC）はすでにいくつかの使用事例があります。暴落時には暗号通貨トレーダーに安全な避難所を提供するだけでなく、ステーブルコインの推進者はデジタル資産の支払いを受け入れる企業や、分散型金融やゲームなどの様々な業界に影響を与えることもできると述べています。',

        DOGE0: 'ドージコイン（DOGE）の名前はインターネットで流行しているフレーズ「DOGE」に由来し、そのロゴにはシバ犬が描かれています。このオープンソースの暗号通貨は、アメリカオレゴン州ポートランドのビリー・マーカス（Billy Markus）とオーストラリアシドニーのジャクソン・パーマー（Jackson Palmer）によって共同作成され、2013年12月にはライトコインから派生しました。ドージコインの創設者たちは、それをシンプルで楽しい仮想通貨に設定し、ビットコインの中核対象以外のユーザーを引き付けることを望みました。なぜなら、それはインターネット上で超人気のある「doge」のエモートコンを元にしているからです。TeslaのCEOであるイーロン・マスクもドージコインをお気に入りだと何度かツイートしています。',

        ADA0: 'Cardanoはプルーフオブステークメカニズムを採用したブロックチェーンプラットフォームであり、「変革者、イノベーター、先見者」による積極的なグローバルな変化を実現することを目指しています。',
        ADA1: 'このオープンソースプロジェクトのもう一つの目標は、「無責任な機関から個人への権力の再分配」であり、より安全で透明性と公平性のある社会を創造することです。',
        ADA2: 'Cardanoは2017年に設立され、ADA（エイダコイン）トークンの設計は所有者がネットワーク運営に参加できることを保証するために行われました。したがって、この暗号通貨を保有する人々は、どんな改良提案にも投票する権利を持っています。',
        ADA4: '農業企業はCardanoを使用して土地から食卓までの新鮮な農産物の追跡を行うことができますし、このプラットフォームの他の製品は教育資格証明書の改竄を防ぐために保管されるか、小売業者が偽造商品を排除するのを支援します。',


        SOL0: 'Solana（ソラナ）は、ブロックチェーン技術のパーミッションレスな特性を利用し、分散型金融（DeFi）ソリューションを提供する強力なオープンソースプロジェクトです。このプロジェクトのアイデアと初期の作業は2017年に始まりましたが、Solanaは2020年3月にスイス・ジュネーブに本部を置くSolana Foundationによって正式に発表されました。',
        SOL1: 'このプロジェクトについての詳細情報は、Solanaの深堀り記事をご覧ください。',
        SOL2: 'Solanaプロトコルは、分散型アプリケーション（DApp）の開発を促進することを目指しています。スケーラビリティを向上させるために、履歴的証明（PoH）とブロックチェーンの下にあるステーク証明（PoS）コンセンサスを組み合わせています。',

        TRX0: 'Tron（トロン）は、孫偉宇（ジャスティン・サン）によって2017年にTron Foundationを通じて設立された暗号通貨です。Tron Foundationの本部はシンガポールにあります。2017年、Tron Foundationの初回トークンセール（ICO）は1000億TRXを創出し、7000万ドルを調達しました。',
        TRX1: 'Tronプロトコルは、オペレーティングシステム全体のアーキテクチャを記述しており、ブロックチェーン技術に基づいて、開発者がスマートコントラクトや分散型アプリ（DApps）を作成し、データやその他のコンテンツを自由に公開、所有、保存できるようにします。Tron Foundationによれば、このネットワークを中心としたエコシステムは、大規模なスケーラビリティと一貫性のある信頼性を提供し、高いスループット計算によりトランザクション処理を高速化することができます。',
        TRX2: 'かつてTronコインはイーサリアムネットワーク上に構築されましたが、2018年に独自のネットワークに移行しました。ERC-20のTronコイン保有者は、トロンネットワーク上のTRXデジタル通貨と交換し、イーサリアム上のTronトークンは廃止されました。',

        Matic0: 'Polygon（ポリゴン）は、イーサリアムの拡張とインフラストラクチャ開発のための合理的で使いやすい初めてのプラットフォームです。その中心コンポーネントはPolygon SDKであり、さまざまなタイプのアプリケーションを構築するためのモジュール化された柔軟なフレームワークを提供します。',
        Matic1: 'このプロジェクトについての詳細情報は、Polygon Maticの深堀り記事をご覧ください。',
        Matic2: '使用 Polygon，可以创建乐观卷积链、ZK 卷积链、独立链或开发人员需要的任何其他类型的基础设施。',

        LTC0: '莱特币（LTC）は、暗号通貨であり、ブロックチェーン技術を利用して迅速かつ安全かつ低コストの支払い機能を提供することを目指しています。',
        LTC1: 'この暗号通貨は、ビットコイン（BTC）プロトコルをベースに作成されており、ハッシュアルゴリズム、上限値（Hard Cap）、ブロックトランザクション時間などで異なる要素があります。莱特币のブロック生成時間はわずか2.5分であり、取引手数料も非常に低いため、小額取引や店頭支払いに非常に適しています。',
        LTC2: '莱特币は2011年10月7日にオープンソースユーザーによってGitHub上で発表され、その後、2011年10月13日に公式にネットワークが開始されました。以来、莱特币は商業利用と受け入れ度が急速に増加し、登場以来、市場価値の上位10位に位置しています。',
        LTC3: 'この暗号通貨は、元Googleの従業員であるCharlie Leeによって作成されました。彼は莱特币をビットコインのより簡略化されたバージョンにすることを望み、ビットコインと同様の特性（重要性と汎用性を除く）を多く開発しました。',

        AVAX0: 'Avalanche（アヴァランチ）は、第1層ブロックチェーンであり、分散型アプリケーションやカスタムブロックチェーンネットワークのプラットフォームとして機能します。それはEthereumの競合相手の1つであり、最も人気のあるスマートコントラクトブロックチェーンになることを目指しています。それは、スケーラビリティに影響を与えずに、毎秒6500回のトランザクション出力を実現することを目指しています。',
        AVAX1: 'これはAvalancheのユニークなアーキテクチャによるものです。Avalancheネットワークは、Xチェーン、Cチェーン、Pチェーンの3つの独立したブロックチェーンから構成されています。それぞれのチェーンには異なる目的があり、これはビットコインやEthereumが使用する方法（すべてのノードですべてのトランザクションを検証する）とはまったく異なります。Avalancheブロックチェーンでは、使用状況に応じて異なる合意メカニズムが使用されます。',

        AVAX2: '2020년 메인넷 론칭 이후, Avalanche는 자체 DApps 및 DeFi 생태계 개발에 주력하고 있습니다. Ethereum 기반의 프로젝트인 SushiSwap 및 TrueUSD와 같은 다양한 프로젝트가 Avalanche와 통합되었습니다. 또한, 이 플랫폼은 Ethereum과의 상호 운용성을 향상시키기 위해 다리 개발 등의 노력을 계속하고 있습니다.',
        ETC0: '이더리움 클래식(ETC)은 이더리움(ETH)의 하드포크로 2016년 7월 출시됐다.주요 기능은 지능형 계약 네트워크로서 분산 애플리케이션 (DApps) 을 호스팅하고 지원하는 것입니다.기본 토큰은 ETC입니다.',
        ETC1: '출시 이후 이더리움 클래식은 이더리움과의 차별화를 추구해 왔으며, 시간이 지남에 따라 이 두 네트워크의 기술 로드맵도 점점 어긋나고 있습니다.',
        ETC2: '중대한 해킹 사건으로 360만 이더리움이 도난당한 후, 이더리움 클래식은 먼저 기존 이더리움 블록체인의 무결성을 유지하기 시작했다.',
        ARB0: '',
        SUI0: 'Sui는 지연 시간이 적고 처리량이 많은 계층 1 블록체인입니다.즉각적인 거래 결제 능력으로 Sui는 DeFi 및 GameFi와 같은 체인의 사용 사례에 적합한 블록 체인이되었습니다.이 블록 체인은 Rust로 작성됩니다 (Rust는 빠르고 안전한 거래 실행에 초점을 맞춘 프로그래밍 언어입니다).Rust는 또한 고속 블록 체인 Solana가 사용하는 또 다른 프로그래밍 언어입니다.',
        SUI1: "Sui가 밝힌 목표는 이더리움과 같은 블록체인 경쟁사보다 탁월한 스마트 계약 구조를 통해 탈중심화 애플리케이션 (DApp) 을 배치하여'다음 10억 명의 사용자를 웹 3에 이끌겠다'는 것이다.블록체인은병행 프로토콜에서 거래를 처리할 수 있도록 하는거래 병행화라는 프로세스를 이용한다.Sui가 말했듯이, 이러한'병렬 확장'은 비잔틴 내결함성 권익 증명 (PoS) 합의 메커니즘에서 더 나은 데이터 조직 방식을 실현할 수 있습니다.",
        DAI0: '',
        LINK0: '',
        UNI0: 'Uniswap은 탈중심화 금융 (DeFi) 토큰의 자동 거래를 촉진하는 데 있어 유명한 탈중심화 거래 프로토콜입니다.',
        UNI1: '자동시장조성업자(AMM)의 한 예로 유니스웨프는 2018년 11월에 출시되었지만 데파이 현상과 관련 토큰 거래의 급증으로 올해 상당한 인기를 얻었습니다.',
        UNI2: 'Uniswap은 토큰 거래를 자동화하고 토큰을 보유한 모든 사람에게 완전히 개방하는 동시에 전통적인 거래소와 비교한 거래 효율을 높이기 위한 것입니다.',
        UNI3: 'Uniswap은 자동화 솔루션을 통해 유동성 문제를 해결하고 첫 번째 탈중심화 거래소를 괴롭히는 문제를 피하여 효율을 높입니다.',
        UNI4: '2020년 9월, Uniswap은 한 걸음 더 나아가 자체 거버넌스 토큰인 UNI를 만들고 계약의 과거 사용자에게 부여했다.이것은 이윤 창출 잠재력을 증가시킬 뿐만 아니라 사용자로 하여금 자신의 미래를 창조할 수 있게 한다. 이것은 탈중심화 실체의 매력적인 측면이다.',
        EOS0: '',
        OP0: '',


    },
    wallet: {
        h1: "Web3 양적 거래 지갑",
        h2: "무한한 거래 잠재력을 잠금 해제하고 뛰어난 양적 거래 경험을 얻으십시오.",
        h3: '무료 평가판',
        h4: "분산 투자",
        h5: "자산 보안",
        h6: "빠르고 효율적인 거래 경험",
        h7: "리액터 월렛 정량화의 장점",
        h8: "지갑 수량화는 보다 안전하고 유연하며 개인화된 거래 경험을 제공합니다",
        h9: "개인 자산 보안 에스코트",
        h10: "Reactor는 거래를 위해 개인 지갑 계정을 사용하며 거래소보다 더 큰 통제력과 보안을 가지고 있습니다.",
        h11: "여러 블록체인에서 원활하게 통합된 정량화",
        h12: "Reactor는 단일 체인에 의해 제한되지 않고 여러 블록체인 네트워크를 통합하여 원활한 자산 전송 및 거래를 달성하고 더 많은 투자 기회를 얻습니다.",
        h13: "스마트 저가스 요금 루트",
        h14: "Reactor는 현재 네트워크 상황을 자동으로 분석하고 가스비가 가장 낮은 경로를 선택하여 트랜잭션을 실행하여 트랜잭션 비용을 줄일 수 있습니다.",
        h15: "개인 자산 안전 에스코트",
        h16: "Reactor는 거래에 개인 지갑 계정을 사용하며 거래소보다 통제력과 보안이 더 뛰어납니다.",
        h17: "메인 HD 지갑을 만들고 Web3 세계 탐색을 시작하세요",
        h18: "메인 HD 지갑은 귀하의 고유한 ID이며 여러 비수탁 지갑에 연결할 수 있습니다.",
        h19: "메인 HD 지갑을 통해 지갑마다 다른 개인 키와 암호를 생성하고 기억하지 않고도 여러 비수탁형 지갑을 쉽게 관리하고 제어할 수 있습니다.",
        h20: "메인 HD 지갑은 자세한 통계 기능을 제공하여 거래 내역, 자산 분배 및 소득을 이해하고 정보에 입각한 투자 결정을 내릴 수 있도록 도와줍니다.",
        h21: "기본 HD 지갑은 매우 유연하며 필요와 선호도에 따라 개인화할 수 있으므로 디지털 자산을 더 잘 맞춤화하고 관리할 수 있습니다.",
        h22: "맞춤형 전략",
        h23: "Reactor는 자신의 필요와 전략에 따라 맞춤형 거래를 수행할 수 있습니다. Exchange API의 제한 없이 자신의 거래 전략과 위험 선호도에 따라 양적 거래를 수행할 수 있습니다.",
        h24: "멀티체인 환경에 대한 강력한 지원",
        h25: "Reactor는 다른 체인에서 거래할 수 있습니다. 이것은 시장 조건 및 자산 할당에 따라 거래할 수 있는 더 많은 옵션과 유연성을 제공합니다.",
    },
    message: {
        home: {
            h1: "참여 자격을 갖추려면 NFT가 필요합니다.",
            h2: "반복적으로 참여하지 마세요",
            h3: "먼저 상위에 바인드",
            h4: "권한 부여에 성공했습니다. 후속 작업을 완료하십시오.",
            h5: "인증 실패",
            h6: "참여 성공",
            h7: "참여 실패"
        },
        nft: {
            h1: "권한 부여에 성공했습니다. 후속 작업을 완료하십시오.",
            h2: "인증 실패",
            h3: "전송 성공",
            h4: "전송 실패",
            h5: "구매 성공",
            h6: "구매 실패"
        },
        node: {
            h1: "먼저 상위에 바인드",
            h2: "권한 부여에 성공했습니다. 후속 작업을 완료하십시오.",
            h3: "인증 실패",
            h4: "구매 성공",
            h5: "구매 실패",
            h6: "먼저 상위에 바인드",
            h7: "인증에 성공했습니다. 후속 작업을 완료하십시오.",
            h8: "인증 실패",
            h9: "성공적으로 구매했습니다",
            h10: "구매 실패",
            h11: "성공적으로 수신됨",
            h12: "수신 실패"
        },
        team: {
            h1: "복사 성공",
            h2: "복사 실패",
            h3: "바인딩 성공",
            h4: "바인딩 실패"
        },
        game: {
            h1: "수령에 성공했습니다",
            h2: "찾을 수 없음"
        },
        unit: {
            h1: "링크 지갑 없음"
        }
    }
}
// {{$t("home.")}}
// this.$t("message.")