<template>
    <div class="merchantRobot">

        <div class="box1">
            <div class="main box1Main">
                <div class="left">
                    <div class="top">
                        <div class="leftH1" style="display:flex;">
                            <span v-for="(item, index) in h1Arr($t('merchantRobot.h1'))" :key="index"
                                :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
                                :style="{ animationDelay: `${index * 0.02}s` }">{{ item }}
                                <span v-if="getI18nByEn()">&nbsp;</span>
                            </span>
                        </div>
                        <div class="leftH2" style="display:flex;">
                            <span v-for="(item, index) in h1Arr($t('merchantRobot.h2'))" :key="index"
                                :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
                                :style="{ animationDelay: `${(index * 0.02) + (h1Arr($t('merchantRobot.h1')).length * 0.02)}s` }">
                                {{ item }}
                                <span v-if="getI18nByEn()">&nbsp;</span>
                            </span>
                        </div>
                    </div>
                    <div class="btm" @click="goFaq">
                        <span class="textSpan">
                            {{ $t('merchantRobot.h3') }}
                        </span>
                        <span class="solidSpan">
                            <img src="../assets/chainData/home_button_right_normal.svg" />
                        </span>
                    </div>
                </div>
                <img class="right fadeInDown" src="../assets/merchantIMG.svg" alt="">
            </div>
        </div>
        <div class="main">
            <div class="box2">
                <div class="h1">{{ $t('merchantRobot.h4') }}</div>
                <div class="h2">{{ $t('merchantRobot.h5') }}</div>
            </div>
            <div class="box3">
                <div class="title">{{ $t('merchantRobot.h6') }}</div>
                <div class="big">
                    <div class="small">
                        <img src="../assets/merchan1.svg" alt="">
                        <div class="h1">{{ $t('merchantRobot.h7') }}</div>
                        <div class="h2">{{ $t('merchantRobot.h8') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/merchan2.svg" alt="">
                        <div class="h1">{{ $t('merchantRobot.h9') }}</div>
                        <div class="h2">{{ $t('merchantRobot.h10') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/merchan3.svg" alt="">
                        <div class="h1">{{ $t('merchantRobot.h11') }}</div>
                        <div class="h2">{{ $t('merchantRobot.h12') }}</div>
                    </div>
                    <div class="small">
                        <img src="../assets/merchan4.svg" alt="">
                        <div class="h1">{{ $t('merchantRobot.h13') }}</div>
                        <div class="h2">{{ $t('merchantRobot.h14') }}</div>
                    </div>
                </div>
            </div>
            <div class="box4">
                <div class="h1">{{ $t('merchantRobot.h15') }}</div>
                <img class="animate__animated animate__fadeIn" src="../assets/Group 97.svg" alt="">
                <div class="h2">{{ $t('merchantRobot.h16') }}</div>
            </div>
            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import { mapGetters } from "vuex"
import dowView from './simViews/dowView.vue';
big.NE = -40;
big.PE = 40;
export default {
    components: { dowView },
    name: "merchantRobot",
    data() {
        return {
        };
    },
    methods: {
        goFaq() {
            // if (this.$router.currentRoute.path === '/faq') {
            //     // 当前路径已经是目标路径，不进行重复导航
            //     return
            // }
            // this.$router.push({
            //     path: "/faq",
            // });
            window.open('https://support.rac.art/chan-pin/zuo-shi-shang-ji-qi-ren/shen-me-shi-zuo-shi-shang-ji-qi-ren', '_blank')
        },
        getI18nByEn() {
            return this.$i18n.locale == 'en';
        },
    },
    computed: {
        ...mapGetters(['GET_IsAnimation']),
        h1Arr(text) {
            if (this.$i18n.locale == 'en') {
                return text => text.split(" ");
            } else {
                return text => text.split("")
            }
        }
    },
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.textAnimation {
    display: block;
    animation: tracking-in-expand-fwd-bottom 0.1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.fadeInDown {
    animation: tracking-in-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes tracking-in-top {
    0% {
        transform: translateY(-50 * $defaultSize);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.textAnimation_none {
    animation: none;
    animation-play-state: paused;
    animation-fill-mode: forwards;
}

@keyframes tracking-in-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(3 * $defaultSize);
    }
}

@keyframes tracking-in-expand-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        opacity: 0;
    }

    40% {
        opacity: 0.1;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

.merchantRobot {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;

    .box1 {
        .box1Main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }

        height: 400 * $defaultSize;
        // background-image: url("../assets/signalBGI.png");
        background: url("../assets/merchantBGI.svg") no-repeat;
        background-size: 100% 100%;
        justify-content: center;

        .left {
            width: 60%;

            .top {
                .leftH1 {
                    font-size: 40 * $defaultSize;
                    font-family: Inter-Semi Bold, Inter;
                    font-weight: 700;
                    color: #000000;
                    margin-bottom: 10 * $defaultSize;
                    display: flex;
                    flex-wrap: wrap;
                }

                .leftH2 {
                    font-size: 25 * $defaultSize;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    color: #4F5559;
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            .btm {
                cursor: pointer;
                display: inline-block;
                width: 100 * $defaultSize;
                height: 35 * $defaultSize;
                background-color: #224FD9;
                line-height: 35 * $defaultSize;
                border-radius: 5 * $defaultSize;
                font-size: 15 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                margin-top: 25 * $defaultSize;
                font-weight: 600;
                color: #FFFFFF;

                .textSpan {
                    margin-left: 10 * $defaultSize;
                }

                img {
                    width: 13 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .btm:hover {
                background: #1F41A7;

                .solidSpan {
                    img {
                        animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }

        .right {
            width: 35%;

            img {
                width: 100%;
            }
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .box2 {
            .h1 {
                font-size: 30 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 600;
                color: #333333;
                margin-top: 50 * $defaultSize;
            }

            .h2 {
                font-size: 14 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #333333;
                margin-top: 25 * $defaultSize;
            }
        }

        .box3 {
            padding-top: 88 * $defaultSize;

            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: 700;
                color: #333333;
                margin-bottom: 20 * $defaultSize;
            }

            .big {
                display: flex;
                justify-content: space-between;

                .small {
                    width: 23%;
                    text-align: center;

                    img {
                        width: 75 * $defaultSize;
                        transform: scale(1);
                        transition: transform 0.5s;
                    }

                    img:hover {
                        transform: scale(1.1)
                    }

                    .h1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                    }

                    .h2 {
                        margin-top: 19 * $defaultSize;
                        font-size: 13 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }

        .box4 {

            padding: 108 * $defaultSize 0;

            .h1 {
                font-size: 20 * $defaultSize;
                font-family: Inter-Semi Bold, Inter;
                font-weight: normal;
                color: #333333;
                margin-bottom: 25 * $defaultSize;
            }

            img {
                width: 100%;
            }

            .h2 {
                margin-top: 25 * $defaultSize;
                font-size: 14 * $defaultSize;
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                color: #4F5559;
            }
        }

    }
}</style>
  
  