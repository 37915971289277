<template>
    <div class="download">
        <div class="main">
            <div class="h1">{{ $t('download.h1') }}</div>
            <div class="h2">{{ $t('download.h2') }}</div>
            <div class="box1">
                <div class="left">
                    <div class="top">
                        <div class="leftH1">{{ $t('download.h3') }}</div>
                        <div class="leftH2">{{ $t('download.h4') }}</div>
                    </div>
                    <div class="btm">
                        <div class="DL1">
                            <!-- <img src="../assets/home_icon_apple2_normal.png" alt="">
                            <div class="btmR">
                                <div class="btmR1">{{ $t('download.h5') }}</div>
                                <div class="btmR2">Mac OS</div>
                            </div> -->
                        </div>
                        <div class="DL2">
                            <!-- <img src="../assets/home_icon_windows2_normal.png" alt="">
                            <div class="btmR">
                                <div class="btmR1">{{ $t('download.h5') }}</div>
                                <div class="btmR2">Windows OS</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img src="../assets/MacBookPro16.png" alt="">
                </div>
            </div>
            <div class="box2">
                <div class="title">{{ $t('download.h6') }}</div>
                <div class="big">
                    <div class="small animate__animated animate__flipInX">
                        <img src="../assets/icon_safe.png" alt="">
                        <div class="smallH1">{{ $t('download.h7') }}</div>
                        <div class="smallH2">{{ $t('download.h8') }}</div>
                    </div>
                    <div class="small animate__animated animate__flipInX">
                        <img src="../assets/icon_trend.png" alt="">
                        <div class="smallH1">{{ $t('download.h9') }}</div>
                        <div class="smallH2">{{ $t('download.h10') }}</div>
                    </div>
                    <div class="small animate__animated animate__flipInX">
                        <img src="../assets/icon_fast.png" alt="">
                        <div class="smallH1">{{ $t('download.h11') }}</div>
                        <div class="smallH2">{{ $t('download.h12') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
big.NE = -40;
big.PE = 40;
export default {
    name: "download",
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {},
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.download {
    border: 1px #FFF solid;

    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;
    .main {
        width: 750 * $defaultSize;
        margin: auto;

        .h1 {
            font-size: 40 * $defaultSize;
            font-family: Inter-Semi Bold, Inter;
            font-weight: normal;
            color: #000000;
            text-align: center;
            margin-top: 100 * $defaultSize;
        }

        .h2 {
            font-size: 20 * $defaultSize;
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            color: #4F5559;
            text-align: center;
        }

        .box1 {
            margin-top: 88 * $defaultSize;
            display: flex;

            .left {
                width: 40%;

                .top {
                    .leftH1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Semi Bold, Inter;
                        font-weight: normal;
                        color: #000000;
                        margin-bottom: 15 * $defaultSize;
                    }

                    .leftH2 {
                        font-size: 15 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }

                .btm {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 30px;
                    height: 180px;

                    .DL1 {
                        cursor: pointer;
                        width: 240px;
                        height: 80px;
                        background-image: url('../../public/macos.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        transition: 0.5s;
                    }

                    .DL1:hover {
                        cursor: pointer;
                        width: 240px;
                        height: 80px;
                        background-image: url('../../public/macos_hover.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        transition: 0.5s;
                    }

                    .DL2 {
                        cursor: pointer;
                        width: 240px;
                        height: 80px;
                        background-image: url('../../public/windows.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        transition: 0.5s;
                    }

                    .DL2:hover {
                        cursor: pointer;
                        width: 240px;
                        height: 80px;
                        background-image: url('../../public/windows_hover.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        transition: 0.5s;
                    }
                }
            }

            .right {
                width: 60%;

                img {
                    width: 100%;
                }
            }
        }

        .box2 {
            margin: 88 * $defaultSize 0;

            .title {
                font-size: 20 * $defaultSize;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                color: #000000;
                margin-bottom: 25 * $defaultSize;
                text-align: center;
            }

            .big {
                display: flex;
                justify-content: space-between;

                .small {
                    width: 30%;
                    text-align: center;

                    img {
                        width: 88 * $defaultSize;
                    }

                    .smallH1 {
                        font-size: 20 * $defaultSize;
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 19 * $defaultSize;
                    }

                    .smallH2 {
                        font-size: 13 * $defaultSize;
                        font-family: Inter-Regular, Inter;
                        font-weight: 400;
                        color: #4F5559;
                    }
                }
            }
        }
    }
}
</style>
  