<template>
    <div class="join">
        <div class="main">
            <img class="box1" src="../assets/jionbg.png" alt="">
            <div class="box2">
                <div class="title">{{$t('join.h1')}}</div>
                <div class="big">
                    <div class="small">
                        <div class="imgDiv">
                            <img src="../assets/join1.png" alt="">
                        </div>
                        <div class="hint">{{$t('join.h2')}}</div>
                        <div class="price">{{$t('join.h3')}}</div>
                        <ul>
                            <li>{{$t('join.h4')}}</li>
                            <li>{{$t('join.h5')}}</li>
                            <li>{{$t('join.h6')}}</li>
                            <li>{{$t('join.h7')}}</li>
                            <li>{{$t('join.h8')}}</li>
                        </ul>
                        <div class="start" @click="goWallet">{{$t('join.h9')}}</div>
                    </div>
                    <div class="small">
                        <div class="imgDiv">
                            <img src="../assets/join2.png" alt="">
                        </div>
                        <div class="hint">{{$t('join.h10')}}</div>
                        <div class="price">$10.95/{{$t('join.h11')}}</div>
                        <ul>
                            <li>{{$t('join.h12')}}</li>
                            <li>{{$t('join.h13')}}</li>
                            <li>{{$t('join.h14')}}</li>
                            <li>{{$t('join.h15')}}</li>
                            <li>{{$t('join.h16')}}</li>
                            <li>{{$t('join.h17')}}</li>
                            <li>{{$t('join.h18')}}</li>
                            <li>{{$t('join.h19')}}</li>
                        </ul>
                        <div class="start" @click="goWallet">{{$t('join.h9')}}</div>
                    </div>
                    <div class="small">
                        <div class="imgDiv">
                            <img src="../assets/join3.png" alt="">
                        </div>
                        <div class="hint">{{$t('join.h20')}}</div>
                        <div class="price">$20.95/{{$t('join.h11')}}</div>
                        <ul>
                            <li>{{$t('join.h21')}}</li>
                            <li>{{$t('join.h22')}}</li>
                            <li>{{$t('join.h23')}}</li>
                            <li>{{$t('join.h24')}}</li>
                            <li>{{$t('join.h25')}}</li>
                            <li>{{$t('join.h26')}}</li>
                            <li>{{$t('join.h27')}}</li>
                            <li>{{$t('join.h28')}}</li>
                            <li>{{$t('join.h29')}}</li>
                            <li>{{$t('join.h30')}}</li>
                            <li>{{$t('join.h31')}}</li>
                        </ul>
                        <div class="start" @click="goWallet">{{$t('join.h9')}}</div>
                    </div>
                    <div class="small">
                        <div class="imgDiv">
                            <img src="../assets/join4.png" alt="">
                        </div>
                        <div class="hint">{{$t('join.h32')}}</div>
                        <div class="price">$42.95/{{$t('join.h11')}}</div>
                        <ul>
                            <li>{{$t('join.h33')}}</li>
                            <li>{{$t('join.h34')}}</li>
                            <li>{{$t('join.h35')}}</li>
                            <li>{{$t('join.h36')}}</li>
                            <li>{{$t('join.h37')}}</li>
                            <li>{{$t('join.h38')}}</li>
                            <li>{{$t('join.h39')}}</li>
                            <li>{{$t('join.h40')}}</li>
                            <li>{{$t('join.h41')}}</li>
                            <li>{{$t('join.h42')}}</li>
                            <li>{{$t('join.h43')}}</li>
                            <li>{{$t('join.h44')}}</li>
                            <li>{{$t('join.h45')}}</li>
                        </ul>
                        <div class="start" @click="goWallet">{{$t('join.h9')}}</div>
                    </div>
                </div>
            </div>
            <div class="box3">
                <div class="title">{{$t('join.h46')}}</div>

                <div class="header">
                    <div class="FirstSmall"></div>
                    <div class="small">{{$t('join.h2')}}</div>
                    <div class="small">{{$t('join.h10')}}</div>
                    <div class="small">{{$t('join.h20')}}</div>
                    <div class="small">{{$t('join.h32')}}</div>
                </div>
                <div class="body">
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h47')}}</div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h48')}}</div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h49')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h50')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h51')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h52')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h53')}}</div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h54')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h55')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h56')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h57')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h58')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h59')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                    <div class="row">
                        <div class="FirstSmall">{{$t('join.h60')}}</div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_error.png" alt=""></div>
                        <div class="small"><img src="../assets/icon_hook.png" alt=""></div>
                    </div>
                </div>
            </div>

            <div>
                <dow-view></dow-view>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { NumSplic } from "../unit/tool";
  import big from "big.js";
import dowView from './simViews/dowView.vue';
  big.NE = -40;
  big.PE = 40;
  export default {
  components: { dowView },
    name: "join",
    data() {
      return {
      };
    },
    methods: {
        goWallet(){
            this.$router.push({
                path: "/wallet",
            });
        },
    },
    computed: {},
    mounted() {
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  $defaultSize: 0.0833333333vw;
  .join {
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;
    .main {
        width: 750 * $defaultSize;
        margin: auto;
        .box1 {
            width: 100%;
        }
        .box2 {
            .title {
                font-size: 20 * $defaultSize;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                text-align: center;
                margin-bottom: 40*$defaultSize;
            }
            .big {
                display: flex;
                justify-content: space-between;
                .small {
                    padding-top: 32 * $defaultSize;
                    width: 22%;
                    height: 586 * $defaultSize;
                    border-radius: 20px;
                    border: 1px solid #CCCCCC;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .imgDiv{
                        width: 75 * $defaultSize;
                        height: 75 * $defaultSize;
                        img {
                            width: 75 * $defaultSize;
                            height: 75 * $defaultSize;
                            transform:scale(1);
                            transition:transform 0.5s;
                        }
                    }
                    .hint {
                        font-size: 13 * $defaultSize;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4F5559;
                    }
                    .price {
                        font-size: 20 * $defaultSize;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                    ul {
                        flex: 1;
                        width: 100%;
                        font-size: 10 * $defaultSize;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4F5559;
                        padding-right: 10 * $defaultSize;
                        li{
                            width: 100%;
                            margin-top: 8* $defaultSize;
                        }
                    }
                    .start {
                        cursor: pointer;
                        margin: 25 * $defaultSize auto;
                        width: 139 * $defaultSize;
                        height: 28 * $defaultSize;
                        line-height: 28 * $defaultSize;
                        text-align: center;
                        background: #224FD9;
                        border-radius: 18 * $defaultSize;

                        font-size: 11 * $defaultSize;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                    .start:hover{
                        background: #1F41A7;
                    }
                }
                .small:hover{
                    border: 1px solid #1F4ED8;
                    .imgDiv{
                        img {
                            transform:scale(1.1)
                        }
                    }
                }
            }
        }
        .box3 {
            .title {
                font-size: 20 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                text-align: center;
                margin-top: 88* $defaultSize;
                margin-bottom: 25*$defaultSize;
            }
            .header {
                display: flex;
                justify-content: space-between;
                height: 38 * $defaultSize;
                line-height: 38 * $defaultSize;
                background: #D3DCF7;
                border-radius: 5px;


                font-size: 11 * $defaultSize;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                .FirstSmall {
                    flex: 1;
                }
                .small {
                    width: 17%;
                    text-align: center;
                }
            }
            .body {
                margin-top: 10 * $defaultSize;
                .row {
                    display: flex;
                    justify-content: space-between;
                    padding: 10 * $defaultSize 0;

                    font-size: 11 * $defaultSize;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    border-bottom: 1px solid #D8D8D8;

                    .FirstSmall {
                        padding-left: 25 * $defaultSize;
                        flex: 1;
                    }
                    .small {
                        width: 17%;
                        text-align: center;
                        img{
                            width: 13 * $defaultSize;
                        }
                    }
                }
            }
        }
    }
  }
  </style>
  