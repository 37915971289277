import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/index';
import VueMeta from 'vue-meta'
import contract from './unit/index.js';
import VueAnimateNumber from 'vue-animate-number';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueClipboard from 'vue-clipboard2'


import Axios from 'axios';

// import echarts from 'echarts'
// console.log("echarts",echarts)
// Vue.prototype.$echarts=echarts

//设置baseURL
// Axios.defaults.baseURL = 'http://223.255.240.51:8899';
//请求头
// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'; 

Vue.use(VueClipboard)

Vue.prototype.$contract = contract

Vue.prototype.$axios = Axios

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true
});

Vue.use(VueClipboard)

Vue.prototype.$contract = contract


Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue

Vue.use(VueAnimateNumber);

Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.directive('animate', {
  inserted: function (el, binding) {
    let isFirst = true
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect()
      const h = document.documentElement.clientHeight || document.body.clientHeight
      if (isFirst) {
        isFirst = false
        return
      }
      if (top < h) {
        if (el.className.indexOf(binding.value) == -1) {
          // 下面单引号中间是一个空格
          el.className = binding.value + ' ' + el.className
        }
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }
    window.addEventListener('scroll', binding.addClass, true)
    binding.addClass()
  },
  unbind: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data: {
    eventHub: new Vue()
  }
}).$mount('#app')
