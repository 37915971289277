<template>
    <div class="dowImg">
      <div class="dowImg-top">
          {{$t('contactView.h1')}}
      </div>
      <div class="dowImg-det">
        <img src="../../assets/sim/contactImg2.svg" alt=""> {{$t('contactView.h2')}}
      </div>
      <div class="dowImg-btm">
          <div class="dowImg-btm1" @click="goDownload">
              <span>{{$t('contactView.h3')}}</span>
              <span class="solidSpan">
                <img src="../../assets/chainData/home_button_right_normal.svg" />
              </span>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      methods: {
          goDownload(){
            window.open('https://tianyi.art/contact/office', '_blank');
          },
      }
  }
  </script>
  
  <style scoped lang="scss">
    $defaultSize: 0.0833333333vw;
    @keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}
  .dowImg{
      background-image: url("../../assets/sim/contactImg.png");
      background-size: 100%;
      background-repeat: no-repeat;
      height: 308 * $defaultSize;
      margin-top: 50 * $defaultSize;
      padding: 50 * $defaultSize;
      .dowImg-top{
            width: 30%;
          font-size: 21 * $defaultSize;
          font-family: Inter-Semi Bold, Semi Bold;
          font-weight: 600;
          color: #333333;
          margin-bottom: 20 * $defaultSize;
      }
      .dowImg-det{
        font-size: 13 * $defaultSize;
        font-family: Inter-Regular, Regular;
        font-weight: 400;
        color: #4F5559;
        display: flex;
      }
      .dowImg-btm{
          width: 100%;
          padding: 70 * $defaultSize 0;
          display: flex;
          .dowImg-btm1 {
              cursor: pointer;
              display: inline-block;
              width: 100 * $defaultSize;
              height: 35 * $defaultSize;
              background-color: #03A76A;
              line-height: 35 * $defaultSize;
              border-radius: 8 * $defaultSize;
              font-size: 15 * $defaultSize;
              font-family: Inter-Semi Bold, Inter;
              text-align: center;
              font-weight: 500;
              color: #FFFFFF;
              img{
                  width: 12 * $defaultSize;
                  color: #FFFFFF;
              }
          }
          .dowImg-btm1:hover{
              background: #3A8459;
              .solidSpan{
                  img{
                    animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                  }
              }
          }
      }
  }
  </style>