<template>
    <div class="dowImg">
      <div class="dowImg-top">
          {{$t('helpView.h1')}}
      </div>
      <div class="dowImg-det">
          {{$t('helpView.h2')}}
      </div>
      <div class="dowImg-btm">
          <div class="dowImg-btm1" @click="goDownload">
              <span>{{$t('helpView.h3')}}</span>
              <span class="solidSpan">
                  <img src="../../assets/solid.svg" alt="">
              </span>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      methods: {
          goDownload(){
            window.open('https://tianyi.art/contact/office', '_blank');
          },
      }
  }
  </script>
  
  <style scoped lang="scss">
    $defaultSize: 0.0833333333vw;

    @keyframes tracking-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(3 * $defaultSize);
  }
}
  .dowImg{
      background-image: url("../../assets/sim/help1.png");
      background-size: 100%;
      background-repeat: no-repeat;
      height: 208 * $defaultSize;
      margin-top: 50 * $defaultSize;
      text-align: center;
      padding: 30 * $defaultSize;
      .dowImg-top{
          font-size: 20 * $defaultSize;
          font-family: Inter-Semi Bold, Semi Bold;
          font-weight: 500;
          color: #333333;
          margin-bottom: 10 * $defaultSize;
      }
      .dowImg-det{
          font-size: 15 * $defaultSize;
          font-family: Inter-Regular, Regular;
          font-weight: 400;
          color: #4F5559;
      }
      .dowImg-btm{
          width: 100%;
          padding: 25 * $defaultSize;
          display: flex;
          align-items: center;
          justify-content: center;
          .dowImg-btm1 {
              cursor: pointer;
              display: inline-block;
              width: 100 * $defaultSize;
              height: 35 * $defaultSize;
              background-color: #03A76A;
              line-height: 35 * $defaultSize;
              border-radius: 8 * $defaultSize;
              font-size: 15 * $defaultSize;
              font-family: Inter-Semi Bold, Inter;
              text-align: center;
              font-weight: 500;
              color: #FFFFFF;
              img{
                  width: 7 * $defaultSize;
                  color: #FFFFFF;
              }
          }
          .dowImg-btm1:hover{
              background: #3A8459;
              .solidSpan{
                  img{
                    animation: tracking-in-left 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                  }
              }
          }
      }
  }
  </style>