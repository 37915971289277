<template>
    <div class="about">
        <div class="box1">
            <div>
                <span v-for="(item,index) in h1Arr($t('about.h1'))" :key="index"
                    :class="[`${GET_IsAnimation ? 'textAnimation' : 'textAnimation_none'}`]"
                    :style="{ animationDelay: `${(index * 0.02) + (h1Arr($t('home.h1')).length * 0.02)}s` }">{{ item }}
                    <span v-if="getI18nByEn()">&nbsp;</span>
                </span>
            </div>
        </div>
        <div class="main">
            <div class="box2">
                <div class="left">{{ $t('about.h2') }}</div>
                <div class="right">{{ $t('about.h3') }}</div>
            </div>
            <div class="box3">
                <div class="small">
                    <div class="imgDiv">
                        <img src="../assets/about1.png" alt="">
                    </div>
                    <div class="name">{{ $t('about.h4') }}</div>
                    <div class="hint">{{ $t('about.h5') }}</div>
                </div>
                <div class="small">
                    <div class="imgDiv">
                        <img src="../assets/about2.png" alt="">
                    </div>
                    <div class="name">{{ $t('about.h6') }}</div>
                    <div class="hint">{{ $t('about.h7') }}</div>
                </div>
                <div class="small">
                    <div class="imgDiv">
                        <img src="../assets/about3.png" alt="">
                    </div>
                    <div class="name">{{ $t('about.h8') }}</div>
                    <div class="hint">{{ $t('about.h9') }}</div>
                </div>
            </div>
            <div class="box4">
                <img src="../assets/about4.png" alt="">
            </div>
            <div class="box5">
                <div class="title">{{ $t('about.h10') }}</div>
                <div class="big">
                    <div class="small">
                        <img src="../assets/LIVHNG9YPOMF0.png" alt="">
                        <div class="info">
                            <div class="Num">01</div>
                            <div class="name">{{ $t('about.h11') }}</div>
                            <div class="hint">{{ $t('about.h12') }}</div>
                        </div>
                    </div>
                    <div class="small">
                        <div class="info">
                            <div class="Num">02</div>
                            <div class="name">愿景</div>
                            <div class="hint">我们的愿景是通过丰富世界的创新解决方案，成为引领行业发展的领导者。我们追求卓越的用户体验，致力于为用户创造无与伦比的价值，并通过创意和创新为社会带来积极的影响。</div>
                        </div>
                        <img src="../assets/LIVHNHY3VBMI1.png" alt="">
                    </div>
                    <div class="small">
                        <img src="../assets/LIVHNJTZT4T0H.png" alt="">
                        <div class="info">
                            <div class="Num">03</div>
                            <div class="name">价值观</div>
                            <div class="hint">以领导力为基础，在与社区的紧密合作中，为您提供高效、高质的解决方案。为客户提供卓越的服务和成果。我们的目标是以卓越的执行力和创新精神，超越客户期望，实现共同成功。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <contact-view></contact-view>
            </div>
        </div>
    </div>
</template>
  
<script>
import { NumSplic } from "../unit/tool";
import big from "big.js";
import contactView from './simViews/contactView.vue';
import {mapGetters} from "vuex"
big.NE = -40;
big.PE = 40;
export default {
    components: { contactView },
    name: "join",
    data() {
        return {
        };
    },
    methods: {
        getI18nByEn(){
      return this.$i18n.locale == 'en';
    },
    },
    computed: {
        ...mapGetters(['GET_IsAnimation']),
        h1Arr(text) {
            if (this.$i18n.locale == 'en') {
                return text => text.split(" ");
            } else {
                return text => text.split("")
            }
        }
    },
    mounted() {
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$defaultSize: 0.0833333333vw;

.about {
    margin-bottom: 100 * $defaultSize;
    // background-color: #f0f2f5;
    // border: 1px solid #f0f2f5;
    .box1 {
        background-image: url("../assets/nexo-corporate-accounts-background.png");
        background-size: 100%;
        background-repeat: no-repeat;
        height: 308 * $defaultSize;

        display: flex;
        justify-content: center;
        align-items: center;

        &>div {

            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            font-size: 40 * $defaultSize;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #224FD9;
            width: 770 * $defaultSize;
            text-align: center;
        }
    }

    .main {
        width: 750 * $defaultSize;
        margin: auto;
        .box2 {
            margin: 86 * $defaultSize 0;
            display: flex;
            justify-content: space-between;
            animation: tracking-in-expand-fwd-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

            .left {
                width: 48%;
                font-size: 25 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
            }

            .right {
                width: 48%;
                font-size: 15 * $defaultSize;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }

        .box3 {
            display: flex;
            justify-content: space-between;

            .small {
                width: 30%;
                text-align: center;

                .imgDiv {
                    width: 100%;
                    height: 95 * $defaultSize;
                }

                img {
                    width: 88 * $defaultSize;
                    transform:scale(1);
                    transition:transform 0.5s;
                }

                .name {
                    font-size: 20 * $defaultSize;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 15 * $defaultSize;
                }

                .hint {
                    font-size: 13 * $defaultSize;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4F5559;
                }
            }

            .small:hover {
                img {
                    transform:scale(1.1)
                }
            }
        }

        .box4 {
            margin: 88 * $defaultSize 0;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .box5 {
            .title {
                text-align: center;
                font-size: 20 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
            }

            .big {
                display: flex;
                padding-top: 50 * $defaultSize;
                justify-content: space-between;

                .small {
                    width: 30%;
                    height: 200 * $defaultSize;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    img {
                        width: 27 * $defaultSize;
                    }

                    .info {
                        .Num {
                            font-size: 10 * $defaultSize;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #1F4DD8;
                        }

                        .name {
                            font-size: 20 * $defaultSize;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                        }

                        .hint {
                            font-size: 13 * $defaultSize;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #4F5559;
                        }
                    }
                }
            }
        }

        .box6 {
            height: 306 * $defaultSize;
            background-image: url("../assets/join5.png");
            background-size: 100%;
            background-repeat: no-repeat;
            margin: 88 * $defaultSize 0;
            padding: 70 * $defaultSize 50 * $defaultSize;

            .h1 {
                font-size: 20 * $defaultSize;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
            }

            .h2 {
                font-size: 13 * $defaultSize;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4F5559;
                margin-top: 10 * $defaultSize;
                margin-bottom: 47 * $defaultSize;
            }

            .btn {
                width: 94 * $defaultSize;
                height: 35 * $defaultSize;
                background: #03A76A;
                border-radius: 5 * $defaultSize;
                font-size: 14 * $defaultSize;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;

                span {
                    margin-left: 10 *$defaultSize;
                }

                line-height: 35 * $defaultSize;

                img {
                    width: 7 * $defaultSize;
                    color: #FFFFFF;
                }
            }

            .btn:hover {
                background-color: #3A8459;

                .solidSpan {
                    img {
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}

@keyframes tracking-in-expand-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        transform: translateZ(-70 * $defaultSize) translateY(50 * $defaultSize);
        opacity: 0;
    }

    40% {
        opacity: 0.1;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

.textAnimation {
    display: block;
    animation: tracking-in-expand-fwd-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.textAnimation_none {
    animation: none;
    animation-play-state: paused;
    animation-fill-mode: forwards;
}
</style>
  